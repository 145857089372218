import { CART_ID_COOKIE_KEY } from '../constants'
import { prepareItemsForLocalStorage } from '../helpers'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение общих параметров для запросов корзины
   * с выключенным addressSelectAddressToPlaid - бэк берет данные из сессии
   * с включенным addressSelectAddressToPlaid - мы передаем сами
   */
  getCartRequestParams(params = {}) {
    if (!this.gaApp.features.get('addressSelectAddressToPlaid')) {
      return params
    }

    const cartId = this.gaApp.services.cart.guest.getId()
    const address = this.gaApp.services.location.main.getDeliveryAddress()

    return {
      ...params,
      cartId,
      fiasId: address.id,
      cityDistrict: address.cityDistrict,
      geoPolygons: address.geoPolygons,
    }
  }

  setCart(cart) {
    this.gaApp.services.cart.main.setCart(cart)
  }

  setAvailableCoupons(coupons) {
    this.gaApp.services.cart.main.setAvailableCoupons(coupons)
  }

  saveCart(rawCart) {
    this.gaApp.services.cache.main.saveCart(rawCart)
    this.gaApp.services.cache.main.saveCartItems(
      prepareItemsForLocalStorage(this.gaApp.stores.cart.main.analyticItems),
    )
  }

  setLoadingItems(ids = []) {
    this.gaApp.stores.cart.modal.itemsLoading = ids
  }

  /**
   * Получение данных корзины
   */
  async getData() {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.getCartV3()
    }

    return await this.getCartV1()
  }

  async getCartV1() {
    const isAuth = this.gaApp.services.user.main.isAuthorized
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = this.getCartRequestParams()

    if (isAuth && cartId) {
      this.gaApp.services.cart.guest.removeId()
    }

    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('cart')

      const { data } =
        cacheData || (await this.gaApp.repositories.cart.main.getCart(params))
      const { cart, rawCart, message } = data

      if (!isAuth && !cartId) {
        this.gaApp.services.cart.guest.setId(cart.id)
      }

      if (!cart) {
        const error = new Error('Не удалось получить данные корзины')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      console.log(error)
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.get'),
      )
    }
  }

  /**
   * Получение данных корзины из плейда с адресом на клиенте
   */
  async getCartV3() {
    const {
      id: fiasId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()
    const isAuth = this.gaApp.services.user.main.isAuthorized
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = {
      fiasId,
      cityDistrict,
      geoPolygons,
      cartId: isAuth ? undefined : cartId,
      guestCartId: isAuth ? cartId : undefined,
    }

    if (isAuth && cartId) {
      this.gaApp.services.cart.guest.removeId()
    }

    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('cart')

      const { data } =
        cacheData || (await this.gaApp.repositories.cart.cartV3.getCart(params))
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось получить данные корзины')
        error.localizedMessage = message
        throw error
      }

      this.gaApp.cookies.set(CART_ID_COOKIE_KEY, cart.id)

      if (!isAuth && !cartId) {
        this.gaApp.services.cart.guest.setId(cart.id)
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      console.log(error)
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.get'),
      )
    }
  }

  async addItems(items = []) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.addCartV3Items(items)
    }

    return await this.addCartV1Items(items)
  }

  async addCartV1Items(items = []) {
    const params = this.getCartRequestParams({
      items,
    })

    try {
      const { data } = await this.gaApp.repositories.cart.main.addItems(params)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      if (error.localizedMessage) {
        this.gaApp.services.notification.main.open(error.localizedMessage)
      }

      throw error
    }
  }

  async addCartV3Items(items = []) {
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = {
      cartId,
      items,
    }

    try {
      const { data } =
        await this.gaApp.repositories.cart.cartV3.addItems(params)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      if (error.localizedMessage) {
        this.gaApp.services.notification.main.open(error.localizedMessage)
      }

      throw error
    }
  }

  /**
   * Добавление товара
   */
  async addItem(product = {}, hasAlert = true) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.addCartV3Item(product, hasAlert)
    }

    return await this.addCartV1Item(product, hasAlert)
  }

  /**
   * Добавление товара черчез мадженту
   */
  async addCartV1Item(product = {}, hasAlert = true) {
    const params = this.getCartRequestParams({
      sku: product.id,
      analyticsDetails: product.analyticsDetails,
    })

    try {
      const { data } = await this.gaApp.repositories.cart.main.addItem(params)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукт')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)

      if (hasAlert) {
        this.gaApp.services.cart.notification.addSuccess(product)
      }

      this.saveCart(rawCart)
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.notification.main.open(error.localizedMessage)
        : this.gaApp.services.cart.notification.addFailure(product)
    }
  }

  /**
   * Добавление товара черчез плейд с адресом на клиенте
   */
  async addCartV3Item(product = {}, hasAlert = true) {
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = {
      cartId,
      sku: product.id,
      analyticsDetails: product.analyticsDetails,
    }

    try {
      const { data } = await this.gaApp.repositories.cart.cartV3.addItem(params)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукт')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)

      if (hasAlert) {
        this.gaApp.services.cart.notification.addSuccess(product)
      }

      this.saveCart(rawCart)
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.notification.main.open(error.localizedMessage)
        : this.gaApp.services.cart.notification.addFailure(product)
    }
  }

  async addItemByBarcode(product = {}, hasAlert = false) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return // TODO: Доработать когда плейд реализует ручку
    }

    return await this.addCartV1ItemByBarcode(product, hasAlert)
  }

  async addCartV1ItemByBarcode(product = {}, hasAlert = false) {
    const params = this.getCartRequestParams({
      barcode: product.id,
    })

    try {
      const { data } =
        await this.gaApp.repositories.cart.main.addItemByBarcode(params)
      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось добавить продукт по баркоду')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)

      if (hasAlert) {
        this.gaApp.services.cart.notification.addSuccess(product)
      }

      this.saveCart(rawCart)
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.notification.main.open(error.localizedMessage)
        : this.gaApp.services.cart.notification.addFailure(product)
    }
  }

  async validateStock() {
    try {
      const items = this.gaApp.services.cart.main.getItemsStatuses()

      const params = this.getCartRequestParams({
        items,
      })

      const { data } =
        await this.gaApp.repositories.cart.main.validateStock(params)

      const { availableCouponsRecollect, isValid, needRequestData } = data
      const isCartActual = isValid && !needRequestData

      if (!isCartActual) {
        await this.getData()
      }

      if (isCartActual && availableCouponsRecollect) {
        await this.recollectAvailableCoupons()
      }
    } catch (error) {
      await this.getData()
    }
  }

  async loadValidCartData() {
    if (this.gaApp.stores.cart.modal.isEmpty) {
      await this.getData()
    } else {
      await this.validateStock()
    }
  }

  async recollectAvailableCoupons() {
    const params = this.gaApp.features.get('addressSelectAddressToPlaid')
      ? this.getCartRequestParams()
      : { cartId: this.gaApp.stores.cart.main.id }

    try {
      const { data } =
        await this.gaApp.repositories.cart.main.recollectAvailableCoupons(
          params,
        )
      const { availableCoupons } = data

      this.setAvailableCoupons(availableCoupons)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.get'),
      )
    }
  }

  async updateItemQty({ id, qty }) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.updateCartV3ItemQty({ id, qty })
    }

    return await this.updateCartV1ItemQty({ id, qty })
  }

  async updateCartV1ItemQty({ id, qty }) {
    const params = this.getCartRequestParams({
      itemId: id,
      itemQty: qty,
    })

    try {
      this.setLoadingItems([id])

      const { data } =
        await this.gaApp.repositories.cart.main.updateItemQty(params)

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось обновить количество')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.updateQty'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async updateCartV3ItemQty({ id, qty }) {
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = {
      cartId,
      itemId: id,
      itemQty: qty,
    }

    try {
      this.setLoadingItems([id])

      const { data } =
        await this.gaApp.repositories.cart.cartV3.updateItemQty(params)

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось обновить количество')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.updateQty'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async deleteItems({ ids }) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.deleteCartV3Items(ids)
    }

    return await this.deleteCartV1Items(ids)
  }

  async deleteCartV1Items(ids) {
    const params = this.getCartRequestParams({
      itemIds: ids.join(','),
    })

    try {
      this.setLoadingItems(ids)

      const { data } =
        await this.gaApp.repositories.cart.main.deleteItems(params)

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось удалить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.delete'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async deleteCartV3Items(ids) {
    const cartId = this.gaApp.services.cart.guest.getId()

    const params = {
      cartId,
      itemIds: ids.join(','),
    }

    try {
      this.setLoadingItems(ids)

      const { data } =
        await this.gaApp.repositories.cart.cartV3.deleteItems(params)

      const { cart, rawCart, message } = data

      if (!cart) {
        const error = new Error('Не удалось удалить продукты')
        error.localizedMessage = message
        throw error
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.delete'),
      )
      this.getData()
    } finally {
      this.setLoadingItems()
    }
  }

  async setCoupon(coupon = null) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return await this.setCartV3Coupon(coupon)
    }

    return await this.setCartV1Coupon(coupon)
  }

  async setCartV1Coupon(coupon = null) {
    const prevValue = this.gaApp.stores.cart.modal.coupon.value
    const isApplying = Boolean(coupon)
    const value = coupon?.value || ''

    const requestMethod = isApplying ? 'applyCoupon' : 'cancelCoupon'

    try {
      const captchaToken =
        await this.gaApp.services.captcha.main.generateToken()

      const params = this.getCartRequestParams({
        captchaToken,
      })

      if (isApplying) {
        params.value = value
      }

      const { data } =
        await this.gaApp.repositories.cart.main[requestMethod](params)
      const { cart, rawCart, message } = data
      const isSuccess = Boolean(cart || !message)

      if (!isSuccess) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, false)
        this.gaApp.stores.cart.main.totals.coupon = value
        this.gaApp.services.cart.modal.setCouponErrorMessage(message)

        return
      }

      this.gaApp.services.cart.modal.setCouponErrorMessage(null)

      if (!cart) {
        const error = new Error('Не удалось применить или удалить купон')
        error.localizedMessage = message
        throw error
      }

      if (isApplying) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, true)
      } else {
        this.gaApp.analytics.modules.cart.onCouponRemove({ value: prevValue })
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.coupon'),
      )
      this.getData()

      throw error
    }
  }

  async setCartV3Coupon(coupon = null) {
    const cartId = this.gaApp.services.cart.guest.getId()
    const prevValue = this.gaApp.stores.cart.modal.coupon.value
    const isApplying = Boolean(coupon)
    const value = coupon?.value || ''

    try {
      const captchaToken =
        await this.gaApp.services.captcha.main.generateToken()
      let result = {}

      if (isApplying) {
        result = await this.gaApp.repositories.cart.cartV3.applyCoupon({
          cartId,
          captchaToken,
          value,
        })
      } else {
        result = await this.gaApp.repositories.cart.cartV3.cancelCoupon({
          cartId,
          captchaToken,
        })
      }

      const { cart, rawCart, message } = result.data
      const isSuccess = Boolean(cart || !message)

      if (!isSuccess) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, false)
        this.gaApp.stores.cart.main.totals.coupon = value
        this.gaApp.services.cart.modal.setCouponErrorMessage(message)

        return
      }

      this.gaApp.services.cart.modal.setCouponErrorMessage(null)

      if (!cart) {
        const error = new Error('Не удалось применить или удалить купон')
        error.localizedMessage = message
        throw error
      }

      if (isApplying) {
        this.gaApp.analytics.modules.cart.onCouponApply(coupon, true)
      } else {
        this.gaApp.analytics.modules.cart.onCouponRemove({ value: prevValue })
      }

      this.setCart(cart)
      this.saveCart(rawCart)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        error.localizedMessage
          ? error.localizedMessage
          : this.gaApp.i18n.t('cart.error.coupon'),
      )
      this.getData()

      throw error
    }
  }

  async getProductsActual(ids) {
    try {
      const { data } =
        await this.gaApp.repositories.plp.main.fetchProductsActual(ids)

      return data?.products ?? []
    } catch (error) {
      console.error(error)

      return []
    }
  }

  reorder(id) {
    if (this.gaApp.features.get('cartPlaidApi')) {
      return // TODO: Доработать когда плейд реализует ручку
    }

    return this.reorderMagento(id)
  }

  async reorderMagento(id) {
    try {
      this.gaApp.stores.cart.main.reorderPending = true

      const response = await this.gaApp.repositories.cart.main.reorder(id)

      const { cart, rawCart, message, isPartial } = response.data

      if (!cart) {
        const error = new Error('Не удалось добавить товары в корзину')

        error.localizedMessage = message
        throw error
      }

      if (isPartial) {
        this.gaApp.services.cart.notification.addNotification(
          this.gaApp.i18n.t('cart.error.partialReorder'),
        )
      }

      this.setCart(cart)
      this.saveCart(rawCart)

      this.gaApp.services.modal.main.openSuperModalCart()
    } catch (error) {
      error.localizedMessage
        ? this.gaApp.services.cart.notification.addNotification(
            error.localizedMessage,
          )
        : this.gaApp.services.cart.notification.addNotification(
            this.gaApp.i18n.t('cart.error.reorder'),
          )
    } finally {
      this.gaApp.stores.cart.main.reorderPending = false
    }
  }

  async getSharingShare(products) {
    this.gaApp.stores.cart.share.loadingHash = true

    try {
      const { data } = await this.gaApp.repositories.cart.share.getHash({
        products,
      })
      const { hash } = data

      if (hash.length === 0) {
        throw new Error('Не удалось получить хэш расшаренных товаров')
      }

      return hash
    } catch (error) {
      this.showError(error, this.gaApp.i18n.t('cart.error.somethingWrong'))

      return ''
    } finally {
      this.gaApp.stores.cart.share.loadingHash = false
    }
  }

  async getSharingList(params) {
    this.gaApp.stores.cart.share.loadingProducts = true

    try {
      const { data } = await this.gaApp.repositories.cart.share.getList(params)
      const { products } = data

      if (products.length === 0) {
        throw new Error('Не удалось получить расшаренные товары')
      }

      return products
    } catch (error) {
      this.showError(error, this.gaApp.i18n.t('cart.error.somethingWrong'))

      return []
    } finally {
      this.gaApp.stores.cart.share.loadingProducts = false
    }
  }

  showError(error, fallbackText) {
    const errorText = error.localizedMessage || fallbackText

    this.gaApp.services.notification.main.open(errorText)
  }
}

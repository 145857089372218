import { computed, unref } from 'vue'

import { prefixExists, valueExists } from '../utils'

/**
 * Возвращает computed объект модификаторы блока
 * @param {Ref<Boolean>} clear - флаг наличия кнопки очистки из props
 * @param {Ref<String>} innerValue - внутреннее значение инпута
 * @param {Ref<Boolean>} hasValue - флаг заполненности инпута
 * @param {Ref<String>} prefix - prefix из props
 * @param {Ref<Boolean>} hasPrefix - наличие префикса
 */

export const useClear = (clear, innerValue, prefix, isFocused) => {
  const clearVisible = computed(() => {
    return prefixExists(unref(prefix))
      ? unref(clear) &&
          valueExists(unref(innerValue)) &&
          unref(innerValue) !== unref(prefix)
      : unref(clear) && valueExists(unref(innerValue))
  })

  const clearValue = () => {
    innerValue.value = prefixExists(unref(prefix)) ? unref(prefix) : ''
    isFocused.value = true
  }

  return {
    clearVisible,
    clearValue,
  }
}

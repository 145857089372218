<template>
  <svg fill="currentColor" viewBox="0 0 20 20">
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="m7.037 13.167 4.463 3.662V3.17L7.037 6.833H1.5v6.334h5.537ZM0 14.667V5.333h6.5L13 0v20l-6.5-5.333H0Z"
      clip-rule="evenodd"
    />
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="M19.75 10c0 2.412-1.113 4.79-3.271 6.578l-.957-1.156c1.841-1.525 2.728-3.49 2.728-5.422 0-1.931-.887-3.897-2.729-5.422l.957-1.156C18.637 5.211 19.75 7.588 19.75 10Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-sound',
}
</script>

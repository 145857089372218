import { analytics } from './analytics'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'

export { buildPlasticLink } from './helpers'

export const giftCards = {
  meta: {
    name: 'giftCards',
    routes,
    locales,
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

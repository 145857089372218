export const ROUTE = '/review/product'
export const SCROLL_HASH_PREFIX = '#s-'
export const ANCHOR_PREVIEW = {
  TOP: 'review-top',
  BOTTOM: 'review-bottom',
}

export const PAGE_NOT_FOUND = 'Not Found'

export const BLOCK_TYPES = {
  REVIEWS: 'listing',
  GALLERY: 'gallery',
  STATISTIC: 'statistic',
  FILTERS: 'filters',
  CARD: 'card',
}

import { unref } from 'vue'

/**
 * Возвращет обработчики, которые закрывают модалку
 * @param {Function} close - функция закрытия модалки
 * @param {Ref<Boolean>} focusTrapped - флаг фокуса
 */
export const useCloseTriggers = (close, focusTrapped) => {
  const onOverlayClick = () => {
    close()
  }

  const onEscapeKeyDown = () => {
    if (unref(focusTrapped)) {
      close()
    }
  }

  const onCloseActionClick = () => {
    close()
  }

  return {
    onOverlayClick,
    onEscapeKeyDown,
    onCloseActionClick,
  }
}

export const getPromoStateInitial = () => ({
  info: null,
  availableShops: [],
  isAvailabilityModalOpen: false,
  inListing: false,

  analyticData: {},

  metaTitle: null,
  metaDescription: null,
  isPrevPagePromo: false,
})

export default () => {
  return getPromoStateInitial()
}

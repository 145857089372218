export const routes = [
  {
    moduleType: 'purchased',
    pageType: 'products',
    pageKey: 'profile',
    path: '/customer/account/purchased-products',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/purchased/page/plp' */
        '~/modules/purchased-products/components/pages/plp.vue'
      ),
  },
]

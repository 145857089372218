import { buildGetUrlForListing } from './builders'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchArticle(id) {
    // выбираем какую ручку использовать в зависимости от типа редактора
    const { url } = this.gaApp.features.get('flaconUseNewEditor')
      ? this.endpoints.fetchArticleV2(id)
      : this.endpoints.fetchArticle(id)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchArticleByUrl(articleUrl) {
    // По умолчанию используем АПИ ручку POST
    let url = this.endpoints.fetchArticleByUrlPost(articleUrl).url

    // Если включен ФТ на использование АПИ ручки GET
    if (this.gaApp.features.get('flaconUseNewEndpointForGetArticle')) {
      // выбираем какую ручку использовать в зависимости от типа редактора
      url = this.gaApp.features.get('flaconUseNewEditor')
        ? this.endpoints.fetchArticleByUrlV2(articleUrl).url
        : this.endpoints.fetchArticleByUrl(articleUrl).url
    }

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchArticlesListing(data) {
    const { url } = this.endpoints.fetchArticlesListing()
    const params = buildGetUrlForListing(data)

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchListingFilters() {
    const { url } = this.endpoints.fetchFilters()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProductsBySku(params) {
    const { url } = this.endpoints.fetchProductsBySku()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchCategoryDataByUrl(categoryUrl) {
    const { url } = this.endpoints.fetchCategoryDataByUrl()
    const params = { url: categoryUrl }

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProducts(data) {
    const { url } = this.endpoints.fetchProducts()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchRubricArticles(data) {
    const { url } = this.endpoints.fetchRubricArticles()

    const fullUrl = `${url}?page=${data.pagination.pageNumber}&limit=${data.pagination.pageSize}&rubric=${data.rubric}`

    try {
      const response = await this.gaApp.api.request().get(fullUrl)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchPreviewListing(id) {
    const { url } = this.endpoints.fetchPreviewListing(id)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchArticleViewed(data) {
    const { url } = this.gaApp.isWebview
      ? this.endpoints.fetchArticleViewedWebview()
      : this.endpoints.fetchArticleViewed()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { OFFERS_PAGE_SIZE } from './../../constants/pages'

export const getters = () => ({
  getPageNumberFirst() {
    const pages = this.pages

    return pages.length ? +pages[0].number : 0
  },

  getPageNumberLast() {
    const pages = this.pages

    return pages.length ? +pages[pages.length - 1].number : 0
  },

  getPagesNumbers() {
    return Math.floor(this.offersCount / OFFERS_PAGE_SIZE) ?? 1
  },
})

function prepareItemForLocalStorage(item) {
  const { indexTotal, itemListId, itemListName, searchTerm, ...rest } = item

  return {
    ...rest,

    index_total: indexTotal,
    item_list_id: itemListId,
    item_list_name: itemListName,
    search_term: searchTerm,
  }
}

export function prepareItemsForLocalStorage(items) {
  return items.map(prepareItemForLocalStorage)
}

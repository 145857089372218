export class ContentService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(this.gaApp.stores.home.content.contentData).includes(
      block.id,
    )
  }

  updateBlockData(block) {
    const index = this.gaApp.stores.home.content.contentData[block.id]?.index
    this.gaApp.stores.home.content.items[index].data = block.data
  }

  addBlockData(block) {
    this.gaApp.stores.home.content.items.push({
      id: block.id,
      data: block.data,
      type: block.type,
    })
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }

  addViewedId(id) {
    this.gaApp.stores.home.content.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.home.content.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }
}

<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M6.5 5.5V5a3.5 3.5 0 1 1 7 0v.5h3v13h-13v-13h3Zm1-.5a2.5 2.5 0 0 1 5 0v.5h-5V5Zm-1 1.5V8h1V6.5h5V8h1V6.5h2v11h-11v-11h2Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-cart-regular',
}
</script>

import { ActionService } from './action.service'
import { AdvModalService } from './adv-modal.service'
import { EventService } from './event.service'
import { HashService } from './hash.service'
import { MainService } from './main.service'
import { QueueService } from './queue'
import { RegisterService } from './register.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  hash: new HashService(gaApp),
  register: new RegisterService(gaApp),
  event: new EventService(gaApp),
  action: new ActionService(gaApp),
  advModal: new AdvModalService(gaApp),
  queue: new QueueService(gaApp),
})

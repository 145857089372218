import * as components from './components'
import { locales } from './locales'
import { services } from './services'
import { stores } from './stores'

export const support = {
  meta: {
    name: 'support',
    locales,
  },
  layers: {
    stores,
    services,
  },
  components,
}

import sourceBreakpoints from '@ga/styles/vars/breakpoints.scss'

// Делаем объект брейкпоинтов, содержащих в себе индекс и ширину
export const breakpoints = Object.keys(sourceBreakpoints).reduce(
  (accumulator, current, index) => ({
    ...accumulator,
    [current]: {
      width: Number.parseInt(sourceBreakpoints[current], 10),
      index,
    },
  }),
  {},
)

import { useContext } from '@nuxtjs/composition-api'

import { onMounted, ref } from 'vue'

export const useDeferRender = (maxFramesCount = 10, timeout = 1000) => {
  const { $gaApp } = useContext()

  const currentFrame = ref(0)

  /**
   * Увеличиваем currentFrame на каждый коллбэк requestAnimationFrame
   */
  const incrementCurrentFrame = () => {
    const fallbackTimer = fallbackFrameTimer()

    const step = () => {
      requestAnimationFrame(() => {
        currentFrame.value++

        currentFrame.value < maxFramesCount ? step() : fallbackTimer.clear()
      })
    }

    fallbackTimer.run()

    step()
  }

  const fallbackFrameTimer = () => {
    let timerId = null

    /**
     * Метод вызывается в случае, если отработал весь диапазон кадров, до истечения таймера
     */
    const clear = () => {
      clearTimeout(timerId)
      timerId = null
    }

    /**
     * Если по истечении %timeout%ms счетчик кадров (currentFrame) будет меньше, чем maxFramesCount,
     * значит компоненты из этого диапазона не отрендрились.
     *
     * Принудительно устанавливаем максимальное значение для currentFrame.
     **/
    const run = () => {
      timerId = setTimeout(() => (currentFrame.value = maxFramesCount), timeout)
    }

    return {
      clear,
      run,
    }
  }

  onMounted(() => {
    incrementCurrentFrame()
  })

  const defer = (framePriority) => {
    if ($gaApp.services.app.main.isSSR) {
      return true
    }

    if (framePriority > maxFramesCount) {
      throw new Error('framePriority не должен превышать maxFramesCount')
    }

    return currentFrame.value >= framePriority
  }

  return {
    defer,
  }
}

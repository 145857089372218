import { MindboxShareAddToCartEventModel } from '../mindbox-share-add-to-cart-event.model/mindbox-share-add-to-cart-event.model'

export class MindboxShareAddToCartModel {
  constructor({ products }) {
    if (!products) {
      throw new Error('Products are required')
    }

    this.events = products.map((product) => new MindboxShareAddToCartEventModel(product))
  }
}

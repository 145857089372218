import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ShareCartClickModel extends GtmBaseModel {
  constructor({ count, totals }) {
    super({ event: EVENT.SHARE_CART_CLICK.GA4 })

    this.event_category = 'cart'
    this.action_type = 'click_share_cart'

    this.items_count = count
    this.amount = totals.total
    this.currency = totals.currency
  }
}

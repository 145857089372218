import { GaIconAdditionalHeartSmall, GaIconPrimaryCartBold } from '@ga/icons'

export const TYPE = {
  FAVORITES: 'favorite',
  CART: 'cart',
}

export const ICON = {
  [TYPE.FAVORITES]: GaIconAdditionalHeartSmall,
  [TYPE.CART]: GaIconPrimaryCartBold,
}

export const TEXT_DELIMITER = '{product}'

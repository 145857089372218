export const TYPE = {
  GTM_4: 'gtm_4',
  GDESLON: 'gdeslon',
  GAA: 'ga_analytics',
  DIGI: 'digi_analytics',
}

export const SOURCE = 'plaid_backend'

export const EVENT_TYPES = {
  WIDGET_VIEW: 'WIDGET_VIEW',
}

export const WIDGET_TYPES = {
  RENDER: 'RENDER',
  VIEW: 'VIEW',
}

export const MERCHANT_ID = '97915'
export const DEDUPLICATION = 'gdeslon'

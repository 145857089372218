import { POSITION } from './const'
import { split } from './utils'

export const validatePosition = (shorthandPosition) => {
  const temporaryPosition = split(shorthandPosition)

  const hasBothPositions = temporaryPosition.length === 2
  const hasPositionX = temporaryPosition.some((elem) =>
    POSITION.x.includes(elem),
  )
  const hasPositionY = temporaryPosition.some((elem) =>
    POSITION.y.includes(elem),
  )

  if (hasBothPositions && hasPositionX && hasPositionY) {
    return true
  }

  return false
}

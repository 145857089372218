import { GaNotificationProduct } from '@ga/ui-components/notification-product'

import {
  buildNotificationEntityName,
  getAttributeColor,
  getAttributeUnit,
  getProductParams,
} from '~/modules/notifications/helpers/product'

import { CommonService } from './common.service'

/**
 * Наследуется от `CommonService` и предоставляет методы
 * для открытия уведомлений, связанных с продуктами.
 * Этот сервис предназначен для открытия уведомлений,
 * связанных с продуктами, с возможностью настройки внешнего вида
 * и содержимого уведомления на основе данных о продукте.
 */

export class ProductService extends CommonService {
  constructor(gaApp) {
    super(gaApp, false)
  }

  /**
   * Возвращает параметры для уведомления о продукте на основе текущего брейкпоинта
   * и ширины экрана для мобильных устройств.
   */
  get paramsProductNotification() {
    return getProductParams({
      currentBreakpoint: this.currentBreakpoint,
      widthMobile: this.widthMobile,
    })
  }

  // Возвращает объект со свойствами для компонента уведомления о продукте
  getPropsNotificationProduct({ product, type, text }) {
    const { brand, name, image = null, attributes } = product
    const size = this.notificationSize
    const entity = buildNotificationEntityName({ brand, name })

    return {
      image,
      size,
      type,
      text,
      hasCloseButton: false,
      testId: this.gaApp.getDataTestId('ga-notification-product'),
      product: {
        name: entity,
        attributes: {
          unit: getAttributeUnit(attributes?.units),
          color: getAttributeColor(attributes?.colors),
        },
      },
    }
  }

  // Открывает уведомление о продукте
  open({ product, type, text, listeners = {} }) {
    const props = this.getPropsNotificationProduct({
      product,
      type,
      text,
    })
    const params = this.paramsProductNotification

    this.show({
      data: {
        component: GaNotificationProduct,
        props: {
          ...props,
        },
        listeners,
      },
      params: {
        ...params,
        stacked: true,
      },
      clean: this.needClean,
    })

    this.needClean = false
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

/**
 * Возвращает z-index и методы для его бронирования/разбронирования
 * @param {string} uniqueId
 */
export const useZIndex = (uniqueId) => {
  const { $gaApp } = useContext()

  const zIndex = computed(() => {
    return $gaApp.stores.app.main.getZIndex({ id: uniqueId })
  })

  const reserveZIndex = () => {
    $gaApp.stores.app.main.reserveZIndex({ id: uniqueId })
  }

  const unreserveZIndex = () => {
    $gaApp.stores.app.main.unreserveZIndex({ id: uniqueId })
  }

  return {
    zIndex,
    reserveZIndex,
    unreserveZIndex,
  }
}

<template>
  <svg viewBox="0 0 16 14">
    <path
      fill="none"
      stroke-width="1"
      d="M7.562 2.142 8 2.57l.437-.43c.397-.389 1.315-1.086 2.412-1.38 1.057-.283 2.273-.196 3.433.941 1.157 1.135 1.24 2.317.956 3.342-.298 1.07-1.006 1.967-1.404 2.357l-3.472 3.406-1.946 1.91-.416.407-.416-.408-1.946-1.909-3.472-3.406C1.77 7.011 1.06 6.114.762 5.043.477 4.02.561 2.837 1.718 1.702 2.877.565 4.093.478 5.15.762c1.098.293 2.015.99 2.412 1.38Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-heart',
}
</script>

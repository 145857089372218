import { UserAccountBuilder } from '../../builders'
import { ACTION_TYPES, SCREEN_TYPES } from '../../constants'
import { BaseController } from '../base.controller/base.controller'

export class PurchasedProductsAnalytics extends BaseController {
  /**
   * Клик на кнопку "Перейти к покупкам" (если нет продуктов)
   */
  onClickBackToShopping() {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_BACK_TO_SHOPPING,
      screenType: SCREEN_TYPES.ACCOUNT_PURCHASED,
    })
  }
}

import { ApiService } from './api.service'
import { IteratorService } from './iterator.service'
import { MainService } from './main.service'
import { RibbonService } from './ribbon.service'
import { SymaphoreService } from './symaphore.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  iterator: new IteratorService(gaApp),
  ribbon: new RibbonService(gaApp),
  symaphore: new SymaphoreService(gaApp),
})

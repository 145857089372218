import { useContext } from '@nuxtjs/composition-api'

import { ref, unref } from 'vue'

import { useAnalytics } from '../../../../composable/use/analytics'

const SELECT_MOUSE_ENTER_DELAY = 250

export const useEvents = () => {
  const { $gaApp } = useContext()
  const selectMouseEnterTimeout = ref(null)

  const analytic = useAnalytics()

  const getIsTablet = () => {
    return !$gaApp.stores.app.main.withHover
  }

  const updateSelectedItem = (item) => {
    if (item.parent) {
      $gaApp.services.header.nav.expandNavItem(item)
    } else {
      $gaApp.services.header.nav.closeNavItems()
    }
  }

  const toggleSelectedItem = (item) => {
    if (!item.parent) {
      return
    }

    const newItemId = item.id
    const oldItemId = $gaApp.stores.header.nav.expandedNavItemsIds[0]

    if (newItemId === oldItemId) {
      $gaApp.services.header.nav.closeNavItems()
    } else {
      updateSelectedItem(item)
    }
  }

  const resetSelectTimeout = (item) => {
    unsetSelectTimeout()
    setSelectTimeout(item)
  }

  const unsetSelectTimeout = () => {
    clearTimeout(unref(selectMouseEnterTimeout))
  }

  const setSelectTimeout = (item) => {
    selectMouseEnterTimeout.value = setTimeout(
      () => updateSelectedItem(item),
      SELECT_MOUSE_ENTER_DELAY,
    )
  }

  const onItemKeyboardClick = (item, index) => {
    if (index !== 0) {
      // временный фикс, чтобы открывалось подменю только у категорий
      $gaApp.services.header.nav.closeNavItems()
    } else {
      toggleSelectedItem(item)
    }
  }

  const onItemMouseEnter = (item, index) => {
    if (getIsTablet()) return

    if (index !== 0) {
      // временный фикс, чтобы открывалось подменю только у категорий
      $gaApp.services.header.nav.closeNavItems()
    } else {
      resetSelectTimeout(item)
    }
  }

  const onItemMouseLeave = () => {
    unsetSelectTimeout()
  }

  const onMouseLeave = () => {
    unsetSelectTimeout()
    $gaApp.services.header.nav.closeNavItems()
  }

  const onItemClick = (item) => {
    const { nuxt, link } = item

    if (nuxt && link) {
      analytic.onMenuItemClick($gaApp.stores.header.nav.nav, item)

      $gaApp.router.push(link)
      return
    }

    if (getIsTablet()) {
      if ($gaApp.stores.header.nav.navExtended) {
        $gaApp.services.header.nav.closeNavItems()
      } else {
        $gaApp.services.header.nav.expandNavItem(item)
      }
    }
  }

  return {
    onMouseLeave,
    onItemMouseLeave,
    onItemMouseEnter,
    onItemKeyboardClick,
    onItemClick,
  }
}

<template>
  <svg viewBox="0 0 32 30">
    <path
      fill="none"
      d="M3.626.999h7.816C10.59 3.557 9.17 8.674 8.884 13.08h5.685V29H.5V17.344C.5 10.095 2.347 3.984 3.626 1Zm16.77 0h7.815c-.852 2.558-2.273 7.675-2.558 12.081h5.685V29h-14.07V17.344c0-7.249 1.848-13.36 3.127-16.345Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-quote',
}
</script>

import { computed, toRefs, unref } from 'vue'

import { GaIcon } from '@ga/ui-components/icon'

import { GaImageResponsive } from '~/components/image-responsive'

import { RESOURCE_TYPE, VIEW } from './scripts/const'

const GaLottieAnimationByUrl = () =>
  import(
    /* webpackChunkName: '@ga/ui-components/lottie' */
    '@ga/ui-components/lottie-animation-by-url/index.js'
  ).then((module) => module.GaLottieAnimationByUrl)

// @vue/component
export default {
  name: 'ga-header-nav-minimized-item-icon',

  components: {
    GaIcon,
    GaImageResponsive,
    GaLottieAnimationByUrl,
  },

  props: {
    icon: {
      type: Object,
      default: () => ({}),
    },
    view: {
      type: String,
      validator: (value) => Object.values(VIEW).includes(value),
      default: VIEW.PREPEND,
    },
  },

  setup(props) {
    const { icon } = toRefs(props)

    const isLottie = computed(() => {
      return unref(icon).resourceType === RESOURCE_TYPE.LOTTIE
    })

    const isImage = computed(() => {
      return unref(icon).resourceType === RESOURCE_TYPE.IMAGE
    })

    const source = computed(() => {
      return unref(icon).resourceUrl
    })

    return {
      isLottie,
      isImage,
      source,
    }
  },
}

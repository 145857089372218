import {
  EVENT,
  EVENT_CATEGORY,
  GTM_SELECT_DESIGN_TYPES,
} from '../../../constants'

export class GtmSelectDesignTypeModel {
  constructor({ type }) {
    this.event = EVENT.USER_INTERACTION
    this.event_category = EVENT_CATEGORY
    this.action_type = 'select_design_type'
    this.type = GTM_SELECT_DESIGN_TYPES[type]
  }
}

import { computed, ref } from 'vue'
import { IMask } from 'vue-imask'

import { PHONE_FORMAT, PHONE_INITIAL } from '../../../constants'

/**
 * Возвращает поля необходимые для работы с масками
 * @param {Object} obj
 * @param {Ref<String>} obj.phoneCodeActive // Значение phone из props
 */

export function useMasks({ phoneCodeActive }) {
  const maskNational = ref()
  initMaskNational()

  const maskInternational = computed(() => {
    const mask = phoneCodeActive.value.masks[PHONE_FORMAT.INTERNATIONAL]
    return IMask.createMask({ mask })
  })

  function initMaskNational() {
    maskNational.value = IMask.createMask({
      mask: phoneCodeActive.value.masks[PHONE_FORMAT.NATIONAL],
      lazy: true,
    })

    maskNational.value.resolve(PHONE_INITIAL)
  }

  return {
    maskNational,
    maskInternational,
    initMaskNational,
  }
}

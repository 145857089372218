import { useContext } from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'

import { unref } from 'vue'

import { useApp } from './app'

/**
 * Функция для предварительной загрузки URL-адресов при клике на ссылку.
 *
 * @param {Object} item - Объект ссылки.
 */
export const usePrefetchRedirect = ({ delay = 200, urlKey = 'url' } = {}) => {
  const { $gaApp } = useContext()
  const { appWithHover } = useApp()

  const prefetchedUrls = []

  const onPrefetchUrl = (item) => {
    const url = item?.[urlKey] || item?.href

    const invalidUrl = !url || url === '/'

    // Предварительно загружаем, только если URL валиден и не находится в массиве.
    if (invalidUrl || prefetchedUrls.includes(url)) return

    prefetchedUrls.push(url)

    // Публикуем событие в шину событий.
    $gaApp.eventBus.publish('module/app/redirect/prefetch', {
      url,
    })
  }

  const onPrefetchUrlDebounced = unref(appWithHover)
    ? debounce((item) => onPrefetchUrl(item), delay)
    : () => {}

  return {
    onPrefetchUrl,
    onPrefetchUrlDebounced,
  }
}

import {
  BubbleClick,
  FastFilterExpressChange,
  FastFilterSortingChange,
  FiltersApplied,
  FiltersModalOpened,
} from '../../builders'
import { FILTER_KEY } from '../../constants'
import { BaseController } from '../base.controller/base.controller'

const { EXPRESS_DELIVERY, SORTING } = FILTER_KEY

export class FiltersAnalytics extends BaseController {
  /**
   * Открытие модалки фильтров
   */
  onOpenedFiltersModal() {
    const data = {
      itemListName: this.getItemListName(),
    }

    this.send(FiltersModalOpened, data)
  }

  /**
   * Клик по бабблу-категории
   */
  onClickBubble(bubble) {
    const itemListName = this.getItemListName()
    const params = this.getAnalyticData() || {} // TODO нужен рефакторинг для приведения к виду {params}, а не отдельно itemListName и другие поля

    const { url, bubbleName, bubblePosition } = this.getBubbleData(bubble)

    const data = {
      ...params,
      url,
      itemListName,
      bubbleName,
      bubblePosition,
    }

    this.send(BubbleClick, data)
  }

  /**
   * Переключение быстрого-фильтра экспресс-доставки
   */
  onChangeFastFilterExpress(expressDeliveryToggleValue) {
    const itemListName = this.getItemListName()
    const { formattedName } = this.getFilterAnalyticByKey(EXPRESS_DELIVERY)

    const data = {
      itemListName,
      formattedName,
      expressDeliveryToggleValue,
    }

    this.send(FastFilterExpressChange, data)
  }

  /**
   * Переключение быстрого-фильтра сортировки
   */
  onChangeFastFilterSorting(sortingValue) {
    const itemListName = this.getItemListName()
    const { formattedName } = this.getFilterAnalyticByKey(SORTING)

    const data = {
      itemListName,
      formattedName,
      sortingValue,
    }

    this.send(FastFilterSortingChange, data)
  }

  /**
   * Применили фильтры
   */
  onAppliedFilters() {
    const itemListName = this.getItemListName()
    const analytics = this.getSelectedFiltersAnalytics()

    const data = {
      itemListName,
      analytics,
    }

    this.send(FiltersApplied, data)
  }
}

// import { TYPE } from '../constants'

export class BaseBuilder {
  constructor(models, data, country) {
    this.data = data
    this.models = models
    this.country = country
  }

  build() {
    const result = new Map()

    this.models.forEach((Model, key) => {
      result.set(key, new Model(this.data, this.country))
    })

    return result
  }
}

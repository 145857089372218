import isString from 'lodash/isString'

/**
 * Возвращает "чистую" строку для поиска.
 *
 * Возвращает строку без пробельных символов и в нижнем регистре. Если значение не является строкой или строка пустая, то возвращает пустую строку.
 * @param {string | null | undefined} value строка, которую необходимо очистить
 * @returns {string}
 */
export const clearForSearch = (value) => {
  if (!value || !isString(value)) {
    return ''
  }

  return value.replace(/\s*/g, '').toLowerCase()
}

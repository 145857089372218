export const getters = () => ({
  asObject() {
    const result = {}
    this.items.forEach((item, index) => {
      result[item.id] = { index, ...item }
    })

    return result
  },

  plainItems() {
    return this.items.map((item, index) => ({ id: item.id, type: item.type }))
  },
})

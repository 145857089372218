import { useContext } from '@nuxtjs/composition-api'
import Swiper from 'swiper/swiper-bundle.esm.browser'

import { computed, onMounted, onUnmounted, ref, unref, watch } from 'vue'

const SLIDES_PER_GROUP = 3

const SLIDES_PER_VIEW = 12
const SLIDES_PER_VIEW_MOBILE = 'auto'
const SLIDES_PER_VIEW_TABLET = 'auto'

export const useSlider = ({
  sliderEl,
  cssClasses,
  prevBtnEl,
  nextBtnEl,
  items,
}) => {
  const { $gaApp } = useContext()
  const slider = ref()
  const breakpoints = $gaApp.stores.app.window.breakpoints
  const isDesktop = computed(() => $gaApp.mq('desktop-small+'))

  const initSlider = () => {
    if (!unref(sliderEl)) return

    slider.value = new Swiper(unref(sliderEl), {
      wrapperClass: cssClasses.wrapper,
      slideClass: cssClasses.slide,
      spaceBetween: 10,
      mousewheel: {
        forceToAxis: true,
      },
      navigation: {
        disabledClass: cssClasses.navDisabledClass,
        hiddenClass: cssClasses.navHiddenClass,
        lockClass: cssClasses.navLockClass,
        nextEl: unref(nextBtnEl),
        prevEl: unref(prevBtnEl),
      },
      breakpoints: {
        [breakpoints['mobile-tiny'].width]: {
          slidesPerView: SLIDES_PER_VIEW_MOBILE,
          centerInsufficientSlides: false,
        },
        [breakpoints['tablet-small'].width]: {
          slidesPerView: SLIDES_PER_VIEW_TABLET,
          centerInsufficientSlides: true,
        },
        [breakpoints['desktop-small'].width]: {
          centerInsufficientSlides: true,
          slidesPerGroup: SLIDES_PER_GROUP,
          slidesPerView: SLIDES_PER_VIEW,
        },
      },
    })
  }

  const slideNext = () => {
    slider.value.slideNext()
  }

  const slidePrev = () => {
    slider.value.slidePrev()
  }

  const destroySlider = () => {
    slider.value.destroy()
    slider.value = null
  }

  const isDesktopSwiperMode = computed(() => items.length > SLIDES_PER_VIEW)

  onMounted(() => {
    if (isDesktop.value && isDesktopSwiperMode.value) {
      initSlider()
    }
  })

  onUnmounted(() => {
    if (unref(slider)) {
      destroySlider()
    }
  })

  watch(isDesktop, (value) => {
    if (!isDesktopSwiperMode.value) {
      return
    }
    value ? initSlider() : destroySlider()
  })

  // В родителе, при вызове данного use, значение sliderEl
  // будет изменяться при смене значения переменной isSkeleton.
  // Когда isSkeleton будет отключен, sliderEl начнет рендериться.
  // После рендера значение переменной sliderEl будет изменено из undefined на нужный DOM элемент,
  // в этот момент необходимо проинициализировать слайдер
  watch(sliderEl, (value) => {
    // Слайдер еще не проинициализирован, десктоп расширение, элементов более SLIDES_PER_VIEW (12)
    const isNeedSliderInit =
      !slider.value && isDesktop.value && isDesktopSwiperMode.value

    if (value && isNeedSliderInit) {
      initSlider()
    }
  })

  return {
    slider,
    slideNext,
    slidePrev,
    isDesktopSwiperMode,
  }
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ContentElementBaseModel } from '../gtm-ga4-content-element-base.model/gtm-ga4-content-element-base.model'

export class GtmGa4ContentElementShowModel extends GtmBaseModel {
  constructor({ blockName, slideName, itemListId, creativeSlot } = {}) {
    super({ event: EVENT.GTM.eventView })

    this.ecommerce = new GtmGa4ContentElementBaseModel({
      blockName,
      slideName,
      itemListId,
      creativeSlot,
    })
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ModalFiltersOpened extends GtmBaseModel {
  constructor(data) {
    super()

    const { itemListName } = data

    this.action_type = 'open_filters'
    this.item_list_name = itemListName
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4TooltipViewModel extends GtmBaseModel {
  constructor(data = {}) {
    super({ eventCategory: 'tooltip' })

    this.action_type = 'show_tooltip'
    this.screen_type = data.type
    this.action_label = data.text
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref, watch } from 'vue'

import { TABS } from '../../../../../constants'

/**
 * @param {Ref<import('../../../../../constants').Tabs>} selectedTab
 * @param {Ref<boolean>} minimized
 */
export const useNav = (
  selectedTab,
  setSelectedTab,
  clearSelectedTab,
  minimized,
) => {
  const { $gaApp } = useContext()

  const navWasActive = ref(false)

  const navExtended = computed(() => {
    return $gaApp.stores.header.nav.navExtended
  })

  const navActive = computed(() => {
    return unref(selectedTab) === TABS.NAV
  })

  const navHeightStyle = computed(() => {
    const { innerHeight } = $gaApp.stores.app.window.windowSizeLazy
    const height = `${innerHeight}px`

    return unref(minimized) ? { height } : null
  })

  watch(navActive, (value) => {
    navWasActive.value = unref(navWasActive) || value
  })

  watch(navExtended, (value) => {
    const isNavExtended = value
    const isNotMinimized = !unref(minimized)

    if (isNavExtended) {
      setSelectedTab(TABS.NAV)

      return
    }

    if (isNotMinimized) {
      clearSelectedTab()
    }
  })

  return {
    navExtended,
    navActive,
    navWasActive,
    navHeightStyle,
  }
}

import { useContext } from '@nuxtjs/composition-api'
import { createEventHook } from '@vueuse/core'

import { MODAL_NAME } from '../constants'

// хуки открытия и закрытия модалок
export const useSuperModalHooks = () => {
  const { $gaApp } = useContext()

  const openSuperModal = createEventHook()
  const closeSuperModal = createEventHook()
  const openSuperModalAuth = createEventHook()
  const openSuperModalCart = createEventHook()
  const openSuperModalAdult = createEventHook()
  const openSuperModalQuickPreview = createEventHook()

  $gaApp.eventBus.subscribe('module/modal/open', (modal) => {
    openSuperModal.trigger(modal)
  })

  $gaApp.eventBus.subscribe('module/modal/close', (modal) => {
    closeSuperModal.trigger(modal)
  })

  $gaApp.eventBus.subscribe(`module/modal/open/${MODAL_NAME.AUTH}`, (data) => {
    openSuperModalAuth.trigger(data)
  })

  $gaApp.eventBus.subscribe(`module/modal/open/${MODAL_NAME.CART}`, (data) => {
    openSuperModalCart.trigger(data)
  })

  $gaApp.eventBus.subscribe(`module/modal/open/${MODAL_NAME.ADULT}`, (data) => {
    openSuperModalAdult.trigger(data)
  })

  $gaApp.eventBus.subscribe(
    `module/modal/open/${MODAL_NAME.QUICK_PREVIEW}`,
    (data) => {
      openSuperModalQuickPreview.trigger(data)
    },
  )

  return {
    onOpenSuperModal: openSuperModal.on,
    onOpenSuperModalAuth: openSuperModalAuth.on,
    onOpenSuperModalCart: openSuperModalCart.on,
    onOpenSuperModalAdult: openSuperModalAdult.on,
    onOpenSuperModalQuickPreview: openSuperModalQuickPreview.on,
    onCloseSuperModal: closeSuperModal.on,
  }
}

import { buildSymbolsRegExp, withMessage } from '../utils'

const generateMessage = ({
  allowSpaces = false,
  allowPunctuation = false,
} = {}) => {
  if (allowSpaces && !allowPunctuation) {
    return 'app.validation.cyrillic.spaces'
  }

  if (!allowSpaces && allowPunctuation) {
    return 'app.validation.cyrillic.punctuation'
  }

  return 'app.validation.cyrillic.alpha'
}

export default (
  options = { allowSpaces: false, allowPunctuation: false },
  message = undefined,
) => {
  const regExp = buildSymbolsRegExp('а-яё', {
    ...options,
    wholeString: true,
    count: '+',
    flags: 'i',
  })

  const validator = (value) => regExp.test(value)

  const params = {
    type: 'cyrillic',
    message: generateMessage(options),
  }

  return withMessage(validator, params)(message)
}

<template>
  <svg fill="none" viewBox="0 0 26 32">
    <g clip-path="url(#checkout-filter-button-icon-a)">
      <path
        fill="#000"
        stroke="currentcolor"
        d="M24.294 9.5 16.5 23 8.706 9.5h15.588z"
      />
    </g>
    <defs>
      <clipPath id="checkout-filter-button-icon-a">
        <path fill="currentcolor" d="M0 0h25v32H0z" transform="translate(.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-filter-button-icon',
}
</script>

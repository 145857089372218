import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4FilterStreamClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const { eventCategory, actionType, filters } = data

    this.event_category = eventCategory
    this.action_type = actionType

    this.filters = filters
  }
}

import {
  useDebounceFn,
  useResizeObserver as vueUseResizeObserver,
} from '@vueuse/core'

import { ref } from 'vue'

export const useResizeObserver = ({
  itemsWrapperRef,
  updateItemWidth,
  updateControlStyle,
  resetItemsSize,
}) => {
  const updateItemWidthDebounce = useDebounceFn(updateItemWidth, 300)
  const updateControlStyleDebounce = useDebounceFn(updateControlStyle, 300)

  const resizeObserver = ref(null)
  const resizeObserverWidth = ref(null)

  const initResizeObserver = () => {
    resizeObserver.value = vueUseResizeObserver(itemsWrapperRef, (entries) => {
      const [entry] = entries

      if (entry.contentRect.width !== resizeObserverWidth.value) {
        resetItemsSize()
        updateItemWidthDebounce()
        updateControlStyleDebounce()
      }

      resizeObserverWidth.value = entry.contentRect.width
    })
  }

  initResizeObserver()
}

export class NetworkService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает текущий статус интернет-соединения
   * @param {boolean} status
   */
  setNetworkStatus(status) {
    this.gaApp.stores.search.main.isOnline = status
  }

  /**
   * Меняет на true значение флага,
   * указывающего, что все chunk'и были подгружены
   */
  setFirstRenderCompleted() {
    this.gaApp.stores.search.main.isFirstRenderCompleted = true
  }
}

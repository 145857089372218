export class IteratorService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.currentPromise = {
      value: null,
      clear: null,
    }
  }

  init(iterable) {
    const iterator = this.createIterator(iterable)

    return this.proxyIterator(iterator)
  }

  reset() {
    this.currentPromise.clear && this.currentPromise.clear()
  }

  /**
   * Прокси над итератором
   * Нужно, что бы резолвить iterator.next() по истечении setTimout()
   */
  proxyIterator(iterator) {
    const self = this

    return new Proxy(iterator, {
      get(target, key) {
        return function (delay = 0) {
          if (self.currentPromise.value) {
            return null
          }

          self.currentPromise.value = new Promise((resolve) => {
            const timeoutId = setTimeout(() => resolve(target[key]()), delay)

            // Вызовем, когда компонент будет удален
            self.currentPromise.clear = () => {
              clearTimeout(timeoutId)
              resolve()
            }
          })

          // Очищаем внутреннее состояние, когда промис зарезолвится
          self.currentPromise.value.then((result) => {
            self.currentPromise.value = null
            self.currentPromise.clear = null

            return result
          })

          return self.currentPromise.value
        }
      },
    })
  }

  /**
   * Бесконечный итератор по массиву
   * Нужно, для асинхронной навигации по массиву
   */
  createIterator(iterable = []) {
    let cursor = 0
    const size = iterable.length

    return {
      [Symbol.iterator]() {
        return this
      },

      next: () => {
        const result = {
          value: iterable[cursor++],
          done: false, // никогда не заканчивается
        }

        if (cursor === size) {
          cursor = 0
        }

        return result
      },
    }
  }
}

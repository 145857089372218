export const LISTENER = {
  CART_WIDGET: 'PUT_IN_CART_FROM_WIDGET',
  CART_STREAMER: 'PUT_IN_CART_REQUEST_ACCEPTED',
  CART_RECORD: 'STREAM_RECORD_PUT_IN_CART_FROM_WIDGET',
  CLOSE: 'CHAT_OVERLAY_CLOSED',
  DIALOG_CREATED: 'DIALOG_CREATED',
}

export const EYEZON_ROOT_ID = 'eyezon-root'
export const EYEZON_DEV_CDN =
  'https://storage.googleapis.com/eyezonwidgetv2/static/js/eyezonwidget.js'

import { initialData, STATE } from '../../constants/common'

export const _state = () => ({
  [STATE.PRODUCT]: initialData(),
  [STATE.DELIVERY]: initialData(),
  hasLoadingDescription: false,

  attributesSelected: {},
  slots: {},
})

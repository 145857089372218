export const TYPE = {
  GTM_4: 'gtm_4',
  GTM_UA: 'gtm_ua',
  MINDBOX: 'mindbox',
  ADVCAKE: 'advcake',
  GAA: 'ga_analytics',
  DIGI: 'digi_analytics',
}

export const EVENT = {
  VIEW: {
    GA4: 'view_cart',
  },
  ADD_TO_CART: {
    GA4: 'add_to_cart',
    GAUA: 'addToCart',
    ADVCAKE: 10,
    MINDBOX: {
      ru: 'Website.AddProductToCartItemList.JS',
      by: 'Website.AddProductToCartItemList.JS',
      kz: 'Website.AddProductToCartItemList.JS',
      qa: 'WebsiteQA.AddProductToCartItemList.JS',
    },
  },
  REMOVE_FROM_CART: {
    GA4: 'remove_from_cart',
    GAUA: 'removeFromCart',
    ADVCAKE: 12,
    MINDBOX: {
      ru: 'Website.RemoveProductFromCartItemList.JS',
      by: 'Website.RemoveProductFromCartItemList.JS',
      kz: 'Website.RemoveProductFromCartItemList.JS',
      qa: 'WebsiteQA.RemoveProductFromCartItemList.JS',
    },
  },
  REMOVE_FROM_CART2: {
    GA4: 'remove_from_cart',
  },
  CHANGE_QYANTITY_IN_CART: {
    ADVCAKE: 11,
  },
  FAVORITE: {
    GA4: 'add_to_wishlist',
  },
  COUPON: {
    GA4: 'user_interaction',
  },
  WARNING: {
    GA4: 'user_interaction',
  },
  MODAL_OPEN: {
    GA4: 'modal_open',
  },
  SHARE_CART_CLICK: {
    GA4: 'user_interaction',
  },
  SHARE_CART_OPEN: {
    GA4: 'view_item_list',
  },
  SHARE_PRODUCT_CLICK: {
    GA4: 'select_item',
  },
  SHARE_ADD_TO_CART: {
    GA4: 'add_to_cart',
    MINDBOX: 'Website.AddProductToCartItemList.JS',
  },
  SHARE_SET_CART: {
    MINDBOX: 'Website.SetCartItemList.JS',
  },
}

export const PLACEMENT_TYPES = {
  CART: 'корзина',
}

export const CHUNK_SIZE_ANALYTICS = 1

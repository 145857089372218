import { CURRENCY_CODE } from '../../../constants'

export class GtmBaseModel {
  constructor({ event }) {
    this.event = event
  }

  getCurrency(currency) {
    return CURRENCY_CODE[currency] || currency
  }
}

import {
  ButtonCategoryClickBuilder,
  ButtonProductClickBuilder,
  CardClickBuilder,
  CardViewBuilder,
  FullCardViewBuilder,
  GetListingAnalyticsBuilder,
  MainActionClickBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

import { MODULE_TYPES, PAGE_TYPES } from './../../constants'

export class PromoAnalytics extends BaseController {
  /**
   * Клик по кнопке с переходом на plp или pdp.
   */
  onContentButtonClick({
    itemId,
    type,
    pageType,
    creativeName,
    creativeSlot,
    itemListId,
    itemListName,
    promotionName,
    url,
  }) {
    if (!PAGE_TYPES[type]) {
      return
    }

    if (!PAGE_TYPES[type].includes(pageType)) {
      return
    }

    switch (type) {
      case MODULE_TYPES.PDP:
        this.send(ButtonProductClickBuilder, {
          creativeName,
          creativeSlot,
          itemListId,
          itemListName,
          promotionName,
          itemId,
        })
        break
      case MODULE_TYPES.PLP:
      case MODULE_TYPES.BRANDS:
      case MODULE_TYPES.PROMO:
        this.send(ButtonCategoryClickBuilder, {
          creativeName,
          creativeSlot,
          itemListId,
          itemListName,
          promotionName,
          url,
        })
        break
      default:
        return null
    }
  }

  /**
   * запрос за данными аналитики
   */
  async getAnalyticByUrl(data) {
    const results = await this.get(GetListingAnalyticsBuilder, data)

    return results.reduce((accumulator, current) => {
      const { key, data } = current.value

      accumulator[key] = data
      return accumulator
    }, {})
  }

  /**
   * Клик по кнопке "перейти к ..." на странице с детальным описание акции/КД
   */
  onMainActionClick({ eventCategory, actionLabel, buttonText, buttonLink }) {
    this.send(MainActionClickBuilder, {
      eventCategory,
      actionLabel,
      buttonText,
      buttonLink,
    })
  }

  /**
   * Показ карточки акций/кд.
   */
  onCardView({ name, position, promotionName }) {
    this.send(CardViewBuilder, {
      name,
      position,
      promotionName,
    })
  }

  /**
   * Показ детальной карточки акций/кд.
   */
  onFullCardView({ type, title }) {
    this.send(FullCardViewBuilder, {
      title,
      type,
    })
  }

  /**
   * Клик по карточке акций/кд.
   */
  onCardClick({ name, position, promotionName }) {
    this.send(CardClickBuilder, {
      name,
      position,
      promotionName,
    })
  }
}

/**
 * @typedef {Object} Time
 * @property {Number} hour - час (0 - 23)
 * @property {Number} minute - минута (0 - 59)
 */

/**
 * Возвращает объект времени по дате
 * @param {Date} date - дата
 * @returns {Time}
 */
const getTimeObjectByDate = (date) => {
  return {
    hour: date.getHours(),
    minute: date.getMinutes(),
  }
}

export { getTimeObjectByDate }

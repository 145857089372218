import { ACTIONS } from '~/modules/home/analytics/constants'
import { GtmBaseModel } from '~/modules/home/analytics/models/gtm/gtm-base.model/gtm-base.model'

export class GtmGa4StoriesViewedModel extends GtmBaseModel {
  constructor({
    eventCategory,
    name,
    actionType,
    state,
    pages,
    pagesViewed,
    pagesView,
    lastPagesId,
    lastPagesPosition,
    sound,
    firstStories,
  }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = eventCategory
    this.action_type = actionType
    this.name = name // название сторис

    /**
     * каким способом закончили просмотр сторис:
     * "close" - закрыли нажатием на "крестик"
     * "swipe" - свайпнули на другой сторис
     * "complete" - закончили просмотр последнего слайда
     */
    this.state = state

    /** кол-во страниц в сторис */
    this.pages = pages

    /** кол-во уникальных полных просмотров слайдов */
    this.pages_viewed = pagesViewed

    /** кол-во уникальных пропусков слайдов (тапнули или нажали клавишу вправо) */
    // this.skip_pages = skipPages

    /** кол-во просмотров слайдов (просмотрели + скипнули) */
    this.pages_view = pagesView

    /** id слайда на котором произошло событие */
    this.last_pages_id = lastPagesId

    /** позиция слайда на котором произошло событие. Нумерация начинается с единицы. */
    this.last_pages_position = lastPagesPosition

    /** звук вкл./выкл. "true" - включен, "false" - выключен */
    this.sound = sound

    /** первый или последующий сторис. "true" - первый, "false" - последующий.
     * Первым считается сторис по которому кликнули в списке.
     * Последующим считается сторис, который открылся после свайпа на следующий сторис
     * или автоматически началось воспроизведение следующего сторис. */
    this.first_stories = firstStories
  }
}

import { computed, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { useItemEventsEmitters } from '../../../../../helpers/nav'

// @vue/component
export default {
  name: 'ga-header-nav-full-item',

  components: {
    GaAction,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { item, selected } = toRefs(props)

    const mods = computed(() => {
      const { parent } = unref(item).mods

      return {
        parent,
        selected: unref(selected),
      }
    })

    const style = computed(() => {
      return (
        unref(item).viewOptions?.textColor &&
        `--header-nav-item-color: ${unref(item).viewOptions.textColor}`
      )
    })

    const { onKeyDown, onMouseEnter, onMouseLeave, onClick } =
      useItemEventsEmitters(item, emit)

    return {
      mods,
      style,

      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onClick,
    }
  },
}

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async updateUserLanguage(data = {}) {
    const { url } = this.endpoints.updateUserLanguage()

    try {
      const response = await this.gaApp.api.request().put(url, data)
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getData(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getData()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getAddress(params = {}) {
    const { url } = this.endpoints.getAddress()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getAddressV2(params = {}) {
    const { url } = this.endpoints.getAddressV2()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async setProofAge(data = {}) {
    const { url } = this.endpoints.setProofAge()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getInfoShort() {
    const { url } = this.endpoints.getInfoShort()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getInfoFull() {
    const { url } = this.endpoints.getInfoFull()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getMobileInfoFull() {
    const { url } = this.endpoints.getMobileInfoFull()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { ACTIONS, SCREEN_TYPES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { OrderProductModel } from '../order-product.model/order-product.model'

export class GtmGa4OrderProductClickModel extends GtmBaseModel {
  constructor(item) {
    super({ event: ACTIONS.CLICK.GA_4 })

    this.item_list_name = SCREEN_TYPES.ACCOUNT_ORDER
    this.value = item.price.actual.amount
    this.ecommerce = {
      items: [new OrderProductModel(item)],
    }
  }
}

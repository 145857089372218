import { ANCHOR_PREVIEW, SCROLL_HASH_PREFIX } from '../constants/listing'

/**
 *
Сервис предоставляет методы для работы с предварительным просмотром отзывов и взаимодействия с ними:

`getReviews({ productId = null } = {})` - асинхронный метод, который получает отзывы для указанного `productId`.

`toFormReview()` - метод, который перенаправляет пользователя на форму оставления отзыва.

`getHrefWithHash(reviewId)` - метод, который возвращает URL-адрес страницы списка отзывов с добавленным хэшем `reviewId`, если он указан.

`onClickLink({ idAnchor = ANCHOR_PREVIEW.BOTTOM } = {})` - метод, который обрабатывает клик по ссылке для открытия списка отзывов.

`filledStore(fetchedData, productId = null)` - метод, который заполняет store данными отзывов и статистикой, полученными из `fetchedData`.

`resetStore()` - метод, который сбрасывает store `preview` к начальному состоянию.

`getProductsForRating()` - метод, который получает варианты продуктов для указанного `productId` из store `preview`.
 */

export class PreviewService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getReviews({ productId = null } = {}) {
    if (!productId || !this.gaApp.features.get('isReviewAvailable')) {
      return
    }

    this.gaApp.stores.review.preview.productId = productId

    await this.gaApp.services.review.api.getPreviewReviews()

    const shouldCheckAvailableProduct = this.gaApp.stores.user.main.isAuthorized

    if (shouldCheckAvailableProduct) {
      await this.gaApp.services.review.api.checkAvailableProductForReview({
        productId,
      })
    }

    const hasReviews = this.gaApp.stores.review.statistic.allReviewsCount
    // прогрев кэша листинга отзывов
    if (hasReviews) {
      const url = this.gaApp.stores.review.preview.urlToListing
      this.gaApp.eventBus.publish('module/app/redirect/prefetch', {
        url,
      })

      this.gaApp.eventBus.publish('module/review/prefetch', {
        productId,
      })
    }
  }

  // редирект на форму оставлениия отзыва
  toFormReview() {
    const orderId = this.gaApp.stores.review.preview?.orderId
    const productId = this.gaApp.stores.review.preview?.productId

    if (!orderId || !productId) {
      return
    }

    this.gaApp.services.review.form.goToForm({ orderId, productId })
  }

  getHrefWithHash(reviewId) {
    const url = this.gaApp.stores.review.preview.urlToListing
    if (!reviewId) {
      return url
    }

    return `${url}${SCROLL_HASH_PREFIX}${reviewId}`
  }

  onClickLink({ idAnchor = ANCHOR_PREVIEW.BOTTOM } = {}) {
    // this.gaApp.services.app.scrollNavigation.setAnchor(idAnchor)

    const source = idAnchor === ANCHOR_PREVIEW.TOP ? 'pdp_top' : 'pdp_bottom'
    this.gaApp.analytics.modules.review.onClickOpenListing({ source })
  }

  filledStore(fetchedData, productId = null) {
    if (!fetchedData) {
      return
    }

    const { reviews, ...statisticData } = fetchedData
    this.gaApp.stores.review.preview.updateAt = Date.now()
    this.gaApp.stores.review.preview.data = fetchedData
    this.gaApp.services.review.statistic.filledStore(statisticData, productId)
  }

  resetStore() {
    this.gaApp.stores.review.preview.$reset()
  }

  getProductsForRating() {
    const productId = this.gaApp.stores.review.preview.productId

    this.gaApp.services.review.products.getProductsVariants({ productId })
  }
}

import { ref, unref, watch } from 'vue'

export const useRender = (minimized) => {
  const renderFull = ref(false)
  const renderMinimized = ref(false)

  const unwatchMinimized = watch(
    minimized,
    (value) => {
      if (unref(renderFull) && unref(renderMinimized)) {
        unwatchMinimized()
        return
      }

      if (value) {
        renderMinimized.value = true
      } else {
        renderFull.value = true
      }
    },
    { immediate: true },
  )

  return {
    renderFull,
    renderMinimized,
  }
}

import { DEDUPLICATION, MERCHANT_ID } from '../../../constants'
import { GdeSlonProductModel } from '../gdeslon-product.model/gdeslon-product.model'

export class GdeSlonModel {
  constructor({ id, userId, amount, categoryId, quantity }) {
    this.page_type = 'card'
    this.deduplication = DEDUPLICATION
    this.products = [new GdeSlonProductModel({ id, amount, quantity })]
    this.merchant_id = MERCHANT_ID

    if (categoryId) {
      this.category_id = categoryId
    }

    if (userId) {
      this.user_id = userId
    }
  }
}

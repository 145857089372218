import { doubleRAF } from '@ga/shared-browser'

import { TYPE } from '../../analytics/constants'
import { TARGET } from '../../constants/common'
import { CommonService } from '../common/_common.service'

/**
 * Этот сервис наследуется от CommonService и предоставляет различные методы,
 * связанные с продуктами и аналитикой на странице продукта (PDP).
 */

export class MainService extends CommonService {
  constructor(gaApp) {
    super(gaApp, TARGET.MAIN)
  }

  // Получает данные о магазинах ,используя репозиторий pdp.main.
  getCityStores(parameters = {}) {
    return this.gaApp.repositories.pdp.main.getCityStores(parameters)
  }

  // Получает данные о наличии товара на складах, используя репозиторий pdp.main.
  getStoresStock(parameters = {}) {
    return this.gaApp.repositories.pdp.main.getStoresStock(parameters)
  }

  // устанавливает обычную цветовую схему для заголовка.
  setHeaderRegular() {
    this.gaApp.services.header.theme.setHeaderRegularColorScheme()
  }

  // изменяет историю маршрутов, используя метод replaceState или другой указанный метод.
  changeRouteHistory({ url, method = 'replace' }) {
    const nameMethod = `${method}State`

    this.gaApp.services.app.router[nameMethod](window.history.state, '', url)
  }

  /**
   * получает данные о продукте по его идентификатору (entityId).
   * Устанавливает обычную цветовую схему заголовка и
   * перенаправляет на страницу ошибки 404, если произошла ошибка.
   * ручка с кэшированием данных
   */
  async getProductBase({ entityId: itemId }) {
    try {
      this.setHeaderRegular()
      const { data } = await this.getProductDataBase({ itemId })

      return data
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 404, message: error })
    }
  }

  // обновление геозависимых данных вариантов продукта
  async getProductAdditional({ entityId: itemId } = {}) {
    return await this.getProductDataAdditional({
      itemId,
    })
  }

  async getBenefits({ entityId: itemId } = {}) {
    return await this.gaApp.services.pdp.api.getBenefits({ itemId })
  }

  reloadProductData() {
    this.getProductDataAdditional()
    this.getBenefits()
  }

  /**
   * Делает запрос за данными слотов на основе URL-адреса бренда.
   *
   * @param {object} product — Текущий продукт
   * @return {boolean|Promise<Object>} — Возвращает данные о слотах.
   */
  getProductSlots(product) {
    if (!this.gaApp.features.get('pdpBrandzoneSlots')) {
      return false
    }

    return this.gaApp.services.pdp.api.getProductSlots(product)
  }

  // получает данные о доставке для указанного продукта.
  getDelivery({ itemId }) {
    return this.getDeliveryData({ itemId })
  }

  // Запрос данных из внутреннего сервиса аналитики
  async getInitialAnalytics(redirect) {
    const data = await this.gaApp.analytics.modules.pdp.onPageInit({
      itemId: redirect.entityId,
    })

    const gaAnalytics = data[TYPE.GAA] ? data[TYPE.GAA] : null

    this.gaApp.stores.pdp.main.analyticsData = gaAnalytics

    return gaAnalytics
  }

  // добавляет идентификатор продукта в список подписанных продуктов.
  addProductSubscribed(productId) {
    if (!this.gaApp.stores.pdp.main.productSubscribed.includes(productId)) {
      this.gaApp.stores.pdp.main.productSubscribed.push(productId)
    }
  }

  /**
   * выполняет определенные действия при монтировании компонента,
   * такие как прокрутка страницы вверх, проверка возраста пользователя
   * для 18+ контента, установка атрибутов продукта и отправка
   * аналитических данных.
   */
  initMount() {
    doubleRAF().then(() => {
      window?.scrollTo?.({ top: 0 })
    })

    if (this.productData.isAdult) {
      this.gaApp.services.user.main.checkIfAdult('product')
    }

    this.gaApp.services.pdp.attributes.setAttributesByHashFallback({
      variants: this.productData.variants,
    })

    this.gaApp.services.app.scheduler.postTask(() => {
      this.gaApp.analytics.modules.pdp.onMount(
        { view: this.view },
        this.gaApp.stores.pdp.main.analyticsData,
      )
    }, 'background')
  }
}

<template>
  <svg viewBox="0 0 22 22">
    <path
      stroke="none"
      d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11Z"
    />
    <path
      fill="#000"
      fill-rule="evenodd"
      stroke="none"
      d="M5.156 10.388a491.619 491.619 0 0 1 5.907-2.456c2.813-1.13 3.397-1.325 3.778-1.332.084-.001.272.019.393.114.102.08.13.188.144.265.014.076.03.249.017.384-.152 1.547-.812 5.298-1.147 7.03-.143.733-.422.979-.693 1.003-.588.052-1.035-.376-1.604-.736-.892-.564-1.395-.915-2.26-1.466-1-.636-.353-.986.217-1.557.15-.15 2.741-2.425 2.791-2.632.007-.026.012-.122-.047-.173-.059-.05-.146-.033-.21-.02-.089.02-1.513.93-4.272 2.727-.404.268-.77.398-1.098.392-.362-.008-1.058-.198-1.575-.36-.634-.2-1.138-.304-1.094-.643.022-.176.274-.356.753-.54Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-support-tg',
}
</script>

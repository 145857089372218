import { PAGINATION_BUTTON, STREAMS_LIMIT } from '../constants'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchHeaderImage() {
    const { data } = await this.gaApp.repositories.streamerce.main.headerImage()
    this.gaApp.stores.streamerce.main.headerImage = data.headerImage
  }

  async fetchStreams(page, lastStreamId, direction = PAGINATION_BUTTON.NEXT) {
    try {
      this.gaApp.services.streamerce.main.setPagination({
        direction,
        value: {
          pending: true,
          error: false,
        },
      })

      const params = {
        lastStreamId,
        limit: STREAMS_LIMIT * page,
      }

      const { data } =
        await this.gaApp.repositories.streamerce.main.getStreams(params)

      return data
    } catch {
      this.gaApp.services.streamerce.main.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.gaApp.services.streamerce.main.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  async getStreamsStatuses() {
    const { data } =
      await this.gaApp.repositories.streamerce.main.getStreamsStatuses()
    this.gaApp.stores.streamerce.main.statuses = data.statuses
  }
}

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Заполнение данных получения ЭПК и параметров реакций
   * @param {object} redirect данные переадресации
   */
  getReceivePageData(redirect) {
    const promises = [
      this.gaApp.services.giftCards.api.getDataReceivePage(redirect),
    ]

    if (this.gaApp.features.get('giftCardsReactions')) {
      promises.push(this.gaApp.services.giftCards.api.getReactionsConfig())
    }

    return Promise.all(promises)
  }

  /**
   * Установка текущего идентификатора ЭПК
   */
  setCardHash() {
    const cardNumberHash = this.gaApp.route.query.cardid

    this.gaApp.stores.giftCards.balance.cardNumberHash = cardNumberHash
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        case 'main':
          return await this.gaApp.services.giftCards.main.getMainPageData()

        case 'about':
          return await this.gaApp.services.giftCards.about.getAboutPageData()

        case 'receive':
          return await this.getReceivePageData(redirect)

        case 'receiveReturn':
          return await this.gaApp.services.giftCards.digitalReceiveReturn.getReceiveReturnPageData(
            redirect,
          )

        case 'balance':
          return this.setCardHash(redirect)

        case 'reactionReceive':
          return await this.gaApp.services.giftCards.api.receiveReaction(
            redirect,
          )

        default:
          break
      }
    } catch (error) {
      switch (redirect.pageType) {
        case 'receive':
        case 'reactionReceive':
          if (error.statusCode >= 500) {
            this.gaApp.redirectError({
              statusCode: 500,
              message: error.message,
            })
          } else {
            this.gaApp.redirectError({
              statusCode: error.statusCode,
              message: error.message,
            })
          }
          break

        default:
          this.gaApp.redirectError({ statusCode: 500, message: error.message })
      }
    }
  }
}

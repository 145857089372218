<template>
  <svg fill="none" stroke="none" viewBox="0 0 50 50">
    <path
      fill="#fff"
      d="M33.705 18.274c.522.676.964 1.41 1.318 2.188l-10.02 7.386-4.188-2.625v-3.158l4.188 2.625 8.702-6.416ZM16.541 25c0-.142.004-.282.01-.422l-2.537-.125a10.97 10.97 0 0 0 3.208 8.324l1.8-1.8A8.429 8.429 0 0 1 16.542 25ZM25 16.54c.142 0 .283.006.423.013l.127-2.539a10.969 10.969 0 0 0-8.328 3.208l1.8 1.8A8.43 8.43 0 0 1 25 16.541Zm0 16.92c-.141 0-.282 0-.423-.012l-.127 2.538a10.963 10.963 0 0 0 8.324-3.208l-1.796-1.8A8.434 8.434 0 0 1 25 33.46Z"
    />
    <path
      fill="#fff"
      d="m29.769 18.017 2.138-1.577a10.95 10.95 0 0 0-6.91-2.44v2.54a8.416 8.416 0 0 1 4.772 1.477ZM36 25a11.08 11.08 0 0 0-.173-1.971l-2.367 1.745V25a8.437 8.437 0 0 1-2.787 6.27l1.708 1.887A10.972 10.972 0 0 0 36 25Zm-11 8.46a8.442 8.442 0 0 1-6.27-2.787l-1.886 1.707A10.971 10.971 0 0 0 25 36v-2.54Zm-5.672-14.73-1.707-1.887A10.971 10.971 0 0 0 14 25h2.541a8.436 8.436 0 0 1 2.787-6.27Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-sber',
}
</script>

export class DigitalAiDesignRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Начинает генерацию изображения
   * @param {object} params данные для генерации
   * @param {string} params.prompt текст запроса для генерации через нейросеть
   */
  async startGeneration(params) {
    const { url } = this.endpoints.startGeneration()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получает параметры изображения после генерации
   * @param {string} yandexImageId идентификатор генерации
   */
  async getImage(yandexImageId) {
    const { url } = this.endpoints.getAiImage(yandexImageId)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получает данные конфигурации генерации дизайна через нейросеть
   */
  async getConfig() {
    const { url } = this.endpoints.getAiConfig()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

<template>
  <svg viewBox="0 0 60 60">
    <circle cx="30" cy="30" r="30" stroke="none" />
    <path
      fill="#fff"
      fill-opacity="1"
      stroke="none"
      d="m40 30-16.5 9.526V20.474L40 30Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-play',
}
</script>

import { toRefs } from 'vue'

import { useTransitionProps } from '@ga/use/transition-props'

import { MODE } from './scripts/const'
import { useTransitionName } from './scripts/use'

// @vue/component
export default {
  name: 'ga-modal-transition-content',

  props: {
    active: {
      type: Boolean,
      default: true,
    },

    duration: {
      type: Object,
      default: () => ({
        enter: 300,
        leave: 150,
      }),
    },

    mode: {
      type: String,
      default: MODE.OUT_IN,
      validator: (value) => Object.values(MODE).includes(value),
    },

    withShift: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const transitionProps = useTransitionProps()

    const { active, withShift } = toRefs(props)

    const { transitionName } = useTransitionName(active, withShift)

    return {
      transitionProps,
      transitionName,
    }
  },
}

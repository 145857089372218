// TODO: Перенести остальную логику по работе с продуктами

export class ProductService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  convertParamsToProducts(params) {
    const streamId = params.stream_id

    return params.products.map((product) => {
      const { id, qty } = product

      const result = {
        sku: id,
        quantity: Number(qty) || 1,
      }

      if (streamId) {
        result.analyticsDetails = {
          itemListName: 'streamerce',
          itemListId: streamId,
        }
      }

      return result
    })
  }

  async addItemsFromUrlParams(params) {
    const products = this.convertParamsToProducts(params)

    await this.gaApp.services.cart.api.addItems(products)
  }

  addAllItemsToFavorite() {
    const skus = [...this.gaApp.stores.cart.main.itemsId]

    return this.addItemsToFavorite(skus)
  }

  async addItemsToFavorite(skus) {
    this.gaApp.services.cart.api.setLoadingItems(skus)

    await this.gaApp.services.favorites.products.addItems(skus)

    this.gaApp.services.cart.api.setLoadingItems()
  }
}

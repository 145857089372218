var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{ref:"rootRef",class:_vm.b(_vm.mods),style:(_vm.style),attrs:{"data-test-id":_vm.$getDataTestId(_vm.testIds.root)},on:{"animationend":function($event){return _vm.onAnimationEnd($event)}}},[(_vm.showTitle || _vm.hasTopControls)?_c('header',{class:_vm.b('header')},[(_vm.showTitle)?_c('h2',{class:_vm.b('heading'),attrs:{"data-test-id":_vm.$getDataTestId(_vm.testIds.heading)}},[(!_vm.skeleton && _vm.title.text)?[(_vm.title.href)?_c('ga-action',{attrs:{"href":_vm.title.href,"nuxt":!_vm.isWebview},on:{"click":function($event){return _vm.$emit('click-title', _vm.title)}}},[_vm._t("title",function(){return [_vm._v("\n            "+_vm._s(_vm.title.text)+"\n          ")]},{"title":_vm.title.text})],2):[_vm._t("title",function(){return [_vm._v("\n            "+_vm._s(_vm.title.text)+"\n          ")]},{"title":_vm.title.text})]]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.hasTopControls)?_c('div',{class:_vm.b('controls')},[(_vm.showControlLeft)?_c('ga-product-slider-control',{class:_vm.b('control'),attrs:{"size":_vm.controls.size,"direction":"left","disabled":_vm.isControlLeftDisabled},on:{"click":function($event){return _vm.onControlLeftClick()}}}):_vm._e(),_vm._v(" "),(_vm.showControlRight)?_c('ga-product-slider-control',{class:_vm.b('control'),attrs:{"size":_vm.controls.size,"direction":"right","disabled":_vm.isControlRightDisabled},on:{"click":function($event){return _vm.onControlRightClick()}}}):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{ref:"itemsWrapperRef",class:_vm.b('items-wrapper')},[(_vm.hasSidesControls && _vm.showControlLeft)?_c('ga-product-slider-control',{class:_vm.b('control', {
          position: 'sides',
          direction: 'left',
        }),style:(_vm.controlStyle),attrs:{"size":_vm.controls.size,"direction":"left","disabled":_vm.isControlLeftDisabled},on:{"click":function($event){return _vm.onControlLeftClick()}}}):_vm._e(),_vm._v(" "),_c('client-only',[_c('div',{directives:[{name:"scroll-lock-scrollable",rawName:"v-scroll-lock-scrollable"},{name:"keep-scroll",rawName:"v-keep-scroll"}],class:[_vm.b('items', { type: 'fake' }), _vm.itemsClass]},_vm._l((_vm.fakeItems),function(item,index){return _c('div',{key:item.product?.itemId || index,class:_vm.b('item', {
              ...item.mods,
              direction: item.active && _vm.itemsDirection,
            })},[_c('div',{class:_vm.b('item-outer')},[_c('div',{class:_vm.b('item-inner'),style:(_vm.itemInnerStyle)},[(item.buttonText)?_c('ga-product-slider-link-card',_vm._b({},'ga-product-slider-link-card',_vm.externalLink,false)):(_vm.hasItemsAdapter)?_c('ga-product-slider-card-adapter',{attrs:{"product":item.product},scopedSlots:_vm._u([{key:"default",fn:function({ product }){return [_vm._t("item",null,{"item":{
                      ...item,
                      product,
                    },"index":index})]}}],null,true)}):_vm._t("item",null,{"item":item,"index":index})],2)])])}),0)]),_vm._v(" "),_c('div',{class:[_vm.b('items', { type: 'real' }), _vm.itemsClass],attrs:{"data-scroll-lock-scrollable":""}},_vm._l((_vm.realItems),function(item,index){return _c('div',{key:item.product?.itemId || index,class:_vm.b('item', {
            ...item.mods,
            direction: item.active && _vm.itemsDirection,
          }),attrs:{"data-test-id":_vm.$getDataTestId(_vm.testIds.item)}},[_c('div',{class:_vm.b('item-outer')},[_c('div',{ref:"itemsRef",refInFor:true,class:_vm.b('item-inner'),style:(_vm.itemInnerStyle)},[(item.buttonText)?_c('ga-product-slider-link-card',_vm._b({attrs:{"data-test-id":_vm.$getDataTestId(_vm.testIds.linkCard)},on:{"click":function($event){return _vm.$emit('click-link-card', _vm.externalLink)}}},'ga-product-slider-link-card',_vm.externalLink,false)):(_vm.hasItemsAdapter)?_c('ga-product-slider-card-adapter',{attrs:{"product":item.product},scopedSlots:_vm._u([{key:"default",fn:function({ product }){return [_vm._t("item",null,{"item":{
                    ...item,
                    product,
                  },"index":index,"itemListeners":_vm.getItemListeners(product, index)})]}}],null,true)}):_vm._t("item",null,{"item":item,"index":index,"itemListeners":_vm.getItemListeners(item.product, index)})],2)])])}),0),_vm._v(" "),(_vm.hasSidesControls && _vm.showControlRight)?_c('ga-product-slider-control',{class:_vm.b('control', {
          position: 'sides',
          direction: 'right',
        }),style:(_vm.controlStyle),attrs:{"size":_vm.controls.size,"direction":"right","disabled":_vm.isControlRightDisabled},on:{"click":function($event){return _vm.onControlRightClick()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
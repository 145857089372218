import { clearForSearch } from '../../utils'

export const getters = {
  currentStep: (state) => state.stepHistory.at(-1),

  firstStep: (state) => state.stepHistory[0],

  cardSavedOptions: (state) =>
    state.card.saved.map((card) => ({
      ...card,
      error: state.card.errors[card.id],
      name: card.id,
      removable: true,
    })),

  lastPayedOption: (state) => state.cardSavedOptions.at(-1),

  cardSelectedOption: (state) => {
    const { selectedId } = state.card

    if (selectedId) {
      return state.cardSavedOptions.find((saved) => selectedId === saved.id)
    }

    return state.lastPayedOption
  },

  sbpBankOptions: (state) =>
    state.sbp.banks.map((bank) => ({
      ...bank,
      label: bank.name,
      imageSrc: bank.logo,
    })),

  sbpSelectedBankOption: (state) => {
    const { selectedBankName } = state.sbp

    if (selectedBankName) {
      return state.sbpBankOptions.find((bank) => selectedBankName === bank.name)
    }

    return undefined
  },

  sbpBankClearQuery: (state) => clearForSearch(state.sbp.searchBankQuery),

  sbpFilteredBankOptions: (state) => {
    if (!state.sbpBankClearQuery) {
      return state.sbpBankOptions
    }

    return state.sbpBankOptions.filter((bank) => {
      const clearBankLabel = clearForSearch(bank.label)

      return clearBankLabel.includes(state.sbpBankClearQuery)
    })
  },
}

import { TYPE } from '../../constants'
import { GaAnalyticsBubbleClickModel, GtmGa4BubbleClick } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class BubbleClick extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4BubbleClick],
      [TYPE.GAA, GaAnalyticsBubbleClickModel],
    ])
    super(models, data)
  }
}

import { AdvcakeBaseModel } from '../advcake-base.model/advcake-base.model'
import { AdvcakeProductModel } from '../advcake-product.model/advcake-product.model'
import { ACTIONS } from '../../../constants'

export class AdvcakePageViewModel extends AdvcakeBaseModel {
  constructor(data) {
    const { product, products } = data

    super(ACTIONS.VIEW.ADVCAKE)

    this.user = {
      email: ''
    }

    this.currentCategory = {
      id: product.categoryId,
      name: product.categoryName,
    }

    this.products = products.map((product) => new AdvcakeProductModel(product))
  }
}

import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/ar'
import layoutsArQa from '~/locales/languages/layouts/ar-QA'

export default () => {
  return {
    ...merge(layouts, layoutsArQa),
  }
}

import { AUTH_MODAL_REASON_TO_OPEN } from '../constants'

export class UserService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get isAuthorized() {
    return this.gaApp.services.user.main.isAuthorized
  }

  subscribeOnUser() {
    this.gaApp.eventBus.subscribe(
      'module/auth/login',
      this.onUserLogin.bind(this),
    )
    this.gaApp.eventBus.subscribe(
      'module/auth/logout',
      this.onUserLogout.bind(this),
    )
  }

  onUserLogin({ meta }) {
    const { reason } = meta

    if (reason !== AUTH_MODAL_REASON_TO_OPEN.LOGIN_TO_CHECKOUT_BY_CART) {
      this.gaApp.services.cart.api.getData()
    }
  }

  onUserLogout() {
    this.gaApp.services.cart.api.getData()
  }
}

export class AddressesRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchAddressList() {
    const { url } = this.endpoints.getAddressList()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchAddressListV2(params) {
    const { url } = this.endpoints.getAddressListV2()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getAddressDefault() {
    const { url } = this.endpoints.getAddressDefault()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async setAddressDefault(data) {
    const { url } = this.endpoints.setAddressDefault()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async setAddressDefaultV2(data) {
    const { url } = this.endpoints.setAddressDefaultV2()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async updateAddress(data) {
    const { url } = this.endpoints.updateAddress()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async updateAddressV2(data) {
    const { url } = this.endpoints.updateAddressV2()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteAddress(addressId) {
    const { url } = this.endpoints.deleteAddress(addressId)

    try {
      const response = await this.gaApp.api.request().delete(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteAddressV2(params) {
    const { url } = this.endpoints.deleteAddressV2()

    try {
      const response = await this.gaApp.api.request().delete(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

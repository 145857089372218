<template>
  <svg viewBox="0 0 15 15">
    <circle
      cx="7.5"
      cy="7.5"
      r="7"
      fill="none"
      transform="rotate(-180 7.5 7.5)"
    />
    <path d="M2.3 12.7 12.7 2.3" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-block-sign',
}
</script>

/**
 * Возвращает строку, содержащую формат изображения без точки вначале.
 * '.jpg' => 'jpg'
 *
 * @param {string} ext формат изображения
 * @returns {string} формат изобаржения без точки
 */
export const removeDot = (ext) => {
  return ext.charAt(0) === '.' ? ext.slice(1) : ext
}

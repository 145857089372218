<template>
  <svg viewBox="0 0 30 20">
    <path fill="none" stroke-width="1.5" d="M10 1 1 9.978 10 19" />
    <path stroke-width="1.5" d="M1 10h29" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-back-arrow',
}
</script>

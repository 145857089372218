import { TYPE } from '../../constants'
import { GtmGenerateMoreDesignButtonClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class GenerateMoreDesignButtonClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGenerateMoreDesignButtonClickModel],
    ])
    super(models, data)
  }
}

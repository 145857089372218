import { clearForSearch } from '../../utils'

export const getters = {
  clearPromptText: (state) => clearForSearch(state.prompt.text),

  activeHintLayer: (state) =>
    state.prompt.hints.find(
      (hintLayer) =>
        !hintLayer.some((hint) => {
          const clearHint = clearForSearch(hint)

          return state.clearPromptText.includes(clearHint)
        }),
    ),

  isConfigLoaded: (state) =>
    state.generation.limit !== null && state.generation.limitMessage !== null,
}

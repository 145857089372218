export const getters = (gaApp) => ({
  addressesAsObject() {
    const result = {}
    this.addresses.forEach((item) => {
      result[item.addressId + (item.saveId || '')] = item
    })

    return result
  },

  defaultAddressId() {
    if (this.addresses.length === 1) {
      const address = this.addresses[0]
      return address.addressId + (address.saveId + '') || null
    }

    if (this.addresses.length > 1) {
      const address = this.addresses.find((item) => item.isDefaultAddress)
      return address.addressId + (address.saveId + '') || null
    }

    return null
  },
})

import { computed, unref } from 'vue'

import { isIOS } from '@ga/shared-browser'

import { useRootIntersection } from './root-intersection'

/**
 * Решает проблему краша страницы в IOS Safari, при рендриге большого списка карточек
 * Нужно для того, что бы сделать галерею прокручиваемой, когда галарея в области просмотра (только для IOS)
 *
 * @param {Ref<Element>} rootRef — ссылка на корневой элемент.
 * @return {Object} Объект с вычисляемым свойством "scrollable".
 */
export const useScrollable = (rootRef) => {
  const { intersected } = useRootIntersection(rootRef)

  const scrollable = computed(() => {
    if (process.client) {
      return unref(intersected) || !isIOS()
    }

    return false
  })

  return {
    scrollable,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

/**
 * @function useQueueModals
 * @description
 *   Hook для работы с очередью модалок.
 *   Он проксирует пропс `opened` (открыта ли модалка) в зависимости от состояния очереди модалок.
 *   Если очередь модалок включена, то проверяется:
 *   - была ли модалка открыта (isModalInQueue)
 *   - блокирует ли очередь открытие модалок (pendingFillModals)
 * @param {Object} props
 * @param {String} [props.modalId] - id модалки (если не указан, то будет создан автоматически)
 * @param {Boolean} [props.opened] - открыт ли модалка (если не указан, то будет false)
 * @returns {Object}
 * @returns {Boolean} internalOpened - opened модалки, проксированный через очередь модалок
 */

export const useQueueModals = ({ modalId: propsModalId, opened }) => {
  const { $gaApp } = useContext()
  const modalId = unref(propsModalId)

  // проксируем пропс октрытия модалок в зависимости от состояния очереди модалок
  const internalOpened = computed(() => {
    // ждем заполнения очереди и на это время блокируем открытие всех модалок
    if ($gaApp.stores.modal.queue.pendingFillModals) {
      return false
    }

    // если модалка в очереди, то смотрим на стор открытия модалки очереди
    if ($gaApp.services.modal.queue.isModalInQueue(modalId)) {
      return $gaApp.services.modal.queue.wasOpenedModal(modalId)
    }

    return unref(opened)
  })

  // запускаем очередь модалок, фт в сервисе
  $gaApp.services.modal.queue.initQueue({
    opened,
    modalId,
  })

  return {
    internalOpened,
  }
}

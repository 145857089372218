import { rafThrottle } from '@ga/shared-browser'

const TARGET_FADE_TRANSITION_DURATION = 200

class CursorFollower {
  constructor(target, area, enterLeaveMode) {
    this.target = target
    this.area = area
    this.enterLeaveMode = enterLeaveMode

    this.transitionDuration = TARGET_FADE_TRANSITION_DURATION
    this.following = false

    this.checkCursorPosition = rafThrottle(this.checkCursorPosition.bind(this))
    this.startFollowing = this.startFollowing.bind(this)
    this.endFollowing = this.endFollowing.bind(this)
    this.startFollowingOnEnter = this.startFollowingOnEnter.bind(this)
    this.endFollowingOnLeave = this.endFollowingOnLeave.bind(this)
    this.fillStylesOnStartFollowing = this.fillStylesOnStartFollowing.bind(this)
    this.clearStylesOnEndFollowing = this.clearStylesOnEndFollowing.bind(this)
    this.followCursor = rafThrottle(this.followCursor.bind(this))
  }

  init() {
    this.setStyles()
    this.bindEventsListeners()
  }

  destroy() {
    this.resetStyles()
    this.unbindEventsListeners()
  }

  setStyles() {
    this.target.style.cssText = `
            visibility: hidden;
            position: fixed;
            left: 0;
            top: 0;
            bottom: auto;
            right: auto;
            opacity: 0;
            transform: translateX(0) translateY(0) translateZ(0) translate3d(0, 0, 0);
        `
    this.target.style.willChange = 'transform, visibility, opacity'
  }

  resetStyles() {
    this.target.style.cssText = ''
    this.target.style.willChange = 'auto'
  }

  bindEventsListeners() {
    if (this.enterLeaveMode) {
      this.area.addEventListener('mouseenter', this.startFollowingOnEnter)
      this.area.addEventListener('mouseleave', this.endFollowingOnLeave)
    } else {
      document.documentElement.addEventListener(
        'mousemove',
        this.checkCursorPosition,
      )
    }
  }

  unbindEventsListeners() {
    if (this.enterLeaveMode) {
      this.area.removeEventListener('mouseenter', this.startFollowingOnEnter)
      this.area.removeEventListener('mousemove', this.followCursor)
      this.area.removeEventListener('mouseleave', this.endFollowingOnLeave)
    } else {
      document.documentElement.removeEventListener(
        'mousemove',
        this.checkCursorPosition,
      )
    }
  }

  checkCursorPosition(event) {
    const cursorIsInArea = this.isCursorInArea(event.clientX, event.clientY)

    if (!this.following && cursorIsInArea) {
      this.startFollowing(event.clientX, event.clientY)
    }

    if (this.following && !cursorIsInArea) {
      this.endFollowing()
    }
  }

  isCursorInArea(x, y) {
    const { left, top, right, bottom } = this.area.getBoundingClientRect()

    return x >= left && x <= right && y >= top && y <= bottom
  }

  startFollowing(startX, startY) {
    this.following = true

    this.updateTargetPosition(startX, startY)
    this.area.addEventListener('mousemove', this.followCursor)
    this.fillStylesOnStartFollowing()
  }

  endFollowing() {
    this.following = false

    this.area.removeEventListener('mousemove', this.followCursor)
    this.clearStylesOnEndFollowing()
  }

  startFollowingOnEnter() {
    this.following = true
    this.area.addEventListener('mousemove', this.followCursor)
    this.fillStylesOnStartFollowing()
  }

  endFollowingOnLeave() {
    this.following = false
    this.area.removeEventListener('mousemove', this.followCursor)
    this.clearStylesOnEndFollowing()
  }

  fillStylesOnStartFollowing() {
    this.target.style.transition = `opacity ${this.transitionDuration}ms ease`
    setTimeout(() => {
      this.target.style.visibility = 'visible'
      this.target.style.opacity = '1'
    })
  }

  clearStylesOnEndFollowing() {
    this.target.style.transition = `
            opacity ${this.transitionDuration}ms ease,
            visibility 0ms ease ${this.transitionDuration}ms
        `
    setTimeout(() => {
      this.target.style.visibility = 'hidden'
      this.target.style.opacity = '0'
    })
  }

  followCursor(event) {
    this.updateTargetPosition(event.clientX, event.clientY)
  }

  updateTargetPosition(x, y) {
    this.target.style.transform = `
            translateX(${x - this.target.offsetWidth / 2}px) 
            translateY(${y - this.target.offsetHeight / 2}px)
            translateZ(0)
            translate3d(0, 0, 0)
        `
  }
}

const cursorFollowersRegistry = new Map()
let cursorFollowerUniqueId = 0

const generateUniqueKey = () => {
  cursorFollowerUniqueId += 1

  return cursorFollowerUniqueId
}

const createCursorFollower = (el, area, enterLeaveMode) => {
  const cursorFollower = new CursorFollower(el, area, enterLeaveMode)

  cursorFollower.init()

  const key = generateUniqueKey()

  el.dataset.cursorFollowerId = key

  cursorFollowersRegistry.set(key, cursorFollower)
}

const destroyCursorFollower = (id) => {
  const followerToDelete = cursorFollowersRegistry.get(id)

  followerToDelete.destroy()
  delete followerToDelete.target.dataset.cursorFollowerId

  cursorFollowersRegistry.delete(id)
}

const followCursor = {
  inserted(el, binding, vnode) {
    const { areaRef, isEnabled, enterLeaveMode = false } = binding.value
    const { context } = vnode

    if (isEnabled) {
      createCursorFollower(el, context.$refs[areaRef], enterLeaveMode)
    }
  },

  update(el, binding, vnode) {
    const { isEnabled: oldIsEnabled } = binding.oldValue
    const { areaRef, isEnabled, enterLeaveMode = false } = binding.value
    const { context } = vnode
    const { cursorFollowerId } = el.dataset

    if (isEnabled !== oldIsEnabled) {
      if (isEnabled && !cursorFollowerId) {
        createCursorFollower(el, context.$refs[areaRef], enterLeaveMode)
      } else if (!isEnabled && cursorFollowerId) {
        destroyCursorFollower(Number(cursorFollowerId))
      }
    }
  },

  unbind(el) {
    const { cursorFollowerId } = el.dataset

    if (cursorFollowerId) {
      destroyCursorFollower(Number(cursorFollowerId))
    }
  },
}

export { followCursor }

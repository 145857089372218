export class EventService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  onModalStartOpen(modal) {
    this.gaApp.stores.modal.main.stateModals[modal.id].isVisible = true
    this.gaApp.stores.modal.main.stateModals[modal.id].isOpening = true

    this.gaApp.eventBus.publish('module/modal/event/start-open', modal)
    this.gaApp.eventBus.publish(
      `module/modal/event/start-open/${modal.name}`,
      modal,
    )
  }

  onModalFinishOpen(modal) {
    this.gaApp.stores.modal.main.stateModals[modal.id].isFullyVisible = true
    this.gaApp.stores.modal.main.stateModals[modal.id].isOpening = false

    this.gaApp.eventBus.publish('module/modal/event/finish-open', modal)
    this.gaApp.eventBus.publish(
      `module/modal/event/finish-open/${modal.name}`,
      modal,
    )
  }

  onModalStartClose(modal) {
    this.gaApp.stores.modal.main.stateModals[modal.id].isFullyVisible = false

    this.gaApp.eventBus.publish('module/modal/event/start-close', modal)
    this.gaApp.eventBus.publish(
      `module/modal/event/start-close/${modal.name}`,
      modal,
    )
  }

  onModalFinishClose(modal) {
    this.gaApp.stores.modal.main.stateModals[modal.id].isVisible = false
    this.gaApp.services.modal.action.delete(modal.id)

    this.gaApp.eventBus.publish('module/modal/event/finish-close', modal)
    this.gaApp.eventBus.publish(
      `module/modal/event/finish-close/${modal.name}`,
      modal,
    )
  }

  onCloseModal(modal) {
    this.gaApp.services.modal.main.close(modal.id)
  }

  onModalToggle({ value, modal }) {
    if (value) {
      return
    }

    this.onCloseModal(modal)
  }

  onScroll({ event, modal }) {
    this.gaApp.stores.modal.main.stateModals[modal.id].scrollTop =
      event.target?.scrollTop
  }
}

import {
  DEMO_API_PREFIX,
  GA_DEBUG_API_COOKIES_KEY,
  GA_DEBUG_COOKIES_KEY,
  GA_DEBUG_FEATURES_COOKIES_KEY,
  LOCAL_API_PREFIX,
  PREPROD_API_PREFIX,
  TEST_API_PREFIX,
} from '../constants/debug'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get debugPanelStores() {
    return this.gaApp.stores.debugPanel
  }

  get featuresToggleStores() {
    return this.gaApp.stores.featuresToggle
  }

  get featuresToggleServices() {
    return this.gaApp.services.featuresToggle
  }

  get currentApi() {
    const apiMap = {
      [TEST_API_PREFIX]: ['testecom.', 'test-'],
      [DEMO_API_PREFIX]: ['demo.', 'demo-'],
      [PREPROD_API_PREFIX]: ['stage.', 'preprod.', 'preprod-'],
    }

    for (const key in apiMap) {
      const matched = apiMap[key].some((item) => {
        return window.location.host.includes(item)
      })

      if (matched) {
        return key
      }
    }

    return this.gaApp.config.get('devConfig')
      ? LOCAL_API_PREFIX
      : PREPROD_API_PREFIX
  }

  reloadWindow() {
    window.location.reload()
  }

  /**
   * Заносим значение апи в стору
   */
  setDebugApi(value) {
    this.debugPanelStores.main.debugApi = value
  }

  /**
   * Заполняем стору фичами из кук
   */
  fillDebugCookieFeatures() {
    const cookieFeatures = this.gaApp.cookies.get(GA_DEBUG_FEATURES_COOKIES_KEY)
    if (cookieFeatures) {
      this.featuresToggleServices.main.setFeatures(cookieFeatures)
    }
  }

  /**
   * Заносим значение выбранного апи из кук в стору
   *
   * Если куки нет и это не прод, выставляем препрод апи
   * по умлочанию
   */
  fillDebugCookieApi() {
    const cookieApi = this.gaApp.cookies.get(GA_DEBUG_API_COOKIES_KEY)
    if (cookieApi) {
      this.setDebugApi(cookieApi)
      return
    }

    if (!this.gaApp.config.get('prod')) {
      this.setDebugCookieApi(this.currentApi)
      this.setDebugApi(this.currentApi)
    }
  }

  /**
   * Заполняем куку фичами
   *
   * В дев режиме заносим значения всех фич в куку,
   * иначе только те, значение которых изменилось
   */
  setDebugCookieFeatures(nextFeaturesState) {
    let nextCookieFeatures = {}

    if (this.gaApp.config.get('devConfig')) {
      nextCookieFeatures = { ...nextFeaturesState }
    } else {
      for (const key in nextFeaturesState) {
        const featureValue = nextFeaturesState[key]
        const apiFeatureValue = this.featuresToggleStores.main.apiFeatures[key]

        if (apiFeatureValue === undefined || apiFeatureValue !== featureValue) {
          nextCookieFeatures[key] = featureValue
        }
      }
    }

    // fix - когда длина строки большая, куки иногда совсем не обновляются
    this.gaApp.cookies.set(GA_DEBUG_FEATURES_COOKIES_KEY, {}, { path: '/' })

    this.gaApp.cookies.set(
      GA_DEBUG_FEATURES_COOKIES_KEY,
      JSON.stringify(nextCookieFeatures),
      {
        path: '/',
        sameSite: 'lax',
      },
    )
  }

  /**
   * Меняем значение выбранного апи в куки
   * Если не дев, сбрасываем текущие фичи
   */
  setDebugCookieApi(value) {
    if (this.debugPanelStores.main.debugApi !== value) {
      this.gaApp.cookies.set(GA_DEBUG_API_COOKIES_KEY, value, {
        path: '/',
        sameSite: 'lax',
      })

      if (!this.gaApp.config.get('devConfig')) {
        this.setDebugCookieFeatures({})
      }
    }
  }

  /**
   * Включаем дебаг режим занося в куку значение
   * и обновляем его в сторе
   */
  setDebugCookieMode() {
    this.gaApp.cookies.set(GA_DEBUG_COOKIES_KEY, true, {
      path: '/',
      sameSite: 'lax',
    })

    this.fillDebugMode()
  }

  /**
   * Заносим значение дебаг режима из куки в стору
   */
  fillDebugMode() {
    this.debugPanelStores.main.debugMode =
      !!this.gaApp.cookies.get(GA_DEBUG_COOKIES_KEY)
  }

  openDebugModal() {
    this.debugPanelStores.main.modalDebugOpened = true
  }

  closeDebugModal() {
    this.debugPanelStores.main.modalDebugOpened = false
  }

  applySettings(features, api) {
    this.setDebugCookieFeatures(features)
    this.setDebugCookieApi(api)
    this.reloadWindow()
  }

  reset() {
    if (this.gaApp.config.get('devConfig')) {
      this.featuresToggleServices.main.fillLocalFeatures()
      this.setDebugCookieFeatures(this.featuresToggleStores.main.features)
    } else {
      this.setDebugCookieFeatures({})
    }
    this.setDebugCookieApi('')
    this.reloadWindow()
  }

  init() {
    /** Заносим в стору дебаг флаг из кук */
    this.fillDebugMode()

    /** Включаем дебаг вне прода */
    if (
      !this.gaApp.config.get('prod') &&
      !this.debugPanelStores.main.debugMode
    ) {
      this.setDebugCookieMode()
    }

    /**
     * Если дебаг включен
     * но в куках нет ключа с фичами, то создаем и заполняем куку,
     * иначе заносим данные из кук в стору
     */
    if (!this.debugPanelStores.main.debugMode) return

    if (!this.gaApp.cookies.get(GA_DEBUG_FEATURES_COOKIES_KEY)) {
      this.setDebugCookieFeatures(this.featuresToggleStores.main.features)
      this.fillDebugCookieApi()

      if (this.gaApp.config.get('devConfig')) {
        this.reloadWindow()
      }

      return
    }

    this.fillDebugCookieFeatures()
    this.fillDebugCookieApi()
  }
}

import {
  CLIENT_DAYS_PAGE_SIZE,
  PAGE_TYPE,
  PROMOTIONS_PAGE_SIZE,
} from '../../constants/pages'

export const getters = (gaApp) => ({
  getPageSize() {
    const pageType = gaApp.stores.promo.page.pageType

    return pageType === PAGE_TYPE.LISTING_PROMO
      ? PROMOTIONS_PAGE_SIZE
      : CLIENT_DAYS_PAGE_SIZE
  },

  getPagesCount() {
    const pageType = gaApp.stores.promo.page.pageType

    const pageSize =
      pageType === PAGE_TYPE.LISTING_PROMO
        ? PROMOTIONS_PAGE_SIZE
        : CLIENT_DAYS_PAGE_SIZE

    return Math.ceil(gaApp.stores.promo.listing.promotionsCount / pageSize)
  },
})

import { MODAL_STATE } from '../constants/products'

/**
 *
Предоставляет методы для работы с датами и временем:

`measurements` - возвращает массив строк с единицами измерения времени: 'day', 'week', 'month', 'year'

`localeDatePaths` - возвращает массив строк, представляющих пути для локализованных строк даты в контексте отзывов и списка отзывов.

`_dateWrapper` - возвращает объект dateWrapper из библиотек приложения для работы с датами.

`diffDateMeasurements(date)` - вычисляет разницу между текущей датой и переданной датой для каждой единицы измерения из measurements. Возвращает массив с этими разницами.

`getRelativeDate(date)` - возвращает относительную дату в локализованном формате на основе разницы между текущей датой и переданной датой.

`getDate(date)` - возвращает объект dateWrapper для указанной даты или текущей даты, если дата не передана.

`timeSinceUpdateAt(date)` - проверяет, прошло ли достаточно времени (определенное количество часов) с указанной даты. Используется для проверки, нужно ли обновлять какие-либо данные.
 */
export class DateService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get measurements() {
    return ['day', 'week', 'month', 'year']
  }

  get localeDatePaths() {
    return this.measurements.map(
      (measurement) => `review.listing.date.${measurement}`,
    )
  }

  get _dateWrapper() {
    return this.gaApp.libs.dateWrapper
  }

  /**
   * Вычисляет разницу между текущей датой и заданной датой
   * для каждого измерения в массиве измерений.
   *
   * @param {Date} date - Дата, с которой нужно сравнить текущую дату.
   * @return {Array} Массив, содержащий разницу между текущей датой
   * и заданной датой для каждого измерения. Если разница отрицательная,
   * возвращается 0.
   */
  diffDateMeasurements(date) {
    const currentDate = this._dateWrapper().startOf('day').utc(true)
    const formattedDate = this._dateWrapper.utc(date).startOf('day')

    return this.measurements.map((measurement) => {
      const diffNumber = currentDate.diff(formattedDate, measurement)
      return diffNumber < 0 ? 0 : diffNumber
    })
  }

  /**
   * Возвращает относительную дату.
   *
   * @param {Date} date - Дата, для которой нужно получить относительную дату.
   * @return {string} Относительная дата в локализованном формате.
   */
  getRelativeDate(date) {
    const relativeDate = this.diffDateMeasurements(date)
    const relativeDateFiltered = relativeDate.filter((diff) => !!diff)

    const minRelativeDate =
      relativeDateFiltered.length > 0 ? Math.min(...relativeDateFiltered) : 0

    const indexRelativeDate = relativeDate.indexOf(minRelativeDate)
    const currentLocalePath = this.localeDatePaths[indexRelativeDate]

    return this.gaApp.i18n.tc(currentLocalePath, minRelativeDate)
  }

  getDate(date) {
    return date !== undefined ? this._dateWrapper(date) : this._dateWrapper()
  }

  timeSinceUpdateAt(date) {
    const currTime = this.getDate()
    const lastTime = this.getDate(date)

    return currTime.diff(lastTime, 'hour') >= MODAL_STATE.HOURS_SINCE_VIEW
  }
}

import { getMappedAttributes } from './get-mapped-attributes'
import { getMappedDolyame } from './get-mapped-dolyame'
import { getMappedPrice } from './get-mapped-price'
import { getSeoEnrichedMedia } from './get-seo-enriched-media'

export const getMappedProduct = (product) => ({
  itemId: product.itemId,
  name: product.name,
  brand: product.brand,
  type: product.productType,
  url: product.url,
  media: getSeoEnrichedMedia(product),
  attributes: getMappedAttributes(product.attributes),
  dolyame: getMappedDolyame(product.dolyame),
  prices: getMappedPrice(product.price),
  labels: product.labels,
  disclaimer: product.disclaimer,
  reviews: product.reviews,
  inStock: product.inStock,
  favourite: product.favourite,
  configurable: product.configurable,
  needOnlineConsultation: product.needOnlineConsultation,
  isAdult: product.isAdult,
  coupon: product.couponResponse,
})

/* eslint-disable no-template-curly-in-string */
export const config = {
  data: {
    cities: [
      {
        text: 'Екатеринбург',
        value: '690d1073-1df0-4cf5-a5e0-0269f7d6ddd1',
        default: false,
      },
      {
        text: 'Москва',
        value: '9b4b8afa-b017-44c4-80ba-b9ad891005b7',
        default: true,
      },
      {
        text: 'Новосибирск',
        value: 'f5ec657e-886b-4fb1-85e9-18acdd8b943d',
        default: false,
      },
      {
        text: 'Челябинск',
        value: '7b78c892-7ea8-43cb-a0c7-56f8a03805c4',
        default: false,
      },
      {
        text: 'Казань',
        value: 'bbdf572d-5608-4019-b4e9-d7d21f37bee2',
        default: false,
      },
      {
        text: 'Самара',
        value: 'd9b3fe34-f4ef-4870-959b-cfa006960dbf',
        default: false,
      },
      {
        text: 'Ростов-на-Дону',
        value: '527c4c26-a29c-48f4-b931-6ace8debbcc1',
        default: false,
      },
      {
        text: 'Санкт-Петербург',
        value: '9d4b8afa-b017-44c4-80ba-b9ad891005b7',
        default: false,
      },
      {
        text: 'Хабаровск',
        value: '9c4b8afa-b017-44c4-80ba-b9ad891005b7',
        default: false,
      },
      {
        text: 'Саратов',
        value: '61ccd698-b630-4d6f-8620-b63beb0a3032',
        default: false,
      },
      {
        text: 'Нижний Новгород',
        value: '07f4a81d-170c-4847-8d47-f7490f876c26',
        default: false,
      },
      {
        text: 'Волгоград',
        value: 'ee96f2ab-63b4-4b70-b0a9-bb4080fa6bf9',
        default: false,
      },
      {
        text: 'Пермь',
        value: '6f7aba8d-a412-4f63-95fc-3ef3f894e032',
        default: false,
      },
      {
        text: 'Сочи',
        value: '923b8afa-b017-44c4-80ba-b9ad891885b7',
        default: false,
      },
      {
        text: 'Краснодар',
        value: 'af6b83ae-59a0-47bd-a914-b298b5ee2168',
        default: false,
      },
      {
        text: 'Иркутск',
        value: '455fbe48-5949-4ad3-9906-4326ca821d4b',
        default: false,
      },
      {
        text: 'Красноярск',
        value: '7162a2d9-d015-4a1d-8a30-8657ecc77210',
        default: false,
      },
      {
        text: 'Уфа',
        value: '16d29c57-2bd3-400c-bdb7-99d54d5559a7',
        default: false,
      },
      {
        text: 'Воронеж',
        value: '86c7d66a-e136-4b90-9300-01d73dce7f71',
        default: false,
      },
    ],
    cards: [
      {
        id: 1,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/24/web/30663138623061342d393836662d346366332d626235612d3334343430343336366132638dbd641b68c722f${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/24/web/30663138623061342d393836662d346366332d626235612d3334343430343336366132638dbd641b68c722f${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 2,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/26/web/36313865613337312d616566642d343066642d396431652d3464666361373534333734328dbd6426963de96${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/26/web/36313865613337312d616566642d343066642d396431652d3464666361373534333734328dbd6426963de96${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 3,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/25/web/61323766636132342d643630322d343535382d613138392d6237323261343835343633318dbd6424dd824d9${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/25/web/61323766636132342d643630322d343535382d613138392d6237323261343835343633318dbd6424dd824d9${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 4,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/27/web/62643832366264632d313337302d343139652d383564642d6463623766363431396436338dbd6427f073c29${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/27/web/62643832366264632d313337302d343139652d383564642d6463623766363431396436338dbd6427f073c29${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 5,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/28/web/33666165633465392d373432632d346534382d626663622d6335316563623336636361368dbd64290ba08ae${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/28/web/33666165633465392d373432632d346534382d626663622d6335316563623336636361368dbd64290ba08ae${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 6,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/33/web/66623834623165632d306235662d343230362d383465312d3935636163656362376662338dbd9245a019ffd${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/33/web/66623834623165632d306235662d343230362d383465312d3935636163656362376662338dbd9245a019ffd${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
      {
        id: 7,
        availability: {
          isAvailable: true,
          status: {
            storeId: '12345',
            type: 'inStock',
            quantity: 1000000,
          },
        },
        imageInfo: [
          {
            type: 'gallery',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/38/web/39623762333461622d313837332d343333372d383134372d3534663462353931613364348dc17f6050fb96f${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
          {
            type: 'preview',
            url: 'https://stage-pcdn.goldapple.ru/pp/g/corp/38/web/39623762333461622d313837332d343333372d383134372d3534663462353931613364348dc17f6050fb96f${screen}.${format}',
            format: ['webp', 'png'],
            screen: ['fullhd', 'tablet', 'mobile'],
          },
        ],
      },
    ],
    rules: {
      maxSets: 10,
      minCardsAmount: 1,
      maxCardsAmount: 10000,
      minCardsValue: 300,
      maxCardsValue: 50000,
    },
  },
}

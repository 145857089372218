import {
  COOKIES_NOFIFICATION_ANSWERED_KEY,
  IS_ACCEPTED_COOKIES_KEY,
} from '../constant'
export class CookiePermissionService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openModal() {
    this.gaApp.stores.app.cookie.permission.modalOpened = true
  }

  closeModal() {
    this.gaApp.stores.app.cookie.permission.modalOpened = false
  }

  openSettingsModal() {
    this.gaApp.stores.app.cookie.permission.modalSettingsOpened = true

    if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      /**
       * Предотвращаем включение скролла при открытии из другой модалки
       */
      this.gaApp.services.modal.register.add('cookie-modal')
    }
  }

  closeSettingsModal() {
    this.gaApp.stores.app.cookie.permission.modalSettingsOpened = false

    if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      /**
       * Включаем скролл обратно
       */
      this.gaApp.services.modal.register.remove('cookie-modal')

      if (!this.gaApp.stores.app.cookie.permission.isAlreadyAnswered) {
        this.openModal()
      }
    }
  }

  showSettingsModalLoading() {
    this.gaApp.stores.app.cookie.permission.modalSettingsLoading = true
  }

  hideSettingsModalLoading() {
    this.gaApp.stores.app.cookie.permission.modalSettingsLoading = false
  }

  async getSettingsModalContent() {
    if (this.gaApp.stores.app.cookie.permission.modalSettingsText) return

    try {
      this.showSettingsModalLoading()

      const { messageHtml } =
        await this.gaApp.services.app.api.getCookieAgreementText()

      this.gaApp.stores.app.cookie.permission.modalSettingsText = messageHtml
    } catch (error) {
    } finally {
      this.hideSettingsModalLoading()
    }
  }

  async setAcceptedCookies(value) {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    this.gaApp.cookies.set(IS_ACCEPTED_COOKIES_KEY, value, {
      expires: expiresDate,
      path: '/',
      sameSite: 'lax',
    })
    this.gaApp.stores.app.cookie.permission.isAccepted = value

    /**
     * Если пользователь в дальнейшем отключил использование сторонних кук,
     * перезагружаем страницу для сброса инициализированной аналитики
     */
    if (this.gaApp.stores.app.cookie.permission.isAlreadyAnswered && !value) {
      await this.gaApp.services.app.api.setCookieAgreement(value)
      window.location.reload()

      return
    }

    this.gaApp.services.app.api.setCookieAgreement(value)

    this.gaApp.stores.app.cookie.permission.isAlreadyAnswered = true
    if (this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
    }

    this.gaApp.analytics.libs.initAllWithPermissionsChecks()
  }

  acceptNotificaionCookies() {
    this.gaApp.services.app.browserStorage.local.set(
      COOKIES_NOFIFICATION_ANSWERED_KEY,
      1,
    )

    this.gaApp.stores.app.cookie.notification.isAnswered = true
  }

  initPermission() {
    const isAcceptedCookies = this.gaApp.cookies.get(IS_ACCEPTED_COOKIES_KEY)
    const shouldAccept = this.gaApp.features.get('modalCookiesPermission')

    if (isAcceptedCookies === undefined && shouldAccept) {
      if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
        this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
        this.openModal()
      } else {
        this.gaApp.stores.app.cookie.permission.shouldRenderCookieModal = false
      }
      return
    }

    if (shouldAccept) {
      this.gaApp.stores.app.cookie.permission.shouldRenderCookieModal = false
      this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
      this.gaApp.stores.app.cookie.permission.isAlreadyAnswered = true
      this.gaApp.stores.app.cookie.permission.isAccepted =
        isAcceptedCookies || false
    }
  }

  initNotification() {
    const shouldAccept = this.gaApp.features.get('сookiesNotificationPanel')

    if (!shouldAccept) {
      return
    }

    const isAnswered = this.gaApp.services.app.browserStorage.local.get(
      COOKIES_NOFIFICATION_ANSWERED_KEY,
    )

    this.gaApp.stores.app.cookie.notification.isAnswered = !!isAnswered
  }

  init() {
    this.initPermission()
    this.initNotification()
  }
}

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MenuModel extends GtmBaseModel {
  constructor(data) {
    super({ event: ACTIONS.MENU.GA_4 })

    const { path } = data

    this.event_category = 'menu_click'
    this.path = path
  }
}

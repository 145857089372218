export const moduleDolyameSsrRoutes = [
  {
    moduleType: 'dolyame',
    pageType: 'main',
    path: '/bnpl',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/dolyame/page/main' */
        '~/modules/dolyame/components/pages/main.vue'
      ),
  },
]

export const routes = [
  ...moduleDolyameSsrRoutes,
  // {
  //   moduleType: 'dolyame',
  //   pageType: 'appMain',
  //   path: '/app-pages/bnpl',
  //   meta: {
  //     addressDisabled: true,
  //     authRequired: false,
  //     analyticDisabled: false,
  //     webview: true,
  //     webviewOnly: true,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'modules/dolyame/page/main' */
  //       '~/modules/dolyame/components/pages/main.vue'
  //     ),
  // },
]

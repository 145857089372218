<template>
  <svg viewBox="0 0 40 25">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M34.7 7.83h-7.25a5.65 5.65 0 0 0 5.3 4.48h5.76c.08-.23.08-.53.08-.75a3.78 3.78 0 0 0-3.9-3.73zm-6.63 4.85v5.6h3.5v-2.99h3.12c1.72 0 3.2-1.12 3.66-2.61H28.07zM15.6 7.83v10.45h3.12s.78 0 1.17-.75l2.73-5.22H23v5.97h3.5V7.83h-3.1s-.78.07-1.17.74L19.5 13.8h-.39V7.83h-3.5zM.8 18.28V7.83h3.51s1.01 0 1.56 1.49c1.4 3.96 1.56 4.48 1.56 4.48s.3-.97 1.55-4.48c.55-1.5 1.56-1.5 1.56-1.5h3.5v10.46h-3.5v-5.6h-.39l-1.94 5.6H6.65l-1.95-5.6h-.39v5.6H.81z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-mir',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

import { useAnalytics } from '../../../../../composable/use/analytics'

export const useEvents = () => {
  const { $gaApp } = useContext()

  const analytic = useAnalytics()

  const onItemClick = (item) => {
    if (item.parent) {
      $gaApp.services.header.nav.expandNavItem(item)
      return
    }

    const { nuxt, link } = item
    if (nuxt && link) {
      analytic.onMenuItemClick($gaApp.stores.header.nav.navMobile, item)

      $gaApp.router.push(link)
    }
  }

  return {
    onItemClick,
  }
}

import { EVENT_NAMES } from '../../../constants'

export class GtmGa4TickerClickModel {
  constructor(tickerAnalyticsData) {
    if (!tickerAnalyticsData) {
      throw new Error('data is required')
    }

    const { screenType, message } = tickerAnalyticsData

    this.event = EVENT_NAMES.CLICK_TICKER.GA_4
    this.screen_type = screenType
    this.message = message
  }
}

export class VideoSliderService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fillStore(block) {
    this.gaApp.stores.brandzone.videoSlider.data = block.data ?? {}
  }

  reset() {
    this.gaApp.stores.brandzone.videoSlider.$reset()
  }
}

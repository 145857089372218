import isEmpty from 'lodash/isEmpty'

export class DigitalReceiveReturnCardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Заполнение данных получения ВЭПК
   * @param {object} redirect данные переадресации
   */
  async getReceiveReturnPageData(redirect) {
    const data =
      await this.gaApp.services.giftCards.api.getDataReceiveReturnPage(redirect)

    this.setReceivedReturnCard({
      ...data,
      cardNumberHash: redirect.id,
    })
  }

  /**
   * Устанавливает данные для страницы получения ВЭПК
   * @param {object} receivedReturnCard данные получаемой ЭПК
   */
  setReceivedReturnCard(receivedReturnCard = {}) {
    if (isEmpty(receivedReturnCard)) {
      return undefined
    }

    this.gaApp.stores.giftCards.digitalReceiveReturn.$patch(receivedReturnCard)
  }

  /**
   * Открывает или скрывает модальное окно с QR-кодом
   * @param {boolean} value
   */
  setQrModalVisibility(value) {
    this.gaApp.stores.giftCards.digitalReceiveReturn.isQrModalOpened = value
  }
}

import { EVENT } from '../../../constants'

export class MindboxConsultationModel {
  constructor({customerId}) {
    this.operation = EVENT.CONSULTATION.MINDBOX
    this.data = {
      customer: {
        ids: {
          backendID: customerId
        }
      }
    }
  }
}

/**
 * Устанавливает отображение иконок
 * @param {Node} nodePrepend - может быть null
 * @param {Node} nodeAppend - может быть null
 * @param {Boolean} displayNodePrepend
 * @param {Boolean} displayNodeAppend
 */
export const setPrependAppendDisplay = (
  nodePrepend,
  nodeAppend,
  displayNodePrepend,
  displayNodeAppend,
) => {
  if (nodePrepend)
    nodePrepend.style.display = displayNodePrepend ? 'block' : 'none'
  if (nodeAppend)
    nodeAppend.style.display = displayNodeAppend ? 'block' : 'none'
}

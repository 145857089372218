import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const balanceStore = () => {
  const init = defineStore('giftCardsBalance', {
    state,
    getters,
  })

  return init(getActivePinia())
}

import { rafThrottle } from '@ga/shared-browser'

const TRANSITION_DURATION = 100
const TRANSITION_DURATION_ON_LEAVE = 300

class Index {
  constructor(target, area, rotateYFactor = 10) {
    this.target = target
    this.area = area
    this.rotateYFactor = rotateYFactor
    this.initialTransition = `transform ${TRANSITION_DURATION}ms linear`
    this.transitionOnLeave = `transform ${TRANSITION_DURATION_ON_LEAVE}ms ease-out`

    this.startFloating = this.startFloating.bind(this)
    this.endFloating = this.endFloating.bind(this)
    this.onMouseMove = rafThrottle(this.onMouseMove.bind(this))
    this.onLeaveTransitionEnd = this.onLeaveTransitionEnd.bind(this)
  }

  init() {
    this.setStyles()
    this.bindEventsListeners()
  }

  destroy() {
    this.resetStyles()
    this.unbindEventsListeners()
  }

  setStyles() {
    this.target.style.willChange = 'transform'
    this.target.style.transition = this.initialTransition
  }

  resetStyles() {
    this.target.style.willChange = 'auto'
    this.target.style.transform = ''
    this.target.style.transition = ''
  }

  bindEventsListeners() {
    this.area.addEventListener('mouseenter', this.startFloating)
    this.area.addEventListener('mouseleave', this.endFloating)
  }

  unbindEventsListeners() {
    this.area.removeEventListener('mouseenter', this.startFloating)
    this.area.removeEventListener('mouseleave', this.endFloating)
  }

  startFloating() {
    this.area.addEventListener('mousemove', this.onMouseMove)
  }

  endFloating() {
    this.area.removeEventListener('mousemove', this.onMouseMove)
    this.target.addEventListener('transitionend', this.onLeaveTransitionEnd)
    this.target.style.transition = this.transitionOnLeave

    setTimeout(() => {
      this.setRotation(0)
    }, 100)
  }

  onLeaveTransitionEnd({ propertyName }) {
    if (propertyName === 'transform') {
      this.target.removeEventListener(
        'transitionend',
        this.onLeaveTransitionEnd,
      )
      this.target.style.transition = this.initialTransition
    }
  }

  onMouseMove(event) {
    const targetLeftOffset = this.target.getBoundingClientRect().left

    const cursorXPosition =
      2 * ((event.pageX - targetLeftOffset) / this.target.offsetWidth - 0.5)

    this.setRotation(cursorXPosition)
  }

  setRotation(cursorXPosition) {
    this.target.style.transform = `
            perspective(1000px)
            rotateY(${(cursorXPosition * this.rotateYFactor).toFixed(2)}deg)
            scale3d(1, 1, 1)
        `
  }
}

const floatingOnHoverRegistry = new Map()
let floatingOnHoverUniqueId = 0

const generateFloatingOnHoverUniqueKey = () => {
  floatingOnHoverUniqueId += 1

  return floatingOnHoverUniqueId
}

const createFloatingOnHover = (el, area, rotateYFactor) => {
  const floatingOnHover = new Index(el, area, rotateYFactor)

  floatingOnHover.init()

  const key = generateFloatingOnHoverUniqueKey()

  el.dataset.floatingOnHoverId = key

  floatingOnHoverRegistry.set(key, floatingOnHover)
}

const destroyFloatingOnHover = (id) => {
  const floatingOnHoverToDelete = floatingOnHoverRegistry.get(id)

  floatingOnHoverToDelete.destroy()
  delete floatingOnHoverToDelete.target.dataset.floatingOnHoverId

  floatingOnHoverRegistry.delete(id)
}

const floatingOnHover = {
  inserted(el, binding, vnode) {
    const { areaRef, isHoverEnabled, rotateYFactor } = binding.value
    const { context } = vnode

    if (isHoverEnabled) {
      createFloatingOnHover(el, context.$refs[areaRef], rotateYFactor)
    }
  },

  update(el, binding, vnode) {
    const { isHoverEnabled: oldIsHoverEnabled } = binding.oldValue
    const { areaRef, isHoverEnabled } = binding.value
    const { context } = vnode
    const { floatingOnHoverId } = el.dataset

    if (isHoverEnabled !== oldIsHoverEnabled) {
      if (isHoverEnabled && !floatingOnHoverId) {
        createFloatingOnHover(el, context.$refs[areaRef])
      } else if (!isHoverEnabled && floatingOnHoverId) {
        destroyFloatingOnHover(Number(floatingOnHoverId))
      }
    }
  },

  unbind(el) {
    const { floatingOnHoverId } = el.dataset

    if (floatingOnHoverId) {
      destroyFloatingOnHover(Number(floatingOnHoverId))
    }
  },
}

export { floatingOnHover }

import pkg from '../../package.json'

const env = process.env.NODE_ENV ?? 'development'
const configEnv = process.env.NODE_CONFIG_ENV ?? 'development'
const dev = env === 'development'
const devConfig = configEnv === 'development'
const prod = ['production'].includes(configEnv)
// const https = Boolean(process.env.NODE_HTTPS) || false;

const isDebugPanelEnabled = ['development', 'testing'].includes(configEnv)

const appLocale = process.env.NODE_LOCALE || 'ru-RU'

export const getDefaultConfig = () => ({
  env,
  configEnv,
  dev,
  devConfig,
  prod,
  isDebugPanelEnabled,

  devtools: false,
  ssr: true,

  ignoreWebviewGuard: false,

  locale: {
    default: 'ru-RU',
    active: appLocale,
  },

  proxy: {
    '/front/api/apm/events': {
      target: process.env.ELASTIC_APM_SERVER_URL || '/',
      pathRewrite: { '^/front/api/apm/events': '/intake/v2/rum/events' },
    },
    '/front/api/': {
      pathRewrite: { '^/front/api/': '' },

      /**
       * Формируем target в случае дебаг режима вне прода
       */
      router: () => process.env.NODE_API_URL || '/',
    },
  },

  maps: {
    yandex: {
      key: 'b82502c5-594e-4fe3-a182-37bfa4f9e6b8',
    },

    google: {
      key: 'AIzaSyCck7ej-hqFTFUn-P9jVLT2j2CHOchN0Bg',
    },
  },

  advcake: {
    id: 'advcakeAsync',
    autoInit: false,
    enabled: true,
    debug: false,
  },

  apm: {
    active: true,
    serviceVersion: pkg.version,
    serviceName: `Plaid_Web_Frontend_Client`,
    serverUrlPrefix: '/front/api/apm/events',
    monitorLongtasks: true,
    sendCredentials: true,
  },

  gtm: {
    autoInit: false,
    enabled: true,
    debug: false,
    id: 'GTM-K9LNN8Q',
  },

  mindbox: {
    id: {
      'ru-RU': 'GoldAppleWebsite',
      'ru-BY': 'GoldApple.by',
      'ru-KZ': 'GoldApple.kz',
      'en-QA': 'Goldappleme.qatar',
      'en-AE': 'Goldappleme.qatar',
    }[appLocale],

    autoInit: false,
    enabled: true,
    debug: false,

    scriptURL: {
      'ru-RU': 'https://api.mindbox.ru/scripts/v1/tracker.js',
      'ru-BY': 'https://api.mindbox.ru/scripts/v1/tracker.js',
      'ru-KZ': 'https://api.mindbox.ru/scripts/v1/tracker.js',
      'en-QA': 'https://api.mindbox.cloud/scripts/v1/tracker.js',
      'en-AE': 'https://api.mindbox.cloud/scripts/v1/tracker.js',
    }[appLocale],
  },

  gaAnalytics: {
    autoInit: false,
    enabled: true,
    debug: false,
    storage: {
      maxSize: 30,
      freePercent: 0.5,
      storageName: 'ga-app-analytics',
    },
  },

  digiAnalytics: {
    autoInit: false,
    enabled: true,
    debug: false,
  },

  gdeslon: {
    merchantId: '97915',
    tracker: {
      path: '/_static/scripts/gdeslon/tracker.js',
      async: true,
    },
    autoInit: false,
    enabled: false,
    debug: false,
  },

  eyezon: {
    autoInit: false,
    businessId: '62725984114355859fe34325',
    // включаем только на ру
    enabled: ['ru-RU'].includes(appLocale),
    debug: false,

    scriptAsync: true,
    scriptUrl:
      'https://storage.googleapis.com/eyezonscriptv2/static/js/eyezonwidget.js',
  },

  eyezonV2: {
    autoInit: false,
    businessId: '82',
    // включаем только на ру
    enabled: ['ru-RU'].includes(appLocale),
    debug: false,
    region: 'prod',

    scriptAsync: true,
    scriptUrl: '/eyezone/static/js/eyezonwidget.js',
    hideEyeAfterMinutes: 240, // Скрывать плавающую кнопку eyezon через 240м. (4ч.)
  },

  yandexMetrika: {
    id: '49452322',
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true,
    ecommerce: 'dataLayer',
  },

  dayjs: {
    locales: ['ru', 'be', 'kk', 'ar', 'en'],
    defaultLocale: 'ru',
    defaultTimeZone: 'Europe/Moscow',
    plugins: [
      'advancedFormat',
      'utc',
      'timezone',
      'relativeTime',
      'customParseFormat',
      'localeData',
      'localizedFormat',
      'updateLocale',
      'weekday',
      'devHelper',
      'toObject',
      'objectSupport',
      'isBetween',
    ],
  },

  enabledCart: true,
  enabledListingAppearing: false,
  storybook: {
    port: 4000,
  },
})

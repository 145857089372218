import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref } from 'vue'

/**
 * Возвращает объект со ссылкой `postRendered`, которая переключается в фоновом режиме.
 * Если ФТ включен, ссылка `postRendered` устанавливается в `true` в фоновом режиме.
 *
 * @return {Object} Объект со ссылкой `postRendered`.
 */
export const usePostRender = () => {
  const { $gaApp } = useContext()

  const hasFeature = computed(() =>
    $gaApp.features.get('productCardPostRenderElements'),
  )

  const postRendered = ref(!unref(hasFeature))

  if (unref(hasFeature)) {
    $gaApp.services.app.scheduler.postTask(() => {
      postRendered.value = true
    }, 'background')
  }

  return {
    postRendered,
  }
}

import { ACTION_TYPES, ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MainActionClickModel extends GtmBaseModel {
  constructor({ eventCategory, actionLabel, buttonText, buttonLink }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.action_type = ACTION_TYPES.CLICK

    this.event_category = eventCategory
    this.action_label = actionLabel
    this.button_text = buttonText
    this.button_link = buttonLink
  }
}

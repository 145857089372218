import { ThankYouViewBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class CheckoutAnalytics extends BaseController {
  // Отправляем при открытии страницы успешного оформления заказа
  onThankYouView() {
    const data = this.getThankYouData()

    this.send(ThankYouViewBuilder, data)
  }
}

import { not } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (validator, message) => {
  return withMessage(not(validator), {
    type: 'not',
    message: 'app.validation.default',
  })(message)
}

export const GaModuleSupportAction = () =>
  import(
    /* webpackChunkName: 'modules/support/action' */
    './atoms/support-action/action.vue'
  )

export const GaModuleSupportModal = () =>
  import(
    /* webpackChunkName: 'modules/support/modal' */
    './organisms/modal/modal.vue'
  )

import { SCREEN_TYPE, STEPS } from '../../constants'

export const state = () => ({
  sets: [],

  config: {
    cards: [],
    cities: [],
    city: '',
    isLoading: false,
    rules: {
      maxSets: 0,
      minValue: 0,
      maxValue: 0,
      minCount: 0,
      maxCount: 0,
    },
  },

  steps: {
    [SCREEN_TYPE.MOBILE]: [
      STEPS.SETS,
      STEPS.DELIVERY,
      STEPS.COUNTERPARTY,
      STEPS.CONTACTS,
      STEPS.CHECKOUT,
    ],
    [SCREEN_TYPE.DESKTOP]: [STEPS.SETS, STEPS.DELIVERY],
  },

  isCityModalOpen: false,
})

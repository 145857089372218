import { trimObjectStrings } from '@ga/utils'

export class PlasticService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Формирование данных заказа для КППК
   */
  get order() {
    const formData = new FormData()
    const { details, counterparty, contacts } =
      this.gaApp.stores.giftCardsB2b.plastic

    const dto = {
      ...trimObjectStrings(details),
      ...trimObjectStrings(counterparty),
      ...trimObjectStrings(contacts),
      deliveryDate: details.deliveryDate.toISOString(),
      qty: parseInt(details.qty),
      cardType: 'real',
    }

    Object.entries(dto).forEach(([name, value]) => {
      if (name === 'requisites') {
        formData.append(name, value, value.name)

        return undefined
      }

      formData.append(name, value)
    })

    return formData
  }
}

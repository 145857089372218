import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaImageResponsive as GaImageResponsiveBase } from '@ga/ui-components/image-responsive'

// @vue/component
export default {
  name: 'ga-image-responsive',

  components: {
    GaImageResponsiveBase,
  },

  setup(_, { attrs }) {
    const { $gaApp } = useContext()

    const lazy = computed(() => !$gaApp.services.app.main.isSSR && attrs.lazy)

    return {
      lazy,
    }
  },
}

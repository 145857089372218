export default async (context) => {
  // страны где подключаем пакет и добавляем в заголовки
  const availableCountries = ['ru', 'by', 'kz']
  const gaApp = context.$gaApp

  // проверяем входждение страны в массив разрешенных
  if (availableCountries.includes(gaApp.i18n.locale.country)) {
    try {
      // подключаем плагин
      const FingerprintJS = await import(
        /* webpackChunkName: 'packages/fp' */
        '@ga/fingerprint'
      )

      const fpPromise = FingerprintJS.load()
      const fp = await fpPromise
      const result = await fp.get()

      // добавляем в заголовки
      gaApp.api.addRequestCallback({
        success: (config) => {
          config.headers.common['x-fp'] = result.visitorId

          return config
        },
      })
    } catch (e) {
      console.log('ошибка инита fp')
    }
  }
}

import { TYPE } from '../../constants'
import { GtmGa4ContactClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ContactClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4ContactClickModel]])
    super(models, data)
  }
}

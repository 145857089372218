import { useTransitionProps } from '@ga/use/transition-props'

import { GaAction } from '@ga/ui-components/action'

import { GaImageAdult } from '~/components/image-adult'
import { GaImageResponsive } from '~/components/image-responsive'

// @vue/component
export default {
  name: 'ga-nav-banners-desktop',

  components: {
    GaAction,
    GaImageAdult,
    GaImageResponsive,
  },

  props: {
    level: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {
      transitionProps: useTransitionProps(),
    }
  },

  computed: {
    itemsInternal() {
      return this.items.slice(0, 2).map((item) => {
        const mods = { adult: item.isForAdults }

        return { ...item, mods }
      })
    },

    mainItem() {
      return this.itemsInternal[0]
    },

    additionalItems() {
      return this.itemsInternal
    },

    main() {
      return this.level === 0 && this.itemsInternal.length === 1
    },

    additionalListKey() {
      return this.itemsInternal.map((item) => item.id).join('-')
    },
  },

  methods: {
    dateIsCorrect(dateString) {
      return !isNaN(Date.parse(dateString))
    },

    isActual(startDate, endDate) {
      let isActual = true

      if (this.dateIsCorrect(startDate) && this.dateIsCorrect(endDate)) {
        isActual = this.$gaApp.libs.dateWrapper().isBetween(startDate, endDate)
      }

      return isActual
    },
  },
}

export const VIEW = {
  REGULAR: 'regular',
  PLP: 'plp',
  GIFT_CARDS_B2B: 'gift-cards-b2b',
}

export const DROPDOWN_PADDING = 20

export const DROPDOWN_OFFSET_X = {
  'bottom-start': -20,
  'bottom-end': 20,
}

export const DROPDOWN_WIDTH = {
  AUTO: 'auto',
  BOX_WIDTH: 'box-width',
}

// Для корректной работы внутри контейнеров с заданным значением overflow-y, из-за чего соответственно position: absolute не работает
export const SELECT_DROPDOWN_STRATEGY = {
  STRATEGY: 'fixed',
}

<template>
  <svg fill="inherit" viewBox="0 0 46 45">
    <path
      fill="inherit"
      fill-rule="evenodd"
      stroke="none"
      d="M45.527 22.5c0 12.426-10.073 22.5-22.5 22.5-12.426 0-22.5-10.074-22.5-22.5S10.601 0 23.027 0c12.427 0 22.5 10.074 22.5 22.5ZM17.777 15h3v15h-3V15Zm10.5 0h-3v15h3V15Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-pause-light',
}
</script>

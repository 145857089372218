import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ShowMoreModel extends GtmBaseModel {
  constructor() {
    super()

    this.event_category = EVENT.GTM.eventCategory

    this.action_type = 'click_read_more_about_brand'
  }
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OutOfStockDialogBeforeCheckoutOpenModel extends GtmBaseModel {
  constructor() {
    super({ event: EVENT.MODAL_OPEN.GA4 })

    this.event_category = 'account'
    this.name = 'delete_products'
  }
}

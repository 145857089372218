export const MEDIA_VIEW = {
  SINGLE: 'single',
  SLIDER: 'slider',
  GALLERY: 'gallery',
}

export const SLIDER_DOTS_SIZE = {
  XXS: 'xxs',
  XS: 'xs',
}

export const VIDEO_STRATEGY = {
  MANUAL: 'manual',
  SINGLE: 'single',
  NONE: 'none',
}

import { computed, nextTick, ref, unref } from 'vue'

import { getId } from '@ga/utils'

import {
  useClear,
  useInputState,
  useMaskWithPhantom,
  useMods,
  usePrefix,
  useStateAttributes,
} from '../use'
import { getFitValue, prefixExists } from '../utils'

/**
 * Возвращает необходимые поля для инпута с маской (с Vue2 компонентом плагина https://www.npmjs.com/package/vue-imask)
 * @param {Ref<String>} value - значение инпута из props
 * @param {Ref<String>} size - размер из props
 * @param {Ref<String>} theme - тема из props
 * @param {Ref<Boolean>} error - параметр наличия ошибки из props
 * @param {Ref<Boolean>} disabled - параметр disabled из props
 * @param {Ref<Boolean>} clear - параметр наличия кнопки очистки из props
 * @param {Ref<Boolean>} highlight - параметр highlight из props (может не совпадать с фокусом, есть есть раскрывающийся список)
 * @param {Ref<String>} mask - строковый шаблон маски из props (https://imask.js.org/guide.html#masked-pattern)
 * @param {Ref<String>} prefix - prefix из props
 * @param {Ref<String[]>} prefixAliases - массив алиасов префикса(используется, чтобы удалить префикс при вставке)
 * @param {Function} emit - emit функция контекста
 * @param {Object} listeners - слушатели событий контекста
 * @param {Object} maxLength - максимальное количество символов
 * @param {String} placeholderChar - символ заполнения
 * @param {Ref<Boolean>} isRequired - является ли поле обязательным, парамерт из props
 * @param {Ref<Boolean>} noMaskSpacing - наследует letter-spacing от родителя, парамерт из props
 */
export const useInput = (
  value,
  size,
  theme,
  error,
  disabled,
  clear,
  highlight,
  mask,
  prefix,
  prefixAliases,
  emit,
  listeners,
  maxLength,
  placeholderChar,
  isRequired,
  noMaskSpacing,
) => {
  const inputRef = ref()

  const innerValue = computed({
    get: () => {
      return getFitValue(unref(value), unref(maxLength))
    },
    set: (newValue) => {
      if (unref(value) === newValue) return

      // обработка вставки префикса по фокусу - чтобы не посылалось наверх исправленное значение
      // в режиме unmask: true -  '343 (' -> '343'
      // такой формат префикса приходится использовать,
      // т.к. в imask плохо работает eager mode
      if (
        prefixExists(unref(prefix)) &&
        unref(prefixAliases)?.includes(newValue) &&
        unref(value) === unref(prefix)
      )
        return

      emit('input', newValue)
    },
  })

  const onNativeInput = (event) => {
    innerValue.value = event.target.value
  }

  const nativeControlListeners = computed(() => {
    // исключение нативного input, т.к. он завязан на v-model и eмиттит единый input компонента
    const { input, ...activeNativeListeners } = listeners
    // переопределение change, т.к. он должен выбросить актуальное для копмонента значение
    activeNativeListeners.change = () => {
      nextTick(() => {
        emit('change', unref(value))
      })
    }
    return activeNativeListeners
  })

  const { isHovered, isFocused, hasValue, focus } = useInputState(
    inputRef,
    innerValue,
  )

  const { clearVisible, clearValue } = useClear(
    clear,
    innerValue,
    prefix,
    isFocused,
  )

  const { hasMask, maskPhantom, onMaskedInputAccept, onMaskedInputComplete } =
    useMaskWithPhantom(innerValue, mask, emit, placeholderChar)

  const { stateAttributes } = useStateAttributes({
    isHovered,
    isFocused,
    hasValue,
    disabled,
    error,
    highlight,
    isRequired,
    hasMask,
    noMaskSpacing,
  })

  const { mods } = useMods(size, theme)

  const {
    hasPrefix,
    onPrefixedInputFocus,
    onPrefixedInputBlur,
    onPrefixedPaste,
  } = usePrefix(innerValue, prefix, prefixAliases)

  return {
    uuid: getId(),
    inputRef,
    innerValue,
    onNativeInput,
    nativeControlListeners,
    stateAttributes,
    mods,

    isFocused,
    hasValue,
    focus,

    clearVisible,
    clearValue,

    hasMask,
    onMaskedInputAccept,
    onMaskedInputComplete,
    maskPhantom,

    hasPrefix,
    onPrefixedInputFocus,
    onPrefixedInputBlur,
    onPrefixedPaste,
  }
}

import { FILTER_ID, TYPES_MAIN, TYPES_MAIN_MOBILE } from '../../constant'

export const getters = () => ({
  getFilterModalDeferRendered() {
    return this.filterModalDeferRendered
  },

  getFiltersModalOpened() {
    return this.filtersModalOpened
  },

  getFiltersPending() {
    return this.filtersPending
  },

  getFilters() {
    return this.filters
      .map((filter) =>
        filter.id === FILTER_ID.SORTING ? this.getSorting?.filter : filter,
      )
      .filter(Boolean)
  },

  hasFilters() {
    return Boolean(this.filters?.length > 0)
  },

  getFiltersMain() {
    return this.getFilters.filter((filter) => TYPES_MAIN.includes(filter.type))
  },

  getFiltersMainMobile() {
    return this.getFilters.filter((filter) =>
      TYPES_MAIN_MOBILE.includes(filter.type),
    )
  },

  getFiltersMainSelected() {
    return this.filtersSelected.filter((filter) =>
      TYPES_MAIN.includes(filter.type),
    )
  },

  getFiltersMainMobileSelected() {
    return this.filtersSelected.filter((filter) =>
      TYPES_MAIN_MOBILE.includes(filter.type),
    )
  },

  hasFiltersSelected() {
    return this.filtersSelected?.length > 0
  },

  getSortingSourceFilter() {
    return this.filters.find((filter) => filter.id === FILTER_ID.SORTING)
  },

  getSortingValueId() {
    return this.filtersSelected.find(
      (filter) => filter.id === FILTER_ID.SORTING,
    )?.valueId
  },

  getSorting() {
    const filter = this.getSortingSourceFilter
    const valueId = this.getSortingValueId

    return {
      valueId,
      filter: filter && {
        ...filter,
        values: filter.values.map(
          ({ id, name, isDefault, isSelected, isEnabled }) => ({
            id,
            name,
            isEnabled,
            isSelected: valueId
              ? id === valueId
              : Boolean(isDefault || isSelected),
          }),
        ),
      },
    }
  },

  getExpressDelivery() {
    return this.filters.find(
      (filter) => filter.key === FILTER_ID.EXPRESS_DELIVERY,
    )
  },

  getPanelCheckboxes() {
    return [this.getExpressDelivery].filter(Boolean)
  },

  ordersTypeFilter() {
    return this.filters.find((filter) => filter.id === FILTER_ID.ORDER_TYPE)
  },

  ordersTypeValue() {
    return this.filtersSelected.find(
      (filter) => filter.id === FILTER_ID.ORDER_TYPE,
    )?.value
  },

  ordersType() {
    const filter = this.ordersTypeFilter
    const value = this.ordersTypeValue

    return {
      value,
      filter: filter && {
        ...filter,
        values: filter.values.map(({ id, name, isSelected, isEnabled }) => ({
          id,
          name,
          isEnabled,
          isSelected: value ? id === value : Boolean(isSelected),
        })),
      },
    }
  },

  getPageNumberCurrent() {
    const filter = this.filtersLocaly.find(
      (filter) => filter.id === FILTER_ID.PAGE_NUMBER,
    )

    return filter ? +filter.value : 1
  },

  getRequestFiltersFn() {
    return this.requestFiltersFn
  },

  getAreHashesEqualFn() {
    return this.areHashesEqualFn
  },

  getGetItemListNameFn() {
    return this.getItemListNameFn
  },

  getGetAnalyticDataFn() {
    return this.getAnalyticDataFn
  },

  formattedForOrdersFilterList() {
    const result = {}
    this.filters.forEach((item) => {
      const selectedFilter = item.values
        .filter((value) => value.isSelected)
        ?.shift()
      if (selectedFilter) {
        result[item.name] = selectedFilter.name
      }
    })
    return result
  },
})

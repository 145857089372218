import { computed, unref } from 'vue'

import { usePhantom } from '../use'

/**
 * Работает вместе с Vue2 компонентом плагина https://www.npmjs.com/package/vue-imask
 * возвращает обработчики событий инпута, текст плейсхолдера маски
 * @param {Computed<String>} value - ссылка на внутреннее computed значение инпута
 * @param {Ref<(String|Object)>} mask - ссылка на значение mask
 * @param {Funciton} emit - emit функция контекста
 * @param {String} placeholderChar - символ для плейсхолдера маски
 */

const useWithPhantom = (value, mask, emit, placeholderChar) => {
  const hasMask = computed(() => {
    return !!unref(mask)
  })

  const onMaskedInputAccept = (acceptedValue) => {
    value.value = acceptedValue
  }

  const onMaskedInputComplete = (completedValue) => {
    emit('inputMaskComplete', completedValue)
  }

  const { maskPhantom } = usePhantom(value, mask, placeholderChar)

  return {
    hasMask,
    onMaskedInputAccept,
    onMaskedInputComplete,
    maskPhantom,
  }
}

export { useWithPhantom }

import { SettingAddressBuilder } from '../../builders'
import { ACTION_TYPES, SCREEN_TYPES } from '../../constants'
import { BaseController } from '../base.controller/base.controller'

export class LocationAnalytics extends BaseController {
  /**
   * Открытие модалки подтверждения адреса пользователя
   */
  onModalConfirmLocationShow() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.MODAL_SHOW,
      screenType: SCREEN_TYPES.HOME,
    })
  }

  /**
   * Закрытие модалки подтверждения адреса пользователя
   */
  onModalConfirmLocationClose() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.MODAL_CLOSE,
      screenType: SCREEN_TYPES.HOME,
    })
  }

  /**
   * Нажатие "выбрать другой" модалки подтверждения адреса пользователя
   */
  onModalConfirmLocationChangeAddress() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.MODAL_CLICK_CHANGE_ADDRESS,
      screenType: SCREEN_TYPES.HOME,
    })
  }

  /**
   * Нажатие "да, верно" модалки подтверждения адреса пользователя
   */
  onModalConfirmLocationConfirm() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.MODAL_CLICK_YES,
      screenType: SCREEN_TYPES.HOME,
    })
  }

  /**
   * Открытие окна выбора адреса
   */
  onSpecifySelectModalOpen(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.SELECT_ADDRESS_OPEN,
      screenType,
    })
  }

  /**
   * Закрытие окна выбора адреса доставки
   */
  onSpecifySelectModalClose(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.SELECT_ADDRESS_CLOSE,
      screenType,
    })
  }

  /**
   * Выбрал другой адрес
   */
  onSpecifySelectModalAnotherAddress(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.SELECT_ANOTHER_ADDRESS,
      screenType,
    })
  }

  /**
   * Нажал кнопку “Привезти сюда”
   */
  onSpecifySelectModalApply(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.SELECT_ADDRESS_APPLY,
      screenType,
    })
  }

  /**
   * Нажал “Добавить адрес”
   */
  onSpecifySelectModalClickAddAddress(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CLICK_ADD_ADDRESS,
      screenType,
    })
  }

  /**
   * Открытие окна “Адрес доставки”, “уточните адрес доставки”
   */
  onSpecifyEditModalOpen(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.ADD_ADDRESS_OPEN,
      screenType,
    })
  }

  /**
   * Закрытие окна “Адрес доставки”, “уточните адрес доставки”
   */
  onSpecifyEditModalClose(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.ADD_ADDRESS_CLOSE,
      screenType,
    })
  }

  /**
   * Выбрал другой город
   */
  onSpecifyEditModalChangeCity(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CHANGE_CITY,
      screenType,
    })
  }

  /**
   * Заполнил адрес
   */
  onSpecifyEditModalChangeAddress(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CHANGE_ADDRESS,
      screenType,
    })
  }

  /**
   * Нажал кнопку “Привезти сюда”
   */
  onSpecifyEditModalApply(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.ADD_ADDRESS_APPLY,
      screenType,
    })
  }

  /**
   * Клик на адресе
   */
  onClickChangeAddress(screenType = SCREEN_TYPES.HOME) {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CLICK_CHANGE_ADDRESS,
      screenType,
    })
  }

  /**
   * Выбрал “Изменить адрес”
   */
  onClickEditAddress() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CLICK_EDIT_ADDRESS,
      screenType: SCREEN_TYPES.ACCOUNT_MY_ADDRESSES,
    })
  }

  /**
   * Выбрал “Удалить адрес”
   */
  onClickDeleteAddress() {
    this.send(SettingAddressBuilder, {
      actionType: ACTION_TYPES.CLICK_DELETE_ADDRESS,
      screenType: SCREEN_TYPES.ACCOUNT_MY_ADDRESSES,
    })
  }
}

<template>
  <svg viewBox="0 0 8 16">
    <path
      d="M2.718 15.602s.529-5.862.628-6.713H.4L5.64.402 5.03 6.23H8l-5.282 9.373Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-lightning',
}
</script>

import { events as notifyEventBus } from './scripts/events'
import { GaNotifications } from '.'

class Notifier {
    installed = false

    params = {}

    constructor(emitter) {
        this.emitter = emitter || notifyEventBus
    }

    // глобальная регистрация компонента нотификаций
    _registerComponent(Vue) {
        Vue.component(this.params.componentName || 'ga-notifications', GaNotifications)
    }

    // запись в прототип методов уведомлений
    _injectToVue(Vue) {
        const name = this.params.name || 'notifier'

        const funcWrapper = this.open
        funcWrapper.close = this.close

        Vue.prototype[`$${name}`] = funcWrapper
        Vue[name] = funcWrapper
    }

    // открытие уведомлений
    open(params) {
        if (typeof params === 'string') {
            this.emitter.$emit('add', { text: params })
        }

        if (typeof params === 'object') {
            this.emitter.$emit('add', params)
        }
    }

    // закрытие уведомлений по id
    close(id) {
        this.emitter.$emit('close', id)
    }

    closeAll() {
        this.emitter.$emit('closeAll')
    }

    // методы для работы с нотификациями
    get notifications() {
        return {
            open: (params) => this.open(params),
            close: (id) => this.close(id),
            closeAll: () => this.closeAll(),
        }
    }

    // для использования во Vue.use через прототип
    install(Vue, args = {}) {
        if (this.installed) {
            return
        }

        this.installed = true
        this.params = args

        this._registerComponent(Vue)
        this._injectToVue(Vue)
    }
}

export { Notifier }

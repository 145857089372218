import { useContext } from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'

import { unref } from 'vue'

import { useApp } from '~/modules/app'

/**
 * Функция для предварительной загрузки данных при наведении на продукт с ссылкой на листинг отзывов.
 * @return {Object} Объект, содержащий функцию для обработки события наведения курсора на отзыв.
 */
export const usePrefetch = () => {
  const { $gaApp } = useContext()
  const { appWithHover } = useApp()

  const itemsPrefetch = []
  const onReviewMouseEnter = unref(appWithHover)
    ? debounce((product) => {
        const productId = product?.itemId || product?.id
        // Предварительно загружаем, только если идентификатор элемента не находится в массиве.
        if (!itemsPrefetch.includes(productId)) {
          itemsPrefetch.push(productId)

          $gaApp.eventBus.publish('module/review/prefetch', {
            productId,
          })
        }
      }, 200)
    : () => {}

  return {
    onReviewMouseEnter,
  }
}

import { breakpoints } from '../../helpers/breakpoints'

const windowSize = {
  innerWidth: 0,
  innerHeight: 0,
}

export const getWindowStateInitial = () => ({
  currentBreakpoint: 'fullhd',
  breakpoints,

  windowSize,
  windowResizing: false,
  windowSizeLazy: windowSize,

  viewportExcludedHeaderRef: null,
})

export default () => {
  return getWindowStateInitial()
}

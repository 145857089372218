export const SETTINGS_SOON_AE = {
  phones: {
    support: {
      value: '+97441412727',
      text: '+974-4141-2727',
      href: 'tel:+97441412727',
    },
  },
  socials: [
    {
      text: 'FaceBook',
      href: 'https://www.facebook.com/profile.php?id=61555725406121',
      icon: 'https://cdn.goldapple.qa/qap/icons/fb.png',
    },
    {
      text: 'Instagram',
      href: 'https://www.instagram.com/goldapple_me',
      icon: 'https://cdn.goldapple.qa/qap/icons/inst.png',
    },
    {
      text: 'TikTok',
      href: 'https://www.tiktok.com/@goldapple_me?_t=8jA9PuA9FZp&_r=1',
      icon: 'https://cdn.goldapple.qa/qap/icons/tik-tok.png',
    },
    {
      text: 'Snapchat',
      href: 'https://t.snapchat.com/T1tMmLF0',
      icon: 'https://cdn.goldapple.qa/qap/icons/snapchat.png',
    },
  ],
  messengers: {
    whatsapp: {
      text: 'WhatsApp',
      href: 'https://wa.me/97431190811',
      isDefault: true,
    },
  },
  languageDefaultCode: 'en',
  languages: [
    {
      code: 'ar',
      title: 'عربي',
      desktopTitle: 'عربي',
      domain: 'https://ar.goldapple.ae',
      isRtl: false,
    },
    {
      code: 'en',
      title: 'English',
      desktopTitle: 'eng',
      domain: 'https://en.goldapple.ae',
      isRtl: false,
    },
  ],
}

import { NuxtBase } from './base'
export class NuxtWebview extends NuxtBase {
  initAppInterceptors() {
    this.gaApp.api.addRequestCallback({
      success: (config) => {
        if (config.method === 'get' && this.gaApp.i18n.locales.length > 1) {
          config.params = {
            ...config.params,
            locale: this.gaApp.i18n.locale.code,
          }
        }

        // добавляем параметр с сегментами во все гет запросы
        if (
          config.method === 'get' &&
          this.gaApp.services.featuresToggle.main.getZSegments()
        ) {
          config.params = {
            ...config.params,
            z: this.gaApp.services.featuresToggle.main.getZSegments(),
          }
        }

        return config
      },
    })
  }

  /**
   * Метод проверки поддержки media (hover)
   */
  detectHoverMedia() {
    this.gaApp.services.app.browserWindow.detectWindowHoverMedia()
  }

  /**
   * Метод инициализации слушателя события `orientationchange` и `resize`
   */
  initWindowResizeListener() {
    this.gaApp.services.app.browserWindow.bindWindowResizeListeners()
  }

  /**
   * Метод инициализации слушателей для событий
   * keyboard, mousedown, mouseup, click, keydown, focusin
   */
  initKeyboardAndPointerListerner() {
    this.gaApp.services.app.browserWindow.bindKeyboardAndPointerListerners()
  }

  initCurrentBreakpoint() {
    this.gaApp.services.app.window.setCurrentBreakpoint()
  }

  /**
   * Метод предположения локации пользователя
   */
  getUserLocation() {
    this.gaApp.services.location.main.setLocationDefault()
  }

  /**
   * Метод получения подтверждения о 18+
   */
  getUserProofAge() {
    // this.gaApp.services.cart.main.fillData()
    this.gaApp.services.user.main.getProofAge()
  }

  /**
   * Метод устанавливает id-сеанса пользователя
   */
  setUserViewGUID() {
    this.gaApp.services.user.main.setUserViewGUID()
  }

  /**
   * Метод инициализации аналитик
   */
  initAnalytics() {
    this.gaApp.analytics.libs.initDataLayer()
    this.gaApp.analytics.libs.initAllWithPermissionsChecks()
  }

  /**
   * Метод заполнения сторов первичными данными страницы
   */
  async prepareData() {
    await Promise.allSettled([
      this.gaApp.services.featuresToggle.main.init(),
      this.gaApp.services.app.api.getCommonData(),
    ])

    this.getUserLocation()
    this.getUserProofAge()
  }

  // eslint-disable-next-line max-statements
  async init() {
    this.gaApp.services.app.main.initWebview()

    this.initAppInterceptors()

    await this.prepareData()

    this.initWindowResizeListener()

    this.initCurrentBreakpoint()

    this.detectHoverMedia()
    this.initKeyboardAndPointerListerner()

    this.setLocale()

    this.setUserViewGUID()

    this.initAnalytics()

    this.gaApp.router.afterEach(() => {
      if (!this.gaApp.isInit) {
        this.gaApp.eventBus.publish('module/app/init')

        this.gaApp.stores.app.main.init = true

        const { isSSR, withHover } = this.gaApp.stores.app
        const { currentBreakpoint, windowSize } = this.gaApp.stores.app.window

        this.gaApp.services.app.apm.setCustomContext({
          app: {
            isSSR,
          },
        })

        this.gaApp.services.app.apm.setCustomContext({
          device: {
            withHover,
            currentBreakpoint,
            ...windowSize,
          },
        })
      }
    })
  }
}

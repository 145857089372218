import { TYPE } from '../../constants'
import {
  AdvcakeThankYouViewModel,
  DigiThankYouViewModel,
  GtmGa4ThankYouViewModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ThankYouViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ThankYouViewModel],
      [TYPE.DIGI, DigiThankYouViewModel],
      [TYPE.ADVCAKE, AdvcakeThankYouViewModel],
    ])

    super(models, data)
  }
}

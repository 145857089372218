/**
 * основано на решении https://stackoverflow.com/a/55652503
 * @param {Object} [options] параметры
 * @param {String} [options.enterkeyhint] - https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
 * @param {String} [options.type] - тип инпута
 * @param {Number} [options.awaitTime] - время ожидания перехвата фокуса, затем инпут надо удалить
 */
export const iosKeyboardOpen = ({
  enterkeyhint,
  type,
  awaitTime = 1000,
} = {}) => {
  if (!document) return

  const invisibleInput = document.createElement('input')
  invisibleInput.setAttribute('type', type || 'text')
  invisibleInput.setAttribute('enterkeyhint', enterkeyhint || 'enter')
  invisibleInput.style.position = 'fixed'
  invisibleInput.style.left = 0
  invisibleInput.style.top = 0
  invisibleInput.style.opacity = 0
  invisibleInput.style.height = 0
  invisibleInput.style.fontSize = '16px'

  const removeInvisibleInput = () => {
    invisibleInput.removeEventListener('blur', removeInvisibleInput)
    invisibleInput.remove()
  }

  invisibleInput.addEventListener('blur', removeInvisibleInput)

  document.body.prepend(invisibleInput)

  // фокус, чтобы дальнейший целевой программный фокус сработал
  invisibleInput.focus()

  // если перехватить фокус не успели, удаляем
  setTimeout(() => {
    invisibleInput.blur()
  }, awaitTime)
}

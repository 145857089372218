import { computed, unref } from 'vue'

import { SIZE as SIZE_ATTRIBUTES } from '../../../../molecules/attributes/scripts/consts'
import { SIZE as SIZE_CART } from '../../../../molecules/cart/scripts/consts'
import { SIZE as SIZE_COUPON } from '../../../../molecules/coupon/scripts/consts'
import { SIZE as SIZE_DISCLAIMER } from '../../../../molecules/disclaimer/scripts/consts'
import { SIZE as SIZE_DOLYAME } from '../../../../molecules/dolyame/scripts/consts'
import { SIZE as SIZE_FAVORITE } from '../../../../molecules/favorite/scripts/consts'
import { SIZE as SIZE_LABELS } from '../../../../molecules/labels/scripts/consts'
import { SLIDER_DOTS_SIZE } from '../../../../molecules/media/scripts/consts'
import { SIZE as SIZE_RATING } from '../../../../molecules/rating/scripts/consts'
import { SIZE } from '../consts'

export const useSizes = (size) => {
  const sizes = computed(() => ({
    dolyame: {
      [SIZE.XS]: SIZE_DOLYAME.XS,
      [SIZE.S]: SIZE_DOLYAME.XS,
      [SIZE.M]: SIZE_DOLYAME.S,
      [SIZE.L]: SIZE_DOLYAME.S,
    }[unref(size)],

    coupon: {
      [SIZE.XS]: {
        size: SIZE_COUPON.S,
      },
      [SIZE.S]: {
        size: SIZE_COUPON.S,
      },
      [SIZE.M]: {
        size: SIZE_COUPON.S,
      },
      [SIZE.L]: {
        size: SIZE_COUPON.M,
      },
    }[unref(size)],

    cart: {
      [SIZE.XS]: SIZE_CART.S,
      [SIZE.S]: SIZE_CART.S,
      [SIZE.M]: SIZE_CART.S,
      [SIZE.L]: SIZE_CART.M,
    }[unref(size)],

    favorite: {
      [SIZE.XS]: SIZE_FAVORITE.S,
      [SIZE.S]: SIZE_FAVORITE.S,
      [SIZE.M]: SIZE_FAVORITE.S,
      [SIZE.L]: SIZE_FAVORITE.M,
    }[unref(size)],

    labels: {
      [SIZE.XS]: SIZE_LABELS.S,
      [SIZE.S]: SIZE_LABELS.S,
      [SIZE.M]: SIZE_LABELS.S,
      [SIZE.L]: SIZE_LABELS.M,
    }[unref(size)],

    sliderDots: {
      [SIZE.XS]: SLIDER_DOTS_SIZE.XXS,
      [SIZE.S]: SLIDER_DOTS_SIZE.XXS,
      [SIZE.M]: SLIDER_DOTS_SIZE.XXS,
      [SIZE.L]: SLIDER_DOTS_SIZE.XS,
    }[unref(size)],

    disclaimer: SIZE_DISCLAIMER.XXS,

    attributes: {
      [SIZE.XS]: SIZE_ATTRIBUTES.S,
      [SIZE.S]: SIZE_ATTRIBUTES.S,
      [SIZE.M]: SIZE_ATTRIBUTES.S,
      [SIZE.L]: SIZE_ATTRIBUTES.M,
    }[unref(size)],

    rating: {
      [SIZE.XS]: SIZE_RATING.M,
      [SIZE.S]: SIZE_RATING.M,
      [SIZE.M]: SIZE_RATING.M,
      [SIZE.L]: SIZE_RATING.L,
    }[unref(size)],

    price: {
      [SIZE.XS]: {
        fontSize: 14,
        threshold: 6,
      },
      [SIZE.S]: {
        fontSize: 16,
        threshold: 6,
      },
      [SIZE.M]: {
        fontSize: 16,
        threshold: 9,
      },
      [SIZE.L]: {
        fontSize: 20,
        threshold: 9,
      },
    }[unref(size)],
  }))

  return {
    sizes,
  }
}

<template>
  <svg viewBox="0 0 15 15">
    <path fill="none" stroke-width="1.5" d="m3 5 4.5 4.5L12 5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-select-arrow',
}
</script>

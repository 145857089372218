import { computed, nextTick, ref, watch } from 'vue'

export const useItemsSize = (itemsRef, skeleton) => {
  const itemsWrapperRef = ref(null)

  const itemWidth = ref(null)
  const itemHeight = ref(null)

  const itemInnerStyle = computed(() => {
    let width = 'auto'
    let height = 'auto'

    if (skeleton.value) {
      return { width, height }
    }

    if (itemWidth.value !== null) {
      width = `${itemWidth.value}px`
    }

    if (itemHeight.value !== null) {
      height = `${itemHeight.value}px`
    }

    return { width, height }
  })

  const updateItemWidth = () => {
    if (skeleton.value) return

    if (itemsRef.value.length === 0) {
      return
    }

    const $item = itemsRef.value.find((item) => item.clientWidth > 0)
    const width = $item?.clientWidth

    if (width > 0) {
      itemWidth.value = width
    }
  }

  const updateItemHeight = () => {
    // eslint-disable-next-line unicorn/no-array-reduce
    itemHeight.value = itemsRef.value.reduce((acc, item) => {
      const { clientHeight } = item

      if (clientHeight > acc) {
        return clientHeight
      }

      return acc
    }, null)
  }

  const resetItemsSize = () => {
    itemWidth.value = null
    itemHeight.value = null
  }

  watch(itemWidth, (newValue) => {
    if (!newValue) return

    nextTick(updateItemHeight)
  })

  return {
    itemsRef,
    itemsWrapperRef,

    itemWidth,
    itemHeight,

    itemInnerStyle,

    updateItemWidth,
    updateItemHeight,
    resetItemsSize,
  }
}

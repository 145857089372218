import { TYPE } from '../../constants'
import { GtmChosePayMethodModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ChosePayMethodBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmChosePayMethodModel]])
    super(models, data)
  }
}

/**
 * Возвращает IANA значение таймзоны по переданной GMT строке
 * @param {string} gmt - значение таймзоны в GMT формате
 * @param {string} country - значение страны в iso формате
 * @returns {string} - значение таймзоны в IANA формате
 */

export const GMT_TO_IANA_COUNTRIES = {
  RU: 'ru',
  BY: 'by',
  KZ: 'kz',
  QA: 'qa',
  AE: 'ae',
}

export function GMTToIANA(gmt, country) {
  const timezoneIANA = {
    [GMT_TO_IANA_COUNTRIES.RU]: {
      'GMT+3': 'Europe/Moscow',
      'GMT+2': 'Europe/Kaliningrad',
      'GMT+4': 'Europe/Samara',
      'GMT+5': 'Asia/Yekaterinburg',
      'GMT+6': 'Asia/Omsk',
      'GMT+7': 'Asia/Krasnoyarsk',
      'GMT+8': 'Asia/Irkutsk',
      'GMT+9': 'Asia/Yakutsk',
      'GMT+10': 'Asia/Vladivostok',
      'GMT+11': 'Asia/Srednekolymsk',
      'GMT+12': 'Asia/Kamchatka',
    },
    [GMT_TO_IANA_COUNTRIES.BY]: {
      'GMT+3': 'Europe/Minsk',
    },
    [GMT_TO_IANA_COUNTRIES.KZ]: {
      'GMT+5': 'Asia/Aqtobe',
      'GMT+6': 'Asia/Almaty',
    },
    [GMT_TO_IANA_COUNTRIES.QA]: {
      'GMT+3': 'Asia/Qatar',
    },
    [GMT_TO_IANA_COUNTRIES.AE]: {
      'GMT+4': 'Asia/Dubai',
    },
  }[country]?.[gmt]

  if (!timezoneIANA) console.error('GMTToIANA: timezoneIANA is not founded')

  return timezoneIANA
}

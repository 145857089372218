<template>
  <svg viewBox="0 0 10 18">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="m.47 8.47 8-8 1.06 1.06L2.06 9l7.47 7.47-1.06 1.06-8-8L-.06 9l.53-.53z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-back-arrow-modal',
}
</script>

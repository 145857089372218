import { computed, ref } from 'vue'

import { GaImageResponsive } from '../image-responsive'

import { OBJECT_FIT } from './scripts/const'
import { useMediaControls } from './scripts/use/mediaControls'
import { useSource } from './scripts/use/source'
import { useTracks } from './scripts/use/tracks'

export default {
  name: 'ga-video-native',

  components: {
    GaImageResponsive,
  },

  props: {
    // строка, объект или массив URL видеофайлов.
    url: {
      type: [String, Object, Array],
      default: '',
    },
    // Объект с опциями управления видео
    options: {
      type: Object,
      required: true,
    },
    // Класс CSS для стилизации видео
    className: {
      type: String,
      default: '',
    },
    // URL изображения обложки для видео
    posterUrl: {
      type: Object,
      default: () => ({}),
    },
    // Определяет, как видео должно заполнять контейнер
    objectFit: {
      type: String,
      default: OBJECT_FIT.COVER,
      validator: (value) => Object.values(OBJECT_FIT).includes(value),
    },
    // рендер видео компонента
    needRenderVideo: {
      type: Boolean,
      default: true,
    },
    // субтитры для видео
    trackUrl: {
      type: String,
      default: '',
    },
    // Скрыть/показать субтитры
    hideTracks: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const videoRef = ref(null)

    useSource(videoRef, props)
    useTracks(videoRef, props, emit)

    const {
      currentTime,
      duration,
      muted,
      buffered,
      ended,
      playing,
      canPlay,
      restartVideo,
      togglePlay,
      toggleMuted,
      play,
      pause,
      stop,
      mute,
      unmute,
    } = useMediaControls(videoRef, emit)

    const mods = computed(() => {
      return {
        showed: canPlay.value && !ended.value,
      }
    })

    return {
      mods,

      videoRef,

      ended,
      playing,

      // Публичные методы плеера
      togglePlay,
      toggleMuted,
      restartVideo,
      play,
      pause,
      stop,
      currentTime,
      duration,
      muted,
      mute,
      unmute,
      buffered,
    }
  },
}

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsCategoryClickModel extends GaAnalyticsBaseModel {
  constructor({ blockPosition, link, blockName }) {
    super('listing')

    const data = {
      itemListName: `главная/block: ${blockName}/listing`,
      itemListId: `главная/block_${blockPosition}`,
      url: link,
    }

    this.category = data

    this.debug = {
      key: 'home-category-click',
      data,
    }
  }
}

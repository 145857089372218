export const endpoints = {
  ordersListingV3: () => ({
    url: `customer/account/orders/listing-v3`,
  }),
  filtersOrdersV3: () => ({
    url: `customer/account/orders/filters-v3`,
  }),
  ordersV3: () => ({
    url: `customer/account/orders/orders-v3`,
  }),
  orderDetailV3: () => ({
    url: 'customer/account/orders/orderdetail-v3',
  }),
  cancelOrderV3: () => ({
    url: 'customer/account/orders/cancelorder-v3',
  }),
  canCancelOrderV3: () => ({
    url: 'customer/account/orders/cancancelorder-v3',
  }),
  getChangeDeliveryInfo: () => ({
    url: 'customer/account/orders/delivery/info',
  }),
  changeDeliveryDate: () => ({
    url: 'customer/account/orders/delivery/changedate',
  }),
  onDemandOrderV3: () => ({
    url: `customer/account/orders/ondemand-v3`,
  }),
  onDemandOrderDetailV3: () => ({
    url: 'customer/account/orders/orderdetail/ondemand-v3',
  }),

  getCities: () => ({
    url: 'retail-stores/cities',
  }),

  getInfo: () => ({
    url: 'customer/info',
  }),
  updateInfo: () => ({
    url: 'customer/info/update',
  }),

  emailConfirm: () => ({
    url: 'customer/account/email-confirm',
  }),
  getLoyalty: () => ({
    url: 'customer/account/giftcards',
  }),
  subscriptionsSettings: () => ({
    url: 'customer/account/settings/subscriptions',
  }),
  getChangeStorageInfo: () => ({
    url: 'customer/account/orders/storage/info',
  }),
  changeStorageDate: () => ({
    url: 'customer/account/orders/storage/changedate',
  }),
}

<template>
  <svg viewBox="0 0 11 11">
    <path stroke-width="1.25" d="M0 5.5h11M5.5 11V0" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-plus',
}
</script>

import { computed } from 'vue'

import { GaIconAdditionalCross } from '@ga/icons'

import { GaAction } from '../action'
import { GaButtonIconOnly } from '../button-icon-only'
import { GaIcon } from '../icon'

import { LINK, SIZE } from './scripts/const'

// @vue/component
export default {
  name: 'ga-notification',

  components: {
    GaAction,
    GaButtonIconOnly,
    GaIcon,
    GaIconAdditionalCross,
  },

  props: {
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    hasMinLinesCount: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Object,
      default: () => ({}),
      validator: (value) =>
        Object.keys(value).map((key) => Object.values(LINK).includes(key)),
    },
    size: {
      type: String,
      default: SIZE.XS,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    text: {
      type: String,
      default: null,
    },
    alert: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  setup(props, context) {
    const onCloseButtonClick = () => {
      context.emit('close')
    }

    const mods = computed(() => {
      return {
        closable: props.hasCloseButton,
        expanded: props.hasMinLinesCount,
        size: props.size,
        alert: props.alert,
      }
    })

    return {
      mods,
      onCloseButtonClick,
    }
  },
}

export const useHandlerTimer = () => {
  const pauseItemTimer = (inst) => {
    inst.timer.pause()
  }
  const resumeItemTimer = (inst) => {
    inst.timer.resume()
  }

  const autoCloseNotification = {}

  const onNotificationMouseenter = (item) => {
    autoCloseNotification.pause(item)
  }

  const onNotificationMouseleave = (item) => {
    autoCloseNotification.resume(item)
  }

  const initNotification = () => {
    autoCloseNotification.pause = pauseItemTimer
    autoCloseNotification.resume = resumeItemTimer
  }

  return {
    pauseItemTimer,
    resumeItemTimer,
    onNotificationMouseenter,
    onNotificationMouseleave,
    initNotification,
  }
}

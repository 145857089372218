export class DigiAnalyticsWidgetBaseModel {
  constructor(data) {
    this.data = {
      strategy: this.generateStrategy(data.placement),
      pageProducts: data.placement?.products?.map((product) => {
        return product.itemId
      }),
    }
  }

  generateStrategy(placement) {
    const strategy = []

    //разделили для генерации без привязки ко всем полям
    if (placement.name) {
      strategy.push(placement.name)
    }
    if (placement.id) {
      strategy.push(placement.id)
    }
    if (placement.strategyName) {
      strategy.push(placement.strategyName)
    }

    return strategy.join('|')
  }
}

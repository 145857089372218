import bemCn from 'bem-cn-lite'

import { getCssModulesClassNames } from './getCssModulesClassNames'

export const bemClassNamePlugin= {
  install(Vue, config = {}) {
    const cfg = {
      hyphenate: false,
      methodName: 'b',
      ...config,
      delimiters: {
        ...(config && config.delimiters ? config.delimiters : {}),
      },
    }

    Vue.mixin({
      created() {
        const block = this.$options.block || this.$options.name

        if (!this.b && typeof block === 'string' && block.startsWith('ga-')) {
          let generator = null

          generator = bemCn(block, cfg)

          this[cfg.methodName] = (...args) => {
            const classNames = generator(...args)

            // Проверяем есть ли в компоненте css modules
            if (this.$style) {
              // Если есть получаем новые имена классов
              return getCssModulesClassNames(this.$style, classNames)
            }

            return classNames
          }
        }
      },
    })
  },
}

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.GTM_4]: this.core.all?.gtm,
    }
  }

  // Запросить данные из сервисов аналитики
  get(Builder, data) {
    const requests = [...new Builder(data).build()].flatMap(([key, data]) => {
      const metric = this.metrics[key]

      return metric.get
        ? [metric.get(data).then(({ data }) => ({ key, data: data.data }))]
        : []
    })

    return Promise.allSettled(requests)
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]
      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

export const endpoints = {
  category: () => ({
    url: 'catalog/category',
  }),
  listingFilters: () => ({
    url: 'catalog/filters',
  }),
  searchFilters: () => ({
    url: 'catalog/search-filters',
  }),
  listingProducts: () => ({
    url: 'catalog/products',
  }),
  searchProducts: () => ({
    url: 'catalog/search-products',
  }),
  actual: () => ({
    url: 'catalog/products-actual',
  }),
  slots: () => ({
    url: 'catalog/product/slots',
  }),
  slotsV2: () => ({
    url: 'catalog/product/slots-v2',
  }),
  checkBrandzone: () => ({
    url: 'catalog/check-brandzone',
  }),
}

import { PAGINATION_DIRECTION } from '../../constant'

const DEFAULT_COLOR = '#000000'

export const getCategoryDataStateInitial = () => ({
  id: 0,
  name: '',
  isAdult: false,

  imageUrl: {
    url: '',
    format: [],
    screen: [],
  },

  viewOptions: {
    textColor: DEFAULT_COLOR,
    isHighlighted: false,
    isBlackFriday: false,
  },

  layout: [],

  breadcrumbs: [
    {
      href: '',
      text: '',
    },
  ],

  seo: {
    title: '',
    text: '',
    meta: [],
  },

  children: [],
})

export const state = () => {
  return {
    pageData: {},
    categoryData: getCategoryDataStateInitial(),

    pages: [],

    pagination: {
      [PAGINATION_DIRECTION.NEXT]: {
        pending: false,
        error: false,
      },
      [PAGINATION_DIRECTION.PREV]: {
        pending: false,
        error: false,
      },
    },

    productsCount: 0,
    marketingProductsCount: 0,

    productsHash: '',

    isSimpleLayout: false,
    isLayoutAnimating: false,

    scrollAnchor: null,
    listingTempData: null,
    analyticsData: {
      itemListName: null,
    },
    searchQueryError: false,
    searchPlacements: [],
    searchQueryCorrected: '',
    searchResultId: null,

    brandzoneSlots: null,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { onBeforeUnmount, ref, toRefs } from 'vue'

import { getId } from '@ga/utils'

import { GaModal } from '@ga/ui-components/modal/modal'

import { useModal } from '~/_base/use/modal'

// @vue/component
export default {
  name: 'ga-modal',

  components: {
    GaModalBase: GaModal,
  },

  inheritAttrs: false,

  model: {
    prop: 'opened',
    event: 'toggle',
  },

  props: {
    modalId: {
      type: String,
      default: null,
    },
    opened: {
      type: Boolean,
      required: true,
    },

    layoutHide: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()
    const { layoutHide, opened } = toRefs(props)

    const modal = ref(null)
    const uniqueId = props.modalId ?? getId()

    const {
      zIndex,
      onStartOpen,
      onFinishClose,
      onStartClose,
      onFinishOpen,
      onLeaveCancelled,
      internalOpened,
    } = useModal({ uniqueId, layoutHide, opened })

    onBeforeUnmount(() => {
      $gaApp.services.modal.register.remove(uniqueId)
    })

    return {
      modal,
      internalOpened,
      zIndex,
      onStartOpen,
      onFinishClose,
      onStartClose,
      onFinishOpen,
      onLeaveCancelled,

      // публичные методы
      scrollTo: (...args) => {
        modal.value.scrollTo(...args)
      },
      getScrollTop: () => {
        return modal.value.getScrollTop()
      },
      getScrollHeight: () => {
        return modal.value.getScrollHeight()
      },
    }
  },
}

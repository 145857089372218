/**
 * Стратегия-заглушка.
 *
 * @return {{ isVideoRendered: boolean }} - объект со свойством `isVideoRendered` равен `false`.
 */
export const useNoneStrategy = () => {
  return {
    isVideoRendered: false,
  }
}

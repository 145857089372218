export class LocationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Установка массива городов для заказа КППК
   * @param {Array} cities города, в которых возможен заказ КППК
   */
  setCities(cities) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.cities = cities
  }

  /**
   * Установка выбранного города для заказа КППК
   * @param {string} cityId идентификатор города
   */
  setCurrentCityId(cityId) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.city = cityId
  }

  /**
   * Смена города для заказа КППК
   * @param {string} cityId идентификатор города
   */
  async changeCurrentCityId(cityId) {
    if (this.gaApp.stores.giftCardsB2b.plasticNew.config.city === cityId) return

    /** Установка id города в store */
    this.setCurrentCityId(cityId)

    /** Получение конфигурации по выбранному городу */
    await this.updateConfigData(cityId)

    /** Вывод уведомления о сбросе выбранных наборов в заказе */
    this.gaApp.services.notification.main.open(
      this.gaApp.i18n.t('giftCardsB2b.plasticNew.city.notification'),
    )

    /** Сброс шага */
    this.gaApp.services.giftCardsB2b.steps.setCurrent(0)

    /** Сброс наборов */
    this.gaApp.services.giftCardsB2b.plasticSets.reset()
  }

  /** Установка города по умолчанию из конфигурации */
  setDefaultCity() {
    /** Если город уже выбран пользователем, то используем его, а не дефолтный */
    if (this.gaApp.stores.giftCardsB2b.plasticNew.config.city) return

    const defaultCityId =
      this.gaApp.stores.giftCardsB2b.plasticNew.config.cities.find(
        (city) => city.default,
      )?.value

    if (!defaultCityId) return

    this.setCurrentCityId(defaultCityId)
  }

  /**
   * Повторный запрос для обновления конфигурации по выбранному городу
   * @param {string} cityId идентификатор города
   */
  async updateConfigData(cityId) {
    await this.gaApp.services.giftCardsB2b.api.getPlasticNewConfig(cityId)
  }
}

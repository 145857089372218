export const EXTENSION = {
  AVIF: 'avif',
  WEBP: 'webp',
  SVG: 'svg',
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
}

export const EXTENSION_PRIORITY = {
  [EXTENSION.AVIF]: 0,
  [EXTENSION.WEBP]: 1,
  [EXTENSION.SVG]: 2,
  [EXTENSION.PNG]: 3,
  [EXTENSION.JPG]: 4,
  [EXTENSION.JPEG]: 5,
}

export const EXTENSION_SAFE = {
  [EXTENSION.PNG]: true,
  [EXTENSION.JPG]: true,
  [EXTENSION.JPEG]: true,
}

// Находит в строке совпадения по паттерну ${text}
// Пример в песочнице: https://regexr.com/6k8pd
const regularExpression = /\${(\w*)}/g
export const PATTERN = new RegExp(regularExpression)

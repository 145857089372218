export class RetailStoresRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getCities(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getCities()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получение списка городов и описания для модалки с выбором города
   */
  async getCitiesModalData() {
    const { url } = this.endpoints.getCitiesModalData()

    try {
      const response = await this.gaApp.api.request().get(url, {})

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

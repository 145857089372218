export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getPageMainData() {
    await this.getCities()
    this.getAllStores()
    // запрос данных для дефолтного города
    await this.getCityStores()
  }

  async getCities() {
    try {
      const { cities, sorted: citiesSorted } =
        await this.gaApp.services.location.retailStores.fetchCities()

      const baseCity = this.gaApp.services.location.retailStores.getBaseCity({
        cities,
        citiesSorted,
      })

      this.setCities({ cities, citiesSorted })
      this.setSelectedCity({
        id: baseCity.value,
        label: baseCity.text,
      })
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('stockists.error.default'),
      )

      throw error
    }
  }

  setSelectedCity({ id, label }) {
    this.gaApp.stores.stockists.main.selectedCityId = id
    this.gaApp.stores.stockists.main.selectedCity = label
  }

  setCities({ cities, citiesSorted }) {
    this.gaApp.stores.stockists.main.cities = cities
    this.gaApp.stores.stockists.main.citiesSorted = citiesSorted
  }

  async getCityStores(cityId) {
    const mainCity = this.gaApp.services.app.main.getMainCity()
    const actualCityId = cityId || mainCity.value

    try {
      const { data } =
        await this.gaApp.repositories.stockists.main.getCityStores({
          cityId: actualCityId,
        })
      this.gaApp.stores.stockists.main.cityStores[actualCityId] = {
        cityId: actualCityId,
        ...data,
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('stockists.error.default'),
      )

      throw error
    }
  }

  getAllStores() {
    // асинхронно запрашиваем данные магазинов по всем городам, заполняем ими стор
    this.gaApp.stores.stockists.main.cities.forEach((city) => {
      this.getCityStores(city.value)
    })
  }
}

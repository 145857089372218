<template>
  <svg fill="none" stroke="none" viewBox="0 0 28 26">
    <path
      fill="#35B34A"
      d="M10.786 15.502c-1.505.814-2.175-.76-2.175-.76s-1.976-3.328-2.502-4.757c-.236-.651-.036-.705.181-.85.417-.29.798.235.798.235s1.36 1.284 2.03 1.881c.907.796 1.831.29 1.831.29l14.23-6.26C22.715 2.117 18.528 0 13.76 0 6.163 0 0 5.354 0 11.902c0 3.564 1.795 6.73 4.659 8.936l-.526 3.618a.672.672 0 0 0 .326.67c.11.054.236.09.345.09a.67.67 0 0 0 .38-.109l3.1-2.206c1.686.614 3.5.976 5.457.976 7.596 0 13.759-5.354 13.759-11.902 0-1.918-.508-3.708-1.414-5.282-3.843 2.207-14.067 8.14-15.3 8.81Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-we-chat-pay',
}
</script>

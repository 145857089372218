import { computed, toRefs } from 'vue'

import { usePostRender } from '../../../composables'
import { propValidator } from '../../../utils'
import { GaProductCardFavorite } from '../../molecules/favorite'

import { useBehavior, useDataFavorite } from './scripts/composables'
import { favoriteAdapter as schemaFavoriteAdapter } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-favorite-adapter',

  components: {
    GaProductCardFavorite,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaFavoriteAdapter),
      required: true,
    },
  },

  emits: ['click-add-to-favorite', 'add-to-favorite'],

  setup(props, { attrs, emit }) {
    const { data } = toRefs(props)

    const { onFavoriteClick } = useBehavior(emit)

    const { dataFavorite } = useDataFavorite(data)

    const propsToPass = computed(() => {
      const { data, ...restProps } = attrs
      return restProps
    })

    const { postRendered } = usePostRender()

    return { onFavoriteClick, dataFavorite, propsToPass, postRendered }
  },
}

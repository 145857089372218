import { DESIGN_OPTIONS, DESIGN_TYPES } from '../constants/digital'
import {
  CHECK_GENERATION_STATUS_INTERVAL_MSEC,
  GENERATION_CHECKING_TIMEOUT_MSEC,
  GENERATION_LIFE_TIME_MSEC,
  LOCAL_STORAGE_KEY,
  MAX_PROMPT_LENGTH,
} from '../constants/digital-ai-design'
import { HASH_PARAM } from '../constants/hash'

export class DigitalAiDesignService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Заполняет подсказки для запроса нейросети из локали
   */
  fillHints() {
    if (this.gaApp.stores.giftCards.digitalAiDesign.prompt.hints?.length) {
      return undefined
    }

    this.gaApp.stores.giftCards.digitalAiDesign.prompt.hints = Object.values(
      this.gaApp.i18n.t('giftCards.promptModal.hints'),
    ).map((object) => Object.values(object))
  }

  /**
   * Изменяет отображение модального окна ввода запроса нейросети
   * @param {boolean} visibility видимость модального окна
   */
  changePromptModalVisibility(visibility) {
    if (
      this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isOpen ===
      visibility
    ) {
      return undefined
    }

    this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isOpen = visibility
    this.gaApp.services.giftCards.main.setHash({
      [HASH_PARAM.PROMPT]: visibility,
    })
  }

  /**
   * Закрывает модальное окно ввода запроса нейросети
   */
  closePromptModal() {
    if (!this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isOpen) {
      return undefined
    }

    this.changePromptModalVisibility(false)
  }

  /**
   * Открывает модальное окно ввода запроса нейросети
   */
  openPromptModal() {
    if (this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isOpen) {
      return undefined
    }

    this.fillHints()
    this.changePromptModalVisibility(true)

    const aiDesigns =
      this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.AI].imagesOptions

    if (aiDesigns.length > 0) {
      const succeedGeneration =
        aiDesigns.length !==
        this.gaApp.stores.giftCards.digitalAiDesign.generation.limit

      this.gaApp.analytics.modules.giftCards.onGenerateMoreDesignButtonClick(
        succeedGeneration,
      )
    } else {
      this.gaApp.analytics.modules.giftCards.onGenerateDesignButtonClick()
    }
  }

  /**
   * Устанавливает значение для текста запроса нейросети
   * @param {string} text текст запроса нейросети
   * @param {boolean} isTextTypedByUser текст был напечатан пользователем
   */
  setPromptText(text, isTextTypedByUser = false) {
    this.gaApp.stores.giftCards.digitalAiDesign.prompt.text = text.slice(
      0,
      MAX_PROMPT_LENGTH,
    )

    if (isTextTypedByUser) {
      this.gaApp.stores.giftCards.digitalAiDesign.prompt.isTextTyped =
        isTextTypedByUser
    }

    const isClear =
      this.gaApp.stores.giftCards.digitalAiDesign.prompt.text.replace(
        /\s*/g,
        '',
      ).length === 0

    if (isClear) {
      this.gaApp.stores.giftCards.digitalAiDesign.prompt.isTextTyped = false
      this.gaApp.stores.giftCards.digitalAiDesign.prompt.isHintsUsed = false
    }
  }

  /**
   * Добавляет в конец текста запроса нейросети текст из подсказки
   * @param {string} hintText текст подсказки
   */
  addHintText(hintText) {
    const text =
      `${this.gaApp.stores.giftCards.digitalAiDesign.prompt.text} ${hintText}`.trim()

    this.gaApp.analytics.modules.giftCards.onPromptChipClick(hintText)
    this.gaApp.stores.giftCards.digitalAiDesign.prompt.isHintsUsed = true
    this.setPromptText(text)
  }

  /**
   * Устанавливает значение загрузки модального окна ввода запроса
   * @param {boolean} loading значение загрузки
   */
  setLoading(loading) {
    this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isLoading = loading
  }

  /**
   * Останавливает проверку статуса генерации изображения
   */
  stopGenerationChecking() {
    clearInterval(this.gaApp.stores.giftCards.digitalAiDesign.generation.timer)
    clearInterval(
      this.gaApp.stores.giftCards.digitalAiDesign.generation.timeoutTimer,
    )
  }

  /**
   * Проверят статус генерации изображения
   * @param {string} yandexImageId идентификатор генерации изображения
   */
  async checkGenerationStatus(yandexImageId) {
    try {
      const { id, imgUrl } =
        await this.gaApp.services.giftCards.api.getAiImage(yandexImageId)

      if (!id) {
        return undefined
      }

      const generatedTimestamp = new Date().getTime()
      const aiDesign =
        this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.AI]

      aiDesign.imagesOptions.unshift({
        id,
        data: { generatedTimestamp, yandexImageId, imgUrl },
      })
      this.stopGenerationChecking()
      this.closePromptModal()
      this.setLoading(false)
      this.gaApp.services.giftCards.digitalDesignTypes.setDesignSelectedOption(
        DESIGN_TYPES.AI,
        DESIGN_OPTIONS.IMAGE,
        id,
      )

      if (
        this.gaApp.stores.giftCards.digital.selectedDesignType ===
        DESIGN_TYPES.AI
      ) {
        this.gaApp.services.giftCards.digital.changeImage(id)
      }

      this.saveDesigns()
    } catch (error) {
      console.error(error)
      this.stopGenerationChecking()
    }
  }

  /**
   * Сохраняет данные дизайна в localStorage
   */
  saveDesigns() {
    const { imagesOptions } =
      this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.AI]

    const dataToSave = imagesOptions.map((image) => ({
      generatedTimestamp: image.data.generatedTimestamp,
      yandexImageId: image.data.yandexImageId,
    }))

    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_KEY,
      dataToSave,
    )
  }

  /**
   * Получение конфигурации сохраненных дизайнов
   *
   * Конфигурация будет получена только в том случае если:
   * - со времени генерации дизайна не прошло `GENERATION_LIFE_TIME` мсек.;
   * - сгенерированный дизайн не был удален ранее с сервера
   */
  async getSavedDesigns() {
    const aiDesign = this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.AI]
    const selectedDesignType =
      this.gaApp.stores.giftCards.digital.selectedDesignType
    const localStorageDesigns =
      this.gaApp.services.app.browserStorage.local.get(LOCAL_STORAGE_KEY) || []

    const promises = localStorageDesigns.map(
      async ({ yandexImageId, generatedTimestamp }, index) => {
        const expireTime = generatedTimestamp + GENERATION_LIFE_TIME_MSEC

        if (expireTime < new Date().getTime()) {
          return undefined
        }

        const { id, imgUrl } =
          await this.gaApp.services.giftCards.api.getAiImage(yandexImageId)

        if (!id) {
          return undefined
        }

        aiDesign.imagesOptions[index] = {
          id,
          data: { generatedTimestamp, yandexImageId, imgUrl },
        }
      },
    )

    await Promise.all(promises)

    aiDesign.imagesOptions = aiDesign.imagesOptions.filter(Boolean)

    const firstDesignId = aiDesign.imagesOptions[0]?.id

    if (firstDesignId) {
      this.gaApp.services.giftCards.digitalDesignTypes.setDesignSelectedOption(
        DESIGN_TYPES.AI,
        DESIGN_OPTIONS.IMAGE,
        firstDesignId,
      )
    }

    if (selectedDesignType === DESIGN_TYPES.AI) {
      this.gaApp.services.giftCards.digital.changeImage(firstDesignId)
    }

    this.saveDesigns()
  }

  /**
   * Удаляются все сохраненные дизайны из localStorage и стора
   */
  clearSavedDesigns() {
    this.gaApp.services.app.browserStorage.local.remove(LOCAL_STORAGE_KEY)
    this.gaApp.stores.giftCards.digital.design[DESIGN_TYPES.AI].imagesOptions =
      []
  }

  /**
   * Вызывается, если превышен лимит ожидания генерации
   */
  checkGenerationTimeOut() {
    this.stopGenerationChecking()
    this.setLoading(false)
    this.gaApp.services.notification.main.open(
      this.gaApp.i18n.t('giftCards.promptModal.errors.timeout'),
    )
  }

  /**
   * Начинает проверку генерации изображения
   * @param {string} yandexImageId  идентификатор генерации изображения
   */
  startGenerationChecking(yandexImageId) {
    this.gaApp.stores.giftCards.digitalAiDesign.generation.timer = setInterval(
      this.checkGenerationStatus.bind(this, yandexImageId),
      CHECK_GENERATION_STATUS_INTERVAL_MSEC,
    )
    this.gaApp.stores.giftCards.digitalAiDesign.generation.timeoutTimer =
      setTimeout(
        this.checkGenerationTimeOut.bind(this),
        GENERATION_CHECKING_TIMEOUT_MSEC,
      )
  }

  /**
   * Отправляет запрос нейросети для генерации изображения
   */
  async sendPrompt() {
    try {
      this.setLoading(true)

      const { isHintsUsed, isTextTyped, text } =
        this.gaApp.stores.giftCards.digitalAiDesign.prompt

      this.gaApp.analytics.modules.giftCards.onStartGenerateButtonClick(
        isHintsUsed,
        isTextTyped,
      )

      const yandexImageId =
        await this.gaApp.services.giftCards.api.startAiGeneration(text)

      this.startGenerationChecking(yandexImageId)
    } catch (error) {
      console.error(error)
      this.gaApp.services.notification.main.open({
        data: {
          props: {
            text: this.gaApp.i18n.t('giftCards.promptModal.errors.rules'),
            link: this.gaApp.i18n.t('giftCards.promptModal.links.rules'),
          },
        },
      })
      this.setLoading(false)
    }
  }

  /**
   * Получает данные конфигурации генерации дизайна через нейросеть
   */
  async getConfig() {
    const { limit, limitMessage } =
      await this.gaApp.services.giftCards.api.getAiConfig()

    Object.assign(this.gaApp.stores.giftCards.digitalAiDesign.generation, {
      limit,
      limitMessage,
    })
  }
}

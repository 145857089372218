import flow from 'lodash/flow'

export class LogoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fillStore(block) {
    this.gaApp.stores.brandzone.logo.data = {
      id: block.id,
      type: block.type,
      data: this.mapBlockData(block),
    }
  }

  mapBlockData(block) {
    return flow(this.enrichNavigation.bind(this))(block)
  }

  enrichNavigation(block) {
    const navigation = this.gaApp.stores.brandzone.page.category?.children

    if (navigation) {
      block.data.navigation = navigation
    }
  }

  reset() {
    this.gaApp.stores.brandzone.logo.$reset()
  }
}

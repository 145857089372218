<template>
  <svg fill="none" stroke="none" viewBox="0 0 70 50">
    <path
      fill="#fff"
      d="M35.93 27.112h2.22c4.214 0 6.476-1.685 6.476-5.036 0-3.249-1.932-4.875-6.477-4.875H33.36v14.394h2.57v-4.483Zm0-7.544h2.447c2.506 0 3.577.678 3.577 2.446 0 1.954-1.234 2.734-3.62 2.734H35.92l.01-5.18Zm12.557 12.216c1.648 0 2.9-.824 3.394-2.015v1.851h2.262v-6.785c0-2.683-1.481-3.702-4.483-3.702-1.768 0-3.002.413-3.679.925v3.003c.698-1.09 1.787-1.83 3.679-1.83 1.379 0 2.076.453 2.076 1.913v.45h-2.323c-2.527 0-4.234.658-4.234 3.003 0 2.015 1.232 3.187 3.308 3.187Zm1.216-4.625h2.033v.831c-.102.698-.864 1.61-2.487 1.61-1.073 0-1.644-.494-1.644-1.274 0-.902.678-1.167 2.098-1.167Zm8.277 6.38a1.814 1.814 0 0 1-1.234-.515v2.243c.308.268 1.006.45 1.644.45 1.358 0 2.28-.555 3.187-3.02l4.11-11.37H63.14l-2.302 7.05-2.817-7.05h-2.683l4.338 10.32c-.394 1.398-.95 1.892-1.695 1.892ZM9.825 22.074v3.158l4.186 2.624 10.02-7.373a10.998 10.998 0 0 0-1.32-2.186l-8.7 6.401-4.186-2.624Z"
    />
    <path
      fill="#fff"
      d="M14.009 36a11 11 0 0 0 10.823-12.972l-2.37 1.744V25a8.465 8.465 0 1 1-3.688-6.976l2.146-1.575A11.001 11.001 0 0 0 5.024 31.358 11 11 0 0 0 14.014 36h-.005Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-sber-pay',
}
</script>

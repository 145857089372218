import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, ref, unref, watch } from 'vue'

import {
  CUSTOM_PROPERTY,
  HEADER_DESKTOP_BREAKPOINT,
} from '../../../../../constants'

export const useColorSet = () => {
  const { $gaApp } = useContext()

  const serverColorSetActive = ref(true)
  const serverColorSetCustomProperties = ref(null)

  const currentColorSet = computed(() => {
    return $gaApp.stores.header.main.headerCurrentColorSet
  })

  // Данный метод устанавливает CSS-переменные для первой отрисовки HTML.
  // Разделение с помощью @media добавлено по причине того, что сервере мы
  // не можем знать заранее разрешение экрана пользователя
  const updateColorSetOnServer = () => {
    const initialScheme = $gaApp.stores.header.main.headerInitialColorSet
    const scrolledScheme = $gaApp.stores.header.main.headerScrolledColorSet

    const desktopBreakpoint =
      $gaApp.stores.app.window.breakpoints[HEADER_DESKTOP_BREAKPOINT].width

    serverColorSetCustomProperties.value = `
                @media screen and (max-width: ${desktopBreakpoint - 1}px)
                {
                    :root
                    {
                        ${CUSTOM_PROPERTY.BACKGROUND}: ${
                          scrolledScheme.background
                        };
                        ${CUSTOM_PROPERTY.FOREGROUND}: ${
                          scrolledScheme.foreground
                        };
                        ${CUSTOM_PROPERTY.ACCENT}: ${scrolledScheme.accent};
                    }
                }

                @media screen and (min-width: ${desktopBreakpoint}px)
                {
                    :root
                    {
                        ${CUSTOM_PROPERTY.BACKGROUND}: ${
                          initialScheme.background
                        };
                        ${CUSTOM_PROPERTY.FOREGROUND}: ${
                          initialScheme.foreground
                        };
                        ${CUSTOM_PROPERTY.ACCENT}: ${initialScheme.accent};
                    }
                }
            `
  }

  // Данный метод устанавливает CSS-переменные уже после инициализации JS.
  // Обновление с помощью setProperty сделано по причине того, что обновление
  // CSS-переменных прямо в <style> сказывается на производительности
  const updateColorSetOnClient = () => {
    const documentStyle = document.documentElement.style

    if (!documentStyle.setProperty) {
      return
    }

    const { background, foreground, accent } = unref(currentColorSet)

    documentStyle.setProperty(CUSTOM_PROPERTY.BACKGROUND, background)
    documentStyle.setProperty(CUSTOM_PROPERTY.FOREGROUND, foreground)
    documentStyle.setProperty(CUSTOM_PROPERTY.ACCENT, accent)
  }

  onMounted(() => {
    serverColorSetActive.value = false
  })

  watch(
    currentColorSet,
    (value) => {
      if (unref(serverColorSetActive)) {
        updateColorSetOnServer(value)
      } else {
        updateColorSetOnClient(value)
      }
    },
    { immediate: true },
  )

  return {
    serverColorSetCustomProperties,
  }
}

export const endpoints = {
  getConfig: () => ({
    url: 'gift-cards-b2b/digital/config',
  }),
  findByInn: () => ({
    url: 'gift-cards-b2b/digital/find-by-inn',
  }),
  digitalOrder: () => ({
    url: 'gift-cards-b2b/digital/order',
  }),
  emailConfirm: () => ({
    url: 'gift-cards-b2b/digital/email-confirm',
  }),
  getPlasticConfig: () => ({
    url: 'gift-cards-b2b/plastic/config',
  }),
  plasticOrder: () => ({
    url: 'gift-cards-b2b/plastic/order',
  }),
}

import { TYPE } from '../../constants'
import { DigiAnalyticsBrandViewModel, MindboxPageViewModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PageViewBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([[TYPE.MINDBOX, MindboxPageViewModel]])

    // если есть данные по бренду,
    // то включаем дигу в список
    if (data.brandName && data.pageProducts) {
      models.set(TYPE.DIGI, DigiAnalyticsBrandViewModel)
    }

    super(models, data, country)
  }
}

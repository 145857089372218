import { ROUTES } from '../constants/form'
import { mockParamsForForm } from '../mocks/products-for-review/mock-products'

/**
 * Сервис предоставляет методы для получения данных о продуктах для отзыва, вариантах продуктов и формирования URL-адреса элемента:

`getProductsForm(context)` - асинхронный метод, который получает данные о продуктах для формы отзыва.

`getProductsVariants({ productId })` - асинхронный метод, который получает варианты продукта по указанному `productId`.

`getItemUrl({ itemId, orderId, customerId } = {})` - метод, который формирует URL-адрес элемента на основе переданных параметров `itemId`, `orderId` и `customerId`.

`setData({ data = null, orderId = null, userId = null, itemId = null, type = '' })` - метод, который устанавливает данные о продукте в store `products`.
 */

export class ProductsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getProductsForm(context) {
    const {
      order_id: orderId,
      customer_id: customerId,
      product_id: itemId,
    } = this.gaApp.route.query

    // в дев режиме используем моки
    const params = this.gaApp.config.get('devConfig')
      ? mockParamsForForm({ itemId })
      : {
          orderId,
          customerId,
          itemId,
        }

    return await this.gaApp.services.review.api.getProducts(params, context)
  }

  async getProductsVariants({ productId }) {
    await this.gaApp.services.review.api.getProductsVariants({ productId })

    const { hasManyItems, hasOneItem } = this.gaApp.stores.review.products

    // если больше одного варианта, то открываем модалку
    if (hasManyItems) {
      return this.gaApp.services.review.modal.open()
    }
    // если единственный вариант, то редиректим сразу на форму оставления отзыва
    if (hasOneItem) {
      const { orderId, itemId } = this.gaApp.stores.review.products.items?.[0]

      return this.gaApp.router.push({
        path: ROUTES.FORM,
        query: {
          order_id: orderId,
          product_id: itemId,
        },
      })
    }
  }

  getItemUrl({ itemId, orderId, customerId } = {}) {
    const query = {
      order_id: orderId,
      customer_id: customerId,
      product_id: itemId,
    }

    const stringQuery = Object.entries(query)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return `${ROUTES.FORM}?${stringQuery}`
  }

  setData({
    data = null,
    orderId = null,
    userId = null,
    itemId = null,
    type = '',
  }) {
    this.gaApp.stores.review.products.orderId = orderId
    this.gaApp.stores.review.products.itemId = itemId
    this.gaApp.stores.review.products.customerId = userId
    this.gaApp.stores.review.products.data = data
    this.gaApp.stores.review.products.typeProducts = type
  }
}

import { ref, watch } from 'vue'

/**
 * Возвращает необходимые поля для отформатированного телефона
 * @param {Object} obj
 * @param {Ref<String>} obj.phone // Значение phone из props
 * @param {Ref<String>} obj.phoneFormatted // Значение phoneFormatted из props
 * @param {Ref<String>} obj.phoneCode // Значение phone из props
 * @param {Ref<Object>} obj.maskInternational // Значение maskInternational из useMasks
 *
 * @param {Function} emit - emit функция контекста
 */

export function usePhoneFormatted(
  { phone, phoneFormatted, phoneCode, maskInternational },
  emit,
) {
  const phoneFormattedInternal = ref('')
  watch(phoneFormatted, (value) => {
    phoneFormattedInternal.value = value
  })

  watch([phone, phoneCode], () => {
    phoneFormattedInternal.value = maskInternational.value.value
  })

  watch(phoneFormattedInternal, (value) => updatePhoneFormatted(value))

  function updatePhoneFormatted(value) {
    emit('update:phone-formatted', value)
  }

  return { phoneFormattedInternal }
}

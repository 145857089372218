export class VideoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  handleVideoPlayError(error) {
    const canVideoAutoplay = Boolean(error && error.name !== 'NotAllowedError')
    return (this.gaApp.stores.app.main.canVideoAutoplay = canVideoAutoplay)
  }
}

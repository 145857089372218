import { unref } from 'vue'

export const useListeners = ({
  videoKey,
  onErrorPlayer,
  emit,
  $listeners = {},
}) => {
  const unrefVideoKey = unref(videoKey)

  return {
    ...$listeners,
    canplay: (playerInstance) => {
      emit('canplay', unrefVideoKey)
    },
    loadeddata: (value = {}) => {
      emit('loadeddata', { ...value, videoKey: unrefVideoKey })
    },
    ended: () => {
      emit('ended', unrefVideoKey)
    },
    pause: () => {
      emit('pause', unrefVideoKey)
    },
    playing: () => {
      emit('playing', unrefVideoKey)
    },
    play: () => {
      emit('play', unrefVideoKey)
    },
    error: (error) => {
      onErrorPlayer(error)

      emit('error', {
        error,
        videoKey: unrefVideoKey,
      })
    },
    durationchange: (duration) => {
      emit('durationchange', {
        duration,
        videoKey: unrefVideoKey,
      })
    },
    timeupdate: (currentTime) => {
      emit('timeupdate', {
        currentTime,
        videoKey: unrefVideoKey,
      })
    },
    bufferedupdate: (buffer) => {
      emit('bufferedupdate', buffer)
    },
    mutedchange: (mute) => {
      emit('mutedchange', mute)
    },
    'hook:mounted': () => {
      emit('hook:mounted', unrefVideoKey)
    },
  }
}

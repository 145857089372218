import objectHash from 'object-hash'

import { CLEAR_PAGES, PAGINATION_BUTTON } from '../constants'

const { NEXT, PREV } = PAGINATION_BUTTON
export class PlpService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getListing() {
    const filtersFromQuery =
      await this.gaApp.services.favorites.filters.getFilteredQueryFilters()

    const page = this.gaApp.stores.favorites.plp.lastViewedPage

    const cacheData =
      this.gaApp.services.cache.main.getServerData('favorites-plp')

    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const customerId = this.gaApp.stores.user.main?.data?.id

    const { data } =
      cacheData ||
      (await this.gaApp.services.favorites.api.getPlp({
        cityId,
        cityDistrict,
        geoPolygons,
        customerId,
        pageNumber: page,
        filters: filtersFromQuery,
      }))
    const { products, filters } = data

    this.setProductsData(products, filtersFromQuery, page)

    this.gaApp.services.favorites.products.setHasProducts(products)

    this.gaApp.services.favorites.filters.setFiltersData(filters)

    this.gaApp.services.favorites.filters.updateFiltersUrlPostTask()
  }

  async fetchFavoriteProductsByPage({
    page = 1,
    clearPages = CLEAR_PAGES.NONE,
  }) {
    try {
      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        this.gaApp.stores.filters.main.getFiltersMainSelected.length,
      )

      const cacheData =
        this.gaApp.services.cache.main.getServerData('favorites-products')

      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const customerId = this.gaApp.stores.user.main?.data?.id

      const { data } =
        cacheData ||
        (await this.gaApp.services.favorites.api.getProducts({
          cityId,
          cityDistrict,
          geoPolygons,
          customerId,
          offset: 0,
          pageSize: 24,
          pageNumber: page,
          filters: filtersSelected,
        }))

      if (clearPages === CLEAR_PAGES.AFTER_REQUEST) {
        this.gaApp.stores.favorites.plp.pages = []
      }

      this.setProductsData(data, filtersSelected, page)
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('favorites.error.products'),
      )
    }
  }

  async fetchFavoriteProducts(
    page = 1,
    direction = NEXT,
    clearPages = CLEAR_PAGES.NONE,
  ) {
    this.setPagination({
      direction,
      value: {
        pending: true,
        error: false,
      },
    })

    try {
      await this.gaApp.services.favorites.plp.fetchFavoriteProductsByPage({
        page,
        clearPages,
      })
    } catch (error) {
      this.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  fetchFavoriteProductsPrev() {
    const pageFirst = this.gaApp.stores.favorites.plp.pageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    return this.fetchFavoriteProducts(pageFirst - 1, PREV)
  }

  fetchFavoriteProductsNext() {
    const pageLast = this.gaApp.stores.favorites.plp.pageNumberLast
    const pagesCount = this.gaApp.stores.favorites.plp.totalPages

    if (pageLast === pagesCount) {
      return false
    }

    this.fetchFavoriteProducts(pageLast + 1, NEXT)
  }

  resetListingAndFetchFavoriteProducts() {
    if (this.gaApp.services.favorites.filters.areHashesEqual()) {
      return false
    }

    // откладываем, чтобы сработало после возвращения
    // первоначального скролла при закрытии модалки
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    this.gaApp.stores.favorites.plp.pages = []

    this.gaApp.services.filters.main.pageNumber.updateFilter(1)

    this.gaApp.services.filters.main.updateFiltersURL()

    this.fetchFavoriteProducts(1, NEXT)
  }

  setProductsData(data, filtersSelected, page) {
    const { products, pageCount, count, countSelectedFilters } = data

    this.gaApp.stores.favorites.plp.productsCount = count

    if (products.length) {
      this.setPage({
        number: page,
        products: products.map(this.normalizeProduct),
      })
    }

    const setFavoritesHash = () => {
      this.gaApp.stores.favorites.plp.favoritesHash = objectHash(
        {
          filters: filtersSelected,
        },
        { unorderedArrays: true },
      )
    }

    const setCountSelectedFilters = () =>
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        countSelectedFilters,
      )

    const setPageCount = () => {
      this.gaApp.stores.favorites.plp.totalPages = pageCount
    }

    this.gaApp.services.app.scheduler.postTasks([
      { task: setFavoritesHash },
      { task: setCountSelectedFilters },
      { task: setPageCount },
    ])
  }

  normalizeProduct(product) {
    const colors = product.attributes?.colors ?? {}
    const units = product.attributes?.units ?? {}

    product.attributes.colors = {
      ...colors,
      values: colors?.values?.[0] ? [colors?.values[0]] : [],
    }

    product.attributes.units = {
      ...units,
      values: units?.currentUnitValue ? [units?.currentUnitValue] : [],
    }

    return product
  }

  addProductFromRecommendations(product) {
    const productNormalized = this.normalizeProduct(product)

    this.gaApp.stores.favorites.plp.productsFromRecommendations.unshift(
      productNormalized,
    )
  }

  scrollToLastViewedProduct = () => {
    const product = this.gaApp.stores.favorites.plp.lastViewedProduct
    this.gaApp.services.app.scrollNavigation.scrollToAnchor(product)
    this.setLastViewedPage(1)
    this.setLastViewedProduct(null)
  }

  clear() {
    this.gaApp.stores.favorites.plp.pages = []
    this.gaApp.stores.favorites.plp.totalPages = 0
    this.gaApp.stores.favorites.plp.productsCount = 0
  }

  setPage(page) {
    this.gaApp.stores.favorites.plp.pages.push(page)
    this.gaApp.stores.favorites.plp.pages.sort((a, b) => a.number - b.number)
  }

  setPagination({ direction, value }) {
    this.gaApp.stores.favorites.plp.pagination[direction] = {
      ...this.gaApp.stores.favorites.plp.pagination[direction],
      ...value,
    }
  }

  setLastViewedPage(page) {
    this.gaApp.stores.favorites.plp.lastViewedPage = page
  }

  setLastViewedProduct(product) {
    this.gaApp.stores.favorites.plp.lastViewedProduct = product
  }

  onAddressChange() {
    const pages = this.gaApp.stores.favorites.plp.pages

    return pages?.length === 1 ? this.softReset() : this.hardReset()
  }

  /**
   *  Нужен для обновления фильтров до актуальных в зависимости от измененного адреса.
   */
  async hardReset() {
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    const filtersFromQuery =
      await this.gaApp.services.favorites.filters.getFilteredQueryFilters()

    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const customerId = this.gaApp.stores.user.main?.data?.id

    const { data } = await this.gaApp.services.favorites.api.getPlp({
      cityId,
      cityDistrict,
      geoPolygons,
      customerId,
      pageNumber: 1,
      filters: filtersFromQuery,
    })

    const { filters, products } = data

    this.gaApp.stores.favorites.plp.pages = []

    this.setProductsData(products, filtersFromQuery, 1)
    this.gaApp.services.favorites.products.setHasProducts(products)
    this.gaApp.services.favorites.filters.setFiltersData(filters)

    this.gaApp.services.favorites.filters.updateFiltersUrlPostTask()
  }

  /**
   * Нужен, чтобы перезапрашивать листинг с флагом очистки (CLEAR_PAGES.AFTER_REQUEST),
   * чтобы не был виден перезапрос продуктов на самой странице. А также метод не обновляет фильтры,
   * чтобы не потерять возможные utm-метки
   */
  async softReset() {
    await this.gaApp.services.favorites.filters.requestFilters()
    this.fetchFavoriteProducts(1, NEXT, CLEAR_PAGES.AFTER_REQUEST)
  }
}

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGaUaProductBaseModel } from '../gtm-gaua-product-base.model/gtm-gaua-product-base.model'

export class GtmGaUaProductCartModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { currency, addToCart, list } = product

    const event = addToCart
      ? ACTIONS.ADD_TO_CART.GA_UA
      : ACTIONS.REMOVE_FROM_CART.GA_UA
    const method = addToCart ? 'add' : 'remove'

    super({ event })

    this.item_list_name = list

    this.ecommerce = {
      currencyCode: currency,
      [method]: {
        products: [new GtmGaUaProductBaseModel(product)],
      },
    }
  }
}

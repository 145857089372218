import { computed } from 'vue'

import { GaAction } from '@ga/ui-components/action'

// @vue/component
export default {
  name: 'ga-app-lang-switcher-list-item',

  components: {
    GaAction,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    code: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const mods = computed(() => {
      return {
        lang: props.code,
      }
    })

    return {
      mods,
    }
  },
}

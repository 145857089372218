import { computed } from 'vue'

/**
 * Возвращает computed объект модификаторы блока
 * @param {Ref<String>} size - размер инпута из props
 * @param {Ref<String>} theme - вид инпута из props
 */

export const useMods = (size, theme) => {
  const mods = computed(() => {
    return {
      [`size_${size.value}`]: true,
      [`theme_${theme.value}`]: true,
    }
  })

  return {
    mods,
  }
}

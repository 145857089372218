import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { watch } from 'vue'

import { useAppRouter } from '~/modules/app'

export const useOnRouteChange = (minimized, clearSelectedTab, updateState) => {
  const { $gaApp } = useContext()

  const { routeChange } = useAppRouter()

  watch(routeChange, (value) => {
    $gaApp.services.header.main.setHeaderExtensionLockState({
      value: false,
    })

    // !value соответствует beforeRouterAfterEach
    if (!value) {
      updateState()
      clearSelectedTab()
      $gaApp.services.header.nav.closeNavItems()
    }
  })
}

export const IMAGE_SIZES = {
  MB: 2,
  WIDTH: 1920,
}

export const KEYS = {
  ITEM_ID: 'itemId',
  FILE: 'file',
  FILE_EXTENSION: 'fileExtension',
  SESSION_ID: 'sessionId',
}

export const VALIDATION_RULES = {
  MAX_LENGTH: 7,
  EXTENSIONS: ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'],
  SIZE_MB: 2,
  WIDTH: 1920,
}

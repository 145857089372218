import { GaAnalyticsBaseModel } from '../ga-analytics-base.model/ga-analytics-base.model'

export class GaAnalyticsMenuModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { url, path } = data

    this.category = {
      url,
      itemListName: path,
    }

    this.debug = {
      key: 'header-menu-item-click',
      data,
    }
  }
}

import { TYPE } from '../../constants'
import { GtmGa4SettingAddressModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class SettingAddressBuilder extends BaseBuilder {
  constructor({ actionType, screenType }) {
    const models = new Map([[TYPE.GTM_4, GtmGa4SettingAddressModel]])
    super(models, { actionType, screenType })
  }
}

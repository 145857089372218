import throttle from 'lodash/throttle'

import {
  DEVICE_ID_COOKIES_KEY,
  SUPPORT_MESSENGERS_ICONS,
  WEBVIEW_COOKIES_KEY,
} from '../constant'
export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    // Обновляем версия раз в минуту
    this.updateAppVersion = throttle(this.updateAppVersion, 60000)
  }

  get commonState() {
    return this.gaApp.stores.app.common
  }

  get isSSR() {
    return this.gaApp.stores.app.main.isSSR
  }

  /** Достаем deviceId из сторы */
  get deviceId() {
    return this.gaApp.stores.app.main.deviceId
  }

  get supportPhone() {
    return this.commonState.contactsModalData.phones.support
  }

  get supportMessengers() {
    return Object.fromEntries(
      Object.entries(this.commonState.messengers).map(([key, value]) => {
        const icon = SUPPORT_MESSENGERS_ICONS[key]

        return [key, { ...value, icon }]
      }),
    )
  }

  get supportMessengerDefault() {
    return Object.values(this.supportMessengers).find(
      (messenger) => messenger.isDefault,
    )
  }

  get suggestCities() {
    return this.gaApp.stores.location.search.cities.items
  }

  get baseUrl() {
    return this.commonState.baseUrl || ''
  }

  get currentStoreContent() {
    const { country } = this.gaApp.i18n.locale

    return this.getStoreContent(country)
  }

  // записываем в куки и локалстору, что был заход с вебвью параметром
  // включаем флаг в сторе
  initWebview() {
    this.gaApp.stores.app.main.webview = true

    this.gaApp.cookies.set(WEBVIEW_COOKIES_KEY, 1, {
      maxAge: 31536000,
      path: '/',
      sameSite: 'lax',
    })

    this.gaApp.services.app.browserStorage.local.set(WEBVIEW_COOKIES_KEY, 1)
  }

  // очищаем куки и локалстору от флага означающего заход с вебвью
  clearWebview() {
    this.gaApp.cookies.remove(WEBVIEW_COOKIES_KEY)
    this.gaApp.services.app.browserStorage.local.remove(WEBVIEW_COOKIES_KEY)
  }

  setStartTime() {
    let data = this.gaApp.services.app.browserStorage.local.get('gast')

    if (!data) {
      const time = String((Date.now() * 5) / 233333)
      data = [time, time]

      // this.gaApp.cookies.set('gast', time, {
      //   maxAge: 31536000,
      //   path: '/',
      //   sameSite: 'lax'
      // })

      this.gaApp.services.app.browserStorage.local.set('gast', data)
    }

    this.gaApp.api.addRequestCallback({
      success: (config) => {
        config.headers.common['x-gast'] = data

        return config
      },
    })
  }

  getStoreContent(country) {
    return this.commonState.stores.find((store) => store.code === country)
  }

  setSSR() {
    this.gaApp.stores.app.main.isSSR = true
  }

  /*
   * Проверяет является ли пользователь ботом.
   */
  checkBot() {
    const isBot = Boolean(this.gaApp.serverRequest.headers['x-customer-is-bot'])

    if (isBot) {
      this.gaApp.stores.user.main.isBot = true
    }
  }

  /**
   * Отдает страницу ошибки
   */
  checkCriticalError() {
    const criticalError = this.gaApp.serverRequest.headers['x-critical-error']

    if (criticalError) {
      throw new Error('Ошибка')
    }
  }

  getMainCity() {
    return this.commonState.mainCity
  }

  getRouter() {
    return this.gaApp.stores.app.main.router
  }

  /** Заполняем в стору deviceId из кук */
  fillDeviceId() {
    const deviceId = this.gaApp.cookies.get(DEVICE_ID_COOKIES_KEY)
    this.gaApp.stores.app.main.deviceId = deviceId
  }

  async updateAppVersion() {
    const { version: newVersion } =
      await this.gaApp.services.app.api.getAppInfo()
    const oldVersion =
      this.gaApp.services.app.browserStorage.local.get('ga-app-version') || ''

    if (oldVersion && oldVersion !== newVersion) {
      this.gaApp.stores.app.main.hasForceUpdate = true
    }

    this.gaApp.services.app.browserStorage.local.set(
      'ga-app-version',
      newVersion || '0',
    )
  }
}

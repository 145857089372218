<template>
  <svg stroke="none" viewBox="0 0 111 197">
    <path
      fill="inherit"
      d="M106.241 98.863c12.101 54.089-.787 97.937-28.786 97.937-28 0-60.508-43.848-72.61-97.937C-7.254 44.773 5.633.925 33.633.925s60.507 43.848 72.609 97.938Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-green-blob-mobile',
}
</script>

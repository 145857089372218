import { DEFAULT_CATEGORY_ID } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    try {
      switch (redirect.pageType) {
        case 'main':
        case 'appMain':
          return this.gaApp.services.dolyame.api.fetchProductsByPage({
            categoryId: DEFAULT_CATEGORY_ID,
          })
        default:
          return undefined
      }
    } catch (error) {
      // здесь только запрос товаров для одного блока
      // не обрабатываем ошибку, т.к. блок просто скроется при отсутствии товаров
    }
  }
}

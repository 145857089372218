export const moduleStockistsSsrRoutes = [
  {
    moduleType: 'stockists',
    pageType: 'main',
    path: '/stockists',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/stockists/page/main' */
        '~/modules/stockists/components/pages/main.vue'
      ),
  },
]

export const routes = [...moduleStockistsSsrRoutes]

<template>
  <svg viewBox="0 0 20 20">
    <path
      stroke="none"
      d="M10.75 6.128v1.46H9.29v-1.46h1.46Zm0 2.093V14.5H9.29V8.22h1.46Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-info-label',
}
</script>

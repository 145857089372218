import { COUNTDOWN_TYPE, STEP, TOKEN_TYPE } from '../../constants'

export const getters = (gaApp) => ({
  isStartStep() {
    return this.step === STEP.START
  },

  isLocaleChangingStep() {
    return this.step === STEP.LOCALE_CHANGING
  },

  isConfirmStep() {
    return this.step === STEP.CONFIRM
  },

  isWelcomeStep() {
    return this.step === STEP.WELCOME
  },

  isPhoneToken() {
    return gaApp.stores.auth.main.grantType === TOKEN_TYPE.PHONE_CALL
  },

  helpPhone() {
    return gaApp.stores.app.common.phones.authorization
  },

  codeMessage() {
    return this.isPhoneToken
      ? gaApp.i18n.t('auth.modalConfirmForm.phoneCode')
      : gaApp.i18n.t('auth.modalConfirmForm.code')
  },

  countdownType() {
    return this.isPhoneToken ? COUNTDOWN_TYPE.PHONE : COUNTDOWN_TYPE.SMS
  },

  actionMessage() {
    return this.isPhoneToken
      ? gaApp.i18n.t('auth.modalConfirmForm.actionPhone')
      : gaApp.i18n.t('auth.modalConfirmForm.action')
  },
})

export const TYPE_PRODUCTS = {
  VARIANTS: 'variants',
  AVAILABLE: 'available',
  FORM: 'form',
}

export const MODAL_STATE = {
  MAX_VIEWS: 2,
  CACHE_KEY: 'module-review-products-modal',
  HOURS_SINCE_VIEW: 24,
}

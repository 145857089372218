import { useContext } from '@nuxtjs/composition-api'

import { computed, unref, watch } from 'vue'

import { TABS } from '../../../../../constants'

export const useOverlaid = (minimized, selectedTab, navActive, navExtended) => {
  const { $gaApp } = useContext()

  const overlaid = computed(() => {
    if (unref(minimized)) {
      return Boolean(unref(selectedTab))
    }

    const isNavActiveAndExtended = unref(navActive) && unref(navExtended)
    const isSearchTab = unref(selectedTab) === TABS.SEARCH

    return isNavActiveAndExtended || isSearchTab
  })

  watch(overlaid, (value) => {
    if (value) {
      $gaApp.services.modal.register.add('header')
    } else {
      $gaApp.services.modal.register.remove('header')
    }
  })

  return {
    overlaid,
  }
}

<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path stroke="#000" stroke-width="3" d="M18 4 6 16" />
    <path stroke="#000" stroke-width="3" d="M6 4h12v12" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-link-arrow',
}
</script>

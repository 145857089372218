import { computed } from 'vue'

export const useConfirm = ($gaApp, emit) => {
  const phone = computed(() => $gaApp.stores.auth.modal.phone)
  const phoneCode = computed(() => $gaApp.stores.auth.modal.phoneCode)
  const phoneFormatted = computed(() => $gaApp.stores.auth.modal.phoneFormatted)
  const nextRequestDelay = computed(
    () => $gaApp.stores.auth.modal.nextRequestDelay,
  )
  const verificationCode = computed(
    () => $gaApp.stores.auth.modal.verificationCode,
  )
  const stepError = computed(() => $gaApp.stores.auth.modal.stepError)
  const onCodeSendSuccess = (event) =>
    $gaApp.services.auth.modal.onCodeSendSuccess(event)
  const onCodeSendErrorClear = () =>
    $gaApp.services.auth.modal.setStepError(null)

  const onCodeConfirmSuccess = (event) =>
    $gaApp.services.auth.modal.onCodeConfirmSuccess(event, emit)
  const onCodeConfirmErrorClear = () =>
    $gaApp.services.auth.modal.setStepError(null)

  const onUpdateNextRequestDelay = (delay) => {
    $gaApp.services.auth.modal.updateNextRequestDelay(delay)
  }
  const onUpdateVerificationCode = (code) => {
    $gaApp.services.auth.modal.updateVerificationCode(code)
  }

  return {
    phone,
    phoneCode,
    phoneFormatted,
    nextRequestDelay,
    verificationCode,
    stepError,

    onCodeSendSuccess,
    onCodeSendErrorClear,
    onCodeConfirmSuccess,
    onCodeConfirmErrorClear,

    onUpdateNextRequestDelay,
    onUpdateVerificationCode,
  }
}

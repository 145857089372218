import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ContactClickModel extends GtmBaseModel {
  constructor({ type }) {
    super({})

    this.action_type = 'click_contacts'
    this.type = type
  }
}

import get from 'lodash/get'

import { MAPS } from '../../../constants/digital'
import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }

  // Требуется передать не value (GMT+5), а text (Екатеринбургское (МСК+2))
  getSelectedTimeZone() {
    try {
      const { timezoneOptions } = this.gaApp.stores.giftCards.digital
      const value = get(this.gaApp.stores.giftCards.digital, MAPS.TIMEZONE)

      return timezoneOptions.find((tz) => tz.value === value)?.text
    } catch (error) {
      return null
    }
  }

  // Если время отправки выбрано "Сейчас" отправляем значение "now"
  // Для всех остальных случаев проставляется дата и время
  getSelectedDeliveryTime() {
    try {
      if (this.gaApp.stores.giftCards.digital.isDeliveryNow) {
        return 'now'
      }

      const timezone = get(this.gaApp.stores.giftCards.digital, MAPS.TIMEZONE)
      const datetime = get(this.gaApp.stores.giftCards.digital, MAPS.DATE)

      const offsetTz = Number(timezone.replace(/[^(\W\d)]/g, ''))
      const dateWrapper = this.gaApp.libs.dateWrapper

      return dateWrapper(datetime)
        .utc(true)
        .utcOffset(offsetTz, true)
        .format('YYYY-MM-DDTHH:mm:ss')
    } catch (error) {
      return null
    }
  }
}

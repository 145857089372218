import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { SEARCH_DEBOUNCE_DELAY, SEARCH_PATH } from '../constants/index'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.gaApp.services.search.main.closeSearchModal()
    })
  }

  /**
   * Возвращает первое совпавшее предложение
   * @param {string} inputValue
   * @returns {string}
   */
  getInputSuggestion(inputValue) {
    return (
      this.gaApp.stores.search.main.searchResult.suggestions.find((s) =>
        s?.query.startsWith(inputValue),
      )?.query || ''
    )
  }

  /**
   * Возвращает форматированные предложение
   * @returns {string}
   */
  getInputSuggestions() {
    return this.gaApp.stores.search.main.searchResult.suggestions.map(
      (s) => s?.query,
    )
  }

  /**
   * Возвращает форматированные предложение
   * @returns {string}
   */
  getInputBubbles() {
    return this.gaApp.stores.search.main.searchResult.bubbles.map(
      (s) => s?.bubble,
    )
  }

  /**
   * Заполняем initialSearchResult стора search данными для пустого запроса,
   * чтобы не ходить за ними каждый раз заново
   * @returns {Promise<void>}
   */
  fetchInitialSearchResult = async () => {
    await this.gaApp.services.search.api.getSearchResult('')
    this.gaApp.stores.search.main.initialSearchResult =
      this.gaApp.stores.search.main.searchResult
  }

  /**
   * Забираем данные из initialSearchResult стора
   */
  getInitialSearchResultFromStore = () => {
    this.gaApp.stores.search.main.searchResult =
      this.gaApp.stores.search.main.initialSearchResult
  }

  /**
   * Определяем первый-ли запрос
   * @param { string } query
   */
  isFirstRequest = (query) => {
    return (
      query === '' && isEmpty(this.gaApp.stores.search.main.initialSearchResult)
    )
  }

  /**
   * Определяем откуда забирать результаты поиска:
   * при первом запросе записываем  результат в initialSearchResult стора search,
   * далее при пустом запросе забираем результат из initialSearchResult,
   * если они там есть, иначе делаем запрос в бэк
   * @param { string } query
   * @returns {Promise<void>}
   */
  chooseSearchResultSource = async (query) => {
    const trimmedQuery = query.trim()

    if (this.isFirstRequest(trimmedQuery)) {
      await this.fetchInitialSearchResult()
    } else if (trimmedQuery === '' && this.gaApp.stores.search.main.isOnline) {
      // не берем данные из сторы, если потеряно соединение
      this.getInitialSearchResultFromStore()
    } else {
      await this.gaApp.services.search.api.getSearchResult(query)
    }
  }

  /**
   * Получает данные от бэка
   * @param { string } query
   */
  getSearchResult = async (query) => {
    try {
      if (this.gaApp.stores.search.main.isError) {
        this.gaApp.stores.search.main.isModalLoading = true
      }
      await this.chooseSearchResultSource(query)
      this.gaApp.stores.search.main.isError = false
    } catch (e) {
      console.error(e)
      this.gaApp.stores.search.main.isError = true
    } finally {
      this.gaApp.stores.search.main.isModalLoading = false
    }
  }

  /**
   * Получает данные от бэка с debounce
   * @param { string } query
   */
  getSearchResultWithDebounce = debounce(async (query) => {
    await this.getSearchResult(query)
  }, SEARCH_DEBOUNCE_DELAY)

  /**
   * Загружает модалку поиска
   */
  async loadModal() {
    try {
      this.gaApp.stores.search.main.isModalLoading = true
      this.gaApp.stores.search.main.isError = false
      await this.chooseSearchResultSource(
        this.gaApp.stores.search.main.searchInputValue,
      )
    } catch (e) {
      console.error(e)
      this.gaApp.stores.search.main.isError = true
    } finally {
      this.gaApp.stores.search.main.isModalLoading = false
    }
  }

  setSearchInputValue(value) {
    this.gaApp.stores.search.main.searchInputValue = value
  }

  /**
   * Пушим в route данные для перехода
   * если есть redirectUrl - переходим на него,
   * иначе идём на страницу результатов поиска и добавляем get-параметр запроса q
   * @param q
   * @param referer
   * @param redirectUrl
   */
  pushToRoute(q, referer, redirectUrl) {
    // если ссылка внешняя - то переходим на неё
    if (redirectUrl?.includes('http')) {
      window.location.assign(redirectUrl)
      return
    }

    const path = redirectUrl ?? SEARCH_PATH
    const query = { referer }

    if (!redirectUrl) {
      query.q = q
    } else {
      query.search_term = q
    }

    this.gaApp.router.push(
      {
        path,
        query,
      },
      null,
      // закрываем модалку, когда навигация прервана
      () => {
        this.gaApp.services.search.main.closeSearchModal()
      },
    )
  }

  /**
   * Переход на страницу результатов поиска:
   * Если в сторе есть redirectUrl и query совпадают, то переходим на него,
   * В ином случае идём на страницу поиска с запросом из q (если он передан),
   * либо из searchInputValue стора
   * @param {string | null} q
   */
  moveToSearchResults(q = null, referer) {
    let urlQuery = this.gaApp.route.query?.q?.toLowerCase()
    urlQuery = urlQuery?.trim()

    let query = q ?? this.gaApp.stores.search.main.searchInputValue
    query = query?.trim()

    // если запрос модалки совпадает с запросом страницы поиска,
    // просто закрываем модалку
    if (urlQuery?.toLowerCase() === query?.toLowerCase()) {
      this.gaApp.services.search.main.closeSearchModal()
    } else {
      // данные, полученные при запросе /autocomplete
      const { redirectUrl: autocompleteRedirect, query: autocompleteQuery } =
        this.gaApp.stores.search.main.searchResult
      // используем redirect url, если текущий query
      // совпадает с query, переданным в запрос /autocomplete
      const redirectUrl =
        query === autocompleteQuery ? autocompleteRedirect : null

      this.pushToRoute(query, referer, redirectUrl)
    }
  }

  moveToHintResults(hintItem, referer) {
    // закрываем модалку, если redirectUrl ссылается на текущую страницу
    if (window?.location?.pathname === hintItem?.redirectUrl) {
      this.gaApp.services.search.main.closeSearchModal()
    } else {
      this.pushToRoute(hintItem?.name, referer, hintItem?.redirectUrl)
    }
  }

  /**
   * Открывает модалку и загружает данные, можно задать начальное значение
   * инпута поиска
   * @param {string} [inputValue=''] - Начальное значение инпута поиска
   */
  handleSearchModalOpen(inputValue = '') {
    this.gaApp.stores.search.main.isError = false
    this.gaApp.services.search.main.setSearchInputValue(inputValue)
    this.gaApp.services.search.searchHistory.setSearchHistory()
    this.gaApp.services.search.searchHistory.setFormattedSearchHistory()
    this.gaApp.services.search.main.loadModal()
  }

  /**
   * Открывает модалку поиска и загружает данные, можно задать начальное значение
   * инпута поиска
   * @param {Object} params
   * @param {boolean} params.value
   * @param {string} [params.inputValue] - Начальное значение инпута поиска
   */
  setSearchModalOpened({ value, inputValue }) {
    this.gaApp.stores.search.main.searchModalOpened = value

    if (value) {
      this.gaApp.services.search.main.handleSearchModalOpen(inputValue)
    }
  }

  closeSearchModal() {
    this.gaApp.ctx.$doubleRAF(() => {
      this.gaApp.services.search.main.setSearchModalOpened({
        value: false,
      })
    })
  }
}

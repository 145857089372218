import { ACTIONS } from '../../../constants'
import { MindboxBaseModel } from '../mindbox-base.model/mindbox-base.model'
import { MindboxProductBaseModel } from '../mindbox-product-base.model/mindbox-product-base.model'

export class MindboxProductCartModel extends MindboxBaseModel {
  constructor(product, country) {
    const { ADD_TO_CART, REMOVE_FROM_CART } = ACTIONS
    const operation = product.addToCart
      ? ADD_TO_CART.MINDBOX[country]
      : REMOVE_FROM_CART.MINDBOX[country]

    super({ operation })

    this.data = {
      addProductToList: new MindboxProductBaseModel(product),
    }
  }
}

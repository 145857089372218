<template>
  <svg fill="none" stroke="none" viewBox="0 0 55 50">
    <path
      d="M11.694 15.095c.666-.833 1.118-1.952.999-3.095-.975.049-2.165.643-2.854 1.477-.619.714-1.166 1.88-1.024 2.975 1.095.095 2.189-.547 2.879-1.357Zm.986 1.571c-1.59-.094-2.942.903-3.701.903-.76 0-1.922-.855-3.18-.832-1.636.024-3.154.95-3.984 2.421-1.708 2.944-.451 7.31 1.21 9.708.806 1.186 1.778 2.492 3.06 2.445 1.21-.047 1.684-.783 3.155-.783 1.47 0 1.897.784 3.178.76 1.329-.024 2.16-1.187 2.966-2.374.925-1.352 1.304-2.658 1.328-2.73-.024-.024-2.562-.997-2.585-3.917-.025-2.444 1.992-3.607 2.087-3.679-1.139-1.684-2.918-1.874-3.535-1.922Zm14.091-1.242c3.14 0 5.326 2.164 5.326 5.315 0 3.162-2.231 5.337-5.404 5.337h-3.476v5.528h-2.512v-16.18h6.066Zm-3.554 8.544h2.881c2.187 0 3.431-1.177 3.431-3.218 0-2.04-1.244-3.207-3.42-3.207h-2.892v6.425Zm9.536 4.283c0-2.063 1.581-3.33 4.385-3.487l3.229-.19v-.909c0-1.312-.886-2.097-2.366-2.097-1.402 0-2.276.673-2.49 1.727h-2.287c.135-2.13 1.951-3.7 4.867-3.7 2.86 0 4.687 1.514 4.687 3.88v8.129h-2.321v-1.94H40.4c-.684 1.312-2.176 2.142-3.723 2.142-2.31 0-3.925-1.436-3.925-3.555Zm7.614-1.065v-.93l-2.904.179c-1.447.1-2.265.74-2.265 1.749 0 1.031.852 1.704 2.153 1.704 1.693 0 3.016-1.166 3.016-2.702Zm4.602 8.758v-1.962c.179.045.583.045.785.045 1.12 0 1.726-.471 2.096-1.682 0-.023.213-.718.213-.729l-4.26-11.807h2.623l2.983 9.598h.045l2.983-9.598h2.556L50.575 32.22C49.566 35.08 48.4 36 45.955 36c-.201 0-.807-.023-.986-.057Z"
      fill="#fff"
    />
    <defs>
      <clipPath><path fill="#fff" d="M1 12h53.993v24H1z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-apple-pay',
}
</script>

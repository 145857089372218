<template>
  <svg fill="inherit" viewBox="0 0 37 37">
    <circle cx="18.5" cy="18.5" r="18.5" fill="inherit" opacity=".6" />
    <path fill="inherit" d="M14.183 12.333h2.467v12.333h-2.467z" />
    <path fill="inherit" d="M20.35 12.333h2.467v12.333H20.35z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-pause-dark',
}
</script>

import isEmpty from 'lodash/isEmpty'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getUserData(cacheStorage) {
    try {
      const { data } =
        await this.gaApp.repositories.cache.main.getData(cacheStorage)
      const { cart, location } = data

      if (!isEmpty(cart)) {
        this.gaApp.services.cart.main.setCache(cart)
      }

      if (!isEmpty(location)) {
        this.gaApp.services.location.main.setCache(location)
      }
    } catch (error) {}
  }
}

import { useIntersectionObserver } from '@vueuse/core'

import { reactive, watch } from 'vue'

import { useCommon } from '../common'

/**
 * Стратегия для воспроизведением видео автоматически.
 *
 * @param {Object} props — объект свойств.
 * @param {Object} videoRef — объект ссылки на видео-компонент.
 * @param {Function} emit — функция для генерации событий.
 * @return {Object} Объект, содержащий определенные функции, связанные с видео.
 */
export const useSingleStrategy = ({ props, videoRef, emit }) => {
  const {
    isVideoRendered,
    playVideo,
    stopVideo,
    onVideoEnd,
    onVideoError,
    onVideoStart,
  } = useCommon({ props, videoRef, emit })

  const state = reactive({
    isPlaying: false,
    isIntersecting: false,
    intersectionRatio: null,
  })

  const stopVideoInternal = () => {
    state.isPlaying = false
    stopVideo({ productId: props.productId })
  }

  const onVideoEndInternal = () => {
    stopVideoInternal()
    playVideoInternal()
    onVideoEnd()
  }

  const onVideoErrorInternal = () => {
    state.isPlaying = false
    onVideoError()
  }

  const playVideoInternal = () => {
    if (
      state.isIntersecting &&
      state.intersectionRatio >= 0.7 &&
      !state.isPlaying
    ) {
      state.isPlaying = true
      startTimerAutoplay()
    } else if (!state.isIntersecting) {
      stopVideoInternal()
    }
  }

  useIntersectionObserver(
    videoRef,
    ([{ intersectionRatio, isIntersecting }]) => {
      emit('video-intersect', { intersectionRatio, isIntersecting })

      state.isIntersecting = isIntersecting
      state.intersectionRatio = intersectionRatio

      playVideoInternal()
    },
    {
      threshold: [0, 0.7, 0.75, 0.8, 0.85, 0.9, 0.93, 0.95, 0.98, 0.97, 1],
    },
  )

  let timerId = null
  const clearTimer = () => {
    clearTimeout(timerId)
    timerId = null
  }

  const startTimerAutoplay = () => {
    clearTimer()

    timerId = setTimeout(() => {
      playVideo({ productId: props.productId })
      clearTimer()
    }, 500)
  }

  // скрытие/отображение слайда при пролистывании
  watch(
    () => props.isVisible,
    (val) => {
      if (props.source.video) {
        return val ? playVideoInternal() : stopVideoInternal()
      }
    },
  )

  return {
    isVideoRendered,
    onVideoEnd: onVideoEndInternal,
    onVideoError: onVideoErrorInternal,
    onVideoStart,
  }
}

import { ALL_STORES_KEY } from '../constants/keys'

export class LocationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async setBaseCity() {
    const { value: baseCityId } =
      this.gaApp.services.location.retailStores.getBaseCity({
        cities: this.gaApp.stores.promo.filters.cities,
        citiesSorted: this.gaApp.stores.promo.filters.citiesSorted,
      })

    await this.changeCurrentCityId(baseCityId)
    this.setPreviousCityId(baseCityId)
  }

  setCities({ cities, citiesSorted }) {
    this.gaApp.stores.promo.filters.cities = cities
    this.gaApp.stores.promo.filters.citiesSorted = citiesSorted
  }

  setCurrentCityId(cityId) {
    this.gaApp.stores.promo.filters.currentCity = cityId
  }

  setPreviousCityId(cityId) {
    this.gaApp.stores.promo.filters.previousCity = cityId
  }

  async changeCurrentCityId(cityId) {
    if (this.gaApp.stores.promo.filters.currentCity === cityId) {
      return
    }

    // если от маджето не пришли данные о наличии магазинов в городе
    // выставить пустое состояние
    if (!this.gaApp.stores.promo.filters.stores[cityId]) {
      await this.setNoData(cityId)
      return
    }

    this.updateCityStores(cityId)
    this.setCurrentCityId(cityId)
    this.gaApp.services.promo.filters.setMobileSelectedFilters()
    await this.updatePageData()
  }

  updateCityStores(cityId) {
    const newStores = this.gaApp.stores.promo.filters.stores[cityId].stores
    const storeKey = newStores.length === 1 ? newStores[0].id : ALL_STORES_KEY

    this.gaApp.services.promo.stores.setCurrentShop(storeKey)
    this.gaApp.services.promo.stores.setStoresForSelect(cityId)
  }

  async updatePageData() {
    if (this.gaApp.stores.promo.listing.shouldReloadPage) {
      await this.gaApp.services.promo.listing.loadNewPage()
    }
  }

  async setNoData(cityId) {
    this.setCurrentCityId(cityId)
    this.gaApp.stores.promo.filters.currentStores = [
      {
        text: this.gaApp.i18n.t('promo.select.shopLabel'),
        value: ALL_STORES_KEY,
      },
    ]

    await this.gaApp.services.promo.listing.clearPages()
    this.gaApp.services.promo.listing.setPromotionsCount(0)
  }

  async resetCurrentCityId() {
    const cityId = this.gaApp.services.location.main.getDeliveryAddress()?.id

    if (cityId) {
      await this.changeCurrentCityId(cityId)
    }
  }
}

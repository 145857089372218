export class GtmGa4ContentElementBaseModel {
  constructor({ blockName, slideName, itemListId, creativeSlot }) {
    this.creative_name = slideName
    this.promotion_name = blockName
    this.items = [
      {
        creative_name: slideName,
        promotion_name: blockName,
        item_list_id: itemListId,
      },
    ]

    if (creativeSlot) {
      this.creative_slot = creativeSlot

      this.items[0].creative_slot = creativeSlot
    }
  }
}

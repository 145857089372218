import { log } from './mindbox.utils.js'
import { scriptInjecting } from '@ga/shared-browser'

export class Mindbox {
    constructor() {
        this.isInit = false
        this.queue = {
            values: [],
            pushArgs: function () {
                this.values.push(arguments)
            },
            unshiftArgs: function () {
                this.values.unshift(arguments)
            },
        }
    }

    async create(id) {
        try {
            if (this.isInit) {
                log('Модуль уже проинициализирован', id)

                return false
            }

            // Добавлям в начало очереди событие 'create'
            this.queue.unshiftArgs('create', { endpointId: id })

            // Устанавливаем все накопившиеся события очереди в объект window.mindbox.queue
            // Скрипт-треккер, после загрузки на страницу, обойдёт эту очередь и выполнит команды из неё
            window.mindbox = window.mindbox || {}
            window.mindbox.queue = [...this.queue.values]

            // Загружем на страницу скрипт-треккер
            const url = 'https://api.mindbox.cloud/scripts/v1/tracker.js';
            const options = { async: true };
            const result = await scriptInjecting.inject(url, options)

            this.isInit = true

            log('Инициализация прошла успешно', id)

            return result
        } catch (error) {
            log('Ошибка инициализации', error)

            // Что бы ошибка не спамила в кибану
            // TODO: Попробовать найти способ разделять ошибки по типу
            // throw error
        }
    }

    async(payload) {
        log('async', payload)

        if (this.isInit) {
            return window.mindbox('async', payload)
        }

        this.queue.pushArgs('async', payload)
    }

    sync(payload) {
        log('sync', payload)

        if (this.isInit) {
            return window.mindbox('sync', payload)
        }

        this.queue.pushArgs('sync', payload)
    }
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchLabelsMap() {
    const cacheData = this.gaApp.services.cache.main.getServerData('labelsMap')

    const { data } =
      cacheData || (await this.gaApp.repositories.labels.main.fetchLabelsMap())

    return data
  }
}

export const ACTION_TYPES = {
  ADD_ADDRESS_APPLY: 'add_address_apply',
  ADD_ADDRESS_CLOSE: 'add_address_close',
  ADD_ADDRESS_OPEN: 'add_address_open',
  CHANGE_ADDRESS: 'change_address',
  CHANGE_CITY: 'change_city',
  CLICK_ADD_ADDRESS: 'click_add_address',
  CLICK_CHANGE_ADDRESS: 'click_change_address',
  CLICK_DELETE_ADDRESS: 'click_delete_address',
  CLICK_EDIT_ADDRESS: 'click_edit_address',
  MODAL_CLICK_CHANGE_ADDRESS: 'modal_click_change_address',
  MODAL_CLICK_YES: 'modal_click_yes',
  MODAL_CLOSE: 'modal_close',
  MODAL_SHOW: 'modal_show',
  SELECT_ADDRESS_APPLY: 'select_address_apply',
  SELECT_ADDRESS_CLOSE: 'select_address_close',
  SELECT_ADDRESS_OPEN: 'select_address_open',
  SELECT_ANOTHER_ADDRESS: 'select_another_address',
}

export const SCREEN_TYPES = {
  FILTER: 'filter',
  HOME: 'home',
  MENU: 'menu',
  PRODUCT: 'product',
  ACCOUNT_MY_ADDRESSES: 'account/my_addresses',
}

export const EVENT_CATEGORIES = {
  SETTING_ADDRESS: 'setting_address',
}

export const EVENT_TYPES = {
  USER_INTERACTION: 'user_interaction',
}

export const TYPE = {
  GTM_4: 'gtm_4',
}

<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      stroke="none"
      d="M13 0v9.94l-1.5-1.5V3.17L8.606 5.546 7.54 4.48 13 0Z"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      d="m13 14.044 4.97 4.986 1.06-1.06L1.56.44.498 1.5l3.82 3.833H0v9.334h6.5L13 20v-5.956Zm-1.5-1.505v4.29l-4.463-3.662H1.5V6.833h4.314L11.5 12.54Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-sound-off',
}
</script>

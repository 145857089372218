<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#EDEDED" rx="2" />
    <path fill="#CCC" d="M0 5h29v5H0z" />
    <path fill="#CCC" d="M22 15h4v2h-4z" />
    <path fill="#CCC" d="M13 15h8v2h-8z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-default',
}
</script>

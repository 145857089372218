<template>
  <svg viewBox="0 0 60 60">
    <circle cx="30" cy="30" r="30" stroke="none" />
    <path fill="#fff" fill-opacity="1" stroke="none" d="M23 20h4v20h-4z" />
    <path fill="#fff" fill-opacity="1" stroke="none" d="M33 20h4v20h-4z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-pause',
}
</script>

/**
 * Максимальная длина запроса нейросети
 */
export const MAX_PROMPT_LENGTH = 500

/**
 * Интервал проверки сгенерированного изображения
 */
export const CHECK_GENERATION_STATUS_INTERVAL_MSEC = 5000

/**
 * Ключ поля в localStorage
 */
export const LOCAL_STORAGE_KEY = 'gift-cards-digital-ai-design'

/**
 * Максимальное время жизни сгенерированного изображения (2 часа)
 */
export const GENERATION_LIFE_TIME_MSEC = 2 * 60 * 60 * 1000

/**
 * Ограничение времени по проверки генерации (2 минуты)
 */
export const GENERATION_CHECKING_TIMEOUT_MSEC = 2 * 60 * 1000

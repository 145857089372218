<template>
  <svg fill="none" viewBox="0 0 16 16">
    <path stroke-width="1.25" d="M5.5 12.5 10 8 5.5 3.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-chevron-right',
}
</script>

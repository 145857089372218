import { buildAnalytics } from '~/analytics'

export default (context, inject) => {
  const gaApp = context.$gaApp
  const libs = {
    advcake: context.app.$advcake,
    gaAnalytics: context.app.$gaAnalytics,
    gdeslon: context.app.$gs,
    gtm: context.app.$gtm,
    mindbox: context.app.$mindbox,
    digiAnalytics: context.app.$digiAnalytics,
  }
  inject('analytics', buildAnalytics(gaApp, libs))
}

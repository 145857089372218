<template>
  <svg viewBox="0 0 18 11">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="m12.335 10.372 5-4.5.412-.372-.413-.372-5-4.5-.668.744L15.697 5H0v1h15.697l-4.031 3.628.668.744Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-button-arrow-long',
}
</script>

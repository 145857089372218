import { ApiService } from './api.service'
import { MainService } from './main.service'
import { NetworkService } from './network.service'
import { PopularRequestsService } from './popular-requests.service'
import { ProductsService } from './products.service'
import { SearchHistoryService } from './search-history.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  products: new ProductsService(gaApp),
  popularRequests: new PopularRequestsService(gaApp),
  searchHistory: new SearchHistoryService(gaApp),
  network: new NetworkService(gaApp),
})

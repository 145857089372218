import { VENDOR } from '../constants'

import { config } from '~/config'

export class GdeslonFacade {
  constructor(gdeslon, gaApp) {
    this.module = gdeslon
    this.gaApp = gaApp
  }

  get entity() {
    return VENDOR.GDESLON
  }

  init(id) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const identificator = id || config.get(VENDOR.GDESLON)?.merchantId

    return this.module.create(identificator)
  }

  send(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const push = () => this.module.push(...args)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }

  thanks(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    return this.module.thanks(...args)
  }
}

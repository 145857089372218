import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsArticleAllProductsButtonClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { categoryUrl, categoryListName, categoryListId } = data

    this.category = {
      url: categoryUrl,
      itemListId: categoryListId,
      itemListName: categoryListName,
    }

    this.debug = {
      key: 'magazine-all-products-button-click',
      data,
    }
  }
}

import { VENDOR } from '../../constants'

import { ModuleItem } from './module-item'

export class YandexMetrikaFacade {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.moduleCommon = new ModuleItem(gaApp, 49452322)
    // this.moduleGiftCards = new ModuleItem(gaApp, 96998452)
  }

  get entity() {
    return VENDOR.YANDEX_METRIKA
  }

  /**
   * Выполняем установку кода Yandex Metrika
   */
  init() {
    // Если это роут gift - cards, то устанавливаем скрипт метрики созданный для gift-cards
    if (
      this.gaApp.services.giftCards.main.isGiftCardsModuleRoute() &&
      !this.gaApp.config.get('dev')
    ) {
      this.setupYandexMetrikaGiftCards()
    }

    // Устанавливаем скрипт метрики для всех остальных страниц, при условии включенного ФТ
    // ФТ нужен для того, что бы включить установку этого скрипта, только после удаления аналогичного скрипта из GTM
    if (this.gaApp.features.get('yandexMetrikaScript')) {
      this.setupYandexMetrikaCommon()
    }
  }

  /**
   * Вставка кода Yandex Metrika для gift-cards
   */
  setupYandexMetrikaGiftCards() {
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.innerHTML =
      '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(96998452, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true });'
    document.body.prepend(scriptTag)

    const noScriptTag = document.createElement('noscript')
    noScriptTag.innerHTML =
      '<div><img src="https://mc.yandex.ru/watch/96998452" style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noScriptTag)
  }

  /**
   * Вставка кода Yandex Metrika для страниц отличных от gift-cards
   */
  setupYandexMetrikaCommon() {
    this.moduleCommon.init()

    const linkTag = document.createElement('link')
    linkTag.as = 'script'
    linkTag.rel = 'preload'
    linkTag.href = 'https://mc.yandex.ru/metrika/tag.js'
    document.head.append(linkTag)

    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.innerHTML =
      '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(49452322, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true, ecommerce:"dataLayer", triggerEvent: true });'
    document.head.append(scriptTag)

    const noScriptTag = document.createElement('noscript')
    noScriptTag.innerHTML =
      '<div><img src="https://mc.yandex.ru/watch/49452322" style="position:absolute; left:-9999px;" alt="" /></div>'
    document.body.prepend(noScriptTag)
  }

  send(args) {
    // Отправляем события только в модуль common яндекс-метрики
    return this.moduleCommon.send(args)
  }
}

export class DateService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getDate(date) {
    return this.gaApp.libs.dateWrapper(date) ?? null
  }

  getFullDate(data, format) {
    const startDate = this.getDate(data.startDate).format(format)
    const endDate = data.endDate
      ? this.getDate(data.endDate).format(format)
      : null

    if (!endDate) {
      return startDate
    }

    return `${startDate} — ${endDate}`
  }

  getShortDate(data, baseFormat = 'DD MMMM', shortFormat = 'DD') {
    const startMonth = this.getDate(data.startDate).month()
    const endMonth = data.endDate ? this.getDate(data.endDate).month() : null

    if (endMonth === null) {
      return this.getDate(data.startDate).format(baseFormat)
    }

    const startDate =
      startMonth === endMonth
        ? this.getDate(data.startDate).format(shortFormat)
        : this.getDate(data.startDate).format(baseFormat)

    const endDate = this.getDate(data.endDate).format(baseFormat)

    return `${startDate} — ${endDate}`
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { GaIconAdditionalBackArrow } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaButton } from '@ga/ui-components/button'
import { GaIcon } from '@ga/ui-components/icon'

import { GaAdaptiveModal } from '~/components/adaptive-modal'

// @vue/component
export default {
  name: 'ga-app-country-confirm-modal',

  components: {
    GaAdaptiveModal,
    GaAction,
    GaButton,
    GaIcon,
    GaIconAdditionalBackArrow,
  },

  props: {},

  setup() {
    const { $gaApp } = useContext()

    const isDesktop = computed(() => $gaApp.mq('tablet-large+'))
    const isMobile = computed(() => $gaApp.mq('tablet-'))

    const opened = computed({
      get() {
        return $gaApp.stores.app.langSwitcher.confirmModal.opened
      },
      set(value) {
        if (value) {
          $gaApp.services.app.langSwitcher.openConfirmModal()
        } else {
          $gaApp.services.app.langSwitcher.closeConfirmModal()
        }
      },
    })

    const isSwipe = computed(() => unref(isMobile))

    const additionalModalProps = computed(
      () => $gaApp.stores.app.langSwitcher.confirmModal.props,
    )

    const modalProps = computed(() => ({
      fullwidth: !unref(isDesktop),
      fullheight: true,
      portalEnabled: false,
      noCloseAction: unref(isMobile),
      position: 'left',
      ...unref(additionalModalProps),
    }))

    const infoItems = computed(() =>
      Object.values($gaApp.i18n.t('app.countryConfirm.modal.infoList')),
    )

    const onBackClick = () => {
      opened.value = false
    }

    const onButtonClick = () => {
      $gaApp.services.app.langSwitcher.setCountryConfirmCookie()

      $gaApp.services.app.langSwitcher.redirectToSelectedStore()
    }

    return {
      opened,

      isSwipe,
      isMobile,
      modalProps,

      infoItems,

      onBackClick,
      onButtonClick,
    }
  },
}

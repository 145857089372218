<template>
  <svg viewBox="0 0 20 20">
    <path
      stroke="none"
      d="M10 2.716c1.98 0 2.227.009 3.005.044 2.648.122 4.117 1.594 4.236 4.237.035.777.044 1.025.044 3.003 0 1.978-.009 2.227-.044 3.004-.12 2.647-1.594 4.115-4.237 4.237-.777.035-1.025.043-3.003.043-1.978 0-2.226-.008-3.004-.043-2.647-.122-4.115-1.592-4.237-4.237-.035-.777-.043-1.026-.043-3.004s.008-2.226.044-3.003C2.883 4.349 4.353 2.88 6.998 2.76c.777-.035 1.025-.044 3.003-.044Zm0 2.91a4.375 4.375 0 1 0 .001 8.75 4.375 4.375 0 0 0 0-8.75ZM14.55 4.43a1.023 1.023 0 1 0 0 2.045 1.023 1.023 0 0 0 0-2.045ZM10 12.84a2.84 2.84 0 1 1 0-5.679 2.84 2.84 0 0 1 0 5.679Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-ig',
}
</script>

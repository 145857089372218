import { TYPE } from '../../constants'
import { GaAnalyticsContentElementLinkClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ContentElementLinkClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GAA, GaAnalyticsContentElementLinkClickModel],
    ])

    super(models, data)
  }
}

import { MenuItemClickBuilder, TabClickBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class HeaderAnalytics extends BaseController {
  /**
   * Клик по табу в хедере
   */
  onTabClick(data) {
    this.send(TabClickBuilder, data)
  }

  /**
   * Клик по пункту меню
   */
  onMenuItemClick(data) {
    this.send(MenuItemClickBuilder, data)
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, onUnmounted, ref, toRefs, unref, watch } from 'vue'

import { doubleRAF } from '@ga/shared-browser'
import { keepScroll, scrollLockScrollable } from '@ga/shared-directives'

import { GaAction } from '@ga/ui-components/action'

import { useAppWindowStore } from '~/modules/app'

import { GaProductSliderCardAdapter } from './children/card-adapter'
import { GaProductSliderControl } from './children/control'
import { GaProductSliderLinkCard } from './children/link-card'
import { DEFAULT_CONTROLS, ROOT_TEST_ID, SIZE } from './scripts/const'
import { useIntersectionObserver } from './scripts/intersection-observer'
import { useItemIntersectionObserver } from './scripts/item-intersection-observer'
import { useSizeDependentProps } from './scripts/size-dependent-props'
import { useControls } from './scripts/use-controls'
import {
  useItems,
  useItemsActiveIndexes,
  useItemsAnimating,
  useItemsDirection,
} from './scripts/use-items'
import { useItemsSize } from './scripts/use-items-size'
import { useMods } from './scripts/use-mods'
import { useResizeObserver } from './scripts/use-resize-observer'
import { useSkeleton } from './scripts/use-skeleton'
import { useSlider } from './scripts/use-slider'
import { useTestIds } from './scripts/use-test-ids'
import {
  controls as controlsSchema,
  linkCard as linkCardSchema,
  title as titleSchema,
} from './utils/schemas'
import { propValidator } from './utils'

// @vue/component
export default {
  name: 'ga-product-slider',

  components: {
    GaProductSliderControl,
    GaProductSliderLinkCard,
    GaProductSliderCardAdapter,
    GaAction,
  },

  directives: {
    keepScroll,
    scrollLockScrollable,
  },

  props: {
    /**
     * Массив продуктов
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * Включает нормализацию продуктов, переданных в items,
     * под контракт универсальных карточек продукта
     */
    hasItemsAdapter: {
      type: Boolean,
      default: false,
    },
    /**
     * Количество карточек на "странице" слайдера размера l,
     * а также количество карточек в режиме скелетона
     * для размера xl всегда равно 4
     */
    itemsCount: {
      type: Number,
      default: 4,
    },
    /**
     * Размер слайдера
     */
    size: {
      type: String,
      default: SIZE.XL,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    /**
     * Включает стартовый отступ для инфо в первой карточке продукта слайдера размеров s и m
     */
    showStartIndent: {
      type: Boolean,
      default: false,
    },
    /**
     * Параметры для кнопок навигации слайдера размеров xl и l:
     * размер кнопок, положение, отображение неактивных кнопок
     */
    controls: {
      type: Object,
      default: () => DEFAULT_CONTROLS,
      validator: (value) => propValidator(value, controlsSchema),
    },
    /**
     * Показывает скелетон слайдера (в т.ч. включает соответствующий пропс в карточках продукта)
     */
    skeleton: {
      type: Boolean,
      default: false,
    },
    /**
     * Данные для карточки-ссылки: заголовок, текст кнопки и ссылка
     */
    externalLink: {
      type: Object,
      default: null,
      required: false,
      validator: (value) => propValidator(value, linkCardSchema),
    },
    /**
     * Данные для заголовка: текст и ссылка
     */
    title: {
      type: Object,
      default: null,
      required: false,
      validator: (value) => propValidator(value, titleSchema),
    },
    /**
     * Доп класс для обертки items.
     * Может использоваться для передачи padding и отрицательного margin для слайдера размеров s и m
     */
    itemsClass: {
      type: String,
      default: '',
    },
    /**
     * Рутовый id для автотестов. также используется для формирования id элементов компонента
     */
    rootTestId: {
      type: String,
      default: ROOT_TEST_ID,
    },
  },

  emits: [
    'click-title',
    'click-link-card',
    'full-intersect-card',
    'full-intersect-slider',
    'slider-mounted',
    'slider-unmounted',
  ],

  setup(props, { emit }) {
    const { $gaApp } = useContext()

    const { windowResizing } = useAppWindowStore()

    const rootRef = ref(null)
    const itemsRef = ref([])

    const {
      items,
      itemsCount,
      skeleton,
      size,
      controls,
      showStartIndent,
      externalLink,
      title,
    } = toRefs(props)

    const isWebview = computed(() => $gaApp.isWebview)

    const testIds = useTestIds(props.rootTestId)

    const { controlsInternal, showStartIndentInternal, itemsCountInternal } =
      useSizeDependentProps({
        size,
        showStartIndent,
        controls,
        itemsCount,
      })

    const { skeletonItemsInitial, activeSkeletonIndexes } =
      useSkeleton(itemsCountInternal)

    const hasLinkCard = computed(
      () => unref(externalLink)?.buttonText && unref(externalLink)?.href,
    )

    const showTitle = computed(() => unref(title))

    const itemsInternal = computed(() => {
      if (skeleton.value) return skeletonItemsInitial.value

      if (!hasLinkCard.value) return items.value

      return [...items.value, externalLink.value]
    })

    const { mods, style } = useMods({
      size,
      skeleton,
      itemsCount: itemsCountInternal,
      itemsInternal,
      showStartIndent: showStartIndentInternal,
    })

    const { itemsDirection } = useItemsDirection()
    const { itemsAnimating } = useItemsAnimating()

    const { offset, offsetStep, offsetLast, onAnimationEnd } = useSlider({
      items,
      itemsCount: itemsCountInternal,
      itemsInternal,
      itemsAnimating,
      itemsDirection,
    })

    const { activeIndexes: activeRealIndexes } = useItemsActiveIndexes({
      itemsInternal,
      offset,
      offsetStep,
    })

    const activeFakeIndexes = ref([])

    watch(activeRealIndexes, (newValue, oldValue) => {
      activeFakeIndexes.value = [...oldValue]
    })

    const { items: realItems } = useItems({
      itemsInternal,
      size,
      activeIndexes: activeRealIndexes,
      showStartIndent: showStartIndentInternal,
      itemsCount: itemsCountInternal,
    })

    const { items: fakeItems } = useItems({
      itemsInternal,
      size,
      activeIndexes: activeFakeIndexes,
      fake: true,
      showStartIndent: showStartIndentInternal,
      itemsCount: itemsCountInternal,
    })

    const { items: skeletonItems } = useItems({
      itemsInternal,
      size,
      activeIndexes: activeSkeletonIndexes,
      showStartIndent: showStartIndentInternal,
      skeleton,
      itemsCount: itemsCountInternal,
    })

    const {
      itemsWrapperRef,
      itemWidth,
      itemInnerStyle,
      updateItemWidth,
      resetItemsSize,
    } = useItemsSize(itemsRef, skeleton)

    const {
      controlStyle,
      hasTopControls,
      hasSidesControls,
      showControlLeft,
      isControlLeftDisabled,
      showControlRight,
      isControlRightDisabled,
      onControlRightClick,
      onControlLeftClick,
      updateControlStyle,
    } = useControls({
      itemWidth,
      itemsInternal,
      itemsCount: itemsCountInternal,
      offset,
      offsetLast,
      itemsAnimating,
      controls: controlsInternal,
    })

    useResizeObserver({
      itemsWrapperRef,
      updateItemWidth,
      updateControlStyle,
      resetItemsSize,
    })

    watch(realItems, () => {
      // при ресайзе экрана меняются зависимые от брейкпоинтов пропсы, а значит меняются realItems
      // т.к. апдейт ширины запускается на ресайзе тоже, таким образом избегаем конфликта вызываемых методов
      if (unref(windowResizing)) return

      doubleRAF(() => {
        updateItemWidth()
      })
    })

    const {
      connect: sliderObserverConnect,
      disconnect: sliderObserverDisconnect,
    } = useIntersectionObserver(emit, rootRef)

    const {
      itemRef,
      connect: itemObserverConnect,
      disconnect: itemObserverDisconnect,
    } = useItemIntersectionObserver(emit, itemsRef)

    const onItemMounted = (item, index) => {
      if (!unref(skeleton)) {
        itemObserverConnect(item, index)

        if (index + 1 === unref(items).length) {
          emit('slider-mounted')

          sliderObserverConnect()
        }
      }
    }

    const getItemListeners = (item, index) => ({
      'hook:mounted': () => onItemMounted(item, index),
    })

    onUnmounted(() => {
      emit('slider-unmounted')

      itemObserverDisconnect()
      sliderObserverDisconnect()
    })

    return {
      rootRef,
      itemRef,

      testIds,

      mods,
      style,

      showTitle,
      isWebview,

      realItems,
      fakeItems,
      skeletonItems,

      itemsRef,
      itemWidth,
      itemsWrapperRef,
      itemsDirection,
      itemInnerStyle,

      controlStyle,

      hasTopControls,
      hasSidesControls,
      showControlLeft,
      isControlLeftDisabled,
      showControlRight,
      isControlRightDisabled,
      onControlRightClick,
      onControlLeftClick,

      onAnimationEnd,

      getItemListeners,
    }
  },
}

import { PRODUCT_LISTING_PAGE_SIZE, SEO_ATTRS_KEY } from '../../constants'

export const getters = () => ({
  titleSeo() {
    return this.seo.find((item) => item.key === SEO_ATTRS_KEY.TITLE)?.value
  },

  descriptionSeo() {
    return this.seo.find((attr) => attr.key === SEO_ATTRS_KEY.DESCRIPTION)
      ?.value
  },

  additionalAttributesSeo() {
    return this.seo.filter(
      (attr) =>
        attr.key !== SEO_ATTRS_KEY.DESCRIPTION &&
        attr.key !== SEO_ATTRS_KEY.TITLE &&
        attr.value,
    )
  },

  listingPageNumber() {
    return this.listing.currentPageNumber
  },

  canFetchNextPage() {
    return (
      Math.ceil(this.listing.totalCount / PRODUCT_LISTING_PAGE_SIZE) >
      this.listing.currentPageNumber
    )
  },
})

<template>
  <svg fill="none" viewBox="0 0 16 16">
    <path stroke-width="1.25" d="M10.5 3.5 6 8l4.5 4.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-chevron-left',
}
</script>

import {
  EXPIRE_POINT_DATE_FORMAT,
  EXPIRE_POINT_DATE_FORMAT_RTL,
  EXPIRE_POINT_UTC_OFFSET,
} from '../../constants/cards'

export const getters = (gaApp) => ({
  loyaltyCard() {
    return {
      backgroundColor: this.loyalty.cardColor,
      backgroundImage: this.loyalty.cardBackground,
      title: this.loyalty.title,
      bonus: this.loyalty.bonus,
      infoUrl: this.loyalty.infoUrl,
      currentLevel: this.loyalty.currentLevel,
      nextLevel: this.loyalty.nextLevel,
      progress: this.loyalty.progress,
      expireBonus: this.loyalty.expirePoint,
    }
  },
  loyaltyInfoModal() {
    return {
      description: this.loyalty.info,
      title: this.loyalty.infoTitle,
    }
  },
  loyaltyDetailsModal() {
    return {
      total: this.loyalty.bonus,
      list: this.loyalty.pointList?.map((point) => ({
        ...point,
        expirationDate: point.expirationDate
          ? gaApp.libs.dateWrapper
              .utc(point.expirationDate)
              .utcOffset(EXPIRE_POINT_UTC_OFFSET)
              .format(
                gaApp.isRtl
                  ? EXPIRE_POINT_DATE_FORMAT_RTL
                  : EXPIRE_POINT_DATE_FORMAT,
              )
          : '',
      })),
      minValueToDisplayText: this.loyalty.minValueToDisplayText,
    }
  },
})

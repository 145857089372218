import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsContentProductClickModel extends GaAnalyticsBaseModel {
  constructor({ blockName, blockPosition, slideName, slidePosition, itemId }) {
    super('product')

    this.item = {
      creativeName: slideName,
      creativeSlot: `slide_${slidePosition}`,
      promotionName: blockName,
      itemListId: `главная/block_${blockPosition}`,
      itemListName: `главная`, // обязательный параметр бэкенда, нужна доработка
      itemId,
    }
  }
}

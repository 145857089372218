import { onMounted, ref, toRefs } from 'vue'

import { scrollLockFillGap } from '@ga/shared-directives'

import { GaNotification } from '../notification'

import {
  AXIS,
  DIRECTION,
  GaNotificationsSwiper,
  INDENT,
} from './children/notifications-swiper'
import { events } from './scripts/events'
import { validatePosition } from './scripts/props-validators'
import {
  useClose,
  useHandlerTimer,
  useNotificationParams,
  useNotificationsMods,
  useNotificationsStyles,
  useShow,
} from './scripts/use'

// @vue/component
export default {
  name: 'ga-notifications',

  components: { GaNotification, GaNotificationsSwiper },

  directives: {
    scrollLockFillGap,
  },

  props: {
    axis: {
      type: String,
      default: AXIS.X,
      validator: (value) => Object.values(AXIS).includes(value),
    },
    indent: {
      type: Object,
      validator: (value) => value[AXIS.X] && value[AXIS.Y],
      default: () => INDENT,
    },
    direction: {
      type: String,
      default: DIRECTION.FORWARD,
      validator: (value) => Object.values(DIRECTION).includes(value),
    },
    duration: {
      type: Number,
      default: 5000,
    },
    position: {
      type: String,
      default: 'right top',
      validator: (value) => validatePosition(value),
    },
    speed: {
      type: Number,
      default: 300,
    },
    width: {
      type: [String, Number],
      default: '360px',
      validator: (value) => value !== 'auto',
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    closeBySwipe: {
      type: Boolean,
      default: true,
    },
    closeByClick: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const items = ref([])

    const { add, mergedProps } = useShow(items, props, emit)

    const {
      axis: compAxis,
      direction: compDirection,
      stacked: compStacked,
      position,
      speed,
      width,
      indent,
    } = toRefs(mergedProps)

    const { style, notificationWrapperStyle } = useNotificationsStyles(
      position,
      speed,
      width,
      indent,
    )

    const { swipe, queued } = useNotificationsMods(
      compAxis,
      compDirection,
      compStacked,
    )

    const { destroy, close, destroyAll, onClickItem, onSwipeItem } = useClose(
      items,
      emit,
    )

    const { getItemComponent, getItemProps, getItemListeners } =
      useNotificationParams()

    const {
      onNotificationMouseenter,
      onNotificationMouseleave,
      initNotification,
    } = useHandlerTimer()

    onMounted(() => {
      initNotification()

      events.$on('add', add)
      events.$on('close', close)
      events.$on('closeAll', destroyAll)
    })

    return {
      items,

      notificationWrapperStyle,
      style,

      swipe,
      queued,

      compDirection,
      compStacked,
      compAxis,

      destroy,
      onClickItem,
      onSwipeItem,

      onNotificationMouseenter,
      onNotificationMouseleave,

      getItemComponent,
      getItemListeners,
      getItemProps,
    }
  },
}


import { MindboxCartModel } from '../mindbox-cart.model/mindbox-cart.model'

export class MindboxProductItemsModel {
  constructor({items, addToCart},country) {

    this.events = items
        .filter(({price}) => !!price)
        .map(({ id, price }) => new MindboxCartModel({ id, price, addToCart }, country))
  }
}

import { GaIconAdditionalCross } from '@ga/icons'

import { GaAction } from '../../action'
import { GaIcon } from '../../icon'

// @vue/component
export default {
  name: 'ga-modal-close-button',

  components: {
    GaAction,
    GaIcon,
    GaIconAdditionalCross,
  },
}

import { getId } from '@ga/utils'

export class DigitalSetsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Поиск еще невыбранной обложки для набора КЭПК
   * @returns {object} данные обложки
   */
  getUnselectedCoverInfo() {
    const { items: sets, covers } = this.gaApp.stores.giftCardsB2b.digital.sets

    const uniqCoverIndex = covers.findIndex(
      (cover) => !sets.some((set) => set.cover.id === cover.id),
    )
    const coverIndex = uniqCoverIndex === -1 ? 0 : uniqCoverIndex
    const cover = covers[coverIndex]

    return {
      coverIndex,
      cover,
    }
  }

  /**
   * Создает новый набор КЭПК
   * @returns {object} новый набор
   */
  createSet() {
    const id = getId()
    const { coverIndex, cover } = this.getUnselectedCoverInfo()

    return {
      cardsAmount: null,
      cardsValue: null,
      cover,
      coverIndex,
      id,
    }
  }

  /**
   * Добавляет новый набор в список наборов
   */
  addSet() {
    if (
      this.gaApp.stores.giftCardsB2b.digital.setsCount >=
      this.gaApp.stores.giftCardsB2b.digital.sets.rules.maxSets
    ) {
      return undefined
    }

    const newSet = this.createSet()
    this.gaApp.stores.giftCardsB2b.digital.sets.items.push(newSet)
  }

  /**
   * Удаляет набор из списка наборов
   */
  removeSet(index) {
    if (this.gaApp.stores.giftCardsB2b.digital.setsCount <= 1) {
      return undefined
    }

    this.gaApp.stores.giftCardsB2b.digital.sets.items.splice(index, 1)
  }
}

import {
  ProductCartBuilder,
  ProductClickBuilder,
  ProductFavoriteBuilder,
  WidgetAddToCartBuilder,
  WidgetClickBuilder,
} from '../..//builders'
import { BaseController } from '../base.controller/base.controller'

export class ProductCardAnalytics extends BaseController {
  /**
   * Клик по товару
   */
  onProductClick({ product, index, analyticData }) {
    const data = this.getProductData({ product, index, analyticData })

    this.send(ProductClickBuilder, data)
  }

  /**
   * Добавление продукта в корзину.
   */
  onAddToCart({ product, index, analyticData }) {
    const data = this.getProductData({ product, index, analyticData })

    this.send(ProductCartBuilder, { ...data, addToCart: true })
  }

  /**
   * Добавление продукта в избранное.
   */
  onAddToFavorite({ product, index, analyticData }) {
    const data = this.getProductData({ product, index, analyticData })

    this.send(ProductFavoriteBuilder, data)
  }

  /**
   * Клик на карточке блока рекомендаций
   */
  onWidgetClick({ product, placement, index }) {
    this.send(WidgetClickBuilder, {
      product,
      placement,
      index,
    })
  }

  /**
   * Добавление в корзину из карточки блока рекомендаций
   */
  onWidgetAddToCart({ product, placement, index, analyticData }) {
    this.send(WidgetAddToCartBuilder, {
      product: this.getProductData({ product, index, analyticData }),
      placement,
    })
  }
}

import cloneDeep from 'lodash/cloneDeep'

import { computed, ref, unref } from 'vue'

import { getNested, withMessage } from '../utils'

export const useManualErrors = (rules) => {
  const manualErrorField = 'manualError'
  const manualErrorFieldType = 'manual'

  const manualErrors = ref({})

  const addManualError = (fieldPath, message) => {
    manualErrors.value = {
      ...manualErrors.value,
      [fieldPath]: message,
    }
  }

  const removeManualError = (fieldPath) => {
    const filtered = Object.entries(manualErrors.value).filter(
      ([path]) => path !== fieldPath,
    )
    manualErrors.value = Object.fromEntries(filtered)
  }

  const removeAllManualErrors = () => {
    manualErrors.value = {}
  }

  const unifiedRules = computed(() => {
    const rulesValue = unref(rules)
    const originalRulesCopy = cloneDeep(rulesValue)

    Object.entries(manualErrors.value).forEach(([path, message]) => {
      const { pointer: fieldParent, lastNode: fieldName } = getNested(
        originalRulesCopy,
        path,
        false,
      )

      fieldParent[fieldName] = {
        ...fieldParent[fieldName],

        [manualErrorField]: withMessage(() => false, {
          type: manualErrorFieldType,
        })(message),
      }
    })

    return originalRulesCopy
  })

  return {
    addManualError,
    removeAllManualErrors,
    removeManualError,
    unifiedRules,
  }
}

export const TYPE = {
  GTM_4: 'gtm_4',
}

export const ACTIONS = {
  USER_INTERACTION: {
    GA4: 'user_interaction',
  },
}

export const ACTION_CATEGORIES = {
  MAIN: 'main',
}

export const ACTION_TYPES = {
  FORM_SENT_SUBSCRIBE: 'form_sent_subscribe',
}

<template>
  <svg fill="none" viewBox="0 0 17 15">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      d="M13.768 3.027C11.568.826 8.007.823 5.815 3.014a5.607 5.607 0 0 0-1.492 5.255l.596-.596L6.3 6.291l.884.884-1.383 1.382-1.659 1.66-.442.441-.442-.441L.217 7.175l.884-.884 1.937 1.937A6.852 6.852 0 0 1 4.931 2.13c2.682-2.682 7.034-2.674 9.72.013 2.687 2.686 2.695 7.038.014 9.72a6.84 6.84 0 0 1-5.241 1.996 6.883 6.883 0 0 1-2.582-.663l.54-1.127c.668.32 1.387.5 2.113.542a5.59 5.59 0 0 0 4.286-1.632c2.191-2.191 2.188-5.752-.013-7.952Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-return',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref } from 'vue'

import {
  GaIconAdditionalBackArrowModal,
  GaIconAdditionalBackArrowModalV2,
} from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaButton } from '@ga/ui-components/button'
import { GaIcon } from '@ga/ui-components/icon'
import { GaRadio } from '@ga/ui-components/radio'
import { GaRadioGroupBase } from '@ga/ui-components/radio-group-base'

import { GaModal } from '~/components/modal'

// @vue/component
export default {
  name: 'ga-app-lang-switcher-modal',
  components: {
    GaModal,
    GaAction,
    GaButton,
    GaRadioGroupBase,
    GaRadio,
    GaIcon,
    GaIconAdditionalBackArrowModal,
    GaIconAdditionalBackArrowModalV2,
  },
  setup() {
    const { $gaApp } = useContext()

    const isDesktop = computed(() => $gaApp.mq('tablet-large+'))
    const isMobile = computed(() => $gaApp.mq('tablet-'))

    const opened = computed({
      get() {
        return $gaApp.stores.app.langSwitcher.modal.opened
      },
      set(value) {
        if (value) {
          $gaApp.services.app.langSwitcher.openModal()
        } else {
          $gaApp.services.app.langSwitcher.closeModal()
        }
      },
    })

    const lang = ref($gaApp.i18n.locale.code)

    const langOptions = computed(() =>
      $gaApp.services.app.langSwitcher.allLanguages
        .map((locale, index) => {
          return {
            id: index,
            label: locale.title,
            value: locale.code,
            isChecked: locale.code === lang.value,
          }
        })
        .sort((lang) => {
          return lang.value ===
            $gaApp.services.app.langSwitcher.currentLanguage.code
            ? -1
            : 1
        }),
    )

    const modalProps = computed(() => ({
      fullwidth: !unref(isDesktop),
      fullheight: true,
      portalEnabled: false,
      noCloseAction: true,
      position: 'left',
    }))

    const onBackClick = () => {
      opened.value = false
    }

    const onSaveClick = () => {
      $gaApp.services.app.langSwitcher.switchLocale(lang.value)
      window.location.reload()
    }

    return {
      opened,

      lang,
      langOptions,

      isMobile,

      modalProps,

      onBackClick,
      onSaveClick,
    }
  },
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

export const useCurrencyVisible = () => {
  const { $gaApp } = useContext()

  const isDesktop = computed(() => $gaApp.mq('tablet-large+'))

  // Для БЛР и КЗ показываем знак валюты только на tablet-large+
  const defaultConfig = {
    by: isDesktop,
    kz: isDesktop,
  }

  return computed(() => {
    const { country } = $gaApp.i18n.locale
    return unref(defaultConfig[country]) ?? true
  })
}

<template>
  <svg stroke="none" viewBox="0 0 15 15">
    <g
      clip-path="url(#common-15-update-a)"
      fill="inherit"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path
        d="M11.42 10a4.84 4.84 0 1 1-.301-5.023l1.394-.218A6.14 6.14 0 1 0 12.85 10h-1.43Z"
      />
      <path d="m12.898 1-.002 5.147h-4.6v-1.3h3.3L11.599 1h1.3Z" />
    </g>
    <defs>
      <clipPath id="common-15-update-a">
        <path fill="#fff" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-update',
}
</script>

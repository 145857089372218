import { Analytics } from './core'

import { productCardAnalytics } from '~/analytics/global/product-card'
import { productListingAnalytics } from '~/analytics/global/product-listing'

export { useProductCardAnalytic } from './composable/product-card-analytic'
export { useProductSliderAnalytic } from './composable/product-slider-analytic'
export { useProductListingAnalytic } from './composable/product-listing-analytic'

export const buildAnalytics = (gaApp, libs) => {
  const core = new Analytics(gaApp, libs)

  return {
    libs: core,
    global: {
      productCard: productCardAnalytics(gaApp, core),
      productListing: productListingAnalytics(gaApp, core),
    },
    modules: {},
  }
}

import isEmpty from 'lodash/isEmpty'

import { reactive } from 'vue'

import { getItem, isReversedItem } from '../utils'

import { useClose } from './close'

export const useShow = (items, props, emit) => {
  const defaultProps = props

  const mergedProps = reactive({
    ...defaultProps,
  })

  const { close, destroyAll } = useClose(items, emit)

  const mergeParams = (params) => {
    if (!isEmpty(params)) {
      Object.keys(defaultProps).forEach((key) => {
        mergedProps[key] = params[key] ? params[key] : defaultProps[key]
      })
    }
  }

  const setPauseTimersIfStacked = () => {
    if (mergedProps.stacked) {
      items.value.forEach((currentItem) => {
        if (currentItem.timer) {
          currentItem.timer.pause()
        }
      })
    }
  }

  const add = (event) => {
    const { clean, params = {} } = event

    // мерж пропсов нотификации
    const item = getItem({
      event,
      defaultProps,
      close,
      destroyAll,
    })
    // ставим на паузу закрытие всех предыдущих уведомлений, если они складываются стопкой
    setPauseTimersIfStacked()

    let delay = 0

    if (clean && items.value.length > 0) {
      destroyAll()
      delay = params.speed || defaultProps.speed
    }

    const timerAddItem = setTimeout(() => {
      // мерж пропсов нотификации
      mergeParams(params)

      const isReversed = isReversedItem({ params, defaultProps })

      if (!isReversed) {
        items.value.unshift(item)
      } else {
        items.value.push(item)
      }

      emit('open', item)

      clearTimeout(timerAddItem)
    }, delay)

    return item
  }

  return {
    add,
    mergedProps,
  }
}

export const getters = (gaApp) => ({
  counterpartyInfo() {
    return {
      inn: this.counterparty.form.inn,
      ...this.counterparty.info,
    }
  },

  total() {
    return this.sets.items.reduce(
      (total, set) => total + (set.cardsValue || 0) * (set.cardsAmount || 0),
      0,
    )
  },

  stringTotal() {
    return gaApp.services.app.number.format(this.total)
  },

  setsCount() {
    return this.sets.items.length
  },

  firstSet() {
    return this.sets.items[0]
  },
})

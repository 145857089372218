import { getPageScrollBarWidth } from 'scroll-lock'

import { scrollLockScrollable } from '@ga/shared-directives'

import { GaAction } from '@ga/ui-components/action'

import { useAnalytics } from '../../../../../composable/use/analytics'
import GaNavBannersMobile from '../../../../molecules/nav-banners-mobile'

import { GaHeaderNavMinimizedScreensItem } from './children/nav-minimized-screens-item'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-screens',

  components: {
    GaAction,

    GaNavBannersMobile,
    GaHeaderNavMinimizedScreensItem,
  },

  directives: {
    scrollLockScrollable,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value) && value.length,
    },
  },

  setup() {
    const analytic = useAnalytics()

    return {
      analytic,
    }
  },

  data() {
    return {
      screens: [],
      screensLevel: -1,

      screensAnimating: false,
      screensAnimatingBack: false,
    }
  },

  computed: {
    headerMinimized() {
      return this.$gaApp.stores.header.main.headerMinimized
    },

    expandedNavItemsIds() {
      return this.$gaApp.stores.header.nav.expandedNavItemsIds
    },

    expandedNavItemsString() {
      return this.expandedNavItemsIds.join('')
    },

    rootStyle() {
      const { screensLevel } = this

      const translate = screensLevel * 100
      const transform = `translate(${this.isRtl ? translate : -translate}%)`

      return { transform }
    },

    screenInnerStyle() {
      return {
        paddingRight: this.screensAnimating
          ? `${getPageScrollBarWidth()}px`
          : 0,
        overflowY: this.screensAnimating ? 'hidden' : 'scroll',
      }
    },

    isRtl() {
      return this.$gaApp.isRtl
    },
  },

  watch: {
    expandedNavItemsString() {
      if (this.screensAnimatingBack || !this.headerMinimized) {
        return
      }

      this.updateScreens()
    },

    headerMinimized: {
      immediate: true,
      handler(value) {
        if (value) {
          this.updateScreens()
        }
      },
    },

    screensLevel() {
      this.screensAnimating = true
    },

    screensAnimatingBack(value) {
      if (value) {
        return
      }

      this.updateScreens()
    },
  },

  methods: {
    onTransitionEnd(event) {
      if (event.target === this.$el && event.propertyName === 'transform') {
        this.screensAnimating = false
        this.screensAnimatingBack = false
      }
    },

    onBackClick() {
      this.screensLevel -= 1
      this.screensAnimatingBack = true

      const item = this.screens[this.screensLevel]

      if (item) {
        this.$gaApp.services.header.nav.expandNavItem(item)
      } else {
        this.$gaApp.services.header.nav.closeNavItems()
      }
    },

    onItemClick(item) {
      if (item.parent) {
        this.$gaApp.services.header.nav.expandNavItem(item)
        return
      }

      const { nuxt, link } = item
      if (nuxt && link) {
        this.analytic.onMenuItemClick(
          this.$gaApp.stores.header.nav.navMobile,
          item,
        )

        this.$gaApp.router.push(link)
      }
    },

    updateScreens() {
      this.screensLevel = this.expandedNavItemsIds.length - 1
      this.screens = this.$gaApp.services.header.nav.getExpandedNavItems(
        this.items,
      )
    },
  },
}

<template>
  <svg viewBox="0 0 51 20">
    <path
      stroke="none"
      d="M24.125 9.775v5.869h-1.834V1.156h4.864a4.324 4.324 0 0 1 3.143 1.259 4.225 4.225 0 0 1 .206 5.916l-.206.208c-.854.82-1.902 1.236-3.143 1.236h-3.03Zm0-6.84v5.06h3.076a2.38 2.38 0 0 0 1.81-.75c.957-1.006.934-2.623-.056-3.594a2.482 2.482 0 0 0-1.754-.716h-3.076Zm11.72 2.472c1.356 0 2.426.37 3.212 1.098.786.728 1.173 1.745 1.173 3.027v6.112h-1.754v-1.375h-.08c-.762 1.132-1.765 1.698-3.029 1.698-1.07 0-1.982-.323-2.7-.97a3.083 3.083 0 0 1-1.082-2.426c0-1.028.388-1.837 1.15-2.45.764-.612 1.79-.912 3.065-.912 1.093 0 1.982.208 2.688.6v-.427c0-.636-.273-1.236-.752-1.652a2.644 2.644 0 0 0-1.765-.682c-1.025 0-1.834.44-2.426 1.317l-1.618-1.028c.866-1.283 2.176-1.93 3.918-1.93Zm-2.369 7.198c0 .486.228.936.604 1.214.41.323.911.496 1.424.485a2.89 2.89 0 0 0 2.061-.867c.604-.577.911-1.26.911-2.045-.57-.462-1.366-.693-2.391-.681-.74 0-1.367.184-1.868.543-.49.358-.74.808-.74 1.351Z"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M50.299 5.731 44.171 20h-1.89l2.278-4.991-4.021-9.278h1.993l2.904 7.117h.035l2.836-7.117h1.993ZM16.71 8.504c0-.566-.045-1.132-.137-1.687H8.84v3.2h4.43a3.856 3.856 0 0 1-1.64 2.53v2.08h2.643c1.549-1.444 2.437-3.581 2.437-6.123Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M8.84 16.638c2.21 0 4.077-.74 5.433-2.01l-2.643-2.08c-.74.508-1.686.797-2.79.797-2.142 0-3.953-1.467-4.602-3.432H1.516v2.15c1.39 2.807 4.225 4.575 7.324 4.575Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M4.238 9.913a5.088 5.088 0 0 1 0-3.188V4.587H1.516a8.354 8.354 0 0 0 0 7.464l2.722-2.138Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M8.84 3.293a4.38 4.38 0 0 1 3.143 1.248l2.346-2.38A7.854 7.854 0 0 0 8.84 0C5.742 0 2.906 1.78 1.517 4.586l2.722 2.15c.65-1.976 2.46-3.444 4.602-3.444Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-google-pay',
}
</script>

import isNumber from 'lodash/isNumber'

/**
 * Нужно для того, что бы инвертировать знак числа
 *
 * @param {number} value исходное значение
 * @param {boolean|undefined} conditionFlag условие, при котором будет выполнено инвертирование знака числа
 * @returns {number} число с инвертированным знаком
 */
export const reverseNumberSign = (value, conditionFlag) => {
  if (!isNumber(value)) {
    return Number.NaN
  }

  /**
   * Условие conditionFlag === undefined нужно для того,
   * что бы можно было использовать ф-ию без доп. аргументов: reverseNumberSign(10)
   */
  return conditionFlag === undefined || conditionFlag ? value * -1 : value
}

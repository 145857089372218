import { useSwipe } from '@vueuse/core'

import { ref, toRefs, watch } from 'vue'

import { AXIS, DIRECTION } from './scripts/const'
import { useSwipeAnimation } from './scripts/use'

// @vue/component
export default {
  name: 'ga-notifications-swiper',

  props: {
    axis: {
      type: String,
      required: true,
      validator: (value) => Object.values(AXIS).includes(value),
    },
    direction: {
      type: String,
      required: true,
      validator: (value) => Object.values(DIRECTION).includes(value),
    },
    resetDuration: {
      type: Number,
      default: 300,
    },
    resetEasing: {
      type: String,
      default: 'ease',
    },
    threshold: {
      type: Number,
      default: 0.25,
    },
    isSwiped: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const refSwiper = ref(null)
    const { axis, direction, resetDuration, resetEasing, threshold } =
      toRefs(props)

    const {
      style,
      swipeThreshold,
      swiperOffset,
      setSwiperOffset,
      resetSwiperOffset,
    } = useSwipeAnimation(
      refSwiper,
      axis,
      direction,
      resetDuration,
      resetEasing,
      threshold,
    )

    watch([axis, direction], resetSwiperOffset)

    const { lengthX, lengthY } = useSwipe(refSwiper, {
      onSwipe: () => {
        if (!props.isSwiped) {
          return
        }

        setSwiperOffset(lengthX, lengthY)
      },
      onSwipeEnd: () => {
        const isSwiped =
          props.isSwiped &&
          Math.abs(swiperOffset.value) > Math.abs(swipeThreshold.value)

        if (isSwiped) {
          context.emit('swiped')
        } else {
          resetSwiperOffset()
        }
      },
      threshold: 0,
    })

    return {
      refSwiper,
      style,
    }
  },
}

import { computed, unref } from 'vue'

export const useMods = (size, extraCount) => {
  const mods = computed(() => ({
    size: unref(size),
    compact: unref(extraCount) > 0,
  }))

  return {
    mods,
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#F2F2F2" rx="2" />
    <path
      fill="#2566AF"
      d="M13.1 13.799h-1.716l1.073-6.594h1.717l-1.073 6.594ZM9.94 7.205 8.303 11.74l-.194-.976-.577-2.966s-.07-.593-.814-.593H4.012l-.032.111s.827.173 1.796.754l1.491 5.73h1.79l2.73-6.595H9.94Zm13.503 6.594h1.577l-1.375-6.594h-1.38c-.637 0-.792.491-.792.491l-2.56 6.103h1.789l.358-.98h2.182l.201.98Zm-1.889-2.333L22.456 9l.508 2.467h-1.41ZM19.046 8.79l.245-1.416s-.756-.287-1.544-.287c-.852 0-2.875.372-2.875 2.183 0 1.704 2.375 1.725 2.375 2.62 0 .894-2.13.734-2.833.17l-.255 1.48s.766.373 1.937.373c1.172 0 2.94-.607 2.94-2.258 0-1.714-2.396-1.874-2.396-2.62 0-.745 1.672-.65 2.406-.245Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-visa',
}
</script>

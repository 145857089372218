import { LAYOUT_TRANSPARENT_DEFAULT, LAYOUT_VISIBLE_DEFAULT } from './state'

function getLastZIndex(state) {
  const zIndexes = Object.values(state.zIndexes)

  if (zIndexes.length === 0) {
    return state.zIndexBase
  }

  return Math.max(...zIndexes)
}

export const actions = (gaApp) => ({
  reserveZIndex({ id }) {
    const zIndex = getLastZIndex(this) + 1

    this.zIndexes = { ...this.zIndexes, [id]: zIndex }
  },

  unreserveZIndex({ id }) {
    delete this.zIndexes[id]
    this.zIndexes = { ...this.zIndexes }
  },

  setLayoutVisible({ header, main, footer }) {
    this.headerVisible = header
    this.mainVisible = main
    this.footerVisible = footer
  },

  resetLayout() {
    this.footerVisible = LAYOUT_VISIBLE_DEFAULT
    this.headerVisible = LAYOUT_VISIBLE_DEFAULT
    this.mainVisible = LAYOUT_VISIBLE_DEFAULT
  },

  setLayoutTransparent({ header = false, main = false, footer = false }) {
    this.headerTransparent = header
    this.mainTransparent = main
    this.footerTransparent = footer
  },

  resetLayoutTransparent() {
    this.headerTransparent = LAYOUT_TRANSPARENT_DEFAULT
    this.mainTransparent = LAYOUT_TRANSPARENT_DEFAULT
    this.footerTransparent = LAYOUT_TRANSPARENT_DEFAULT
  },

  setRouter({ from, to }) {
    this.router.from = from
    this.router.to = to
  },
})

import { hide } from '../../../../../utils/schemas/hide'

export const priceSize = {
  type: 'object',
  properties: {
    fontSize: {
      type: 'number',
      required: true,
      description: 'Размер шрифта в пикселях',
    },
    threshold: {
      oneOf: [{ type: 'number' }, hide],
      required: true,
      description:
        'Пороговое значение веса цены после преодоления которого, нужно уменьшить размер шрифта',
    },
  },
  additionalProperties: false,
}

export default function (analytic, context) {

  const getData = () => {
    const operation = {
      ru: 'WebsiteRU.AuthenticatedOnWebsite.JS',
      by: 'WebsiteBY.AuthenticatedOnWebsite.JS',
      kz: 'WebsiteKZ.AuthenticatedOnWebsite.JS',
      qa: 'WebsiteQA.AuthenticatedOnWebsite.JS'
    }[context.i18n.locale.country]

    const { id, email, phone } = context.stores.user.main.data

    return {
      operation,
      id,
      email,
      phone,
    }
  }

  return {
    // Открытие модального окна
    onSuccessLogin() {
      const { operation, id, email, phone } = getData()

      analytic.send({
        operation: operation,
        data: {
          customer: {
            ids: {
              userwebsiteid: id,
            },
            email: email,
            mobilePhone: phone,
          },
        },
      })
    },
  }
}

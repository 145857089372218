<template>
  <svg fill="none" viewBox="0 0 104 22">
    <rect width="104" height="22" y=".004" fill="#DCFF00" rx="11" />
    <rect
      width="96"
      height="18"
      x="4"
      y="2.004"
      fill="#DCFF00"
      fill-opacity=".5"
      rx="9"
    />
    <path
      fill="#000"
      d="M19.61 11.954c0 1.23-.71 2.69-4.38 2.69h-.48c-4.15 0-4.48-1.71-4.48-2.44v-.07h2.95c.03.1.17.67 1.76.67 1.43 0 1.68-.44 1.68-.85v-.04c0-.4-.25-.84-1.68-.84-1.58 0-1.75.51-1.78.58h-2.79v-4.59h8.68v1.81h-5.87v1.21c.15-.13.88-.66 2.63-.66h.09c3 0 3.67 1.13 3.67 2.47v.06Zm3.44-.66c-2.56 0-2.97-1.33-2.97-2.1v-.16c0-.77.42-2.05 2.97-2.05h.22c2.55 0 2.96 1.28 2.96 2.05v.16c0 .77-.4 2.1-2.96 2.1h-.22Zm7.05-4.23h2.15l-8.16 7.44h-2.15l8.16-7.44Zm-6.95 2.91c.86 0 1.15-.42 1.15-.82v-.06c0-.43-.28-.81-1.15-.81-.86 0-1.14.38-1.14.81v.05c0 .41.29.83 1.14.83Zm8.02 4.61h-.24c-2.54 0-2.96-1.33-2.96-2.1v-.16c0-.78.42-2.06 2.97-2.06h.22c2.55 0 2.97 1.28 2.97 2.06v.16c0 .77-.41 2.1-2.96 2.1Zm-.12-1.32c.86 0 1.15-.42 1.15-.82v-.07c0-.42-.29-.8-1.15-.8-.88 0-1.15.38-1.15.8v.05c0 .42.29.84 1.15.84Zm12.01 1.24-2-2.42v2.42h-2.72v-5.38h2.72v2.1l1.84-2.1h2.93l-2.19 2.38 2.51 3h-3.09Zm11.292-2.63c0 1.12-.54 2.78-3.96 2.78h-.6c-3.52 0-3.96-1.64-3.96-2.4v-.02h2.77c.04.35.29 1.04 1.51 1.04h.04c.84 0 1.23-.34 1.39-.85h-2.36v-1.26h2.37c-.15-.48-.54-.83-1.4-.83h-.04c-1.24 0-1.46.66-1.51 1h-2.77v-.03c0-.72.46-2.33 3.95-2.33h.62c3.35 0 3.95 1.61 3.95 2.72v.18Zm.829 2.63v-5.38h2.72v3.74h1.62v-3.74h2.72v3.74h1.62v-3.74h2.71v5.38H55.18Zm20.928-2.45c0 1.67-1.5 2.6-4.14 2.6h-.41c-2.5 0-4.15-.94-4.15-3.27v-.47c0-2.36 1.16-3.74 4.29-3.74h1.06c.53 0 .58-.2.58-.24h2.6c-.01 1.37-.66 2.01-2.7 2.01h-1.23c-1.61 0-2.2.46-2.21 1.41.42-.45 1.39-.89 2.87-.89h.1c1.86 0 3.34.71 3.34 2.4v.19Zm-4.34 1.07c1.13 0 1.58-.55 1.58-1.11v-.07c0-.59-.46-1.06-1.58-1.06s-1.6.47-1.6 1.06v.08c0 .57.46 1.1 1.6 1.1Zm13.454-.56c0 .75-.46 2.09-3.97 2.09h-.53c-3.3 0-4-1.51-4-2.77v-.2c0-1.25.64-2.71 3.97-2.71h.55c3.51 0 4 1.64 4 2.93v.3h-5.85c.09.55.53.99 1.63.99h.01c1.29 0 1.49-.52 1.51-.65h2.68v.02Zm-4.25-2.27c-1.03 0-1.42.33-1.55.81h3.1c-.1-.5-.53-.81-1.55-.81Zm9.798 4.21-2-2.42v2.42h-2.72v-5.38h2.72v2.1l1.84-2.1h2.93l-2.19 2.38 2.51 3h-3.09Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-cashback-label',
}
</script>

import { ALL_STORES_KEY } from '../constants/keys'

export class StoresService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getCityStores(cityId) {
    return this.gaApp.stores.promo.filters.stores[cityId]
  }

  setStoresForSelect(cityId) {
    const data = this.getCityStores(cityId)
    this.gaApp.stores.promo.filters.currentStores =
      this.mapStoreDataToSelectOptions(data.stores)
  }

  async changeCurrentShopId(shopId) {
    if (this.gaApp.stores.promo.filters.currentShop === shopId) {
      return
    }

    this.setCurrentShop(shopId)
    this.gaApp.services.promo.filters.setMobileSelectedFilters()

    const shouldReloadPage = this.gaApp.stores.promo.listing.shouldReloadPage
    shouldReloadPage && (await this.gaApp.services.promo.listing.loadNewPage())
  }

  setStores(shops) {
    this.gaApp.stores.promo.filters.stores = this._mapStores(shops)
  }

  setCurrentShop(shop) {
    this.gaApp.stores.promo.filters.currentShop = shop
  }

  setPreviousShop(shopId) {
    this.gaApp.stores.promo.filters.previousShop = shopId
  }

  resetCurrentStoreId() {
    const storeKey =
      this.gaApp.stores.promo.filters.currentStores.length === 1
        ? this.gaApp.stores.promo.filters.currentStores[0].value
        : ALL_STORES_KEY

    this.setCurrentShop(storeKey)
    this.gaApp.services.promo.filters.setMobileSelectedFilters()
  }

  mapStoreDataToSelectOptions(data) {
    const all = {
      text: this.gaApp.i18n.t('promo.select.shopLabel'),
      value: ALL_STORES_KEY,
    }

    const stores = data.map((store) => ({
      text: store.mollsName
        ? `${store.mollsName}, ${store.address}`
        : store.address,
      value: store.id,
    }))

    return data.length > 1 ? [all, ...stores] : stores
  }

  _mapStores(shops) {
    return this.gaApp.stores.promo.filters.cities.reduce((stores, city) => {
      if (!shops[city.text]) {
        return stores
      }

      if (!stores[city.value]) {
        stores[city.value] = {
          city: city.text,
          stores: [],
        }
      }

      stores[city.value].stores.push(...shops[city.text])

      return stores
    }, {})
  }
}

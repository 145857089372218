import { computed, reactive, unref, useSlots } from 'vue'

/**
 * Нужно для управление выводом слотов.
 *
 * Выводим слот, если в слот передали контент снаружи, либо если слот имеет контент по-умолчанию.
 * А так же собираем properties сцелью прокидывания в слот, и использования properties по месту.
 *
 * @param {object} product - объект продукта
 * @param {object} props - некоторые пропсы продукта
 * @param {object} sizes - размеры компонентов для каждого слота см. в компоненте карточки scripts/composables/sizes.js
 * @param {object} mods - некоторые стилевые модификаторы
 */
export const useSlot = ({ product, props, sizes, mods }) => {
  const slots = useSlots()

  /**
   * Проверяет, был ли передан контент в слот или слот имеет контент по умолчанию.
   *
   * @param {string} slotName - название слота.
   * @returns {boolean} - true, если передан контент в слот или слот имеет контент по умолчанию.
   */
  const has = (slotName) => {
    const slot = slots[slotName]?.()

    // Если передали контент в слот, то выводим слот
    if (slot) {
      return true
    }

    const attrs = unref(product).attributes

    // Если в слот ничего не передали, то проверяем наличие данных для дефолтного контента
    switch (slotName) {
      case 'top-1':
        return attrs && (attrs.colors.count > 1 || attrs.units.count > 1)

      // В этом слоте нет дефолтного контента
      // case 'top-2':

      // В этом слоте нет дефолтного контента
      // case 'top-3':

      // В этом слоте нет дефолтного контента
      // case 'bot-1':

      default:
        return false
    }
  }

  const hasSomeTop = computed(() => {
    return ['top-1', 'top-2', 'top-3'].some(has)
  })

  const properties = reactive({
    sizes,
    mods: {
      reversed: unref(props)?.reversed,
      highlight: unref(mods)?.highlight,
    },
  })

  return {
    has,
    hasSomeTop,
    properties,
  }
}

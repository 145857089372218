import { computed, unref } from 'vue'

import { transitionPropsBuilder } from '@ga/utils'

import { STATE, TRANSITION, TRANSITIONS_WITH_DIRECTION } from '../const'

/**
 * Возвращает общие стили для transition, возвращает модификаторы transition
 * @param {Ref<import('../const').State>} state - состояние модалки
 * @param {Ref<import('../const').Transition} transitionOpening
 * @param {Ref<import('../const').Transition} transitionClosing
 * @param {Ref<Number>} transitionOpeningDelay
 * @param {Ref<Number>} transitionClosingDelay
 * @param {Ref<import('../const').Position>} position
 * @param {Ref<Boolean>} fullwidth
 * @param {Function} b - bem функция контекста
 */
export const useTransition = (
  state,
  transitionOpening,
  transitionClosing,
  transitionOpeningDelay,
  transitionClosingDelay,
  position,
  fullwidth,
  b,
) => {
  const transitionStyles = computed(() => {
    const transitionDelayCurrent =
      unref(state) === STATE.OPENING
        ? unref(transitionOpeningDelay)
        : unref(transitionClosingDelay)

    const transitionDelay =
      typeof transitionDelayCurrent === 'number'
        ? `${transitionDelayCurrent}ms`
        : null

    return {
      transitionDelay,
    }
  })

  const activeTransition = computed(() => {
    let transition

    // Упрощаем транзишен, если используется fullwidth
    if (unref(fullwidth) && unref(transitionOpening) === TRANSITION.FANCY) {
      transition = TRANSITION.SLIDE
    } else {
      transition =
        unref(state) === STATE.OPENING
          ? unref(transitionOpening)
          : unref(transitionClosing)
    }

    return transition
  })

  const transitionMods = computed(() => {
    return {
      transition: unref(activeTransition),
      [`transition_${unref(activeTransition)}`]: unref(position),
    }
  })

  const transitionProps = computed(() => {
    const transitionName = TRANSITIONS_WITH_DIRECTION.includes(
      unref(activeTransition),
    )
      ? `transition_${unref(activeTransition)}_${unref(position)}`
      : `transition_${unref(activeTransition)}`

    return transitionPropsBuilder(b)(transitionName)
  })

  return {
    transitionStyles,
    transitionMods,
    transitionProps,
  }
}

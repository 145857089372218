<template>
  <svg fill="inherit" viewBox="0 0 37 37">
    <circle cx="18.5" cy="18.5" r="18.5" fill="inherit" opacity=".6" />
    <path fill="inherit" d="m24.667 18.5-10.175 5.874V12.625L24.667 18.5Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-play-dark',
}
</script>

export const getters = (gaApp) => ({
  humanCurrentStep() {
    return this.currentStep + 1
  },

  orderId() {
    return gaApp.stores.review.products.orderId
  },

  itemId() {
    return gaApp.stores.review.products.itemId
  },
})

<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#F2F2F2" rx="2" />
    <g
      clip-path="url(#payment-cards-mir-a)"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path
        fill="#4DB45E"
        d="M9.659 7v.003c-.003 0-.82-.003-1.038.8-.2.736-.764 2.77-.78 2.825h-.155S7.11 8.55 6.907 7.8C6.69 6.997 5.87 7 5.87 7H4v6.11h1.869V9.48h.156l1.09 3.628h1.298l1.09-3.625h.156v3.625h1.869V7h-1.87Z"
      />
      <path
        fill="#4DB45E"
        d="M16.486 7s-.548.05-.805.64l-1.324 2.988h-.155V7h-1.87v6.11h1.766s.573-.054.83-.641l1.298-2.988h.156v3.628h1.869V7h-1.765Z"
      />
      <path
        fill="#4DB45E"
        d="M19.081 9.773v3.335h1.87v-1.947h2.024c.883 0 1.63-.58 1.908-1.388h-5.802Z"
      />
      <path
        fill="url(#payment-cards-mir-b)"
        d="M22.976 7H18.82c.207 1.163 1.056 2.097 2.154 2.401.25.07.511.107.781.107h3.204c.028-.14.041-.28.041-.427C25 7.93 24.094 7 22.976 7Z"
      />
    </g>
    <defs>
      <linearGradient
        id="payment-cards-mir-b"
        x1="18.82"
        x2="25"
        y1="8.254"
        y2="8.254"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stop-color="#00B4E6" />
        <stop offset="1" stop-color="#088CCB" />
      </linearGradient>
      <clipPath id="payment-cards-mir-a">
        <path fill="#fff" d="M0 0h21v6.109H0z" transform="translate(4 7)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-mir',
}
</script>

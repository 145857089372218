import { AdvcakeBaseModel } from '../advcake-base.model/advcake-base.model'
import { AdvcakeProductModel } from '../advcake-product.model/advcake-product.model'
import { ACTIONS } from '../../../constants'

export class AdvcakeProductCartModel extends AdvcakeBaseModel {
  constructor({ id, name, price, quantity = 1, addToCart, isAdded }) {
    let _pageType, _quantity

    if (addToCart) {
      _pageType = isAdded ? ACTIONS.CHANGE_QYANTITY_IN_CART.ADVCAKE : ACTIONS.ADD_TO_CART.ADVCAKE
      _quantity = quantity
    } else {
      _pageType = ACTIONS.REMOVE_FROM_CART.ADVCAKE
      _quantity = 0
    }

    super(_pageType)

    this.currentProduct = new AdvcakeProductModel({id, name, price})
    this.currentProduct.quantity = _quantity
  }
}

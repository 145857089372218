/*
 * Данная достает ссылку на поле из вложенного объекта.
 */

export const getNested = (object, path, includeLast = true) => {
  if (!path) {
    return object
  }

  let pointer = object
  const nodes = path.split('.')
  const lastNode = nodes.pop()

  for (const node of nodes) {
    if (!pointer[node]) {
      return undefined
    }

    pointer = pointer[node]
  }

  if (includeLast) {
    return pointer[lastNode]
  }

  return {
    pointer,
    lastNode,
  }
}

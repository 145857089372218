export class GalleryService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  toggleModal(value) {
    value ? this.openModal() : this.closeModal()
  }

  openModal() {
    this.gaApp.stores.articles.gallery.isOpened = true
  }

  closeModal() {
    this.gaApp.stores.articles.gallery.isOpened = false
  }

  setActiveSlideIndex(value) {
    this.gaApp.stores.articles.gallery.activeSlideIndex = value
  }

  fillModal(slides, activeSlideIndex = 0) {
    this.gaApp.stores.articles.gallery.slides = slides
    this.gaApp.stores.articles.gallery.slideRefs = []
    this.setActiveSlideIndex(activeSlideIndex)
  }

  addSlideRef(el) {
    if (el) {
      this.gaApp.stores.articles.gallery.slideRefs.push(el)
    }
  }
}

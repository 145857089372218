import { useContext } from '@nuxtjs/composition-api'

import { GaAction } from '@ga/ui-components/action'

// @vue/component
export default {
  name: 'ga-header-contacts',

  components: {
    GaAction,
  },

  setup() {
    const { $gaApp } = useContext()

    const { messengers, phones } = $gaApp.stores.app.common.contactsModalData

    const whatsappHref = messengers.whatsapp?.href
    const supportText = phones.support?.text.replace('+7', '8')
    const supportHref = phones.support?.href

    return {
      whatsappHref,
      supportHref,
      supportText,
    }
  },
}

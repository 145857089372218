export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchCmsContent(key) {
    const { data } = await this.gaApp.repositories.info.main.getCmsContent(key)
    this.gaApp.stores.info.cms.info = data
  }

  async fetchContactsInfo() {
    const { data } = await this.gaApp.repositories.info.main.fetchContactsInfo()

    this.gaApp.stores.info.contacts.title = data.title
    this.gaApp.stores.info.contacts.description = data.description
    this.gaApp.stores.info.contacts.items = data.items
  }

  async subscribe(firstName, lastName, email, locale = 'launchQa') {
    try {
      if (!firstName || !lastName || !email) {
        throw new Error('The information for subscription is not full')
      }
      if (locale === 'launchAe') {
        await this.gaApp.repositories.info.main.launchAeSubscribe({
          firstName,
          lastName,
          email,
        })
      } else {
        await this.gaApp.repositories.info.main.launchQaSubscribe({
          firstName,
          lastName,
          email,
        })
      }
    } catch (error) {
      const subscribed = error.statusCode === 409

      if (subscribed) {
        const error = new Error('E-mail is already subscribed')
        error.alreadySubscribed = true

        throw error
      }
    }
  }
}

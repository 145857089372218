import { computed, toRefs } from 'vue'

import { useAnimationHooks, useTransition } from './scripts/composables'

// @vue/component
export default {
  name: 'ga-expand-transition',

  inheritAttrs: false,

  props: {
    /**
     * Объект, содержащий классы анимаций для transition.
     *
     * Для генерации этого объекта используйте утилиту `useTransitionProps`. Если настройки анимации не указаны, то будет использована анимация по умолчанию: ./styles/elems/expand.scss
     */
    transitionProps: {
      type: Object,
      default: null,
    },

    /**
     * Использовать для анимации `<transition-group ... />`
     */
    group: {
      type: Boolean,
      default: false,
    },

    /**
     * При изменении высоты содержимое не обрезается
     */
    overflowVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup: (props, { attrs }) => {
    const { transitionProps, group, overflowVisible } = toRefs(props)

    const componentType = computed(() =>
      group.value ? 'transition-group' : 'transition',
    )

    const { transitionPropsInternal } = useTransition(transitionProps)
    const { onAfterEnter, onAfterLeave, onEnter, onLeave } =
      useAnimationHooks(overflowVisible)

    const rootProps = computed(() => ({
      ...attrs,
      ...transitionPropsInternal.value,
    }))

    return {
      componentType,
      onAfterEnter,
      onAfterLeave,
      onEnter,
      onLeave,
      rootProps,
    }
  },
}

/**
 * @typedef {string} Tabs
 */

/**
 * @enum {Tabs}
 */

export const TABS = {
  MAIN: 'main',
  NAV: 'nav',
  SEARCH: 'search',
  FAVORITES: 'favorites',
  AUTH: 'profile',
  CART: 'cart',
}

/**
 * @typedef {string} State
 */

/**
 * @enum {State}
 */

export const STATE = {
  INITIAL: 'initial',
  SCROLLED: 'scrolled',
  ACTIVE: 'active',
}

export const SCROLL_DISTANCE_DEBOUNCE = 100
const SCROLL_DISTANCE_LIMIT = 30
export const NEGATIVE_SCROLL_DISTANCE_LIMIT = -SCROLL_DISTANCE_LIMIT
export const POSITIVE_SCROLL_DISTANCE_LIMIT = SCROLL_DISTANCE_LIMIT

export const HEADER_EXTENSTION_TRANSITOIN_DURATION = 250 // $header-extension-transition-duration
export const HEADER_EXTENSTION_TRANSITOIN_TIMING_FUNCTION = 'ease-in-out' // $header-extension-transition-timing-function

export const COLOR_SET_PROPERTY = {
  BACKGROUND: 'background',
  FOREGROUND: 'foreground',
  ACCENT: 'accent',
}

export const CUSTOM_PROPERTY_NAMESPACE = '--header-color-set'

export const CUSTOM_PROPERTY = {
  BACKGROUND: `${CUSTOM_PROPERTY_NAMESPACE}-${COLOR_SET_PROPERTY.BACKGROUND}`,
  FOREGROUND: `${CUSTOM_PROPERTY_NAMESPACE}-${COLOR_SET_PROPERTY.FOREGROUND}`,
  ACCENT: `${CUSTOM_PROPERTY_NAMESPACE}-${COLOR_SET_PROPERTY.ACCENT}`,
}

export const DEFAULT_BACKGROUND_COLOR = '#fff'
export const DEFAULT_BACKGROUND_TRANSPARENT_COLOR = 'transparent'
export const DEFAULT_FOREGROUND_COLOR = '#000'
export const DEFAULT_ACCENT_COLOR = '#fff'

export const COLOR_SET = {
  REGULAR: {
    [COLOR_SET_PROPERTY.BACKGROUND]: DEFAULT_BACKGROUND_COLOR,
    [COLOR_SET_PROPERTY.FOREGROUND]: DEFAULT_FOREGROUND_COLOR,
    [COLOR_SET_PROPERTY.ACCENT]: DEFAULT_ACCENT_COLOR,
  },
  TRANSPARENT: {
    [COLOR_SET_PROPERTY.BACKGROUND]: DEFAULT_BACKGROUND_TRANSPARENT_COLOR,
    [COLOR_SET_PROPERTY.FOREGROUND]: DEFAULT_FOREGROUND_COLOR,
    [COLOR_SET_PROPERTY.ACCENT]: DEFAULT_ACCENT_COLOR,
  },
  TRANSPARENT_INVERTED: {
    [COLOR_SET_PROPERTY.BACKGROUND]: DEFAULT_BACKGROUND_TRANSPARENT_COLOR,
    [COLOR_SET_PROPERTY.FOREGROUND]: DEFAULT_ACCENT_COLOR,
    [COLOR_SET_PROPERTY.ACCENT]: DEFAULT_FOREGROUND_COLOR,
  },
}

export const COLOR_SCHEME = {
  REGULAR: {
    [STATE.INITIAL]: COLOR_SET.REGULAR,
    [STATE.SCROLLED]: COLOR_SET.REGULAR,
    [STATE.ACTIVE]: COLOR_SET.REGULAR,
  },
  TRANSPARENT: {
    [STATE.INITIAL]: COLOR_SET.TRANSPARENT,
    [STATE.SCROLLED]: COLOR_SET.REGULAR,
    [STATE.ACTIVE]: COLOR_SET.REGULAR,
  },
  TRANSPARENT_INVERTED: {
    [STATE.INITIAL]: COLOR_SET.TRANSPARENT_INVERTED,
    [STATE.SCROLLED]: COLOR_SET.REGULAR,
    [STATE.ACTIVE]: COLOR_SET.REGULAR,
  },
}

export const HEADER_DESKTOP_BREAKPOINT = 'desktop-large' // $breakpoint-desktop
export const TABLET_LANDSCAPE_BREAKPOINT = 'tablet-landscape' // $breakpoint-tablet-l
export const MOBILE_BREAKPOINT = 'mobile' // $breakpoint-mobile

export const HEADER_HEIGHT_FULL_DESKTOP = 120 // $header-height-full-desktop
export const HEADER_HEIGHT_DESKTOP = 60 // $header-height-desktop
export const HEADER_HEIGHT_TABLET = 60 // $header-height-tablet
export const HEADER_HEIGHT_MOBILE = 50 // $header-height-mobile

export const HEADER_BREAKPOINT = {
  MOBILE: MOBILE_BREAKPOINT,
  TABLET: TABLET_LANDSCAPE_BREAKPOINT,
  DESKTOP: HEADER_DESKTOP_BREAKPOINT,
}

export const HEADER_HEIGHT = {
  [HEADER_BREAKPOINT.DESKTOP]: HEADER_HEIGHT_FULL_DESKTOP,
  [HEADER_BREAKPOINT.TABLET]: HEADER_HEIGHT_TABLET,
  [HEADER_BREAKPOINT.MOBILE]: HEADER_HEIGHT_MOBILE,
}

export const HEADER_HEIGHT_COLLAPSED = {
  [HEADER_BREAKPOINT.DESKTOP]: HEADER_HEIGHT_DESKTOP,
  [HEADER_BREAKPOINT.TABLET]: HEADER_HEIGHT_TABLET,
  [HEADER_BREAKPOINT.MOBILE]: HEADER_HEIGHT_MOBILE,
}

import { ORDER_SECTIONS } from '../../constants'

export function getSectionItems(section) {
  return section.sections
    ? section.sections.flatMap(getSectionItems)
    : section.items
}

export function getMapSections(items) {
  const itemsForMap = items
    .reduce(
      (acc, item) => {
        if (item.deleted) {
          return acc
        }

        const indexView =
          ORDER_SECTIONS.findIndex((name) => name === item.view) ?? 0

        acc[indexView][1].push(item)

        return acc
      },
      ORDER_SECTIONS.map((name) => [name, []]),
    )
    .filter(([_, value]) => value.length > 0)

  return new Map(itemsForMap)
}

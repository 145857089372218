import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'

export const cart = {
  meta: {
    name: 'cart',
    locales,
    routes,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

export { useCartAdd } from './composable'

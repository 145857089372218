<template>
  <svg fill="none" stroke="none" viewBox="0 0 90 50">
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M18.793 21.014h-7.767v7.734H9v-7.734h2.026v-2.018h7.767v2.018Zm0 9.752h-7.767v-2.018h7.767v2.018Zm2.026-2.018h-2.026v-7.734h2.026v7.734Zm15.702 0h1.942v-6.03h3.883v6.03h1.942v-7.734H36.52v7.734Zm30.089-6.03h-3.074v-1.704h8.105v1.705h-3.074v6.03H66.61v-6.03Zm-19.805 4.097h-1.166v1.933h.615c1.49 0 2.343-.854 2.343-2.343v-3.688h3.382v6.031h1.933v-7.734h-7.107v5.8Zm27.8-.361h.094l3.436-5.44h2.623v7.734h-1.953v-5.44h-.092l-3.436 5.44h-2.624v-7.734h1.952v5.44Zm-16.263-.917 1.244-2.838h.133l1.244 2.838h-2.62Zm.049-4.523-3.466 7.734h2.01l.66-1.508h4.114l.66 1.508h2.01l-3.465-7.734h-2.523Zm-30.528 3.783c0 1.404.875 2.35 2.664 2.35 1.803 0 2.665-.935 2.665-2.35 0-1.41-.895-2.35-2.665-2.35-1.767 0-2.664.94-2.664 2.35Zm-1.979 0c0-2.575 1.789-4.12 4.643-4.12 2.943 0 4.643 1.666 4.643 4.12 0 2.487-1.7 4.12-4.643 4.12-2.833 0-4.643-1.524-4.643-4.12Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-oplati',
}
</script>

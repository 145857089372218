import { validate } from 'jsonschema'

export const propValidator = (value, schema) => {
  const result = validate(value, schema, { nestedErrors: true })

  if (!result.valid) {
    console.error(result.errors)
    return false
  }

  return true
}

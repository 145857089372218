/**
 * Отфильтровывает недействительные экраны из предоставленной конфигурации.
 *
 * @param {Object} [config={}] — объект конфигурации для фильтрации.
 * @return {Object} Новый объект, содержащий только действительные экраны из исходной конфигурации.
 */
export const filterInvalidScreens = (config = {}) => {
  if (!config) {
    return {}
  }

  return Object.keys(config).reduce((accumulator, current, index) => {
    if (config[current]) {
      accumulator[current] = config[current]
    }

    return accumulator
  }, {})
}

import { doubleRAF } from '@ga/shared-browser'
import { useTransitionProps } from '@ga/use/transition-props'

import { useAnalytics } from '../../../../../composable/use/analytics'
import GaNavBannersDesktop from '../../../../molecules/nav-banners-desktop'

import GaHeaderNavFullExtensionList from './children/nav-full-extension-list'
import { hasVerticalBanners } from './utils'

const SELECT_MOUSE_ENTER_DELAY = 200
const VERTICAL_BANNER_SPACE_HEIGHT = 580

// @vue/component
export default {
  name: 'ga-header-nav-full-extension',

  components: {
    GaNavBannersDesktop,
    GaHeaderNavFullExtensionList,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
      validator: (value) => typeof value === 'object',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const analytic = useAnalytics()

    return {
      analytic,

      transitionProps: useTransitionProps(),
    }
  },

  data() {
    return {
      lists: null,
      listsMinHeight: 0,

      selectMouseEnterTimeout: null,
    }
  },

  computed: {
    listIndependentScroll() {
      return this.$gaApp.features.get('navIndependentScroll')
    },

    headerMinimized() {
      return this.$gaApp.stores.header.main.headerMinimized
    },

    banners() {
      return this.$gaApp.services.header.nav.getBannersNavFull()
    },

    listsStyle() {
      const minHeight = `${this.listsMinHeight}px`

      return { minHeight }
    },

    hasVerticalBanners() {
      return hasVerticalBanners(this.item)
    },
  },

  watch: {
    headerMinimized(value) {
      if (value || !this.active) {
        return
      }

      this.updateHeight()
    },

    active(value) {
      if (!value) {
        return
      }

      doubleRAF(() => {
        this.updateHeight()
        this.resetScroll()
      })
    },
  },

  mounted() {
    this.lists = [...this.$el.querySelectorAll('ul')]

    this.updateHeight()
  },

  methods: {
    onItemClick(item) {
      this.analytic.onMenuItemClick(this.$gaApp.stores.header.nav.nav, item)

      this.$gaApp.services.header.nav.expandNavItem(item)
    },

    onItemClickKeyboard(item) {
      this.$gaApp.services.header.nav.expandNavItem(item)
    },

    onItemMouseEnter(item) {
      this.resetMouseEnterTimeout(item)
    },

    onItemMouseLeave() {
      this.unsetMouseEnterTimeout()
    },

    resetMouseEnterTimeout(item) {
      this.unsetMouseEnterTimeout()
      this.setMouseEnterTimeout(item)
    },

    unsetMouseEnterTimeout() {
      clearTimeout(this.selectMouseEnterTimeout)
    },

    setMouseEnterTimeout(item) {
      this.selectMouseEnterTimeout = setTimeout(() => {
        this.$gaApp.services.header.nav.expandNavItem(item)
      }, SELECT_MOUSE_ENTER_DELAY)
    },

    updateHeight() {
      if (this.listIndependentScroll) return

      const heights = this.lists.map((list) => list.clientHeight)

      this.listsMinHeight = this.hasVerticalBanners
        ? Math.max(...heights, VERTICAL_BANNER_SPACE_HEIGHT)
        : Math.max(...heights)
    },

    resetScroll() {
      this.$el.scrollTop = 0
      if (this.listIndependentScroll) {
        this.$refs.listRef?.resetScroll()
      }
    },
  },
}

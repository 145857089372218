import { requiredUnless } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (locator, message) => {
  return withMessage(requiredUnless(locator), {
    type: 'requiredUnless',
    message: 'app.validation.required',
  })(message)
}

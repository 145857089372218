import { unref } from 'vue'

import { useImageBreakpoints } from '../../use/image-breakpoints'

export const sortByBreakpoint = (breakpointsKeys) => {
  const { breakpoints } = useImageBreakpoints()

  const priorities = unref(breakpoints).keys.reduce(
    (accumulator, current, index) => ({
      ...accumulator,
      [current]: index,
    }),
    {},
  )

  return breakpointsKeys.sort((a, b) => priorities[a] - priorities[b])
}

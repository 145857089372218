import { useEventListener } from '@vueuse/core'
import debounce from 'lodash/debounce'

import { set } from 'vue'

import { AUTOPLAY_DELAY, CARD_SIZE_NUMBER } from '../constant'

/**
 * Сервис для проигрывания видео в универсальных карточках по очередности
 * Старт нового видео начинается после того как заканчивается предыдущее
 * Очередь воспроизведения формируется исходя из видимости карточек
 * Очередность определяется размером карточки - от большего к меньшему, и порядковым номером в массиве продуктов
 * Старт воспроизведения начинается при скроле страницы с задержкой
 * При старте очереди воспроизведения, ранее проигрываемые видео не останавливаются и доигрываются до конца
 */
export class MediaService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Функция playVideo проигрывает видео для заданного productId.
   *
   * @param {object} productId - ID продукта, для которого проигрывается видео
   * @return {void}
   */
  playVideo({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].played = false
    const isPlaying =
      this.gaApp.stores.plp.media.mapVideoState[productId].playing

    if (isPlaying) {
      return
    }
    this.gaApp.stores.plp.media.currentPlayingId = productId

    this.gaApp.services.app.eventBus.emit('module/product-card/video/play', {
      productId,
    })
  }

  /**
   * Остановить видео, вызывая событие через шину событий приложения.
   *
   * @param {Object} productId - идентификатор продукта, связанного с видео
   * @return {undefined}
   */
  stopVideo({ productId }) {
    const isPlaying =
      this.gaApp.stores.plp.media.mapVideoState[productId].playing

    if (!isPlaying) {
      return
    }

    this.gaApp.services.app.eventBus.emit('module/product-card/video/stop', {
      productId,
    })
    this.gaApp.stores.plp.media.mapVideoState[productId].playing = false
  }

  /**
   * Функция, которая проверяет, закончилось ли видео
   *
   * @param {object} productId - ID продукта
   * @return {undefined}
   */
  endedVideo({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].playing = false
    this.gaApp.stores.plp.media.mapVideoState[productId].ended = true
    this.gaApp.stores.plp.media.currentPlayingId = null

    this.gaApp.analytics.modules.plp.onEndPlayingVideo({ productId })

    this.startNextPlayAfterEnd({ productId })
  }

  /**
   * Функция для запуска следующего воспроизведения после окончания текущего воспроизведения.
   *
   * @param {string} productId - Уникальный идентификатор продукта
   * @return {void}
   */
  startNextPlayAfterEnd({ productId }) {
    // видео уже было проиграно в рамках предыдущего воспроизведения
    const isPlayed =
      this.gaApp.stores.plp.media.mapVideoState[productId]?.played

    // страница скролится
    const isScrolledPage = this.gaApp.stores.plp.media.scrolledPage
    if (isPlayed || isScrolledPage) {
      return
    }

    // если есть воспроизводимое видео, то выдоходим
    if (this.gaApp.stores.plp.media.hasPlayingQueueVideo) {
      return
    }

    // последнее из очереди, которая видна на экране
    const isLastVisibleQueueVideo =
      this.gaApp.stores.plp.media.lastVisibleQueueId === productId &&
      this.isActiveVideo(productId)

    // последнее из очереди на воспроизведение
    const isLastQueueVideo =
      this.gaApp.stores.plp.media.lastQueuePlayingId === productId

    // видео воспроизводится циклично, после проигрывания последнего в очереди, начинается воспроизведение первого
    if (isLastQueueVideo || isLastVisibleQueueVideo) {
      this.startTimerAutoplay()
    } else {
      this.findNextAndPlay({ productId })
    }
  }

  /**
   * Обновляет текущее воспроизводимое видео и останавливает все остальные видео.
   *
   * @param {Object} productId - ID воспроизводимого продукта
   * @return {void}
   */
  playingVideo({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].playing = true
    this.gaApp.stores.plp.media.mapVideoState[productId].ended = false

    this.gaApp.analytics.modules.plp.onStartPlayingVideo({ productId })

    if (this.gaApp.features.get('plpHowToShortVideoOnlyOne')) {
      this.stopAllWithoutCurrent()
    }
  }

  errorVideo({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].error = true
  }

  /**
   * Находит следующее воспроизводимое видео и воспроизводит его.
   *
   * @param {object} productId - Идентификатор продукта, с которого начинается поиск.
   * @return {void}
   */
  findNextAndPlay({ productId }) {
    let currentIndex =
      this.gaApp.stores.plp.media.sortedQueueIds.indexOf(productId)

    if (currentIndex === -1) {
      return
    }

    while (true) {
      const nextProductId =
        this.gaApp.stores.plp.media.sortedQueueIds[currentIndex + 1]

      if (!nextProductId) {
        break
      }

      // если следующее видео воспроизводится или с ним взаимодействует пользователь, то ищем другое
      if (
        this.isMediaPlaying(nextProductId) ||
        this.isActiveVideo(nextProductId)
      ) {
        currentIndex += 1
        continue
      }

      this.playVideo({ productId: nextProductId })
      break
    }
  }

  setActiveStateMedia({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].active = true
  }

  // воспроизведение видео при фокусе
  setActiveStateMediaWithPlay({ productId }) {
    this.setActiveStateMedia({ productId })
    this.playVideo({ productId })
  }

  removeActiveStateMedia({ productId }) {
    this.gaApp.stores.plp.media.mapVideoState[productId].active = false
  }

  /**
   * Устанавливает статус воспроизведения для ранее воспроизводимого видео
   */
  setPlayedPrevMedia() {
    this.gaApp.stores.plp.media.queuePlayingIds.forEach((productId) => {
      if (this.gaApp.stores.plp.media.mapVideoState[productId].playing) {
        this.setMediaPlayed(productId, true)
      }
    })
  }

  /**
   * Метод для остановки всех видео, кроме текущего воспроизводимого.
   */
  stopAllWithoutCurrent() {
    Object.entries(this.gaApp.stores.plp.media.mapVideoState).forEach(
      ([productId]) => {
        if (productId !== this.gaApp.stores.plp.media.currentPlayingId) {
          this.stopVideo({ productId })
        }
      },
    )
  }

  startAutoplay() {
    const startProductId = this.gaApp.stores.plp.media.queuePlayingIds?.[0]

    if (!startProductId) {
      return
    }

    this.setPlayedPrevMedia()
    this.playVideo({ productId: startProductId })
  }

  /**
   * Добавляет данные продукта в видимое состояние, если видео продукта еще не видно или не воспроизводится.
   *
   * @param {Object} productData - данные продукта, которые будут добавлены в видимое состояние
   * @return {void}
   */
  pushToVisibleState(productData) {
    const { productId } = productData
    // уже есть в видимых продуктах
    const alreadyVisibleVideo = this.isIdIntoVisibleQueue(productId)
    const isPlaying = this.isMediaPlaying(productId)

    if (alreadyVisibleVideo || isPlaying) {
      return
    }

    this.gaApp.stores.plp.media.visibleProducts.push(productData)

    // запуск видео при первом заходе на страницу
    if (!this.gaApp.stores.plp.media.isNotFirstShowedProduct) {
      this.gaApp.stores.plp.media.isNotFirstShowedProduct = true
      this.startTimerAutoplay()
    }
  }

  /**
   * Удаляет указанный продукт из видимого состояния, если он существует.
   *
   * @param {Object} options - Объект с параметрами.
   * @param {string} options.productId - Идентификатор удаляемого продукта.
   * @param {boolean} options.isIntersecting - Указывает, находится ли продукт в пересечении.
   * @return {void}
   */
  removeFromVisibleState({ productId }) {
    if (!this.isIdIntoVisibleQueue(productId)) {
      return
    }

    this.gaApp.stores.plp.media.visibleProducts =
      this.gaApp.stores.plp.media.visibleProducts.filter(
        (productData) => productData.productId !== productId,
      )
  }

  // проверка на наличие продукта в очереди проигрывания
  isIdIntoVisibleQueue(productId) {
    return this.gaApp.stores.plp.media.visibleProducts.some(
      (productData) => productData.productId === productId,
    )
  }

  // проверка на воспроизведение видео
  isMediaPlaying(productId) {
    return this.gaApp.stores.plp.media.mapVideoState[productId]?.playing
  }

  // проверка на то взаимодействует ли пользователь с текущим видео
  isActiveVideo(productId) {
    return this.gaApp.stores.plp.media.mapVideoState[productId]?.active
  }

  setMediaPlayed(productId, status = false) {
    this.gaApp.stores.plp.media.mapVideoState[productId].played = status
  }

  // проверка нахождения продукта в пересечении с экраном
  needAddVisibleState({ currentView, intersectionRatio }) {
    // если карточка большая, то необходимая видимость должна быть больше 70%
    if (currentView === CARD_SIZE_NUMBER.L) {
      return intersectionRatio >= 0.75
    }

    if (currentView === CARD_SIZE_NUMBER.M) {
      return intersectionRatio >= 0.85
    }

    return intersectionRatio >= 0.95
  }

  /**
   * Запускает таймер для функции автовоспроизведения видимых карточек с видео.
   */
  startTimerAutoplay() {
    this.clearAutoplayTimer()

    this.gaApp.stores.plp.media.timerId = setTimeout(() => {
      this.startAutoplay()

      this.clearAutoplayTimer()
    }, AUTOPLAY_DELAY)
  }

  clearAutoplayTimer() {
    clearTimeout(this.gaApp.stores.plp.media.timerId)
    this.gaApp.stores.plp.media.timerId = null
  }

  /**
   * Инициализирует слушатель прокрутки страницы.
   */
  initPageScrollListener() {
    const debouncedFn = debounce(() => {
      this.gaApp.stores.plp.media.scrolledPage = false
      this.startAutoplay()
    }, AUTOPLAY_DELAY)

    useEventListener(window, 'scroll', () => {
      this.gaApp.stores.plp.media.scrolledPage = true

      debouncedFn()
    })
  }

  /**
   * Функция для проверки видимости карточки продукта и выполнения соответствующих действий в зависимости от статуса видимости.
   *
   * @param {Object} productData - Данные карточки продукта
   * @param {boolean} isIntersecting - Флаг, указывающий, пересекается ли карточка продукта с видимой областью
   * @param {number} intersectionRatio - Соотношение пересечения карточки продукта с видимой областью
   * @return {void}
   */
  checkVisible({ productData, intersectionRatio, isIntersecting }) {
    const { productId, currentView } = productData

    const needAddToStore = this.needAddVisibleState({
      currentView,
      intersectionRatio,
    })

    if (!isIntersecting) {
      this.stopVideo({ productId })
    }

    if (needAddToStore) {
      this.pushToVisibleState(productData)
    } else {
      this.removeFromVisibleState({ productId })
    }
  }

  setVideoState({ product }) {
    const { itemId, inStock } = product

    // видео показываем только для товаров в наличие
    if (inStock) {
      // хак для реактивности на vue2
      set(this.gaApp.stores.plp.media.mapVideoState, itemId, {
        playing: false,
        played: false,
        ended: false,
        active: false,
        error: false,
      })
    }
  }
}

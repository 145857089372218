import { TYPE } from '../../constants'
import { GaAnalyticsBrandClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class BrandClickBuilder extends BaseBuilder {
  constructor(product) {
    const models = new Map([[TYPE.GAA, GaAnalyticsBrandClickModel]])
    super(models, product)
  }
}

import { TYPE } from '../../constants'
import { GtmStartVideoModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class StartVideoBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmStartVideoModel]])
    super(models, data)
  }
}

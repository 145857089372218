import { LISTING_MAX_PAGE_INFINITE_LOADER } from '../constants'

export class ListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fillStore(block) {
    this.gaApp.stores.home.listing.$reset()

    this.gaApp.stores.home.listing.name = block.data?.name ?? ''
    this.gaApp.stores.home.listing.layout = block.data?.layout ?? ''

    this.gaApp.stores.home.listing.pages.push({
      items: block.data?.products ?? [],
      number: this.gaApp.stores.home.listing.page,
    })
  }

  async getNextPage(slotData) {
    const { id, type, version, url } = slotData

    this.gaApp.stores.home.listing.pagination.pending = true
    this.gaApp.stores.home.listing.pagination.show = true

    if (
      this.gaApp.stores.home.listing.pagination.page ===
      LISTING_MAX_PAGE_INFINITE_LOADER
    ) {
      this.gaApp.stores.home.listing.pagination.page = 0
    }

    const page = await this.gaApp.services.home.api.fetchBlock({
      id,
      type,
      version,
      url,
      pageNumber: this.gaApp.stores.home.listing.page + 1,
    })

    this.gaApp.stores.home.listing.pagination.pending = false

    if (page.data === null) {
      return
    }

    if (page.data.products.length > 0) {
      this.gaApp.stores.home.listing.pages.push({
        items: page.data.products,
        number: this.gaApp.stores.home.listing.page + 1,
      })
    } else {
      this.gaApp.stores.home.listing.pagination.end = true
    }

    this.gaApp.stores.home.listing.page++
    this.gaApp.stores.home.listing.pagination.page++
    if (
      this.gaApp.stores.home.listing.pagination.page !==
      LISTING_MAX_PAGE_INFINITE_LOADER
    ) {
      this.gaApp.stores.home.listing.pagination.show = false
    }
  }
}

import {
  disablePageScroll,
  enablePageScroll,
  getPageScrollBarWidth,
  getScrollState,
} from 'scroll-lock'

export class PageScrollService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  update(disableScroll) {
    if (disableScroll !== this.gaApp.stores.app.pageScroll.scrollEnabled) {
      // если нет изменений
      return
    }

    if (disableScroll) {
      this.disableScroll()
    } else {
      this.enableScroll()
    }
  }

  /*
   * вместо disableScroll нужно использовать modal.register.add(), который дергает app.pageScroll.update()
   * */
  disableScroll() {
    this.bodyWidth.set()

    disablePageScroll()

    this.updateScrollState()
  }

  /*
   * вместо enableScroll нужно использовать modal.register.remove(), который дергает app.pageScroll.update()
   * */
  enableScroll() {
    this.bodyWidth.reset()

    enablePageScroll()

    this.updateScrollState()
  }

  updateScrollState() {
    const isScrollEnabled = getScrollState()

    this.gaApp.stores.app.pageScroll.scrollEnabled = isScrollEnabled
    this.gaApp.stores.app.pageScroll.scrollbarGapWidth = isScrollEnabled
      ? 0
      : getPageScrollBarWidth()
  }

  /**
   * Что бы во время блокирования скролла избежать перерасчета
   * ширин элементов, у которых css-свойства выражены в процентах
   * (например padding-bottom: 0 0 100%) нужно установить ширину для body в еденицах VW
   */
  get bodyWidth() {
    return {
      set: () => (document.body.style.width = '100vw'),
      reset: () => (document.body.style.width = null),
    }
  }
}

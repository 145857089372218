import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './stores'

export const streamerce = {
  meta: {
    name: 'streamerce',
    locales,
    routes,
  },
  layers: {
    stores,
    services,
    repositories,
  },
}

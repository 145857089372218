import { useIntersectionObserver } from '@vueuse/core'

import { onMounted, ref } from 'vue'

import { isIOS } from '@ga/shared-browser'

/**
 * Включаем обсервер только для IOS.
 * Используется для того, что бы сделать галерею прокручиваемой, когда галарея в области просмотра (только для IOS)
 *
 * Возвращает объект с реактивным свойством intersected, которое указывает, пересекается ли корневой элемент с областью просмотра.
 *
 * @param {Ref<HTMLElement>} rootRef — ссылка на корневой элемент.
 * @return {Object} Объект со свойством intersected.
 */
export const useRootIntersection = (rootRef) => {
  const intersected = ref(false)

  onMounted(() => {
    if (!isIOS()) {
      return false
    }

    useIntersectionObserver(
      rootRef,
      ([{ isIntersecting }]) => (intersected.value = isIntersecting),
    )
  })

  return {
    intersected,
  }
}

import { SETTINGS } from '../constants'

export class SettingsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запись данных о подписках в стор
   * @param {object} data объект данных
   */
  setSubscriptionsSettings(data) {
    // Записываем в стор поля подписок
    this.gaApp.stores.customer.settings.options =
      this.getSubscriptionsSettings(data)

    // Записываем текущее значение согласия пользака на подписки
    this.gaApp.stores.customer.settings.subscriptionsSigned =
      data.subscriptionsSigned
  }

  /**
   * Отправка данных о подписках
   */
  updateSubscriptionsSettings() {
    const optionsList = this.gaApp.stores.customer.settings.optionsList

    // Если пользак изменил хотя бы один тоггл в true, то отправляем флаг
    // subsriptionsSigned = true, иначе отправляем то значение, которое пришло с бека
    const subscriptionsSigned = optionsList.some(
      (setting) =>
        setting.checked ||
        this.gaApp.stores.customer.settings.subscriptionsSigned,
    )

    // формируем объект для отправки на бек
    const data = {
      ...this.gaApp.stores.customer.settings.options,
      subscriptionsSigned,
    }

    this.gaApp.services.customer.api.updateSubscriptionsSettings(data)
  }

  /**
   * Обновление состояния тоггла
   * @param {string} name название измененного тоггла
   * @param {boolean} value предыдущее значение измененного тоггла
   */
  updateSubscription(name, value) {
    this.gaApp.stores.customer.settings.options[name] = !value
  }

  /**
   * Маппинг полей подписок
   * @param {object} data объект данных
   * @returns {object} объект полей подписок и их значений
   */
  getSubscriptionsSettings(data) {
    const settings = {}
    const settingsNames = Object.values(SETTINGS)

    Object.entries(data).forEach(([key, value]) => {
      if (settingsNames.includes(key)) {
        settings[key] = value
      }
    })

    return settings
  }
}

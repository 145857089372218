import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'
export const useCityIdCategoryId = () => {
  const { $gaApp } = useContext()

  const cityId = computed(
    () => $gaApp.services.location.main.getDeliveryAddress().id,
  )
  const categoryId = computed(() => $gaApp.stores.plp.main.getCategoryData?.id)

  return {
    cityId,
    categoryId,
  }
}

import { TYPE } from '../analytics/constants'
import { PAGE_SIZE, PAGE_TYPE } from '../constant'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getSearchQuery(filters = []) {
    return filters.find((filter) => filter.type === 'queryType')?.value
  }

  getData(redirect) {
    switch (redirect.pageType) {
      case PAGE_TYPE.LISTING:
        this.gaApp.stores.plp.main.setSearchQueryCorrected('')

        this.gaApp.stores.plp.main.setPageData({
          params: { categoryId: +redirect.entityId, meta: redirect.meta },
          type: redirect.pageType,
        })

        return this.getListingInitialData(redirect)

      case PAGE_TYPE.SEARCH:
        this.gaApp.stores.plp.main.setPageData({
          params: {
            query: this.getSearchQuery(redirect.filters),
            meta: redirect.meta,
          },
          type: redirect.pageType,
        })

        return this.getListingInitialData(redirect)

      default:
        return undefined
    }
  }

  async getListingInitialData(redirect) {
    this.gaApp.services.filters.main.resetState()
    this.gaApp.services.filters.main.pageNumber.setFromQuery()

    // TODO: Удалить когда избавимся от ФТ showSortByRating
    redirect.filters =
      this.gaApp.services.plp.main.exlcudeRedirectFiltersByFeatureToggle(
        redirect.filters,
      )

    // Устанавливаем выбранные фильтры из редиректа
    this.gaApp.stores.filters.main.setFiltersSelected(redirect.filters)
    this.gaApp.stores.filters.main.setCountSelectedFilters(
      redirect.filters?.length,
    )

    const [listing] = await Promise.allSettled([
      this.getListing(redirect), // Запрос листинга
      this.getInitialAnalytics(), // Запрос аналитики PLP
    ])

    return listing
  }

  // Запрос всего листинга: фильтры, данные категории, продукты
  async getListing(redirect) {
    try {
      const listing = await this.gaApp.services.plp.api.getListing(redirect)
      switch (redirect.pageType) {
        case PAGE_TYPE.LISTING:
          this.gaApp.stores.plp.main.setSearchQueryError(false)
          break

        case PAGE_TYPE.SEARCH:
          this.setSearchQueryError(listing.products.count)
      }

      return this.validateListing(listing, redirect)
    } catch (error) {
      this.redirectError(error)
    }
  }

  // Запрос данных из внутреннего сервиса аналитики
  async getInitialAnalytics() {
    const { path, hash } = this.gaApp.route

    const data = await this.gaApp.analytics.modules.plp.onPageInit({
      url: path.replace(hash, ''),
    })

    const gaAnalytics = data[TYPE.GAA] ? data[TYPE.GAA] : null

    return this.gaApp.stores.plp.main.setAnalyticsData(gaAnalytics)
  }

  validateListing(listing, redirect) {
    const pageLast = Math.ceil(listing.products?.count / PAGE_SIZE)
    const pageCurrent = this.gaApp.stores.filters.main.getPageNumberCurrent

    /**
     * Если pageLast = 0, то продукты с текущими фильтрами отсутствуют.
     *                    Значит, показывам листинг, в котором будет предложено сбросить фильтры
     *
     * Если pageCurrent <= pageLast, то запрашиваемая страница с продуктами пристутствует.
     *                               Значит показываем листинг с продуктами.
     */
    if (pageLast === 0 || (pageCurrent && pageCurrent <= pageLast)) {
      return listing
    }

    /**
     * Иначе, выходит, что в принципе, продукты есть, но на запрашиваемой странице пагинации, продуктов нет.
     *
     * Значит, на клиенте просто запрашиваем листинг с первой страницы,
     *       а на сервере показываем сообщение, что страница не найдена.
     */
    if (!this.gaApp.services.app.main.isSSR) {
      // Скидываем локальный фильтр страницы в 1
      this.gaApp.services.filters.main.pageNumber.updateFilter(1)

      // Запрашиваем тот же самый листинг, но с параметром страницы = 1
      return this.gaApp.services.plp.api.getListing(redirect)
    }

    // Это SSR. Показываем страницу "Не найдено"
    return this.redirectError()
  }

  redirectError(error = null) {
    if (error.statusCode >= 500) {
      return this.gaApp.redirectError({
        statusCode: 500,
        message: error.message,
      })
    }
    return this.gaApp.redirectError({
      statusCode: 404,
      message: 'Cтраница не найдена',
    })
  }

  setSearchQueryError(productsCount) {
    const searchQueryError = productsCount <= 0

    // Не сбрасывается это значение в сторе
    this.gaApp.stores.plp.main.setSearchQueryError(searchQueryError)
  }
}

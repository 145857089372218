import { generateId } from '../generate-id'
import { setTimer } from '../set-timer'

export const getItem = ({ defaultProps, event, close, destroyAll }) => {
  const { id: itemId, text, slotText, link, data, params = {} } = event

  const duration = params.duration || defaultProps.duration
  const speed = params.speed || defaultProps.speed
  const closeBySwipe = params.closeBySwipe || defaultProps.closeBySwipe
  const closeByClick = params.closeByClick || defaultProps.closeByClick
  const stacked = params.stacked || defaultProps.stacked
  const length = duration + 2 * speed
  const id = itemId || generateId()

  return {
    id,
    text,
    slotText,
    link,
    speed,
    length,
    closeBySwipe,
    closeByClick,
    data,
    timer: setTimer(() => {
      if (stacked) {
        destroyAll()
      } else {
        close(id)
      }
    }, length),
  }
}

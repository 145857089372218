export class AdvcakeOrderInfoModel {
  constructor({ id, orderTotal, coupon = '' }) {
    this.id = id
    // сумму заказа передавать без учета доставки
    this.totalPrice = this.getTotalPrice(orderTotal)
    this.coupon = coupon
  }

  getTotalPrice(orderTotal) {
    const delivery = orderTotal.list.find(({ name }) => name === 'delivery')

    if (delivery) {
      return orderTotal.total.amount - delivery.value.amount
    }

    return orderTotal.total.amount
  }
}

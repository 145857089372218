import { AdvcakeBaseModel } from '../advcake-base.model/advcake-base.model'
import { AdvcakeProductModel } from '../advcake-product.model/advcake-product.model'
import { EVENT } from '../../../constants'

export class AdvcakeCartModel extends AdvcakeBaseModel {
  constructor({ items, addToCart, isAdded, decrement }) {
    let _pageType

    if (addToCart) {
      _pageType = isAdded ? EVENT.CHANGE_QYANTITY_IN_CART.ADVCAKE : EVENT.ADD_TO_CART.ADVCAKE
    } else {
      _pageType = decrement ? EVENT.CHANGE_QYANTITY_IN_CART.ADVCAKE : EVENT.REMOVE_FROM_CART.ADVCAKE
    }

    super(_pageType)

    this.products = items.map(item => {
      const { id, name, price, _quantity } = item
      let quantity

      switch (decrement) {
        case true:
          quantity = _quantity - 1

          break;

        case false:
          quantity = _quantity + 1

          break;

        default:
          quantity = 0

          break;
      }

      return {
        ...new AdvcakeProductModel({ id, name, price }),
        quantity
      }
    })
  }
}

export class SuccessModalService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.gaApp.services.giftCardsB2b.successModal.close()
    })
  }

  /**
   * Открывает модальное окно успеха
   */
  open() {
    this.gaApp.stores.giftCardsB2b.main.successModalIsOpen = true
  }

  /**
   * Закрывает модальное окно успеха
   */
  close() {
    this.gaApp.stores.giftCardsB2b.main.successModalIsOpen = false
  }
}

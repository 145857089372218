import { TYPE } from '../../constants'
import { GtmGa4UserAuthModel, YandexMetrikaUserAuthModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class OnUserAuthBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([])

    if (data && data.userId && data.isAuthorized) {
      models.set(TYPE.YANDEX_METRIKA, YandexMetrikaUserAuthModel)
      models.set(TYPE.GTM_4, GtmGa4UserAuthModel)
    }

    super(models, data)
  }
}

// Checks if device is iOS the old-fashioned way
export const isStrictIOS = () => {
  return (
    window.navigator.userAgent.match(/iPhone|iPad|iPod/) && !window.MSStream
  )
}

// Checks if device is iOS or if it's desktop Safari with touch
// We have to do this because Safari 13+ on iPad is in desktop mode by default
// for some reason
export const isIOS = () => {
  return isStrictIOS() || isIPad13()
}

// On iPad iOS 13, in Safari the user agent is identical with that of a MacOS 13 computer
export const isIPad13 = () => {
  return (
    isSafari() &&
    navigator.userAgent.includes('Mac') &&
    !window.matchMedia('(hover: hover) and (pointer: fine)').matches
  )
}

export const isAndroid = () => {
  return /android/i.test(window.navigator.userAgent)
}

export const isSafari = () => {
  return (
    Boolean(navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) &&
    !isAndroid()
  )
}

export const isHuawei = () => {
  return (
    window.navigator.userAgent.match(/Huawei|HUAWEI|huawei/) && !window.MSStream
  )
}

export const isXiaomi = () => {
  return (
    window.navigator.userAgent.match(
      /XIAOMI|Xiaomi|xiaomi| Mi |xiaoMi|Miui|Redmi/,
    ) && !window.MSStream
  )
}

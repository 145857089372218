/**
 * Возвращает очищенный телефон
 * @param {String} value // Строка с номером телефона
 * @param {('asNumber'|'withPlus')}  rules // Правила очистки
 */
export const getPhoneWithoutMask = (value, rules) => {
  const sanitizeValue = value.replace(/\D/g, '')

  switch (rules) {
    case 'asNumber':
      return Number(sanitizeValue)
    case 'withPlus':
      return value.replace(/[^+\d]/g, '')
    default:
      return sanitizeValue
  }
}

import isFunction from 'lodash/isFunction'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  requestFilters(...args) {
    const fn = this.gaApp.stores.filters.main.getRequestFiltersFn

    return isFunction(fn) ? fn(...args) : false
  }
}

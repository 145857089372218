import merge from 'lodash/merge'

import { unref } from 'vue'

import { useCartAdd } from '~/modules/cart'

export const useProductCardCart = (product) => {
  const {
    addToCart: _addToCart,
    isLoading,
    isAdded,
    isWholeStock,
  } = useCartAdd(unref(product))

  const addToCart = (...args) => _addToCart(merge(...args))

  return {
    addToCart,

    isLoading,
    isAdded,
    isWholeStock,
  }
}

import { COVER_CAPTION_COLOR, PRODUCT_LISTING_PAGE_SIZE } from '../../constants'
import { STREAM_STATUS } from '../../constants/stream'

export const state = () => {
  return {
    id: '',
    seo: [],

    status: STREAM_STATUS.ARCHIVE,

    translationDate: '',
    externalId: '',
    url: '',
    name: '',
    imageUrl: '',
    coverCaptionColor: COVER_CAPTION_COLOR.WHITE,
    // цвет подложки текста лейбла стрима
    labelColor: COVER_CAPTION_COLOR.BLACK,
    tags: [],
    options: {
      isActive: false,
      isEnded: false,
      startDate: null,
      totalDuration: null,
    },
    sections: [],

    listing: {
      skus: [],
      link: null,
      currentPageNumber: 0,
      totalCount: PRODUCT_LISTING_PAGE_SIZE,

      pagination: {
        show: false,
        pending: false,
      },
      pages: [],
    },

    recommendations: [],

    isPreview: false,
    // будем хранить состояние была ли страница загружена ранее
    isLoadedStream: false,
  }
}

<template>
  <svg viewBox="0 0 12 12">
    <path
      fill="none"
      d="M7.507 10.557 11.146.854 1.443 4.493 5.671 6.03l.22.08.079.22 1.537 4.227Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-location',
}
</script>

import { GtmBaseModel } from '../../../models/gtm/gtm-base.model/gtm-base.model'
export class GtmGa4AdvantageModel extends GtmBaseModel {
  constructor({ id, click }) {
    super({
      event: 'user_interaction',
    })

    this.event_category = 'pdp_info'
    this.action_type = 'click_benefits'
    this.click = click
    this.item_id = id
  }
}

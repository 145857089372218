import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsMenuClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { url, itemListName } = data

    this.category = {
      url,
      itemListName,
    }

    this.debug = {
      key: 'brandzone-menu-item-click',
      data,
    }
  }
}

import { getStateInitial } from './state'

export const actions = (gaApp) => ({
  /* $reset сбрасывает весь стейт, этот метод нужен для сброса одного элемента стейта */
  resetProp(prop) {
    const initialState = getStateInitial()

    if (Object.hasOwnProperty.call(initialState, prop)) {
      this[prop] = initialState[prop]
    }
  },
})

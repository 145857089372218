import * as components from './components'
import { services } from './services'
import { stores } from './stores'

export const footer = {
  meta: {
    name: 'footer',
  },
  components,
  layers: {
    services,
    stores,
  },
}

export const getters = {
  maxStep() {
    return this.items.length - 1
  },

  canGoBack() {
    return this.stepIndex > this.minStep
  },

  canGoForward() {
    return this.stepIndex < this.maxStep
  },

  currentStep() {
    return this.items[this.stepIndex]
  },
}

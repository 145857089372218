/**
 * Преобразуем массив в строку
 *
 * @param {array} array исходный массив
 * @param {string} separator разделитель
 * @returns {string} выходная строка
 */
import isArray from 'lodash/isArray'

export const formatArrayAsString = (array, separator = '') => {
  if (isArray(array)) {
    return (
      array
        // фильтруем невалидные значения
        .filter(Boolean)
        // убираем пробелы
        .map((item) => {
          return item.trim()
        })
        // собираем в строку
        .join(separator)
    )
  } else {
    return String(array)
  }
}

/**
 * TODO:
 * – добавить логику показа строки доставки (isDeliveryVisible),
 *   когда выбран способ доставки курьером
 */
export const getters = () => ({
  total() {
    return this.sets.reduce(
      (total, set) => total + (set.cardsValue || 0) * (set.cardsAmount || 0),
      0,
    )
  },

  packagesCount() {
    return this.sets.reduce((acc, set) => {
      if (!set.cardsAmount) return acc

      return acc + set.cardsAmount
    }, 0)
  },

  setsCount() {
    return this.sets.length
  },

  isDeliveryVisible() {
    return false
  },

  selectedCityText() {
    return this.config.cities.find((city) => city.value === this.config.city)
      ?.text
  },
})

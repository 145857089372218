export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getProducts(data = {}) {
    const { url } = this.endpoints.getProducts()

    try {
      const response = await this.gaApp.api.request().get(url, { params: data })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

export const getters = (gaApp) => ({
  errorMessage() {
    return this.errors[0]
  },

  hasError() {
    return this.errors.length > 0
  },
  preview() {
    return this.fileIds.map((id) => this.files[id])
  },

  needLoadFiles() {
    return this.preview
      .filter(
        (file) => !file.isAdded && !file.isCompressing && !file.isUploading,
      )
      .map(({ id }) => id)
  },

  hasUploadingFiles() {
    return this.preview.some((file) => !file.isAdded)
  },
})

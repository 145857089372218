import { defineStore, getActivePinia } from 'pinia'

import { actions } from './actions'
import { state } from './state'

export const modalStore = (gaApp) => {
  const init = defineStore('locationModal', {
    actions: actions(gaApp),
    state,
  })

  return init(getActivePinia())
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, ref, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { GaImageResponsive } from '~/components/image-responsive'

import { GaHeaderNavMinimizedArrow } from '../nav-minimized-arrow'

import { userInfo } from './scripts/use'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-auth',

  components: {
    GaAction,
    GaImageResponsive,
    GaHeaderNavMinimizedArrow,
  },

  setup(_, { emit }) {
    const { $gaApp } = useContext()

    const mounted = ref(false)

    onMounted(() => {
      mounted.value = true
    })

    const authorized = computed(() => {
      return $gaApp.stores.user.main.isAuthorized
    })

    const href = computed(() => {
      const href = $gaApp.services.app.router.getPathByRoute({
        moduleType: 'customer',
        pageType: 'orders',
      })

      if (unref(mounted)) {
        return unref(authorized) && href
      }

      return null
    })

    const nuxt = computed(() => {
      return unref(authorized)
    })

    const onClick = () => {
      if (!unref(authorized)) emit('click')
    }

    const { name, phone, avatar } = userInfo()

    return {
      authorized,
      href,
      nuxt,
      onClick,
      name,
      phone,
      avatar,
    }
  },
}

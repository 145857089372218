import { computed, unref } from 'vue'

import { mapProduct } from './map-product'

export const useProductData = (item) => {
  const product = unref(item)
  const productData = computed(() => (product ? mapProduct(product) : null))

  return {
    productData,
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4FindCounterpartyClickModel extends GtmBaseModel {
  constructor({ success, error = undefined }) {
    super({})

    this.action_type = 'click_find_org'
    this.error = error
    this.success = success
    this.type = 'kepk'
  }
}

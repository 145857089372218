import { AVAILABLE_GAMES } from '../consts'
export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    try {
      switch (redirect.pageType) {
        case 'main':
          // проверяем наличие игры
          if (
            AVAILABLE_GAMES[this.gaApp.i18n.locale.country]?.[redirect.name]
          ) {
            return this.gaApp.services.game.main.init(redirect.name)
          } else {
            return this.gaApp.redirectError({ statusCode: 404 })
          }
        default:
          return undefined
      }
    } catch (error) {}
  }
}

import useVuelidate from '@vuelidate/core'

export const buildVuelidate = (rules, state) => {
  if (rules && state) {
    return useVuelidate(rules, state)
  }

  if (rules && !state) {
    return useVuelidate(rules)
  }

  return useVuelidate()
}

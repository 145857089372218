<template>
  <svg fill="currentColor" stroke="none" viewBox="0 0 80 80">
    <path
      d="m2.566 25.952 7.014.125-.516 1.495-1.552-.057-.751 2.28 1.253.92-.462 1.318-5.629-4.204.643-1.877Zm3.826 1.529-3.01-.111 2.435 1.784.575-1.673Z"
    />
    <path
      d="M10.06 24.229a2.165 2.165 0 0 1-1.74-.211l.747-1.234c.25.133.536.178.815.13a1.332 1.332 0 0 0 .779-.665.953.953 0 0 0-.195-1.401.667.667 0 0 0-.631-.077c-.296.188-.554.43-.76.713a3.5 3.5 0 0 1-1.482 1.28 1.607 1.607 0 0 1-1.47-.275 1.834 1.834 0 0 1-.907-1.308 2.76 2.76 0 0 1 .426-1.785c.776-1.306 1.798-1.702 3.004-1.117l-.718 1.201a.98.98 0 0 0-1.353.49c-.348.582-.25 1.019.134 1.253a.586.586 0 0 0 .585.068 2.14 2.14 0 0 0 .78-.698c.366-.538.859-.977 1.434-1.282a1.7 1.7 0 0 1 1.566.225 1.941 1.941 0 0 1 .937 1.369 2.82 2.82 0 0 1-.43 1.918 3.046 3.046 0 0 1-1.52 1.405Z"
    />
    <path
      d="m10.111 13.45 2.096 1.966-.531-3.675 1.097-1.174.516 4.088 4.463.378-1.209 1.29-3.762-.359 2.18 2.042-1.035 1.102-4.842-4.555 1.027-1.102Z"
    />
    <path
      d="m21.214 4.718 5.162 4.747-1.363.777-1.128-1.07-2.105 1.19.33 1.52-1.24.695-1.373-6.888 1.717-.971Zm1.866 3.684-2.185-2.081.645 2.945 1.54-.864Z"
    />
    <path
      d="M33.67 7.42a3.26 3.26 0 0 1-4.004-2.682l.003-.074a3.292 3.292 0 0 1 .495-2.583A3.441 3.441 0 0 1 32.391.675a3.5 3.5 0 0 1 2.585.456 3.38 3.38 0 0 1 1.407 2.187l-.003.073a3.38 3.38 0 0 1-.583 2.787l.806.583-.704.995-1.073-.767a4.413 4.413 0 0 1-1.156.431Zm.118-1.23-.753-.554.703-.975.975.703a2.666 2.666 0 0 0 .12-1.65l.003-.073a2.507 2.507 0 0 0-.78-1.457 1.726 1.726 0 0 0-1.462-.334c-1.142.225-1.664 1.18-1.426 2.503l-.003.073a2.42 2.42 0 0 0 .832 1.512 1.74 1.74 0 0 0 1.435.333l.356-.08Z"
    />
    <path
      d="m38.925 4.018.197-4.068 1.52.056L40.446 4c-.041 1.12.24 1.61 1.166 1.658.926.047 1.308-.366 1.393-1.583l.165-3.93 1.519.056-.194 3.995c-.068 1.832-1.134 2.714-2.952 2.626-1.818-.087-2.704-1.026-2.619-2.805Z"
    />
    <path
      d="m52.35 1.942-.302 1.15L49.3 2.37l-.396 1.493 2.2.581-.28 1.064-2.2-.581-.425 1.612 2.9.774-.302 1.136-4.347-1.161L48.148.84l4.203 1.102Z"
    />
    <path
      d="M52.987 8.764a2.194 2.194 0 0 1 .064-1.753l1.311.636a1.2 1.2 0 0 0-.03.82 1.333 1.333 0 0 0 .706.74.96.96 0 0 0 1.347-.325.665.665 0 0 0 .023-.633 2.981 2.981 0 0 0-.775-.695 3.52 3.52 0 0 1-1.383-1.386 1.627 1.627 0 0 1 .102-1.51 1.846 1.846 0 0 1 1.218-.996 2.767 2.767 0 0 1 1.857.295c1.357.657 1.854 1.636 1.38 2.893l-1.25-.62a.972.972 0 0 0-.62-1.283c-.603-.296-1.028-.158-1.223.241a.574.574 0 0 0-.022.593c.197.29.46.529.768.696a3.947 3.947 0 0 1 1.393 1.312 1.673 1.673 0 0 1-.059 1.579 1.942 1.942 0 0 1-1.273 1.053 2.82 2.82 0 0 1-1.952-.252 3.1 3.1 0 0 1-1.582-1.405Z"
    />
    <path
      d="m61.221 7.434.702-.942 4.101 3.12-.701.935-1.442-1.08-3.304 4.394-1.214-.912 3.312-4.42-1.454-1.095Z"
    />
    <path d="m69.127 12.67-4.774 4.647-1.06-1.093 4.78-4.647 1.054 1.093Z" />
    <path
      d="M66.96 18.942a3.367 3.367 0 0 1 1.5-2.12l.061-.044a3.273 3.273 0 0 1 2.576-.572 3.408 3.408 0 0 1 2.153 1.507c.534.743.752 1.667.606 2.57a3.373 3.373 0 0 1-1.487 2.154l-.062.038a3.46 3.46 0 0 1-2.576.572 3.42 3.42 0 0 1-2.2-1.495 3.381 3.381 0 0 1-.57-2.61Zm2.84 2.773c.577.03 1.146-.14 1.612-.48l.062-.038a2.513 2.513 0 0 0 1.041-1.296 1.866 1.866 0 0 0-1.503-2.297 2.42 2.42 0 0 0-1.605.481l-.062.044c-.5.299-.872.772-1.043 1.33a1.807 1.807 0 0 0 1.5 2.23l-.002.026Z"
    />
    <path
      d="m77.178 25.368-3.259 3.883 4.138-1.502.454 1.264-6.275 2.284-.514-1.427 3.492-4.24-4.47 1.623-.46-1.265 6.274-2.277.62 1.657Z"
    />
    <path
      d="M76.471 38.682c.331.013.645.144.879.367.234.223.371.522.384.837.014.315-.098.624-.313.864s-.517.394-.845.432c-.34.009-.67-.11-.92-.329a1.26 1.26 0 0 1-.418-.876 1.162 1.162 0 0 1 .068-.48c.057-.154.145-.296.26-.417.115-.12.255-.218.41-.286.156-.069.324-.107.495-.112Z"
    />
    <path
      d="m77.336 54.174-6.998-.158.521-1.488 1.533.056.797-2.279-1.246-.926.462-1.317 5.58 4.275-.649 1.837Zm-3.819-1.541 3.012.11-2.43-1.79-.582 1.68Z"
    />
    <path
      d="M69.856 55.865a2.128 2.128 0 0 1 1.733.218l-.753 1.233a1.227 1.227 0 0 0-.809-.137 1.334 1.334 0 0 0-.799.685.953.953 0 0 0 .182 1.4.666.666 0 0 0 .63.077c.307-.18.576-.417.794-.698.36-.56.874-1.005 1.481-1.28a1.633 1.633 0 0 1 1.499.25 1.834 1.834 0 0 1 .879 1.306 2.773 2.773 0 0 1-.44 1.798c-.782 1.306-1.803 1.688-3.01 1.097l.712-1.188a.967.967 0 0 0 1.352-.49c.355-.575.258-1.012-.12-1.246a.58.58 0 0 0-.592-.068c-.312.163-.58.4-.78.691a4 4 0 0 1-1.44 1.282 1.694 1.694 0 0 1-1.56-.232 1.953 1.953 0 0 1-.93-1.368 2.786 2.786 0 0 1 .438-1.919 3.02 3.02 0 0 1 1.533-1.41Z"
    />
    <path
      d="m69.745 66.656-2.096-1.972.532 3.676-1.097 1.167-.516-4.088-4.462-.398 1.208-1.29 3.762.379-2.178-2.082 1.027-1.07 4.855 4.576-1.035 1.102Z"
    />
    <path
      d="m58.598 75.338-5.162-4.76L54.8 69.8l1.128 1.075 2.105-1.183-.324-1.513 1.24-.695 1.366 6.895-1.716.958Zm-1.866-3.685 2.178 2.082-.638-2.952-1.54.87Z"
    />
    <path
      d="M46.136 72.588a3.274 3.274 0 0 1 4.004 2.696l-.003.073a3.334 3.334 0 0 1-.509 2.59 3.466 3.466 0 0 1-2.24 1.391 3.507 3.507 0 0 1-2.584-.462 3.373 3.373 0 0 1-1.393-2.22l.003-.066a3.4 3.4 0 0 1 .596-2.787l-.805-.59.703-.988 1.045.786c.372-.2.77-.342 1.183-.423Zm-.118 1.223.753.561-.703.975-.968-.703a2.7 2.7 0 0 0-.155 1.662l-.002.073c.082.56.352 1.077.766 1.463a1.746 1.746 0 0 0 1.462.34c1.149-.217 1.671-1.172 1.427-2.502l.002-.073a2.4 2.4 0 0 0-.825-1.511 1.74 1.74 0 0 0-1.435-.34l-.322.055Z"
    />
    <path
      d="m40.895 75.966-.204 4.096-1.519-.056.214-3.995c.041-1.12-.227-1.61-1.153-1.657-.925-.048-1.307.359-1.392 1.576l-.211 3.915-1.52-.056.215-3.995c.067-1.832 1.14-2.706 2.958-2.612 1.818.093 2.711 1.007 2.612 2.784Z"
    />
    <path
      d="m27.426 77.98.302-1.137 2.746.769.403-1.507-2.2-.588.286-1.063 2.2.595.433-1.606-2.894-.773.302-1.137 4.347 1.161-1.725 6.448-4.2-1.163Z"
    />
    <path
      d="M26.83 71.159a2.145 2.145 0 0 1-.065 1.745l-1.31-.635a1.22 1.22 0 0 0 .03-.826 1.332 1.332 0 0 0-.753-.735.954.954 0 0 0-1.38.316.666.666 0 0 0-.024.666c.21.278.471.513.768.696.58.322 1.058.802 1.377 1.385a1.606 1.606 0 0 1-.11 1.517 1.834 1.834 0 0 1-1.224.989 2.739 2.739 0 0 1-1.83-.275c-1.31-.662-1.841-1.642-1.362-2.892l1.245.613a.973.973 0 0 0 .62 1.284c.603.296 1.035.165 1.23-.242a.575.575 0 0 0 .021-.593 2.213 2.213 0 0 0-.761-.695 3.952 3.952 0 0 1-1.392-1.319A1.68 1.68 0 0 1 22 70.581a1.948 1.948 0 0 1 1.273-1.047 2.794 2.794 0 0 1 1.951.265c.68.247 1.25.73 1.605 1.36Z"
    />
    <path
      d="m18.597 72.433-.701.934-4.083-3.092.702-.942 1.434 1.094 3.324-4.38 1.22.919-3.323 4.38 1.427 1.087Z"
    />
    <path d="m10.713 67.18 4.84-4.625 1.054 1.093-4.84 4.632-1.054-1.1Z" />
    <path
      d="M12.891 60.916a3.379 3.379 0 0 1-1.506 2.12l-.061.037a3.335 3.335 0 0 1-2.581.532 3.694 3.694 0 0 1-2.744-4.104 3.367 3.367 0 0 1 1.506-2.132l.061-.045a3.453 3.453 0 0 1 2.577-.572 3.439 3.439 0 0 1 2.183 1.582 3.386 3.386 0 0 1 .565 2.582Zm-2.833-2.773a2.507 2.507 0 0 0-1.612.474l-.061.038a2.546 2.546 0 0 0-1.049 1.296 1.747 1.747 0 0 0 .252 1.483 1.726 1.726 0 0 0 1.246.774 2.426 2.426 0 0 0 1.612-.468l.061-.044a2.327 2.327 0 0 0 1.043-1.33 1.794 1.794 0 0 0-1.492-2.236v.013Z"
    />
    <path
      d="m2.724 54.423 3.285-3.881-4.145 1.495-.447-1.264 6.28-2.25.509 1.42-3.531 4.219 4.468-1.597.454 1.265-6.28 2.25-.593-1.657Z"
    />
    <path
      d="M3.584 41.33a1.267 1.267 0 0 1-.925-.321 1.219 1.219 0 0 1-.4-.878 1.216 1.216 0 0 1 .327-.894 1.261 1.261 0 0 1 1.772-.075c.246.234.388.553.398.889a1.186 1.186 0 0 1-.314.879 1.232 1.232 0 0 1-.858.4Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-chat-text-eng',
}
</script>

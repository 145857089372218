// @vue/component
export default {
  name: 'ga-details',

  props: {
    /**
     * Текст ошибки
     */
    error: {
      type: String,
      default: '',
    },
    /**
     * Текст описания
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * Отключает форматирование в lowercase
     */
    disableLowerCase: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    lowercase() {
      return (
        Object.keys(this.$scopedSlots).length === 0 && !this.disableLowerCase
      )
    },
  },
}

import { SETTINGS_SOON_AE } from '~/modules/app/mocks/settings-soon-ae.js'

import { NuxtBase } from './base'

export class NuxtSoon extends NuxtBase {
  initAppInterceptors() {
    this.gaApp.api.addRequestCallback({
      success: (config) => {
        if (config.method === 'get' && this.gaApp.i18n.locales.length > 1) {
          config.params = {
            ...config.params,
            locale: this.gaApp.i18n.locale.code,
          }
        }

        return config
      },
    })
  }

  /**
   * Метод проверки поддержки media (hover)
   */
  detectHoverMedia() {
    this.gaApp.services.app.browserWindow.detectWindowHoverMedia()
  }

  /**
   * Метод инициализации слушателя события `orientationchange` и `resize`
   */
  initWindowResizeListener() {
    this.gaApp.services.app.browserWindow.bindWindowResizeListeners()
  }

  /**
   * Метод инициализации слушателей для событий
   * keyboard, mousedown, mouseup, click, keydown, focusin
   */
  initKeyboardAndPointerListerner() {
    this.gaApp.services.app.browserWindow.bindKeyboardAndPointerListerners()
  }

  initCurrentBreakpoint() {
    this.gaApp.services.app.window.setCurrentBreakpoint()
  }

  /**
   * Метод инициализации аналитик
   */
  initAnalytics() {
    this.gaApp.analytics.libs.initDataLayer()
    this.gaApp.analytics.libs.initAllWithPermissionsChecks()
  }

  /**
   * Метод заполнения сторов первичными данными страницы
   */
  prepareData() {
    this.gaApp.stores.app.common.setData(SETTINGS_SOON_AE)
    this.gaApp.services.footer.main.setData({
      contacts: SETTINGS_SOON_AE.messengers,
      phone: SETTINGS_SOON_AE.phones?.support,
      socials: SETTINGS_SOON_AE.socials,
    })
  }

  // eslint-disable-next-line max-statements
  init() {
    this.initAppInterceptors()

    this.prepareData()

    this.initWindowResizeListener()

    this.initCurrentBreakpoint()

    this.detectHoverMedia()
    this.initKeyboardAndPointerListerner()

    this.setLocale()

    this.initAnalytics()

    this.gaApp.router.afterEach(() => {
      if (!this.gaApp.isInit) {
        this.gaApp.eventBus.publish('module/app/init')

        this.gaApp.stores.app.main.init = true

        const { isSSR, withHover } = this.gaApp.stores.app
        const { currentBreakpoint, windowSize } = this.gaApp.stores.app.window

        this.gaApp.services.app.apm.setCustomContext({
          app: {
            isSSR,
          },
        })

        this.gaApp.services.app.apm.setCustomContext({
          device: {
            withHover,
            currentBreakpoint,
            ...windowSize,
          },
        })
      }
    })
  }
}

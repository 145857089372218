export const endpoints = {
  sync: () => ({
    url: 'auth/sync',
  }),
  sendCodeV2: () => ({
    url: 'auth/push-v2',
  }),
  resendCodeV2: () => ({
    url: 'auth/push-v2',
  }),
  sendCode: () => ({
    url: 'auth/push',
  }),
  resendCode: () => ({
    url: 'auth/push',
  }),
  getTokens: () => ({
    url: 'auth/token',
  }),
  refreshTokens: () => ({
    url: 'auth/token',
  }),
  logout: () => ({
    url: 'auth/logout',
  }),
}

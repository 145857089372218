<template>
  <svg viewBox="0 0 12 12">
    <path
      fill="none"
      stroke-width=".75"
      d="m1.127 8.812.078.135-.04.15L.53 11.47l2.373-.635.15-.04.135.078a5.625 5.625 0 1 0-2.06-2.06Z"
    />
    <path
      stroke="none"
      d="M7.367 6.384a.42.42 0 0 0-.304-.138.435.435 0 0 0-.308.138l-.284.282-.07-.036c-.032-.016-.063-.031-.089-.048a3.087 3.087 0 0 1-.74-.672 1.814 1.814 0 0 1-.243-.381c.073-.068.142-.137.208-.205l.076-.076c.189-.188.189-.432 0-.62l-.246-.244-.083-.086a4.242 4.242 0 0 0-.17-.166A.427.427 0 0 0 4.813 4a.442.442 0 0 0-.306.132l-.002.002-.306.307a.654.654 0 0 0-.195.417c-.022.261.056.505.115.664.146.392.364.755.688 1.144.395.468.869.838 1.41 1.1.208.097.484.212.793.232L7.067 8a.68.68 0 0 0 .519-.222c0-.002.003-.003.004-.005.046-.056.1-.107.157-.162a3.54 3.54 0 0 0 .117-.115c.09-.093.136-.2.136-.31a.43.43 0 0 0-.139-.308l-.494-.494Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-wa',
}
</script>

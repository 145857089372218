import { EVENT_CATEGORIES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4LogoutModel extends GtmBaseModel {
  constructor() {
    super({ event: 'logout' })

    this.event_category = EVENT_CATEGORIES.USER_AUTH
  }
}

import get from 'lodash/get'

import {
  DESIGN_TYPES,
  MAPS,
  TABS_VALUE_DELIVERY,
  TABS_VALUE_RECIPIENT,
} from '~/modules/gift-cards/constants/digital'

export const getters = {
  isUserRecipient() {
    return this.activeTabRecipient === TABS_VALUE_RECIPIENT.SELF
  },

  isDeliveryNow() {
    return this.activeTabDelivery === TABS_VALUE_DELIVERY.NOW
  },

  isDeliveryDiff() {
    return this.activeTabDelivery === TABS_VALUE_DELIVERY.DEFERRED
  },

  timezoneValue() {
    return get(this, MAPS.TIMEZONE)
  },

  timezoneText() {
    const timezone = get(this, MAPS.TIMEZONE)

    return (
      this.timezoneOptions.find((option) => option.value === timezone)?.text ||
      ''
    )
  },

  phoneReceiptValue() {
    return get(this, MAPS.PHONE_RECEIPT)
  },

  getLoaderStatus() {
    return (typeLoader) => this.loader[typeLoader]
  },

  selectedDesign() {
    return this.design[this.selectedDesignType]
  },

  isConfigLoaded() {
    return (
      this.design[DESIGN_TYPES.GA].backgroundsOptions.length > 0 &&
      this.design[DESIGN_TYPES.GA].imagesOptions.length > 0 &&
      this.pricesOptions.length > 0
    )
  },

  isGaDesignSelected() {
    return this.selectedDesignType === DESIGN_TYPES.GA
  },

  isAiDesignSelected() {
    return this.selectedDesignType === DESIGN_TYPES.AI
  },

  selectedImage() {
    const id = get(this, MAPS.IMG)

    return (
      this.selectedDesign.imagesOptions?.find((image) => image.id === id) ||
      null
    )
  },

  selectedBackground() {
    const id = get(this, MAPS.COLOR)

    return (
      this.selectedDesign.backgroundsOptions?.find(
        (background) => background.id === id,
      ) || null
    )
  },

  visitedSteps() {
    return this.visitedStepsByDesign[this.selectedDesignType]
  },
}

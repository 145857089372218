export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запрос списка продуктов
   */
  async fetchProductsByPage({ categoryId, page = 1 }) {
    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const user = this.gaApp.services.user.main.data

    const { data } = await this.gaApp.repositories.dolyame.main.fetchProducts({
      cityId,
      cityDistrict,
      geoPolygons,

      pageNumber: page,
      categoryId,

      filters: [],

      customerGroupId: user.customerGroupId || user.groupId || '0',
    })

    const { products } = data

    this.gaApp.stores.dolyame.main.products = products
  }
}

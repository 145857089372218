import { useContext } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/isEmpty'

import { computed, unref } from 'vue'

/**
 * Нужно для того, что бы вычислить размер шрифта
 *
 * @param {Object} size — объект конфигурации размера.
 * @param {Object} price — объект цены.
 * @param {boolean} showCurrency — указывает, должна ли отображаться валюта.
 * @return {Ref<Number>} Вычисленный размер шрифта.
 */
export const useSize = (size = {}, price = {}, showCurrency = false) => {
  const { $gaApp } = useContext()

  const fontSize = computed(() => {
    const { fontSize, threshold } = unref(size)

    // Вычисляем вес цены на основе объекта цены.
    const weight = (() => {
      if (isEmpty(unref(price))) return null

      const weight = $gaApp.services.app.currency.getPriceWeight(unref(price))

      // Возвращаем вес с валютой или без нее на основе флага showCurrency.
      return unref(showCurrency) ? weight.withCurrency : weight.value
    })()

    // Если пороговое значение было пройдено, то возвращаем уменьшенный размер шрифта
    return weight && threshold && weight > threshold
      ? Math.round(fontSize - (fontSize * 10) / 100)
      : fontSize
  })

  return {
    fontSize,
  }
}

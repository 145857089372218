import { bannersStore } from './banners'
import { categoriesStore } from './categories'
import { categoryStore } from './category'
import { clientDaysStore } from './client-days'
import { contentStore } from './content'
import { infoStore } from './info'
import { listingStore } from './listing'
import { mainStore as main } from './main'
import { productsStore } from './products'
import { promoStore } from './promo'
import { slotsStore } from './slots'
import { storiesStore } from './stories'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    slots: slotsStore(gaApp),
    banners: bannersStore(gaApp),
    stories: storiesStore(gaApp),
    content: contentStore(gaApp),
    promo: promoStore(gaApp),
    products: productsStore(gaApp),
    info: infoStore(gaApp),
    category: categoryStore(gaApp),
    clientDays: clientDaysStore(gaApp),
    listing: listingStore(gaApp),
    categoryList: categoriesStore(gaApp),
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { useDefaultSliderLayout } from '~/components/widgets/product-slider'

export const useSlider = ({ isLoading, products, title }) => {
  const { $gaApp } = useContext()

  const sliderProducts = computed(() => {
    return unref(products) ?? []
  })

  const isMobile = computed(() => $gaApp.mq('tablet-'))

  const { cardSize, sliderSize } = useDefaultSliderLayout()

  const showStartIndent = computed(() => {
    return unref(isMobile)
  })

  const sliderAttrs = computed(() => {
    return {
      skeleton: unref(isLoading),
      title: {
        text: unref(title),
      },
      showStartIndent: unref(showStartIndent),
      size: unref(sliderSize),
      items: unref(sliderProducts),
    }
  })

  return {
    sliderAttrs,
    cardSize,
  }
}

import { AUTH_MODAL_REASON_TO_OPEN, DIALOG } from '../constants'

export class DialogService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openDialog({ type }) {
    this.gaApp.stores.cart.modal.typeDialog = type
    this.gaApp.stores.cart.modal.isOpenDialog = true
  }

  closeDialog() {
    this.gaApp.stores.cart.modal.isOpenDialog = false
  }

  openAuthConfirm() {
    this.openDialog({ type: DIALOG.AUTH })
  }

  openDeleteSectionConfirm(sectionName) {
    this.gaApp.stores.cart.modal.deleteSectionName = sectionName
    this.openDialog({ type: DIALOG.DELETE_SECTION })
  }

  openDeleteAllConfirm() {
    this.openDialog({ type: DIALOG.DELETE_ALL })
  }

  openDeleteBeforeCheckoutConfirm() {
    this.openDialog({ type: DIALOG.DELETE_BEFORE_CHECKOUT })
    this.gaApp.analytics.modules.cart.onOpenOutOfStockDialogBeforeCheckout()
  }

  openItemDialog() {
    this.openDialog({ type: DIALOG.ITEM })
  }

  async acceptDeleteSection() {
    await this.gaApp.services.cart.modal.deleteSection(
      this.gaApp.stores.cart.modal.deleteSectionName,
    )

    this.gaApp.stores.cart.modal.deleteSectionName = null

    this.closeDialog()
  }

  async acceptDeleteAll() {
    const ids = [...this.gaApp.stores.cart.main.itemsMagentoId]
    await this.gaApp.services.cart.modal.deleteItems(ids)
    this.closeDialog()
  }

  async acceptDeleteBeforeCheckout() {
    this.closeDialog()

    await this.gaApp.services.cart.modal.deleteProductsBeforeCheckout()
  }

  acceptAuth() {
    this.closeDialog()
    this.openAuthToCheckout()
  }

  cancelAuth() {
    this.gaApp.services.cart.modal.redirectToCheckout()
  }

  async addAllProductToFavorite() {
    await this.gaApp.services.cart.product.addAllItemsToFavorite()
    await this.acceptDeleteAll()
  }

  async addProductsToFavoriteBeforeCheckout() {
    this.closeDialog()

    await this.gaApp.services.cart.modal.addProductsToFavoriteBeforeCheckout()
  }

  openAuthToCart() {
    this.gaApp.services.modal.main.openSuperModalAuth({
      push: true,
      redirect: 'cart',
    })
  }

  openAuthToCheckout(skusToFavorites = []) {
    this.gaApp.services.modal.main.openSuperModalAuth({
      push: true,
      redirect: 'cart',
      reason: AUTH_MODAL_REASON_TO_OPEN.LOGIN_TO_CHECKOUT_BY_CART,
      callbacks: {
        register: async () => {
          if (skusToFavorites.length) {
            await this.gaApp.services.cart.product.addItemsToFavorite(
              skusToFavorites,
            )
          }
        },
        login: async () => {
          if (skusToFavorites.length) {
            await this.gaApp.services.cart.product.addItemsToFavorite(
              skusToFavorites,
            )
          }

          await this.gaApp.services.cart.main.toCheckoutWithChecking()
        },
      },
    })
  }
}

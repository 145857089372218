export class GtmGa4ShareCartProductModel {
  constructor(product) {
    const { index, name, itemId, price, brand, inStock, quantity } = product

    this.index = index
    this.item_name = name
    this.item_id = itemId
    this.item_brand = brand
    this.item_list_name = 'share_cart'
    this.price = price
    this.stock = String(inStock)
    this.quantity = quantity
  }
}

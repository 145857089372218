<template>
  <svg fill="none" stroke="none" viewBox="0 0 30 20">
    <rect
      width="28.162"
      height="18.75"
      x=".625"
      y=".625"
      fill="#fff"
      stroke="#000"
      stroke-width="1.25"
      rx="1.375"
    />
    <path
      fill="#000"
      stroke="#000"
      stroke-width="1.25"
      d="M.625 5.919h28.162v2.868H.625V5.919Z"
    />
    <path stroke="#000" stroke-width="1.25" d="M14.118 15.294h7.059" />
    <path stroke="#000" stroke-width="1.25" d="M22.353 15.294h3.53" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-card',
}
</script>

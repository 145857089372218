import { TYPE } from '../../constants'
import { DigiAnalyticsAqSynonymClickModel } from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class DigiAqSynonymClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map()

    models.set(TYPE.DIGI, DigiAnalyticsAqSynonymClickModel)

    super(models, data)
  }
}

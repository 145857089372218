<template>
  <svg fill="none" stroke="none" viewBox="0 0 50 50">
    <path
      fill="#fff"
      d="M25.204 23.571V27h5.787c-.233 1.471-1.749 4.314-5.787 4.314-3.484 0-6.326-2.828-6.326-6.314s2.842-6.314 6.326-6.314c1.983 0 3.31.828 4.067 1.543l2.77-2.615C30.262 15.986 27.959 15 25.204 15 19.563 15 15 19.471 15 25s4.563 10 10.204 10C31.094 35 35 30.943 35 25.229c0-.658-.073-1.158-.16-1.658h-9.636Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-google',
}
</script>

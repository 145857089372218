<template>
  <svg stroke="none" viewBox="0 0 30 30">
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="M15.806 10.112A5.234 5.234 0 0 1 15 12.913a5.245 5.245 0 0 1-.804-2.8v-.235c0-1.03.296-1.99.804-2.8.511.81.806 1.77.806 2.8v.234Zm0 8.828c0 1.03-.296 1.991-.806 2.801a5.254 5.254 0 0 1-.804-2.8v-.235c0-1.028.296-1.992.804-2.802a5.24 5.24 0 0 1 .806 2.802v.234ZM26 4.687V3h-6.75A6.676 6.676 0 0 0 15 4.561 6.682 6.682 0 0 0 10.748 3H4v1.687h6.742c1.169 0 2.246.41 3.106 1.097a6.971 6.971 0 0 0-1.362 4.147v.314c0 .657.092 1.297.264 1.905a6.578 6.578 0 0 0-2.031-.32H4v1.684h6.742c1.05 0 2.027.33 2.837.894a4.927 4.927 0 0 1-2.837.897H4v1.866h6.719c.705 0 1.389-.112 2.029-.317a7.055 7.055 0 0 0-.262 1.904v.313c0 1.5.474 2.89 1.277 4.028a4.944 4.944 0 0 1-3.02 1.033H4V26h6.719c1.62 0 3.111-.585 4.281-1.559A6.675 6.675 0 0 0 19.282 26H26v-1.868h-6.742a4.944 4.944 0 0 1-3.021-1.033 6.967 6.967 0 0 0 1.277-4.028v-.313a6.91 6.91 0 0 0-.265-1.904 6.67 6.67 0 0 0 2.033.317H26v-1.866h-6.742c-1.05 0-2.025-.33-2.836-.897a4.949 4.949 0 0 1 2.836-.894H26v-1.685h-6.718c-.706 0-1.39.113-2.033.321a6.934 6.934 0 0 0 .265-1.905v-.314c0-1.55-.507-2.988-1.36-4.147a4.95 4.95 0 0 1 3.104-1.097H26Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-30-logo',
}
</script>

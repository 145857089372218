import { byteToMb } from '../byte-to-mb'

// https://github.com/Donaldcwl/browser-image-compression
export const compressImage = async (fileImage, options) => {
  try {
    const imageCompression = () =>
      import('browser-image-compression').then((mod) => {
        return mod.default(fileImage, options)
      })

    return await imageCompression()
  } catch (error) {
    console.error(`${fileImage?.name || 'image'} non compressed`, error)
    return fileImage
  }
}

// сначала кропаем размер, потом снижаем качество
export const compressImageSize = async (
  fileImage,
  { maxWidthOrHeight = 1920, maxSizeMB = Infinity, ...options },
) => {
  const imageSize = byteToMb(fileImage.size)

  if (imageSize < maxSizeMB) {
    return Promise.resolve(fileImage)
  }

  const cropImage = await compressImage(fileImage, {
    maxWidthOrHeight,
    ...options,
  })

  if (byteToMb(cropImage?.size) > maxSizeMB) {
    return await compressImage(cropImage, { maxSizeMB, ...options })
  }

  return cropImage
}

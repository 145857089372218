export const state = () => ({
  foreground: {},
  background: {},
  designColor: '',
  denomination: {},
  felicitation: {},
  cardNumber: '',
  qrCode: '',
  cvc: '',
  validUntil: '',
  video: {},
  orderType: '',
})

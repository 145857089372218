import { CHANGE_DELIVERY_MODAL_TYPES } from '../../constants'

export const getters = (gaApp) => ({
  modalType() {
    const data = this.changeDeliveryModal.data

    switch (true) {
      case Boolean(data?.possibleDates && data.possibleDates[0]?.times):
        return CHANGE_DELIVERY_MODAL_TYPES.DELIVERY_DATES
      case Boolean(data?.possibleDates):
        return CHANGE_DELIVERY_MODAL_TYPES.STORAGE_DATES
      case Boolean(data?.additionalInfo):
        return CHANGE_DELIVERY_MODAL_TYPES.INFO
      default:
        return ''
    }
  },
})

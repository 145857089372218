import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const aboutStore = () => {
  const init = defineStore('giftCardsAbout', {
    state,
  })

  return init(getActivePinia())
}

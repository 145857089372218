export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    switch (redirect.pageType) {
      case 'main':
        return this.getBrandzoneInitialData(redirect)

      case 'landing':
        return this.getBrandzoneLandingInitialData(redirect)
    }
  }

  setDataOnPageCreated(data) {
    const setData = () => {
      this.gaApp.stores.brandzone.slots.items = data.blocks
      this.gaApp.services.brandzone.slots.fillStores()
    }

    if (!this.gaApp.isClient) {
      return setData()
    }

    this.gaApp.services.brandzone.slots.resetAll()

    const unsubscribe = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      () => {
        setData()
        unsubscribe()
      },
    )
  }

  async getBrandzoneLandingInitialData(redirect) {
    const categoryRedirect =
      await this.gaApp.services.brandzone.api.fetchRedirect({
        url: `/brands/${redirect.entityId}`,
      })

    this.gaApp.stores.brandzone.page.data = {
      params: { categoryId: +categoryRedirect.entityId },
      type: redirect.pageType,
    }

    return Promise.allSettled([
      this.gaApp.services.brandzone.api.fetchCategory(),
      this.gaApp.services.brandzone.landing.fetchLandingData(),
    ])
  }

  getBrandzoneInitialData(redirect) {
    this.gaApp.stores.brandzone.page.data = {
      params: { categoryId: +redirect.entityId },
      type: redirect.pageType,
    }

    const [, brandName] = this.gaApp.route.path.split('/brands/')
    this.gaApp.stores.brandzone.page.meta = {
      brandName,
    }

    return Promise.allSettled([
      this.gaApp.services.brandzone.api.fetchCategory(),
      this.gaApp.services.brandzone.slots.fetchSlotsData(),
    ])
  }
}

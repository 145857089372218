export const addressesEndpoints = {
  getAddressList: () => ({
    url: `customer/addresses/list`,
  }),
  getAddressListV2: () => ({
    url: `customer/addresses/list-v2`,
  }),
  getAddressDefault: () => ({
    url: `customer/addresses/getDefault`,
  }),
  setAddressDefault: () => ({
    url: `customer/addresses/setDefault`,
  }),
  setAddressDefaultV2: () => ({
    url: `customer/addresses/setDefault-v2`,
  }),
  deleteAddress: (id) => ({
    url: `customer/addresses/delete/${id}`,
  }),
  deleteAddressV2: () => ({
    url: `customer/addresses/delete-v2/`,
  }),
  updateAddress: () => ({
    url: `customer/addresses/update`,
  }),
  updateAddressV2: () => ({
    url: `customer/addresses/update-v2`,
  }),
}

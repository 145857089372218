import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductCartModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { ADD_TO_CART, REMOVE_FROM_CART } = ACTIONS
    const { list, id, plpType, searchTerm, price, itemListId, addToCart } =
      product
    const event = addToCart ? ADD_TO_CART.GA_4 : REMOVE_FROM_CART.GA_4

    super({ event })

    this.item_list_name = list
    this.item_list_id = itemListId || id
    this.plp_type = plpType
    this.search_term = searchTerm
    this.value = price
    this.ecommerce = {
      items: [new GtmGa4ProductBaseModel(product)],
    }
  }
}

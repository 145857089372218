import isNull from 'lodash/isNull'

export class DigitalCoverSelectorService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Открывает модальное окно выбора обложки набора КЭПК
   */
  open() {
    this.gaApp.stores.giftCardsB2b.digital.coverSelector.isOpen = true
  }

  /**
   * Закрывает модальное окно выбора обложки набора КЭПК
   */
  close() {
    this.gaApp.stores.giftCardsB2b.digital.coverSelector.isOpen = false
  }

  /**
   * Начало изменения обложки набора КЭПК
   * @param {number} index индекс набора КЭПК в массиве наборов
   */
  startEdit(index) {
    const set = this.gaApp.stores.giftCardsB2b.digital.sets.items[index]

    this.gaApp.stores.giftCardsB2b.digital.coverSelector.coverIndex =
      set.coverIndex
    this.gaApp.stores.giftCardsB2b.digital.coverSelector.editIndex = index

    this.open()
  }

  /**
   * Завершение изменения обложки набора КЭПК
   * @param {number} index индекс набора КЭПК в массиве наборов
   */
  finishEdit() {
    const setIndex =
      this.gaApp.stores.giftCardsB2b.digital.coverSelector.editIndex
    const newCoverIndex =
      this.gaApp.stores.giftCardsB2b.digital.coverSelector.coverIndex

    if (isNull(setIndex)) {
      return undefined
    }

    this.gaApp.stores.giftCardsB2b.digital.sets.items[setIndex].coverIndex =
      newCoverIndex
    this.gaApp.stores.giftCardsB2b.digital.sets.items[setIndex].cover =
      this.gaApp.stores.giftCardsB2b.digital.sets.covers[newCoverIndex]
    this.gaApp.stores.giftCardsB2b.digital.coverSelector.editIndex = null
  }
}

export const SETTINGS = {
  PUSH: 'pushSigned',
  EMAIL: 'emailSigned',
  SMS: 'smsSigned',
}

export const SETTINGS_PRIORITY = {
  [SETTINGS.PUSH]: 1,
  [SETTINGS.EMAIL]: 2,
  [SETTINGS.SMS]: 3,
}

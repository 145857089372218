import { cardStore as card } from './card'
import { detailStore as detail } from './detail'
import { filtersStore as filters } from './filters'
import { formStore as form } from './form'
import { galleryStore as gallery } from './gallery'
import { listingStore as listing } from './listing'
import { mainStore as main } from './main'
import { mediaStore as media } from './media'
import { modalStore as modal } from './modal'
import { previewStore as preview } from './preview'
import { productsStore as products } from './products'
import { statisticStore as statistic } from './statistic'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    form: form(gaApp),
    media: media(gaApp),
    products: products(gaApp),
    gallery: gallery(gaApp),
    filters: filters(gaApp),
    statistic: statistic(gaApp),
    listing: listing(gaApp),
    preview: preview(gaApp),
    detail: detail(gaApp),
    modal: modal(gaApp),
    card: card(gaApp),
  }
}

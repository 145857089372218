<template>
  <svg fill="none" viewBox="0 0 22 30">
    <path
      fill="#4D4D4D"
      fill-rule="evenodd"
      d="M17.452 8.1h-13.8V4.5h13.8v3.6Zm0 17.4h-13.8v-3.6h13.8v3.6Zm-17.4-3.6h3.6V8.1h-3.6v13.8Zm21 0h-3.6V8.1h3.6v13.8Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-bel',
}
</script>

import { queryHighlight } from '@ga/utils'

import { findQueryPosition } from '../components/organisms/searchable-alphabet-list/scripts/utils'
import { BRANDS_SEARCH_ABORT_KEY } from '../constants'

export class SearchService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  buildBrandLabel({ start, size, label }) {
    const highlighted = label.substr(start, size)

    return queryHighlight(label, highlighted, {
      htmlTag: 'mark',
    })
  }

  // Для того, чтобы выделить жирным совпадение поискового запроса в названии бренда,
  // нужно учесть возможность наличия апострофа и т.н. алиасов букв вроде è, é и т.д.
  buildBrand(option, b) {
    const query = this.gaApp.stores.brands.search.queryPlain
    const startPos = option.labelPlain.indexOf(query)
    const querySize = query.length

    const apostropheIndex = option.label.search(/['`’]/)

    const { start, size } = findQueryPosition(
      query,
      startPos,
      querySize,
      apostropheIndex,
    )

    const label = this.buildBrandLabel({
      start,
      size,
      label: option.label,
    })

    return {
      ...option,
      label,
    }
  }

  buildBrandV2(option) {
    let label = option.label

    const reversedNameSelections = option.nameSelections.reverse() // нужно для того, чтобы правильно вставлять выделение жирным с конца

    reversedNameSelections.forEach((selection) => {
      const start = selection.offset
      const size = selection.length

      label = this.buildBrandLabel({ size, start, label })
    })

    return {
      ...option,
      label,
    }
  }

  /**
   * Возвращает рузльтаты поиска
   *
   * @param {string} queryPlain нормализованная строка поиска
   * @param {object} groupped содержит сгруппированные значения брэндом по буквам
   * @param {array} resultFirst результаты поиска, где поисковая фраза входит название брэнда с первого символа
   * @param {array} resultSecond результаты поиска, где поисковая фраза входит название брэнда НЕ с первого символа
   *
   * @returns {object} объект содуржащий результаты поиска
   */
  getSearchResult({ queryPlain, groupped, resultFirst, resultSecond }, b) {
    let first = []
    let second = []
    let entryDirectChar = ''

    // Если введен только один символ, то пытаемся найти группу элементов по этому символу
    const queryUppercase = queryPlain.value.toUpperCase()
    const elements = groupped.value[queryUppercase]

    if (elements) {
      entryDirectChar = queryUppercase
      first = elements
      second = []
    }

    // Иначе билдим результат с учетом всей строки поиска
    if (!elements && queryPlain.value) {
      first = resultFirst.value.map((option) => this.buildBrand(option, b))
      second = resultSecond.value.map((option) => this.buildBrand(option, b))
    }

    return {
      first,
      second,
      entryDirectChar,
    }
  }

  getSearchResultV2({ query, result }) {
    return result.map((option) =>
      option.nameSelections?.length ? this.buildBrandV2(option, query) : option,
    )
  }

  /**
   * Нужно для того, что сформировать результаты поиска
   *
   * @param {object} $event объект, который возвращается из компонента алфивитного списка при поиске
   */
  search($event, b) {
    this.gaApp.stores.brands.search.queryOriginal = $event.query.value
    this.gaApp.stores.brands.search.queryPlain = $event.queryPlain.value

    const { first, second, entryDirectChar } = this.getSearchResult($event, b)

    this.gaApp.stores.brands.search.resultFirst = first
    this.gaApp.stores.brands.search.middleOfAWord = second
    this.gaApp.stores.brands.search.firstCharEntryDirect = entryDirectChar
  }

  searchBrands(params) {
    return this.gaApp.services.brands.api.searchBrands(params, {
      abortKey: BRANDS_SEARCH_ABORT_KEY,
    })
  }

  setSearchResults(data) {
    const { query, brandSearch, inputLanguageQuery } = data

    const { inputLanguage, transliteratedLanguage } = brandSearch

    this.gaApp.stores.brands.search.queryOriginal = query
    // совпадение по названию с первого символа
    this.gaApp.stores.brands.search.resultFirst = inputLanguage
    // совпадение по названию с первого символа, НО в транслите
    this.gaApp.stores.brands.search.resultSecond = transliteratedLanguage

    // используем данные для вывода,
    // если оба списка не пустые (inputLanguage, transliteratedLanguage)
    if (
      inputLanguageQuery &&
      inputLanguage?.length &&
      transliteratedLanguage?.length
    ) {
      // первый символ из query
      this.gaApp.stores.brands.search.firstCharEntryDirect =
        inputLanguageQuery.toUpperCase()
    } else {
      // иначе очищаем поля
      this.gaApp.stores.brands.search.firstCharEntryDirect = ''
    }
  }

  reset() {
    this.gaApp.stores.brands.search.$reset()
  }

  setSearchResultsV2(data, { query }) {
    this.gaApp.stores.brands.search.searchResultsV2 = this.getSearchResultV2({
      query,
      result: data.brands,
    })
    this.gaApp.stores.brands.search.queryOriginal = query
  }
}

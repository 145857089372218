<template>
  <svg fill="none" viewBox="0 0 15 16">
    <path fill="none" stroke="inherit" d="M9 12.5 13.5 8 9 3.5M13 8H1" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-arrow-more',
}
</script>

<template>
  <svg viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="19.375" fill="none" stroke-width="1.25" />
    <path
      fill="none"
      stroke-width=".9"
      d="m11.588 18.78 3.613-4.33h9.155l3.613 4.33-8.19 9.667-8.191-9.667Z"
    />
    <path stroke-width=".9" d="M11.797 19.18h15.96" />
    <path
      fill="none"
      stroke-linejoin="bevel"
      stroke-width=".9"
      d="m14.988 14.793 1.596 3.985 3.192-3.985 3.193 3.985 1.596-3.985"
    />
    <path
      stroke="none"
      d="m19.784 28.35-.425.147h.85l-.425-.148Zm-3.617-9.018 3.192 9.165.85-.296-3.192-9.165-.85.296Zm4.042 9.165 3.192-9.165-.85-.296L19.36 28.2l.85.296Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-circle-diamond',
}
</script>

import get from 'lodash/get'
import isObject from 'lodash/isObject'

import { log } from './ga-analytics.utils.js'

class StorageItem {
  constructor(key, value) {
    return {
      [key]: {
        data: value,
        createdAt: Date.now(),
      },
    }
  }
}

export class Storage {
  constructor(storage, options = {}) {
    this.storage = storage
    this.options = options

    this.freeCount = this.getFreeCount()
  }

  /**
   * Устанавливает значение в хранилище.
   *
   * @param {*} value Значение, которое должно быть установлено.
   * @return {*} Значение, которое было установлено.
   */
  setStorage(value) {
    return this.storage.set(this.options.storageName, value)
  }

  /**
   * Возвращает текущее состояние хранилища.
   *
   * @return {Object} Текущее состояние хранилища.
   */
  getStorage() {
    return this.storage.get(this.options.storageName)
  }

  /**
   * Вычисляет количество элементов, которые необходимо удалить из хранилища, чтобы освободить место, когда оно заполнено.
   *
   * @throws {Error} Если freePercent не находится в диапазоне [0, 1].
   * @return {number} Количество элементов, которые необходимо удалить.
   */
  getFreeCount() {
    if (this.options.freePercent > 1 || this.options.freePercent < 0) {
      throw new Error('freePercent must be between 0 and 1')
    }

    return Math.round(this.options.maxSize * this.options.freePercent)
  }

  /**
   * Устанавливает значение по ключу в хранилище.
   *
   * @param {string} key Ключ, для которого устанавливается значение.
   * @param {*} value Значение, которое необходимо установить.
   * @return {Object} Новое состояние хранилища.
   */
  set(key, value) {
    // Получаем текущие данные хранилища
    const current = this.getStorage() ?? {}

    // Обертка элемента хранилища. Нужно для того, что бы наделить элемент доп. данными
    const item = new StorageItem(key, value)

    // Хранилище, с обновленными данными
    const updatedStorage = Object.assign(current, item)

    // Устанавилваем обновленные данные в хранилище
    this.setStorage(updatedStorage)

    // Освобождаем место в хранилище, если необходимо
    this.free()

    log('Установка значения в storage', { key, value })

    return updatedStorage
  }

  /**
   * Возвращает значение, которое хранится под указанным ключом
   *
   * @param {string} key Ключ, для которого нужно получить значение
   * @return {*} Значение, которое хранится под указанным ключом
   */
  get(key) {
    // Получаем текущие данные хранилища
    const current = this.getStorage() ?? {}

    // Возвращаем значение, которое хранится под указанным ключом
    const value = current && get(current, key)

    log('Получение значения из storage', { key, value })

    return value
  }

  /**
   * Возвращает массив, содержащий все элементы хранилища,
   * отсортированные в порядке возрастания даты создания.
   *
   * @return {Array} Массив, содержащий все элементы хранилища
   */
  stack() {
    // Получаем текущие данные хранилища
    const current = this.getStorage()

    // Если нет хранилища, то возвращаем null
    if (!isObject(current)) {
      return null
    }

    return Object.entries(current)
      .sort((a, b) => b[1].createdAt - a[1].createdAt)
      .map((entry) => entry[1])
  }

  /**
   * Очищает хранилище, оставляя только {options.maxSize} последних
   * значений, которые были добавлены. Если хранилище не достигло
   * порога, то возвращается false.
   *
   * @return {Object|false} Новое состояние хранилища, если оно было
   * очищено, или false, если оно не было очищено.
   */
  free() {
    // Получаем текущие данные хранилища
    const current = this.getStorage()

    // Если нет хранилища, то ничего не делаем
    if (!isObject(current)) {
      return false
    }

    // Если хранилище не достигло порога макс. объема, то ничего не делаем
    const entries = Object.entries(current)
    if (entries.length < this.options.maxSize) {
      return false
    }

    // Если хранилище достигло порога, то сортируем элементы по дате добавления
    // затем удаляем первые {freeCount} элементов
    const sorted = entries.sort((a, b) => a[1].createdAt - b[1].createdAt)
    const sliced = sorted.slice(this.freeCount)
    const updatedStorage = Object.fromEntries(sliced)

    // Обновляем значение хранилища
    this.setStorage(updatedStorage)

    log('Очистка хранилища', { options: this.options, storage: updatedStorage })

    return updatedStorage
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ChangeDesignClickModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'click_edit_design_card'
    this.type = 'kepk'
  }
}

<template>
  <svg viewBox="0 0 13 12">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      d="M6.5 12 12.995.75H.005L6.5 12Zm0-2.5L10.83 2H2.17L6.5 9.5Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-location-geo-pin',
}
</script>

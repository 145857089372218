import { TYPE } from '../../constants'
import { GtmGa4OrderClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class OrderClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4OrderClickModel]])
    super(models, data)
  }
}

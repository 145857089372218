<template>
  <svg fill="none" viewBox="0 0 15 16">
    <path stroke="inherit" stroke-width="2" d="M2 8h11m-5.5 5.5v-11" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-step-button-plus',
}
</script>

const appLocale = process.env.NODE_LOCALE || 'ru-RU'

export const getConfigDevelopment = () => ({
  devtools: true,
  ssr: false,

  apm: {
    apmRequest: () => false,
  },
  yandexMetrika: null,

  ignoreWebviewGuard: true,
  mindbox: {
    id: {
      'ru-RU': 'goldapple.ru-stage',
      'ru-BY': 'goldapple.ru-stage',
      'ru-KZ': 'goldapple.ru-stage',
      'en-QA': 'Goldappleme.qatar-test',
      'en-AE': 'Goldappleme.qatar-test',
    }[appLocale],
  },

  maps: {
    yandex: {
      key: '1ae2f24b-fa05-43c0-88c8-3439eb4e8f3f',
    },
  },
})

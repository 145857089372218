// Для метода writeText не требуется полифилл
// Для других методов, возможно, понадобится
// https://github.com/lgarron/clipboard-polyfill

export class ClipboardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  writeText(text) {
    return navigator.clipboard.writeText(text)
  }

  write(data) {
    return navigator.clipboard.write(data)
  }
}

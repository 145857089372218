import { TYPE } from '../../constants'
import { GtmClickGoToPurchasesReceiveReturnModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ClickGoToPurchasesReceiveReturnBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmClickGoToPurchasesReceiveReturnModel],
    ])
    super(models, data)
  }
}

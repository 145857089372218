export const state = () => {
  return {
    openedModal: false,
    wasOpenedModal: false,
    data: null,
    hasError: false,
    pending: false,
    reviewId: null,
  }
}

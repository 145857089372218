<template>
  <svg fill="none" stroke="none" viewBox="0 0 20 20">
    <path
      fill="#A0EB00"
      d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Z"
    />
    <path
      fill="#fff"
      d="m10 2.404 1.557 6.038L17.595 10l-6.038 1.557L10 17.595l-1.558-6.038L2.404 10l6.038-1.558L10 2.404Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-on-demand',
}
</script>

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ShareCartProductModel } from '../gtm-ga4-share-cart-product.model/gtm-ga4-share-cart-product.model'

export class GtmGa4ShareAddToCartModel extends GtmBaseModel {
  constructor({ products }) {
    if (!products) {
      throw new Error('Products are required')
    }

    super({ event: EVENT.SHARE_ADD_TO_CART.GA4 })

    this.event_source = 'plaid_backend'

    this.ecommerce = {
      item_list_name: 'share_cart',
      items: products.map(
        (product) => new GtmGa4ShareCartProductModel(product),
      ),
    }
  }
}

<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M1.5 1.5v17h17v-17h-17Zm15.75 1.25h-6.625v6.625h6.625V2.75Zm0 7.875h-6.625v6.625h6.625v-6.625Zm-7.875-1.25V2.75H2.75v6.625h6.625Zm-6.625 1.25h6.625v6.625H2.75v-6.625Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-grid-normal',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { useCityIdCategoryId } from '~/modules/plp/composable/use/cityId-categoryId'

export const useFiltersModalToggle = () => {
  const { $gaApp } = useContext()

  const { categoryId } = useCityIdCategoryId()
  const {
    id: cityId,
    cityDistrict,
    geoPolygons,
  } = $gaApp.services.location.main.getDeliveryAddress()

  const onModalOpened = (isOpen) => {
    // Если модалка закрывается, то не запрашиваем фильтры
    // Если хеши равны, то не запрашиваем фильтры
    if (!isOpen || $gaApp.services.filters.main.areHashesEqual()) {
      return false
    }

    $gaApp.services.filters.api.requestFilters({
      categoryId: categoryId.value,
      cityId,
      cityDistrict,
      geoPolygons,
    })
  }

  const modal = computed({
    get() {
      return $gaApp.stores.filters.main.getFiltersModalOpened
    },

    set(value) {
      onModalOpened(value)

      $gaApp.stores.filters.main.setFilterModalDeferRendered()

      $gaApp.stores.filters.main.setFiltersModalOpened(value)
    },
  })

  return {
    modal,
  }
}

import { COLOR_SCHEME, STATE } from '../../constants'

export const state = () => {
  return {
    headerState: STATE.INITIAL,
    headerColorScheme: COLOR_SCHEME.REGULAR,

    headerMinimized: false,
    headerExtended: true,
    headerExtensionLock: false,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { HEADER_DESKTOP_BREAKPOINT } from '../../../../../constants'

export const useMedia = () => {
  const { $gaApp } = useContext()

  const desktop = computed(() => $gaApp.mq(`${HEADER_DESKTOP_BREAKPOINT}+`))

  const tablet = computed(() => $gaApp.mq('tablet+') && !unref(desktop))

  return {
    desktop,
    tablet,
  }
}

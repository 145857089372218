import { GaNotification } from '../../../notification'

export const useNotificationParams = () => {
  const getItemComponent = (item) => {
    const { data } = item

    return (data && data.component) || GaNotification
  }

  const getItemProps = (item) => {
    const { text, link, data } = item

    return (data && data.props) || { text, link }
  }

  const getItemListeners = (item) => {
    const { data } = item

    return (data && data.listeners) || {}
  }

  return {
    getItemComponent,
    getItemProps,
    getItemListeners,
  }
}

export const TYPE = {
  BRANDZONE: 'brandzone',
  CART: 'cart',
  PDP: 'pdp',
  ERROR: 'errorPage',
  FAVORITES: 'LkFavorites',
  HOME: 'home',
  HOME_LISTING: 'homeListing',
  CATEGORY: 'category',
  PLP: 'plp',
  STORIES: 'stories',
  SEARCH: 'search',
  ORDERS: 'lkOrders',
  PURCHASED: 'lkPurchasedProducts',
  UNAVAILABLE_CROSS_COUNTRY: 'pdpUnavailable',
}

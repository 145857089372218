import { useContext } from '@nuxtjs/composition-api'
import { useElementHover } from '@vueuse/core'

import { computed, ref, toRefs, unref, watch } from 'vue'

import { GaHoverGallery } from '@ga/ui-components/hover-gallery'
import { GaTouchSlider } from '@ga/ui-components/touch-slider'

import { GaImageAdult } from '~/components/image-adult'

import { propValidator } from '../../../utils'
import { media as schemaMedia } from '../../../utils/schemas'
import { GaProductCardMediaItem } from '../media-item'

import { MEDIA_VIEW, SLIDER_DOTS_SIZE, VIDEO_STRATEGY } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-media',

  components: {
    GaImageAdult,
    GaProductCardMediaItem,
    GaTouchSlider,
    GaHoverGallery,
  },

  props: {
    productId: {
      type: String,
      required: true,
    },
    media: {
      type: Array,
      default: () => [],
      required: true,
      validator: (value) => propValidator(value, schemaMedia),
    },
    isAdult: {
      type: Boolean,
      default: false,
      required: true,
    },
    isHovering: {
      type: Boolean,
      default: false,
      required: true,
    },
    maxCount: {
      type: Number,
      default: 10,
    },
    faded: {
      type: Boolean,
      default: false,
      required: true,
    },
    sliderDotsSize: {
      type: String,
      default: SLIDER_DOTS_SIZE.XXS,
      validator: (value) => Object.values(SLIDER_DOTS_SIZE).includes(value),
    },
    videoStrategy: {
      type: String,
      default: VIDEO_STRATEGY.NONE,
    },
  },

  setup(props, { emit }) {
    const { media, videoStrategy, maxCount } = toRefs(props)

    const { $gaApp } = useContext()

    const singleMediaRef = ref(null)
    const hoverGalleryRef = ref(null)
    const isSingleMediaHovered = useElementHover(singleMediaRef)
    const isHoverGalleryHovered = useElementHover(hoverGalleryRef)
    const isSSR = computed(() => $gaApp.services.app.main.isSSR)
    const withHover = computed(() => $gaApp.stores.app.main.withHover)

    const filterVideo = (source) => {
      // Видео будет проигнорировано, если это SSR
      if (unref(isSSR)) {
        return !source.video
      }

      // Видео будет проигнорировано, если видео-стратегия не указана
      if (source.video) {
        const strategy = unref(videoStrategy)
        return strategy && strategy !== VIDEO_STRATEGY.NONE
      }

      return source
    }

    const filteredMedia = computed(() =>
      unref(media).filter(filterVideo).slice(0, unref(maxCount)),
    )

    const hasVideo = computed(() =>
      unref(filteredMedia).some((source) => source.video),
    )

    const mediaItems = computed(() =>
      unref(filteredMedia).map((source) => {
        const data = { ...source }

        if (data.image) {
          data.meta = data.meta ?? false
        }

        return data
      }),
    )

    const mediaView = computed(() => {
      if (unref(filteredMedia).length === 0) return null

      if (unref(filteredMedia).length === 1) return MEDIA_VIEW.SINGLE

      if (unref(withHover)) return MEDIA_VIEW.GALLERY

      return MEDIA_VIEW.SLIDER
    })

    const mediaListeners = {
      'video-end': () => emit('video-end'),
      'video-error': () => emit('video-error'),
      'video-start': () => emit('video-start'),
      'video-hover': ($event) => emit('video-hover', $event),
      'video-swipe': ($event) => emit('video-swipe', $event),
      'video-intersect': ($event) => emit('video-intersect', $event),
    }

    // взаимодействие с hover gallery
    watch(isHoverGalleryHovered, (val) => {
      if (unref(hasVideo)) {
        emit('video-wrapper-hover', { hovered: val })
      }
    })

    return {
      mediaItems,
      mediaView,
      MEDIA_VIEW,
      isSSR,
      mediaListeners,
      singleMediaRef,
      hoverGalleryRef,
      isSingleMediaHovered,
    }
  },
}

export const getters = (gaApp) => ({
  title() {
    let path = 'review.products.title'
    const { isVariantsTypeProducts, isAvailableTypeProducts, hasOneItem } =
      gaApp.stores.review.products

    if (isAvailableTypeProducts) {
      path = hasOneItem ? `${path}.available.short` : `${path}.available.full`
    } else if (isVariantsTypeProducts) {
      path = `${path}.variants.full`
    } else {
      return ''
    }

    return gaApp.i18n.t(path)
  },
})

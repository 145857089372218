import { DigitalAiDesignRepository } from './digital-ai-design.repository'
import { endpoints } from './endpoints'
import { MainRepository } from './main.repository'
import { PaymentRepository } from './payment.repository'
import { ReactionsRepository } from './reactions.repository'

export const repositories = (gaApp) => ({
  main: new MainRepository(gaApp, endpoints),
  reactions: new ReactionsRepository(gaApp, endpoints),
  payment: new PaymentRepository(gaApp, endpoints),
  digitalAiDesign: new DigitalAiDesignRepository(gaApp, endpoints),
})

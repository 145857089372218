<template>
  <svg viewBox="0 0 20 20">
    <circle r="10" stroke="none" transform="matrix(-1 0 0 1 10 10)" />
    <path
      fill="none"
      stroke="#fff"
      stroke-width="1.2"
      d="m11 13 3-3-3-3m2.667 3h-8"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-coupon-apply',
}
</script>

export class CancelRequest {
  constructor(httpClient) {
    this.requests = new Map()
    this.httpClient = httpClient
  }

  addRequest(key) {
    if (this.requests.get(key)) {
      this.abortRequest(key)
    }

    const source = this.httpClient.CancelToken.source()

    this.requests.set(key, source)

    return source.token
  }

  abortRequest(key) {
    const cancelToken = this.requests.get(key)

    if (cancelToken) {
      this.requests.delete(key)
      cancelToken.cancel(key)
    }
  }
}

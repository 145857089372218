<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M14.876 16.853c.226.16.516.2.775.102.26-.1.45-.32.507-.589.609-2.86 2.085-10.1 2.64-12.702a.547.547 0 0 0-.183-.53.56.56 0 0 0-.558-.099C15.12 4.122 6.077 7.515 2.38 8.883a.579.579 0 0 0-.38.56.58.58 0 0 0 .416.535c1.657.496 3.833 1.186 3.833 1.186s1.017 3.071 1.548 4.633c.066.196.22.35.422.403a.607.607 0 0 0 .568-.145l2.168-2.047s2.502 1.835 3.92 2.845Zm-7.711-6.077 1.176 3.879.261-2.457 7.133-6.433a.194.194 0 0 0 .023-.264.199.199 0 0 0-.263-.045l-8.33 5.32Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-tg',
}
</script>

import { computed } from 'vue'

import { STATE } from '../const'

/**
 * Возвращает параметры для focus-trap
 * @param {Ref<import('../const').State>} state - состояние модалки
 * @param {Reactive<Object>} refs - refs
 */

export const useFocusTrap = (state, refs) => {
  const focusTrapped = computed(() => {
    return [
      STATE.CLOSING,
      STATE.OPENED,
      STATE.EXPANDED,
      STATE.EXPANDING,
    ].includes(state.value)
  })

  const fallbackFocus = () => {
    return refs.modal
  }

  return {
    focusTrapped,
    fallbackFocus,
  }
}

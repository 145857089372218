import { GaCounterBase } from '@ga/ui-components/counter-base'

// @vue/component
export default {
  name: 'ga-header-cart-counter',

  components: {
    GaCounterBase,
  },

  inheritAttrs: false,

  props: {
    qty: {
      type: Number,
      required: true,
    },
  },

  computed: {
    qtyInternal() {
      return this.overflown ? '∞' : this.qty
    },

    overflown() {
      return this.qty > 99
    },
  },
}

import { setPrependAppendDisplay } from './set-prepend-append-display'
import { setPrependAppendOpacity } from './set-prepend-append-opacity'

/**
 * Функция перерасчитывает видимость иконок при mount и ресайзе,
 * поэтому сначала выводим их прозрачными, чтобы рассчитать ширины,
 * а затем вычисляем их отображение
 * @param {Node} nodeInner
 * @param {Node} nodeText
 * @param {Node} nodePrepend - может быть null
 * @param {Node} nodeAppend - может быть null
 * @param {Function} [callback]
 */
export const updatePrependAppendDisplay = (
  nodeInner,
  nodeText,
  nodePrepend,
  nodeAppend,
  callback = () => {},
) => {
  if (!nodeInner || !nodeText) return
  if (!nodePrepend && !nodeAppend) return

  setPrependAppendOpacity(nodePrepend, nodeAppend, 0, 0)
  setPrependAppendDisplay(nodePrepend, nodeAppend, true, true)

  // обязательно должны быть в разметке
  const nodeInnerWidth = nodeInner.offsetWidth
  const nodeTextWidth = nodeText.offsetWidth

  // могут отсутсвовать
  const nodePrependWidth = nodePrepend?.offsetWidth
  const nodeAppendWidth = nodeAppend?.offsetWidth

  let total = nodeTextWidth

  let nodePrependDisplay = false
  let nodeAppendDisplay = false

  if (nodePrependWidth && total + nodePrependWidth <= nodeInnerWidth) {
    total += nodePrependWidth
    nodePrependDisplay = true
  }
  if (nodeAppendWidth && total + nodeAppendWidth <= nodeInnerWidth) {
    total += nodeAppendWidth
    nodeAppendDisplay = true
  }

  setPrependAppendDisplay(
    nodePrepend,
    nodeAppend,
    nodePrependDisplay,
    nodeAppendDisplay,
  )
  setPrependAppendOpacity(nodePrepend, nodeAppend, 1, 1)
  callback()
}

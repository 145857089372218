export const TYPE = {
  GTM_4: 'gtm_4',
}

export const EVENT = 'user_interaction'
export const EVENT_CATEGORY = 'landing_qatar_launch'

export const EVENT_CATEGORY_BTS = 'lp_back_to_school'

export const EVENT_CATEGORY_TRIX = 'lp_matrix'

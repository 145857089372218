import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4UserInteractionModel extends GtmBaseModel {
  constructor(data) {
    super({ event: ACTIONS.MENU.GA_4 })

    for (const key in data) {
      this[key] = data[key]
    }
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, toRefs, unref } from 'vue'

import { useBem } from '@ga/bem'

import { GaIconAdditionalBackArrowModal } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

import { GaHomeStoriesCirclesSliderItem } from './children/item'
import { GaHomeStoriesCirclesSliderSkeleton } from './children/skeleton'
import { useSlider } from './scripts/use'

// @vue/component
export default {
  name: 'ga-home-base-stories-circles-slider',
  components: {
    GaHomeStoriesCirclesSliderItem,
    GaHomeStoriesCirclesSliderSkeleton,
    GaIconAdditionalBackArrowModal,
    GaIcon,
  },
  props: {
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { $gaApp } = useContext()
    const { items, showSkeleton } = toRefs(props)
    const b = useBem()

    const isDesktop = computed(() => $gaApp.mq('desktop-small+'))

    const sliderEl = ref()
    const prevBtnEl = ref()
    const nextBtnEl = ref()

    const cssClasses = {
      // раздаем обычные классы, т.к. периодически swiper некорректно работает с хешами
      wrapper: 'ga-home-base-stories-circles-slider__wrapper',
      slide: 'ga-home-base-stories-circles-slider__slide',

      navDisabledClass: b('nav-button_disabled'),
      navHiddenClass: b('nav-button_hidden'),
      navLockClass: b('nav-button_lock'),
    }

    const isSkeleton = computed(
      () => !unref(items).length || unref(showSkeleton),
    )

    const { slideNext, slidePrev, isDesktopSwiperMode } = useSlider({
      sliderEl,
      prevBtnEl,
      nextBtnEl,
      cssClasses,
      items: props.items,
    })

    const onStoryCircleClick = (index, item) => {
      emit('storyClick', index, item.id)
      $gaApp.analytics.modules.home.onStoriesClick({
        name: item.name,
        storyPosition: index + 1,
      })
    }

    return {
      sliderEl,
      prevBtnEl,
      nextBtnEl,

      isSkeleton,

      isDesktop,
      isDesktopSwiperMode,

      onStoryCircleClick,

      slideNext,
      slidePrev,
    }
  },
}

export const state = () => {
  return {
    searchResult: {
      suggestions: [],
      popularRequests: [],
      formattedPopularRequests: [],
      products: [],
      categories: [],
      brands: [],
      bubbles: [],
    },
    searchInputValue: '',
    isModalLoading: false,
    searchHistory: [],
    formattedSearchHistory: [],
    isError: false,
    isOnline: true,
    searchModalOpened: false,
    initialSearchResult: null,
    // флаг, указывающий на то,
    // что все chunk'и были подгружены
    isFirstRenderCompleted: false,
  }
}

import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

/*
 * Данная утилита позволяет задать шаблонные сообщение об ошибке.
 *
 * Использование: 'Введите не меньше {min} символов'.
 */
export const template = (templateString, params) => {
  return templateString.replace(/{(\w+)}/g, (_, alias) => {
    const value = alias && params[alias]

    if (isNull(value) || isUndefined(value)) {
      return ''
    }

    return value
  })
}

export const getters = (gaApp) => ({
  cartProductsCompact() {
    return gaApp.stores.cart.main.items.map((product) => {
      const { sku, qty } = product

      return { sku, qty }
    })
  },

  availableProducts() {
    return gaApp.stores.cart.share.products.filter((product) => product.inStock)
  },

  productsQty() {
    return this.availableProducts.reduce(
      (acc, product) => acc + product.quantity,
      0,
    )
  },

  hasOutOfStockProducts() {
    return gaApp.stores.cart.share.products.some((product) => !product.inStock)
  },

  hasAdultProducts() {
    return this.availableProducts.some((product) => product.isAdult)
  },

  validProducts() {
    return this.availableProducts.filter((product) => {
      return (
        !product.isAdult || (gaApp.stores.user.main.isAdult && product.isAdult)
      )
    })
  },

  needAdultConfirm() {
    const isYoung = !gaApp.stores.user.main.isAdult

    return isYoung && this.hasAdultProducts
  },
})

/**
 * Это автоматически добавит источники в медиа-элемент.
 * Источники будут добавлены как дочерние элементы к медиа-элементу
 * как элементы `<source>`.
 * @param {ref} target ссылка на медиа-элемент.
 */

import { createEventHook, isObject, tryOnScopeDispose } from '@vueuse/shared'

import { toRefs, unref, watch } from 'vue'

export const useSource = (target, props) => {
  const sourceErrorEvent = createEventHook()
  const { url } = toRefs(props)

  watch([url, target], () => {
    if (!document) {
      return
    }
    const el = unref(target)
    if (!el) {
      return
    }
    const src = unref(url)
    let sources = []
    if (!src) return
    // Объединяем источники в массив
    if (typeof src === 'string') {
      sources = [{ src }]
    } else if (Array.isArray(src)) {
      sources = src
    } else if (isObject(src)) {
      sources = [src]
    }
    // Удаляем источники
    el.querySelectorAll('source').forEach((e) => {
      e.removeEventListener('error', sourceErrorEvent.trigger)
      e.remove()
    })
    // Добавляем новые источники
    sources.forEach(({ src, type }) => {
      const source = document.createElement('source')
      source.setAttribute('src', src)
      source.setAttribute('type', type || '')
      source.addEventListener('error', sourceErrorEvent.trigger)
      el.appendChild(source)
    })
    // Загружаем новые источники
    el.load()
  })

  // Удалить слушатели ошибок источника
  tryOnScopeDispose(() => {
    const el = unref(target)
    if (!el) {
      return
    }
    el.querySelectorAll('source').forEach((e) =>
      e.removeEventListener('error', sourceErrorEvent.trigger),
    )
  })
}

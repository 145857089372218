import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const specifyStore = (gaApp) => {
  const init = defineStore('locationSpecify', {
    state,
  })

  return init(getActivePinia())
}

<template>
  <svg stroke="none" viewBox="0 0 421 421">
    <circle
      cx="210.057"
      cy="210.057"
      r="110"
      fill="inherit"
      transform="rotate(34.7 210.057 210.057)"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-dark-green-round-blob',
}
</script>

import { TYPE } from '../../constants'
import { GtmGa4SaleRulesClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class SaleRulesClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4SaleRulesClickModel]])
    super(models, data)
  }
}

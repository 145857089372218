import { TYPE } from '~/modules/filters/constant'

import { ALL_STORES_KEY, FILTER_KEY } from '../../constants/keys'

export const getters = (gaApp) => ({
  isSingleShop() {
    return this.shopsCount === 1
  },

  shopsCount() {
    return Object.values(this.stores).reduce((count, { stores }) => {
      return count + stores.length
    }, 0)
  },

  currentCityShopsCount() {
    return this.stores[this.currentCity]
      ? this.stores[this.currentCity].stores.length
      : 0
  },

  currentCityName() {
    return this.cities.find((city) => city.value === this.currentCity)?.text
  },

  citiesFilters() {
    const cityId = gaApp.services.location.main.getDeliveryAddress()?.id

    return this.cities.map((city) => {
      return {
        id: city.value,
        name: city.text,
        isEnabled: true,
        isDefault: city.value === cityId,
        isSelected: city.value === this.currentCity,
      }
    })
  },

  currentCityStoresFullInfo() {
    return this.stores[this.currentCity].stores
  },

  currentStoreName() {
    if (this.currentShop === ALL_STORES_KEY) {
      return this.currentStores.find(
        (store) => store.value === this.currentShop,
      )?.text
    }

    const store = this.currentCityStoresFullInfo.find(
      (store) => store.id === this.currentShop,
    )

    return store.mollsName
      ? `${store.mollsName}, ${store.address}`
      : store.address
  },

  storesFilters() {
    return this.currentStores.map((store) => ({
      id: store.value,
      name: store.text,
      isDefault: store.value === ALL_STORES_KEY,
      isEnabled: this.currentStores.length > 1,
      isSelected: store.value === this.currentShop,
    }))
  },

  modalFilters() {
    return [
      {
        type: TYPE.CHECKLIST_SINGLE,
        id: FILTER_KEY.CITY,
        name: gaApp.i18n.t('promo.filters.city'),
        key: FILTER_KEY.CITY,
        isEnabled: true,
        subtitle: this.currentCityName,
        values: this.citiesFilters,
      },
      {
        type: TYPE.CHECKLIST_SINGLE,
        id: FILTER_KEY.STORE,
        name: gaApp.i18n.t('promo.filters.store'),
        key: FILTER_KEY.STORE,
        isEnabled: this.storesFilters.length > 1,
        subtitle: this.currentStoreName,
        values: this.storesFilters,
      },
    ]
  },

  mobileSelectedFilters() {
    return [
      {
        type: TYPE.CHECKLIST_SINGLE,
        id: FILTER_KEY.CITY,
        key: FILTER_KEY.CITY,
        value: this.currentCity,
      },
      {
        type: TYPE.CHECKLIST_SINGLE,
        id: FILTER_KEY.STORE,
        key: FILTER_KEY.STORE,
        value: this.currentShop,
      },
    ]
  },
})

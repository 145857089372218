import isString from 'lodash/isString'

import { EXTENSION } from '../../const'

export const extractExtension = (url) => {
  // Создаем массив с форматами, которые должны быть включены в регулярное выражение
  const formats = Object.values(EXTENSION)

  // Создаем регулярное выражение, объединяя массивы форматов
  const formatRegex = new RegExp(`\\.(${formats.join('|')})$`, 'i')

  // Проверяем, соответствует ли URL регулярному выражению
  if (formatRegex.test(url)) {
    // Извлекаем формат из URL
    const match = url.match(formatRegex)
    if (match) {
      // Возвращаем только формат, без точки в начале
      const result = match[0].replace(/^\./, '')

      return isString(result) && result.toLowerCase()
    }
  }

  // Если формат не найден, возвращаем null или другое значение по умолчанию
  return null
}

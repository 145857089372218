<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M8.403 18.81v-8.455H6v-2.73h2.403V5.61C8.403 3.273 9.857 2 11.982 2c1.017 0 1.892.075 2.147.108V4.55h-1.474c-1.155 0-1.379.54-1.379 1.33v1.744h2.756l-.359 2.731h-2.397v8.453H8.403Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-fb',
}
</script>

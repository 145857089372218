import { vueEmit } from '@ga/shared-browser'

import { ATTRIBUTE } from '../scroll-lock-scrollable'

const handlers = new WeakMap()

function addListener(el, vnode) {
  // Т.к. мы используем scroll-lock для всех элементов со скроллом,
  // нет смысла находить такие элементы по overflow и scrollHeight
  const scrollable = el.closest(`[${ATTRIBUTE}]`)

  if (!scrollable) {
    return
  }

  const handler = (event) => vueEmit(vnode, 'closest-scroll', event)

  scrollable.addEventListener('scroll', handler)

  handlers.set(el, { handler, scrollable })
}

function removeListener(el) {
  const { handler, scrollable } = handlers.get(el) || {}

  if (scrollable) {
    scrollable.removeEventListener('scroll', handler)
  }
}

const closestScroll = {
  inserted(el, binding, vnode) {
    addListener(el, vnode)
  },

  componentUpdated(el, binding, vnode) {
    removeListener(el)
    addListener(el, vnode)
  },

  unbind(el) {
    removeListener(el)
  },
}

export { closestScroll }

import { apiMock } from '../mocks/api'

const INCLUDE_MOCK = false

export class ShareRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getHash(data = {}) {
    const { url } = this.endpoints.sharingHash()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.fetchSharingHash({ url, req: data })
      }

      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getList(params = {}) {
    const { url } = this.endpoints.sharingList()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.fetchSharingList({ url, req: { params } })
      }

      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

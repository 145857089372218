import { analytics } from './analytics'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export {
  HEADER_DESKTOP_BREAKPOINT,
  TABLET_LANDSCAPE_BREAKPOINT,
  HEADER_HEIGHT_FULL_DESKTOP,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_TABLET,
  HEADER_HEIGHT_MOBILE,
  HEADER_EXTENSTION_TRANSITOIN_DURATION,
  HEADER_EXTENSTION_TRANSITOIN_TIMING_FUNCTION,
} from './constants'

export { useHeaderProps } from './composable/use'

export const header = {
  meta: {
    name: 'header',
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

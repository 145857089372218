import { KEYS, VALUES } from '../../constants/filters'

export const getters = (gaApp) => ({
  hasFilters() {
    return this.media || this.stars?.length
  },

  hasFiltersValue() {
    return this.value[KEYS.MEDIA] || this.value[KEYS.FILTERS]?.length > 0
  },

  // формируем список фильтров с настройками
  list() {
    if (!this.hasFilters) {
      return []
    }

    const filters = [
      {
        key: KEYS.ALL,
        value: VALUES.ALL,
        props: {
          label: gaApp.i18n.t('review.listing.filters.all'),
          checked:
            this.value[KEYS.FILTERS].length === 0 && !this.value[KEYS.MEDIA],
        },
      },
    ]

    if (this.media) {
      filters.push({
        key: KEYS.MEDIA,
        value: VALUES.WITH_MEDIA,
        props: {
          checked: this.value[KEYS.MEDIA],
          label: gaApp.i18n.t('review.listing.filters.withMedia'),
        },
      })
    }

    this.stars.forEach((star, index) => {
      filters.push({
        key: KEYS.FILTERS,
        icon: 'ga-icon-review-star',
        value: star,
        props: {
          label: star,
          checked: this.value[KEYS.FILTERS].includes(star),
        },
      })
    })

    return filters
  },
})

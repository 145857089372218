<template>
  <svg fill="none" stroke="none" viewBox="0 0 64 64">
    <path
      fill="#575EAA"
      fill-rule="evenodd"
      d="m14.092 44.24 5.362-9.453v-5.773l-5.371-9.47.009 24.696Z"
      clip-rule="evenodd"
    />
    <path
      fill="#DA195A"
      fill-rule="evenodd"
      d="m39.73 22.524-5.031 3.043v6.214l15.324-9.264-10.294.007Z"
      clip-rule="evenodd"
    />
    <path
      fill="#FBB42D"
      fill-rule="evenodd"
      d="M29.317 10.02v18.743l5.382 3.262-.028-12.537-5.354-9.468Z"
      clip-rule="evenodd"
    />
    <path
      fill="#F37632"
      fill-rule="evenodd"
      d="m34.67 19.488 5.06 3.036 10.294-.007L29.316 10.02l5.354 9.468Z"
      clip-rule="evenodd"
    />
    <path
      fill="#5FBA46"
      fill-rule="evenodd"
      d="m29.319 53.768 5.38-9.475v-6.084l-5.381-3.2.001 18.759Z"
      clip-rule="evenodd"
    />
    <path
      fill="#1289CA"
      fill-rule="evenodd"
      d="m19.454 29.014 20.263 12.264 10.286-.012-35.92-21.723 5.37 9.47Z"
      clip-rule="evenodd"
    />
    <path
      fill="#0B8341"
      fill-rule="evenodd"
      d="M39.717 41.278 34.7 44.293l-5.38 9.475 20.684-12.502-10.286.012Z"
      clip-rule="evenodd"
    />
    <path
      fill="#90509F"
      fill-rule="evenodd"
      d="m19.454 34.788-5.362 9.452 15.268-9.23-5.134-3.108-4.772 2.886Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-qr-logo-sbp',
}
</script>

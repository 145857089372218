import { TYPE } from '../../constants'
import { GtmGa4ProductMediaModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ProductCardMediaBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map()

    models.set(TYPE.GTM_4, GtmGa4ProductMediaModel)

    super(models, data)
  }
}

import { ACTIONS } from '../../../constants'
import { MindboxProductModel } from '../mindbox-product.model/mindbox-product.model'

export class MindboxModel {
  constructor({ id }, country) {
    this.operation = ACTIONS.VIEW.MINDBOX[country]

    this.data = {
      viewProduct: new MindboxProductModel({ id }),
    }
  }
}

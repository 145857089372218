import mitt from 'mitt'

const emitter = mitt()

export class EventBusService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  on(...args) {
    emitter.on(...args)
  }

  off(...args) {
    emitter.off(...args)
  }

  emit(...args) {
    emitter.emit(...args)
  }

  clear() {
    emitter.all.clear()
  }
}

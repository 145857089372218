import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

export const useBanners = (navActive) => {
  const { $gaApp } = useContext()

  const bannersTablet = computed(() => {
    return $gaApp.services.header.nav.getBannersTablet()?.items
  })

  const bannersTabletActive = computed(() => {
    return (
      unref(navActive) &&
      $gaApp.stores.header.nav.expandedNavItemsIds?.length === 1
    )
  })

  return {
    bannersTablet,
    bannersTabletActive,
  }
}

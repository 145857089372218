import { PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    const { maskedId } = redirect

    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.THANK_YOU:
          return this.gaApp.services.checkout.api.getThankYouData({ maskedId })

        default:
          return undefined
      }
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }
}

export const couponFixed = {
  type: 'object',
  properties: {
    code: { type: 'string', required: true },
    type: { type: 'string', required: true },
    discountPercent: { type: 'number', required: true },
    linkCoupon: { type: 'string', required: false },
  },
  additionalProperties: false,
}

import { repositories } from './repositories'
import { services } from './services'

export { usePlacements } from './composable'
export * from './constants'

export const placements = {
  meta: {
    name: 'placements',
  },
  layers: {
    services,
    repositories,
  },
}

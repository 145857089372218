import { NuxtClient } from '~/init/client'
import { NuxtSoon } from '~/init/soon'
import { NuxtWebview } from '~/init/webview'

export default async (context) => {
  // пытаемся найти инфу про вебвью
  const isWebview =
    context.$gaApp.route.query.webview ||
    context.$gaApp.cookies.get('ga-webview') ||
    context.$gaApp.services.app.browserStorage.local.get('ga-webview')

  // проверяем, если это прод ae
  const [hostName, hostDomain] = window.location.host.split('.')
  const isMainDomain = hostName === 'goldapple'
  const isMainDomainAe = hostDomain === 'ae'
  const isProdAe = isMainDomain && isMainDomainAe
  const isSoonPath = window.location.pathname.startsWith('/soon')
  const isSoonInit = isProdAe && isSoonPath

  // для прода ae запускаем урезанный инит для запуска soon лендинга
  const client = isSoonInit
    ? new NuxtSoon(context.$gaApp)
    : isWebview
      ? new NuxtWebview(context.$gaApp)
      : new NuxtClient(context.$gaApp)

  await client.init()
}

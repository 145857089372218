<template>
  <svg fill="none" stroke="none" viewBox="0 0 24 24">
    <path fill="#000" d="M2.5 7.25h19v1.5h-19v-1.5Z" />
    <path fill="#000" d="M2.5 15.25h19v1.5h-19v-1.5Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-24-menu',
}
</script>

import { toRefs } from 'vue'

import { PIXEL_IMAGE } from '@ga/constants/pixel'

import { GaImage } from '../image'

import { useImageResponsive } from './scripts/use'

export default {
  name: 'ga-image-responsive',

  inheritAttrs: false,

  props: {
    image: {
      type: Object,
      required: true,
    },

    fallbackImage: {
      type: String,
      default: PIXEL_IMAGE,
    },

    lazy: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    GaImage,
  },

  setup(props) {
    const { image, fallbackImage } = toRefs(props)

    return useImageResponsive(image, fallbackImage)
  },
}

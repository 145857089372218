import get from 'lodash/get'

import { KEYS } from '../../../constants/form'
import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp
    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
    }
  }

  get reviewProductId() {
    return this.getStoreData(['products', KEYS.ITEM_ID])
  }

  get listingProductId() {
    return this.gaApp.stores.review.listing.productId
  }

  get filtersValue() {
    return this.gaApp.stores.review.filters.value
  }

  get previewProductId() {
    return this.gaApp.stores.review.preview.productId
  }

  getFormData(...key) {
    return this.getStoreData(['form', 'data', ...key])
  }

  getStoreData(path) {
    return get(this.gaApp.stores.review, path, null)
  }

  /**
   * Отправляем аналитические данные из билдеров в соответствующие метрики.
   */
  send(Builders, data) {
    new Builders(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

import { GROUP_ADDITIONAL_IDS, MESSENGERS_ICONS } from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get state() {
    return this.gaApp.stores.footer.main
  }

  get messengersList() {
    const list = Object.entries(this.state.contacts)

    return list.map(([key, messenger]) => {
      const href = messenger.href
      const text = this.gaApp.isRtl
        ? this.gaApp.i18n.t(`footer.contacts.writeTo.${key}`)
        : messenger.text

      const icon = this.gaApp.isRtl ? null : MESSENGERS_ICONS[key]

      return {
        id: key,
        href,
        text,
        icon,
      }
    })
  }

  get groupsList() {
    const additionalList = [
      {
        id: GROUP_ADDITIONAL_IDS.COOKIES,
        text: this.gaApp.i18n.t('footer.groups.about-us.list.cookies.text'),
        href: '',
        nuxt: false,
        hide: !this.gaApp.features.get('modalCookiesPermission'),
      },
      {
        id: GROUP_ADDITIONAL_IDS.DEBUG_PANEL,
        text: this.gaApp.i18n.t(
          'footer.groups.about-us.list.debug-toggle.text',
        ),
        href: '',
        nuxt: false,
        hide: !this.gaApp.stores.debugPanel.main.debugMode,
      },
    ]

    const isBlank = (link) => {
      return link.includes('http')
    }

    // Добавляем дополнительные элементы для кнопок кукис и дебаг панели
    const groups = this.state.groups?.map((item, index) => {
      if (index === 0) {
        additionalList.forEach((additionalLink) => {
          const inList = item.list.find((link) => link.id === additionalLink.id)

          if (!inList) {
            item.list.push(additionalLink)
          }
        })
      }

      return item
    }) || [{ list: additionalList }]

    // Добавляем target и фильтруем ссылки по флагу hide
    const updatedGroups = groups.map((item) => {
      item.list = item.list
        .map((item) => ({
          ...item,
          target: isBlank(item.href) && '_blank',
        }))
        .filter((item) => !item.hide)

      return item
    })

    return updatedGroups
  }

  setData(data) {
    this.state.setData(data)
  }
}

<template>
  <svg viewBox="0 0 15 15">
    <circle cx="6.5" cy="6.5" r="6" fill="none" />
    <path fill="none" d="m14 14-3-3" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-plp-filter-search',
}
</script>

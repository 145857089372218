import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'

export const isValidImageProp = (image) => {
  if (!isPlainObject(image) || isEmpty(image)) {
    return false
  }

  return true
}

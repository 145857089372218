import { EVENT } from '../../constants'

export class DigiAnalyticsViewModel {
  constructor({ brandName, breadcrumbs, pageProducts }) {
    this.data = {
      eventType: EVENT.DIGI.view,
      brandName,
      breadcrumbs,
      pageProducts,
    }
  }
}

import pkg from '../../package.json'

export class NuxtBase {
  constructor(gaApp) {
    this.gaApp = gaApp

    globalThis.appConfig = {
      version: pkg.version,
    }
  }

  setLocale() {
    this.gaApp.stores.app.main.locale = this.gaApp.i18n.locale
    this.gaApp.stores.app.main.locales = this.gaApp.i18n.locales
  }
}

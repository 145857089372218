import { SIZE } from './scripts/const'

// @vue/component
export default {
  name: 'ga-radio',

  inheritAttrs: false,

  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },
}

import { ACTION_TYPES, ACTIONS, EVENT_CATEGORIES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4SearchQueryEnteringModel extends GtmBaseModel {
  constructor() {
    super({
      event: ACTIONS.USER_INTERACTION.GA4,
      eventCategory: EVENT_CATEGORIES.BRANDS_LIST,
      actionType: ACTION_TYPES.ENTERING_SEARCH_PHRASE,
    })
  }
}

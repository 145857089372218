import { ACTIONS } from '../../../constants'

import { MindboxProductModel } from '../mindbox-product.model/mindbox-product.model'

export class MindboxFavoriteModel {
  constructor({ id, amount }, country) {
    this.operation = ACTIONS.FAVORITE.MINDBOX[country]

    this.data = {
      addProductToList: new MindboxProductModel({ id, amount })
    }
  }
}

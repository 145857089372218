export class CityModalService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.close()
    })
  }

  open() {
    this.gaApp.stores.giftCardsB2b.plasticNew.isCityModalOpen = true
  }

  close() {
    this.gaApp.stores.giftCardsB2b.plasticNew.isCityModalOpen = false
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke-width="1.5" d="M11 9 7.5 5.5 4 9" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-home-simple-arrow-top',
}
</script>

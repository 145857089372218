<template>
  <svg fill="none" viewBox="0 0 153 50">
    <path
      stroke="inherit"
      stroke-width="inherit"
      d="M151.76 38.75v-.875H131.6v6.16h-29.05c.288-.313.772-.686 1.582-1.103 1.864-.961 5.239-2.029 11.199-3.04l11.189-1.888h.005c9.386-1.472 15.494-3.277 19.259-5.894 1.906-1.325 3.224-2.865 4.056-4.675.828-1.8 1.15-3.811 1.15-6.045v-.28c0-4.479-1.362-8.841-6.233-12.02-4.778-3.118-12.831-5.025-26.002-5.025h-3.01c-14.279 0-22.276 3.117-26.7 7.253-4.444 4.157-5.115 9.189-5.115 12.592v1.365h21.993l.055-.817c.065-.974.247-2.373 1.655-3.575 1.448-1.237 4.347-2.398 10.212-2.398 4.959 0 7.668.407 9.113.972.706.277 1.038.564 1.202.79.155.217.22.462.22.793 0 .345-.04.552-.121.715-.077.156-.236.36-.635.604-.848.521-2.521 1.076-5.813 1.741-2.745.493-6.812 1.335-10.098 2.034-13.65 2.892-20.86 7.442-24.634 12.307-3.783 4.879-3.99 9.927-3.99 13.469v6.965h68.671V38.75Zm-72.685-5.81v-2.1c0-4.918-1.148-11.654-6.495-17.156-5.35-5.505-14.752-9.619-30.92-9.619h-3.15c-16.203 0-25.622 4.113-30.98 9.618-5.356 5.502-6.505 12.238-6.505 17.157v2.1c0 4.85 1.15 11.618 6.501 17.171 5.358 5.558 14.777 9.744 30.984 9.744h3.15c16.173 0 25.574-4.186 30.923-9.745 5.343-5.552 6.492-12.32 6.492-17.17Zm-22.4-1.68v.91c0 2.553-.8 5.467-3.201 7.748-2.403 2.282-6.532 4.047-13.424 4.047-6.887 0-11.035-1.797-13.457-4.107-2.424-2.31-3.238-5.245-3.238-7.758v-.84c0-2.525.75-5.343 3.123-7.54 2.38-2.203 6.526-3.905 13.572-3.905 6.9 0 11.033 1.7 13.434 3.908 2.395 2.202 3.191 5.024 3.191 7.537Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-number-2',
}
</script>

export const GaModuleFiltersToggle = () =>
  import(
    /* webpackChunkName: 'modules/filters/toggle' */
    './molecules/filters-toggle'
  ).then(({ GaFiltersToggle }) => GaFiltersToggle)

export const GaModuleFiltersModal = () =>
  import(
    /* webpackChunkName: 'modules/filters/modal-filters' */
    './organisms/modal-filters'
  ).then(({ GaModalFilters }) => GaModalFilters)

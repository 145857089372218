export const getVideoSliderStateInitial = () => ({
  data: {
    items: [],
    name: null,
    autoplay: false,
    isForAdults: false,
  },
})

export default () => {
  return getVideoSliderStateInitial()
}

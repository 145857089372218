/*
 * Добавляет в компонент метод, чтобы использовать его в шаблонах.
 * Метод использует `bem-cn-lite` – генератор имен классов по методологии БЭМ.
 * Применение - const b = useBem()
 * Документация: https://github.com/mistakster/bem-cn-lite#readme
 */

import block from 'bem-cn-lite'

import { getCurrentInstance } from 'vue'

import { getCssModulesClassNames } from './getCssModulesClassNames'

export const useBem = (userOptions) => {
  const instance = getCurrentInstance()?.proxy

  let options = { blockName: null }

  // предполагаем, что передано только имя блока
  if (typeof userOptions === 'string') {
    options.blockName = userOptions
  }

  options = { ...options, ...userOptions }

  const applyFunc = () => {
    const { $style, $options } = instance
    const name = options.blockName || $options.name

    // кешируем вызов функции block
    const cacheFuncBlock = block(name)

    return (...args) => {
      const classNames = cacheFuncBlock.apply(null, args)

      if ($style) {
        return getCssModulesClassNames($style, classNames)
      }

      return classNames
    }
  }

  return applyFunc()
}

<template>
  <svg viewBox="0 0 10 11">
    <path stroke="inherit" d="M1.336 5.503h7.333M5.003 9.169V1.836" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-product-slider-plus',
}
</script>

import mockReviewCard from '../card/mock-card.json'
import filtersMock from '../filters/filters.json'
import galleryMock from '../gallery/gallery.json'
import mainReviewsMock from '../main-reviews/reviews-mock.json'
import previewMock from '../preview/preview-mock.json'
import productStatistics from '../product-statistic/statistics.json'
import productsMock from '../products-for-review/products.json'
import { mockMedia } from '../uploadmedia/mock-image'

const mockPromise = (data, timeout = 500) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data)
    }, timeout)
  })
}
export const apiMock = {
  uploadMedia: async ({ url, req = null, res = mockMedia() }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  getProducts: async ({ url, req, res = { data: productsMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  getMainReviews: async ({ url, req, res = { data: mainReviewsMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  getPreviewReviews: async ({ url, req, res = { data: previewMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  getStatistic: async ({ url, req, res = { data: productStatistics } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  fetchGallery: async ({ url, req = null, res = { data: galleryMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
  getFilters: async ({ url, req, res = { data: filtersMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },

  getReview: async ({ url, req, res = { data: mockReviewCard } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },

  getProductsVariants: async ({ url, req, res = { data: productsMock } }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
}

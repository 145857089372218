// This is a mock version because mindbox module is disabled
// You can explicitly enable module using `midnbox.enabled: true` in nuxt.config
import { log } from './eyezon.utils'

const initConfig = {
  businessId: 'MOCK_ID'
}
const autoInit = false;

export default function (context, inject) {
    log('Using mocked API. Real EYEZON events will not be reported.')

    const eyezon = {
        init: (payload) => {
            log('init', payload)
        },
        get eyeZon() {
          return (...event) => log('event', ...event)
        }
    }

    if (autoInit) {
        eyezon.init(initConfig)
    }

    context.$eyezon = eyezon.eyeZon

    inject('eyezon', context.$eyezon)
}

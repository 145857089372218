import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4CouponRemoveModel extends GtmBaseModel {
  constructor({ coupon }) {
    super({ event: EVENT.COUPON.GA4 })

    this.event_category = 'cart'
    this.action_type = 'click_promocode_remove'
    this.coupon_code = coupon.value
  }
}

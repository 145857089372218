import { ApmVuePlugin } from '@elastic/apm-rum-vue'

import Vue from 'vue'

import { config } from '~/config'

export default (context, inject) => {
  const apmConfig = {
    serverUrl: window.location.protocol + '//' + window.location.host,
    environment: window.location.host,
    ...config.get('apm'),
  }

  /**
   * При загрузке страницы устанавливаем trace ID полученную от APP
   * @link https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/configuration.html#page-load-trace-id
   */
  if (window.serverCache?.traceparent) {
    apmConfig.pageLoadTraceId = window.serverCache.traceparent.split('-')?.[1]
  }

  Vue.use(ApmVuePlugin, {
    config: apmConfig,
  })
  inject('apm', Vue.prototype.$apm)
}

import { TickerClickBuilder, TickerViewBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class TickerAnalytics extends BaseController {
  onTickerClick() {
    const currentTicker = this.gaApp.services.ticker.main.getCurrentTicker()

    if (currentTicker && this.isTickerClickable(currentTicker)) {
      this.send(TickerClickBuilder, this.getNormalizedData(currentTicker))
    }
  }

  onTickerView() {
    const currentTicker = this.gaApp.services.ticker.main.getCurrentTicker()

    if (currentTicker && !this.isTickerViewed(currentTicker)) {
      this.setTickerAsViewed(currentTicker)
      this.send(TickerViewBuilder, this.getNormalizedData(currentTicker))
    }
  }
}

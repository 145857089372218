export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        // Статьи
        case 'articles':
          return await this.gaApp.services.articles.listing.loadData()

        case 'preview-listing':
          return await this.gaApp.services.articles.listing.loadPreviewListing(
            redirect.id,
          )

        case 'article':
          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.one.fetchArticleByUrl(
            redirect.url,
          )

        case 'preview':
          return await this.gaApp.services.articles.one.fetchPreviewArticle(
            redirect.id,
          )

        case 'articles-filter':
          return await this.gaApp.services.articles.listing.loadFilterArticles(
            redirect,
          )

        // Стримы
        case 'streams':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.streams.loadData()

        case 'streams-preview':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.streams.loadPreviewListingData(
            redirect.id,
          )

        case 'stream':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return this.gaApp.services.articles.stream.fetchDataByUrl(
            redirect.url,
          )

        case 'stream-live':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          this.gaApp.stores.articles.streamLive.externalId = redirect.externalId
          return

        case 'stream-preview':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.stream.fetchDataById(
            redirect.id,
          )

        case 'streams-filter':
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          return await this.gaApp.services.articles.streams.fetchStreamsFilterListing(
            redirect,
          )

        default:
          return undefined
      }
    } catch (error) {
      this.redirectError(error)
    }
  }

  redirectError(error = null) {
    if (error.statusCode >= 500) {
      return this.gaApp.redirectError({
        statusCode: 500,
        message: error.message,
      })
    }

    return this.gaApp.redirectError({
      statusCode: 404,
      message: error.message,
    })
  }
}

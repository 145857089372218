import { useContext } from '@nuxtjs/composition-api'

import { onMounted, ref, unref } from 'vue'

export const useAnimating = (active, canvasRef, innerRef) => {
  const { $gaApp } = useContext()

  const animating = ref(false)

  const isValidProp = (event) => {
    return event.propertyName === 'transform'
  }

  const onTransitionStart = (event) => {
    if (unref(animating) || !isValidProp(event)) {
      return
    }

    const isInnerElement = event.target === unref(innerRef)

    if (isInnerElement) {
      animating.value = true
    }
  }

  const onCanvasTransitionEnd = () => {
    const isNavClosed = !unref(active)

    if (isNavClosed) {
      $gaApp.services.header.nav.closeNavItems()
    }
  }

  const onTransitionEnd = (event) => {
    if (!unref(animating) || !isValidProp(event)) {
      return
    }

    const isCanvasElement = event.target === unref(canvasRef)
    const isInnerElement = event.target === unref(innerRef)

    if (isInnerElement) {
      animating.value = false
    }

    if (isCanvasElement) {
      onCanvasTransitionEnd(event)
    }
  }

  onMounted(() => {
    unref(canvasRef).addEventListener('transitionstart', onTransitionStart)
    unref(canvasRef).addEventListener('transitionend', onTransitionEnd)
  })

  return {
    animating,
  }
}

<template>
  <svg fill="none" viewBox="0 0 21 21">
    <path
      stroke="#000"
      stroke-width="1.5"
      d="m13.9 6.203 3.6 3.6-3.6 3.6m3.2-3.6H7.5"
    />
    <path stroke="#000" stroke-width="1.5" d="M11.3 18.602H2.5v-17.6h8.8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-exit',
}
</script>

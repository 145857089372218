import { minLength } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (length, message) => {
  return withMessage(minLength(length), {
    type: 'minLength',
    min: length,
    message: 'app.validation.minLength',
  })(message)
}

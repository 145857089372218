/**
 * Разбивает массив по чанкам
 * @param {Array<any>} arr - исходный массив
 * @param {number} [size] - размер чанка
 * @returns {Array<any[]>} - массив массивов
 */

export const chunkArray = (arr, size = 7) => {
  return Array.from({ length: Math.ceil(arr.length / size) })
    .fill(null)
    .map((_, i) => {
      return arr.slice(i * size, i * size + size)
    })
}

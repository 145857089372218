export const state = () => ({
  sets: {
    items: [],
    covers: [],
    rules: {
      maxSets: 0,
      minValue: 0,
      maxValue: 0,
      minCount: 0,
      maxCount: 0,
    },
  },
  coverSelector: {
    isOpen: false,
    editIndex: null,
    coverIndex: null,
  },
  felicitation: {
    form: {
      recipientName: '',
      text: '',
      senderName: '',
    },
    rules: {
      recipientNameLimit: 0,
      textLimit: 0,
      senderNameLimit: 0,
    },
    previewIsOpen: false,
  },
  counterparty: {
    form: {
      inn: '',
      agreed: false,
      approved: false,
    },
    info: {
      name: '',
      kpp: '',
      ognip: '',
      address: '',
    },
    rules: {
      maxInnLength: 0,
      minInnLength: 0,
    },
    isLoading: false,
    infoIsVisible: false,
  },
  contacts: {
    name: '',
    phone: '',
    email: '',
  },
  requestIdCache: {},
  steps: ['sets', 'felicitation', 'counterparty', 'contacts', 'checkout'],
})

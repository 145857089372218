import { ApiService } from './common/api.service'
import { AttributesServices } from './common/attributes.services/'
import { MainService } from './main/main.service'
import { PageService } from './main/page.service'
import { PreviewService } from './preview/preview.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  page: new PageService(gaApp),
  preview: new PreviewService(gaApp),
  api: new ApiService(gaApp),
  attributes: new AttributesServices(gaApp),
})

<template>
  <svg viewBox="0 0 3 17">
    <circle
      cx="1.5"
      cy="1.5"
      r="1.5"
      stroke="none"
      transform="rotate(90 1.5 1.5)"
    />
    <circle
      cx="1.5"
      cy="8.5"
      r="1.5"
      stroke="none"
      transform="rotate(90 1.5 8.5)"
    />
    <circle
      cx="1.5"
      cy="15.5"
      r="1.5"
      stroke="none"
      transform="rotate(90 1.5 15.5)"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-kebab',
}
</script>

import { KEY_CODES } from '@ga/constants/key-codes'

import { GaAction } from '@ga/ui-components/action'

import { GaImageResponsive } from '~/components/image-responsive'

const TOUCH_DELAY = 100

// @vue/component
export default {
  name: 'ga-header-nav-full-extenstion-list-item',

  components: {
    GaAction,
    GaImageResponsive,
  },

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isTouch: false,
      touchTimeout: null,
    }
  },

  computed: {
    style() {
      return (
        this.data.viewOptions?.textColor &&
        `--header-nav-item-color: ${this.data.viewOptions.textColor}`
      )
    },

    isParent() {
      return this.data.parent
    },

    isButton() {
      return !this.isLink
    },
  },

  methods: {
    onTouchStart() {
      this.isTouch = true

      clearTimeout(this.touchTimeout)
    },

    onTouchEnd() {
      clearTimeout(this.touchTimeout)

      this.touchTimeout = setTimeout(() => {
        this.isTouch = false
      }, TOUCH_DELAY)
    },

    onClick(event) {
      if (this.isParent) {
        event.preventDefault()
      }

      this.routeUpdate()

      this.$emit('item-click', this.data)
    },

    onKeyDown(event) {
      const { space, enter } = KEY_CODES
      const { keyCode } = event

      const isParent = this.data.parent
      const isSpaceOrEnterPressed = [space, enter].includes(keyCode)

      if (isParent && isSpaceOrEnterPressed) {
        event.preventDefault()
        event.stopPropagation()

        this.$emit('item-click-keyboard', this.data)
      }
    },

    onMouseEnter() {
      if (this.isTouch) {
        return
      }

      this.$emit('item-mouseenter', this.data)
    },

    onMouseLeave() {
      if (this.isTouch) {
        return
      }

      this.$emit('item-mouseleave', this.data)
    },

    routeUpdate() {
      const { isTouch } = this
      const { nuxt, link, parent: isItemParent } = this.data

      if ((isTouch && isItemParent) || !nuxt) {
        return
      }

      if (nuxt && link) {
        this.$gaApp.router.push(link)
      }
    },
  },
}

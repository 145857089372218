export const GaModuleCustomerLayout = () =>
  import(
    /* webpackChunkName: 'module/customer/GaModuleCustomerLayout' */
    './templates/layout/layout.vue'
  )
export const GaModuleCustomerSectionTitle = () =>
  import(
    /* webpackChunkName: 'module/customer/GaModuleCustomerSectionTitle' */
    './atoms/section-title/section-title.vue'
  )

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsBrandItemClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { url, label, itemListName } = data

    this.category = {
      url,
      itemListName: `${itemListName}/${label}`,
    }

    this.debug = {
      key: 'brands-item-click',
      data,
    }
  }
}

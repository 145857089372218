export const getters = (gaApp) => ({
  hasPages() {
    return Boolean(this.pages?.length)
  },

  pageNumberFirst() {
    return this.pages.length ? this.pages[0].number : 0
  },

  pageNumberLast() {
    return this.pages.length ? this.pages[this.pages.length - 1].number : 0
  },
})

import { GaAnalytics } from './ga-analytics.class'

// docs: https://lodash.com/docs/#template
const autoInit = false;

function getGaAnalytics() {
    const gaAnalytics = new GaAnalytics()

    return {
        create(api, endpoints, storage) {
            return gaAnalytics.create(api, endpoints, storage)
        },

        get product() {
            return gaAnalytics.product
        },

        get listing() {
            return gaAnalytics.listing
        },
    }
}

export default function (context, inject) {
    const $gaAnalytics = getGaAnalytics()

    if (autoInit) {
        $gaAnalytics.create(context.$api)
    }

    inject('gaAnalytics', $gaAnalytics)
}

import once from 'lodash/once'

import { computed, ref, toRefs, unref } from 'vue'

import { GaImageResponsive } from '../image-responsive'

import { useRenderedItems, useScrollable } from './scripts/composables'
import { SIZE_DOTS } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-touch-slider',

  components: {
    GaImageResponsive,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    sizeDots: {
      type: String,
      default: SIZE_DOTS.XXS,
    },
  },

  setup(props) {
    const rootRef = ref(null)
    const activeIndex = ref(0)

    const { items } = toRefs(props)

    const { renderedItems, renderedItemsSlot, renderRestItems } =
      useRenderedItems(items)
    const renderRestItemsOnce = once(renderRestItems)

    const onScroll = ({ target }) => {
      setCurrentSlide(target)

      renderRestItemsOnce()
    }

    const setCurrentSlide = (target) => {
      const { clientWidth, scrollLeft } = target

      activeIndex.value = Math.round(Math.abs(scrollLeft / clientWidth))
    }

    const { scrollable } = useScrollable(rootRef)

    const mods = computed(() => ({
      scrollable: unref(scrollable),
    }))

    return {
      mods,
      rootRef,
      onScroll,
      activeIndex,

      renderedItems,
      renderedItemsSlot,
    }
  },
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ShareCartProductModel } from '../gtm-ga4-share-cart-product.model/gtm-ga4-share-cart-product.model'

export class GtmGa4ShareCartOpenModel extends GtmBaseModel {
  constructor({ products }) {
    if (!products) {
      throw new Error('Products are required')
    }

    super({ event: EVENT.SHARE_CART_OPEN.GA4 })

    this.event_source = 'plaid_backend'
    this.item_list_name = 'share_cart'

    this.ecommerce = {
      items: products.map(
        (product) => new GtmGa4ShareCartProductModel(product),
      ),
    }
  }
}

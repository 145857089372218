import Countdown from '@chenfengyuan/vue-countdown'

import { computed, ref } from 'vue'

import { useCountdownApi } from './scripts/use/countdown-api'

// @vue/component
export default {
  name: 'ga-countdown',

  components: {
    Countdown,
  },

  inheritAttrs: false,

  setup() {
    const rootRef = ref(null)

    const counting = computed(() => rootRef.value.counting)

    const { start, abort, end } = useCountdownApi(rootRef)

    return { rootRef, counting, start, abort, end }
  },
}

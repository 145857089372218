import { EVENT_CATEGORIES } from '../../../constants'
import { GtmGa4UserInteraction } from '../gtm-ga4-user-interaction.model/user-interaction.model'

export class GtmGa4OrderInteraction extends GtmGa4UserInteraction {
  constructor({ actionType, screenType }) {
    super({
      event_category: EVENT_CATEGORIES.ORDER_INTERACTION,
      action_type: actionType,
      screen_type: screenType,
    })
  }
}

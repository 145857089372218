export class InfiniteScrollService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fetchProductsOnPageIntersect(pageNumber) {
    this.gaApp.services.filters.main.onPageNumberChange(pageNumber)

    const { pages, pagination } = this.gaApp.stores.purchased.plp

    const nextPage = pages.find((page) => page.number === pageNumber + 1)

    if (pagination.next.pending || pagination.prev.pending || nextPage) {
      return false
    }

    return this.gaApp.services.purchased.plp.fetchProductsNext()
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke="inherit" stroke-width="1.5" d="M2 7.5h11M7.5 13V2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-plus-bold',
}
</script>

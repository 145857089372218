/*
 * TODO:
 *  - Оптимизировать svg
 */

import { computed, toRefs } from 'vue'

// @vue/component
export default {
  name: 'ga-icon',

  props: {
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    rtlIgnore: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { width, height } = toRefs(props)

    const style = computed(() => {
      const widthInternal = width.value
        ? `${Number.parseInt(width.value, 10)}px`
        : null
      const heightInternal = height.value
        ? `${Number.parseInt(height.value, 10)}px`
        : null

      return { width: widthInternal, height: heightInternal }
    })

    const mods = computed(() => ({
      rtl: !props.rtlIgnore,
    }))

    return {
      mods,
      style,
    }
  },
}

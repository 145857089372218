<template>
  <svg fill="none" stroke="currentcolor" viewBox="0 0 15 15">
    <path stroke="inherit" stroke-width="1.25" d="m2 2 11 11M2 13 13 2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-cross-medium',
}
</script>

<template>
  <svg fill="none" stroke="none" viewBox="0 0 48 13">
    <path
      fill="#0F754E"
      fill-rule="evenodd"
      d="M4.407.002C4.833 0 6.098-.114 6.634 1.695c.361 1.218.936 3.214 1.726 5.988h.321c.846-2.924 1.428-4.92 1.744-5.988.542-1.828 1.896-1.693 2.437-1.693h4.179V13h-4.259V5.34h-.286L10.122 13H6.918L4.544 5.335H4.26V13H0V.002h4.407Zm18.75 0v7.666h.339l2.888-6.304C26.944.11 28.139.002 28.139.002h4.121V13h-4.347V5.335h-.34l-2.831 6.303C24.18 12.886 22.93 13 22.93 13h-4.122V.002h4.348Zm24.13 6.177c-.606 1.718-2.51 2.95-4.619 2.95h-4.56V13h-4.134V6.179h13.314Z"
      clip-rule="evenodd"
    />
    <path
      fill="url(#pay-methods-mir-pay-a)"
      fill-rule="evenodd"
      d="M42.867.002h-9.11c.217 2.894 2.71 5.371 5.289 5.371h8.529c.492-2.405-1.203-5.37-4.708-5.37Z"
      clip-rule="evenodd"
    />
    <defs>
      <linearGradient
        id="pay-methods-mir-pay-a"
        x1="47.658"
        x2="33.757"
        y1="3.381"
        y2="3.381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1F5CD7" />
        <stop offset="1" stop-color="#02AEFF" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-mir-pay',
}
</script>

import { TYPE } from '../../constants'
import { GtmGa4FilterStreamClickModel } from '../../models'
import { BaseBuilder } from '../index'

export class FilterStreamClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4FilterStreamClickModel]])
    super(models, data)
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 77 20">
    <rect
      width="77"
      height="20"
      fill="#fff"
      fill-opacity=".2"
      data-id="background"
      rx="10"
    />
    <path
      fill="#fff"
      d="m12 5 1.591 3.409L17 10l-3.409 1.591L12 15l-1.591-3.409L7 10l3.409-1.591L12 5Z"
      stroke="none"
    />
    <path
      fill="#fff"
      stroke="none"
      d="M25.44 10.7c1.155 0 1.771-.55 1.771-1.793V7.565c0-1.31-.582-1.75-1.77-1.75h-2.343V14h1.463v-3.3h.88Zm-.88-3.718h.518c.506 0 .681.198.681.858v.77c0 .682-.175.869-.681.869h-.517V6.982Zm4.772-1.166V14h3.663v-1.31h-2.233v-2.265h1.804v-1.31h-1.804V7.093h2.167V5.816h-3.597ZM38.087 14h1.496l-1.716-4.367 1.562-3.817h-1.43l-1.474 3.64v-3.64h-1.452V14h1.452v-3.916L38.087 14Zm6.023 0h1.464V5.816h-3.905c0 5.06-.011 5.423-.077 6.083-.066.583-.22.759-.495.759h-.088V14h.473c.946 0 1.309-.462 1.43-1.562.099-.902.11-2.74.11-5.357h1.089V14Zm6.54 0h1.408l-1.463-8.184h-1.826L47.394 14h1.287l.23-1.507h1.497L50.65 14Zm-1.254-4.565c.132-.88.22-1.496.264-1.958.033.462.11 1.056.253 1.958l.297 1.837h-1.1l.286-1.837Zm8.6.594c.22-1.1.352-1.815.473-2.497-.022.913-.044 1.672-.044 2.838V14h1.276V5.816h-1.925l-.56 2.915c-.188.957-.254 1.507-.353 2.123-.099-.616-.176-1.166-.363-2.123l-.56-2.915h-1.96V14h1.243v-3.63c0-1.166-.01-1.925-.033-2.838.121.704.242 1.386.473 2.574l.77 3.894h.77l.792-3.971ZM64.881 14h1.408l-1.463-8.184H63L61.625 14h1.287l.231-1.507h1.496L64.881 14Zm-1.254-4.565c.132-.88.22-1.496.264-1.958.033.462.11 1.056.253 1.958l.297 1.837h-1.1l.286-1.837Z"
    />
    <defs>
      <clipPath><path fill="#fff" d="M7 5h10v10H7z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-ad',
}
</script>

// @vue/component
export default {
  name: 'ga-app',

  head() {
    return {
      htmlAttrs: {
        dir: this.dir,
      },
      bodyAttrs: {
        ...this.mods,
      },
    }
  },

  computed: {
    focus() {
      return this.$gaApp.stores.app.main.focusSource
    },

    adult() {
      return this.$gaApp.stores.user.main.isAdult
    },

    hover() {
      return this.$gaApp.stores.app.main.withHover ? 'supported' : 'unsupported'
    },

    resizing() {
      return this.$gaApp.stores.app.window.windowResizing
    },

    lang() {
      return this.$gaApp.i18n.locale.code || 'ru'
    },

    dir() {
      return this.$gaApp.i18n.locale.dir || 'ltr'
    },

    webview() {
      return this.$gaApp.isWebview
    },

    mods() {
      const { focus, hover, resizing, adult, lang, dir, webview } = this

      return {
        'data-app': null,
        'data-focus': focus,
        'data-resizing': resizing,
        'data-hover': hover,
        'data-adult-blur': !adult,
        'data-i18n-lang': lang,
        'data-dir': dir,
        'data-webview': webview,
      }
    },
  },
}

import { Advcake } from './advcake.class'

const autoInit = true;
const _id = 'advcakeAsync'

function getAdvcake() {
  const advcake = new Advcake()

  return {
    create(id) {
      return advcake.create(id)
    },
    push(payload) {
      return advcake.push(payload)
    },
  }
}

export default function (context, inject) {
  const $advcake = getAdvcake()

  if (autoInit) {
    $advcake.create(_id)
  }

  inject('advcake', $advcake)
}

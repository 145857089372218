export const buildCorporativeLink = (
  defaultLink,
  { isNewCorporateLink = false, isNewPlastic = false } = {},
) => {
  if (isNewCorporateLink) {
    return '/b2b/cards/corporative?from=fiz'
  }

  if (isNewPlastic) {
    return '/b2b/cards/corporative/plastic'
  }

  return defaultLink
}

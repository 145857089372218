export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /*
   * Получение выбранного адреса с сессии | геолокации по ip | дефолтный город
   * мага
   * */
  async getData() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('location')

      const isAddressBlockEnabled =
        this.gaApp.stores.app.common.toggle.addressBlockEnabled

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.location.main.getData({
          addressBlockEnabled: isAddressBlockEnabled,
        }))

      const { location, rawLocation } = data

      if (!location || !rawLocation || !location.city) {
        const error = new Error('Ошибка получения локации')
        error.data = JSON.stringify(data || {})

        this.gaApp.services.app.apm.captureError(error)
        this.gaApp.services.location.main.setLocationDefault()
        return
      }

      this.gaApp.services.cache.main.saveLocation(rawLocation)
      // location.isSelectedAddress = true
      this.gaApp.services.location.main.setLocation(location)
      this.gaApp.services.location.cache.saveMigrateSelectedAddress(location)
    } catch (error) {
      this.gaApp.services.location.main.setLocationDefault()

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.get'),
      )
    }
  }

  /*
   * Смена локации(города) для стран с выключенным адресным блоком
   * мага
   * */
  async updateData(id) {
    try {
      const { data } = await this.gaApp.repositories.location.main.setData({
        id,
      })
      const { location, rawLocation } = data

      if (!location || !rawLocation || !location.city) {
        const error = new Error('Ошибка смены локации')
        error.data = JSON.stringify(data || {})

        this.gaApp.services.app.apm.captureError(error)

        return
      }

      this.gaApp.services.location.main.setLocation(location)
      this.gaApp.services.location.cache.saveMigrateSelectedAddress(location)
      this.gaApp.services.cache.main.saveLocation(rawLocation)

      this.gaApp.services.location.confirm.saveClientLocation(location.id)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.set'),
      )
      throw error
    }
  }

  /**
   * Смена адреса
   * Мага, не используется
   */
  async setData(id) {
    try {
      await this.gaApp.repositories.location.main.setData({ id })
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.set'),
      )
      throw error
    }
  }

  /*
   * Запрос подсказок для саджеста по городам
   * мага
   * */
  async getSearchCitiesData(query) {
    // мага
    const params = {
      query,
      country: this.gaApp.i18n.locale.country,
      from: 'city',
      to: 'settlement',
      count: 20,
    }

    // плейд
    const paramsV2 = {
      query,
      type: 'city',
    }

    try {
      const { data } = this.gaApp.features.get(
        'addressSeparateSuggestGeocodeRequests',
      )
        ? await this.gaApp.repositories.location.main.geoSuggest(paramsV2)
        : await this.gaApp.repositories.location.main.getSearch(params)

      this.gaApp.services.location.search.setItems(data)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос подсказок для саджеста по улицам
   * */
  async getSearchStreetsData(street) {
    const suggestSeparated = this.gaApp.features.get(
      'addressSeparateSuggestGeocodeRequests',
    )

    if (suggestSeparated) {
      await this.suggestStreets(street)
    } else {
      await this.getSearchStreetsDataV1(street)
    }
  }

  async getSearchStreetsDataV1(street) {
    const location =
      this.gaApp.stores.location.specify.locationTemp ||
      this.gaApp.services.location.main.getDeliveryAddress()

    /*
     * Добавили регион и город в квери потому что:
     * ручка под капотом использует не только дадату (РФ, БЛ), но и гугл (КЗ, Катар)
     * ручка используется только в адресном блоке
     * адресный блок включен только в РФ, но планируется и в других странах
     * иначе эта доработка не нужна
     * */
    const query =
      this.gaApp.services.location.formatter.formatStreetSuggestQueryV1(
        location,
        street,
      )

    const params = {
      query,
      country: this.gaApp.i18n.locale.country,
      from: 'street',
      to: 'house',
      count: 20,
      cityId: location.id,
      cityName: location.city,
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.getSearch(params)

      this.gaApp.stores.location.search.streets.items = data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  async suggestStreets(street) {
    const location =
      this.gaApp.stores.location.specify.locationTemp ||
      this.gaApp.stores.location.main.location

    const query =
      this.gaApp.services.location.formatter.formatStreetSuggestQueryV2(
        location,
        street,
      )

    const { cityName, regionName } =
      this.gaApp.services.location.formatter.formatStreetSuggestFilterParams(
        location,
      )

    /*
     * плейд
     * cityName и regionName нужно передавать как отдельными полями, так и в query
     * это нужно для единообразного формата по всем странам
     * сейчас ориентир больше на query
     * отдельный параметр cityName используется для фильтрации подсказок по городу/поселению
     * */
    const params = {
      query,
      type: 'street',
      cityName,
      regionName,
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoSuggest(params)

      this.gaApp.stores.location.search.streets.items = data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации после выбора подсказки с городом
   * */
  async geocodeCities(objectId, fullAddress) {
    const params = {
      objectId,
      type: 'city',
      query: fullAddress,
    }

    try {
      if (!this.gaApp.features.get('addressSeparateSuggestGeocodeRequests')) {
        return
      }

      const { data } =
        await this.gaApp.repositories.location.main.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации после выбора подсказки с улицей
   * */
  async geocodeStreets(objectId, fullAddress) {
    const location =
      this.gaApp.stores.location.specify.locationTemp ||
      this.gaApp.stores.location.main.location

    const { cityName, regionName } =
      this.gaApp.services.location.formatter.formatStreetSuggestFilterParams(
        location,
      )

    /*
     * fullAddress передаем с ранее полученной подсказки
     * чтобы решить проблемы с точностью и бэк вернул корректный ответ
     * */
    const params = {
      objectId,
      type: 'street',
      query: fullAddress,
      cityName,
      regionName,
    }

    try {
      if (!this.gaApp.features.get('addressSeparateSuggestGeocodeRequests')) {
        return
      }

      const { data } =
        await this.gaApp.repositories.location.main.geoGeocode(params)

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.search.error.default'),
      )
      throw error
    }
  }

  /*
   * Запрос локации по координатам
   * */
  async getDataByCoordinates(...params) {
    const suggestSeparated = this.gaApp.features.get(
      'addressSeparateSuggestGeocodeRequests',
    )

    return suggestSeparated
      ? await this.reverseGeocode(...params)
      : await this.getDataByCoordinatesV1(...params)
  }

  /*
   * Запрос локации по координатам в магу
   * */
  async getDataByCoordinatesV1(
    coordinates,
    requestParams,
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.getDataByCoordinates(
          coordinates,
          requestParams,
        )

      return data
    } catch (error) {
      if (hasAlertError) {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('location.geolocation.error.default'),
        )
      }

      throw error
    }
  }

  /*
   * Запрос локации по координатам в плейд
   * */
  async reverseGeocode(
    coordinates,
    requestParams,
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.geoReverseGeocode(
          {
            useDefaultCity: true, // вернет дефолтный город, если нет ответа от дадаты по координатам
            ...coordinates,
          },
          requestParams,
        )

      return data
    } catch (error) {
      if (hasAlertError) {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('location.geolocation.error.default'),
        )
      }

      throw error
    }
  }

  async getGeolocation(
    { longitude, latitude, abortKey },
    { hasAlertError } = { hasAlertError: true },
  ) {
    try {
      this.gaApp.services.location.geolocation.setPending(true)
      const data = await this.gaApp.services.location.api.getDataByCoordinates(
        {
          lon: longitude,
          lat: latitude,
        },
        { abortKey },
        { hasAlertError },
      )

      if (!data?.id) {
        throw new Error('Ошибка')
      }

      return data
    } catch (error) {
      // внутри getDataByCoordinates уже есть уведомление, если словили ошибку

      return null
    } finally {
      this.gaApp.services.location.geolocation.setPending(false)
    }
  }

  /**
   * Смена адреса
   * @param {object} data - данные адреса
   */
  async setAddress(data) {
    this.gaApp.features.get('addressSelectAddressToPlaid')
      ? await this.setAddressPlaid(data)
      : await this.setAddressMagento(data)
  }

  /**
   * Смена адреса
   * мага
   */
  async setAddressMagento(data) {
    const address = {
      id: data.saveId,
      addressId: data.addressId || data.exactId || data.id,
      dadataJson: data.row ? JSON.stringify(data.row) : null,
      addressLatitude: data.latitude,
      addressLongitude: data.longitude,
    }

    try {
      const { data } = await this.gaApp.repositories.location.main.setAddress({
        address,
      })

      const { addresses, selectedAddress } = data

      if (addresses) {
        this.gaApp.stores.user.main.addresses = addresses
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
        this.gaApp.services.location.cache.saveMigrateSelectedAddress(
          selectedAddress,
        )
      }
    } catch (error) {}
  }

  /**
   * Смена адреса
   * плед
   * если авторизован - бэк записывает адрес
   * если неавторизован - бэк обогащает адрес, а адрес хранится на клиенте
   */
  async setAddressPlaid(data) {
    const address = {
      id: data.saveId,
      addressId: data.addressId || data.exactId || data.id,
      dadataJson: data.row ? JSON.stringify(data.row) : null,
    }

    if (data.latitude && data.longitude) {
      address.addressLatitude = data.latitude
      address.addressLongitude = data.longitude
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.main.setAddressV2(address)

      const { addresses, selectedAddress } = data || {}

      if (addresses) {
        this.gaApp.stores.user.main.addresses = addresses
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
        this.gaApp.services.location.cache.saveGuestSelectedAddress(
          selectedAddress,
        )
      }

      if (
        selectedAddress &&
        !this.gaApp.stores.app.common.toggle.addressBlockEnabled
      ) {
        this.gaApp.services.location.confirm.saveClientLocation(
          selectedAddress.id,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Получение геолокации по ip | дефолтный город
   * плед
   */
  async getLocationByIp() {
    try {
      const { data } =
        await this.gaApp.repositories.location.main.getLocationByIp()

      const { location } = data || {}

      if (!location.city || !location.id) {
        const error = new Error('Ошибка получения локации по ip')
        error.data = JSON.stringify(data || {})

        this.gaApp.services.app.apm.captureError(error)
        this.gaApp.services.location.main.setLocationDefault()
        return
      }

      return location
    } catch (error) {
      this.gaApp.services.location.main.setLocationDefault()

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.error.get'),
      )
    }
  }

  async getCitiesModalData() {
    const { data } =
      await this.gaApp.repositories.location.retailStores.getCitiesModalData()

    if (!data?.cities) {
      throw new Error('Список c городами пуст')
    }

    return data
  }
}

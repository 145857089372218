<template>
  <svg fill="none" viewBox="0 0 16 15">
    <path stroke-width="1.5" d="M2.5 7.5h11M8 13V2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-plus',
}
</script>

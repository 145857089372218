import { TYPE } from '../../constants'
import { GtmGa4ListingArticleClickModel } from '../../models'
import { BaseBuilder } from '../index'

export class ListingArticleClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4ListingArticleClickModel]])
    super(models, data)
  }
}

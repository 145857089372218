import { TYPE } from '../../constants'
import { GtmRatingModel } from '../../models/gtm/rating.model'
import { BaseBuilder } from '../base.builder'

export class RatingBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmRatingModel]])
    super(models, data)
  }
}

export const testIds = {
  type: 'object',
  properties: {
    old: {
      type: String,
      default: '',
    },
    actual: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
  },
}

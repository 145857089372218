import { PAGINATION_DIRECTION } from '../constants/pagination'

export class PaginationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fetchPromotionsPrev() {
    const pageFirst = this.gaApp.stores.promo.listing.getPageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    return this.gaApp.services.promo.api.fetchPromotions(
      pageFirst - 1,
      PAGINATION_DIRECTION.PREV,
    )
  }

  fetchOffersPrev() {
    const pageFirst = this.gaApp.stores.promo.offers.getPageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    return this.gaApp.services.promo.api.fetchOffers({
      page: pageFirst - 1,
      direction: PAGINATION_DIRECTION.PREV,
    })
  }

  fetchPromotionsNext() {
    const pageLast = this.gaApp.stores.promo.listing.getPageNumberLast
    const pagesCount = this.gaApp.stores.promo.page.getPagesCount

    if (pagesCount > 0 && pageLast === pagesCount) {
      return false
    }

    return this.gaApp.services.promo.api.fetchPromotions(
      pageLast + 1,
      PAGINATION_DIRECTION.NEXT,
    )
  }

  fetchOffersNext() {
    const pageLast = this.gaApp.stores.promo.offers.getPageNumberLast
    const pagesCount = this.gaApp.stores.promo.offers.getPagesNumbers

    if (pagesCount > 0 && pageLast === pagesCount) {
      return false
    }

    return this.gaApp.services.promo.api.fetchOffers({
      page: pageLast + 1,
      direction: PAGINATION_DIRECTION.NEXT,
    })
  }

  async fetchPromotionsOnPageIntersect(page) {
    const currentPage = page || 1

    // проверить нужно ли обновлять данные
    const pagesCount = this.gaApp.stores.promo.page.getPagesCount

    if (pagesCount <= currentPage) {
      return
    }

    // обновить номер страницы в url
    await this.gaApp.services.promo.listing.changeUrlPage(currentPage)

    // проверить что страницу еще не загружали
    const { pages, pagination } = this.gaApp.stores.promo.listing

    const nextPage = pages.find((page) => page.number === currentPage + 1)

    if (pagination.next.pending || pagination.prev.pending || nextPage) {
      return false
    }

    // загрузить страницу
    return this.fetchPromotionsNext()
  }

  fetchOffersOnPageIntersect(page) {
    const currentPage = page || 1

    // проверить нужно ли обновлять данные
    const pagesCount = this.gaApp.stores.promo.offers.getPagesNumbers

    if (pagesCount <= currentPage) {
      return
    }

    // проверить что страницу еще не загружали
    const { pages, pagination } = this.gaApp.stores.promo.offers

    const nextPage = page === pages.offers?.length

    if (pagination.next.pending || pagination.prev.pending || nextPage) {
      return false
    }

    // загрузить страницу
    return this.fetchOffersNext()
  }

  setPagination({ direction, value }) {
    this.gaApp.stores.promo.listing.pagination[direction] = {
      ...this.gaApp.stores.promo.listing.pagination[direction],
      ...value,
    }
  }

  setOffersPagination({ direction, value }) {
    this.gaApp.stores.promo.offers.pagination[direction] = {
      ...this.gaApp.stores.promo.offers.pagination[direction],
      ...value,
    }
  }
}

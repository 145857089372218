/**
 * @typedef {string} Placement
 * @typedef {string} Strategy
 */

/**
 * @enum {Placement}
 */
export const PLACEMENT = {
  AUTO: 'auto',
  AUTO_START: 'auto-start',
  AUTO_END: 'auto-end',

  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',

  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',

  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',

  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end',
}

/**
 * @enum {Strategy}
 */
export const STRATEGY = {
  FIXED: 'fixed',
  ABSOLUTE: 'absolute',
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getThankYouData(params = {}) {
    const { maskedId } = params
    const { data } =
      await this.gaApp.repositories.checkout.main.getThankYou(maskedId)

    this.gaApp.stores.checkout.thankYou.$patch(data)
  }
}

<template>
  <svg viewBox="0 0 16 16">
    <path fill="none" stroke-width="1.25" d="M.625.625h5.75v5.75H.625z" />
    <path fill="none" stroke-width="1.25" d="M9.625.625h5.75v5.75h-5.75z" />
    <path fill="none" stroke-width="1.25" d="M9.625 9.625h5.75v5.75h-5.75z" />
    <path fill="none" stroke-width="1.25" d="M.625 9.625h5.75v5.75H.625z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-plp-layout-simple',
}
</script>

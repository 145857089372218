import { computed, toRefs, unref } from 'vue'

import { propValidator } from '../../../utils'
import { attributes as schemaAttributes } from '../../../utils/schemas'
import { GaProductCardColors } from '../colors'
import { GaProductCardUnits } from '../units'

import { SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-attributes',

  components: {
    GaProductCardColors,
    GaProductCardUnits,
  },

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaAttributes),
    },

    forceShowAttrs: {
      type: Boolean,
      default: false,
    },

    reversed: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },

  setup(props) {
    const { data, forceShowAttrs, size, reversed } = toRefs(props)

    const colorsOptions = computed(() => unref(data).colors?.values || [])
    const colorsCount = computed(() => unref(data).colors?.count || 0)

    const showColors = computed(() => {
      if (unref(colorsOptions).length > 1) return true

      if (
        unref(colorsOptions).length === 1 &&
        unref(forceShowAttrs) &&
        unref(colorsCount) > 1
      )
        return true

      return false
    })

    const unitsOptions = computed(() => unref(data).units?.values || [])

    const unitsCount = computed(() => unref(data).units?.count || 0)

    const showUnits = computed(() => {
      if (unref(unitsOptions).length > 1) return true

      if (
        unref(unitsOptions).length === 1 &&
        unref(forceShowAttrs) &&
        unref(unitsCount) > 1
      )
        return true

      return false
    })

    const mods = computed(() => ({
      size: unref(size),
      reversed: unref(reversed),
    }))

    return {
      colorsOptions,
      showColors,
      colorsCount,
      unitsOptions,
      showUnits,
      mods,
    }
  },
}

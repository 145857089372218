export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async syncMagentoTokens() {
    const { url } = this.endpoints.sync()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async sendCode(data, parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.sendCode()

    try {
      const response = await this.gaApp.api.request().post(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      console.error(error)
      throw this.gaApp.api.error(error)
    }
  }

  async resendCode(data, parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.resendCode()

    try {
      const response = await this.gaApp.api.request().put(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async sendCodeV2(data, parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.sendCodeV2()

    try {
      const response = await this.gaApp.api.request().post(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      console.error(error)
      throw this.gaApp.api.error(error)
    }
  }

  async resendCodeV2(data, parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.resendCodeV2()

    try {
      const response = await this.gaApp.api.request().put(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async refreshTokens(data, parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.refreshTokens()

    try {
      const response = await this.gaApp.api.request().post(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async login(data, parameters = {}) {
    const params = parameters
    const { url } = this.endpoints.getTokens()

    try {
      const response = await this.gaApp.api.request().post(url, data, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async logout() {
    const { url } = this.endpoints.logout()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

/**
 * Предоставляет методы для управления модальным окном с деталями отзыва:

`toggleModal(value)` - переключает состояние модального окна, устанавливая значение openedModal в store для деталей отзыва.

`closeModal()` - закрывает модальное окно, устанавливая openedModal в false.

`openModal({ reviewId })` - открывает модальное окно и запрашивает детали отзыва с указанным reviewId, если они еще не были загружены. Также устанавливает флаг wasOpenedModal, чтобы отметить факт открытия модального окна, и отправляет аналитические данные.

`sendAnalytics()` - отправляет аналитические данные о событии открытия модального окна с деталями отзыва.
 */

export class DetailService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  toggleModal(value) {
    this.gaApp.stores.review.detail.openedModal = value
  }

  closeModal() {
    this.gaApp.stores.review.detail.openedModal = false
  }

  openModal({ reviewId }) {
    if (!this.gaApp.stores.review.detail.wasOpenedModal) {
      this.gaApp.stores.review.detail.wasOpenedModal = true
    }

    this.gaApp.stores.review.detail.reviewId = reviewId
    this.gaApp.stores.review.detail.openedModal = true
    // делаем запрос только, если этого отзыва нет в листинге или отзыв уже был запрошен
    if (!this.gaApp.stores.review.detail.isLoadedReview) {
      this.gaApp.services.review.api.getReview()
    }

    this.sendAnalytics()
  }

  sendAnalytics() {
    this.gaApp.services.app.scheduler.postTask(() => {
      this.gaApp.analytics.modules.review.onOpenDetail()
    }, 'background')
  }
}

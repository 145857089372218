import flip from '@popperjs/core/lib/modifiers/flip'
import offset from '@popperjs/core/lib/modifiers/offset'
import { createPopper } from '@popperjs/core/lib/popper-lite'

import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  unref,
  watch,
} from 'vue'

import { PLACEMENT, STRATEGY } from './const'

/**
 * Слушает флаг видимости и позиционирует тултип
 * @param {Ref<Node|VueInstance>} tooltipOwnerRef ссылка на элемент, относительно которого происходит позиционирование тултипа
 * @param {Ref<Node|VueInstance>} tooltipRef ссылка на тултип
 * @param {Ref<boolean>} isTooltipVisible флаг видимости тултипа
 * @param {Object} options параметры
 * @param {number} [options.offsetX] сдвиг тултипа по оси x
 * @param {number} [options.offsetY] сдвиг тултипа по оси y
 * @param {import('./const').Placement} [options.placement] положение тултипа
 * @param {import('./const').Strategy} [options.strategy] стратегию позиционирования https://popper.js.org/docs/v2/constructors/#strategy
 * @param {Ref<array>} [options.modifiers] массив модификаторов popper'а https://popper.js.org/docs/v2/modifiers/
 * @param {number} [options.destroyInstanceTimeoutMs] количество миллисекунд, по истечении которых, нужно удалить инстанс popper'а
 * @param {Ref<boolean>} [options.isPending] флаг, означающий, что контент в поппере асинхронный
 * @param {Ref<number | {
 *  top?: number
 *  bottom?: number
 *  right?: number
 *  left?: number
 * }>} [options.flipPadding] отступы для переворачивания содержимого popper'а https://popper.js.org/docs/v2/utils/detect-overflow/#padding
 */

export const usePopper = (
  tooltipOwnerRef,
  tooltipRef,
  isTooltipVisible,
  {
    offsetX = 0,
    offsetY = 0,
    placement = PLACEMENT.BOTTOM_START,
    strategy = STRATEGY.ABSOLUTE,
    modifiers = [],
    destroyInstanceTimeoutMs = 0,
    isPending = false,
    flipPadding = null,
  } = {},
) => {
  const timer = ref(null)
  const popperInstance = ref()

  const tooltipOwnerNode = computed(() => {
    return unref(tooltipOwnerRef).$el || unref(tooltipOwnerRef)
  })

  const tooltipNode = computed(() => {
    return unref(tooltipRef).$el || unref(tooltipRef)
  })

  const destroy = () => {
    unref(popperInstance).destroy()
    popperInstance.value = null
  }

  const usePendingWatcher = () => {
    if (!unref(isPending)) {
      return false
    }

    const unwatch = watch(isPending, (value) => {
      if (!value) {
        if (unref(popperInstance)) {
          nextTick(() => {
            popperInstance.value.update()
          })
        }

        unwatch()
      }
    })
  }

  onMounted(() => {
    usePendingWatcher()

    watch(
      isTooltipVisible,
      (newValue) => {
        if (destroyInstanceTimeoutMs) {
          clearTimeout(unref(timer))
        }

        if (newValue) {
          // Если инстанс существует, то вызываем update()
          if (unref(popperInstance)) {
            nextTick(() => {
              popperInstance.value.update()
            })

            return
          }

          // Если инстанса нет, то создаём
          nextTick(() => {
            popperInstance.value = createPopper(
              unref(tooltipOwnerNode),
              unref(tooltipNode),
              {
                placement: unref(placement),
                strategy: unref(strategy),
                modifiers: [
                  flip,
                  {
                    name: 'flip',
                    options: {
                      flipVariations: false,
                      padding: flipPadding?.value ?? 0,
                    },
                  },
                  offset,
                  {
                    name: 'offset',
                    options: {
                      offset: [offsetX, offsetY],
                    },
                  },

                  // Дополнительные модификаторы
                  ...unref(modifiers),
                ],
              },
            )

            nextTick(() => {
              popperInstance.value.update()
            })
          })
        } else if (unref(popperInstance)) {
          if (destroyInstanceTimeoutMs) {
            timer.value = setTimeout(() => destroy(), destroyInstanceTimeoutMs)
          } else {
            destroy()
          }
        }
      },
      { immediate: true },
    )
  })

  onBeforeUnmount(() => {
    if (unref(popperInstance)) {
      clearTimeout(unref(timer))

      destroy()
    }
  })
}

const regularExpression = /\${(\w*)}/g

/**
 * Подменяет в строке url паременные по паттерну ${text}
 *
 * @param {string} url url-путь до картинки, в котором нужно подменить паттерны переменных
 * @param {object} option объект переменных, которые будут подменяться в url
 * @returns {string} строка с подмененными паттернами переменных
 */
export const replaceMediaUrl = (url, option = {}) => {
  if (!url) {
    return ''
  }
  return url.replace(
    new RegExp(regularExpression),
    (_, key) => option[key] ?? '',
  )
}

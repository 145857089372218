// получаем категории из хлебных крошек
export const getCategoriesBreadcrumbs = (breadcrumbs = []) => {
  const [, ...catalogLinks] = breadcrumbs

  if (!catalogLinks.length) {
    return []
  }

  return catalogLinks.map((link) => link.text)
}

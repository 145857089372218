import { useContext } from '@nuxtjs/composition-api'

import { computed, nextTick, unref, watch } from 'vue'

import { processErrors } from '../utils'
import { getVuelidateField } from '../utils/get-vuelidate-field'

export const useErrors = (
  v$,
  { addManualError, removeAllManualErrors, removeManualError } = {},
) => {
  const { $gaApp } = useContext()

  const errorsInternal = computed(() => processErrors(unref(v$), $gaApp.i18n.t))

  const clearManualErrorInternal = (
    fieldPath,
    errorClearCallback = null,
    lazy = false,
  ) => {
    const unwatch = watch(
      () => getVuelidateField(v$, '$model', fieldPath),
      () => {
        removeManualError?.(fieldPath)

        if (errorClearCallback) {
          errorClearCallback(fieldPath)
        }

        if (unwatch) {
          unwatch()
        }
      },
      { immediate: !lazy },
    )
  }

  const scrollToErrorElement = (rootElement, selector = '[data-error]') => {
    const errorElement = rootElement.querySelector(selector)

    if (!errorElement) {
      return undefined
    }

    if ('scrollIntoViewIfNeeded' in errorElement) {
      errorElement.scrollIntoViewIfNeeded(true)
    } else {
      errorElement.scrollIntoView()
    }
  }

  /**
   * Устанавливает все свойства как "грязные", запуская все средства проверки. Возвращает обещание с логическим значением, которое разрешается после завершения работы всех валидаторов.
   *
   * Если поле не найдено, то валидации не происходит и возвращается `undefined`.
   *
   * Если `filedPath === undefined`, то валидация производится для всех полей.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @param {{
   *  scrollToError: boolean,
   *  rootElement: Element | null,
   *  errorSelector: string
   * }} validateOptions настройки валидации:
   *   - scrollToError – после валидации произойдет скролл до первой ошибки. По-умолчанию: `true`
   *   - rootElement – DOM элемент в рамках которого будет произведен поиск ошибок. Если null, то скролла не произойдет. По-умолчанию: `null`
   *   - errorSelector – CSS селектор, по которому элементы определяются как ошибки. По-умолчанию `[data-error]`.
   *
   * @returns {Promise<boolean | undefined>}
   */
  const validate = async (
    fieldPath,
    { scrollToError = true, rootElement = null, errorSelector = null } = {},
  ) => {
    const method = getVuelidateField(v$, '$validate', fieldPath)

    if (!method) {
      return undefined
    }

    const result = await method()

    if (scrollToError && rootElement) {
      nextTick(() => {
        scrollToErrorElement(rootElement, errorSelector)
      })
    }

    return result
  }

  /**
   * Устанавливает принудительную ошибку для определенного поля с сообщением `message`.
   *
   * Если поле не найдено, то ошибка не добавляется.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @param {string} message содержание сообщения ошибки
   * @param {{
   *  autoClear: boolean,
   *  scrollToError: Element | null,
   *  rootElement: string,
   *  selector: string | null,
   *  errorClearCallback: (fieldPath: string) => void
   * }} setErrorOptions настройки установки ошибок:
   *   - autoClear – после изменения значения поля произойдет удаление добавленной ошибки. По-умолчанию: `true`
   *   - scrollToError – после валидации произойдет скролл до первой ошибки. По-умолчанию: `true`
   *   - rootElement – DOM элемент в рамках которого будет произведен поиск ошибок. Если null, то скролла не произойдет. По-умолчанию: `null`.
   *   - errorSelector – CSS селектор, по которому элементы определяются как ошибки. По-умолчанию: `'[data-error]'`.
   *   - errorClearCallback – функция, которая вызывается в момент удаления ошибки, если установлен `autoClear === true`
   *
   * @returns {void}
   */
  const setError = (
    fieldPath,
    message,
    {
      autoClear = true,
      scrollToError = true,
      rootElement = null,
      errorSelector = '[data-error]',
      errorClearCallback = null,
    } = {},
  ) => {
    addManualError?.(fieldPath, message)
    validate(fieldPath, { scrollToError, rootElement })

    if (scrollToError && rootElement) {
      nextTick(() => {
        scrollToErrorElement(rootElement, errorSelector)
      })
    }

    if (autoClear) {
      nextTick(() => {
        clearManualErrorInternal(fieldPath, errorClearCallback, true)
      })
    }
  }

  const resetErrors = (fieldPath) => {
    if (fieldPath) {
      removeManualError?.(fieldPath)
    } else {
      removeAllManualErrors?.()
    }

    getVuelidateField(v$, '$reset', fieldPath)()
  }

  /**
   * Возвращает список ошибок в виде объекта. Где, ключ – путь до поля, значение - сообщение ошибки.
   *
   * Например:
   * ```JavaScript
   * {
   *   'form.inn': 'Минимальная длина 10',
   *   'form.agreed': 'Обязательное поле',
   * }
   * ```
   *
   * @returns {{ [key: string]: string }}
   */
  const getErrors = () => {
    return errorsInternal.value
  }

  /**
   * Возвращает текст ошибки выбранного поля.
   *
   * Если поле не найдено, то возвращается `undefined`.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   *
   * @param {boolean} includeChildren определяет включать ли в результат потомков поля. Потомки включены по умолчанию.
   *
   * @returns {string | undefined}
   */
  const getError = (fieldPath, includeChildren = true) => {
    if (!includeChildren) {
      return errorsInternal.value[fieldPath]
    }

    const searchRegExp = new RegExp('^' + fieldPath.replace('.', '\\.'))

    const message = Object.entries(errorsInternal.value).find(([path]) =>
      searchRegExp.test(path),
    )?.[1]

    return message
  }

  /**
   * Возвращает флаг, отображающий видимость ошибок. Эквивалентно `isDirty(fieldPath) && isPending(fieldPath) && isInvalid(fieldPath)`
   *
   * Если поле не найдено, то возвращается `undefined`.
   *
   * Если `filedPath === undefined`, то флаг относится ко всем полям.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   *
   * @returns {boolean | undefined}
   */
  const isError = (fieldPath) => {
    return getVuelidateField(v$, '$error', fieldPath)
  }

  /**
   * Флаг, очень похожий на `isDirty(...)`, за одним исключением. Флаг `isAnyDirty(...)` считается истинным, если данная модель была $touched или **любой из ее дочерних** элементов является $anyDirty, что означает, что по крайней мере один потомок является $dirty.
   *
   * Если поле не найдено, то возвращается `undefined`.
   *
   * Если `filedPath === undefined`, то флаг относится ко всем полям.
   *
   * @param {string} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   *
   * @returns {boolean | undefined}
   */
  const isAnyDirty = (fieldPath) => {
    return getVuelidateField(v$, '$anyDirty', fieldPath)
  }

  /**
   * Сообщает о наличии видимых ошибок.
   *
   * Если поле не найдено, то возвращается `undefined`.
   *
   * Если `filedPath === undefined`, то флаг относится ко всем полям.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   *
   * @returns {boolean | undefined}
   */
  const hasErrors = (fieldPath) => {
    return isAnyError(fieldPath) && isAnyDirty(fieldPath)
  }

  /**
   * Сообщает о наличии хотя бы одной ошибки.
   *
   * Если поле не найдено, то возвращается `undefined`.
   *
   * Если `filedPath === undefined`, то флаг относится ко всем полям.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   *
   * @returns {boolean | undefined}
   */
  const isAnyError = (fieldPath) => {
    const errors = getVuelidateField(v$, '$errors', fieldPath)

    return Boolean(errors.length)
  }

  /**
   * Сообщает о наличии хотя бы одной НЕ ручной ошибки
   *
   * Если `fieldPath === undefined`, то флаг относится ко всем полям
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean}
   */
  const isAnyNoneManualError = (fieldPath) => {
    const errors = getVuelidateField(v$, '$errors', fieldPath)

    return errors.some((error) => error.$params?.type !== 'manual')
  }

  return {
    getError,
    getErrors,
    hasErrors,
    isError,
    resetErrors,
    setError,
    validate,
    isAnyDirty,
    isAnyError,
    isAnyNoneManualError,
  }
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGaUaCartModel extends GtmBaseModel {
  constructor({ items, currency, addToCart }) {
    const event = addToCart
      ? EVENT.ADD_TO_CART.GAUA
      : EVENT.REMOVE_FROM_CART.GAUA
    const keyName = addToCart ? 'add' : 'remove'

    super({ event })

    this.event_source = 'plaid_backend'
    this.ecommerce = {
      currencyCode: currency,
      [keyName]: {
        products: items.map((item) => ({
          id: item.id,
          name: item.name,
          list: item.itemListName,
          brand: item.brand,
          price: item.price,
          variant: item.variant,
          category: item.category,
          dimension4: item.dimension4,
        })),
      },
    }
  }
}

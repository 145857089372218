import { TYPE } from '../../constants'
import { MindboxPageViewModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PageViewBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([[TYPE.MINDBOX, MindboxPageViewModel]])

    super(models, data, country)
  }
}

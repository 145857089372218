export const moduleHomeSsrRoutes = [
  {
    moduleType: 'home',
    pageType: 'main',
    path: '/',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/home/page/main' */
        '~/modules/home/components/pages/main.vue'
      ),
  },
]

export const routes = [...moduleHomeSsrRoutes]

import { TYPE } from '../../constants'
import {
  GaAnalyticsMenuModel,
  GtmGa4MenuModel,
  GtmGaUaMenuModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class MenuItemClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_UA, GtmGaUaMenuModel],
      [TYPE.GTM_4, GtmGa4MenuModel],
      [TYPE.GAA, GaAnalyticsMenuModel],
    ])
    super(models, data)
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path d="M7.5 12 1.005.75h12.99L7.5 12Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-location-geo-pin-filled',
}
</script>

import { NOT_SET, VENDOR } from '../constants'

import { config } from '~/config'

export class GtmFacade {
  constructor(gtm, gaApp) {
    this.module = gtm
    this.gaApp = gaApp
  }

  get entity() {
    return VENDOR.GTM
  }

  get CONSTANTS() {
    return {
      NOT_SET,
    }
  }

  init(id) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const identificator = id || config.get(VENDOR.GTM)?.id

    return this.module.init(identificator)
  }

  send(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const push = () => this.module.push(...args)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 16">
    <path stroke="inherit" stroke-width="2" d="M5 13.5 10.5 8 5 2.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-step-button-arrow',
}
</script>

import sharingListMock from '../sharing/list.json'
import sharingHashMock from '../sharing/share.json'

const mockPromise = (data, timeout = 500) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data)
    }, timeout)
  })
}

export const apiMock = {
  fetchSharingHash: async ({
    url,
    req = null,
    res = { data: sharingHashMock },
  }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },

  fetchSharingList: async ({
    url,
    req = null,
    res = { data: sharingListMock },
  }) => {
    console.info(url, req, res)
    return await mockPromise(res)
  },
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 58 50">
    <path
      fill="#fff"
      d="M27.013 25.241v6.75h-2.11V15.329h5.594a4.973 4.973 0 0 1 3.615 1.448 4.859 4.859 0 0 1 .236 6.803l-.236.24c-.982.943-2.187 1.421-3.615 1.421h-3.484Zm0-7.866v5.82h3.536a2.738 2.738 0 0 0 2.083-.864c1.1-1.156 1.074-3.016-.066-4.132a2.854 2.854 0 0 0-2.017-.824h-3.536Zm13.477 2.844c1.56 0 2.79.425 3.694 1.262.904.837 1.35 2.006 1.35 3.481v7.029h-2.017V30.41h-.092c-.878 1.302-2.03 1.953-3.484 1.953-1.232 0-2.28-.372-3.105-1.116a3.546 3.546 0 0 1-1.244-2.79c0-1.183.445-2.113 1.323-2.817.877-.704 2.056-1.05 3.523-1.05 1.258 0 2.28.24 3.092.691v-.492c0-.73-.315-1.421-.865-1.9a3.04 3.04 0 0 0-2.03-.784c-1.18 0-2.11.505-2.79 1.515l-1.86-1.182c.995-1.475 2.502-2.22 4.506-2.22Zm-2.724 8.277c0 .558.262 1.077.695 1.396a2.552 2.552 0 0 0 1.637.558c.89 0 1.742-.36 2.37-.997.695-.664 1.048-1.448 1.048-2.352-.654-.531-1.571-.797-2.75-.784-.852 0-1.572.213-2.148.625-.564.412-.852.93-.852 1.554Z"
    />
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M57.113 20.59 50.066 37H47.89l2.62-5.74-4.624-10.67h2.292l3.34 8.186h.04l3.261-8.185h2.293Z"
      clip-rule="evenodd"
    />
    <path
      fill="#4285F4"
      fill-rule="evenodd"
      d="M18.485 23.78c0-.651-.052-1.302-.157-1.94H9.434v3.68h5.095a4.435 4.435 0 0 1-1.886 2.91v2.392h3.04c1.78-1.661 2.802-4.12 2.802-7.042Z"
      clip-rule="evenodd"
    />
    <path
      fill="#34A853"
      fill-rule="evenodd"
      d="M9.434 33.133c2.541 0 4.69-.85 6.248-2.312l-3.039-2.391c-.851.584-1.938.917-3.209.917-2.462 0-4.545-1.688-5.292-3.947h-3.13v2.472c1.598 3.228 4.86 5.261 8.422 5.261Z"
      clip-rule="evenodd"
    />
    <path
      fill="#FBBC04"
      fill-rule="evenodd"
      d="M4.142 25.4a5.851 5.851 0 0 1 0-3.667v-2.458h-3.13a9.607 9.607 0 0 0 0 8.584l3.13-2.458Z"
      clip-rule="evenodd"
    />
    <path
      fill="#EA4335"
      fill-rule="evenodd"
      d="M9.434 17.787a5.036 5.036 0 0 1 3.615 1.435l2.699-2.737a9.032 9.032 0 0 0-6.314-2.484c-3.563 0-6.824 2.046-8.422 5.274l3.13 2.472c.747-2.272 2.83-3.96 5.292-3.96Z"
      clip-rule="evenodd"
    />
    <defs>
      <clipPath><path fill="#fff" d="M0 14h57.112v23H0z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-google-pay',
}
</script>

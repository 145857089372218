export const TYPE = {
  PRODUCTS: 'products',
  BRANDS: 'brands',
}

export const ACTION = {
  DEL: 'delete',
  ADD: 'add',
}

export const LOCALE_PATH = {
  ERROR: {
    add: 'favorites.error.add',
    delete: 'favorites.error.delete',
  },

  SUCCESS: {
    add: 'favorites.success.add',
    delete: 'favorites.success.delete',
  },
}

export const PAGINATION_BUTTON = {
  NEXT: 'next',
  PREV: 'prev',
}

export const CLEAR_PAGES = {
  NONE: null,
  AFTER_REQUEST: 'afterRequest',
}

import { TYPE } from '../../constants'
import { GtmGa4MenuClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class MenuClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4MenuClickModel]])
    super(models, data)
  }
}

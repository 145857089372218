import isNumber from 'lodash/isNumber'

import { computed } from 'vue'

import { GaReviewStars } from '../../atoms/stars'

import { TEXT_SIZE } from './scripts/const'

// @vue/component
export default {
  name: 'ga-review-listing-product-rating',

  components: {
    GaReviewStars,
  },

  props: {
    // Значечние оценки рейтинга
    ratingValue: {
      type: Number,
      default: null,
    },

    // Количество отзывов
    reviewsCount: {
      type: Number,
      default: null,
    },

    // Размер звездочек
    starsSize: {
      type: String,
      default: 's',
    },

    // Расстояние между звездочками
    starsIndent: {
      type: String,
      default: 's',
    },

    // Размер шрфита текста
    textSize: {
      type: String,
      default: TEXT_SIZE.S,
      validator: (value) => Object.values(TEXT_SIZE).includes(value),
    },

    // Внешний класс с целью стилизации звездочек
    // Может быть функцией, что бы принять актуальные модификаторы
    classNameStars: {
      type: [String, Function],
      default: null,
    },
  },

  setup(props) {
    const attrs = computed(() => ({
      size: props.starsSize,
      indent: props.starsIndent,
      rating: props.ratingValue,
      classNameStars: props.classNameStars,
    }))

    const mods = computed(() => ({
      'text-size': props.textSize,
    }))

    const toFixed = (value) => isNumber(value) && value.toFixed(1)

    return {
      mods,

      attrs,

      toFixed,
    }
  },
}

/**
 * Выполняет поиск резервного экрана.
 * Возвращает имя экрана
 *
 *
 * @param {string} currentScreen имя экрана для которого нужно собрать sources
 * @param {array} allScreens массив названий экранов для которых прописаны параметры media
 * @param {array} mediaScreen массив значений экранов которые пришли в пропсах
 * @param {array} takeNearest true - вернуть ближайший доступный экран, false - вернуть null
 * @returns {string|null} название резервного экрана
 */
export function findReserveScreen(currentScreen, allScreens, mediaScreen) {
  const isAvailableScreen = (screen) => mediaScreen.includes(screen)

  let nameScreen = currentScreen
  let indexNameScreen = allScreens.indexOf(nameScreen)

  while (indexNameScreen >= 0) {
    if (isAvailableScreen(nameScreen)) {
      // Проверяем есть ли в массиве доступных экранов, текущий экран
      // Если есть, берем текущий экран
      break
    } else if (indexNameScreen === 0 && !isAvailableScreen(nameScreen)) {
      // Если проверили все экраны, и не нашли значения
      // то берем экран по умолчанию
      nameScreen = null
      break
    } else {
      // Идем по возрастанию ширины экрана
      // fullhd <- desktop <- tablet <- mobile
      nameScreen = allScreens[indexNameScreen - 1]
      indexNameScreen -= 1
    }
  }

  return nameScreen
}

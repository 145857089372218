import { PAGINATION_BUTTON } from '../../constants'

export const state = () => {
  return {
    ordersPending: false,

    completedCount: 0,

    totalPages: 0,

    pages: [],

    count: 0,

    hasOrders: false,

    firstCompletedOrderIndex: -1,

    pagination: {
      [PAGINATION_BUTTON.NEXT]: {
        pending: false,
        error: false,
      },
      [PAGINATION_BUTTON.PREV]: {
        pending: false,
        error: false,
      },
    },

    bubbleYear: null,

    ordersHash: '',

    lastViewedOrder: null,

    lastViewedPage: 1,

    updatedOrder: {},
  }
}

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  formatTime(time, format) {
    if (!time || !format) {
      return ''
    }

    return this.gaApp.libs.dateWrapper(`2000-01-01 ${time}`).format(format)
  }
}

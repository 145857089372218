import { galleryStore as gallery } from './gallery'
import { headerStore as header } from './header'
import { listStore as list } from './list'
import { mainStore as main } from './main'
import { oneStore as one } from './one'
import { streamStore as stream } from './stream'
import { streamLiveStore as streamLive } from './stream-live'
import { streamsStore as streams } from './streams'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    gallery: gallery(gaApp),
    list: list(gaApp),
    one: one(gaApp),
    header: header(gaApp),
    stream: stream(gaApp),
    streamLive: streamLive(gaApp),
    streams: streams(gaApp),
  }
}

<template>
  <svg fill="none" viewBox="0 0 10 10">
    <path stroke="currentColor" stroke-width="1.25" d="m1 1 8 8M1 9l8-8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-cross-bold',
}
</script>

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    this.gaApp.services.customer.menu.setActiveMenuItem(redirect.pageType)
    this.gaApp.services.customer.menu.setSelectedMenuItem(redirect.pageType)
    this.gaApp.services.customer.menu.getMenu(redirect.pageType)

    try {
      switch (redirect.pageType) {
        case 'products':
          return await this.gaApp.services.purchased.api.getListing()
      }
    } catch (error) {
      this.redirectError(error)
    }
  }

  redirectError(error) {
    return this.gaApp.redirectError({
      statusCode: 500,
      message: error,
    })
  }
}

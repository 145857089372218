import { EVENT_CATEGORY_TRIX } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmTrixButtonGoToBrandModel extends GtmBaseModel {
  constructor() {
    super({ eventCategory: EVENT_CATEGORY_TRIX })

    this.action_type = 'click_button_gift'
  }
}

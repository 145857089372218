export class SymaphoreService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  createAsyncSymaphore(fn, flags) {
    const appliedFlags = new Set()

    let done = false

    return function wrapper(flag) {
      if (done) {
        return false
      }

      if (flags.includes(flag)) {
        appliedFlags.add(flag)
      }

      if (appliedFlags.size === flags.length) {
        done = true

        return fn()
      }
    }
  }
}

import { computed, reactive, unref } from 'vue'

import { CONTROLS_POSITION } from './const'

export const useControls = ({
  itemWidth,
  itemsInternal,
  itemsCount,
  offset,
  offsetLast,
  itemsAnimating,
  controls,
}) => {
  const controlStyle = reactive({ top: '' })

  const updateControlStyle = () => {
    if (controls.value?.position === CONTROLS_POSITION.SIDES_TOP) {
      controlStyle.top = `${unref(itemWidth) / 2}px`
    } else {
      controlStyle.top = ''
    }
  }

  const hasControls = computed(
    () => itemsInternal.value.length > itemsCount.value && controls.value,
  )

  const hasTopControls = computed(
    () =>
      hasControls.value && controls.value?.position === CONTROLS_POSITION.TOP,
  )

  const hasSidesControls = computed(
    () =>
      hasControls.value &&
      !(controls.value?.position === CONTROLS_POSITION.TOP),
  )

  const isControlLeftDisabled = computed(() => offset.value === 0)

  const showControlLeft = computed(() => {
    return !isControlLeftDisabled.value || controls.value?.showDisabled
  })

  const isControlRightDisabled = computed(() => {
    return offset.value === offsetLast.value
  })

  const showControlRight = computed(() => {
    return !isControlRightDisabled.value || controls.value?.showDisabled
  })

  const moveOffset = (direction) => {
    if (itemsAnimating.value) {
      return
    }

    const step = direction * itemsCount.value

    let offsetInternal = offset.value + step

    if (offsetInternal > offsetLast.value) {
      offsetInternal = offsetLast.value
    } else if (offsetInternal < 0) {
      offsetInternal = 0
    }

    offset.value = offsetInternal
  }

  const onControlRightClick = () => {
    moveOffset(1)
  }

  const onControlLeftClick = () => {
    moveOffset(-1)
  }

  return {
    controlStyle,
    updateControlStyle,

    hasTopControls,
    hasSidesControls,

    showControlLeft,
    isControlLeftDisabled,
    showControlRight,
    isControlRightDisabled,

    onControlLeftClick,
    onControlRightClick,
  }
}

import isEqual from 'lodash/isEqual'

import { PAGINATION_BUTTON } from '../constants'

const { NEXT, PREV } = PAGINATION_BUTTON

export class OrdersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { orders } = this.gaApp.stores.customer

    return isEqual(filtersHash, orders.ordersHash)
  }

  async fetchOrders(page = 1, direction = NEXT) {
    this.setPagination({
      direction,
      value: {
        pending: true,
        error: false,
      },
    })

    try {
      await this.gaApp.services.customer.api.fetchOrdersByPage({ page })
    } catch (error) {
      this.setPagination({
        direction,
        value: { error: true },
      })
    } finally {
      this.setPagination({
        direction,
        value: { pending: false },
      })
    }
  }

  fetchOrdersFirstPage() {
    return this.fetchOrders(1, NEXT)
  }

  fetchOrdersPrev() {
    const pageFirst = this.gaApp.stores.customer.orders.pageNumberFirst

    if (pageFirst <= 1) {
      return false
    }

    return this.fetchOrders(pageFirst - 1, PREV)
  }

  fetchOrdersNext() {
    const pageLast = this.gaApp.stores.customer.orders.pageNumberLast
    const pagesCount = this.gaApp.stores.customer.orders.totalPages

    if (pageLast === pagesCount) {
      return false
    }

    this.fetchOrders(pageLast + 1, NEXT)
  }

  fetchOrdersOnPageIntersect(pageNumber) {
    this.gaApp.services.filters.main.onPageNumberChange(pageNumber)

    const { pages, pagination } = this.gaApp.stores.customer.orders

    const nextPage = pages.find((page) => page.number === pageNumber + 1)

    if (pagination.next.pending || pagination.prev.pending || nextPage) {
      return false
    }

    return this.fetchOrdersNext()
  }

  /**
   * Запрос списка продуктов при изменении селекта
   */
  async refreshOrders({ select }) {
    this.clearPages()

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        filter: { ...select },
      }),

      this.fetchOrders(),
    ])
  }

  setBubbleYear(creationDate) {
    const year = new Date(creationDate).getFullYear()

    this.gaApp.stores.customer.orders.bubbleYear = year
  }

  resetListingAndFetchOrders() {
    this.gaApp.analytics.modules.customer.onApplyOrdersFilters()

    if (this.areHashesEqual()) {
      return false
    }

    // откладываем, чтобы сработало после возвращения
    // первоначального скролла при закрытии модалки
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    this.clearPages()

    this.gaApp.services.filters.main.pageNumber.updateFilter(1)

    this.gaApp.services.filters.main.updateFiltersURL()

    this.gaApp.services.customer.orders.fetchOrdersFirstPage()
  }

  normalizeOrder(order) {
    order.items.map((item) => {
      if (!item.imageUrls) {
        item.imageUrls = []
      }

      return item
    })

    return order
  }

  normalizeOrders(orders) {
    return orders.map((order) => this.normalizeOrder(order))
  }

  scrollToLastViewedOrder = () => {
    const order = this.gaApp.stores.customer.orders.lastViewedOrder
    this.gaApp.services.app.scrollNavigation.scrollToAnchor(order)
    this.setLastViewedItems({ lastViewedOrder: null, lastViewedPage: 1 })
  }

  setPage(page) {
    this.gaApp.stores.customer.orders.pages.push(page)
    this.gaApp.stores.customer.orders.pages.sort((a, b) => a.number - b.number)
  }

  setPagination({ direction, value }) {
    this.gaApp.stores.customer.orders.pagination[direction] = {
      ...this.gaApp.stores.customer.orders.pagination[direction],
      ...value,
    }
  }

  updateOrders() {
    const updatedOrderId = this.gaApp.stores.customer.orders.updatedOrder.id

    const updatedPages = this.gaApp.stores.customer.orders.pages.map((page) => {
      const orders = page.orders.map((order) => {
        if (order.id !== updatedOrderId) return order
        return this.gaApp.stores.customer.orders.updatedOrder
      })

      return {
        number: page.number,
        orders,
      }
    })

    this.gaApp.stores.customer.orders.pages = updatedPages
  }

  setLastViewedItems({ lastViewedOrder, lastViewedPage }) {
    this.gaApp.stores.customer.orders.lastViewedOrder = lastViewedOrder
    this.gaApp.stores.customer.orders.lastViewedPage = lastViewedPage
  }

  clearPages() {
    this.gaApp.stores.customer.orders.pages = []
  }
}

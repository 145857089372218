export class MindboxProductModel {
  constructor({ id, amount }) {
    this.product = {
      ids: {
        website: id,
      },
    }

    if (amount) {
      this.pricePerItem = amount
    }
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref, watch } from 'vue'

export const useLocation = () => {
  const { $gaApp } = useContext()

  const isLocationConfirmAddressOpen = ref(false)

  const pageScreenType = computed(
    () => $gaApp.stores.location.main.pageScreenType,
  )

  const onLocationClick = () => {
    $gaApp.services.location.main.openModal(pageScreenType.value)
  }

  const confirmAddressOpened = computed(
    () => $gaApp.stores.location.confirm.opened,
  )

  const cookieModalAnswered = computed(() => {
    return $gaApp.features.get('modalCookiesPermission')
      ? $gaApp.stores.app.cookie.permission.isAlreadyAnswered
      : true
  })

  const locationVisuallyHidden = computed(() => {
    return $gaApp.services.location.main.getDeliveryAddress()?.formattedName
  })

  const locationMods = computed(() => ({
    active: unref(confirmAddressOpened),
  }))

  watch(
    [confirmAddressOpened, cookieModalAnswered],
    ([newOpened, newAnswered]) => {
      if (
        $gaApp.features.get('modalCookiesPermission') &&
        !$gaApp.features.get('modalCookiesPermissionSwitchToPanel')
      ) {
        if (newOpened && newAnswered && !isLocationConfirmAddressOpen.value) {
          isLocationConfirmAddressOpen.value = true
        }
      } else if (newOpened && !isLocationConfirmAddressOpen.value) {
        isLocationConfirmAddressOpen.value = true
      }
    },
    { immediate: true },
  )

  return {
    locationVisuallyHidden,
    locationMods,
    isLocationConfirmAddressOpen,
    onLocationClick,
  }
}

import { STATE } from '../../constants/common'
import { getProductSelected } from '../../utils/common/get-product-selected'

export const _getters = (gaApp) => ({
  productState() {
    return this[STATE.PRODUCT]
  },

  productData() {
    return this[STATE.PRODUCT].data
  },

  productSelected() {
    const productCommon = this.productData
    const attributesSelected = this.attributesSelected

    if (!productCommon) {
      return {}
    }

    return getProductSelected({ productCommon, attributesSelected })
  },

  productMainVariantId() {
    return this.productData.mainVariantItemId
  },

  productSelectedId() {
    return this.productSelected?.id
  },

  deliveryData() {
    return this[STATE.DELIVERY].data
  },

  deliveryOptions() {
    return this.deliveryData?.options ?? []
  },

  hasErrorProduct() {
    return !!this[STATE.PRODUCT].error
  },

  hasErrorDelivery() {
    return !!this[STATE.DELIVERY].error
  },
})

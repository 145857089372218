import { useContext } from '@nuxtjs/composition-api'

import { onUnmounted } from 'vue'

export const useAddressChange = (addressChangeCallback) => {
  const { $gaApp } = useContext()

  const unsubscribe = $gaApp.eventBus.subscribe(
    'module/location/address-change',
    addressChangeCallback,
  )

  onUnmounted(() => {
    unsubscribe()
  })
}

<template>
  <svg stroke="none" viewBox="0 0 48 17">
    <g clip-path="url(#gift-cards-ya-art-a)" fill="inherit">
      <path d="M41.168 5.031h6.416v1.42h-2.38v6.584H43.56V6.451h-2.392V5.03Z" />
      <path
        d="M37.515 5.031c1.066 0 1.863.198 2.392.593.537.395.805 1.017.805 1.866 0 .641-.164 1.156-.492 1.543-.32.38-.749.656-1.285.827l2.135 3.175h-1.878l-1.934-2.962h-.984v2.962h-1.632V5.031h2.873Zm-.045 3.711c.507 0 .898-.093 1.174-.28.276-.193.413-.502.413-.927 0-.395-.13-.674-.39-.838-.262-.164-.65-.246-1.163-.246h-1.23v2.291h1.196Z"
      />
      <path
        d="m30.836 5.031 3.097 8.004H32.3l-.693-1.789h-3.22l-.67 1.789h-1.43l3.04-8.004h1.509Zm-1.912 4.807h2.147l-1.085-2.806-1.062 2.806Z"
      />
      <path
        d="M22.56 8.452c-.306 0-.6.03-.884.09a5.73 5.73 0 0 0-.816.234V7.434c.276-.089.582-.16.917-.212a6.12 6.12 0 0 1 1.05-.09c.455 0 .854.05 1.197.146.343.09.63.242.86.458.232.209.403.481.515.816.12.336.179.746.179 1.23v3.253h-1.51a2.083 2.083 0 0 1-.01-.246v-.324a1.872 1.872 0 0 1-.66.503c-.268.12-.593.179-.973.179a2.53 2.53 0 0 1-.805-.123 1.963 1.963 0 0 1-.626-.347 1.609 1.609 0 0 1-.413-.559 1.865 1.865 0 0 1-.146-.749c0-.328.06-.61.18-.85.119-.245.283-.446.491-.603.216-.164.47-.283.76-.358.29-.082.611-.123.962-.123.245 0 .47.02.67.056.202.038.377.086.526.146-.023-.433-.157-.738-.403-.917-.238-.179-.592-.268-1.062-.268Zm.312 3.6c.38 0 .664-.116.85-.347.186-.231.287-.51.302-.839v-.268a2.92 2.92 0 0 0-.403-.123 2.271 2.271 0 0 0-.525-.056c-.343 0-.615.071-.816.213-.201.141-.302.357-.302.648 0 .238.078.429.235.57.164.134.384.201.66.201Z"
      />
      <path
        d="m15.775 5.031 1.811 3.309 1.822-3.309h1.543l-2.638 4.796v3.208h-1.644V9.737L14.02 5.031h1.755Z"
      />
      <path d="M10.32 12.909a.881.881 0 1 1-1.763 0 .881.881 0 0 1 1.762 0Z" />
      <path d="M10.32 7.564a.881.881 0 1 1-1.763 0 .881.881 0 0 1 1.762 0Z" />
      <path d="M7.646 10.237a.881.881 0 1 1-1.762 0 .881.881 0 0 1 1.762 0Z" />
      <path d="M4.974 12.909a.881.881 0 1 1-1.762 0 .881.881 0 0 1 1.762 0Z" />
      <path d="M5.429 6.228h2.673V3.555H5.429v2.673Z" />
      <path d="M.083 11.573h2.673V8.9H.083v2.673Z" />
      <path d="M2.756 8.9H5.43V6.227H2.756V8.9Z" />
      <path d="M.083 6.228h2.673V3.555H.083v2.673Z" />
    </g>
    <defs>
      <clipPath id="gift-cards-ya-art-a">
        <path fill="#fff" d="M0 0h47.304v17H0z" transform="translate(.015)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-ya-art',
}
</script>

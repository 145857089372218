import { GdeslonBaseModel } from '../gdeslon-base-model/gdeslon-base.model'
import { GdeSlonProductBaseModel } from '../gdeslon-product-base.model/gdeslon-product-base.model'

export class GdeslonProductViewModel extends GdeslonBaseModel {
  constructor({ product }) {
    if (!product) {
      throw new Error('product is required')
    }

    super()

    const { categoryId, userId } = product

    this.page_type = 'list'
    this.category_id = categoryId
    this.products = [new GdeSlonProductBaseModel(product)]
    this.user_id = userId
    this.deduplication = 'gdeslon'
  }
}

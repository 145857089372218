<template>
  <ga-module-app-error :code="code" :error="error" />
</template>

<script>
import { doubleRAF } from '@ga/shared-browser'

export default {
  name: 'page-error',

  // serverCacheKey: (props) => props.error.statusCode,

  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  head() {
    const { title } = this

    return { title }
  },

  computed: {
    code() {
      return this.error && this.error.statusCode
    },

    title() {
      switch (this.code) {
        case 404:
          return this.$t('layouts.error.title.e404')
        case 500:
        default:
          return this.$t('layouts.error.title.e500')
      }
    },
  },

  created() {
    this.$gaApp.services.header.theme.setHeaderRegularColorScheme()
  },

  mounted() {
    doubleRAF(() => {
      window.scrollTo(0, 0)
    })
  },
}
</script>

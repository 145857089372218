import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGaUaProductBaseModel } from '../gtm-gaua-product-base.model/gtm-gaua-product-base.model'

export class GtmGaUaProductClickModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    super({ event: 'productClick' })

    const { list } = product

    this.ecommerce = {
      click: {
        actionField: {
          list,
        },
        products: [new GtmGaUaProductBaseModel(product)],
      },
    }
  }
}

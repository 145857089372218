export const endpoints = {
  products: (type) => ({
    url: type ? `review/products/${type}` : 'review/products',
  }),
  submit: () => ({
    url: 'review/form/submit',
  }),
  submitV2: () => ({
    url: 'review/form/submit/v2',
  }),
  uploadMedia: () => ({
    url: 'review/media/upload',
  }),
  gallery: () => ({
    url: 'review/gallery',
  }),
  filters: () => ({
    url: 'review/filters',
  }),
  getListingSlots: () => ({
    url: 'review/listing',
  }),
  mainReviews: () => ({
    url: 'review/listing/main',
  }),
  previewReviews: () => ({
    url: 'review/listing/preview',
  }),
  statistic: () => ({
    url: 'review/statistic',
  }),
  checkAvailableForReview: () => ({
    url: 'review/available-for-review',
  }),
  review: (reviewId) => ({
    url: `review/detail/${reviewId}`,
  }),
}

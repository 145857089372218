import { VIEW_QUEUE } from '../../components/organisms/base-stories-circles-slider'

export const getters = () => ({
  /** Сортируем сторисы по состоянию */
  sortedItems() {
    return [...this.items].sort(
      (a, b) => VIEW_QUEUE.indexOf(a.view) - VIEW_QUEUE.indexOf(b.view),
    )
  },
  /** Мапа id -> index */
  itemsIdMap() {
    return this.items.reduce((acc, item, index) => {
      acc[item.id] = index
      return acc
    }, {})
  },

  lastSlideIndex() {
    return this.items.length - 1
  },

  activeStory() {
    if (!this.items.length) return {}
    return this.sortedItems[this.activeSlideIndex]
  },

  activeStoryItem() {
    if (!this.items.length) return {}

    const { activeIndex } = this.activeStory
    return this.activeStory.items[activeIndex]
  },

  hasCatalogData() {
    return this.activeStoryItem.hasCatalogData
  },
})

import { maxValue } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (max, message) => {
  return withMessage(maxValue(max), {
    type: 'maxValue',
    max,
    message: 'app.validation.maxValue',
  })(message)
}

import {
  ArticleAllProductsButtonClickBuilder,
  ArticleAllProductsButtonShowBuilder,
  ArticleScrollBuilder,
  FilterStreamClickBuilder,
  ListingArticleClickBuilder,
  ListingArticleShowBuilder,
  ListingStreamCardClickBuilder,
  ListingStreamCardShowBuilder,
  ProductAddToCartBuilder,
  ProductAddToFavoritesBuilder,
  ProductClickBuilder,
  ProductPreviewBuilder,
  ProductShowBuilder,
  RecommendArticleClickBuilder,
  RecommendArticleShowBuilder,
  StreamRecommendCardClickBuilder,
  StreamRecommendCardShowBuilder,
  StreamWatchClickBuilder,
} from '../../builders'
import { ACTION_TYPES, EVENT_CATEGORIES } from '../../constants'
import { BaseController } from '../base.controller/base.controller'

export class ArticlesAnalytics extends BaseController {
  /**
   * 100% попадание карточки статьи во viewport
   */
  onListingArticleShow({ articleId, tags }) {
    this.send(ListingArticleShowBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.SHOW_ARTICLE_MAIN,
      articleId,
      tags,
    })
  }

  /**
   * Клик по статье на главной
   */
  onListingArticleClick({ articleId, tags }) {
    this.send(ListingArticleClickBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.CLICK_ARTICLE_MAIN,
      articleId,
      tags,
    })
  }

  /**
   * Показ кнопки "смотреть все продукты" в разных частях статьи
   */
  onArticleAllProductsButtonShow({ listName }) {
    this.send(ArticleAllProductsButtonShowBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.SHOW_BUTTON_ALL_PRODUCTS,
      listName,
    })
  }

  /**
   * Нажатие кнопки "смотреть все продукты" в разных частях статьи
   */
  onArticleAllProductsButtonClick({
    gtmListName,
    categoryListName,
    categoryListId,
    categoryUrl,
  }) {
    this.send(ArticleAllProductsButtonClickBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.CLICK_BUTTON_ALL_PRODUCTS,
      gtmListName,
      categoryUrl,
      categoryListName,
      categoryListId,
    })
  }

  /**
   * Показ статьи в слайдере статьи
   */
  onRecommendArticleShow({ articleId }) {
    this.send(RecommendArticleClickBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.SHOW_ARTICLE_RECOMEND,
      articleId,
    })
  }

  /**
   * Клик на статье в слайдере статьи
   */
  onRecommendArticleClick({ articleId }) {
    this.send(RecommendArticleShowBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.CLICK_ARTICLE_RECOMEND,
      articleId,
    })
  }

  /**
   * Глубина скролла в статье
   */
  onArticleScroll({ articleId, depth }) {
    this.send(ArticleScrollBuilder, {
      eventCategory: EVENT_CATEGORIES.MAGAZINE,
      actionType: ACTION_TYPES.SCROLL,
      articleId,
      depth,
    })
  }

  /**
   * Просмотр товаров в статье - Товар попал во viewport на 100%
   */
  onProductShow({ product }) {
    this.send(ProductShowBuilder, { product })
  }

  /**
   * Клик по товару - Пользователь кликнул/тапнул на одиночный товар
   */
  onProductClick({ product }) {
    this.send(ProductClickBuilder, { product })
  }

  /**
   * Добавление товара в корзину - Пользователь добавил товар в корзину
   */
  onAddProductToCart({ product }) {
    this.send(ProductAddToCartBuilder, { product })
  }

  /**
   * Добавление товара в избранное - Пользователь добавил товар в избранное
   */
  onAddProductToFavorites({ product }) {
    this.send(ProductAddToFavoritesBuilder, { product })
  }

  /**
   * Предпросмотр-карточки товара - Пользователь увидел модальное окно карточки товара
   */
  onProductPreview({ product }) {
    this.send(ProductPreviewBuilder, { product })
  }

  /**
   * 100% попадание карточки статьи во viewport
   */
  onListingStreamCardShow(data) {
    this.send(ListingStreamCardShowBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.SHOW_STREAM_MAIN,
      ...data,
    })
  }

  /**
   * Показ карточки стрима в слайдере - 100% попадание карточки стрима во viewport в рекомендательном блоке
   */
  onRecommendationStreamCardShow(data) {
    this.send(StreamRecommendCardShowBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.SHOW_STREAM_RECOMMEND,
      ...data,
    })
  }

  /**
   * Клик по фильтру на главной
   */
  onStreamsFilterClick({ filterName }) {
    this.send(FilterStreamClickBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.CLICK_FILTER,
      filters: filterName,
    })
  }

  /**
   * Клик на кнопку "смотреть стрим"
   */
  onStreamsWatchButtonClick({ streamName, state }) {
    this.send(StreamWatchClickBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.CLICK_STREAM_WATCH,
      streamName,
      state,
    })
  }

  /**
   * Клик по карточке стрима на главной
   */
  onListingStreamCardClick(data) {
    this.send(ListingStreamCardClickBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.CLICK_STREAM_MAIN,
      ...data,
    })
  }

  /**
   * Клик на карточку стрима в слайдере - Клик на стрим в слайдере
   */
  onRecommendationStreamCardClick(data) {
    this.send(StreamRecommendCardClickBuilder, {
      eventCategory: EVENT_CATEGORIES.STREAM,
      actionType: ACTION_TYPES.CLICK_STREAM_RECOMMEND,
      ...data,
    })
  }
}

import { SCREEN_TYPES } from '../../../constants'
import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsOrderProductClickModel extends GaAnalyticsBaseModel {
  constructor(product) {
    super('product')

    this.item = {
      itemId: product.itemId,
      itemListName: SCREEN_TYPES.ACCOUNT_ORDER,
    }

    this.debug = {
      key: 'customer-item-click',
      data: product,
    }
  }
}

import { getId } from '@ga/utils'

import { GaInputPhone as GaInputPhoneBase } from '@ga/ui-components/input-phone'

import { useModal } from '~/_base/use/modal'

// @vue/component
export default {
  name: 'ga-input-phone',
  components: { GaInputPhoneBase },
  inheritAttrs: false,
  setup() {
    const uniqueId = getId()

    const {
      zIndex: modalZIndex,
      onStartOpen: onModalStartOpen,
      onFinishClose: onModalFinishClose,
    } = useModal({ uniqueId })

    return {
      modalZIndex,
      onModalStartOpen,
      onModalFinishClose,
    }
  },
}

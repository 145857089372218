import { buildPlasticLink } from '~/modules/gift-cards'
import { buildCorporativeLink } from '~/modules/gift-cards-b2b'

import { buildSubmenuList } from './submenu'

export const CARDS_SUBSECTION_ROUTE = '/cards'

export const submenuCardsList = (i18n, options = {}) => {
  return buildSubmenuList([
    {
      id: 'digital',
      name: i18n.t('giftCards.nav.cards.digital.name'),
      link: i18n.t('giftCards.nav.cards.digital.link'),
    },
    {
      id: 'plastic',
      name: i18n.t('giftCards.nav.cards.plastic.name'),
      link: buildPlasticLink(i18n.t, options.isNewPlasticLink),
      nuxt: options.isNewPlasticLink,
    },
    {
      id: 'about',
      name: i18n.t('giftCards.nav.cards.about.name'),
      link: i18n.t('giftCards.nav.cards.about.link'),
    },
    {
      id: 'corporate',
      name: i18n.t('giftCards.nav.cards.corporate.name'),
      link: buildCorporativeLink(
        i18n.t('giftCards.nav.cards.corporate.link'),
        options,
      ),
      nuxt: options.isNewCorporateLink || options.isNewPlastic,
    },
  ])
}

import { SIZE as CONTROL_SIZE } from '../children/control'

export const SIZE = {
  XL: 'xl',
  L: 'l',
  M: 'm',
  S: 's',
}

export const COUNT_MOD = {
  1: 'one',
  2: 'two',
  3: 'three',
  MANY: 'many',
}

export const ITEMS_DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
}

export const CONTROLS_POSITION = {
  TOP: 'top',
  SIDES_CENTER: 'sides-center',
  SIDES_TOP: 'sides-top',
}

export const CONFIG_BY_SIZE = {
  [SIZE.S]: {
    controls: null,
  },
  [SIZE.M]: {
    controls: null,
  },
  [SIZE.L]: {
    showStartIndent: false,
  },
  [SIZE.XL]: {
    showStartIndent: false,
    itemsCount: 4,
  },
}

// для запуска авто-тестов

export const ROOT_TEST_ID = 'ga-product-slider'

export const ELEMENT_TEST_IDS = {
  heading: 'heading',
  item: 'item',
  linkCard: 'link-card',
}

export const DEFAULT_CONTROLS = {
  size: CONTROL_SIZE.M,
  position: CONTROLS_POSITION.TOP,
  showDisabled: true,
}

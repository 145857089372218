import { PAGE_TYPE } from '../constants'

export const routes = [
  {
    moduleType: 'checkout',
    pageType: PAGE_TYPE.THANK_YOU,
    path: '/checkout/success/:maskedId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/checkout/page/thank-you' */
        '~/modules/checkout/components/pages/thank-you.vue'
      ),
  },
]

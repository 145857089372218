export const TYPE = {
  GTM_4: 'gtm_4',
  MINDBOX: 'mindbox',
}

export const EVENT = {
  ACTION: {
    GA4: 'start_live_shopping',
  },
  CONSULTATION: {
    MINDBOX: 'Client.VideoConsultation',
  },
}

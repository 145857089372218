import { useContext } from '@nuxtjs/composition-api'

export const useCaptureError = ({ code, error }) => {
  const { $gaApp } = useContext()

  const newError = new Error(`Страница ошибки ${code}`)
  newError.dataError = JSON.stringify(error || {})
  newError.name = 'Page Error ' + code

  const captureError = () => {
    $gaApp.services.app.apm.captureError(newError)
  }

  return {
    captureError,
  }
}

export const moduleGiftCardsB2bSsrRoutes = [
  {
    moduleType: 'giftCardsB2b',
    pageType: 'plastic',
    path: '/b2b/cards/corporative/plastic',
    meta: {
      addressDisabled: true,
      analyticDisabled: false,
      authRequired: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards-b2b/page/plastic' */
      import('~/modules/gift-cards-b2b/components/pages/plastic.vue'),
  },
  {
    moduleType: 'giftCardsB2b',
    pageType: 'landing',
    path: '/b2b/cards/corporative',
    meta: {
      addressDisabled: true,
      analyticDisabled: false,
      authRequired: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards-b2b/page/landing' */
      import('~/modules/gift-cards-b2b/components/pages/landing.vue'),
  },
  {
    moduleType: 'giftCardsB2b',
    pageType: 'b2b-receive',
    path: '/b2b/cards/receive/:id',
    meta: {
      addressDisabled: true,
      analyticDisabled: false,
      authRequired: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards-b2b/page/receive' */
      import('~/modules/gift-cards/components/pages/receive/_id.vue'),
  },
  {
    moduleType: 'giftCardsB2b',
    pageType: 'digital',
    path: '/b2b/cards/corporative/digital',
    meta: {
      addressDisabled: true,
      analyticDisabled: false,
      authRequired: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards-b2b/page/digital' */
      import('~/modules/gift-cards-b2b/components/pages/digital.vue'),
  },
]

export const routes = [
  ...moduleGiftCardsB2bSsrRoutes,
  {
    moduleType: 'giftCardsB2b',
    pageType: 'email-confirm',
    path: '/b2b/cards/corporative/email-confirm',
    meta: {
      addressDisabled: true,
      analyticDisabled: false,
      authRequired: false,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards-b2b/page/email-confirm' */
      import('~/modules/gift-cards-b2b/components/pages/email-confirm.vue'),
  },
]

export const getStateInitial = () => ({
  isOpenAuthConfirm: false,

  itemsLoading: [],

  couponState: {
    errorMessage: null,
    isLoading: false,
  },

  deleteSectionName: null,

  isOpenDialog: false,
  typeDialog: null,

  widthModal: 'auto',
})

export default () => {
  return getStateInitial()
}

export class LandingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchLandingData() {
    try {
      const { data } = await this.gaApp.services.brandzone.api.fetchLanding()

      /**
       * Используется одна и та же стора, что и для БЗ.
       * В будущем, нужно разделить сторы для БЗ и лендинга.
       */
      this.gaApp.services.brandzone.page.setDataOnPageCreated(data)
    } catch (error) {
      const statusCode = error.statusCode >= 500 ? 500 : 404

      return this.gaApp.redirectError({
        statusCode,
        message: error.message,
      })
    }
  }
}

import { WEB_VIEW_ICON_COLOR, WEB_VIEW_QUERY_PARAM } from './index'

export const ARTICLE_PAGE_WEB_VIEW_PARAMS = {
  [WEB_VIEW_QUERY_PARAM.shareable]: 1,
  [WEB_VIEW_QUERY_PARAM.iconColorNav]: WEB_VIEW_ICON_COLOR.black,
}

export const LISTING_PAGE_WEB_VIEW_PARAMS = {
  [WEB_VIEW_QUERY_PARAM.iconColorNav]: WEB_VIEW_ICON_COLOR.white,
}

export const WEB_VIEW_PAGE_TYPE = {
  LISTING: 'listing',
  ARTICLE: 'article',
}

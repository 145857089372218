import { ELEMENT_TEST_IDS } from '../constants'

/**
 * Возвращает объект со значениями data-test-id для элементов карточки продукта.
 *
 * @param {string} rootTestId — значение рутового testId карточки продукта.
 * @return {Object} — объект со значениями data-test-id для элементов карточки продукта..
 */
export const useTestIds = (rootTestId) => {
  const getModifiedTestIds = (testIds, prefix) => {
    const modifiedTestIds = {}

    Object.entries(testIds).forEach(([key, value]) => {
      if (typeof value === 'object') {
        modifiedTestIds[key] = getModifiedTestIds(value, prefix)
      } else {
        modifiedTestIds[key] = `${prefix}__${value}`
      }
    })

    return modifiedTestIds
  }

  const modifiedTestIds = getModifiedTestIds(ELEMENT_TEST_IDS, rootTestId)

  return {
    root: rootTestId,
    ...modifiedTestIds,
  }
}

import { GaButtonBase } from '@ga/ui-components/button-base'
import { GaLinkBase } from '@ga/ui-components/link-base'

import GaHeaderNavFullExtensionListItem from './children/nav-full-extenstion-list-item'

const MAX_LEVEL = 2

// @vue/component
export default {
  name: 'ga-header-nav-full-extension-list',

  components: {
    GaButtonBase,
    GaLinkBase,
    GaHeaderNavFullExtensionListItem,
  },

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      hoveredItem: null,

      maxLevel: MAX_LEVEL,
    }
  },

  computed: {
    listIndependentScroll() {
      return this.$gaApp.features.get('navIndependentScroll')
    },

    hovered() {
      return this.hoveredItem !== null
    },

    rootMods() {
      const { level } = this.data

      const selected = this.isItemExpanded(this.data)

      const root = level === 0
      const child = level > 0
      const scrolling = this.listIndependentScroll

      return { root, child, selected, scrolling }
    },

    listMods() {
      const { hovered } = this
      const parental = this.isItemParental(this.data)
      const scrolling = this.listIndependentScroll

      return { hovered, parental, scrolling }
    },
  },

  methods: {
    onItemMouseEnter(id) {
      this.hoveredItem = id
    },

    onItemMouseLeave(id) {
      window.requestAnimationFrame(() => {
        if (this.hoveredItem === id) {
          this.hoveredItem = null
        }
      })
    },

    getModsItem(item, index) {
      const { mods, level } = item

      const selected = this.isItemExpanded(item) && index > 0

      const itemMods = {
        ...mods,
        selected,
      }

      if (level > this.maxLevel) {
        return {
          ...itemMods,
          parent: false,
        }
      }

      return itemMods
    },

    isItemExpanded(item) {
      return this.$gaApp.services.header.nav.getIsItemExpanded(item)
    },

    isItemRendered(item) {
      return this.$gaApp.services.header.nav.getIsItemRendered(item)
    },

    isItemParental(item) {
      return this.$gaApp.services.header.nav.getIsItemParental(item)
    },

    resetScroll() {
      this.$refs.listRef.scrollTop = 0

      this.$refs.childListRef?.forEach((childList) => {
        childList?.resetScroll()
      })
    },
  },
}

export const findQueryPosition = (
  query,
  startPos,
  querySize,
  apostropheIndex,
) => {
  let start = startPos
  let size = querySize

  if (apostropheIndex >= 0 && apostropheIndex < start) {
    start += 1
  }

  if (apostropheIndex >= start && apostropheIndex <= start + size) {
    size += 1
  }

  if (!query) {
    start = apostropheIndex
    size = 1
  }

  return { start, size }
}

import { MODULE_TYPE } from '../consts'

export const moduleGameSsrRoutes = [
  {
    moduleType: MODULE_TYPE,
    pageType: 'main',
    path: '/game/:name',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/game/page/main' */
        '~/modules/game/components/pages/main.vue'
      ),
  },
]

export const routes = [...moduleGameSsrRoutes]

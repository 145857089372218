export const TYPE = {
  GTM_4: 'gtm_4',
}

export const SOURCE = 'plaid_backend'

export const ACTIONS = {
  MENU: {
    GA_4: 'user_interaction',
  },
}

export const SCREEN_TYPES = {
  ACCOUNT_PURCHASED: 'account/my_purchases/products',
  ACCOUNT_PURCHASES: 'account/my_purchases',
}

export const EVENT_CATEGORIES = {
  ACCOUNT: 'user_account',
}

export const ACTION_TYPES = {
  CLICK_BACK_TO_SHOPPING: 'click_back_to_shopping',
}

export const CHUNK_SIZE_ANALYTICS = 5

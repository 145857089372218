import { EVENT, EVENT_CATEGORY } from '../../../constants'

export class GtmPromptChipClickModel {
  constructor({ text }) {
    this.event = EVENT.USER_INTERACTION
    this.event_category = EVENT_CATEGORY
    this.action_type = 'click_neural_hint_bubble'
    this.text = text
  }
}

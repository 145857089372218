export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    this.gaApp.services.home.main.setPageHeading(redirect?.seo?.header || '')

    return this.gaApp.services.home.slots.fetchSlotsData()
  }
}

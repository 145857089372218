export const TYPE = {
  GTM_4: 'gtm_4',
  GAA: 'ga_analytics',
}

export const ACTIONS = {
  MODAL_FILTERS_OPENED: {
    GA_4: 'user_interaction',
  },
}

export const DEFAULT_EVENT_NAME = 'user_interaction'
export const DEFAULT_EVENT_CATEGORY = 'filters'

export const FILTER_KEY = {
  SORTING: 'sort',
  EXPRESS_DELIVERY: 'expressdelivery',
}

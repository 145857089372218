export class SchedulerService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  postTask(task, priority = 'user-visible') {
    if (this.gaApp.isServer) {
      return task()
    }

    return window.scheduler.postTask(task, { priority })
  }

  async postTasks(tasks) {
    while (tasks.length > 0) {
      const { task, priority } = tasks.shift()

      await this.postTask(task, priority)
    }
  }
}

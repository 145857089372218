import { PAGE_TYPE } from '~/modules/checkout/constants'

import { ITEM_STATUS } from '../constants'
import { getCartStateInitial } from '../store/main/state'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // get isCacheData() {
  //   return this.gaApp.stores.cart.main.isCacheData
  // }

  get sections() {
    return this.gaApp.stores.cart.main.mapSections
  }

  get wholeStockSku() {
    return this.gaApp.stores.cart.main.ids.wholeStock
  }

  // async fillData() {
  //   if (!this.isCacheData) {
  //     await this.gaApp.services.cart.api.getData()
  //   }
  // }

  getItems() {
    return this.gaApp.stores.cart.main.items
  }

  getItem(id) {
    return (
      this.gaApp.stores.cart.main.items.find((item) => item.id === id) || null
    )
  }

  getProductItem(sku) {
    return this.gaApp.stores.cart.main.itemsHashTable?.[sku] ?? null
  }

  getProductId(sku) {
    return this.getProductItem(sku)?.id
  }

  getSectionIds(sectionName) {
    return (
      this.gaApp.stores.cart.main.mapSections
        .get(sectionName)
        ?.map(({ id }) => id) ?? []
    )
  }

  getSectionSkus(sectionName) {
    return (
      this.gaApp.stores.cart.main.mapSections
        .get(sectionName)
        ?.map(({ sku }) => sku) ?? []
    )
  }

  getItemsIds() {
    return this.gaApp.stores.cart.main.items.map(({ id }) => id)
  }

  checkProductWholeStock(sku) {
    return (
      this.gaApp.stores.cart.main.itemsHashTable?.[sku]?.canAddMoreFromPDP ===
      false
    )
  }

  checkProductError(sku) {
    return this.gaApp.stores.cart.main.itemsHashTable?.[sku]?.errors?.length > 0
  }

  getItemStatus(item) {
    switch (true) {
      case item.view === 'outofstock':
        return ITEM_STATUS.OUT_OF_STOCK

      case !item.canAddMore:
        return ITEM_STATUS.CANT_ADD_MORE

      case item.stockExceeded:
        return ITEM_STATUS.MORE_THAN_IN_STOCK

      default:
        return ITEM_STATUS.OUT_OF_STOCK
    }
  }

  getItemsStatuses() {
    return this.gaApp.stores.cart.main.items.map((item) => ({
      id: item.id,
      qty: item.qty,
      itemPrice: item.price?.actual,
      rowPrice: item.price?.row,
      stockFlag: this.getItemStatus(item),
    }))
  }

  setCart(cart) {
    this.gaApp.stores.cart.main.$patch(cart)

    this.gaApp.services.cart.modal.updateCouponData()
    this.gaApp.services.cart.price.setTotalsWeight()
  }

  setCoupon(value) {
    this.gaApp.stores.cart.main.totals.coupon = value
  }

  setAvailableCoupons(coupons) {
    this.gaApp.stores.cart.main.totals.availableCoupons = coupons
  }

  setCache(cart) {
    this.setCart(cart)
    this.gaApp.stores.cart.main.isCacheData = true
  }

  resetState() {
    this.setCart(getCartStateInitial())
  }

  /**
   * Запрос корзины при инициализации страницы
   * если мы на thankYou page - предполагаем что заказ оплачен
   * и перед запросом чистим id гостевой корзины
   */
  getData() {
    const unsubscribe = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      ({ pageType }) => {
        if (pageType === PAGE_TYPE.THANK_YOU) {
          this.gaApp.services.cart.guest.removeId()
        }

        // очистка id в page-created чтобы по-красоте проверять что мы на странице успешной оплаты
        // getData вложен в page-created потому что getData нужно дергать после очистки id
        // отписываемся чтобы вызывать getData только один раз
        // исходим из того что на страницу успешной оплаты можно перейти только по внешней ссылке

        this.gaApp.services.cart.api.getData()
        unsubscribe()
      },
    )
  }

  // Метод для перехода на чекаут с проверкой корзины
  async toCheckoutWithChecking() {
    await this.gaApp.services.cart.api.getData()

    if (this.gaApp.stores.cart.main.validForOrder) {
      this.gaApp.services.cart.modal.redirectToCheckout()
      await new Promise(() => {}) // Ожидание пока происходит переход на чекаут
    }
  }
}

import { ref } from 'vue'

export const useItemIntersectionObserver = (emit, itemsRef) => {
  const observer = ref([])

  const threshold = [0.98, 0.99, 1]

  const connect = (item, index) => {
    if (!observer.value[index]) {
      observer.value.push(
        new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              // Карточки которые имеют ширину больше 0,
              // отображаются на текущий момент в слайдере
              if (
                !entry.isIntersecting ||
                entry.boundingClientRect.width <= 0
              ) {
                return false
              }

              // Элемент полностью в области наблюдения
              // Значение 0.97 потому, что карточка продукта в слайдере рекомендаций может незаметно обрезаться на несколько пикселей,
              // из-за чего пересечение на 100% может никогда не произойти. Пересечение более 97% можно считать полным.
              if (entry.intersectionRatio > 0.97) {
                emit('full-intersect-card', { ...item, index })

                observer.value[index].unobserve(entry.target)
              }
            })
          },
          {
            threshold,
          },
        ),
      )
    }

    if (itemsRef.value[index]) {
      observer.value[index].observe(itemsRef.value[index])
    }
  }

  const disconnect = () => {
    observer.value.forEach((observerInstanse) => {
      observerInstanse?.disconnect()
    })
  }

  return {
    connect,
    disconnect,
  }
}

import { ApiService } from './api.service'
import { CacheService } from './cache.service'
import { CardService } from './card.service'
import { DateService } from './date.service'
import { DetailService } from './detail.service'
import { FiltersService } from './filters.service'
import { FormService } from './form.service'
import { GalleryService } from './gallery.service'
import { ListingService } from './listing.service'
import { MainService } from './main.service'
import { MediaService } from './media.service'
import { ModalService } from './modal.service'
import { NotifyService } from './notify.service'
import { PageService } from './page.service'
import { PaginationService } from './pagination.service'
import { PreviewService } from './preview.service'
import { ProductsService } from './products.service'
import { SeoService } from './seo.service'
import { StatisticService } from './statistic.service'
import { UserService } from './user.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  form: new FormService(gaApp),
  media: new MediaService(gaApp),
  date: new DateService(gaApp),
  products: new ProductsService(gaApp),
  page: new PageService(gaApp),
  notify: new NotifyService(gaApp),
  gallery: new GalleryService(gaApp),
  filters: new FiltersService(gaApp),
  listing: new ListingService(gaApp),
  preview: new PreviewService(gaApp),
  detail: new DetailService(gaApp),
  user: new UserService(gaApp),
  seo: new SeoService(gaApp),
  pagination: new PaginationService(gaApp),
  modal: new ModalService(gaApp),
  cache: new CacheService(gaApp),
  card: new CardService(gaApp),
  statistic: new StatisticService(gaApp),
})

import { TYPE } from '../../constants'
import { GaAnalyticsMenuClickModel, GtmGa4MenuClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class MenuClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4MenuClickModel],
      [TYPE.GAA, GaAnalyticsMenuClickModel],
    ])
    super(models, data)
  }
}

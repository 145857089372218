import { TYPE } from '../../constants'
import { GtmGa4SearchUserInteraction } from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class SearchBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4SearchUserInteraction]])
    super(models, data)
  }
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const digitalStore = (gaApp) => {
  const init = defineStore('giftCardsB2bDigital', {
    state,
    getters: getters(gaApp),
  })

  return init(getActivePinia())
}

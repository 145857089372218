export const getters = (gaApp) => ({
  items() {
    return Object.values(this.reviews || {}).flat()
  },

  itemsHashTable() {
    return this.items.reduce((acc, item) => {
      if (item?.reviewId) {
        acc[item.reviewId] = item
      }
      return acc
    }, {})
  },
  hasReviews() {
    return this.items.length > 0
  },

  hasPagination() {
    return this.pageNumber > 1
  },

  hasPaginationWithFilters() {
    return gaApp.stores.review.filters.hasFiltersValue && this.hasPagination
  },
})

import { initFiltersData } from '../../constants/filters'

export const state = () => {
  return {
    media: false,
    stars: [],
    value: initFiltersData(),
    pending: false,
  }
}

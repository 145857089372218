import { ACTIONS } from '../../../constants'
import { GtmBaseModel, GtmGa4ProductAddToBaseModel } from '../../index'

export class GtmGa4ProductAddToCartModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.ADD_TO_CART.GA4)

    this.pdp_type = ''

    this.ecommerce = new GtmGa4ProductAddToBaseModel({ product })
  }
}

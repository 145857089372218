export class PromoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(this.gaApp.stores.brandzone.promo.promoData).includes(
      block.id,
    )
  }

  updateBlockData(block) {
    const index = this.gaApp.stores.brandzone.promo.promoData[block.id]?.index
    this.gaApp.stores.brandzone.promo.items[index].data = block.data
  }

  addBlockData(block) {
    this.gaApp.stores.brandzone.promo.items.push({
      id: block.id,
      data: block.data,
      type: block.type,
    })
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }

  addViewedId(id) {
    this.gaApp.stores.brandzone.promo.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.brandzone.promo.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }
}

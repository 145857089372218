<template>
  <svg fill="currentColor" viewBox="0 0 30 30">
    <g filter="url(#video-player-switch-video-a)">
      <path
        fill="inherit"
        fill-rule="evenodd"
        d="m4 25.428 17-10-17-10v20Zm22.5-20h-2v20h2v-20Z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="video-player-switch-video-a"
        width="34.8"
        height="34.8"
        x="-2.4"
        y="-2.4"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3186_4372"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3186_4372"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-switch-video',
}
</script>

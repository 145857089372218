import { computed, ref, unref } from 'vue'

import { getId } from '@ga/utils'

import {
  useAutoHeightStyles,
  useCounter,
  useInputState,
  useMods,
  useStateAttributes,
} from '../use'
import { getFitValue } from '../utils'

/**
 * Возвращает необходимые поля для инпута с маской (с Vue2 компонентом плагина https://www.npmjs.com/package/vue-imask)
 * @param {Ref<String>} value - значение инпута из props
 * @param {Ref<String>} size - размер из props
 * @param {Ref<String>} theme - тема из props
 * @param {Ref<Boolean>} error - параметр наличия ошибки из props
 * @param {Ref<Boolean>} disabled - параметр disabled из props
 * @param {Ref<Number>} maxLength - максимальное количество символов
 * @param {Ref<Boolean>} autoHeight - автоматически растет высота с ввводом
 * @param {Ref<Number>} minHeight - минимально возможная высота px
 * @param {Ref<Number>} maxHeight - максимально возможная высота px
 * @param {Ref<Number>} baseHeight - базовая высота инпута
 * @param {Ref<Boolean>} disallowEnter - разрешен ли перенос на следующую строк
 * @param {Function} emit - emit функция контекста
 * @param {Object} listeners - слушатели событий контекста
 */
export const useTextarea = (
  value,
  size,
  theme,
  error,
  disabled,
  maxLength,
  autoHeight,
  minHeight,
  maxHeight,
  baseHeight,
  disallowEnter,
  emit,
  listeners,
) => {
  const textareaRef = ref()

  const innerValue = computed({
    get: () => {
      return getFitValue(unref(value), unref(maxLength))
    },
    set: (newValue) => {
      emit('input', newValue)
    },
  })

  const onInput = (event) => {
    const { value: rawValue } = event.target
    const acceptedValue = unref(disallowEnter)
      ? rawValue.replace(/\r?\n|\r/gm, '')
      : rawValue
    event.target.value = acceptedValue
    innerValue.value = acceptedValue
  }

  const onKeydownEnter = (event) => {
    if (!unref(disallowEnter)) {
      return
    }

    event.preventDefault()
  }

  const nativeControlListeners = computed(() => {
    // исключение нативного input, т.к. он завязан на v-model и eмиттит единый input компонента
    const { input, ...activeNativeListeners } = listeners
    return activeNativeListeners
  })

  const { isHovered, isFocused, hasValue, focus } = useInputState(
    textareaRef,
    innerValue,
  )

  const { styleAutoHeight, hasScroll } = useAutoHeightStyles(
    textareaRef,
    innerValue,
    autoHeight,
    minHeight,
    maxHeight,
    baseHeight,
  )

  const { stateAttributes } = useStateAttributes({
    isHovered,
    isFocused,
    hasValue,
    disabled,
    error,
    hasScroll,
  })

  const { mods } = useMods(size, theme)

  const { counter } = useCounter(innerValue, maxLength)

  return {
    uuid: getId(),
    textareaRef,
    innerValue,
    onInput,
    onKeydownEnter,
    nativeControlListeners,
    stateAttributes,
    mods,

    focus,
    isFocused,
    hasValue,

    styleAutoHeight,

    counter,
  }
}

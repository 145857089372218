<template>
  <nuxt />
</template>

<script>
export default {
  name: 'layout-default-amp',
  head() {
    /**
     * Возвращает MetaInfo
     * addSeoAttributes — Добавляет различные атрибуты SEO.
     * https://i18n.nuxtjs.org/api#nuxti18nhead
     */
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    const siteName = this.$t('layouts.default.meta.siteName')

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,

        prefix: 'og: https://ogp.me/ns#',
        translate: 'no',
      },
      meta: [
        ...i18nSeo.meta,

        {
          hid: 'robots',
          name: 'robots',
          content: 'INDEX,FOLLOW',
        },

        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: siteName,
        },

        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          content: '/_static/favicons/mstile-144x144.png',
        },
        {
          name: 'msapplication-config',
          content: '/_static/scripts/browser-updater/browserconfig.xml',
        },
      ],
      link: [
        // amp вставляет свой canonical url и нам не нужен дубликат
        // ...i18nSeo.link,

        // TODO: посмотреть на показатели CLS и оценить проблемы,
        // связанные с приоретизацией запросов
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-Regular.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-RegularItalic.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-Medium.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/GraphikLCG-MediumItalic.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/DrukWide-Bold.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/DrukText-Medium.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/DrukCy-Medium.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/NeueMontreal-Regular.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/NeueMontreal-Bold.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/NeueMontreal-SemiBold.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/NeueMontreal-Medium.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'preload',
          as: 'font',
          type: 'font/woff2',
          href: '/_static/fonts/NeueMontreal-Thin.woff2',
          crossorigin: 'anonymous',
          fetchpriority: 'high',
        },
        {
          rel: 'mask-icon',
          href: '/_static/favicons/safari-pinned-tab.svg',
          color: '#000',
        },
        {
          rel: 'apple-touch-icon',
          href: '/_static/favicons/apple-touch-icon.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: '/_static/favicons/apple-touch-icon-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: '/_static/favicons/apple-touch-icon-57x57.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '57x57',
          href: '/_static/favicons/apple-touch-icon-57x57-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: '/_static/favicons/apple-touch-icon-60x60.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '60x60',
          href: '/_static/favicons/apple-touch-icon-60x60-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: '/_static/favicons/apple-touch-icon-72x72.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '72x72',
          href: '/_static/favicons/apple-touch-icon-72x72-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: '/_static/favicons/apple-touch-icon-76x76.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '76x76',
          href: '/_static/favicons/apple-touch-icon-76x76-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: '/_static/favicons/apple-touch-icon-114x114.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '114x114',
          href: '/_static/favicons/apple-touch-icon-114x114-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/_static/favicons/apple-touch-icon-120x120.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '120x120',
          href: '/_static/favicons/apple-touch-icon-120x120-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: '/_static/favicons/apple-touch-icon-144x144.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '144x144',
          href: '/_static/favicons/apple-touch-icon-144x144-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/_static/favicons/apple-touch-icon-152x152.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '152x152',
          href: '/_static/favicons/apple-touch-icon-152x152-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '167x167',
          href: '/_static/favicons/apple-touch-icon-167x167.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '167x167',
          href: '/_static/favicons/apple-touch-icon-167x167-precomposed.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/_static/favicons/apple-touch-icon-180x180.png',
        },
        {
          rel: 'apple-touch-icon-precomposed',
          sizes: '180x180',
          href: '/_static/favicons/apple-touch-icon-180x180-precomposed.png',
        },

        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/_static/favicons/favicon-16x16.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/_static/favicons/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '194x194',
          href: '/_static/favicons/favicon-194x194.png',
        },

        {
          rel: 'shortcut icon',
          type: 'image/ico',
          href: '/_static/favicons/favicon.ico',
        },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/_static/favicons/favicon.ico',
        },

        {
          rel: 'manifest',
          href: '/_static/site.webmanifest',
        },
      ],
    }
  },
}
</script>

export const endpoints = {
  fetchProductsBySku: () => ({
    url: 'catalog/products-by-sku',
  }),

  fetchArticle: (id) => ({
    url: `articles/article/preview/${id}`,
  }),

  fetchArticleV2: (id) => ({
    url: `articles/article/preview/v2/${id}`,
  }),

  fetchArticleByUrl: (url) => ({
    url: `articles/article/${url}`,
  }),

  fetchArticleByUrlV2: (url) => ({
    url: `articles/article/v2/${url}`,
  }),

  fetchArticleByUrlPost: (url) => ({
    url: `articles/article/p/${url}`,
  }),

  fetchFilters: () => ({
    url: 'articles/filters/main',
  }),

  fetchArticlesListing: () => ({
    url: 'articles/listing/main',
  }),

  fetchCategoryDataByUrl: () => ({
    url: 'catalog/redirect',
  }),

  fetchProducts: () => ({
    url: 'catalog/products',
  }),

  fetchRubricArticles: () => ({
    url: 'articles/listing/rubrics',
  }),

  fetchPreviewListing: (id) => ({
    url: `articles/listing/main/preview/${id}`,
  }),

  fetchArticleViewed: () => ({
    url: 'articles/viewed',
  }),

  fetchArticleViewedWebview: () => ({
    url: 'articles/webview/viewed',
  }),
}

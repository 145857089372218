<template>
  <svg fill="none" viewBox="0 0 157 50">
    <path
      stroke="#inherit"
      stroke-width="inherit"
      d="M84.922 37.875h-.875v1.505c0 3.126.83 8.299 5.585 12.675 4.739 4.363 13.233 7.8 28.26 7.8h3.36c14.207 0 22.86-2.386 27.978-5.987 5.186-3.648 6.637-8.464 6.637-12.948v-.49c0-4.302-1.605-7.452-3.911-9.654-1.468-1.402-3.203-2.404-4.954-3.087 1.294-.516 2.557-1.215 3.647-2.116 1.924-1.59 3.328-3.834 3.328-6.773v-.42c0-1.51-.198-3.214-1.028-4.907-.835-1.702-2.277-3.323-4.654-4.72-4.708-2.766-13.149-4.688-28.163-4.688h-3.01c-14.797 0-23.025 3.079-27.557 6.994-4.565 3.943-5.238 8.607-5.238 11.381v1.575h21.962l.141-.703c.016-.082.03-.163.044-.244.045-.265.091-.536.237-.883.179-.423.514-.946 1.213-1.467 1.427-1.065 4.433-2.163 10.808-2.163 6.432 0 9.724.548 11.387 1.244.809.34 1.155.683 1.317.943.162.259.211.545.211.928v.07c0 .575-.163 1.014-.724 1.37-.651.415-1.915.765-4.281.765h-17.815v14.21h18.305c2.528 0 3.921.416 4.665.93.672.464.9 1.07.9 1.835 0 .426-.059.833-.273 1.225-.211.388-.619.842-1.455 1.282-1.719.902-5.089 1.658-11.607 1.658-6.391 0-9.595-1.461-11.213-2.902-1.602-1.426-1.788-2.953-1.848-3.465l-.091-.773H84.922Zm-5.347-4.935v-2.1c0-4.918-1.148-11.654-6.495-17.156-5.35-5.505-14.752-9.619-30.92-9.619h-3.15c-16.203 0-25.622 4.113-30.98 9.618-5.356 5.502-6.505 12.238-6.505 17.157v2.1c0 4.85 1.15 11.618 6.501 17.171 5.358 5.558 14.777 9.744 30.984 9.744h3.15c16.173 0 25.574-4.186 30.923-9.744 5.343-5.553 6.492-12.321 6.492-17.171Zm-22.4-1.68v.91c0 2.553-.8 5.467-3.201 7.748-2.403 2.282-6.532 4.047-13.424 4.047-6.887 0-11.035-1.797-13.457-4.107-2.424-2.31-3.238-5.245-3.238-7.758v-.84c0-2.525.75-5.343 3.123-7.54 2.38-2.203 6.526-3.905 13.572-3.905 6.9 0 11.033 1.7 13.434 3.908 2.395 2.202 3.191 5.024 3.191 7.537Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-number-3',
}
</script>

import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

export class ErrorWrapper {
  constructor(error, dataRequest) {
    console.log(error)
    if (isObject(dataRequest)) {
      this.dataRequest = JSON.stringify(dataRequest)
    }
    if (isString(dataRequest)) {
      this.dataRequest = dataRequest
    }

    if (isObject(error.response?.data)) {
      this.dataResponse = JSON.stringify(error.response?.data)
    }
    if (isString(error.response?.data)) {
      this.dataResponse = error.response?.data
    }

    this.stack = error.stack

    this.type = this.name
    this.name = 'ApiRequest'
    this.data = error.response?.data || {}

    this.reason = this.data.reason
    this.statusCode = this.data.statusCode || error.response?.status
    this.statusMessage =
      this.data.message || error.response?.statusText || error.message || ''

    // Иногда могут переписать по месту, иза этого есть дубль statusMessage
    this.message =
      this.data.message || error.response?.statusText || error.message || ''

    this.localizedMessage = this.data.localizedMessage
    this.id = this.data.errorId
    this.invalidParameters = this.data.invalidParameters
  }
}

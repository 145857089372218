export class AboutService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Заполняет данные для страницы "о картах"
   */
  async getAboutPage() {
    try {
      const data = await this.gaApp.services.giftCards.api.getDataAboutPage()
      this.gaApp.stores.giftCards.about = data
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получение данных для страницы О картах
   */
  getAboutPageData() {
    const promises = [this.getAboutPage()]

    const isMenuFetchEnabled = this.gaApp.features.get('giftCardsMenuFetch')

    if (isMenuFetchEnabled)
      promises.push(this.gaApp.services.giftCards.main.getMainPageMenu())

    return Promise.all(promises)
  }
}

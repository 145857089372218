<template>
  <svg fill="inherit" viewBox="0 0 34 30">
    <g
      filter="url(#video-player-forward-animated-a)"
      fill="inherit"
      stroke="none"
    >
      <path
        fill-rule="evenodd"
        d="M7.421 7.42c4.27-4.27 11.18-4.278 15.434-.024 2.757 2.757 3.725 6.63 2.9 10.177l-1.976-1.976-1.29-1.29-1.06 1.06 1.29 1.29 3.097 3.098.53.53.53-.53 2.84-2.84 1.42-1.419-1.061-1.06-1.42 1.419-1.284 1.284c.634-3.813-.515-7.864-3.455-10.804-4.843-4.842-12.703-4.829-17.556.024-4.853 4.853-4.866 12.713-.023 17.556a12.354 12.354 0 0 0 9.464 3.604c1.6-.091 3.186-.49 4.663-1.197l-.648-1.353a10.93 10.93 0 0 1-4.1 1.052 10.854 10.854 0 0 1-8.319-3.167C3.143 18.6 3.151 11.69 7.421 7.42Z"
        clip-rule="evenodd"
      />
      <path
        d="M14.932 20.378c-2.17 0-3.178-1.19-3.332-2.828h1.162c.196 1.092.644 1.848 2.156 1.848 1.358 0 2.198-.91 2.198-2.422 0-1.414-.812-2.338-2.1-2.338-1.176 0-1.82.364-2.366.966h-.448v-5.362h5.628v1.05h-4.62v3.052c.406-.378 1.092-.686 2.044-.686 1.792 0 3.108 1.274 3.108 3.318 0 2.128-1.526 3.402-3.43 3.402Z"
      />
    </g>
    <defs>
      <filter
        id="video-player-forward-animated-a"
        width="33.788"
        height="32.652"
        x="-.326"
        y="-1.326"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.163" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3186_4385"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3186_4385"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-forward-animated',
}
</script>

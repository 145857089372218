export const routes = [
  {
    moduleType: 'productCard',
    pageType: 'vertical',
    path: '/product-card/vertical',
    meta: {
      authRequired: false,
      analyticDisabled: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/product-card/page/demo-vertical' */
        '~/modules/product-card/components/pages/demo-vertical.vue'
      ),
  },
  {
    moduleType: 'productCard',
    pageType: 'horizontal',
    path: '/product-card/horizontal',
    meta: {
      authRequired: false,
      analyticDisabled: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/product-card/page/demo-horizontal' */
        '~/modules/product-card/components/pages/demo-horizontal.vue'
      ),
  },
  {
    moduleType: 'productCard',
    pageType: 'large',
    path: '/product-card/large',
    meta: {
      authRequired: false,
      analyticDisabled: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/product-card/page/demo-large' */
        '~/modules/product-card/components/pages/demo-large.vue'
      ),
  },
]

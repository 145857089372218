import { TYPE } from '../../constants'
import {
  GtmGa4ShareAddToCartModel,
  MindboxShareAddToCartModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ShareAddToCartBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ShareAddToCartModel],
      [TYPE.MINDBOX, MindboxShareAddToCartModel],
    ])

    super(models, data)
  }
}

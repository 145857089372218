import { TYPE } from '../../constants'
import { GtmSelectTimeModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class SelectTimeBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmSelectTimeModel]])
    super(models, data)
  }
}

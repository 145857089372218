import { set as vueSet } from 'vue'

/**
 *
Предоставляет методы для управления галереей медиафайлов отзывов:

`openModal()` - открывает модальное окно галереи.

`closeModal()` - закрывает модальное окно галереи.

`setMediaForModal({ index, reviewId = null })` - устанавливает активный индекс и идентификатор отзыва для модального окна галереи и вызывает openModal().

`setChunkMedia({ pageNumber, media })` - устанавливает медиафайлы для указанного номера страницы в store галереи с помощью vueSet для сохранения реактивности vue2.

`incrementPageNumber()` - увеличивает номер страницы в store галереи на 1.

`forward()` - переходит к следующей странице галереи, увеличивая номер страницы и вызывая fetchGallery для загрузки следующей страницы медиафайлов.

`filledStore(data)` - заполняет store галереи медиафайлами и общим количеством медиафайлов из переданных данных.
 */
export class GalleryService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openModal() {
    if (!this.gaApp.stores.review.gallery.wasModalOpened) {
      this.gaApp.stores.review.gallery.wasModalOpened = true
    }

    this.gaApp.stores.review.gallery.modalOpened = true

    this.gaApp.analytics.modules.review.onOpenGallery()
  }

  closeModal() {
    this.gaApp.stores.review.gallery.modalOpened = false
  }

  setMediaForModal({ index, reviewId = null }) {
    this.gaApp.stores.review.gallery.activeReviewId = reviewId
    this.gaApp.stores.review.gallery.activeIndex = index

    this.openModal()
  }

  setChunkMedia({ pageNumber, media }) {
    // необходимо из-за потери реактивности во вновь создаваемом объекте (vue2)
    vueSet(this.gaApp.stores.review.gallery.media, pageNumber, media)
  }

  incrementPageNumber() {
    this.gaApp.stores.review.gallery.pageNumber += 1
  }

  forward() {
    this.incrementPageNumber()
    this.gaApp.services.review.api.fetchGallery()
  }

  filledStore(data) {
    if (!data) {
      return
    }

    const { pageNumber } = this.gaApp.stores.review.gallery

    this.setChunkMedia({
      pageNumber,
      media: data?.media,
    })

    this.gaApp.stores.review.gallery.mediaCount = data?.mediaCount
  }
}

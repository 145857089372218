export const state = () => {
  return {
    productId: null,
    data: {},
    pending: false,
    hasError: false,
    // якорь для скролинга при нажатии кнопки назад из листинга
    anchor: null,
    updateAt: 0,
  }
}

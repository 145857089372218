<template>
  <svg viewBox="0 0 40 40">
    <path
      fill-rule="evenodd"
      stroke-width="0"
      d="M25.96 5.96 40 20 25.96 34.04l-1.415-1.414L36.172 21H1v-2h35.172L24.545 7.374 25.96 5.96Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-search-big-thin-arrow',
}
</script>

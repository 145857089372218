<template>
  <svg viewBox="0 0 24 24">
    <path fill="none" stroke-width="1.5" d="m17 8-5-5-5 5" />
    <path fill="none" stroke-width="1.5" d="M12 16V4" />
    <path fill="none" stroke-width="1.5" d="M4 14v7h16v-7" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-share',
}
</script>

import { getCountryCode } from '.'

/**
 * Возвращает код региона номера телефона
 * ```text
 * ru-RU, ru-KZ:
 *   +799999999999
 *     ^
 * ru-BY:
 *   +37599999999999
 *       ^
 * ```
 * @param {string} localeNameIso iso-код локали страны
 * @param {string} clearPhoneNumber номер телефона без маски
 * @returns
 */
export const getPhoneRegionCode = (localeNameIso, clearPhoneNumber) => {
  const countryCode = getCountryCode(localeNameIso)

  if (!countryCode) {
    return null
  }

  const position = {
    RU: 2,
    BY: 4,
    KZ: 2,

    // todo: Добавить номера для других регионов
    // QA: [],
  }[countryCode]

  if (!position) {
    return null
  }

  const includesPlus = /^\+/.test(clearPhoneNumber)
  const positionWithPlus = position - Number(!includesPlus)

  return Number(clearPhoneNumber[positionWithPlus])
}

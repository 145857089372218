export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getBrandName() {
    return this.gaApp.router.currentRoute.path.match(
      /^\/brands\/([^/]+?)(\/|$|\?)/,
    )?.[1]
  }

  findSlotById(blockId) {
    const items = this.gaApp.stores.brandzone.slots.items
    const result = {
      index: null,
      item: null,
    }

    for (const [index, item] of items.entries()) {
      if (item.id === blockId) {
        result.index = index
        result.item = item

        break
      }
    }

    return result
  }
}

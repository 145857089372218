import {EVENT_TYPES} from '../../../constants'

export class DigiAnalyticsProductCartModel {
  constructor({product, placement}) {
    this.data = {
      eventType: EVENT_TYPES.CART_ADD_EVENT,
      productId: product.id,
      price: product.price,
      productName: product.name,
      sku: this.generateSku(placement),
    }
  }

  generateSku(placement) {
    const sku = ['recsClick']

    //разделили для генерации без привязки ко всем полям
    if (placement.name) {
      sku.push(placement.name)
    }
    if (placement.id) {
      sku.push(placement.id)
    }
    if (placement.strategyName) {
      sku.push(placement.strategyName)
    }

    return sku.join('|')
  }
}


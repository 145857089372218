import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4StreamRecommendCardClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const {
      eventCategory,
      actionType,
      position,
      streamName,
      streamParentName,
    } = data

    this.event_category = eventCategory
    this.action_type = actionType

    this.position = position
    this.stream_name = streamName
    this.stream_name_parent = streamParentName
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGaUaProductBaseModel } from '../gtm-gaua-product-base.model/gtm-gaua-product-base.model'

export class GtmGaUaProductViewModel extends GtmBaseModel {
  constructor({ chunk }) {
    if (!chunk.length) {
      throw new Error('data chunk is required')
    }

    super({ event: 'productImpression' })

    const { currency, list } = chunk[0]

    this.item_list_name = list

    this.ecommerce = {
      currencyCode: currency,
      impressions: chunk.map((product) => new GtmGaUaProductBaseModel(product)),
    }
  }
}

export class RegisterService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  add(key) {
    if (this.gaApp.stores.modal.register.modals.includes(key)) {
      return
    }

    this.gaApp.stores.modal.register.modals.push(key)

    this.onModalsUpdate()
  }

  remove(key) {
    const index = this.gaApp.stores.modal.register.modals.indexOf(key)

    if (index !== -1) {
      this.gaApp.stores.modal.register.modals.splice(index, 1)
      this.gaApp.eventBus.publish('module/modal/register/remove', key)
    }

    this.onModalsUpdate()
  }

  onModalsUpdate() {
    const hasModals = this.gaApp.stores.modal.register.hasModals

    this.gaApp.services.app.pageScroll.update(hasModals)
  }
}

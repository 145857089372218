// This is a mock version because gdeslon module is disabled
// You can explicitly enable module using `gdeslon.enabled: true` in nuxt.config
import { log } from './gdeslon.utils'

const id = 'MOCK_ID'
const autoInit = false;

export default function (context, inject) {
    log('Using mocked API. Real GDESLON events will not be reported.')

    const gdeslon = {
        create: () => {
            log('create', id)
        },

        push: (event) => {
            log('push', event)

            if (typeof event.eventCallback === 'function') {
                event.eventCallback()
            }
        },

        thanks: (event) => {
            log('thanks', event)

            if (typeof event.eventCallback === 'function') {
                event.eventCallback()
            }
        },
    }

    if (autoInit && id) {
        gdeslon.create()
    }

    context.$gdeslon = gdeslon

    inject('gs', context.$gdeslon)
}

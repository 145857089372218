<template>
  <svg fill="none" viewBox="0 0 16 15">
    <path
      stroke="currentColor"
      d="M11.033 3.501v7.733a3.267 3.267 0 1 1-6.533 0V2.68a2.178 2.178 0 1 1 4.356 0v7.233a1.089 1.089 0 1 1-2.178 0v-6.41"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-clip',
}
</script>

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4CartItemModel } from '../gtm-ga4-cart-item.model/gtm-ga4-cart-item.model'

export class GtmGa4ViewModel extends GtmBaseModel {
  constructor({ items, currency }) {
    if (!items) {
      throw new Error('product is required')
    }

    super({ event: EVENT.VIEW.GA4 })

    this.event_source = 'plaid_backend'

    this.ecommerce = {
      currency,
      items: items.map((item) => new GtmGa4CartItemModel(item)),
    }
  }
}

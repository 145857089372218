import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MoreClickModel extends GtmBaseModel {
  constructor({ type }) {
    super({})

    this.action_type = this.buildActionType(type)
  }

  buildActionType(type) {
    return {
      digital: 'click_more_kepk',
      plastic: 'click_more_kppk',
    }[type]
  }
}

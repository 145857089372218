/**
 *
 * @param {Date} date1
 * @param {string} sign - ['>','<','=']
 * @param {Date} date2
 */
export const dateCompare = (date1, sign, date2) => {
  const day1 = date1.getDate()
  const mon1 = date1.getMonth()
  const year1 = date1.getFullYear()
  const day2 = date2.getDate()
  const mon2 = date2.getMonth()
  const year2 = date2.getFullYear()

  const isEqual = day1 === day2 && mon1 === mon2 && year1 === year2

  const isGreater =
    year1 > year2 ||
    (year1 === year2 && mon1 > mon2) ||
    (year1 === year2 && mon1 === mon2 && day1 > day2)

  if (sign === '=') {
    return isEqual
  }

  if (sign === '>') {
    return isGreater
  }

  if (sign === '<') {
    return !isEqual && !isGreater
  }

  return null
}

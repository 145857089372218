/*
 * Добавляет в компонент поле `focused`, а также публичный метод `.focus()`
 */

import mixinNamespaceGenerator from './utils/mixin-namespace-generator'

const namespace = mixinNamespaceGenerator('focusable', [
  'el',

  'onFocus',
  'onBlur',

  'addEventListeners',
  'removeEventListeners',
])

const defaultOptions = {
  // ссылка на DOM-узел, на который буду повешены события
  ref: null,

  // свойство в `data` для внутреннего хранения состояния
  focusData: 'focused',

  // публичный метод для ручного фокусирования элемента
  focusMethod: 'focus',

  // публичный метод для ручного сброса фокуса элемента
  blurMethod: 'blur',
}

// у данных типов инпутов не срабатывает blur событие,
// поэтому нельзя устанавливать focused на click событие
const inputTypesWithoutClickEvent = ['checkbox', 'radio']

function checkIfNeedClickEvent($el) {
  const tag = $el.tagName.toLowerCase()
  const type = $el.getAttribute('type')

  return !(tag === 'input' && inputTypesWithoutClickEvent.includes(type))
}

export default (userOptions) => {
  let options = { ...defaultOptions }

  // предполагаем, что передана только ссылка на DOM-узел
  if (typeof userOptions === 'string') {
    options.ref = userOptions
  } else {
    options = { ...options, ...userOptions }
  }

  return {
    data() {
      return {
        [options.focusData]: false,
      }
    },

    computed: {
      [namespace.el]() {
        if (options.ref === null) {
          return null
        }

        const $ref = this.$refs[options.ref]

        return $ref ? $ref.$el || $ref : null
      },
    },

    mounted() {
      const $el = this[namespace.el]

      if ($el) {
        this[namespace.addEventListeners]($el)

        if (this.$attrs.autofocus) {
          this[options.focusMethod]()
        }
      }
    },

    destroyed() {
      const $el = this[namespace.el]

      if ($el) {
        this[namespace.removeEventListeners]($el)
      }
    },

    methods: {
      [options.focusMethod]() {
        // не будет работать в iOS без действия пользователя

        const $el = this[namespace.el]
        const { selectionStart, selectionEnd } = $el

        $el.focus()
        $el.click()

        if (selectionStart || selectionEnd) {
          window.requestAnimationFrame(() => {
            $el.selectionStart = selectionStart || 0
            $el.selectionEnd = selectionEnd || selectionStart || 0
          })
        }
      },

      [options.blurMethod]() {
        const $el = this[namespace.el]

        $el.blur()
      },

      [namespace.onFocus](event) {
        event.stopPropagation()

        this[options.focusData] = true
      },

      [namespace.onBlur](event) {
        event.stopPropagation()

        this[options.focusData] = false
      },

      [namespace.addEventListeners]($el) {
        const needClickEvent = checkIfNeedClickEvent($el)

        if (needClickEvent) {
          $el.addEventListener('click', this[namespace.onFocus], false)
        }

        $el.addEventListener('focus', this[namespace.onFocus], false)
        $el.addEventListener('blur', this[namespace.onBlur], false)
      },

      [namespace.removeEventListeners]($el) {
        $el.removeEventListener('click', this[namespace.onFocus])
        $el.removeEventListener('focus', this[namespace.onFocus])
        $el.removeEventListener('blur', this[namespace.onBlur])
      },
    },
  }
}

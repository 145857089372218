import isEqual from 'lodash/isEqual'

export const getProductSelected = ({ productCommon, attributesSelected }) => {
  const getProductByAttributes = (attributes) => {
    return productCommon.variants.find((product) =>
      isEqual(product.attributesValue, attributes),
    )
  }

  const productSelectedDefault = getProductByAttributes(
    productCommon.attributesSelectedDefault,
  )
  const productSelectedByAttributes = getProductByAttributes(attributesSelected)

  const productSelected = productSelectedByAttributes || productSelectedDefault
  const { itemId: id } = productSelected

  return {
    id,

    ...productSelected,
    href: productSelected.url,
  }
}

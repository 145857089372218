/**
 * Возвращает обработку клика
 * @param {Function} emit
 */
export const useBehavior = (emit) => {
  const onFavoriteClick = ($event) => {
    const { addToFavorites, isAdded } = $event

    emit('click-add-to-favorite')

    addToFavorites()

    if (!isAdded) {
      emit('add-to-favorite')
    }
  }

  return {
    onFavoriteClick,
  }
}

import dayjs from 'dayjs'

import { withMessage } from '../utils'

export default (minDate, message) => {
  const validator = (value) => {
    const diff = dayjs(minDate).diff(value, 'minute')

    return diff <= 0
  }

  const params = {
    type: 'minDate',
    minDate,
    message: 'app.validation.date',
  }

  return withMessage(validator, params)(message)
}

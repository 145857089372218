import { useContext } from '@nuxtjs/composition-api'

import { GaIconLocationGeoPinFilled } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'

import { SCREEN_TYPES } from '../../../../location/analytics/constants'

const svg = {
  location: GaIconLocationGeoPinFilled,
}

// @vue/component
export default {
  name: 'ga-nav-location',

  components: {
    GaAction,
  },

  setup() {
    const { $gaApp } = useContext()

    const onClick = () => {
      $gaApp.services.location.main.openModal(SCREEN_TYPES.MENU)
    }

    return {
      onClick,

      svg,
    }
  },
}

import { DELIVERY_TYPES, ORDER_TOTAL_NAMES } from '../../../../constants'
import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ThankYouViewModel extends GtmBaseModel {
  constructor(data) {
    super({
      event: EVENT.THANK_YOU_VIEW.GA4,
    })

    this.payment_type = data.orderInfo.payment.type
    this.parcels = data.shipments?.length
    this.delivery_type = data.isPickup
      ? DELIVERY_TYPES.PICKUP
      : DELIVERY_TYPES.COURIER

    this.ecommerce = {
      transaction_id: data.id,
      currency: this.getCurrency(data.orderTotal.total.currency),
      value: data.orderTotal.total.amount,
      tax: data.tax,
      items: data.products.map((product, i) => ({
        ...new GtmGa4ProductBaseModel(product),
        index: product.index,
        quantity: product.quantity,
        item_list_name: product.listName,
      })),
    }

    if (data.coupon) {
      this.ecommerce.coupon = data.coupon
    }

    const totalBonus = data.orderTotal.list.find(
      ({ name }) => name === ORDER_TOTAL_NAMES.BONUS_DISCOUNT,
    )

    if (totalBonus) {
      this.ecommerce.bonus = totalBonus.value.amount
    }

    const totalDelivery = data.orderTotal.list.find(
      ({ name }) => name === ORDER_TOTAL_NAMES.DELIVERY,
    )

    if (totalDelivery) {
      this.ecommerce.shipping = totalDelivery.value.amount
    }
  }
}

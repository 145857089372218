import { TYPE } from '../../constants'
import { GtmGa4StreamWatchClickModel } from '../../models'
import { BaseBuilder } from '../index'

export class StreamWatchClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4StreamWatchClickModel]])
    super(models, data)
  }
}

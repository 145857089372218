// типы страниц которые возвращает бэк
export const PAGE_TYPE = {
  plp: 'plp',
  pdp: 'pdp',
  redirect: 'redirect',
}

// название модулей от типов страниц
export const MODULE_TYPE = {
  [PAGE_TYPE.pdp]: 'pdp',
  [PAGE_TYPE.plp]: 'plp',
  [PAGE_TYPE.redirect]: 'redirect',
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async subscribe() {
    try {
      const { data } =
        await this.gaApp.repositories.subscription.main.subscribe({
          email: this.gaApp.stores.subscription.email.form.email,
        })
      return data.status
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('subscription.subscriptionModal.errorText'),
      )
      return false
    }
  }
}

export const MAX_MESSAGE_LENGTH = 100

export const TYPE = {
  GTM_4: 'gtm_4',
}

export const EVENT_NAMES = {
  CLICK_TICKER: {
    GA_4: 'click_ticker',
  },
  VIEW_TICKER: {
    GA_4: 'view_ticker',
  },
}

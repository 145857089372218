<template>
  <svg viewBox="0 0 11 12">
    <path fill="none" stroke-width="1.5" d="m1 1.5 9 9m-9 0 9-9" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-search-search-cross',
}
</script>

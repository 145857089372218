export const state = () => ({
  form: {
    isLoading: false,
    successModalIsOpen: false,
    fields: {
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  scroller: null,
  menuModalIsOpen: false,
  dpaModalIsOpen: false,
})

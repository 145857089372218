export const disclaimer = {
  type: 'object',
  properties: {
    legalEntity: {
      type: 'array',
      items: { type: 'string' },
      required: true,
    },
    erid: { type: 'string', required: true },
    label: { type: 'string', required: true },
  },
  additionalProperties: false,
}

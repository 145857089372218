import { DEVICE } from '@ga/constants/device'

import { ENTITY, SIZE } from '../constants'

/**
 * Предоставляет общие методы и свойства для работы с уведомлениями.
 * Этот сервис предоставляет общую функциональность для работы с уведомлениями,
 * такую как определение размера и типа устройства, а также методы для отображения,
 * закрытия и закрытия всех уведомлений.
 */

export class CommonService {
  constructor(gaApp, needClean) {
    this.gaApp = gaApp
    this.needClean = needClean
  }

  // Возвращает тип открытого уведомления из store `notifications`.
  get openedTypeNotification() {
    return this.gaApp.store.state.notifications.type
  }

  /**
   * Возвращает `true`, если текущая ширина экрана соответствует
   * мобильному разрешению, в противном случае - `false`.
   */
  get isMobileBreakpoint() {
    return this.currentBreakpoint === DEVICE.MOBILE
  }

  /**
   * Возвращает размер уведомления (`S` для мобильных устройств,
   * `M` для остальных) на основе `isMobileBreakpoint`.
   */
  get notificationSize() {
    return this.isMobileBreakpoint ? SIZE.S : SIZE.M
  }

  // Возвращает массив типов продуктов для уведомлений.
  get notificationProductTypes() {
    return Object.values(ENTITY)
  }

  // Возвращает строку с шириной в `px` для мобильных устройств
  get widthMobile() {
    return `${this.windowInnerWidth - 30}px`
  }

  // возвращает ширину внутренней области окна браузера
  get windowInnerWidth() {
    return this.gaApp.stores.app.window?.windowSize?.innerWidth || 360
  }

  // Возвращает текущий брейкпоинт
  get currentBreakpoint() {
    switch (true) {
      case this.gaApp.mq('tablet-'):
        return DEVICE.MOBILE
      case this.gaApp.mq('tablet+') && this.gaApp.mq('desktop-small-'):
        return DEVICE.TABLET
      default:
        return DEVICE.DESKTOP
    }
  }

  // Отображает уведомление с указанными параметрами
  show(options) {
    this.gaApp.libs.notifier.open(options)
  }

  // Закрывает уведомление с указанным идентификатором
  close(id) {
    this.gaApp.libs.notifier.close(id)
  }

  // Закрывает все открытые уведомления
  closeAll() {
    this.gaApp.libs.notifier.closeAll()
  }
}

export const state = () => ({
  id: null,
  cartId: null,

  isPickup: false,
  isCertificatePay: false,
  isBonusPay: false,

  coupon: null,
  tax: null,

  products: [],
  gifts: [],
  shipments: [],

  orderInfo: null,
  orderTotal: null,
})

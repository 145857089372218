<template>
  <svg viewBox="0 0 20 20">
    <path fill="none" stroke-width="1.5" d="M1 10h18" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-zoom-out',
}
</script>

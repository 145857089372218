import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref } from 'vue'

import {
  HEADER_EXTENSTION_TRANSITOIN_DURATION,
  TABS,
} from '../../../../../constants'

export const useModals = (full, collapsed) => {
  const { $gaApp } = useContext()

  const searchModalOpened = computed(() => {
    return $gaApp.stores.search.main.searchModalOpened
  })

  const langSwitcherModalOpened = computed(() => {
    return $gaApp.stores.app.langSwitcher.modal.opened
  })

  const setModalsByTab = (tab, value) => {
    switch (tab) {
      case TABS.SEARCH:
        return tryToSetSearchModalOpened(value)
      default:
        return false
    }
  }

  const openCartModal = () => {
    $gaApp.services.modal.main.openSuperModalCart()
  }

  const tryToSetSearchModalOpened = (value) => {
    if (unref(searchModalOpened) === value) {
      return
    }

    $gaApp.services.search.main.setSearchModalOpened({ value })
  }

  const searchModalTransitionDelay = ref()
  const updateSearchModalTransitionDelay = () => {
    searchModalTransitionDelay.value =
      unref(full) && unref(collapsed) && unref(searchModalOpened)
        ? HEADER_EXTENSTION_TRANSITOIN_DURATION
        : null
  }

  return {
    setModalsByTab,
    searchModalOpened,
    searchModalTransitionDelay,
    updateSearchModalTransitionDelay,
    langSwitcherModalOpened,
    openCartModal,
  }
}

export class BalanceService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает флаг загруженности изображения
   */
  setImagesLoaded() {
    this.gaApp.stores.giftCards.balance.isImagesLoaded = true
  }
}

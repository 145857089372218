<template>
  <svg stroke="none" preserveAspectRatio="none" viewBox="0 0 888 954">
    <path
      fill="inherit"
      d="M628.183 604.425C528.15 748.887 364.68 808.955 263.062 738.589c-101.618-70.365-102.903-244.517-2.87-388.98C360.225 205.147 523.696 145.08 625.314 215.445c101.618 70.366 102.902 244.518 2.869 388.98Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-dark-green-blob',
}
</script>

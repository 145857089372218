export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getAppInfo() {
    try {
      const { data } = await this.gaApp.repositories.app.main.getAppInfo()
      return data
    } catch (error) {
      return {}
    }
  }

  async getCommonData() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('common')
      const { data } =
        cacheData || (await this.gaApp.repositories.app.main.getCommonData())

      this.gaApp.stores.app.common.setData(data)

      this.gaApp.services.footer.main.setData({
        contacts: data.messengers,
        phone: data.phones?.support,
        email: data.emails?.support,
        groups: data.footerGroups,
        apps: data.marketApps,
        socials: data.socials,
        payments: data.payments,
        text: data.footerText,
      })
    } catch (error) {}
  }

  async getCookieAgreementText() {
    const { data } =
      await this.gaApp.repositories.app.main.getCookieAgreementText()

    const { message, messageHtml } = data

    if (!messageHtml) {
      const error = new Error('Ошибка')
      error.localizedMessage = message
      throw error
    }

    return data
  }

  async setCookieAgreement(value) {
    try {
      await this.gaApp.repositories.app.main.setCookieAgreement({
        accept: value,
      })
    } catch (error) {}
  }
}

import { EVENT_CATEGORY_BTS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmBtsSocialClickModel extends GtmBaseModel {
  constructor({ name, place }) {
    super({ eventCategory: EVENT_CATEGORY_BTS })

    this.action_type = 'click_social'
    this.name = name
    this.place = place
  }
}

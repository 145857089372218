import { computed, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'

import { propValidator } from '../../../utils'

import { SIZE } from './scripts/consts'
import { favorite as schemaFavorite } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-favorite',

  components: {
    GaIcon,
    GaAction,
  },

  props: {
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    data: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaFavorite),
    },
    testId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { size } = toRefs(props)

    const mods = computed(() => ({
      size: unref(size),
    }))

    return {
      mods,
    }
  },
}

import { analytics } from './analytics'
import * as components from './components'
import { services } from './services'
import { stores } from './stores'

export { useFiltersModalToggle } from './composables'

export const filters = {
  meta: {
    name: 'filters',
  },
  layers: {
    services,
    analytics,
    stores,
  },
  components,
}

<template>
  <svg viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="19.375" fill="none" stroke-width="1.25" />
    <path
      stroke="none"
      d="M13 16v7.996h1.487v-3.232h.95l2.092 3.232h1.61l-2.247-3.456c.984-.268 1.778-.883 1.778-2.147v-.044c0-1.633-1.23-2.349-2.986-2.349H13Zm2.684 3.702h-1.197v-2.55h1.197c.95 0 1.52.302 1.52 1.23v.045c0 .85-.502 1.275-1.52 1.275Z"
    />
    <path
      stroke="none"
      d="M23.338 24.108c2.136 0 3.243-1.14 3.243-3.243V16h-1.499v4.742c0 1.655-.57 2.17-1.733 2.17-1.197 0-1.655-.593-1.655-2.07V16h-1.499v4.954c0 2.069 1.13 3.154 3.143 3.154Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-circle-ru',
}
</script>

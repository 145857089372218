import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductCartModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { ADD_TO_CART, REMOVE_FROM_CART } = ACTIONS
    const {
      list,
      listId,
      plpType,
      searchTerm,
      currency,
      addToCart,
      analyticData,
    } = product
    const event = addToCart ? ADD_TO_CART.GA_4 : REMOVE_FROM_CART.GA_4

    super({ event })

    if (analyticData?.creativeName)
      this.creative_name = analyticData.creativeName
    if (analyticData?.creativeSlot)
      this.creative_slot = analyticData.creativeSlot
    if (analyticData?.promotionName)
      this.promotion_name = analyticData.promotionName

    this.plp_type = plpType
    this.search_term = searchTerm
    this.ecommerce = {
      currency,
      item_list_id: listId,
      item_list_name: list,
      items: [new GtmGa4ProductBaseModel(product)],
    }
  }
}

const getImageAttributes = (product) => {
  const { productType = '', brand = '', name = '', attributes } = product

  const productColor = attributes?.colors?.name || ''
  const productUnitValue = attributes?.units?.currentUnitValue || ''
  const productUnitName = attributes?.units?.name || ''

  const productUnit = productUnitValue
    ? `${productUnitValue} ${productUnitName}`
    : ''

  const alt = `${brand} ${productType} ${name}`
  const title = `${alt} ${productColor} ${productUnit}`

  return {
    alt,
    title,
    itemprop: 'image',
  }
}

export const getSeoEnrichedMedia = (product) => {
  const images = product.imageUrls ?? []

  return images.map((image) => ({
    image,
    meta: getImageAttributes(product) ?? false,
  }))
}

export const endpoints = {
  products: () => ({
    url: 'purchased/products',
  }),
  filters: () => ({
    url: 'purchased/filters',
  }),
  plp: () => ({
    url: 'purchased/plp',
  }),
}

import { ActionBuilder, ConsultationBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class EyezonAnalytics extends BaseController {
  /**
   * клик на иконку.
   */
  onClickIcon({ sku }) {
    this.send(ActionBuilder, {
      sku,
      type: 'eye',
    })
  }

  /**
   * клик на кнопку в карточке.
   */
  onClickButton({ sku }) {
    this.send(ActionBuilder, {
      sku,
      type: 'button',
    })
  }

  /**
   * клиент прошел консультация.
   */
  createDialog() {
    const customerId = this.gaApp.stores.user.main?.data?.id

    if (!customerId) {
      return
    }

    this.send(ConsultationBuilder, {
      customerId,
    })
  }
}

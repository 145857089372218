import { VIDEO_STRATEGY } from '../../../../media'

import {
  useManualStrategy,
  useNoneStrategy,
  useSingleStrategy,
} from './strategies'

export const useVideo = ({ props, videoRef, emit }) => {
  const currentStrategy = {
    [VIDEO_STRATEGY.NONE]: useNoneStrategy,
    [VIDEO_STRATEGY.MANUAL]: useManualStrategy,
    [VIDEO_STRATEGY.SINGLE]: useSingleStrategy,
  }[props.videoStrategy]

  return currentStrategy({ props, videoRef, emit })
}

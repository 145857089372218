import { OnAppInitBuilder, OnUserAuthBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class UserAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.init()
  }

  init() {
    /**
     * Подписка на событие инита window.dataLayer
     */
    const unsubscribeDataLayer = this.gaApp.eventBus.subscribe(
      'layer/analitycs/initDataLayer',
      () => {
        this.onDataLayerInit()

        unsubscribeDataLayer()
      },
    )

    /**
     * Подписка на событие авторизации пользователем
     */
    const unsubscribeAuth = this.gaApp.eventBus.subscribe(
      'module/auth/login',
      () => {
        this.onUserAuthorized()

        unsubscribeAuth()
      },
    )
  }

  /**
   * Метод вызыватеся до инита модулей аналитики
   * подробнее в OnAppInitBuilder
   */
  onDataLayerInit() {
    const builder = new OnAppInitBuilder(this.gaApp, {
      user: {
        userId: this.gaApp.stores.user.main?.data?.id,
        isAuthorized: this.gaApp.stores.user.main?.isAuthorized,
      },
      store: {
        country: this.gaApp.i18n.locale.name,
      },
    })

    builder.send()
  }

  /**
   * Метод вызыватеся при авторизации пользователя
   */
  onUserAuthorized() {
    const userId = this.gaApp.stores.user.main?.data?.id
    const isAuthorized = this.gaApp.stores.user.main?.isAuthorized

    this.send(OnUserAuthBuilder, {
      userId,
      isAuthorized,
    })
  }
}

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
    }
  }

  send(Builder, data) {
    const { country } = this.gaApp.i18n.locale
    new Builder(data, country).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getToken(game) {
    try {
      const { data } = await this.gaApp.repositories.game.main.getToken(game)

      this.gaApp.stores.game.main.gameToken = data.access_token || null
      this.gaApp.stores.game.main.processingStatus =
        data.processingStatus || null
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  async fetchProductBase({ itemId }) {
    const { groupId } = this.gaApp.services.user.main.data
    const cityId = this.gaApp.services.app.main.getMainCity()?.value

    const requestParams = {
      itemId,
      cityId,
      customerGroupId: groupId || '0',
    }

    return await this.gaApp.repositories.game.main.getProductBase(requestParams)
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4FiltersApplied extends GtmBaseModel {
  constructor(data) {
    super()

    const { itemListName, analytics } = data

    this.type = 'main'
    this.action_type = 'apply_filters'
    this.item_list_name = itemListName

    analytics.forEach(([key, value]) => {
      if (key && value) {
        this[key] = value
      }
    })
  }
}

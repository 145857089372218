import { ACTIONS } from '../../../constants'
import { GtmBaseModel, GtmGa4ProductBaseModel } from '../../index'

export class GtmGa4ProductClickModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.SELECT_ITEM.GA4)

    const { itemListId, itemListName } = product

    this.item_list_name = itemListName
    this.item_list_id = itemListId

    this.ecommerce = new GtmGa4ProductBaseModel({ product })
  }
}

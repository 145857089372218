<template>
  <svg viewBox="0 0 15 15">
    <path fill="none" stroke-width="1.25" d="M12 10 7.5 5.5 3 10" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-chevron',
}
</script>

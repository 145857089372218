import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import {
  HEADER_DESKTOP_BREAKPOINT,
  TABLET_LANDSCAPE_BREAKPOINT,
} from '~/modules/header'

import { MEDIA_TYPE } from '../../const'

export const useMediaType = () => {
  const { $gaApp } = useContext()

  const mediaType = computed(() => {
    if ($gaApp.mq('tablet-')) return MEDIA_TYPE.MOBILE

    if ($gaApp.mq(`${HEADER_DESKTOP_BREAKPOINT}+`)) return MEDIA_TYPE.DESKTOP

    if ($gaApp.mq(`${TABLET_LANDSCAPE_BREAKPOINT}+`))
      return MEDIA_TYPE.TABLET_LANDSCAPE

    return MEDIA_TYPE.TABLET
  })

  return {
    mediaType,
  }
}

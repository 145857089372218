import { rafThrottle } from '@ga/shared-browser'

import clamp from './clamp'

const TRANSITION_DURATION_ON_LEAVE_ENTER = 150

class Index {
  constructor(target, rotateXFactor = 0.5, rotateYFactor = 1) {
    this.target = target
    this.rotateXFactor = rotateXFactor
    this.rotateYFactor = rotateYFactor
    this.prevGamma = null
    this.baseXAngle = 35
    this.transitionDuration = TRANSITION_DURATION_ON_LEAVE_ENTER

    this.onDeviceOrientation = rafThrottle(this.onDeviceOrientation.bind(this))
  }

  init() {
    this.setStyles()
    this.bindEventsListeners()
  }

  destroy() {
    this.resetStyles()
    this.unbindEventsListeners()
  }

  setStyles() {
    this.target.style.willChange = 'transform'
  }

  resetStyles() {
    this.target.style.transition = `transform ease-out ${this.transitionDuration}ms`
    setTimeout(() => {
      this.target.style.willChange = 'auto'
      this.target.style.transform = ''
      this.target.style.transition = ''
    }, this.transitionDuration)
  }

  bindEventsListeners() {
    window.addEventListener('deviceorientation', this.onDeviceOrientation)
  }

  unbindEventsListeners() {
    window.removeEventListener('deviceorientation', this.onDeviceOrientation)
  }

  onDeviceOrientation(event) {
    // проверка, чтобы избежать резкого поворота, когда значение изменяется
    // с 90 до -90 и с -90 до 90
    if (this.prevGamma && Math.abs(event.gamma - this.prevGamma) > 90) {
      return
    }

    this.prevGamma = event.gamma

    const xRotation = clamp(
      (event.beta - this.baseXAngle) * this.rotateXFactor.toFixed(2),
      { min: -30, max: 30 },
    )
    const yRotation = clamp((event.gamma * this.rotateYFactor).toFixed(2), {
      min: -30,
      max: 30,
    })

    this.setRotation(xRotation, yRotation)
  }

  setRotation(xRotation, yRotation) {
    this.target.style.transform = `
            perspective(1000px)
            rotateX(${xRotation}deg)
            rotateY(${yRotation}deg)
            scale3d(1, 1, 1)
        `
  }
}

const floatingOnDeviceOrientationRegistry = new Map()
let floatingOnDeviceOrientationUniqueId = 0

const generateFloatingOnDeviceOrientationUniqueKey = () => {
  floatingOnDeviceOrientationUniqueId += 1

  return floatingOnDeviceOrientationUniqueId
}

const createFloatingOnDeviceOrientation = (
  el,
  rotateXFactor,
  rotateYFactor,
) => {
  const floatingOnDeviceOrientation = new Index(
    el,
    rotateXFactor,
    rotateYFactor,
  )

  floatingOnDeviceOrientation.init()

  const key = generateFloatingOnDeviceOrientationUniqueKey()

  el.dataset.floatingOnDeviceOrientationId = key

  floatingOnDeviceOrientationRegistry.set(key, floatingOnDeviceOrientation)
}

const destroyFloatingOnDeviceOrientation = (id) => {
  const floatingOnDeviceOrientationToDelete =
    floatingOnDeviceOrientationRegistry.get(id)

  floatingOnDeviceOrientationToDelete.destroy()
  delete floatingOnDeviceOrientationToDelete.target.dataset
    .floatingOnDeviceOrientationId

  floatingOnDeviceOrientationRegistry.delete(id)
}

const floatingOnDeviceOrientation = {
  inserted(el, binding) {
    const { isGyroscopeEnabled, rotateXFactor, rotateYFactor } = binding.value

    if (isGyroscopeEnabled) {
      createFloatingOnDeviceOrientation(el, rotateXFactor, rotateYFactor)
    }
  },

  update(el, binding) {
    const { isGyroscopeEnabled: oldIsGyroscopeEnabled } = binding.oldValue
    const { isGyroscopeEnabled, rotateXFactor, rotateYFactor } = binding.value
    const { floatingOnDeviceOrientationId } = el.dataset

    if (isGyroscopeEnabled !== oldIsGyroscopeEnabled) {
      if (isGyroscopeEnabled && !floatingOnDeviceOrientationId) {
        createFloatingOnDeviceOrientation(el, rotateXFactor, rotateYFactor)
      } else if (!isGyroscopeEnabled && floatingOnDeviceOrientationId) {
        destroyFloatingOnDeviceOrientation(
          Number(floatingOnDeviceOrientationId),
        )
      }
    }
  },

  unbind(el) {
    const { floatingOnDeviceOrientationId } = el.dataset

    if (floatingOnDeviceOrientationId) {
      destroyFloatingOnDeviceOrientation(Number(floatingOnDeviceOrientationId))
    }
  },
}

export { floatingOnDeviceOrientation }

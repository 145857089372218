import { GaAction } from '@ga/ui-components/action'

import { GaImageAdult } from '~/components/image-adult'
import { GaImageResponsive } from '~/components/image-responsive'

// @vue/component
export default {
  name: 'ga-nav-banners-mobile',

  components: {
    GaAction,
    GaImageAdult,
    GaImageResponsive,
  },

  props: {
    level: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    main() {
      return this.level === 0 && this.items.length === 1
    },

    itemsInternal() {
      return this.items.map((item) => {
        const mods = { adult: item.isForAdults }

        return { ...item, mods }
      })
    },

    mainItem() {
      return this.itemsInternal[0]
    },

    additionalItems() {
      return this.itemsInternal.map((item, index) => {
        const right = Boolean(index % 2)
        const left = !right

        const mods = { ...item.mods, left, right }

        return { ...item, mods }
      })
    },
  },
}

export const TYPE = {
  GTM_UA: 'gtm_ua',
  GTM_4: 'gtm_4',
  MINDBOX: 'mindbox',
  GDESLON: 'gdeslon',
  GAA: 'ga_analytics',
  ADVCAKE: 'advcake',
  DIGI: 'digi_analytics',
}

export const EVENT = {
  GA_UA: {
    view: 'productDetail',
  },
  GA4: {
    view: 'view_item',
  },
  DIGI: {
    view: 'PRODUCT_VIEW',
  },
}

export const ACTIONS = {
  VIEW: {
    GA_UA: 'productDetail',
    GA4: 'view_item',
    MINDBOX: {
      ru: 'Website.ProductView.JS',
      by: 'Website.ProductView.JS',
      kz: 'Website.ProductView.JS',
      qa: 'WebsiteQA.ProductView.JS',
    },
    GDESLON: 'card',
    ADVCAKE: 2,
  },
  ADD_TO_CART: {
    GA_UA: 'addToCart',
    GA4: 'add_to_cart',
    MINDBOX: {
      ru: 'Website.AddProductToCartItemList.JS',
      by: 'Website.AddProductToCartItemList.JS',
      kz: 'Website.AddProductToCartItemList.JS',
      qa: 'WebsiteQA.AddProductToCartItemList.JS',
    },
    GDESLON: 'basket',
    ADVCAKE: 10,
  },
  REMOVE_FROM_CART: {
    GA_UA: 'removeFromCart',
    GA4: 'remove_from_cart',
    MINDBOX: {
      ru: 'Website.RemoveProductFromCartItemList.JS',
      by: 'Website.RemoveProductFromCartItemList.JS',
      kz: 'Website.RemoveProductFromCartItemList.JS',
      qa: 'WebsiteQA.RemoveProductFromCartItemList.JS',
    },
    ADVCAKE: 12,
  },
  CHANGE_QYANTITY_IN_CART: {
    ADVCAKE: 11,
  },
  FAVORITE: {
    MINDBOX: {
      ru: 'Website.AddProductToFavoritesItemList.JS',
      by: 'Website.AddProductToFavoritesItemList.JS',
      kz: 'Website.AddProductToFavoritesItemList.JS',
      qa: 'WebsiteQA.AddProductToFavoritesItemList.JS',
    },
    GA4: 'add_to_wishlist',
  },
}

export const SOURCE = 'plaid_backend'
export const VIEW = {
  BASE: 'basic_view',
  PREVIEW: 'quick_view',
}

export const OPTION_NAME = {
  GA_UA: 'detail',
  GA4: 'items',
}

export const MERCHANT_ID = '97915'
export const DEDUPLICATION = 'gdeslon'

export const PLACEMENT_TYPES = {
  PDP: 'pdp',
}

export const CHUNK_SIZE_ANALYTICS = 1

export const USER_INTERACTION = {
  ACTION_TYPE: {
    VIDEO_START: 'start',
    VIDEO_ENDED: 'viewed',
    CLICK_IMAGE: 'click_image',
    CLICK_BRAND: 'click_brand',
    CLICK_ADMISSION: 'click_admission',
    SENT_ADMISSION: 'form_sent_admission',
    CLICK_AVAILABILITY: 'click_availability_in_stores',
    CLICK_CHAT: 'click_ask_question',
    CLICK_BREADCRUMBS: 'click_breadcrumbs',
    CLICK_SHOW_MORE: 'click_more_info',
  },
  EVENT_CATEGORY: {
    SHORT_VIDEO: 'short_video',
    PDP_INFO: 'pdp_info',
  },
  EVENT: {
    SWIPE_PHOTO: 'swipe_photo',
  },
  SCREEN_TYPE: {
    PDP: 'pdp',
  },
  BRAND_PLACE: {
    TAB: 'tab',
    TITLE: 'title',
  },
}

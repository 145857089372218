import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MailConfirmedModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'mail_confirmed'
    this.type = 'kepk'
  }
}

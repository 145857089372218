<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path
      fill="#FF6108"
      stroke="none"
      d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Z"
    />
    <path
      fill="#fff"
      stroke="none"
      d="M8.718 17.602s.529-5.862.628-6.713H6.4l5.24-8.487-.61 5.827H14l-5.282 9.373Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-lightning-round',
}
</script>

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ContentSlideBaseModel } from '../gtm-ga4-content-slide-base.model/gtm-ga4-content-slide-base.model'

export class GtmGa4ContentSlideViewModel extends GtmBaseModel {
  constructor({ blockName, blockPosition, slideName, slidePosition }) {
    super(ACTIONS.VIEW_PROMOTION.GA4)

    this.ecommerce = new GtmGa4ContentSlideBaseModel({
      blockName,
      blockPosition,
      slideName,
      slidePosition,
    })
  }
}

import camelCase from 'lodash/camelCase'
import flow from 'lodash/flow'
import isPlainObject from 'lodash/isPlainObject'
import mapKeys from 'lodash/mapKeys'
import pickBy from 'lodash/pickBy'
import replace from 'lodash/replace'
import startsWith from 'lodash/startsWith'

/**
 * Собирает ключи начинающиеся с префикса, затем удаляет префикс и переводит в camelCase
 *
 * @param {object} obj простой объект
 * @param {string} prefix префикс ключей
 * @returns {object} Объект с ключами начинающиеся с префикса, без префикса в camelCase
 */
export const mapObjectByPrefix = (obj, prefix) => {
  if (!isPlainObject(obj)) {
    throw new Error(
      `Ожидается plain-object. Получен аргумент типа: ${typeof obj}`,
    )
  }

  return mapKeys(
    // Получили ключи, начинающиеся с prefix
    pickBy(obj, (_, key) => startsWith(key, prefix)),

    // Заменили prefix => "", и перевели в camelCase
    (_, key) => flow(replace, camelCase)(key, prefix, ''),
  )
}

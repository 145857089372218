import {DigiAnalyticsWidgetBaseModel} from '../digi-analytics-widget-base/digi-analytics-widget-base.model'
import {EVENT_TYPES, WIDGET_TYPES} from '../../../constants'

export class DigiAnalyticsWidgetRenderModel extends DigiAnalyticsWidgetBaseModel {
    constructor(data) {
        super(data)
        this.data.eventType = EVENT_TYPES.WIDGET_VIEW
        this.data.widgetType = WIDGET_TYPES.RENDER

        // согласно документации должны слать это поле, но с ним АПИ отвечает ошибкой 400
        // this.data.seed = data.productsIds
    }
}

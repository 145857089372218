/**
 * @typedef {string} Size
 */

/**
 * @enum {Size}
 */

export const SIZE = {
  L: 'l',
  M: 'm',
  S: 's',
}

import { GtmBaseModel } from '../base.model'
export class GtmFilterModel extends GtmBaseModel {
  constructor({ sku, filters } = {}) {
    super({ sku, action: 'set_filter' })

    const { filters: filtersValue, hasMedia } = filters
    switch (true) {
      case filtersValue?.length > 0:
        this.filter = filtersValue[0]
        break
      case hasMedia:
        this.filter = 'only photo'
        break
      default:
        this.filter = 'all'
    }
  }
}

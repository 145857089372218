import { getCategoriesBreadcrumbs } from '../../../../utils/common/get-categories-breadcrumbs'

export class GtmGaUaProductModel {
  constructor(product) {
    if (!product) {
      throw new Error('GtmGaUaProductModel params required')
    }

    const {
      brand,
      name,
      amount,
      id,
      breadcrumbs,
      attributesSelected,
      itemListName,
      inStock,
    } = product

    this.name = `${brand} ${name}`
    this.id = id
    this.brand = brand
    this.price = amount
    this.dimension4 = inStock
    this.list = itemListName

    const category =
      breadcrumbs?.length && getCategoriesBreadcrumbs(breadcrumbs).join('/')

    if (category) {
      this.category = category
    }

    const variant = Object.values(attributesSelected || {}).join(' ')
    if (variant) {
      this.variant = variant
    }
  }
}

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsButtonProductClickModel extends GaAnalyticsBaseModel {
  constructor({
    creativeName,
    creativeSlot,
    itemListId,
    itemListName,
    promotionName,
    itemId,
  }) {
    super('product')

    this.item = {
      creativeName,
      creativeSlot,
      itemListId,
      itemListName,
      promotionName,
      itemId,
    }
  }
}

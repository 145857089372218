export const getAttributeUnit = (units) => {
  const name = units?.name
  const value = units?.currentUnitValue || units?.values?.[0]

  return value
    ? {
        name,
        value,
      }
    : false
}

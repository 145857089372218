export const useCountdownApi = (el) => {
  const start = () => {
    el.value.start()
  }

  const abort = () => {
    el.value.abort()
  }

  const end = () => {
    el.value.end()
  }

  return { start, abort, end }
}

import { computed, toRefs, unref } from 'vue'

import { GaImageResponsive } from '~/components/image-responsive'

import { usePostRender } from '../../../composables'
import { propValidator } from '../../../utils'
import { colorsValues as schemaColorsValues } from '../../../utils/schemas'

import { useMods } from './scripts/composables'
import { SIZE, VISIBLE_MAX_COUNT } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-colors',

  components: {
    GaImageResponsive,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
      validator: (value) => propValidator(value, schemaColorsValues),
    },
    count: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },

  setup(props) {
    const { size, count, options } = toRefs(props)

    const visibleCount = computed(() =>
      Math.min(unref(options).length, VISIBLE_MAX_COUNT),
    )

    const extraCount = computed(() => unref(count) - unref(visibleCount))

    const optionsVisible = computed(() =>
      unref(options).slice(0, unref(visibleCount)),
    )

    const { mods } = useMods(size, extraCount)

    const { postRendered } = usePostRender()

    return {
      mods,
      optionsVisible,
      extraCount,

      postRendered,
    }
  },
}

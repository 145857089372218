<template>
  <svg fill="inherit" viewBox="0 0 25 25">
    <path
      fill="inherit"
      d="M16 3v9.94l-1.5-1.5V6.17l-2.894 2.375L10.54 7.48 16 3Z"
    />
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="m16 17.044 4.97 4.986 1.06-1.06L4.56 3.44 3.498 4.5l3.82 3.833H3v9.334h6.5L16 23v-5.956Zm-1.5-1.505v4.29l-4.463-3.662H4.5V9.833h4.314L14.5 15.54Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-muted',
}
</script>

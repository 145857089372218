import { TYPE } from '../../constants'
import { GtmGa4AdvantageModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class AdvantageBuilder extends BaseBuilder {
  constructor(product) {
    const models = new Map([[TYPE.GTM_4, GtmGa4AdvantageModel]])

    super(models, product)
  }
}

export const getLocationStateInitial = () => ({
  isCacheData: false,
  location: {},

  pageScreenType: '',
})

export const state = () => {
  return getLocationStateInitial()
}

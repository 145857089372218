import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import state from './state'

export const productsStore = (gaApp) => {
  const init = defineStore('homeProducts', {
    getters: getters(gaApp),
    state,
  })

  return init(getActivePinia())
}

<template>
  <svg stroke="none" preserveAspectRatio="none" viewBox="0 0 1374 1374">
    <path
      fill="inherit"
      d="M1180.98 600.193c47.83 272.797-134.53 532.727-407.331 580.557-272.797 47.84-532.722-134.53-580.558-407.327-47.836-272.797 134.531-532.722 407.328-580.558 272.797-47.836 532.721 134.531 580.561 407.328Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-yellow-blob',
}
</script>

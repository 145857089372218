import { toRefs } from 'vue'

import { useProductData } from '~/modules/product-card'

// @vue/component
export default {
  name: 'ga-product-listing-card-adapter',

  props: {
    product: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const { product } = toRefs(props)

    const { productData } = useProductData(product)

    return {
      productData,
    }
  },
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaAction } from '@ga/ui-components/action'
import { GaImage } from '@ga/ui-components/image'

import { GaLangSwitcherListItem } from './children/list-item'

// @vue/component
export default {
  name: 'ga-app-lang-switcher',

  components: {
    GaLangSwitcherListItem,
    GaAction,
    GaImage,
  },

  setup() {
    const { $gaApp } = useContext()

    const currentStore = computed(
      () => $gaApp.services.app.langSwitcher.currentStore,
    )

    const availableLanguages = computed(
      () => $gaApp.services.app.langSwitcher.availableLanguages,
    )

    const switchLocale = (code) => {
      $gaApp.services.app.langSwitcher.switchLocale(code)
    }

    return {
      currentStore,
      availableLanguages,

      switchLocale,
    }
  },
}

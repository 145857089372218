import { aboutStore } from './about'
import { balanceStore } from './balance'
import { digitalStore } from './digital'
import { digitalAiDesignStore } from './digital-ai-design'
import { digitalReceiveStore } from './digital-receive'
import { digitalReceiveReturnStore } from './digital-receive-return'
import { mainStore } from './main'
import { paymentStore } from './payment'
import { reactionsStore } from './reactions'
export { digitalStore } from './digital'

export const stores = (gaApp) => ({
  about: aboutStore(gaApp),
  balance: balanceStore(gaApp),
  digital: digitalStore(gaApp),
  digitalAiDesign: digitalAiDesignStore(gaApp),
  digitalReceive: digitalReceiveStore(gaApp),
  digitalReceiveReturn: digitalReceiveReturnStore(gaApp),
  main: mainStore(gaApp),
  payment: paymentStore(gaApp),
  reactions: reactionsStore(gaApp),
})

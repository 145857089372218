import { analytics } from './analytics'
import * as components from './components'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './store'

export const labels = {
  meta: {
    name: 'labels',
  },
  components,
  layers: {
    stores,
    services,
    analytics,
    repositories,
  },
}

<template>
  <svg fill="none" viewBox="0 0 21 21">
    <path stroke-width="1.5" d="m12 18 6-15L3 9l7 2 2 7Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-geo',
}
</script>

<template>
  <svg fill="none" viewBox="0 0 16 16">
    <path stroke="#000" stroke-width="2" d="M5.5 13.5 11 8 5.5 2.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-chevron-bold',
}
</script>

import {
  MOBILE_APP_BANNER_COOKIE_MAX_AGE,
  MOBILE_APP_BANNER_COOKIE_NAME,
} from '~/modules/notifications/constants'

/**
 * Предоставляет методы для управления отображением баннера мобильного приложения:
 */
export class BannerService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * инициализирует состояние видимости баннера приложения
   * на основе значения cookie `MOBILE_APP_BANNER_COOKIE_NAME`.
   * Если cookie существует, баннер скрывается, устанавливая
   * `mobileAppBannerVisible` в `false` в store `app.main`
   */
  // initMobileAppBanner() {
  //   const mobileAppBannerHidden = this.gaApp.cookies.get(
  //     MOBILE_APP_BANNER_COOKIE_NAME,
  //   )

  //   if (mobileAppBannerHidden) {
  //     this.gaApp.stores.app.main.mobileAppBannerVisible = false
  //   }
  // }

  /**
   * скрывает баннер приложения, устанавливая cookie
   * `MOBILE_APP_BANNER_COOKIE_NAME` со значением `true`
   * и максимальным возрастом `MOBILE_APP_BANNER_COOKIE_MAX_AGE`.
   */
  hideMobileAppBanner() {
    this.gaApp.cookies.set(MOBILE_APP_BANNER_COOKIE_NAME, true, {
      path: '/',
      maxAge: MOBILE_APP_BANNER_COOKIE_MAX_AGE,
      sameSite: 'lax',
    })

    this.gaApp.stores.app.main.mobileAppBannerVisible = false
  }
}

import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/en'
import layoutsEnAe from '~/locales/languages/layouts/en-AE'

export default () => {
  return {
    ...merge(layouts, layoutsEnAe),
  }
}

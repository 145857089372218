import { computed } from 'vue'

export const useSkeleton = (itemsCount) => {
  const skeletonItemsInitial = computed(() => {
    return Array.from({ length: itemsCount.value })
      .fill()
      .map(() => null)
  })

  const activeSkeletonIndexes = computed(() => {
    return Array.from({ length: itemsCount.value })
      .fill()
      .map((_, index) => index)
  })

  return { skeletonItemsInitial, activeSkeletonIndexes }
}

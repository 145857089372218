import { colors } from './colors'
import { units } from './units'

export const attributes = {
  type: 'object',
  properties: {
    units,
    colors,
  },
  additionalProperties: false,
}

import { TYPE } from '../../constants'
import { GaAnalyticsPreviewDetailsClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ClickPreviewMoreDetailsBuilder extends BaseBuilder {
  constructor({ productData, analyticsData }) {
    const models = new Map([[TYPE.GAA, GaAnalyticsPreviewDetailsClickModel]])
    super(models, { productData, analyticsData })
  }
}

/*
 * Oggetto
 *
 * Компонент является оберткой над контролом формы и отвечает за вывод ошибки и описания.
 * Входной параметр error скрывает description.
 */

import { GaDetails } from '../details'

// @vue/component
export default {
  name: 'ga-field',

  components: {
    GaDetails,
  },

  inheritAttrs: false,
}

<template>
  <svg fill="none" viewBox="0 0 15 4">
    <circle cx="1.5" cy="1.59" r="1" fill="#000" />
    <circle cx="7.5" cy="1.59" r="1" fill="#000" />
    <circle cx="13.5" cy="1.59" r="1" fill="#000" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-kebab-menu',
}
</script>

<template>
  <svg viewBox="0 0 24 24">
    <path fill="none" stroke="inherit" stroke-width="1.245" d="M17 3v18" />
    <path fill="none" stroke="inherit" stroke-width="1.245" d="M7 3v18" />
    <rect
      width="4"
      height="4"
      x="5"
      y="13"
      fill="inherit"
      stroke="inherit"
      stroke-width="1.245"
      rx="2"
    />
    <rect
      width="4"
      height="4"
      x="15"
      y="7"
      fill="inherit"
      stroke="inherit"
      stroke-width="1.245"
      rx="2"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-filter-button',
}
</script>

<template>
  <svg viewBox="0 0 22 22">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M11 1.63a9.37 9.37 0 0 0-8.94 6.56L.86 7.8a10.62 10.62 0 0 1 20.28 0l-1.2.38A9.37 9.37 0 0 0 11 1.63zm0 20c-4.76 0-8.79-3.13-10.14-7.44l1.2-.38a9.37 9.37 0 0 0 17.88 0l1.2.38A10.62 10.62 0 0 1 11 21.63z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M21.63 3v5.63H16V7.38h4.38V3.01h1.25zM.38 13.38H6v1.25H1.62V19H.38v-5.62z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-refresh',
}
</script>

import { ACTION_TYPES, ACTIONS, EVENT_CATEGORIES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4AlphabetNavigationModel extends GtmBaseModel {
  constructor({ actionLabel }) {
    super({
      event: ACTIONS.USER_INTERACTION.GA4,
      eventCategory: EVENT_CATEGORIES.BRANDS_LIST,
      actionType: ACTION_TYPES.CLICK_ALPHABET,
    })

    this.action_label = actionLabel
  }
}

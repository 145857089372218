import { ACTIONS } from '../../../constants'
import { MindboxBaseModel } from '../mindbox-base.model/mindbox-base.model'
import { MindboxProductBaseModel } from '../mindbox-product-base.model/mindbox-product-base.model'

export class MindboxProductFavoriteModel extends MindboxBaseModel {
  constructor(product, country) {
    const operation = ACTIONS.FAVORITE.MINDBOX[country]

    super({ operation })

    this.data = {
      addProductToList: new MindboxProductBaseModel(product),
    }
  }
}

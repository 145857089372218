import get from 'lodash/get'
import set from 'lodash/set'

import { ORDER_TYPE } from '../constants/digital'
import { REACTIONS_TRIGGER_TYPE } from '../constants/reactions'

export class ReactionsService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.gaApp.services.giftCards.reactions.closeSelectModal()
    })
  }

  /**
   * Сброс стора реакций с сохранением значений перечисленных полей
   * @param {string[]} excludeFields список путей до полей стора реакций, которые необходимо сохранить
   */
  resetStore(excludeFields = ['selectModal.hasBeenShown']) {
    const saved = {}

    excludeFields.forEach((field) => {
      saved[field] = get(this.gaApp.stores.giftCards.reactions, field)
    })

    this.gaApp.stores.giftCards.reactions.$reset()

    Object.entries(saved).forEach(([field, value]) => {
      set(this.gaApp.stores.giftCards.reactions, field, value)
    })
  }

  /**
   * Изменение видимости модального окна выбора реакции
   * @param {boolean} visibility видимость модального окна выбора реакции.
   * Где `true` — видимо,
   * `false` — скрыто
   */
  changeSelectModalVisibility(visibility) {
    this.gaApp.stores.giftCards.reactions.selectModal.isOpen = visibility
  }

  /**
   * Открытие модального окна выбора реакции
   * @param {REACTIONS_TRIGGER_TYPE} triggerType тип триггера отображения
   */
  openSelectModal(triggerType) {
    if (
      !this.gaApp.features.get('giftCardsReactions') ||
      this.gaApp.stores.giftCards.reactions.selectModal.isOpen ||
      this.gaApp.stores.giftCards.reactions.selectModal.hasBeenShown ||
      this.gaApp.stores.giftCards.digitalReceive.isForSelf ||
      this.gaApp.stores.giftCards.digitalReceive.orderType ===
        ORDER_TYPE.CORPORATE
    ) {
      return undefined
    }

    this.stopTimer()
    this.gaApp.stores.giftCards.reactions.hasBeenSent = false
    this.gaApp.stores.giftCards.reactions.selectModal.triggeredBy = triggerType
    this.gaApp.stores.giftCards.reactions.selectModal.hasBeenShown = true
    this.gaApp.analytics.modules.giftCards.onReactionsSelectModalOpen(
      triggerType,
    )

    this.changeSelectModalVisibility(true)
  }

  /**
   * Закрытие модального окна выбора реакции подарочной карты
   */
  closeSelectModal() {
    if (!this.gaApp.stores.giftCards.reactions.selectModal.isOpen) {
      return undefined
    }

    if (!this.gaApp.stores.giftCards.reactions.hasBeenSent) {
      this.gaApp.analytics.modules.giftCards.onReactionSelectModalClose()
    }

    this.changeSelectModalVisibility(false)
  }

  /**
   * Отправка реакции получателя
   * @param {string} imageId идентификатор изображения реакции
   * @param {string} emojiId идентификатор реакции
   */
  async sendReaction(imageId, emojiId) {
    await this.gaApp.services.giftCards.api.sendReaction(
      imageId,
      emojiId,
      this.gaApp.services.giftCards.main.cardNumberHash,
    )

    this.gaApp.analytics.modules.giftCards.onReactionSent()
    this.gaApp.stores.giftCards.reactions.hasBeenSent = true
  }

  /**
   * Сброс состояния таймера в сторе реакций
   */
  clearTimerState() {
    this.gaApp.stores.giftCards.reactions.timer.durationLeft = null
    this.gaApp.stores.giftCards.reactions.timer.startTime = null
    this.gaApp.stores.giftCards.reactions.timer.pauseTime = null
    this.gaApp.stores.giftCards.reactions.timer.timeoutId = null
    this.gaApp.stores.giftCards.reactions.timer.resumeTime = null
  }

  /**
   * Создание таймера отображения модального окна выбора реакции
   * @param {number} duration длительность таймера в миллисекундах
   */
  createTimerTimeout(duration) {
    this.gaApp.stores.giftCards.reactions.timer.timeoutId = setTimeout(() => {
      this.openSelectModal(REACTIONS_TRIGGER_TYPE.TIMER)
      this.clearTimerState()
    }, duration)
  }

  /**
   * Удаление таймера отображения модального окна выбора реакции
   */
  deleteTimerTimeout() {
    clearTimeout(this.gaApp.stores.giftCards.reactions.timer.timeoutId)
    this.gaApp.stores.giftCards.reactions.timer.timeoutId = null
  }

  /**
   * Запуск таймера отображения модального окна выбора реакции
   */
  startTimer() {
    if (
      !this.gaApp.stores.giftCards.reactions.hasDuration ||
      this.gaApp.stores.giftCards.reactions.selectModal.hasBeenShown
    ) {
      return undefined
    }

    this.gaApp.stores.giftCards.reactions.timer.startTime = new Date().getTime()
    this.createTimerTimeout(
      this.gaApp.stores.giftCards.reactions.timer.duration,
    )
    this.gaApp.stores.giftCards.reactions.timer.durationLeft =
      this.gaApp.stores.giftCards.reactions.timer.duration
  }

  /**
   * Пауза таймера отображения модального окна выбора реакции
   */
  pauseTimer() {
    if (!this.gaApp.stores.giftCards.reactions.isTimerRunning) {
      return undefined
    }

    this.gaApp.stores.giftCards.reactions.timer.pauseTime = new Date().getTime()
    this.deleteTimerTimeout()

    const lastStartedTime =
      this.gaApp.stores.giftCards.reactions.timer.resumeTime ||
      this.gaApp.stores.giftCards.reactions.timer.startTime
    this.gaApp.stores.giftCards.reactions.timer.durationLeft -=
      this.gaApp.stores.giftCards.reactions.timer.pauseTime - lastStartedTime
  }

  /**
   * Продолжение отсчета таймера отображения модального окна выбора реакции
   */
  resumeTimer() {
    if (!this.gaApp.stores.giftCards.reactions.isTimerPaused) {
      return undefined
    }

    this.gaApp.stores.giftCards.reactions.timer.resumeTime =
      new Date().getTime()
    this.createTimerTimeout(
      this.gaApp.stores.giftCards.reactions.timer.durationLeft,
    )
  }

  /**
   * Полная остановка таймера отображения модального окна выбора реакции
   */
  stopTimer() {
    if (!this.gaApp.stores.giftCards.reactions.isTimerRunning) {
      return undefined
    }

    this.deleteTimerTimeout()
    this.clearTimerState()
  }

  /**
   * Установка флага загруженности изображения
   */
  imageLoaded() {
    this.gaApp.stores.giftCards.reactions.receive.isImageLoaded = true
  }

  /**
   * Запуск анимации нажатия
   */
  animate() {
    this.gaApp.stores.giftCards.reactions.selectModal.isAnimating = true
    this.gaApp.stores.giftCards.reactions.selectModal.isAnimated = false
  }

  /**
   * Завершение анимации нажатия
   */
  animated() {
    this.gaApp.stores.giftCards.reactions.selectModal.isAnimated = true
    this.gaApp.stores.giftCards.reactions.selectModal.isAnimating = false
  }
}

/**
 * Проверяет, является ли переданная строка iso датой YYYY-MM-DDTHH:MN:SS.MSSZ
 * @param {string} isoDateString
 */
export const dateISOValidate = (isoDateString) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(isoDateString))
    return false
  const date = new Date(isoDateString)
  return (
    date instanceof Date && date > 0 && date.toISOString() === isoDateString
  )
}

import { TYPE } from '../../constants'
import { AdvcakeHomeModel, DigiAnalyticsViewModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.DIGI, DigiAnalyticsViewModel],
      [TYPE.ADVCAKE, AdvcakeHomeModel],
    ])
    super(models, data)
  }
}

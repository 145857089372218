export class GtmGa4CardItemBaseModel {
  constructor({ name, position, promotionName }) {
    this.creative_name = name
    this.creative_slot = `card_${position}`
    this.promotion_name = promotionName

    this.items = [
      {
        creative_name: name,
        creative_slot: `card_${position}`,
        promotion_name: promotionName,
      },
    ]
  }
}

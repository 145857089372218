export const getters = {
  hasDuration() {
    return Boolean(this.timer.duration)
  },
  isTimerRunning() {
    return Boolean(
      this.timer.timeoutId && (this.timer.resumeTime || this.timer.startTime),
    )
  },
  isTimerPaused() {
    return Boolean(!this.timer.timeoutId && this.timer.durationLeft)
  },
}

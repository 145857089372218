import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './stores'

export const game = {
  meta: {
    name: 'game',
    locales,
    routes,
  },
  layers: {
    stores,
    services,
    repositories,
  },
}

export { MODULE_TYPE } from './consts'

<template>
  <svg viewBox="0 0 30 31">
    <path
      stroke-width="1.25"
      d="M25.92 14.24c.739.302 1.43.557 2.02.762-.59.205-1.281.46-2.02.761-2.127.868-4.728 2.155-6.366 3.793-1.638 1.638-2.925 4.24-3.793 6.366-.301.738-.556 1.43-.761 2.02-.205-.59-.46-1.282-.761-2.02-.868-2.127-2.155-4.728-3.793-6.366-1.638-1.638-4.24-2.925-6.366-3.793a40.68 40.68 0 0 0-2.02-.761 40.79 40.79 0 0 0 2.02-.761c2.127-.868 4.728-2.155 6.366-3.793 1.638-1.638 2.925-4.24 3.793-6.366.301-.739.556-1.43.761-2.02.205.59.46 1.281.761 2.02.868 2.127 2.155 4.728 3.793 6.366 1.638 1.638 4.24 2.925 6.366 3.793Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-star',
}
</script>

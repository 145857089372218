<template>
  <svg viewBox="0 0 31 30">
    <g stroke="none">
      <path
        fill-rule="evenodd"
        d="M1.002 0h30v15h-30V0Zm8.896 10.932 2.824-6.736H10.89L9.077 8.78l-.733-3.899c-.087-.435-.428-.686-.805-.686H4.574l-.041.195c.609.132 1.3.346 1.72.573.256.141.328.262.413.593l1.39 5.375h1.842Zm8.461-4.094c.704.37 1.585.833 1.58 1.896-.005 1.4-1.203 2.304-3.032 2.306-.779-.01-1.53-.171-1.938-.358l.317-1.477.033.016c.4.187.903.423 1.756.41.5-.008 1.038-.208 1.043-.66.003-.293-.224-.505-.905-.836-.66-.322-1.536-.863-1.526-1.833.01-1.312 1.222-2.227 2.944-2.227.672 0 1.211.145 1.63.297L19.957 5.8c-.78-.359-1.468-.333-1.718-.301-.505.065-.734.323-.74.564-.007.32.388.526.861.775Zm7.742 4.094H24.49l-.21-1.006h-2.235l-.364 1.006h-1.83l2.615-6.242a.793.793 0 0 1 .742-.494h1.487l1.407 6.736Zm-2.64-4.916-.917 2.53h1.445l-.527-2.53Zm-9.69 4.916 1.44-6.736H13.47l-1.44 6.736h1.742Z"
        clip-rule="evenodd"
      />
      <path
        d="M7.85 23.308c.198.117.505.217.823.217.394 0 .616-.186.616-.463 0-.258-.172-.41-.6-.566-.566-.197-.925-.5-.925-.984 0-.561.464-.98 1.206-.98.369 0 .637.081.813.167l-.147.505a1.488 1.488 0 0 0-.682-.162c-.394 0-.56.207-.56.409 0 .262.196.383.65.554.592.228.873.525.873 1.01 0 .55-.413 1.02-1.286 1.02-.364 0-.736-.102-.924-.213l.144-.514Zm4.482-.833h-1.281v1h1.433v.509h-2.055v-3.402h1.98v.51H11.05v.878h1.281v.505Zm3.084 1.409c-.147.071-.47.152-.883.152-1.08 0-1.737-.676-1.737-1.71 0-1.12.782-1.793 1.821-1.793.41 0 .697.08.823.147l-.132.494a1.662 1.662 0 0 0-.666-.132c-.691 0-1.191.435-1.191 1.251 0 .747.433 1.222 1.186 1.222.247 0 .51-.05.676-.122l.103.491Zm1.097-3.301v1.983c0 .667.277.975.697.975.448 0 .717-.308.717-.975v-1.983h.62v1.943c0 1.05-.538 1.513-1.357 1.513-.788 0-1.297-.44-1.297-1.509v-1.949h.62v.002Zm2.695.051a5.28 5.28 0 0 1 .94-.076c.489 0 .818.08 1.05.273.186.156.292.394.292.686 0 .44-.297.737-.621.849v.015c.247.09.394.329.474.656.11.424.202.818.268.948h-.636c-.05-.101-.136-.378-.227-.803-.102-.444-.258-.584-.611-.595h-.314v1.398h-.615v-3.351Zm.615 1.498h.369c.42 0 .675-.222.675-.56 0-.368-.257-.546-.66-.546-.197 0-.323.015-.384.03v1.076Zm4.11.343H22.65v1h1.433v.509H22.03v-3.402h1.98v.51h-1.36v.878h1.282v.505Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-visa-secure',
}
</script>

import merge from 'lodash/merge'

import { localesRu as captcha } from '~/modules/captcha'
import customer from '~/modules/customer/locales/ru'
import customerOptions from '~/modules/customer/locales/ru-RU.options'
import filters from '~/modules/filters/locales/ru'
import footer from '~/modules/footer/locales/ru'
import footerOptions from '~/modules/footer/locales/ru-RU.options'
import header from '~/modules/header/locales/ru'
import headerOptions from '~/modules/header/locales/ru-RU.options'
import plp from '~/modules/plp/locales/ru'
import stockists from '~/modules/stockists/locales/ru'
import stockistsOptions from '~/modules/stockists/locales/ru-RU.options'
import streamerce from '~/modules/streamerce/locales/ru'
import user from '~/modules/user/locales/ru'

import layouts from '~/locales/languages/layouts/ru'
import pages from '~/locales/languages/pages/ru'

export default () => {
  return {
    ...captcha,
    ...merge(customer, customerOptions),
    ...filters,
    ...merge(footer, footerOptions),
    ...merge(header, headerOptions),
    ...plp,
    ...merge(stockists, stockistsOptions),
    ...streamerce,
    ...user,

    ...layouts,
    ...pages,
  }
}

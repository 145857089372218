import { DEVICE } from '@ga/constants/device'

export const getProductParams = ({ currentBreakpoint, widthMobile }) => {
  return (
    {
      [DEVICE.DESKTOP]: {
        indent: {
          x: '20px',
          y: '70px',
        },
        closeByClick: true,
      },
      [DEVICE.TABLET]: {
        indent: {
          x: '20px',
          y: '45px',
        },
      },
      [DEVICE.MOBILE]: {
        indent: {
          x: '0px',
          y: '10px',
        },
        position: 'center top',
        axis: 'y',
        direction: 'backward',
        width: widthMobile,
      },
    }[currentBreakpoint] ?? {}
  )
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 50 50">
    <path
      fill="#fff"
      d="M26.035 14.127C27.647 12.01 29.888 12 29.888 12s.333 1.99-1.268 3.908c-1.71 2.047-3.653 1.712-3.653 1.712s-.365-1.61 1.068-3.493Zm-.863 4.887c.829 0 2.368-1.136 4.371-1.136 3.449 0 4.805 2.445 4.805 2.445s-2.653 1.351-2.653 4.631c0 3.7 3.305 4.975 3.305 4.975s-2.31 6.48-5.431 6.48c-1.433 0-2.548-.963-4.058-.963-1.54 0-3.066.998-4.061.998-2.85 0-6.45-6.146-6.45-11.087 0-4.86 3.047-7.41 5.906-7.41 1.858 0 3.3 1.067 4.266 1.067Z"
    />
    <defs>
      <clipPath><path fill="#fff" d="M15 12h20v24.444H15z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-apple',
}
</script>

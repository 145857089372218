export const ATTRIBUTE = 'data-scroll-lock-fill-gap'

function updateAttribute(el, { value = true }) {
  if (value) {
    el.setAttribute(ATTRIBUTE, '')
  } else {
    el.removeAttribute(ATTRIBUTE)
  }
}

const scrollLockFillGap = {
  name: 'scroll-lock-fill-gap',

  inserted: updateAttribute,
  componentUpdated: updateAttribute,
}

export { scrollLockFillGap }

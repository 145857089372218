import { DISCLAIMER_TOOLTIP_PLACEMENT } from '../../../constants'
import { propValidator } from '../../../utils'
import { disclaimer as schemaDisclaimer } from '../../../utils/schemas'

import { useTooltip } from './scripts/composables'
import { SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-disclaimer',

  props: {
    size: {
      type: String,
      default: SIZE.XXS,
    },
    data: {
      type: Object,
      default: () => ({}),
      required: true,
      validator: (value) => propValidator(value, schemaDisclaimer),
    },
    reversed: {
      type: Boolean,
      default: false,
      required: true,
    },
    tooltipPlacement: {
      type: String,
      default: DISCLAIMER_TOOLTIP_PLACEMENT.AUTO,
      validator: (value) =>
        Object.values(DISCLAIMER_TOOLTIP_PLACEMENT).includes(value),
    },
  },

  setup(props) {
    const { tooltipPlacementSide } = useTooltip(
      props.tooltipPlacement,
      props.reversed,
    )

    return {
      tooltipPlacementSide,
    }
  },
}

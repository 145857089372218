/**
 *
Сервис предоставляет методы для получения метаданных и атрибутов изображений для SEO-оптимизации страниц отзывов:

`get productName()` - геттер, возвращающий название продукта, полученное из store `productCard`.

`get baseUrl()` - геттер, возвращающий базовый URL сайта.

`getFullProductName()` - метод, возвращающий полное название продукта, включая тип продукта и название, полученные из store `productCard`.

`getImageAttributes(product)` - метод, принимающий объект `product` и возвращающий объект с атрибутами изображения продукта (`title`, `alt`, `itemprop`).

`getImageAttributesReview()` - метод, возвращающий объект с атрибутами изображения отзыва (`alt`, `itemprop`).

`getAltImage()` - метод, возвращающий альтернативный текст для изображения отзыва.

`listingMeta()` - метод, возвращающий объект с метаданными для страницы списка отзывов.
 */

export class SeoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get productName() {
    if (!this.gaApp.stores.review.card?.productCard) {
      return ''
    }

    const { brand = '', name = '' } = this.gaApp.stores.review.card.productCard
    return `${brand} ${name}`
  }

  get baseUrl() {
    return this.gaApp.services.app.main.baseUrl
  }

  getFullProductName() {
    const { productType = '' } = this.gaApp.stores.review.card.productCard
    return `${productType} ${this.productName}`
  }

  getImageAttributes(product) {
    const { productType = '', brand = '', name = '', attributes = {} } = product
    const title = attributes?.colors?.name || ''
    const alt = `${productType} ${brand} ${name} ${title}`

    return {
      title,
      alt,
      itemprop: 'image',
    }
  }

  getImageAttributesReview() {
    return {
      alt: this.getAltImage(),
      itemprop: 'image',
    }
  }

  getAltImage() {
    return this.gaApp.i18n.t('review.seo.altImageText', {
      productName: this.productName,
      url: this.baseUrl,
    })
  }

  listingMeta() {
    const productName = this.getFullProductName()
    const {
      inStock,
      price = {},
      imageUrl = {},
    } = this.gaApp.stores.review.card.productCard

    const priceRegular = price?.regular ?? {}
    const amount = this.gaApp.services.app.currency.getNominal(priceRegular)
    const currency = priceRegular?.currency

    const { allReviewsCount: count } = this.gaApp.stores.review.statistic
    const url = `${this.baseUrl}${this.gaApp.route.path || ''}`

    // изображение продукта
    const image = imageUrl.url
      ? imageUrl.url
          // eslint-disable-next-line no-template-curly-in-string
          .replace('${screen}', imageUrl?.screen?.[0])
          // eslint-disable-next-line no-template-curly-in-string
          .replace('${format}', imageUrl?.format?.[0])
      : ''

    return {
      title: this.gaApp.i18n.t('review.listing.meta.title', {
        productName,
        count,
      }),

      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: `${count ? 'INDEX' : 'NOINDEX'},FOLLOW`,
        },
        {
          hid: 'title',
          name: 'title',
          content: this.gaApp.i18n.t('review.listing.meta.title', {
            productName,
            count,
          }),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.gaApp.i18n.t('review.listing.meta.description', {
            productName,
          }),
        },
        {
          name: 'keywords',
          content: this.gaApp.i18n.t('review.listing.meta.keywords', {
            productName,
          }),
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.gaApp.i18n.t('review.listing.meta.og.title', {
            productName,
          }),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.gaApp.i18n.t('review.listing.meta.og.description', {
            productName,
          }),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: image,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: url,
        },
        {
          hid: 'og:price:currency',
          property: 'og:price:currency',
          content: currency,
        },
        {
          hid: 'og:price:amount',
          property: 'og:price:amount',
          content: amount,
        },
        {
          hid: 'og:availability',
          property: 'og:availability',
          content: inStock,
        },
      ].filter(({ content }) => Boolean(content)),
    }
  }
}

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsProductClickModel extends GaAnalyticsBaseModel {
  constructor(product) {
    super('product')

    this.item = {
      itemId: product.id,
      itemListName: product.list,
      itemListId: product.itemListId,
      categoryId: product.categoryId?.toString(),
      index: product.position,
    }

    this.debug = {
      key: 'placements-item-click',
      data: product,
    }
  }
}

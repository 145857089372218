export class BaseBuilder {
  constructor(models, data) {
    this.data = data
    this.models = models
  }

  build() {
    const result = new Map()

    this.models.forEach((Model, key) => {
      result.set(key, new Model(this.data))
    })

    return result
  }
}

import { vueEmit } from '@ga/shared-browser'

const handlers = new WeakMap()

function isServer(vnode) {
  return (
    typeof vnode.componentInstance !== 'undefined' &&
    vnode.componentInstance.$isServer
  )
}

function getClickHandler() {
  return 'ontouchstart' in document.documentElement ? 'touchstart' : 'click'
}

const clickOutside = {
  bind(el, binding, vnode) {
    if (isServer(vnode)) {
      return
    }

    function handler(event) {
      if (!el.contains(event.target)) {
        vueEmit(vnode, 'click-outside', event)
      }
    }

    handlers.set(el, handler)

    document.addEventListener(getClickHandler(), handler)
  },

  unbind(el, binding, vnode) {
    const handler = handlers.get(el)

    if (isServer(vnode) || !handler) {
      return
    }

    document.removeEventListener(getClickHandler(), handler)
  },
}

export { clickOutside }

export const endpoints = {
  addProducts: () => ({
    url: 'user/favorites/products',
  }),
  deleteProducts: () => ({
    url: 'user/favorites/products',
  }),
  addBrands: () => ({
    url: 'user/favorites/brands',
  }),
  deleteBrands: () => ({
    url: 'user/favorites/brands',
  }),

  getProductsV2: () => ({
    url: 'favourites/products',
  }),
  getFilters: () => ({
    url: 'favourites/filters',
  }),
  getPlp: () => ({
    url: 'favourites/plp',
  }),
  addProductsV2: () => ({
    url: 'favourites/products',
  }),
  deleteProductsV2: () => ({
    url: 'favourites/products',
  }),
  addBrandsV2: () => ({
    url: 'favourites/brands',
  }),
  deleteBrandsV2: () => ({
    url: 'favourites/brands',
  }),
}

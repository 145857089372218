<template>
  <svg fill="none" stroke="none" viewBox="0 0 52 50">
    <path
      fill="#fff"
      d="M51.636 21.631v7.335h-2.558V23.82h-2.464v5.145h-2.558V21.63h7.58Z"
    />
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M38.802 29.228c2.29 0 3.991-1.437 3.991-3.617 0-2.109-1.255-3.478-3.352-3.478-.968 0-1.766.349-2.368.95.144-1.243 1.171-2.151 2.302-2.151l2.227-.005 1.111-2.177s-2.467.058-3.614.058c-2.621.046-4.391 2.485-4.391 5.447 0 3.45 1.726 4.973 4.094 4.973Zm.014-5.167c.85 0 1.44.572 1.44 1.55 0 .88-.524 1.606-1.44 1.608-.876 0-1.466-.672-1.466-1.593 0-.978.59-1.565 1.466-1.565Z"
      clip-rule="evenodd"
    />
    <path
      fill="#fff"
      d="M32.625 26.51s-.604.357-1.506.424c-1.038.032-1.962-.64-1.962-1.83 0-1.163.815-1.83 1.935-1.83.687 0 1.596.488 1.596.488s.664-1.25 1.009-1.874c-.63-.489-1.47-.757-2.448-.757-2.465 0-4.374 1.645-4.374 3.957 0 2.342 1.794 3.95 4.374 3.9.722-.027 1.716-.286 2.323-.685l-.947-1.793Z"
    />
    <path fill="#5B57A2" d="m0 16.53 3.549 6.494v3.961L.004 33.466 0 16.53Z" />
    <path
      fill="#D90751"
      d="m13.627 20.66 3.325-2.086 6.806-.007-10.131 6.354v-4.26Z"
    />
    <path
      fill="#FAB718"
      d="m13.608 16.492.019 8.597-3.558-2.237V10l3.539 6.492Z"
    />
    <path
      fill="#ED6F26"
      d="m23.758 18.568-6.806.006-3.344-2.082L10.069 10l13.689 8.568Z"
    />
    <path
      fill="#63B22F"
      d="M13.627 33.502v-4.171l-3.558-2.195L10.071 40l3.556-6.498Z"
    />
    <path
      fill="#1487C9"
      d="m16.944 31.434-13.395-8.41L0 16.53l23.743 14.896-6.8.009Z"
    />
    <path
      fill="#017F36"
      d="m10.071 40 3.555-6.498 3.317-2.068 6.8-.008L10.07 40Z"
    />
    <path
      fill="#984995"
      d="m.004 33.465 10.094-6.33-3.393-2.131-3.156 1.98-3.545 6.481Z"
    />
    <defs>
      <clipPath><path fill="#fff" d="M0 10h52v30H0z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-sbp',
}
</script>

export const REACTIONS_PURCHASE_PATH = '/'
export const REACTIONS_RECEIVE_EXCEPTION_REDIRECT_PATH = '/'
export const REACTIONS_RECEIVE_REDIRECT_PATH = '/'

/**
 * Тип триггера отображения модального окна выбора реакции
 * @enum {string}
 * @readonly
 */
export const REACTIONS_TRIGGER_TYPE = {
  TIMER: 'timer',
  BUTTON: 'button',
}

export class AnalyticsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливаем данные GA-аналитики для события клика по ссылке элемента.
   *
   * Делаем запрос в редирект сервис, что бы понять на какую страницу проихсодит переход.
   * Когда редирект сервис вернул pageType/moduleType, то можно установить аналитику для конкретного модуля
   * и делаем перенаправление по url.
   *
   * @param {Object} data - объект данных, содержащий аналитику и информацию о перенаправлении.
   * @param {boolean} data.nuxt - флаг, указывающий, обрабатывается ли навигация
   * @param {string} data.url - URL для перенаправления.
   */
  async setGaAnalytics(data) {
    if (!data.nuxt) {
      return (window.location = data.url)
    }

    const redirect = await this.gaApp.services.brandzone.api.fetchRedirect({
      url: data.url,
    })

    try {
      this.gaApp.analytics.modules.brandzone.onContentElementLinkClick({
        data,
        redirect,
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.gaApp.services.app.router.push(data.url)
    }
  }
}

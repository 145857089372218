export * from './modal'

export const ACCESS_TOKEN_KEY = 'access_token'
export const HAS_REFRESH_TOKEN_KEY = 'has_refresh_token'
export const HAS_ACCESS_TOKEN_KEY = 'has_access_token'

export const CUSTOMER_PREFIX = 'customer_'

export const CLIENT_ID = 'client_web'

export const REQUEST_FIELD_NAME = {
  CLIENT_ID: 'client_id',
  GRANT_TYPE: 'grant_type',
  VERIFICATION_TOKEN: 'verification_token',
  PHONE_NUMBER: 'phone_number',
  PHONE_COUNTRY_ISO: 'phone_country_iso',
  ACCESS_TOKEN: 'access_token',
  RESEND_TOKEN: 'resend_token',
  CAPTCHA_TOKEN: 'captcha_token',
}

export const GRANT_TYPE = {
  REFRESH: 'refresh_token',
  PHONE_NUMBER: 'phone_number_token',
}

export const TOKEN_TYPE = {
  PHONE_CALL: 'phone_call_token',
  PHONE_SMS: 'phone_sms_token',
}

export const COUNTDOWN_TYPE = {
  PHONE: 'phone',
  SMS: 'sms',
}

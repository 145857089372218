import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4CounterpartyUploadClickModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'load_details'
    this.type = 'kppk'
  }
}

import {
  DAYS_UNTIL_NEAREST_DELIVERY_DATE,
  HOLIDAY_DATES,
} from '../../constants'

export const state = () => ({
  details: {
    deliveryDate: null,
    cityId: '',
    qty: '',
    comment: '',
  },
  counterparty: {
    requisites: null,
    companyName: '',
  },
  contacts: {
    managerName: '',
    managerPosition: '',
    phone: '',
    email: '',
  },
  cities: [],
  steps: ['details', 'counterparty', 'contacts'],
  deliveryOptions: {
    excludeWeekends: true,
    disabledDeliveryDates: HOLIDAY_DATES,
    daysUntilNearestDeliveryDate: DAYS_UNTIL_NEAREST_DELIVERY_DATE,
  },
})

import { cardsStore } from './cards'
import { editStore } from './edit'
import { mainStore } from './main'
import { modalStore as modal } from './modal'
import { orderStore } from './order'
import { ordersStore } from './orders'
import { settingsStore } from './settings'

export const stores = (gaApp) => {
  return {
    main: mainStore(gaApp),
    modal: modal(gaApp),
    orders: ordersStore(gaApp),
    order: orderStore(gaApp),
    edit: editStore(gaApp),
    settings: settingsStore(gaApp),
    cards: cardsStore(gaApp),
  }
}

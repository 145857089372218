<template>
  <svg viewBox="0 0 28 28">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M25.6 7.984c.162-.567 0-.984-.773-.984h-2.553c-.649 0-.948.36-1.11.757 0 0-1.299 3.319-3.138 5.474-.595.624-.865.822-1.19.822-.162 0-.397-.198-.397-.765V7.984c0-.68-.188-.984-.73-.984h-4.011a.629.629 0 0 0-.65.615c0 .646.92.794 1.014 2.61v3.942c0 .864-.148 1.021-.473 1.021-.865 0-2.97-3.333-4.22-7.147C7.126 7.299 6.88 7 6.228 7H3.674c-.73 0-.875.36-.875.757 0 .71.865 4.226 4.03 8.877 2.11 3.177 5.082 4.899 7.787 4.899 1.622 0 1.823-.383 1.823-1.042v-2.4c0-.765.154-.918.668-.918.378 0 1.027.199 2.542 1.73 1.731 1.816 2.017 2.63 2.99 2.63h2.553c.73 0 1.094-.383.884-1.138-.23-.752-1.057-1.843-2.153-3.137-.595-.737-1.488-1.531-1.758-1.928-.38-.51-.27-.738 0-1.192 0 0 3.11-4.594 3.435-6.154Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-social-vk',
}
</script>

<template>
  <svg viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="19.375" fill="none" stroke-width="1.25" />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M10 22.899h1.336v-2.286h1.94v-1.006h-1.94v-1.56h2.45V17H10v5.899Zm5.107 0h1.329v-2.277h.577l1.411 2.277h1.419l-1.56-2.508a1.9 1.9 0 0 0 .769-.426c.034-.032.067-.067.098-.102l.02-.023c.203-.244.32-.556.326-.976V18.79c0-.538-.177-.957-.498-1.253a1.577 1.577 0 0 0-.12-.1c-.396-.297-.965-.437-1.659-.437h-2.112v5.899Zm1.329-3.185h.75c.38 0 .654-.093.819-.277a.75.75 0 0 0 .178-.416c.007-.05.01-.102.01-.157V18.79a1.052 1.052 0 0 0-.034-.247.728.728 0 0 0-.035-.1c-.13-.3-.439-.415-.904-.42h-.784v1.691Zm8.618 3.185v-1.04h-2.649v-1.468h2.013v-.974h-2.013V18.04h2.517V17h-3.845v5.899h3.977Zm5.34 0v-1.04h-2.647v-1.468h2.012v-.974h-2.012V18.04h2.516V17h-3.845v5.899h3.977Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-circle-free',
}
</script>

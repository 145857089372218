import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4CouponApplyModel extends GtmBaseModel {
  constructor({ coupon, isSuccess }) {
    super({ event: EVENT.COUPON.GA4 })

    this.event_category = 'cart'
    this.action_type = 'click_promocode_apply'
    this.type = coupon.source
    this.coupon_code = coupon.value
    this.success = String(isSuccess)
  }
}

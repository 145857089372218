export const buildNotificationEntityName = ({ brand, name }) => {
  const brandName = brand ? brand.toUpperCase() : ''
  const productName = name ? name.toLowerCase() : ''

  return `${brandName} ${productName || ''}`
}

export const buildNotificationEntityNameAsHtml = ({
  brand,
  name,
  hasDivider = true,
}) => {
  const brandName = brand ? brand.toUpperCase() : ''
  const productName = name ? name.toLowerCase() : ''
  const divider = hasDivider ? '<br>' : ''

  return `${divider}<span style="font-weight: 500">${brandName} ${
    productName || ''
  }</span>${divider}`
}

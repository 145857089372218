import { computed, unref } from 'vue'

const MAX_LABELS_COUNT = 3

/**
 * Возвращает данные для для компонента labels
 * @param {Ref<import('../../../../../utils/schemas/labels-adapter').LabelsAdapterItem[]>} data
 */
export const useDataLabels = (data) => {
  const dataLabels = computed(() => {
    return unref(data)
      .slice(0, MAX_LABELS_COUNT)
      .map((label) => {
        const {
          id,
          group,
          text,
          resource,
          textColor,
          backgroundColor,
          textGravity,
        } = label

        return {
          id,
          group,
          text,
          textColor,
          textGravity,
          backgroundColor,
          image: resource?.resourceUrl,
          imageType: resource?.resourceType,
        }
      })
  })

  return {
    dataLabels,
  }
}

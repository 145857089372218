export const getters = (gaApp) => ({
  // очередь id видео, которая должна воспроизводится автоплеем
  // приоритет в зависимости от размера карточек - от большего к меньшему
  // при одинаковых размерах - очередь по индексу в массиве продуктов
  sortedQueueIds() {
    if (this.visibleProducts.length === 0) {
      return []
    }

    return [...this.visibleProducts]
      .sort((a, b) => {
        if (a.currentView !== b.currentView) {
          return a.currentView - b.currentView
        }
        return a.priorityIndex - b.priorityIndex
      })
      .map(({ productId }) => productId)
  },

  // последнее id из общей очереди, которая видна на экране
  lastVisibleQueueId() {
    if (!this.sortedQueueIds.length) {
      return null
    }

    return this.sortedQueueIds[this.sortedQueueIds.length - 1]
  },

  // очередь id видео, которая должна воспроизводится в данный момент
  queuePlayingIds() {
    return this.sortedQueueIds.filter(
      (productId) => !this.mapVideoState[productId].active,
    )
  },

  // последнее id из очереди на воспроизведение
  lastQueuePlayingId() {
    if (!this.queuePlayingIds?.length) {
      return null
    }

    return this.queuePlayingIds[this.queuePlayingIds.length - 1]
  },

  // id видео, которое проигрывается сейчас
  activeQueuePlayingId() {
    return this.queuePlayingIds?.find(
      (productId) => this.mapVideoState[productId].playing,
    )
  },

  // индикатор активного видео
  hasPlayingQueueVideo() {
    return !!this.activeQueuePlayingId
  },
})

import { DESKTOP_SCROLL_END, MEDIA_TYPES } from '../../constants'

export const state = () => ({
  metaData: null,

  items: [],

  isFavoritesFetching: false,

  isPageScrolling: false,
  isBlocksScrolling: false, // touchmove по алфавитной навигации

  searchBarConfig: {
    [MEDIA_TYPES.DESKTOP]: {
      initial: {
        fontSize: 60,
        letterSpacing: -0.2,
        height: 200,
      },

      squeezed: {
        fontSize: 35,
        letterSpacing: -0.4,
        height: 80,
      },

      fontGrades: [
        {
          fontSize: 60,
          letterSpacing: -0.2,
        },
        {
          fontSize: 50,
          letterSpacing: -0.2,
        },
        {
          fontSize: 45,
          letterSpacing: -0.2,
        },
        {
          fontSize: 40,
          letterSpacing: -0.2,
        },
        {
          fontSize: 35,
          letterSpacing: -0.4,
        },
      ],

      scrollTrigger: {
        start: 120,
        end: DESKTOP_SCROLL_END,
      },

      clear: true,
    },

    [MEDIA_TYPES.TABLET]: {
      initial: {
        fontSize: 45,
        letterSpacing: -0.2,
        height: 110,
      },

      squeezed: {
        fontSize: 35,
        letterSpacing: -0.4,
        height: 80,
      },

      fontGrades: [
        {
          fontSize: 45,
          letterSpacing: -0.2,
        },
        {
          fontSize: 40,
          letterSpacing: -0.2,
        },
        {
          fontSize: 35,
          letterSpacing: -0.4,
        },
      ],

      scrollTrigger: {
        start: 10,
        end: 110,
      },

      clear: true,
      zoomFocus: true,
    },

    [MEDIA_TYPES.MOBILE]: {
      initial: {
        fontSize: 30,
        letterSpacing: -0.4,
        height: 60,
      },

      squeezed: {
        fontSize: 20,
        letterSpacing: 0,
        height: 50,
      },

      fontGrades: [
        {
          fontSize: 30,
          letterSpacing: -0.4,
        },
      ],

      scrollTrigger: {
        start: 0,
        end: 60,
      },

      clear: true,
      zoomFocus: true,
    },
  },
})

export const state = () => {
  return {
    modal: {
      opened: false,
    },
    countryModal: {
      opened: false,
      props: {},
    },
    confirmModal: {
      opened: false,
      props: {},
    },
    dropdown: {
      opened: false,
    },
    selectedCountry: '',
    selectedStoreDomain: '',
  }
}

import { TYPE } from '../../constants'
import { GtmExitModel } from '../../models/gtm/exit.model'
import { BaseBuilder } from '../base.builder'

export class ExitBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmExitModel]])
    super(models, data)
  }
}

export const RECEIVE_RETURN_PURCHASE_PATH = '/'

/**
 * Страница получения карты: ВЭПК или ЭПК
 * @enum {string}
 * @readonly
 */
export const RECEIVE_PAGE_TYPES = {
  RECEIVE: { storeName: 'digitalReceive', localeName: 'receiveCard' },
  RECEIVE_RETURN: {
    storeName: 'digitalReceiveReturn',
    localeName: 'receiveReturnCard',
  },
}

/**
 * Возможные опции источника при получении ЭПК
 */
export const RECEIVE_SOURCE_LINK_OPTIONS = {
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  SMS: 'sms',
}

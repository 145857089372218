<template>
  <svg fill="none" stroke="none" viewBox="0 0 24 24">
    <path
      fill="#FF0001"
      d="M12 0a12 12 0 1 0 12 12A12.016 12.016 0 0 0 12 0Zm8.74 12a8.74 8.74 0 1 1-17.48 0 8.74 8.74 0 0 1 17.48 0Z"
    />
    <path
      fill="#000"
      d="M23.981 10.582A12.521 12.521 0 0 0 13.401.014a1.683 1.683 0 0 0-1.337.412 1.706 1.706 0 0 0 .86 2.972 9.083 9.083 0 0 1 7.69 7.681 1.705 1.705 0 0 0 3.319.223c.068-.233.085-.479.048-.72Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-podeli',
}
</script>

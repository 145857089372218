<template>
  <svg viewBox="0 0 50 50">
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke="currentColor"
      stroke-dasharray="141.764 7.461"
      stroke-dashoffset="149.226"
      stroke-linecap="round"
      stroke-width="1.5"
      transform="rotate(-90 25 25)"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-outline-spinner',
}
</script>

export class SchemaService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  mobileApp() {
    const currencyCode = this.gaApp.i18n.locale.currencyСode

    return [
      {
        '@type': 'SoftwareApplication',
        name: this.gaApp.i18n.t('app.schema.android.name'),
        description: this.gaApp.i18n.t('app.schema.android.description'),
        operatingSystem: this.gaApp.i18n.t(
          'app.schema.android.operatingSystem',
        ),
        applicationCategory: this.gaApp.i18n.t(
          'app.schema.android.applicationCategory',
        ),
        downloadUrl: this.gaApp.stores.app.common.apps.android.href,
        rating: {
          '@type': 'AggregateRating',
          ratingValue: this.gaApp.i18n.t(
            'app.schema.android.rating.ratingValue',
          ),
          ratingCount: this.gaApp.i18n.t(
            'app.schema.android.rating.ratingCount',
          ),
        },
        offer: {
          '@type': 'Offer',
          price: this.gaApp.i18n.t('app.schema.android.offer.price'),
          priceCurrency: currencyCode,
        },
      },

      {
        '@type': 'SoftwareApplication',
        name: this.gaApp.i18n.t('app.schema.ios.name'),
        description: this.gaApp.i18n.t('app.schema.ios.description'),
        operatingSystem: this.gaApp.i18n.t('app.schema.ios.operatingSystem'),
        applicationCategory: this.gaApp.i18n.t(
          'app.schema.ios.applicationCategory',
        ),
        downloadUrl: this.gaApp.stores.app.common.apps.ios.href,
        rating: {
          '@type': 'AggregateRating',
          ratingValue: this.gaApp.i18n.t('app.schema.ios.rating.ratingValue'),
          ratingCount: this.gaApp.i18n.t('app.schema.ios.rating.ratingCount'),
        },
        offer: {
          '@type': 'Offer',
          price: this.gaApp.i18n.t('app.schema.ios.offer.price'),
          priceCurrency: currencyCode,
        },
      },
    ]
  }
}

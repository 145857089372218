import { analytics } from './analytics'
import * as components from './components'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './store'

export const ticker = {
  meta: {
    name: 'ticker',
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

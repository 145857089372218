import { AdvcakeBaseModel } from '../advcake-base.model/advcake-base.model'
import { AdvcakeProductModel } from '../advcake-product.model/advcake-product.model'
import { AdvcakeOrderInfoModel } from '../advcake-order-info.model/advcake-order-info.model'

export class AdvcakeThankYouViewModel extends AdvcakeBaseModel {
  constructor({
    id,
    orderTotal,
    products,
    coupon,
  }) {

    super()

    this.basketProducts = products.map(product => {
      return new AdvcakeProductModel(product)
    })

    this.orderInfo = new AdvcakeOrderInfoModel({ id, orderTotal, coupon })
  }
}

import { _state } from '../_common/_state'

export const state = () => {
  return {
    ..._state(),
    listingPageNumber: null,
    analyticsData: {
      itemListName: null,
      searchTerm: null,
    },
  }
}

import { FocusDetector, HoverDetector } from '@ga/shared-browser'

export class BrowserWindowService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  updateWindowSize() {
    const { innerWidth, innerHeight } = window

    this.gaApp.services.app.window.setWindowSize({
      innerWidth,
      innerHeight,
    })
  }

  setWindowPropertyInnerHeight() {
    const propertyName = '--window-inner-height'
    const propertyValue = `${window.innerHeight}px`

    document.documentElement.style.setProperty(propertyName, propertyValue)
  }

  bindWindowResizeListeners() {
    let resizingTimeout = null

    const RESIZING_DELAY = 200

    const onResizeFinish = () => {
      this.gaApp.services.app.window.setWindowResizingState(false)

      this.gaApp.services.app.window.setWindowSizeLazy()
    }

    const onResizeStart = () => {
      this.gaApp.services.app.window.setWindowResizingState(true)

      resizingTimeout = clearTimeout(resizingTimeout)
      resizingTimeout = setTimeout(onResizeFinish, RESIZING_DELAY)
    }

    const onOrientationChange = () => {
      onResizeStart()
      this.updateWindowSize()
    }

    const onResize = () => {
      onResizeStart()
      this.updateWindowSize()
      this.setWindowPropertyInnerHeight()

      this.gaApp.services.app.window.setCurrentBreakpoint()
    }

    window.addEventListener('orientationchange', onOrientationChange)
    window.addEventListener('resize', onResize)

    this.updateWindowSize()
    this.gaApp.services.app.window.setWindowSizeLazy()
    this.setWindowPropertyInnerHeight()

    this.gaApp.services.app.window.setCurrentBreakpoint()
  }

  detectWindowHoverMedia() {
    // Если установлена кука тестирования
    if (this.gaApp.isAutomatedTesting || this.gaApp.automatedTestingConfig) {
      this.gaApp.stores.app.main.withHover = true
      return
    }

    const hoverDetector = new HoverDetector()

    if (!hoverDetector.isSupportHover) {
      // если @media (hover) не поддерживается, то предполагаем,
      // что это старый десктопный браузер и разрешаем :hover
      this.gaApp.stores.app.main.withHover = true
      return
    }

    const hoverMedia = window.matchMedia('(hover: hover) and (pointer: fine)')

    hoverMedia.addListener(
      (event) => (this.gaApp.stores.app.main.withHover = event.matches),
    )

    this.gaApp.stores.app.main.withHover = hoverMedia.matches
  }

  /*
   * TODO:
   *  - доработать определение источников фокуса
   */
  // eslint-disable-next-line max-statements
  bindKeyboardAndPointerListerners() {
    const focusDetector = new FocusDetector()

    const onPointerEvent = () => {
      focusDetector.pointerInteraction = true

      focusDetector.resetPointerInteractionTimeout()
    }

    const onKeyboardEvent = () => {
      focusDetector.pointerInteraction = false

      focusDetector.unsetPointerInteractionTimeout()
    }

    const onFocusIn = () => {
      const source = focusDetector.getFocusSource()

      this.gaApp.stores.app.main.focusSource = source

      this.gaApp.stores.app.main.withFocusKeyboard =
        !focusDetector.pointerInteraction
    }

    const html = document.documentElement

    html.addEventListener('mousedown', onPointerEvent, false)
    html.addEventListener('mouseup', onPointerEvent, false)
    html.addEventListener('click', onPointerEvent, false)

    html.addEventListener('keydown', onKeyboardEvent, false)

    html.addEventListener('focusin', onFocusIn, false)

    onFocusIn()
  }
}

<template>
  <svg fill="inherit" viewBox="0 0 45 45">
    <path
      fill="inherit"
      fill-rule="evenodd"
      stroke="none"
      d="M22.5 45C34.926 45 45 34.926 45 22.5S34.926 0 22.5 0 0 10.074 0 22.5 10.074 45 22.5 45Zm-4.875-15.355L30 22.5l-12.375-7.145v14.29Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-play-light',
}
</script>

import chunk from 'lodash/chunk'

import { CHUNK_SIZE_ANALYTICS } from '../constants'

export class ViewedProducts {
  constructor() {
    this.products = []
    this.addedProducts = {}
  }

  setViewed(product) {
    if (!this.addedProducts[product.itemId]) {
      this.products.push(product)
      this.addedProducts[product.itemId] = true
    }

    return this
  }

  getLastChunk() {
    const chunks = chunk(this.products, CHUNK_SIZE_ANALYTICS)

    const lastChunk = chunks[chunks.length - 1]

    return lastChunk.length === CHUNK_SIZE_ANALYTICS ? lastChunk : []
  }

  getAllProducts() {
    return this.products
  }

  clearProducts() {
    this.products = []
    this.addedProducts = {}
  }
}

import { GaImage } from '@ga/ui-components/image'

// @vue/component
export default {
  name: 'ga-app-store-switcher-country',

  components: {
    GaImage,
  },

  props: {
    flag: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
}

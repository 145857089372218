import { repositories } from './repositories'
import { services } from './services'

export const cache = {
  meta: {
    name: 'cache',
  },
  layers: {
    services,
    repositories,
  },
}

<template>
  <svg fill="none" viewBox="0 0 28 20">
    <path stroke-width="1.5" d="M5 10h22M13 2l-8 8 8 8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-back-arrow-modal-v2',
}
</script>

export const OPTIONS = {
  EVENT: 'user_authorize',
  CATEGORY: 'user_auth',
  LABEL: {
    MODAL_OPEN: 'modal_open',
    SUBMIT_SMS: 'submit_sms',
    SUBMIT_TEL: 'submit_tel',
    RESUBMIT_SMS: 'resubmission_sms',
    LOGIN: 'login',
  },
}

export default function (analytic) {
  return {
    // Открытие модального окна
    onOpenAuthModal() {
      analytic.send({
        event: OPTIONS.EVENT,
        eventCategory: OPTIONS.CATEGORY,
        eventLabel: OPTIONS.LABEL.MODAL_OPEN,
      })
    },

    // Клик по кнопке "Получить код"
    onSubmitSms() {
      analytic.send({
        event: OPTIONS.EVENT,
        eventCategory: OPTIONS.CATEGORY,
        eventLabel: OPTIONS.LABEL.SUBMIT_SMS,
      })
    },

    // Клик по кнопке "Подтверждение номера"
    onSubmitTel() {
      analytic.send({
        event: OPTIONS.EVENT,
        eventCategory: OPTIONS.CATEGORY,
        eventLabel: OPTIONS.LABEL.SUBMIT_TEL,
      })
    },

    // Клик по ссылке "Отправить код повторно"
    onResubmitSms() {
      analytic.send({
        event: OPTIONS.EVENT,
        eventCategory: OPTIONS.CATEGORY,
        eventLabel: OPTIONS.LABEL.RESUBMIT_SMS,
      })
    },

    // Пользователь успешно залогинился
    onSuccessLogin() {
      analytic.send({
        event: OPTIONS.EVENT,
        eventCategory: OPTIONS.CATEGORY,
        eventLabel: OPTIONS.LABEL.LOGIN,
      })
    },
  }
}

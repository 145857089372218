<template>
  <svg fill="none" viewBox="0 0 12 12">
    <path stroke="currentColor" d="M2.1 3.7h7v7h-7z" />
    <path
      stroke="currentColor"
      d="M3.911 4.8V2.533a1.733 1.733 0 0 1 3.467 0V4.8"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-cart-bold',
}
</script>

<template>
  <svg viewBox="0 0 41 26">
    <g stroke="none">
      <path
        fill-rule="evenodd"
        d="M3.636 0h33.34l.415.001c.117.001.234.002.352.006.254.007.511.022.763.067.256.046.494.12.726.24A2.44 2.44 0 0 1 40.3 1.38c.118.232.193.47.239.726.045.252.06.509.067.763.003.117.005.234.005.351l.001.417V22.78l-.006.351a5.065 5.065 0 0 1-.067.763 2.567 2.567 0 0 1-.24.727 2.443 2.443 0 0 1-1.067 1.067c-.232.118-.47.194-.726.24-.252.044-.509.06-.763.067-.118.002-.235.004-.352.004-.138.002-.277.002-.416.002H3.636c-.139 0-.278 0-.416-.002-.117 0-.234-.002-.351-.004a5.133 5.133 0 0 1-.764-.068 2.567 2.567 0 0 1-.726-.24A2.42 2.42 0 0 1 .312 24.62a2.566 2.566 0 0 1-.24-.726 5.063 5.063 0 0 1-.067-.763 17.25 17.25 0 0 1-.005-.35V3.553a53.608 53.608 0 0 1 .005-.684 5.02 5.02 0 0 1 .068-.763c.045-.256.12-.494.239-.726A2.433 2.433 0 0 1 1.379.313c.232-.118.47-.193.726-.24a5.1 5.1 0 0 1 .764-.066c.117-.004.234-.005.35-.006h.417Zm33.75.868H3.226c-.11.001-.222.002-.333.005a4.327 4.327 0 0 0-.634.054 1.708 1.708 0 0 0-.486.159 1.567 1.567 0 0 0-.688.688 1.699 1.699 0 0 0-.159.486 4.29 4.29 0 0 0-.054.634c-.003.111-.004.222-.005.333v19.549c0 .11.002.221.005.332.005.194.016.421.054.633.033.185.083.34.158.487a1.56 1.56 0 0 0 .688.688c.149.076.303.126.487.16.214.037.451.048.634.053a55.387 55.387 0 0 0 .738.005h33.754l.339-.005c.178-.005.415-.016.631-.054a1.68 1.68 0 0 0 .484-.159 1.557 1.557 0 0 0 .689-.688c.075-.148.125-.302.158-.485a4.31 4.31 0 0 0 .054-.635c.003-.11.005-.22.005-.331l.001-.405V3.631l-.001-.403c0-.113-.002-.223-.005-.333a4.304 4.304 0 0 0-.054-.634 1.687 1.687 0 0 0-.158-.486 1.578 1.578 0 0 0-.688-.689 1.711 1.711 0 0 0-.487-.159 4.334 4.334 0 0 0-.633-.054 16.53 16.53 0 0 0-.334-.005Z"
        clip-rule="evenodd"
      />
      <path
        d="M11.265 8.746c.348-.435.584-1.019.522-1.616-.51.026-1.13.336-1.49.771-.323.373-.609.982-.534 1.553.571.05 1.142-.285 1.502-.708Zm.515.82c-.83-.05-1.535.471-1.931.471-.397 0-1.004-.446-1.66-.434-.854.013-1.647.496-2.08 1.264-.892 1.537-.236 3.817.631 5.068.421.62.929 1.301 1.598 1.277.631-.025.88-.41 1.647-.41.768 0 .99.41 1.66.398.693-.013 1.127-.62 1.548-1.24.483-.706.68-1.387.693-1.425-.013-.012-1.337-.52-1.35-2.045-.012-1.276 1.04-1.883 1.09-1.92-.595-.88-1.523-.979-1.845-1.004Zm7.226-1.728c1.804 0 3.06 1.244 3.06 3.054 0 1.816-1.282 3.066-3.105 3.066h-1.997v3.176h-1.443V7.838h3.485Zm-2.042 4.909h1.656c1.256 0 1.97-.676 1.97-1.849 0-1.172-.714-1.842-1.964-1.842h-1.662v3.691Zm5.479 2.461c0-1.185.908-1.913 2.518-2.004l1.856-.109v-.522c0-.753-.51-1.204-1.36-1.204-.805 0-1.307.386-1.43.992h-1.314c.078-1.224 1.121-2.126 2.796-2.126 1.643 0 2.692.87 2.692 2.229v4.67h-1.333V16.02h-.032c-.393.753-1.25 1.23-2.139 1.23-1.327 0-2.254-.825-2.254-2.042Zm4.374-.612v-.535l-1.669.103c-.83.058-1.301.426-1.301 1.005 0 .593.49.98 1.237.98.972 0 1.733-.67 1.733-1.553Zm2.643 5.031v-1.128c.104.026.335.026.451.026.645 0 .993-.27 1.205-.966 0-.013.123-.412.123-.419l-2.448-6.783h1.507l1.714 5.514h.025l1.714-5.514h1.469l-2.539 7.131c-.58 1.643-1.25 2.17-2.653 2.17-.116 0-.464-.012-.567-.031Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-apple-pay',
}
</script>

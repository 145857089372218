import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, unref, watch } from 'vue'

import { useWindowScrollTopListener } from '@ga/use/window-scroll-top-listener'

import { STATE } from '../../../../../constants'

/**
 * Установка состояния хедера
 * для полного варианты возможны STATE.INITIAL, STATE.SCROLLED, STATE.ACTIVE
 * для minimized возможны STATE.SCROLLED, STATE.ACTIVE
 */
export const useState = (overlaid, minimized, hovered) => {
  const { $gaApp } = useContext()

  const updateHeaderState = (newState) => {
    if (newState === $gaApp.stores.header.main.headerState) return
    $gaApp.services.header.main.setHeaderState({ value: newState })
  }

  const stateActive = computed(() => {
    if (unref(overlaid)) {
      return true
    }

    const isNotMinimized = !unref(minimized)
    const hasHover = unref(hovered)

    return isNotMinimized && hasHover
  })

  const updateState = (scrollTop) => {
    // состояние active имеет приоритет над scroll
    if (unref(stateActive)) {
      updateHeaderState(STATE.ACTIVE)
      return
    }

    if (unref(minimized) || scrollTop > 0) {
      updateHeaderState(STATE.SCROLLED)
      return
    }

    updateHeaderState(STATE.INITIAL)
  }

  useWindowScrollTopListener(updateState)

  onMounted(() => {
    updateState(window.pageYOffset)

    watch([stateActive, minimized], () => {
      updateState(window.pageYOffset)
    })
  })

  return {
    updateState,
  }
}

<template>
  <svg stroke="none" viewBox="0 0 7 40">
    <path
      d="M6.889 40v-2.541H2.593l4.296-2.386v-1.33l-4.296-2.395h4.296V28.77H0v3.431l3.87 2.129L0 36.422V40h6.889ZM0 27.94h6.889v-7.624H5.167v5.055h-.945v-4.88H2.546v4.88h-.86V20.39H0v7.551Zm0-8.324h6.889V17.03H2.713l4.176-4.275v-2.606H0v2.587h3.917L0 16.7v2.917ZM3.287 9.395C4.62 9.395 7 8.835 7 4.863v-.367C7 .624 4.62 0 3.287 0H0v2.606h3.083c.843 0 1.852.385 1.852 2.082 0 1.725-1.01 2.092-1.852 2.092H0v2.615h3.287Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-menu',
}
</script>

import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './stores'

export * from './composable'

export const pdp = {
  meta: {
    name: 'pdp',
    locales,
    routes,
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
  components,
}

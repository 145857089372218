<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke-width="1.25" d="m9 3 4.5 4.5L9 12m4-4.5H1" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-search-big-arrow',
}
</script>

import { PAGINATION_BUTTON } from '../../constants/pagination'

export const getPromoStateInitial = () => ({
  pages: [],
  viewedIds: [],

  promotionsCount: 0,

  pagination: {
    [PAGINATION_BUTTON.NEXT]: {
      pending: false,
      error: false,
    },
    [PAGINATION_BUTTON.PREV]: {
      pending: false,
      error: false,
    },
  },

  isLoading: false,
  shouldReloadPage: false,

  metaTitle: null,
  metaDescription: null,
  metaKeywords: null,
})

export default () => {
  return getPromoStateInitial()
}

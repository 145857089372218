import isEqual from 'lodash/isEqual'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setFiltersData(data) {
    const { analytics, filters, productsCount } = data

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { plp } = this.gaApp.stores.purchased

    return isEqual(filtersHash, plp.productsHash)
  }

  async refreshProducts({ select }) {
    this.gaApp.stores.purchased.plp.pages = []

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        filter: { ...select },
      }),

      this.gaApp.services.purchased.plp.fetchProducts(),
    ])
  }

  /**
   * Метод нужен, чтобы получить актуальный список выбранных фильтров на основе тех,
   * которые пришли с бека и которые находятся в query params
   * @returns {Promise<object[]>}
   */
  async getFilteredQueryFilters() {
    // Сбрасываем стейт, чтобы получить фильтры, относящиеся только к текущей локации
    this.gaApp.services.filters.main.resetState()
    // Устанавливаем ссылку на функцию запроса фильтров, т.к. после ресета
    // эта ссылка теряется
    this.gaApp.services.filters.main.setRequestFiltersFn(
      this.gaApp.services.purchased.api.getFilters.bind(
        this.gaApp.services.purchased.api,
      ),
    )

    const { data: filtersData } =
      await this.gaApp.services.purchased.api.getFilters()

    const query = this.gaApp.route.query

    // Получаем список только тех фильтров из query, которые есть и в query, и в фильтрах,
    // пришедших с бека
    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        filtersData.filters,
        query,
      )

    // Устанавливаем актуальные выбранные фильтры
    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
    })

    // Устанавливаем актуальное кол-во выбранных фильтров
    this.gaApp.stores.filters.main.setCountSelectedFilters(
      this.gaApp.stores.filters.main.getFiltersMainSelected.length,
    )

    return filtersFromQuery
  }

  /**
   * Нужно для того, чтобы обновить query параметры, убрав оттуда
   * те фильтры, которых нет в текущей локации пользака
   */
  updateFiltersUrlPostTask() {
    this.gaApp.services.app.scheduler.postTasks([
      {
        task: () => {
          this.gaApp.services.filters.main.updateFiltersURL()
        },
      },
    ])
  }
}

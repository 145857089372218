export const TYPE = {
  GTM_4: 'gtm_4',
  GAA: 'ga_analytics',
}

export const ACTIONS = {
  USER_INTERACTION: {
    GA4: 'user_interaction',
  },
  VIEW_ITEM_LIST: {
    GA4: 'view_item_list',
  },
  SELECT_ITEM: {
    GA4: 'select_item',
  },
  ADD_TO_CART: {
    GA4: 'add_to_cart',
  },
  ADD_TO_WISHLIST: {
    GA4: 'add_to_wishlist',
  },
  VIEW_ITEM: {
    GA4: 'view_item',
  },
}

export const EVENT_CATEGORIES = {
  MAGAZINE: 'magazine',
  STREAM: 'streamers',
}

export const ACTION_TYPES = {
  CLICK_ARTICLE_MAIN: 'click_article_main',
  SHOW_BUTTON_ALL_PRODUCTS: 'show_button_all_products',
  CLICK_BUTTON_ALL_PRODUCTS: 'click_button_all_products',
  SHOW_ARTICLE_MAIN: 'show_article_main',
  SHOW_ARTICLE_RECOMEND: 'show_article_recomend',
  CLICK_ARTICLE_RECOMEND: 'click_article_recomend',
  SCROLL: 'scroll',
  SHOW_STREAM_RECOMMEND: 'show_stream_recomend',
  SHOW_STREAM_MAIN: 'show_stream_main',
  CLICK_STREAM_MAIN: 'click_stream_main',
  CLICK_STREAM_RECOMMEND: 'click_stream_recomend',
  CLICK_STREAM_WATCH: 'click_watch_stream',
  CLICK_FILTER: 'click_filter',
}

export const PLACEMENT_TYPES = {
  MAGAZINE: 'magazine',
  STREAM: 'streamers',
}

export const BLOCK_TYPES = {
  SINGLE_PRODUCT: 'single_product',
  SLIDER: 'slider',
  LISTING: 'listing',
}

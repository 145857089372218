import { computed, ref, toRefs } from 'vue'

import { GaModal } from '../modal'
import { GaModalSwipe } from '../modal-swipe'

// @vue/component
export default {
  name: 'ga-adaptive-modal',

  components: {
    GaModalSwipe,
    GaModal,
  },

  inheritAttrs: false,

  model: {
    prop: 'opened',
    event: 'toggle',
  },

  props: {
    /**
     * Отображает модальное окно. Используется для v-model
     */
    opened: {
      type: Boolean,
      default: false,
    },

    /**
     * Определяет отображение ga-swipe-modal
     */
    swipe: {
      type: Boolean,
      default: false,
    },

    /**
     * Целевой портал.
     *
     * Поле необходимо, чтобы предотвратить баг с двумя одновременно открытыми видами при реактивном переключении swipe-вида.
     */
    portalTarget: {
      type: String,
      default: null,
    },
  },

  setup: (props, { emit }) => {
    const { swipe } = toRefs(props)
    const modal = ref(null)
    const componentType = computed(() =>
      swipe.value ? 'ga-modal-swipe' : 'ga-modal',
    )

    const onModalComponentToggleOpened = ($event) => {
      emit('toggle', $event)
    }

    const scrollTo = (...args) => {
      modal.value?.scrollTo?.(...args)
    }

    const getScrollTop = () => {
      return modal.value?.getScrollTop?.()
    }

    const getScrollHeight = () => {
      return modal.value?.getScrollHeight?.()
    }
    return {
      modal,

      componentType,
      onModalComponentToggleOpened,

      // публичные методы
      scrollTo,
      getScrollTop,
      getScrollHeight,
    }
  },
}

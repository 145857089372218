import { TYPE } from '../../constants'
import { GtmGa4StreamRecommendCardShowModel } from '../../models'
import { BaseBuilder } from '../index'

export class StreamRecommendCardShowBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4StreamRecommendCardShowModel]])
    super(models, data)
  }
}

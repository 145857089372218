import capitalize from 'lodash/capitalize'

/*
 * Приводит каждое слово в строке в capitalize case
 */
export const eachWordCapitalize = ({ text, delimeter = ' ' }) => {
  if (typeof text !== 'string') {
    return ''
  }
  return text
    .trim()
    .split(delimeter)
    .map((word) => capitalize(word))
    .join(delimeter)
}

export const GaModuleEyezonButton = () =>
  import(
    /* webpackChunkName: 'module/eyezon/GaModuleEyezonButton' */
    './molecules/button'
  ).then(({ GaEyezonButton }) => GaEyezonButton)

export const GaModuleEyezonCard = () =>
  import(
    /* webpackChunkName: 'module/eyezon/GaModuleEyezonCard' */
    './molecules/card'
  ).then(({ GaEyezonCard }) => GaEyezonCard)

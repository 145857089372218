import { computed, toRefs, unref } from 'vue'

import { useSize } from './size'

export const useStyle = (props) => {
  const { size, old, showCurrency } = toRefs(props)

  const { fontSize } = useSize(size, old, showCurrency)

  const style = computed(() => ({
    fontSize: `${unref(fontSize)}px`,
  }))

  return {
    style,
  }
}

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async slots(params = {}) {
    const { url } = this.endpoints.slots()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async block(params = {}) {
    const { url } = this.endpoints.block()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async storyItem(storyId, params = {}) {
    const { url } = this.endpoints.storyItem(storyId)

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // получаем данные редиректа для отправки аналитики для страницы перехода
  async getRedirectData(parameters = {}) {
    const params = { ...parameters }

    const { url } = this.endpoints.redirect()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

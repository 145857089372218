import isEmpty from 'lodash/isEmpty'

import { ALL_STORES_ID } from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  storesByCity(cityId) {
    if (isEmpty(this.gaApp.stores.stockists.main.formattedStores[cityId])) {
      this.gaApp.services.stockists.api.getCityStores(cityId)
    }

    return this.gaApp.stores.stockists.main.formattedStores[cityId] || []
  }

  cityText(cityId) {
    return this.gaApp.stores.stockists.main.cityStores[cityId]?.city ?? ''
  }

  cities() {
    // добавляем в список городов вариант "все магазины"
    return (
      [
        {
          value: ALL_STORES_ID,
          text: this.gaApp.i18n.t('stockists.select.all'),
        },
        ...this.gaApp.stores.stockists.main.cities,
      ] || []
    )
  }
}

export const state = () => {
  return {
    // инфа о продуктах, которые видны на экране в нужной области видимости
    visibleProducts: [],
    // хэш таблица состояний видео productId - state
    mapVideoState: {},
    timerId: null,
    currentPlayingId: null,
    scrolledPage: false,
    isNotFirstShowedProduct: false,
  }
}

export const MEDIA_TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

export const LOCALES = {
  AR: 'ar',
  EN: 'en',
}

export const DESKTOP_SCROLL_END = 320

export const BRANDS_SEARCH_ABORT_KEY = 'brands-search'

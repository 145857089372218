import { ACTION_TYPES, ACTIONS, EVENT_CATEGORIES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4BrandClickModel extends GtmBaseModel {
  constructor({ label, isFavorite, searchPhrase }) {
    super({
      event: ACTIONS.USER_INTERACTION.GA4,
      eventCategory: EVENT_CATEGORIES.BRANDS_LIST,
      actionType: ACTION_TYPES.CLICK_BRAND,
    })

    this.action_label = label
    this.wishlist = isFavorite

    if (searchPhrase) {
      this.search_phrase = searchPhrase
    }
  }
}

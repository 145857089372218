import { SCREEN_TYPES } from '../../../constants'

export class OrderProductModel {
  constructor(item) {
    this.item_name = item.name
    this.item_id = item.itemId
    this.price = item.price.actual.amount
    this.item_brand = item.brand
    this.item_list_name = SCREEN_TYPES.ACCOUNT_ORDER
    this.index = item.index
  }
}

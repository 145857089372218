import { TYPE } from '../../constants'
import { GtmIssetAbFtModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class IssetAbFtBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmIssetAbFtModel]])
    super(models, data)
  }
}

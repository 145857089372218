import { useContext } from '@nuxtjs/composition-api'

import { unref } from 'vue'

import { ViewedProducts } from '~/analytics/global/product-listing/helpers'

export const useProductListingAnalytic = (analyticData) => {
  const { $gaApp } = useContext()

  const viewedProduct = new ViewedProducts()

  const onFullIntersect = (product) => {
    const chunk = viewedProduct
      .setViewed(product)
      .getLastChunk(unref(analyticData).chunkSize)

    if (chunk.length === unref(analyticData).chunkSize) {
      $gaApp.analytics.global.productListing.onProductView({
        product,
        analyticData: unref(analyticData),
        chunk,
      })
    }
  }

  const onListingUnmounted = () => {
    const products = viewedProduct.getAllProducts()

    const productsLength = products.length
    const productsLengthRest = productsLength % unref(analyticData).chunkSize
    const lengthRestDiff = productsLength - productsLengthRest

    if (
      productsLengthRest &&
      productsLengthRest < unref(analyticData).chunkSize
    ) {
      $gaApp.analytics.global.productListing.onDestroyProductView({
        products,
        analyticData: unref(analyticData),
        lengthRestDiff,
      })
    }

    // Если не слать событие ecommerce: null, то могут возникать коллизии
    $gaApp.analytics.global.productListing.onPageLeaveView()

    // Очищаем список просмотренных продуктов
    viewedProduct.clearProducts()
  }

  return {
    productListingListeners: {
      'item-full-intersect': onFullIntersect,
      'page-unmounted': onListingUnmounted,
    },
  }
}

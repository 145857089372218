import { mediaQueryInstance } from '~/modules/app'

export default (context, inject) => {
  const isTruthyMediaQuery = (breakpoint) => {
    return mediaQueryInstance.isTruthyMediaQuery(
      breakpoint,
      context.$gaApp.stores.app.window.currentBreakpoint,
    )
  }

  inject('mq', isTruthyMediaQuery)
}

import { FILTER_PAGE_NUMBER } from '../constants/filters'

/**
 * Сервис предоставляет методы для работы с пагинацией списка отзывов:

`get qtyButtons()` - геттер, который возвращает количество кнопок пагинации на основе общего количества отзывов из store `statistic`.

`queryStringWithoutPageParam()` - метод, который возвращает строку запроса без параметра страницы.

`buildHref(pageNumber)` - метод, который строит URL-адрес для перехода на указанную страницу пагинации (`pageNumber`).
 */

export class PaginationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get qtyButtons() {
    const { allReviewsCount } = this.gaApp.stores.review.statistic

    return allReviewsCount ? Math.ceil(allReviewsCount / 20) : 0
  }

  queryStringWithoutPageParam() {
    const query = { ...this.gaApp.route.query }

    delete query[FILTER_PAGE_NUMBER.key]

    return Object.entries(query)
      .map(([param, value]) => `${param}=${value}`)
      .join('&')
  }

  buildHref(pageNumber) {
    const { path } = this.gaApp.route

    const queryPageParam = `${FILTER_PAGE_NUMBER.key}=${pageNumber}`

    const query = [queryPageParam, this.queryStringWithoutPageParam()]
      .filter(Boolean)
      .join('&')

    return `${path}?${query}`
  }
}

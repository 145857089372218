export const TYPE = {
  DIGI: 'digi_analytics',
  GTM_4: 'gtm_4',
  GAA: 'ga_analytics',
}

export const EVENT = {
  DIGI: {
    view: 'BRAND_PAGE_VIEW',
  },
  GTM: {
    event: 'user_interaction',
    eventCategory: 'brandzone',
    eventPromotion: 'select_promotion',
    eventView: 'view_promotion',
  },
}

import { useContext } from '@nuxtjs/composition-api'
import { useElementHover } from '@vueuse/core'

import { computed, onMounted, ref, unref, watch } from 'vue'

export const useParams = (desktop, root) => {
  const { $gaApp } = useContext()

  const minimized = computed(() => {
    return $gaApp.stores.header.main.headerMinimized
  })

  const full = computed(() => {
    return !unref(minimized)
  })

  const mounted = ref(false)

  onMounted(() => {
    mounted.value = true
    if (!unref(desktop)) {
      $gaApp.services.header.main.setHeaderMinimizedState({
        value: true,
      })
    }
  })

  watch(desktop, (value) => {
    $gaApp.services.header.main.setHeaderMinimizedState({
      value: !value,
    })
  })

  const extended = computed(() => {
    return $gaApp.stores.header.main.headerExtended
  })

  const collapsed = computed(() => {
    return !unref(extended)
  })

  const authorized = computed(() => {
    return $gaApp.stores.user.main.isAuthorized
  })

  const hasHover = useElementHover(root)
  const hovered = computed(() => {
    return unref(hasHover) && $gaApp.stores.app.main.withHover
  })

  return {
    mounted,
    minimized,
    full,
    extended,
    collapsed,
    authorized,
    hovered,
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4BackClickModel extends GtmBaseModel {
  constructor({ step, type }) {
    super({})

    this.action_type = 'click_previous'
    this.type = type
    this.step = step
  }
}

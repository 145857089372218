export const actions = () => ({
  setData(data) {
    Object.assign(this, data)
  },

  // Методы работы с плашкой-перетяжкой
  setTickerHas(value) {
    this.ticker.has = value
  },

  setTickerGap(value) {
    this.ticker.gap = value
  },

  setTickerPosition(value) {
    this.ticker.position = value
  },
})

import flow from 'lodash/flow'
import omit from 'lodash/omit'
import set from 'lodash/set'

export class BannerService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(this.gaApp.stores.brandzone.banner.items).includes(
      block.id,
    )
  }

  addBlockData(block) {
    this.gaApp.stores.brandzone.banner.items.push({
      id: block.id,
      type: block.type,
      data: this.mapBlockData(block),
    })
  }

  fillStore(block) {
    if (!this.blockExists(block)) {
      this.addBlockData(block)
    }
  }

  mapBlockData(block) {
    return flow(
      this.replaceImageFiled,
      this.wrapForContract,
      this.pickOnlyData,
    )(block)
  }

  /**
   * TODO: порефачить
   *
   * Заменяем свойство "imageUrl" свойством "image" в объекте баннера внутри объекта блока.
   * Нужно, что бы правильно передать в компонент баннера картинку
   */
  replaceImageFiled(block) {
    const renameField = (banner) => {
      if (banner.imageUrl) {
        set(banner, 'image', banner.imageUrl)
        omit(banner, 'imageUrl')
      }

      return banner
    }

    if (block.data.banners) {
      block.data.banners = block.data.banners.map(renameField)
    } else {
      block.data = renameField(block.data)
    }

    return block
  }

  /**
   * TODO: порефачить
   * Оборачиваем данные в поле items, если баннеров несколько
   *                    в поле item, если баннер один
   */
  wrapForContract(block) {
    if (block.data?.banners) {
      const temp = block.data?.banners

      block.data = {
        items: temp,
      }
    } else {
      const temp = block.data

      block.data = {
        item: temp,
      }
    }

    return block
  }

  pickOnlyData(block) {
    return {
      ...block.data,
    }
  }

  reset() {
    this.gaApp.stores.brandzone.banner.$reset()
  }
}

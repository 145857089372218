import { useContext } from '@nuxtjs/composition-api'

import { computed, reactive, ref, unref, watch } from 'vue'

import { GaPortalTarget } from '@ga/ui-components/portal-target'

import { useHeaderProps } from './scripts/use/header-props'
import { useMediaType } from './scripts/use/media-type'

// @vue/component
export default {
  name: 'ga-viewport-excluded-header',

  components: {
    GaPortalTarget,
  },

  props: {
    name: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { $gaApp } = useContext()

    const rootRef = ref()

    const { mediaType } = useMediaType()

    const { headerHeight, headerExtended, headerHeightCollapsed } =
      useHeaderProps(mediaType)

    const top = computed(() => {
      return unref(headerExtended)
        ? `${unref(headerHeight)}px`
        : `${unref(headerHeightCollapsed)}px`
    })

    const zIndex = computed(() => {
      const zIndexes = Object.values($gaApp.stores.app.main.zIndexes)

      return zIndexes.length ? Math.max(...zIndexes) + 1 : null
    })

    const unwatch = watch(rootRef, (value) => {
      $gaApp.services.app.window.setViewportExcludedHeaderRef(
        value.$el || value,
      )

      unwatch()
    })

    return {
      rootRef,

      styles: reactive({
        top,
        zIndex,
      }),
    }
  },
}

export const toOpenGraphMeta = (openGraphObject) =>
  Object.entries(openGraphObject).map(([propertyAlias, content]) => {
    const property = `og:${propertyAlias}`

    return {
      property,
      hid: property,
      content,
    }
  })

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  formatDateForView(date, format) {
    return this.gaApp.libs.dateWrapper(date).format(format)
  }

  async fetchArticlePreviewListing(id) {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchPreviewListing(id)
      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  fetchArticle(id) {
    return this.gaApp.repositories.articles.main.fetchArticle(id)
  }

  fetchArticleByUrl(url) {
    return this.gaApp.repositories.articles.main.fetchArticleByUrl(url)
  }

  async fetchFilters() {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchListingFilters()
      return response.data
    } catch {
      return { filters: [] }
    }
  }

  // Запрос списка статей
  async fetchArticles(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchArticlesListing(params)

      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  // Запрос списка статей конкретной рубрики
  async fetchRubricArticles(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchRubricArticles(params)
      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  prepareListingData(response) {
    const { currentPage, totalCount, articles, title, seo } = response.data

    const mappedArticles = articles.map((item) => ({
      image: item.imageUrl,
      date: this.formatDateForView(item.publishedDate, 'D MMMM YYYY'),
      subtitle: item.subTitle,
      ...item,
    }))

    return {
      currentPage,
      totalCount,
      title,
      seo,
      articles: mappedArticles,
    }
  }

  // fetch products for content
  async fetchProductsBySku(skuArray = []) {
    const user = this.gaApp.services.user.main.data
    const cityId = this.gaApp.services.location.main.getDeliveryAddress()?.id

    const params = {
      itemIds: skuArray,
      cityId,
      customerGroupId: user.customerGroupId || user.groupId || '0',
    }

    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchProductsBySku(params)
      return data.products || []
    } catch (e) {
      console.error('fetchProductsBySku error: ' + e.message)
      return []
    }
  }

  async fetchProductsByCategoryId({ categoryId, filters, pageNumber }) {
    const user = this.gaApp.services.user.main.data
    const cityId = this.gaApp.services.location.main.getDeliveryAddress()?.id

    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchProducts({
          cityId,
          categoryId,
          pageNumber,
          filters,
          customerGroupId: user.customerGroupId || user.groupId || '0',
        })

      return {
        products: data.products,
        count: data.count,
      }
    } catch (e) {
      console.error('fetchProductsByCategoryId error: ' + e.message)
      throw e
    }
  }

  async fetchProductsByUrl(url, pageNumber = 1) {
    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchCategoryDataByUrl(url)

      if (!data.entityId) throw new Error('некорректный url категории ' + url)

      const shouldUseFilters = this.gaApp.features.get(
        'flaconUseFiltersInFetchProductsRequest',
      )
      const filters = shouldUseFilters ? data.filters : []

      const { products, count } = await this.fetchProductsByCategoryId({
        categoryId: data.entityId,
        filters,
        pageNumber,
      })

      return {
        products: products || [],
        count,
      }
    } catch (e) {
      console.error('fetchProductsUrl error: ' + e.message)
      return {
        products: [],
        count: 0,
      }
    }
  }

  async fetchArticleViewed({ captchaToken, id }) {
    try {
      await this.gaApp.repositories.articles.main.fetchArticleViewed({
        captchaToken,
        id,
      })
    } catch (e) {
      console.error(e.message)
    }
  }
}

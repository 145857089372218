export const INPUT_PHONE_SIZE = {
  S: 's',
  M: 'm',
}

export const COUNTRY_MODAL = {
  Z_INDEX: 10001,
  HEIGHT: 182,
}

export const MIN_COUNTRIES_LENGTH = 1

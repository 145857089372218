<template>
  <svg stroke="none" viewBox="0 0 244 302">
    <path
      fill="inherit"
      d="M243.14 151.134c-6.774 83.306-66.523 150.839-133.453 150.839S-6.007 234.44.767 151.134C7.542 67.828 67.291.294 134.22.294c66.93 0 115.695 67.534 108.92 150.84Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-dark-green-blob-mobile',
}
</script>

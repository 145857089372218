import { ALL_STORES_KEY } from '../../constants/keys'

export const getters = (gaApp) => ({
  getPageNumberFirst() {
    const pages = this.pages

    return pages.length ? +pages[0].number : 0
  },

  getPageNumberLast() {
    const pages = this.pages

    return pages.length ? +pages[pages.length - 1].number : 0
  },

  getLoadedPagesCount() {
    return this.pages?.length
  },

  shopsKey() {
    const currentCityId = gaApp.stores.promo.filters.currentCity
    const currentStoreId = gaApp.stores.promo.filters.currentShop

    const stores =
      gaApp.stores.promo.filters.stores[currentCityId]?.stores || []

    return currentStoreId === ALL_STORES_KEY
      ? stores.map((store) => store.id)
      : [stores.find((store) => store.id === currentStoreId)?.id]
  },
})

import dayjs from 'dayjs'

import 'dayjs/locale/ru'
import 'dayjs/locale/be'
import 'dayjs/locale/kk'
import 'dayjs/locale/ar'
import 'dayjs/locale/en'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import weekday from 'dayjs/plugin/weekday'
import devHelper from 'dayjs/plugin/devHelper'
import toObject from 'dayjs/plugin/toObject'
import objectSupport from 'dayjs/plugin/objectSupport'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(weekday)
dayjs.extend(devHelper)
dayjs.extend(toObject)
dayjs.extend(objectSupport)
dayjs.extend(isBetween)

dayjs.locale('ru')

dayjs.tz.setDefault('Europe/Moscow')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}

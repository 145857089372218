<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path stroke="inherit" stroke-width="1.5" d="M18 2 2 18M2 2l16 16" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-20-cross',
}
</script>

<template>
  <svg fill="none" viewBox="0 0 42 26">
    <path
      fill="#3C4043"
      fill-rule="evenodd"
      stroke="none"
      d="M38.218.009H3.33l-.363.006c-.264.007-.53.022-.79.067-.264.046-.51.121-.75.24A2.521 2.521 0 0 0 .787.77a2.44 2.44 0 0 0-.711 1.343 4.88 4.88 0 0 0-.07.763c-.003.117-.005.234-.005.35L0 3.643v19.126c.001.117.003.234.006.35.007.255.023.512.07.763a2.437 2.437 0 0 0 .711 1.343c.187.182.403.334.64.45.24.118.485.193.75.239.26.045.525.06.789.067l.363.005h35.32c.12 0 .241-.003.362-.005a5.52 5.52 0 0 0 .79-.067 2.73 2.73 0 0 0 .75-.24 2.448 2.448 0 0 0 1.35-1.792c.047-.251.063-.508.07-.762.003-.117.005-.234.005-.35l.001-.417V3.226l-.006-.35a4.876 4.876 0 0 0-.07-.763A2.451 2.451 0 0 0 41.19.77a2.525 2.525 0 0 0-.639-.449 2.729 2.729 0 0 0-.75-.239 5.436 5.436 0 0 0-.79-.067 19.997 19.997 0 0 0-.363-.005l-.43-.001Z"
      clip-rule="evenodd"
    />
    <path
      fill="#FFFFFE"
      fill-rule="evenodd"
      stroke="none"
      d="M38.219.875h.424c.114.001.23.002.345.005.2.006.436.016.655.054.19.033.35.084.503.159a1.62 1.62 0 0 1 .711.688c.078.147.13.3.164.486.039.21.05.437.055.633a50.825 50.825 0 0 1 .006.743v19.122l-.006.331a4.14 4.14 0 0 1-.056.634 1.569 1.569 0 0 1-.463.883c-.121.118-.26.215-.413.29a1.788 1.788 0 0 1-.5.158c-.223.038-.468.05-.652.054-.116.003-.231.004-.35.005H3.334c-.116 0-.231-.002-.343-.005a4.71 4.71 0 0 1-.655-.054 1.795 1.795 0 0 1-.504-.16 1.604 1.604 0 0 1-.71-.686 1.657 1.657 0 0 1-.164-.487 4.191 4.191 0 0 1-.056-.632c-.003-.11-.004-.222-.005-.332V3.232c0-.11.002-.222.005-.333.006-.194.017-.42.056-.634a1.568 1.568 0 0 1 .463-.883c.121-.117.26-.214.412-.29.153-.075.313-.125.503-.158.219-.038.454-.048.655-.054.115-.003.23-.004.344-.005h34.884Z"
      clip-rule="evenodd"
    />
    <path
      fill="#3C4043"
      stroke="none"
      d="M20.21 13.597V17.3h-1.195V8.16h3.171a2.872 2.872 0 0 1 2.05.795 2.604 2.604 0 0 1 .134 3.731l-.133.132c-.557.517-1.24.78-2.05.78H20.21Zm0-4.314v3.192h2.006A1.58 1.58 0 0 0 23.397 12a1.587 1.587 0 0 0-.037-2.266 1.647 1.647 0 0 0-1.144-.452h-2.005Z"
    />
    <path
      fill="#3C4043"
      stroke="none"
      d="M27.854 10.842c.884 0 1.582.233 2.095.693.512.459.765 1.1.765 1.91V17.3H29.57v-.867h-.052c-.498.714-1.152 1.07-1.976 1.07-.698 0-1.293-.203-1.76-.611-.461-.38-.721-.94-.706-1.53 0-.65.252-1.16.75-1.546.498-.386 1.166-.576 1.998-.576.713 0 1.293.131 1.753.38v-.27c0-.401-.178-.78-.49-1.043a1.757 1.757 0 0 0-1.151-.43c-.669 0-1.196.277-1.582.831l-1.055-.649c.564-.809 1.419-1.217 2.555-1.217Zm-1.545 4.541c0 .306.148.59.394.765.267.204.594.314.928.306.505 0 .988-.196 1.345-.546.393-.365.594-.795.594-1.29-.372-.292-.892-.438-1.56-.43-.483 0-.891.116-1.218.342-.32.226-.483.51-.483.853Z"
    />
    <path
      fill="#3C4043"
      fill-rule="evenodd"
      stroke="none"
      d="m37.28 11.046-3.996 9.002H32.05l1.485-3.149-2.622-5.853h1.3l1.894 4.49h.023l1.85-4.49h1.3Z"
      clip-rule="evenodd"
    />
    <path
      fill="#4285F4"
      fill-rule="evenodd"
      stroke="none"
      d="M15.375 12.795c0-.357-.03-.714-.089-1.064h-5.044v2.019h2.89a2.428 2.428 0 0 1-1.07 1.596v1.312h1.724c1.01-.911 1.59-2.26 1.59-3.863Z"
      clip-rule="evenodd"
    />
    <path
      fill="#34A853"
      fill-rule="evenodd"
      stroke="none"
      d="M10.241 17.926c1.441 0 2.66-.466 3.544-1.268l-1.724-1.312c-.483.321-1.1.503-1.82.503-1.396 0-2.577-.925-3-2.164H5.464v1.355c.906 1.771 2.756 2.886 4.776 2.886Z"
      clip-rule="evenodd"
    />
    <path
      fill="#FBBC04"
      fill-rule="evenodd"
      stroke="none"
      d="M7.24 13.685a3.11 3.11 0 0 1 0-2.012v-1.348H5.466a5.116 5.116 0 0 0 0 4.708l1.776-1.348Z"
      clip-rule="evenodd"
    />
    <path
      fill="#EA4335"
      fill-rule="evenodd"
      stroke="none"
      d="M10.241 9.508c.765-.014 1.5.27 2.05.787l1.53-1.501a5.218 5.218 0 0 0-3.58-1.363c-2.02 0-3.87 1.122-4.776 2.894L7.24 11.68c.424-1.246 1.605-2.172 3.001-2.172Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-gpay-logo',
}
</script>

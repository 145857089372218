import { defineStore, getActivePinia } from 'pinia'

import state from './state'

export const mainStore = (gaApp) => {
  const init = defineStore('promoMain', {
    state,
  })

  return init(getActivePinia())
}

import isEmpty from 'lodash/isEmpty'

import { PAGE_TYPE } from '../constant'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get type() {
    return this.gaApp.stores.plp.main.pageData?.type
  }

  isPageMeta() {
    return Boolean(this.gaApp.stores.plp.main.pageData?.params.meta)
  }

  isPageTypeSearch() {
    return this.type === PAGE_TYPE.SEARCH
  }

  isPageTypeListing() {
    return this.type === PAGE_TYPE.LISTING
  }

  isPageTypeBrandzone() {
    return this.gaApp.router.currentRoute.fullPath.startsWith('/brands')
  }

  // временный метод
  // используется, чтобы отличить брендзону от дочерней категории
  isBrandzoneChildCategory() {
    return !this.gaApp.router.currentRoute.fullPath.match(
      /^\/brands\/([^/]+?)(\/|$|\?)$/,
    )
  }

  setHeaderColorTheme({ category, slots } = {}) {
    switch (true) {
      case isEmpty(slots?.blocks) && Boolean(category?.imageUrl?.url):
        return this.gaApp.services.header.theme.setHeaderTransparentInvertedColorScheme()

      default:
        return this.gaApp.services.header.theme.setHeaderRegularColorScheme()
    }
  }

  /**
   * Временный метод. TODO: Удалить когда избавимся от ФТ showSortByRating
   *
   * Исключить значения фильтров конкретного типа, у которых отключен ФТ
   *
   * @param {Array<Object>} фильтры - список фильтров.
   * @return {Array<Object>} - список фильтров.
   */
  exlcudeFiltersValuesByFeatureToggle(filters = []) {
    const map = {
      byRating: this.gaApp.features.get('showSortByRating'),
    }

    return filters.map((filter) => {
      if (filter.type === 'sortType') {
        filter.values = filter.values.filter((value) =>
          value.id in map ? map[value.id] : true,
        )
      }

      return filter
    })
  }

  /**
   * Временный метод. TODO: Удалить когда избавимся от ФТ showSortByRating
   *
   * Исключить фильтры, у которых отключен ФТ
   *
   * @param {Array<Object>} фильтры - список фильтров.
   * @return {Array<Object>} - список фильтров.
   */
  exlcudeRedirectFiltersByFeatureToggle(filters = []) {
    const map = {
      byRating: this.gaApp.features.get('showSortByRating'),
    }

    return filters.filter((filter) => {
      if (filter.type === 'sortType') {
        if (filter.valueId in map) {
          return map[filter.valueId] && filter
        }

        return filter
      }

      return filter
    })
  }
}

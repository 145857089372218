import { image } from '../../../../../utils/schemas'

export const label = {
  type: 'object',
  properties: {
    id: { type: 'string', required: true },
    group: { type: 'string', required: true },
    text: { type: 'string' },
    textColor: { type: 'string' },
    textGravity: { type: 'array', items: { type: 'string' } },
    backgroundColor: { type: 'string' },
    image: {
      oneOf: [
        image,
        {
          type: 'string',
        },
      ],
    },
    imageType: { type: 'string' },
  },
  additionalProperties: false,
}

export const labels = {
  type: 'array',
  items: label,
}

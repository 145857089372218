<template>
  <svg fill="none" viewBox="0 0 16 15">
    <path
      stroke="currentColor"
      stroke-width="1.25"
      d="m7.667 1.618 1.416 3.38.146.351.379.031 3.653.302-2.778 2.392-.288.248.087.37.842 3.567-3.133-1.903-.324-.197-.325.197-3.133 1.903.842-3.568.087-.37-.288-.247-2.778-2.392 3.653-.302.379-.031.146-.35 1.417-3.381Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-star',
}
</script>

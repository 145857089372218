/**
 * Переводит секунды в формат M:SS (0:05)
 * @param {Number} seconds
 * @return {String} 'M:SS'
 */

export const secondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedMinutes = String(minutes).padStart(1, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

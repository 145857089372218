import { hide } from './hide'
import { image } from './image'
import { imageMeta } from './image-meta'

export const imageExpanded = {
  type: 'object',
  properties: {
    image: { ...image, required: true },
    meta: {
      oneOf: [imageMeta, hide],
      required: true,
    },
  },
}

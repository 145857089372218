import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsButtonCategoryClickModel extends GaAnalyticsBaseModel {
  constructor({
    creativeName,
    creativeSlot,
    itemListId,
    itemListName,
    promotionName,
    url,
  }) {
    super('listing')

    this.category = {
      creativeName,
      creativeSlot,
      itemListId,
      itemListName,
      promotionName,
      url,
    }
  }
}

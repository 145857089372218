import { EVENT } from '../../../constants'
import { MindboxProductModel } from '../mindbox-product.model/mindbox-product.model'

export class MindboxCartModel {
  constructor({ id, price, addToCart }, country) {
    const { ADD_TO_CART, REMOVE_FROM_CART } = EVENT
    this.operation = addToCart ? ADD_TO_CART.MINDBOX[country]
      : REMOVE_FROM_CART.MINDBOX[country]

    const eventName = addToCart ? 'addProductToList' : 'removeProductFromList'
    this.data = {
      [eventName]: new MindboxProductModel({ id, price }),
    }
  }
}

import { computed } from 'vue'

import { ICON } from '../../constants'

export const useIcon = (props) => {
  const iconComponent = computed(() => ICON[props.type])

  return {
    iconComponent,
  }
}

import { computed } from 'vue'

import { SIZE, VIEW } from './scripts/const'

// @vue/component
export default {
  name: 'ga-spinner',

  props: {
    view: {
      type: String,
      default: VIEW.DOT,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },

  setup(props) {
    const isDots = computed(() => props.view === VIEW.DOT)
    const isLine = computed(() => props.view === VIEW.LINE)

    const mods = computed(() =>
      isLine.value
        ? { size: props.size, view: props.view }
        : { view: props.view },
    )

    return {
      mods,

      isDots,
      isLine,
    }
  },
}

import { computed } from 'vue'

import { TEXT_DELIMITER } from '../../constants'

export const useTextParts = (props) => {
  const parts = computed(() => {
    const [top, bottom] = props.text.split(TEXT_DELIMITER)

    return {
      top,
      middle: props.product?.name,
      bottom,
    }
  })

  return {
    parts,
  }
}

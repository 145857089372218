import { TYPE } from '../../constants'
import { GtmSelectWhomModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class SelectWhomBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmSelectWhomModel]])
    super(models, data)
  }
}

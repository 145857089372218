import { computed, toRefs } from 'vue'

import { propValidator } from '../../../utils'
import { GaProductCardCart } from '../../molecules/cart'

import { useBehavior } from './scripts/composables'
import { cartAdapter as schemaCartAdapter } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-cart-adapter',

  components: {
    GaProductCardCart,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
      validator: (value) => propValidator(value, schemaCartAdapter),
      required: true,
    },
    cancelDefaultAddToCart: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click-add-to-cart', 'add-to-cart', 'open-preview'],

  setup(props, { attrs, emit }) {
    const { cancelDefaultAddToCart, data } = toRefs(props)

    const { onCartClick } = useBehavior(data, cancelDefaultAddToCart, emit)

    const propsToPass = computed(() => {
      const { data, cancelDefaultAddToCart, ...restProps } = attrs
      return restProps
    })

    return { onCartClick, propsToPass }
  },
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 50 50">
    <path
      fill="#fff"
      d="M31 35.5h-3.68V17.338h-1.642c-3.009 0-4.582 1.5-4.582 3.739 0 2.538 1.087 3.715 3.332 5.215l1.851 1.246-5.322 7.962H17l4.79-7.108c-2.754-1.961-4.304-3.877-4.304-7.107 0-4.039 2.823-6.785 8.168-6.785h5.323v21H31Z"
    />
    <defs>
      <clipPath><path fill="#fff" d="M17 14.5h14v21H17z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-yandex',
}
</script>

<template>
  <svg fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-width="2" d="m5 12 5-5 5 5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-streamerce-slider-arrow',
}
</script>

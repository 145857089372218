import dayjs from 'dayjs'

import { withMessage } from '../utils'

export default (maxDate, message) => {
  const validator = (value) => {
    const diff = dayjs(maxDate).diff(value, 'minute')

    return diff > 0
  }

  const params = {
    type: 'maxDate',
    maxDate,
    message: 'app.validation.date',
  }

  return withMessage(validator, params)(message)
}

export const moduleReviewSsrRoutes = [
  {
    moduleType: 'review',
    pageType: 'listing',
    path: '/review/product/:entityId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      // footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/review/main' */
        '~/modules/review/components/pages/main.vue'
      ),
  },
]

export const routes = [
  ...moduleReviewSsrRoutes,
  {
    moduleType: 'review',
    pageType: 'submit',
    path: '/submitreview',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/review/page/form' */
        '~/modules/review/components/pages/form.vue'
      ),
  },
  {
    moduleType: 'review',
    pageType: 'submitSuccess',
    path: '/review/submit/success',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      // footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/review/page/success' */
        '~/modules/review/components/pages/success.vue'
      ),
  },
  {
    moduleType: 'review',
    pageType: 'submitFailure',
    path: '/review/submit/failure',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      // footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/review/page/failure' */
        '~/modules/review/components/pages/failure.vue'
      ),
  },
  // UI-KIT
  {
    moduleType: 'review',
    pageType: 'reviewComponents',
    path: '/review/components',
    meta: {
      authRequired: false,
      analyticDisabled: true,
      addressDisabled: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/review/components' */
        '~/modules/review/components/pages/components.vue'
      ),
  },
]

import { addressesStore } from './addresses'
import { confirmStore } from './confirm'
import { mainStore } from './main'
import { modalStore } from './modal'
import { searchStore } from './search'
import { specifyStore } from './specify'

export const stores = (gaApp) => {
  return {
    addresses: addressesStore(gaApp),
    main: mainStore(gaApp),
    modal: modalStore(gaApp),
    search: searchStore(gaApp),
    specify: specifyStore(gaApp),
    confirm: confirmStore(gaApp),
  }
}

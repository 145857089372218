<template>
  <svg stroke="none" viewBox="0 0 306 120">
    <path
      d="M257.562 7.52v7.94h-3.13V2.733h4.667l2.455 6.823.077.222.078-.222 2.455-6.823h4.578v12.729h-3.505V7.52l-.166-.034-3.141 7.974h-1.106l-3.108-7.974-.165.034h.011Z"
    />
    <path
      d="m279.226 13.127-.022-.066h-4.412l-.023.066-.663 2.334h-3.262l4.08-12.729h4.656l4.048 12.729h-3.738l-.675-2.334h.011Zm-.807-2.433h.11l-.033-.11-1.404-4.966-.089-.287-.088.287-1.427 4.966-.033.11h2.964Z"
    />
    <path
      d="M291.358 15.693c-2.068 0-3.827-.675-4.944-1.814l-.011-.022c-1.139-1.14-1.725-2.754-1.725-4.667v-.144c0-1.946.719-3.56 1.902-4.7 1.195-1.139 2.853-1.802 4.778-1.802 1.504 0 2.93.342 4.014 1.105 1.04.741 1.758 1.87 1.902 3.473h-3.494c-.089-.597-.332-1.05-.73-1.36-.409-.321-.973-.476-1.67-.476-.94 0-1.692.365-2.212 1.029-.52.663-.796 1.614-.796 2.798v.143c0 1.073.177 2.013.685 2.688.509.696 1.261 1.117 2.422 1.117.896 0 1.549-.233 1.991-.62.442-.376.608-.896.664-1.404v-.1h-2.422v-2.41h5.894v1.504c0 1.625-.52 3.04-1.559 4.058-1.051 1.006-2.599 1.615-4.667 1.615l-.022-.011Z"
    />
    <path
      d="M251.402 0a14.473 14.473 0 0 0-2.942 8.759c0 3.284.995 6.027 2.676 8.404h-4.346c-1.305-2.52-2.057-5.23-2.057-8.404 0-3.174.808-6.16 2.234-8.759H251.402Z"
    />
    <path
      d="M299.453 17.164c1.681-2.367 2.676-5.12 2.676-8.405 0-3.285-1.095-6.326-2.942-8.759h4.424c1.426 2.6 2.234 5.729 2.234 8.759 0 3.03-.741 5.883-2.057 8.405h-4.346.011Z"
    />
    <path
      d="M0 34.283v27.249h.033c0 18.59.044 57.329.044 57.948h11.037V61.598H25.48V51.502H11.214v-17.22c0-5.13 4.18-9.31 9.322-9.31 2.433-.045 5.165-.034 8.095 0V13.78a247.116 247.116 0 0 0-8.095 0C9.212 13.769 0 22.97 0 34.283Z"
    />
    <path
      d="M62.03 119.635c-1.582.022-3.252.022-5.01 0-12.685-.288-21.056-9.422-21.056-21.056V13.757h11.28v83.65c0 1.017.055 2.035.254 3.019.708 3.428 2.5 5.308 4.446 6.58 2.93 1.913 10.074 1.581 10.074 1.581v11.048h.011Z"
    />
    <path
      d="M179.629 119.701c-10.019 0-18.18-8.15-18.18-18.18V31.728c0-10.02 8.15-18.18 18.18-18.18 10.031 0 18.181 8.15 18.181 18.18v69.793c0 10.019-8.15 18.18-18.181 18.18Zm0-95.858c-3.925 0-7.121 3.406-7.121 7.896v69.793c0 4.899 3.196 7.94 7.121 7.94 3.926 0 7.122-3.008 7.122-7.94V31.728c0-4.7-3.196-7.896-7.122-7.896v.011Z"
    />
    <path
      d="M244.501 119.447h-11.258V31.806c0-5.364-3.539-7.952-7.1-7.952-3.351 0-7.1 2.135-7.1 8.084v87.509h-11.258V31.806c0-10.12 8.239-18.358 18.358-18.358s18.358 8.239 18.358 18.358v87.641Z"
    />
    <path
      d="M133.027 120c-10.119 0-18.346-8.228-18.346-18.347V31.916c0-10.119 8.227-18.347 18.346-18.347s18.347 8.228 18.347 18.347v13.503h-11.28V31.916c0-4.855-2.721-8.106-7.067-8.106-3.66 0-7.066 2.654-7.066 8.106v69.737c0 4.026 3.03 7.277 7.066 7.277 4.28 0 7.067-3.185 7.067-7.277V87.432h11.28v14.221c0 10.119-8.228 18.347-18.347 18.347Z"
    />
    <path
      d="M86.303 13.448c-10.118 0-18.357 8.239-18.357 18.358v87.641h11.258V71.972h14.188v47.475h11.258V31.806c0-10.12-8.239-18.358-18.358-18.358h.011Zm-7.1 47.896V31.927c0-5.95 3.75-8.084 7.1-8.084 3.562 0 7.1 2.588 7.1 7.951v29.539H79.215l-.011.01Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-articles-logo',
}
</script>

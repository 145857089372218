import { useContext } from '@nuxtjs/composition-api'

export const useBusyBrands = () => {
  const { $gaApp } = useContext()

  const checkIsBusy = (id) => {
    return $gaApp.stores.favorites.brands.busy.has(id)
  }

  return {
    checkIsBusy,
  }
}

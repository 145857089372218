<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke="#000" d="m7 13.5-6-6 6-6" />
    <path stroke="#000" d="M1 7.5h14" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-mini-arrow',
}
</script>

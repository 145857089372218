import { ref } from 'vue'

export const useIntersectionObserver = (emit, rootRef) => {
  const observer = ref(null)

  const connect = () => {
    observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            emit('full-intersect-slider')

            observer.value.unobserve(entry.target)
          }
        })
      },
      {
        threshold: 0.2,
      },
    )

    observer.value.observe(rootRef.value)
  }

  const disconnect = () => {
    observer.value?.disconnect()
  }

  return {
    connect,
    disconnect,
  }
}

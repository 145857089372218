import { ref } from 'vue'

import { GaImageResponsive } from '~/components/image-responsive'
import { GaVideoResponsive } from '~/components/video-responsive'

import { propValidator } from '../../../utils'
import { mediaItem as schemaMediaItem } from '../../../utils/schemas'
import { VIDEO_STRATEGY } from '../media/scripts/consts'

import { useVideo } from './scripts/composable/video'

// @vue/component
export default {
  name: 'ga-product-card-media-item',

  components: {
    GaImageResponsive,
    GaVideoResponsive,
  },

  props: {
    productId: {
      type: String,
      required: true,
    },
    source: {
      type: Object,
      required: true,
      validator: (value) => propValidator(value, schemaMediaItem),
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    videoStrategy: {
      type: String,
      default: VIDEO_STRATEGY.NONE,
    },
  },

  setup(props, { emit }) {
    const videoRef = ref(null)

    const { isVideoRendered, onVideoEnd, onVideoError, onVideoStart } =
      useVideo({ props, emit, videoRef })

    return {
      videoRef,
      isVideoRendered,

      onVideoEnd,
      onVideoError,
      onVideoStart,
    }
  },
}

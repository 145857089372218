export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async headerImage() {
    const { url } = this.endpoints.headerImage()

    try {
      const { data } = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(data)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getStreams(params = {}) {
    const { url } = this.endpoints.streams()

    try {
      const { data } = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(data)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getStreamsStatuses() {
    const { url } = this.endpoints.streamsStatuses()

    try {
      const { data } = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(data)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { CARD_TYPE } from '../../constants/digital'

export const state = () => ({
  mainPage: {},

  card: {
    selectedType: CARD_TYPE.ELECTRONIC,
  },

  menu: {
    menuImageUrl: {},
    electronicLink: '',
    plasticLink: '',
    aboutCardsLink: '',
    corpCardsLink: '',
  },
})

import { TYPE } from '../../constants'
import {
  GaAnalyticsProductClickModel,
  GtmGa4ProductClickModel,
} from '../../models'
import { BaseBuilder } from '../index'

export class ProductClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ProductClickModel],
      [TYPE.GAA, GaAnalyticsProductClickModel],
    ])
    super(models, data)
  }
}

import merge from 'lodash/merge'

export const clearGarbagePrefix = (path) => {
  const result = path.split('/')

  const hasGarbagePrefix = (arr) => {
    const startPiece = arr[1]

    const exclusions = ['qr', 'lp']

    // считаем, что если в начале строки 2 символа после слеша, то это двухбуквенная локаль
    // exclusions - исключения
    // amp - исключение
    // например, /ar/19992877474-preq
    return (
      (startPiece.length === 2 && !exclusions.includes(startPiece)) ||
      startPiece === 'amp'
    )
  }

  if (!hasGarbagePrefix(result)) {
    return result.join('/')
  } else {
    result.splice(1, 1)
    return result.join('/')
  }
}

const clearedPath = (path, locale, defaultLocale) => {
  let modifiedPath = path

  // если строка начинается с локали, временно удаляем локаль
  const hasLocalePrefix = path.startsWith(`/${locale}`)
  if (hasLocalePrefix) {
    modifiedPath = modifiedPath.replace(`/${locale}`, '')
  }

  // если дальше ещё локаль, считаем что она ошибочная и удаляем её
  modifiedPath = clearGarbagePrefix(modifiedPath)

  // если локаль не совпадает с дефолтной, то добавляем её обратно
  if (locale !== defaultLocale) {
    modifiedPath = `/${locale}${modifiedPath}`
  }

  return modifiedPath
}

export const locationHandler = (
  location,
  { locale, defaultLocale, localePath },
) => {
  let result
  if (typeof location === 'string') {
    result = localePath(location)
  } else if ('path' in location) {
    result = {
      ...location,
      path: clearedPath(location.path, locale, defaultLocale),
    }
  } else {
    result = location
  }

  return result
}

export const mergeLocales = (...locales) => {
  const result = merge(
    {},
    ...locales.map((locale) => {
      if (Array.isArray(locale)) {
        return merge({}, ...locale)
      }

      return locale
    }),
  )

  return result
}

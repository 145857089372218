export const SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
}

export const HIGHLIGHT_THEME = {
  DEFAULT: 'default',
  PALE: 'pale',
}

import { getId, trimObjectStrings } from '@ga/utils'

export class DigitalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Форма в строковом формате
   */
  get stringifiedForm() {
    return JSON.stringify(this.form)
  }

  /**
   * Данные формы для заказа
   */
  get form() {
    const sets = this.gaApp.stores.giftCardsB2b.digital.sets.items.map(
      (set) => ({
        coverId: set.cover.id,
        cardsAmount: set.cardsAmount,
        cardsValue: set.cardsValue,
      }),
    )

    return {
      sets,
      contactInfo: trimObjectStrings(
        this.gaApp.stores.giftCardsB2b.digital.contacts,
      ),
      counterpartyInfo: trimObjectStrings(
        this.gaApp.stores.giftCardsB2b.digital.counterpartyInfo,
      ),
      felicitation: this.gaApp.stores.giftCardsB2b.digital.felicitation.form,
      orderAmount: this.gaApp.stores.giftCardsB2b.digital.total,
    }
  }

  /**
   * Генерирует идентификатор заказа опираясь на данные
   * @returns {string}
   */
  generateRequestId() {
    const newId = getId()

    this.gaApp.stores.giftCardsB2b.digital.requestIdCache = {
      ...this.gaApp.stores.giftCardsB2b.digital.requestIdCache,
      [this.stringifiedForm]: newId,
    }

    return newId
  }

  /**
   * Текущий идентификатор заказа
   */
  get requestId() {
    return (
      this.gaApp.stores.giftCardsB2b.digital.requestIdCache[
        this.stringifiedForm
      ] || this.generateRequestId()
    )
  }

  /**
   * Данные для заказа
   */
  get order() {
    return {
      ...this.form,
      requestId: this.requestId,
    }
  }
}

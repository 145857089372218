export class NumberService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  format(value, options = {}, locale = this.gaApp.i18n.locale.iso) {
    const mergedOptions = {
      numberingSystem: 'latn',
      maximumFractionDigits: 2,
      ...options,
    }

    return (
      new Intl.NumberFormat(locale, mergedOptions)
        .format(value)
        /**
         * NumberFormat возвращает строку с неразрывными пробелами
         * неразрывный пробел и обычный пробел различются по ширине
         * поэтому неразрывные пробелы заменяем на обычные пробелы
         */
        .replace(/\s/g, ' ')
    )
  }
}

import { TYPE } from '~/modules/filters/constant'

export class SeoService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getImageAttributes(product) {
    const { productType = '', brand = '', name = '', attributes } = product

    const productColor = attributes?.colors?.name || ''
    const productUnitValue = attributes?.units?.currentUnitValue || ''
    const productUnitName = attributes?.units?.name || ''

    const productUnit = productUnitValue
      ? `${productUnitValue} ${productUnitName}`
      : ''

    const alt = `${brand} ${productType} ${name}`
    const title = `${alt} ${productColor} ${productUnit}`

    return {
      alt,
      title,
      itemprop: 'image',
    }
  }

  /**
   * Получает цены и количество элементов для схемы категории
   */
  getCategorySchemaPrices() {
    const result = {}

    const prices = this.gaApp.stores.filters.main.filters.find(
      (filter) => filter.type === TYPE.RANGE,
    )
    if (prices) {
      result.price = {
        low: prices.minValue.amount,
        high: prices.maxValue.amount,
        currency: prices.minValue.currency || prices.maxValue.currency,
      }
    }

    const itemsCount = this.gaApp.stores.plp.main.getProductsCount

    if (itemsCount) {
      result.count = itemsCount
    }

    return result
  }
}

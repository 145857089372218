import { ALL_STORES_KEY, FILTER_KEY } from '../constants/keys'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setMobileSelectedFilters() {
    const mobileSelectedFilters =
      this.gaApp.stores.promo.filters.mobileSelectedFilters

    this.gaApp.stores.filters.main.setFiltersSelected(mobileSelectedFilters)
  }

  async resetFilters() {
    await this.gaApp.services.promo.location.resetCurrentCityId()
    this.gaApp.services.promo.stores.resetCurrentStoreId()
  }

  async setNewFilters() {
    const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

    const citySelectedFilter = filtersSelected.find(
      (filter) => filter.id === FILTER_KEY.CITY,
    )

    if (citySelectedFilter === undefined) {
      return this.gaApp.services.promo.location.setBaseCity()
    }

    const isCityUpdated =
      citySelectedFilter.value !== this.gaApp.stores.promo.filters.currentCity

    if (isCityUpdated) {
      await this.gaApp.services.promo.location.changeCurrentCityId(
        citySelectedFilter.value,
      )

      const storeKey =
        this.gaApp.stores.promo.filters.currentStores.length === 1
          ? this.gaApp.stores.promo.filters.currentStores[0].value
          : ALL_STORES_KEY

      await this.gaApp.services.promo.stores.changeCurrentShopId(storeKey)

      return
    }

    const storeSelectedFilter = filtersSelected.find(
      (filter) => filter.id === FILTER_KEY.STORE,
    )

    await this.gaApp.services.promo.stores.changeCurrentShopId(
      storeSelectedFilter ? storeSelectedFilter.value : ALL_STORES_KEY,
    )
  }

  async updateSelectedFilters() {
    const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

    if (filtersSelected.length) {
      await this.setNewFilters()
    } else {
      await this.resetFilters()
    }
  }

  async requestFiltersPromotions() {
    this.gaApp.stores.filters.main.setFiltersPending(true)

    await this.updateSelectedFilters()

    const filters = this.gaApp.stores.promo.filters.modalFilters

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
      key: filter.id,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  areEqualPromotionFilters() {
    const isEqualCity =
      this.gaApp.stores.promo.filters.previousCity ===
      this.gaApp.stores.promo.filters.currentCity

    const isEqualShop =
      this.gaApp.stores.promo.filters.previousShop ===
      this.gaApp.stores.promo.filters.currentShop

    return isEqualCity && isEqualShop
  }

  async onApplyFilters() {
    if (this.areEqualPromotionFilters()) {
      return
    }

    this.gaApp.services.promo.location.setPreviousCityId(
      this.gaApp.stores.promo.filters.currentCity,
    )
    this.gaApp.services.promo.stores.setPreviousShop(
      this.gaApp.stores.promo.filters.currentShop,
    )
    await this.gaApp.services.promo.listing.loadNewPage()
  }

  getSelectedCount() {
    let count = 0

    const filtersSelected = this.gaApp.stores.filters.main.filtersSelected
    const defaultId = this.gaApp.services.location.main.getDeliveryAddress()?.id

    const citySelectedFilter = filtersSelected.find(
      (filter) => filter.id === FILTER_KEY.CITY,
    )

    if (
      citySelectedFilter !== undefined &&
      citySelectedFilter.value !== defaultId
    ) {
      count++
    }

    const storeSelectedFilter = filtersSelected.find(
      (filter) => filter.id === FILTER_KEY.STORE,
    )

    if (
      storeSelectedFilter !== undefined &&
      storeSelectedFilter.value !== ALL_STORES_KEY
    ) {
      count++
    }

    return count
  }
}

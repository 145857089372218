export const state = () => {
  return {
    // модалки добавленые в очередь при старте приложения
    modals: [],
    // модалки открытые при запуске очереди
    openedModals: [],
    // пауза запущеной очерди, если открывается ещё одна модалка из подгруппы очереди
    pauseQueue: false,
    // блокировка открытия всех модалок при старте приложения, для заполнения [modals]
    pendingFillModals: false,
  }
}

import truncate from 'lodash/truncate'

import { MAX_MESSAGE_LENGTH } from '~/modules/ticker/analytics/constants'

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp
    this.core = core
    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
    }
    this.viewedTickerIds = []
  }

  isTickerClickable(ticker) {
    return Boolean(ticker.href)
  }

  getNormalizedData(ticker) {
    const formattedScreenType = this.getScreenType()
    const message = this.gaApp.services.ticker.main.getСurrentText(ticker)
    const formattedMessage = truncate(message, { length: MAX_MESSAGE_LENGTH })

    return {
      screenType: formattedScreenType,
      message: formattedMessage,
    }
  }

  getScreenType() {
    const { moduleType, pageType } = this.gaApp.stores.ticker.main.parameters

    return `${moduleType}/${pageType}`
  }

  isTickerViewed(currentTicker) {
    return currentTicker && this.viewedTickerIds.includes(currentTicker.id)
  }

  setTickerAsViewed(currentTicker) {
    this.viewedTickerIds.push(currentTicker.id)
  }

  clearviewedTickerIds() {
    this.viewedTickerIds = []
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

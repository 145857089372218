import {
  ARTICLES_PAGE_CHUNK_SIZE,
  FILTER_TYPE,
  LISTING_PAGE_TYPE,
  LOAD_DIR,
} from '../constants'

import { ListService } from './list.service'

export class ListingService extends ListService {
  constructor(gaApp) {
    super()
    this.gaApp = gaApp
  }

  // Начальная загрузка данных для листинга материалов
  async loadData() {
    this.reset()
    this.gaApp.stores.articles.list.type = LISTING_PAGE_TYPE.ARTICLES

    await this.gaApp.services.articles.filters.loadFilters(
      this.gaApp.route.query,
    )

    await this.fetchInitialData()
  }

  // Начальная загрузка материалов для страницы рубрик
  async loadFilterArticles({ filterType, filterKey }) {
    // Если это клиент - перенаправляем на листинг и проставляем фильтры в url
    if (!this.gaApp.isServer) {
      return this.redirectToListingPage(filterType, filterKey)
    }

    // Потенциально помимо рубрик далее появятся теги
    switch (filterType) {
      case FILTER_TYPE.rubric:
        await this.fetchRubricArticles(filterKey)
        break
      default: {
        const filters = [{ key: filterKey, type: filterType }]
        await this.fetchInitialData(filters)
      }
    }
  }

  // Загружаем дынные для превью карточки материала/статьи в листинге.
  // Необходимо редакторам, чтобы посмотреть, как карточка статьи будет выглядеть перед публикацией
  async loadPreviewListing(id) {
    this.gaApp.stores.articles.list.isLoading = true

    try {
      const response =
        await this.gaApp.services.articles.api.fetchArticlePreviewListing(id)

      const data = {
        ...response,
        items: response.articles,
      }

      this.setInitialListingDataToStore(data)

      this.gaApp.stores.articles.list.totalArticlesAmount =
        response.articles.length
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.list.isLoading = false
    }
  }

  // Пытаемся загрузить страницу со статьями если это возможно
  async triggerFetchPage(pageNumber, dir) {
    const canFetchPage =
      !this.isPageFetching(pageNumber) && // страница не загружается сейчас
      this.isPageExists(pageNumber) && // страница теоретически существует
      !this.isPageRendered(pageNumber) // страница не была уже загружена

    if (!canFetchPage) {
      return
    }

    await this.fetchPage(pageNumber, dir)
  }

  // Загружает список материалов/статей для листинга по заданному номеру страницы
  // При включенном ФТ - flaconUseNewPaginationList,
  // пагинация будет начинаться с даты последней статьи на предыдущей странице
  async fetchPage(pageNumber, dir) {
    this.setLoadingDirection(dir)
    this.gaApp.stores.articles.list.fetchingPageNumber = pageNumber

    try {
      const useNewPagination =
        this.gaApp.features.get('flaconUseNewPaginationList') &&
        dir === LOAD_DIR.NEXT

      const lastActualPublicationDate = useNewPagination
        ? this.gaApp.stores.articles.list.lastActualPublicationDate
        : undefined

      const { articles } = await this.loadArticlesByPage({
        pageNumber,
        lastActualPublicationDate,
      })

      const page = {
        number: pageNumber,
        items: articles,
      }

      this.addPage(page, dir)
    } catch (error) {
      console.error(error)
    } finally {
      this.setLoadingDirection(null)
      this.gaApp.stores.articles.list.fetchingPageNumber = null
    }
  }

  // Загружаем следующую страницу с материалами
  loadNextPage() {
    this.triggerFetchPage(
      this.gaApp.stores.articles.list.lastLoadedPageNumber,
      LOAD_DIR.NEXT,
    )
  }

  // Загружаем предыдущую страницу с материалами
  loadPrevPage() {
    if (this.gaApp.stores.articles.list.firstLoadedPageNumber === 0) {
      return
    }

    this.resetFirstLoadedImagesCount()

    this.triggerFetchPage(
      this.gaApp.stores.articles.list.firstLoadedPageNumber - 1,
      LOAD_DIR.PREV,
    )
  }

  // Загружаем следующую страницу с материалами + проставляем номер страницы в url
  onPageIntersect(pageNumber) {
    // страница появилась в области видимости, проставляем ее как текущую страницу
    // это вызовет обновление данных url
    this.setCurrentPageNumber(pageNumber)
    this.triggerFetchPage(pageNumber + 1, LOAD_DIR.NEXT)
  }

  // Загружаем первую страницу материалов
  async fetchInitialData(filters) {
    this.resetFirstLoadedImagesCount()
    this.gaApp.stores.articles.list.isLoading = true

    try {
      const { p: pageNumberFromQuery } = this.gaApp.route.query

      // если в url есть параметр p больший 1 (2,3,4...) берем его
      // для передачи в апи отнимаем 1, так как нумерация с 0
      // если параметра p в строке нет, запрашиваем первую (0) страницу
      let pageNumber = pageNumberFromQuery > 1 ? pageNumberFromQuery - 1 : 0

      // пробуем получить данные по текущей странице
      let response = await this.loadArticlesByPage({ pageNumber, filters })

      // Если для текущей (не первой) страницы нет данных - грузим первую страницу
      const shouldRetryResponse = pageNumber !== 0 && !response.articles.length

      if (shouldRetryResponse) {
        pageNumber = 0
        response = await this.loadArticlesByPage({ pageNumber, filters })
      }

      this.gaApp.services.articles.list.setCurrentPageNumber(pageNumber)

      const data = {
        ...response,
        items: response.articles,
      }

      this.clearPages()
      this.setInitialListingDataToStore(data)
    } catch (error) {
      console.log(error)
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.list.isLoading = false
    }
  }

  // Загружаем страницу с материалами
  loadArticlesByPage({ pageNumber, filters, lastActualPublicationDate }) {
    const params = this.prepareParams({
      pageNumber,
      filters,
      lastActualPublicationDate,
    })

    return this.gaApp.services.articles.api.fetchArticles(params)
  }

  // Загружаем материалы конкретной рубрики
  async fetchRubricArticles(rubric) {
    this.gaApp.stores.articles.list.isLoading = true

    try {
      const { p: pageNumberFromQuery } = this.gaApp.route.query

      const pageNumber = pageNumberFromQuery > 1 ? pageNumberFromQuery - 1 : 0

      this.gaApp.stores.articles.list.currentPageNumber = pageNumber

      const params = {
        pagination: {
          pageSize: ARTICLES_PAGE_CHUNK_SIZE,
          pageNumber,
        },
        rubric,
      }

      const response =
        await this.gaApp.services.articles.api.fetchRubricArticles(params)

      const data = {
        ...response,
        items: response.articles,
      }

      this.gaApp.services.articles.list.setInitialListingDataToStore(data)
    } catch {
      this.gaApp.services.articles.list.clearPages()
    } finally {
      this.gaApp.stores.articles.list.isLoading = false
    }
  }
}

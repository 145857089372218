import { unitsValues } from './units-values'

export const units = {
  type: 'object',
  properties: {
    name: { type: 'string', required: true },
    values: { ...unitsValues, required: true },
    count: { type: 'number', required: true },
    currentUnitValue: { type: 'string', required: true },
  },
  additionalProperties: false,
}

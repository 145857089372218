import {
  KEYS,
  NEXT_STEP_DELAY,
  REASON_OPEN_AUTH_MODAL,
} from '../../constants/form'

export class UserService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  openLogoutModal() {
    this.gaApp.stores.review.form.openedLogoutModal = true
  }

  closeLogoutModal() {
    this.gaApp.stores.review.form.openedLogoutModal = false
  }

  setSubscriptions() {
    this.gaApp.eventBus.subscribe('module/auth/login', () => {
      // переходим на следующий шаг формы если пользователь авторизовался и ссылка из урла совпадает с текущим пользователем
      const timerId = setTimeout(() => {
        this.gaApp.services.review.form.nextStep()
        clearTimeout(timerId)
      }, NEXT_STEP_DELAY)
    })
  }

  isValidUser() {
    // в дев режиме не валидируем пользователя
    if (this.gaApp.config.get('devConfig')) {
      return true
    }

    const userId = (this.gaApp.stores.user.main.data?.id || '').toLowerCase()
    const customerIdFromUrl = (
      this.gaApp.stores.review.products?.[KEYS.CUSTOMER_ID] || ''
    ).toLowerCase()

    // возравщаем true, если пользователь авторизован и ссылка из урла совпадает с текущим пользователем
    if (userId === customerIdFromUrl) {
      return true
    }

    if (userId === '') {
      // окрываем модалку авторизации, если пользак не авторизован
      this.openAuthModal()
    } else {
      // открываем модалку логаута, если ссылка из урла не совпадает с текущим пользователем
      this.openLogoutModal()
    }

    return false
  }

  openAuthModal() {
    this.gaApp.services.auth.main.openModal({
      reason: REASON_OPEN_AUTH_MODAL,
    })
  }

  // логаут пользователя, если customerId из url не совпадает с текущим пользователем
  async onLogout() {
    await this.gaApp.services.auth.main.logoutWithClearData()

    this.closeLogoutModal()
    this.gaApp.ctx.$doubleRAF(() => {
      this.openAuthModal()
    })
  }
}

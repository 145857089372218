export const ATTRIBUTE = 'data-keep-scroll'

const SELECTOR = `[${ATTRIBUTE}]`

const keepScroll = {
  bind(el) {
    el.addEventListener(
      'scroll',
      (event) => {
        const scroll = `${event.target.scrollLeft}-${event.target.scrollTop}`

        event.target.setAttribute(ATTRIBUTE, scroll)
      },
      { passive: true },
    )
  },

  inserted(el) {
    ;[el.hasAttribute(ATTRIBUTE) && el, ...el.querySelectorAll(SELECTOR)]
      .filter(Boolean)
      .forEach((target) => {
        const [left, top] = target.getAttribute(ATTRIBUTE).split('-')

        target.scrollLeft = left
        target.scrollTop = top
      })
  },
}

export { keepScroll }

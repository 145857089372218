export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchNavigation(parameters = {}) {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('navigation')

      let { data } =
        cacheData ||
        (await this.gaApp.repositories.header.main.fetchNavigation(parameters))

      // Если используем стримы флакона - вырезать пункт меню стримы
      if (this.gaApp.features.get('flaconUseStreamers')) {
        data = (data || []).filter((navItem) => navItem.link !== '/streamerce')
      }

      this.gaApp.stores.header.nav.nav = data || []
    } catch (error) {
      throw new Error(`Ошибка получения навигации`)
    }
  }
}

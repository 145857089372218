import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4SuccessOrderModel extends GtmBaseModel {
  constructor({ orderId, type }) {
    super({})

    this.action_type = 'order_success'
    this.order_id = orderId
    this.type = type
  }
}

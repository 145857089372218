import { computed } from 'vue'

import { COUNT_MOD } from './const'

export const useMods = ({
  size,
  skeleton,
  itemsCount,
  itemsInternal,
  showStartIndent,
}) => {
  const count = computed(() => {
    const { length } = itemsInternal.value

    const countInternal = Math.min(length, itemsCount.value)

    return COUNT_MOD[countInternal] || COUNT_MOD.MANY
  })

  const mods = computed(() => {
    return {
      size: size.value,
      count: count.value,
      skeleton: skeleton.value,
      'start-indent': showStartIndent.value,
    }
  })

  const style = computed(() => `--items-count: ${itemsCount.value}`)

  return { mods, style }
}

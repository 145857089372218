export const actions = (gaApp) => ({
  setRequestPending(value) {
    this.labelsMapRequest.pending = value
  },

  setRequestRejected(value) {
    this.labelsMapRequest.rejected = value
  },

  setLabelsMap(value) {
    this.labelsMap = value
  },
})

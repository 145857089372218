<template>
  <svg fill="none" stroke="none" viewBox="0 0 62 15">
    <rect
      width="62"
      height="15"
      fill="#fff"
      fill-opacity=".2"
      data-id="background"
      rx="7.5"
    />
    <path
      fill="#fff"
      d="m10 3.5 1.273 2.727L14 7.5l-2.727 1.273L10 11.5 8.727 8.773 6 7.5l2.727-1.273L10 3.5Z"
    />
    <path
      fill="#fff"
      d="M19.906 8.3c.945 0 1.449-.45 1.449-1.467V5.735c0-1.071-.477-1.431-1.45-1.431H17.99V11h1.197V8.3h.72Zm-.72-3.042h.423c.414 0 .558.162.558.702v.63c0 .558-.144.711-.558.711h-.423V5.258Z"
    />
    <path
      fill="#fff"
      d="M23.09 4.304V11h2.997V9.929H24.26V8.075h1.476V7.004H24.26V5.348h1.773V4.304H23.09Z"
    />
    <path
      fill="#fff"
      d="M30.253 11h1.224l-1.404-3.573 1.278-3.123h-1.17l-1.206 2.979V4.304h-1.188V11h1.188V7.796L30.253 11Z"
    />
    <path
      fill="#fff"
      d="M35.181 11h1.197V4.304h-3.195c0 4.14-.009 4.437-.063 4.977-.054.477-.18.621-.405.621h-.072V11h.387c.774 0 1.071-.378 1.17-1.278.081-.738.09-2.241.09-4.383h.891V11Z"
    />
    <path
      fill="#fff"
      d="M40.532 11h1.152l-1.197-6.696h-1.495L37.868 11h1.052l.19-1.233h1.224L40.53 11Zm-1.026-3.735c.108-.72.18-1.224.215-1.602.028.378.09.864.208 1.602l.242 1.503h-.9l.234-1.503Z"
    />
    <path
      fill="#fff"
      d="M46.542 7.751c.18-.9.288-1.485.387-2.043-.018.747-.036 1.368-.036 2.322V11h1.044V4.304h-1.575l-.459 2.385c-.153.783-.207 1.233-.288 1.737-.08-.504-.144-.954-.297-1.737l-.459-2.385h-1.602V11h1.017V8.03c0-.954-.009-1.575-.027-2.322.1.576.198 1.134.387 2.106l.63 3.186h.63l.648-3.249Z"
    />
    <path
      fill="#fff"
      d="M52.175 11h1.152L52.13 4.304h-1.494L49.511 11h1.053l.19-1.233h1.223L52.175 11ZM51.15 7.265c.108-.72.18-1.224.216-1.602.027.378.09.864.207 1.602l.243 1.503h-.9l.234-1.503Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-ad-xs',
}
</script>

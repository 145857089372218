export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Если индекс текущего брейкпоинта, больше индекса брейкпоинта от screenKey,
   * значит текущий брейкпоинт, входит в диапазон брейкпоинта от screenKey,
   * значит брейкпоинт от screenKey тот, что мы искали, возвращаем его
   *
   * Если брейкпоинт от screenKey не найден, то мы вернем последний объект из объекта screens по ключу screenKey
   *
   * @param {array} screens ключи breakpoint'ов дисклеймера
   * @returns {object} объект найденный по ключу из screens
   */
  getСurrentResponsiveDisclaimer(screens) {
    const breakpoints = this.gaApp.stores.app.window.breakpoints
    const breakpointCurrent = this.gaApp.stores.app.window.currentBreakpoint
    const breakpointCurrentIndex = breakpoints[breakpointCurrent].index

    // Сначала сортируем ключи screens от большего к меньшему.
    const comparator = (a, b) => breakpoints[b].index - breakpoints[a].index
    const keys = Object.keys(screens).sort(comparator)

    // Затем выполняем поиск экрана
    const findDisclaimerScreenName = (screenKey) => {
      const breakpointByScreenKeyIndex = breakpoints[screenKey].index

      return breakpointCurrentIndex >= breakpointByScreenKeyIndex
    }

    const screen = keys.find(findDisclaimerScreenName) ?? keys[keys.length - 1]

    return screens[screen]
  }

  formatLegalEntity(entity) {
    return Array.isArray(entity) ? entity.join(', ') : entity
  }

  getLegalEntityCount(entity) {
    return Array.isArray(entity) ? entity.length : 1
  }

  // Метод копирования erid-токена в буффер обмена
  async clipboardWriteToken(token) {
    const { app, notification } = this.gaApp.services

    try {
      await app.clipboard.writeText(`erid: ${token}`)

      notification.main.open(
        this.gaApp.i18n.t('advDisclaimer.tokenSuccessCopied'),
      )
    } catch (error) {
      notification.main.open(
        this.gaApp.i18n.t('advDisclaimer.tokenFailedCopied'),
      )
    }
  }
}

import { ApiService } from './api.service'
import { FiltersService } from './filters.service'
import { InfiniteScrollService } from './infinite-scroll.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { PlpService } from './plp.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  plp: new PlpService(gaApp),
  infiniteScroll: new InfiniteScrollService(gaApp),
  filters: new FiltersService(gaApp),
})

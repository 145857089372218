export const moduleGiftCardsSsrRoutes = [
  {
    moduleType: 'giftCards',
    pageType: 'main',
    path: '/cards',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/main' */
      import('~/modules/gift-cards/components/pages/main.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'digital',
    path: '/cards/digital',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/digital' */
      import('~/modules/gift-cards/components/pages/digital.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'about',
    path: '/cards/about',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/about' */
      import('~/modules/gift-cards/components/pages/about.vue'),
  },

  {
    moduleType: 'giftCards',
    pageType: 'receive',
    path: '/cards/receive/:id',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/receive' */
      import('~/modules/gift-cards/components/pages/receive/_id.vue'),
  },
]

export const routes = [
  ...moduleGiftCardsSsrRoutes,

  {
    moduleType: 'giftCards',
    pageType: 'payment/error',
    path: '/cards/payment/error',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/payment-error' */
      import('~/modules/gift-cards/components/pages/payment/error.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'payment/success',
    path: '/cards/payment/success',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/payment-success' */
      import('~/modules/gift-cards/components/pages/payment/success.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'payment/mastercard',
    path: '/cards/payment/mastercard',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/payment-mastercard' */
      import('~/modules/gift-cards/components/pages/payment/mastercard.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'balance',
    path: '/giftcardlp',
    meta: {
      authRequired: false,
      analyticDisabled: true,
      addressDisabled: true,

      headerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/balance' */
      import('~/modules/gift-cards/components/pages/balance/_id.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'reactionReceive',
    path: '/cards/reaction/receive/:reactionId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/reaction-receive' */
      import('~/modules/gift-cards/components/pages/reaction-receive/_id.vue'),
  },
  {
    moduleType: 'giftCards',
    pageType: 'receiveReturn',
    path: '/cards/receive-return/:id',
    meta: {
      authRequired: false,
      analyticDisabled: false,
      addressDisabled: true,

      headerVisible: false,
      footerVisible: true,
    },
    component: () =>
      /* webpackChunkName: 'modules/gift-cards/page/receive-return' */
      import('~/modules/gift-cards/components/pages/receive-return/_id.vue'),
  },
]

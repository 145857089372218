import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import {
  HEADER_BREAKPOINT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_COLLAPSED,
} from '../../constants'

export const useHeaderProps = () => {
  const { $gaApp } = useContext()

  const mediaType = computed(() => {
    switch (true) {
      case $gaApp.mq(`${HEADER_BREAKPOINT.DESKTOP}+`):
        return HEADER_BREAKPOINT.DESKTOP

      case $gaApp.mq(`${HEADER_BREAKPOINT.TABLET}-`):
        return HEADER_BREAKPOINT.MOBILE

      default:
        return HEADER_BREAKPOINT.TABLET
    }
  })

  const minimized = computed(() => $gaApp.stores.header.main.headerMinimized)
  const extended = computed(() => $gaApp.stores.header.main.headerExtended)

  const height = computed(() =>
    extended.value
      ? HEADER_HEIGHT[unref(mediaType)]
      : HEADER_HEIGHT_COLLAPSED[unref(mediaType)],
  )

  return {
    height,
    minimized,
    extended,
  }
}

import { TYPE } from '../../constants'
import { GtmGa4OrderInteraction } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class OrderInteractionBuilder extends BaseBuilder {
  constructor({ actionType, screenType }) {
    const models = new Map([[TYPE.GTM_4, GtmGa4OrderInteraction]])
    super(models, { actionType, screenType })
  }
}

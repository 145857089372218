export class MindboxShareProductModel {
  constructor(product, showCount = false) {
    const { itemId, price, quantity } = product

    this.product = {
      ids: {
        website: itemId,
      },
    }

    this.pricePerItem = price

    if (showCount) {
      this.count = quantity
    }
  }
}

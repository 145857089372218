/**
 *
Сервис предоставляет методы для работы со статистикой отзывов:

`filledStore(fetchedData, productId = null)` - метод, который заполняет store `statistic` полученными данными `fetchedData` и идентификатором продукта `productId`.

`filledAvailableForReviewStatus(fetchedData = {})` - метод, который обновляет статус доступности отзыва в store `statistic`.

`getPreviewStatistic()` - метод, который возвращает предварительную статистику отзывов.

 */

export class StatisticService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  filledStore(fetchedData, productId = null) {
    if (!fetchedData) {
      return
    }

    this.gaApp.stores.review.statistic.updateAt = Date.now()
    this.gaApp.stores.review.statistic.productId = productId
    this.gaApp.stores.review.statistic.data = fetchedData
  }

  // обогащаем стору со статистикой, некэшируемым флажком доступности для оценки
  filledAvailableForReviewStatus(fetchedData = {}) {
    this.gaApp.stores.review.statistic.data = {
      ...(this.gaApp.stores.review.statistic.data || {}),
      ...fetchedData,
    }
  }

  getPreviewStatistic() {
    if (!this.gaApp.features.get('isReviewAvailable')) {
      return null
    }

    const { allReviewsCount, recommended, rating } =
      this.gaApp.stores.review?.statistic?.data

    switch (allReviewsCount) {
      case 0:
        return {}
      case undefined:
        return null
      default:
        return {
          rating,
          recommended,
          allReviewsCount,
        }
    }
  }
}

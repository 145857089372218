export const SIZE = {
  L: 'l',
  M: 'm',
}

export const DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
}

export const ICON_COMPONENT = {
  [SIZE.L]: 'GaIconCommon30ArrowBack',
  [SIZE.M]: 'GaIconCommon15ArrowBack',
}

import { useImage } from './scripts/use'

// @vue/component
export default {
  name: 'ga-image',

  inheritAttrs: false,

  props: {
    className: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    return useImage(props, context)
  },
}

export const state = () => {
  return {
    labelsMapRequest: {
      pending: false,
      rejected: false,
    },

    labelsMap: null,
  }
}

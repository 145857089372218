import {
  AlphabetNavigationBuilder,
  BrandFavoriteBuilder,
  BrandItemClickBuilder,
  SearchFocusBuilder,
  SearchQueryEnteringBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class BrandsAnalytics extends BaseController {
  /**
   * Клик на ссылку бренда
   */
  onBrandItemClick(data) {
    const { url, labelOriginal, isFavorite, searchQuery } = data

    this.send(BrandItemClickBuilder, {
      url,
      label: labelOriginal,
      searchPhrase: searchQuery,
      isFavorite: Number(isFavorite), // конвертируем Boolean в 1/0
      itemListName: this.getItemListName(),
    })
  }

  /**
   * Добавление бренда в избранные
   */
  onAddBrandToFavorite({ labelOriginal }) {
    this.send(BrandFavoriteBuilder, {
      actionLabel: labelOriginal,
    })
  }

  /**
   * Клик/тап/скролл по алфавитной навигации
   */
  onAlphabetNavigation({ label }) {
    this.send(AlphabetNavigationBuilder, {
      actionLabel: label,
    })
  }

  /**
   * Клик по полю поиска
   */
  onSearchFocus() {
    this.send(SearchFocusBuilder)
  }

  /**
   * Ввод в поле поиска
   */
  onSearchQueryEntering() {
    this.send(SearchQueryEnteringBuilder)
  }
}

import isFunction from 'lodash/isFunction'

import { TYPE } from '../../constants'
import { hasData } from '../../helpers/is-empty-data'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
    }

    this.getItemListName = () => {
      const fn = this.gaApp.stores.filters.main.getGetItemListNameFn

      return isFunction(fn) ? fn() : fn
    }

    this.getAnalyticData = () => {
      const fn = this.gaApp.stores.filters.main.getGetAnalyticDataFn

      return isFunction(fn) ? fn() : fn
    }

    this.getBubbleData = ({ item, index }) => {
      return {
        url: item?.link,
        bubbleName: item.title,
        bubblePosition: index + 1,
      }
    }

    this.getSelectedFiltersAnalytics = () => {
      const normalize = this.gaApp.services.filters.main.getCortegeAnalytics
      const analytics = this.gaApp.stores.filters.main.filtersAnalytics || []

      return analytics.flatMap((filterAnalytics) => {
        if (hasData(filterAnalytics.data)) {
          return [normalize.call(this, filterAnalytics)]
        }

        return []
      })
    }

    this.getFilterAnalyticByKey = (key) => {
      const analytics = this.gaApp.stores.filters.main.filtersAnalytics || []

      return analytics.find((filter) => filter.key === key) || {}
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

/**
 * Возвращает функцию, создающую необходимые props для <transition> по bem имени
 * https://v2.vuejs.org/v2/guide/transitions.html#Transition-Classes
 * @param {Function} bemMethod - функция bem-cn из контекста
 * @param {Object} options - объект опций для добавления дополнительных классов для компонента transition-group
 * @param {Boolean} options.isGroup - флаг, который разделяет компоненты transition и transition-group
 */
export const transitionPropsBuilder = (bemMethod, options) => {
  return (transitionName) => {
    return {
      name: bemMethod(transitionName),
      'enter-class': bemMethod(transitionName, { enter: true }),
      'enter-active-class': bemMethod(transitionName, { 'enter-active': true }),
      'enter-to-class': bemMethod(transitionName, { 'enter-to': true }),
      'leave-class': bemMethod(transitionName, { leave: true }),
      'leave-active-class': bemMethod(transitionName, { 'leave-active': true }),
      'leave-to-class': bemMethod(transitionName, { 'leave-to': true }),
      'move-class':
        options && options.isGroup
          ? bemMethod(transitionName, { move: true })
          : '',
    }
  }
}

import { TYPE } from '../../constants'
import {
  GtmGa4ProductCartWishModel,
  MindboxProductFavoriteModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ProductFavoriteBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([
      [TYPE.MINDBOX, MindboxProductFavoriteModel],
      [TYPE.GTM_4, GtmGa4ProductCartWishModel],
    ])
    super(models, data, country)
  }
}

<template>
  <svg fill="none" viewBox="0 0 120 50">
    <path
      stroke="inherit"
      stroke-width="inherit"
      d="M84.535 27.206v.875h12.6v30.8h21.49V5.05h-17.283l-.222.545c-1.173 2.882-2.878 5.215-5.367 6.893-2.495 1.683-5.847 2.754-10.389 2.993l-.829.044v11.68Zm-4.96 5.74v-2.1c0-4.919-1.148-11.655-6.495-17.156-5.35-5.505-14.752-9.62-30.92-9.62h-3.15c-16.203 0-25.622 4.114-30.98 9.62-5.356 5.5-6.505 12.237-6.505 17.156v2.1c0 4.85 1.15 11.618 6.501 17.17 5.358 5.56 14.777 9.745 30.984 9.745h3.15c16.173 0 25.574-4.186 30.923-9.745 5.343-5.552 6.492-12.32 6.492-17.17Zm-22.4-1.68v.91c0 2.553-.8 5.467-3.201 7.748-2.403 2.282-6.532 4.047-13.424 4.047-6.887 0-11.035-1.797-13.457-4.107-2.424-2.31-3.238-5.246-3.238-7.758v-.84c0-2.525.75-5.343 3.123-7.54 2.38-2.204 6.526-3.905 13.572-3.905 6.9 0 11.033 1.699 13.434 3.908 2.395 2.202 3.191 5.024 3.191 7.537Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-number-1',
}
</script>

import { nextTick, onMounted, ref, unref, watch } from 'vue'

import { STATE } from '../../const'

import { useBehavior } from './behavior'
import { useTransitionHooks } from './transition-hooks'

/**
 * Возвращает состояние модалки, методы открытия/закрытия, хуки анимациии,
 * которые переключают состояние
 * @param {Ref<Boolean>} opened - props - открыта ли модалка
 * @param {Ref<Boolean>} locked - props - залочена ли модалка
 * @param {Function} emit - эмит функция контекста
 */
export const useModalAutomat = (opened, locked, emit) => {
  const state = ref(STATE.CLOSED)

  const { transitionHooks } = useTransitionHooks(state, emit)

  const { open, close } = useBehavior(state, locked, emit)

  onMounted(() => {
    if (unref(opened)) {
      // Ждем окончания телепортации, чтобы модалка была в DOM
      nextTick(() => {
        state.value = STATE.OPENING
      })
    }
  })

  watch(opened, (value) => {
    if (value) {
      open({ silent: true })
    } else {
      close({ silent: true })
    }
  })

  return {
    state,
    open,
    close,
    transitionHooks,
  }
}

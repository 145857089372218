import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmChosePayMethodModel extends GtmBaseModel {
  constructor({ payMethod }) {
    super({
      event: 'chose_gift_card_payment_method',
    })

    this.parameters = {
      payment_type: payMethod,
    }
  }
}

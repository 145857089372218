import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OutOfStockDialogBeforeCheckoutFavoriteModel extends GtmBaseModel {
  constructor({ items }) {
    super({ event: EVENT.FAVORITE.GA4 })

    this.ecommerce = {
      type: 'all',
      items,
    }
  }
}

import { REQUEST_UPLOAD_TIMEOUT } from '../constants/form'
import { apiMock } from '../mocks/api'

const INCLUDE_MOCK = false

/**
 * Репозиторий предоставляет методы для взаимодействия с API и получения данных.
 */

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  // асинхронный метод, который получает блоки листинга. Если есть заполненные фильтры или пагинация, используется POST-запрос, иначе GET-запрос.
  async getListingBlocks(data) {
    const { url } = this.endpoints.getListingSlots()
    // если есть заполненные фильтры или пагинация, то используем POST
    const needUsePost =
      this.gaApp.stores.review.listing.hasPaginationWithFilters

    try {
      const response = needUsePost
        ? await this.gaApp.api.request().post(url, data)
        : await this.gaApp.api.request().get(url, {
            params: data,
          })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает список продуктов. В режиме разработки (`this.gaApp.config.get('devConfig')`) использует моки данных.
  async getProducts(parameters = {}) {
    const { type, ...params } = { ...parameters }
    const { url } = this.endpoints.products(type)

    try {
      // в дев режиме используем моки
      if (this.gaApp.config.get('devConfig')) {
        return apiMock.getProducts({ url, req: params })
      }

      const response = await this.gaApp.api.request().get(url, {
        params,
      })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который загружает медиафайлы.
  async uploadMedia(data = {}, abortKey) {
    const { url } = this.endpoints.uploadMedia()
    try {
      if (this.gaApp.features.get('reviewModuleIncludeMock')) {
        return apiMock.uploadMedia({ url, req: data })
      }

      const response = await this.gaApp.api
        .request({ abortKey, timeout: REQUEST_UPLOAD_TIMEOUT })
        .post(url, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который отправляет данные формы.
  async submit(data = {}) {
    const { url } = this.endpoints.submitV2()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает данные галереи. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async fetchGallery({ data = {} } = {}) {
    const { url } = this.endpoints.gallery()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.fetchGallery({ url, req: data })
      }
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает фильтры. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async getFilters(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.filters()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.getFilters({ url, req: params })
      }

      const response = await this.gaApp.api.request().get(url, {
        params,
      })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает статистику. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async getStatistic(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.statistic()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.getStatistic({ url, req: params })
      }

      const response = await this.gaApp.api.request().get(url, {
        params,
      })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который проверяет доступность продукта для отзыва.
  async checkAvailableProductForReview(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.checkAvailableForReview()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает основные отзывы. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async fetchMainReviews({ data = {} } = {}) {
    const { url } = this.endpoints.mainReviews()

    try {
      if (INCLUDE_MOCK) {
        return apiMock.getMainReviews({ url, req: data })
      }
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает предварительные отзывы. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async getPreviewReviews(parameters) {
    const { url } = this.endpoints.previewReviews()
    const params = { ...parameters }

    try {
      if (INCLUDE_MOCK) {
        return apiMock.getPreviewReviews({ url, req: params })
      }
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // асинхронный метод, который получает отзыв по его идентификатору. Если включен флаг `INCLUDE_MOCK`, использует моки данных.
  async getReview(reviewId) {
    const { url } = this.endpoints.review(reviewId)

    try {
      if (INCLUDE_MOCK) {
        return apiMock.getReview({ url })
      }

      const response = await this.gaApp.api.request().get(url, {})
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { ApiService } from './api.service'
import { DialogService } from './dialog.service'
import { GuestService } from './guest.service'
import { MainService } from './main.service'
import { ModalService } from './modal.service'
import { NotificationService } from './notification.service'
import { PageService } from './page.service'
import { PriceService } from './price.service'
import { ProductService } from './product.service'
import { ShareService } from './share.service'
import { UserService } from './user.service'
import { WarningService } from './warning.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  notification: new NotificationService(gaApp),
  page: new PageService(gaApp),
  modal: new ModalService(gaApp),
  dialog: new DialogService(gaApp),
  price: new PriceService(gaApp),
  product: new ProductService(gaApp),
  user: new UserService(gaApp),
  share: new ShareService(gaApp),
  warning: new WarningService(gaApp),
  guest: new GuestService(gaApp),
})

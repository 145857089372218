<template>
  <svg fill="none" viewBox="0 0 15 15">
    <circle cx="6.5" cy="6.5" r="5.875" stroke-width="1.25" />
    <path stroke-width="1.25" d="m14 14-3-3" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-map-search',
}
</script>

import { ROUTE } from '../../constants/listing'

export const getters = (gaApp) => ({
  reviews() {
    const reviews = this.data?.reviews ?? []

    return gaApp.mq('desktop-small-') ? reviews : reviews.slice(0, 3)
  },

  statistic() {
    return gaApp.stores.review.statistic.data
  },

  hasSingleReview() {
    return this.reviews.length === 1
  },

  allReviewsCount() {
    return this.statistic?.allReviewsCount ?? 0
  },

  starStatistic() {
    return this.statistic?.starStatistic ?? []
  },

  isAvailableForReview() {
    return this.statistic?.isAvailableForReview ?? false
  },

  rating() {
    return this.statistic?.rating ?? 0
  },

  recommended() {
    return this.statistic?.recommended ?? 0
  },

  hasReviews() {
    return this.reviews.length > 0
  },

  hasButtonReviewForm() {
    return (
      this.isAvailableForReview && gaApp.features.get('reviewShowButtonToForm')
    )
  },

  hasRenderedPreview() {
    return this.hasReviews || this.hasButtonReviewForm
  },

  scores() {
    return {
      count: this.allReviewsCount,
      recommended: `${this.recommended}%`,
      rating: gaApp.mq('desktop-small-') ? this.rating : 0,
      href: this.urlToListing,
    }
  },

  urlToListing() {
    if (!this.productId) {
      return ''
    }
    return `${ROUTE}/${this.productId}`
  },
})

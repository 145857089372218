import { computed, toRefs } from 'vue'

import { useBem } from '@ga/bem'

import { EXTENSION } from '../const'

export const useImage = (props, { emit, attrs }) => {
  const b = useBem()

  const { className, src, sources, lazy } = toRefs(props)

  const buildMedia = (media) => {
    if (!media) {
      return null
    }

    const from = media.from && `(min-width: ${media.from}px)`
    const till = media.till && `(max-width: ${media.till - 1}px)`

    return [from, till].filter(Boolean).join(' and ')
  }

  const buildSrcset = (srcset) => {
    return srcset.length > 1
      ? srcset.map((source, index) => `${source} ${index + 1}x`).join(', ')
      : srcset[0]
  }

  const buildType = (ext = 'jpeg') => {
    const type = getExtType(ext)
    return `image/${type}`.toLowerCase()
  }

  const rootClass = computed(() => [b(), className.value])

  const sourcesInternal = computed(() =>
    sources.value.map(({ media, srcset, ext }) => ({
      media: buildMedia(media),
      srcset: buildSrcset(srcset),
      type: buildType(ext),
    })),
  )

  const hasSourcesInternal = computed(() => sourcesInternal.value.length > 0)

  const imgAttrs = computed(() => ({
    ...attrs,
    src: src.value,
    loading: lazy.value && 'lazy',
  }))

  const getExtType = (ext) => {
    /**
     * Для .jpg изображений должен быть mime-type = "image/jpeg", а не "image/jpg"
     *
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
     * https://stackoverflow.com/questions/33692835/is-the-mime-type-image-jpg-the-same-as-image-jpeg
     */
    switch (ext) {
      case EXTENSION.JPG:
        return EXTENSION.JPEG

      default:
        return ext
    }
  }

  const onLoad = ($event) => {
    emit('load', $event)
  }

  const onError = ($event) => {
    emit('error', $event)
  }

  return {
    b,

    imgAttrs,
    hasSourcesInternal,
    sourcesInternal,
    rootClass,

    onLoad,
    onError,
  }
}

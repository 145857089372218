export const TYPE = {
  BASE: 'base',
  PRODUCT: 'product',
}

export const ENTITY = {
  FAV: 'favorite',
  CART: 'cart',
}

export const OFFSET = {
  [TYPE.BASE]: {},
  [TYPE.PRODUCT]: {},
}

export const SIZE = {
  S: 's',
  M: 'm',
  XS: 'xs',
}

export const MOBILE_APP_BANNER_COOKIE_NAME = 'hide-app-notification'
export const MOBILE_APP_BANNER_COOKIE_MAX_AGE = 24 * 60 * 60

import { SCREEN_TYPES } from '../analytics/constants'

const SET_INITIAL_GEO_ABORT_KEY = 'specify-initial-geo'

export class SpecifyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openEditModal({ screenType, openingReason } = {}) {
    this.gaApp.stores.location.specify.editModalOpened = true
    this.gaApp.stores.location.specify.screenType = screenType
    this.gaApp.stores.location.specify.openingReason = openingReason
    this.gaApp.analytics.modules.location.onSpecifyEditModalOpen(screenType)

    this.gaApp.services.location.specify.resetSearchQueryTemp()
    this.gaApp.stores.location.specify.locationTemp = null
  }

  updateSearchQueryTemp(value) {
    this.gaApp.stores.location.specify.searchQueryTemp = value
  }

  resetSearchQueryTemp() {
    this.gaApp.stores.location.specify.searchQueryTemp = null
  }

  closeEditModal() {
    this.gaApp.stores.location.specify.editModalOpened = false
    this.gaApp.analytics.modules.location.onSpecifyEditModalClose(
      this.gaApp.stores.location.specify.screenType,
    )
  }

  toggleEditModal(value) {
    if (this.gaApp.stores.location.specify.editModalOpened !== value) {
      value ? this.openEditModal() : this.closeEditModal()
    }
  }

  openSelectModal(screenType = SCREEN_TYPES.HOME) {
    this.gaApp.stores.location.specify.selectModalOpened = true
    this.gaApp.stores.location.specify.screenType = screenType
    this.gaApp.stores.location.specify.addressIdTemp = null
    this.gaApp.analytics.modules.location.onSpecifySelectModalOpen(screenType)
  }

  closeSelectModal() {
    this.gaApp.stores.location.specify.selectModalOpened = false
    this.gaApp.analytics.modules.location.onSpecifySelectModalClose(
      this.gaApp.stores.location.specify.screenType,
    )
  }

  toggleSelectModal(value) {
    if (this.gaApp.stores.location.specify.selectModalOpened !== value) {
      value ? this.openSelectModal() : this.closeSelectModal()
    }
  }

  async addNewAddress() {
    this.gaApp.analytics.modules.location.onSpecifySelectModalClickAddAddress(
      this.gaApp.stores.location.specify.screenType,
    )

    this.closeSelectModal()
    this.openEditModal({
      screenType: this.gaApp.stores.location.specify.screenType,
    })

    this.gaApp.stores.location.specify.initialGeoPending = true
    await this.setInitialGeo()
    this.gaApp.stores.location.specify.initialGeoPending = false
  }

  async setInitialGeo() {
    this.gaApp.stores.location.specify.initialGeoCancel = false

    const geolocation = await this.loadInitialGeo()

    if (this.gaApp.stores.location.specify.initialGeoCancel) {
      return
    }

    if (!geolocation) {
      return
    }

    const { countryId } = geolocation

    if (countryId !== this.gaApp.stores.app.main.locale.country) {
      return
    }

    this.gaApp.stores.location.specify.locationTemp = geolocation
  }

  cancelSetInitialGeo() {
    this.gaApp.api.requestAbort.abortRequest(SET_INITIAL_GEO_ABORT_KEY)
    this.gaApp.stores.location.specify.initialGeoPending = false
    this.gaApp.stores.location.specify.initialGeoCancel = true
  }

  async loadInitialGeo() {
    try {
      const coords =
        await this.gaApp.services.location.geolocation.browserDetection()

      const { longitude, latitude } = coords

      if (this.gaApp.stores.location.specify.initialGeoCancel) {
        return
      }

      if (longitude && latitude) {
        const geoLocation =
          await this.gaApp.services.location.api.getGeolocation(
            {
              longitude,
              latitude,
              abortKey: SET_INITIAL_GEO_ABORT_KEY,
            },
            {
              hasAlertError: false,
            },
          )

        if (this.gaApp.stores.location.specify.initialGeoCancel) {
          return
        }

        if (geoLocation) {
          return geoLocation
        }
      }
    } catch (error) {
      console.log(error)
    }

    return null
  }

  getActiveAddressId() {
    const selected = this.gaApp.stores.location.main.location
    const addresses = this.gaApp.stores.user.main.addressesAsObject

    if (
      selected.isSelectedAddress &&
      addresses[selected.addressId + (selected.saveId || '')]
    ) {
      return selected.addressId + (selected.saveId || '')
    } else {
      return this.gaApp.stores.user.main.defaultAddressId
    }
  }

  getAddressById(id) {
    const selected = this.gaApp.stores.location.main.location
    const addresses = this.gaApp.stores.user.main.addressesAsObject

    if (selected.id === id) {
      return selected
    } else {
      return addresses[id] || null
    }
  }

  getUserAddresses() {
    return (
      this.gaApp.stores.user.main.addresses?.map((item) => {
        const { area, localityMain, localityHouse } =
          this.gaApp.services.location.formatter.formatLocationAddressList(item)

        return {
          name: item.addressId + (item.saveId || ''),
          value: item.addressId + (item.saveId || ''),
          id: item.addressId + (item.saveId || ''),

          area,
          localityMain,
          localityHouse,
        }
      }) || []
    )
  }

  async browserDetectionGeolocation() {
    try {
      const { latitude, longitude } =
        await this.gaApp.services.location.geolocation.browserDetection()

      if (latitude && longitude) {
        const data = await this.gaApp.services.location.api.getGeolocation({
          latitude,
          longitude,
        })

        if (data) {
          this.gaApp.stores.location.specify.locationTemp = data
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}

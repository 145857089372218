import { ALL_STORES_ID } from '../../constants'

export const getters = () => ({
  formattedStores() {
    const result = {
      [ALL_STORES_ID]: [],
    }
    for (const cityId in this.cityStores) {
      result[cityId] = []
      // формируем списки магазинов по id и для "всех магазинов"
      this.cityStores[cityId].stores.forEach((store) => {
        // обогощаем данные магазина полем city
        const data = {
          ...store,
          city: this.cityStores[cityId].city,
        }
        result[cityId].push(data)
        result[ALL_STORES_ID].push(data)
      })
    }

    return result
  },
})

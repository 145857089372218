import { VIDEO_FORMAT } from '~/modules/home/constants/video'

import { VIEW } from '../components/organisms/base-stories-circles-slider'
import {
  STORY_ITEM_DEFAULT_DURATION,
  STORY_ITEM_TYPE,
  STORY_TYPES,
  VIEWED_STORIES_LOCAL_STORE_KEY,
} from '../constants'

export class StoriesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get getActiveStory() {
    const { activeSlideIndex } = this.gaApp.stores.home.stories
    return this.gaApp.stores.home.stories.sortedItems[activeSlideIndex]
  }

  get getActiveStoryIndex() {
    return this.getActiveStory.index
  }

  get viewedStoriesLength() {
    return Object.keys(this.gaApp.stores.home.stories.viewed).length
  }

  get isActiveStoryItemMediaLoaded() {
    const { activeIndex } = this.getActiveStory
    const { type, imageLoaded, videoLoaded } =
      this.getActiveStory.items[activeIndex]

    return type === STORY_ITEM_TYPE.VIDEO ? videoLoaded : imageLoaded
  }

  getStoryIndexById(id) {
    return this.gaApp.stores.home.stories.itemsIdMap[id]
  }

  getStoryItem(storyId, itemIndex) {
    return this.gaApp.stores.home.stories.items[this.getStoryIndexById(storyId)]
      .items[itemIndex]
  }

  openModal() {
    this.gaApp.stores.home.stories.modalOpened = true
    this.showStoryLoader()
  }

  closeModal() {
    this.startChanging()
    this.stopStory()
    this.clearProducts()

    this.gaApp.stores.home.stories.modalOpened = false

    this.syncViewed()
  }

  mute() {
    this.gaApp.stores.home.stories.muted = true
  }

  unmute() {
    this.gaApp.stores.home.stories.muted = false
  }

  getProducts() {
    if (this.gaApp.stores.home.stories.hasCatalogData) {
      this.gaApp.services.home.api.fetchStoryItem(
        this.gaApp.stores.home.stories.activeStoryItem.id,
      )
    } else {
      this.clearProducts()
    }
  }

  clearProducts() {
    this.gaApp.stores.home.stories.products.items = []
  }

  openProducts() {
    this.gaApp.stores.home.stories.products.opened = true
    this.stopStory()

    if (!this.gaApp.stores.home.stories.products.items.length) {
      this.getProducts()
    }
  }

  closeProducts() {
    this.gaApp.stores.home.stories.products.opened = false
  }

  setProductFavorite(itemId, isAdded) {
    const itemIndex = this.gaApp.stores.home.stories.products.items.findIndex(
      (item) => {
        return item.id === itemId
      },
    )

    if (itemIndex >= 0) {
      this.gaApp.stores.home.stories.products.items[itemIndex].favourite =
        isAdded
    }
  }

  setDisclaimerOpened() {
    this.gaApp.stores.home.stories.disclaimer.opened = true
  }

  setDisclaimerClosed() {
    this.gaApp.stores.home.stories.disclaimer.opened = false
  }

  setActiveSlideIndex(index) {
    this.gaApp.stores.home.stories.activeSlideIndex = index
  }

  /**
   * Просмотренные стори хранятся в формате объекта { [story.id]: [story.updatedDate] }
   *
   * При запуске последнего storyItem, updatedDate сохраняется в объект
   *
   * Состояние стори проверяет соответствие story updatedDate с updatedDate в объекте,
   * тогда, если в стори были добавлены или удалены items, мы сможем определить,
   * что он был изменен и вычислить сооветствующее состояние
   */

  /** Проверяем если story item последний, то сохранаяем story как просмотренный */
  checkIsLastStoryItemAndStoreViewed() {
    const { id, activeIndex, lastIndex, updatedDate } = this.getActiveStory
    if (activeIndex === lastIndex) {
      this.storeViewed(id, updatedDate)
    }
  }

  /** Вычисляем текущее состояние стори */
  getStoryViewMode(story) {
    if (story.type === STORY_TYPES.STREAM) return VIEW.LIVE

    const isUnviewed =
      this.gaApp.stores.home.stories.viewed?.[story.id] !== story.updatedDate

    return isUnviewed ? VIEW.NEW : VIEW.BASE
  }

  /** Сохраняем просмотренные стори в локал стору */
  setViewedToLocalStore() {
    this.gaApp.services.app.browserStorage.local.set(
      VIEWED_STORIES_LOCAL_STORE_KEY,
      this.gaApp.stores.home.stories.viewed,
    )
  }

  /** Сохраняем просмотренную стори */
  storeViewed(storyId, updatedDate) {
    if (this.gaApp.stores.home.stories.viewed?.[storyId] !== updatedDate) {
      this.gaApp.stores.home.stories.viewed[storyId] = updatedDate

      this.setViewedToLocalStore()
    }
  }

  /** Переопределяем состояние сторирисов */
  syncViewed() {
    if (this.viewedStoriesLength === 0) return

    this.gaApp.stores.home.stories.items.forEach((item, index) => {
      this.gaApp.stores.home.stories.items[index].view =
        this.getStoryViewMode(item)
    })
  }

  /**
   * Очищаем список просмотренных стори от неиспользуемых/удаленных
   * чтоб не хранить лишнюю информацию
   */
  clearViewedFromUnused() {
    const itemsIds = Object.keys(this.gaApp.stores.home.stories.itemsIdMap)
    const viewedIds = Object.keys(this.gaApp.stores.home.stories.viewed)

    viewedIds.forEach((item) => {
      if (!itemsIds.includes(item)) {
        delete this.gaApp.stores.home.stories.viewed[item]
      }
    })

    this.setViewedToLocalStore()
  }

  /** Достаем из локал сторы просмотренные стори и наполняем нашу стору */
  fillViewed() {
    const viewed = this.gaApp.services.app.browserStorage.local.get(
      VIEWED_STORIES_LOCAL_STORE_KEY,
    )
    this.gaApp.stores.home.stories.viewed = viewed || {}
  }

  showStoryLoader() {
    this.gaApp.stores.home.stories.items[this.getActiveStoryIndex].loading =
      !this.isActiveStoryItemMediaLoaded
  }

  hideStoryLoader() {
    this.gaApp.stores.home.stories.items[this.getActiveStoryIndex].loading =
      false
  }

  startStory() {
    if (!this.getActiveStory.paused || !this.isActiveStoryItemMediaLoaded) {
      return
    }
    this.hideStoryLoader()

    this.gaApp.stores.home.stories.items[this.getActiveStoryIndex].paused =
      false

    this.checkIsLastStoryItemAndStoreViewed()
  }

  stopStory() {
    if (this.getActiveStory.paused) {
      return
    }

    this.gaApp.stores.home.stories.items[this.getActiveStoryIndex].paused = true
  }

  startChanging() {
    this.gaApp.stores.home.stories.changing = true
  }

  stopChanging() {
    this.gaApp.stores.home.stories.changing = false
  }

  startDragging() {
    this.gaApp.stores.home.stories.dragging = true
  }

  stopDragging() {
    this.gaApp.stores.home.stories.dragging = false
  }

  stopLongpress() {
    this.gaApp.stores.home.stories.longpressed = false
  }

  startLongpress() {
    this.gaApp.stores.home.stories.longpressed = true
  }

  stopWaiting() {
    this.gaApp.stores.home.stories.waiting = false
  }

  startWaiting() {
    this.gaApp.stores.home.stories.waiting = true
  }

  stopEverything() {
    this.stopChanging()
    this.stopDragging()
    this.stopLongpress()
    this.stopWaiting()
  }

  toNextStory() {
    this.gaApp.analytics.modules.home.onStoriesViewed()
    this.startChanging()
    this.hideStoryLoader()

    const { activeSlideIndex, lastSlideIndex } = this.gaApp.stores.home.stories
    if (activeSlideIndex < lastSlideIndex) {
      this.stopStory()
      this.setActiveSlideIndex(activeSlideIndex + 1)
    }

    this.showStoryLoader()
    this.stopChanging()
  }

  toPrevStory() {
    this.gaApp.analytics.modules.home.onStoriesViewed()
    this.startChanging()
    this.hideStoryLoader()

    const { activeSlideIndex } = this.gaApp.stores.home.stories
    if (activeSlideIndex > 0) {
      this.stopStory()
      this.setActiveSlideIndex(activeSlideIndex - 1)
    }

    this.showStoryLoader()
    this.stopChanging()
  }

  toNextStoryItem() {
    const { activeSlideIndex, lastSlideIndex } = this.gaApp.stores.home.stories
    const { activeIndex, lastIndex } =
      this.gaApp.stores.home.stories.items[this.getActiveStoryIndex]

    if (activeSlideIndex === lastSlideIndex && activeIndex === lastIndex) {
      this.closeModal()
      return
    }

    this.startChanging()
    this.hideStoryLoader()

    this.stopStory()
    if (activeIndex >= lastIndex) {
      this.toNextStory()
    } else {
      this.gaApp.stores.home.stories.items[this.getActiveStoryIndex]
        .activeIndex++

      this.showStoryLoader()
      this.stopChanging()
      this.gaApp.services.home.stories.setStoriesViewedState('')
    }
  }

  toPrevStoryItem() {
    const { activeSlideIndex } = this.gaApp.stores.home.stories
    const { activeIndex } =
      this.gaApp.stores.home.stories.items[this.getActiveStoryIndex]

    if (activeSlideIndex === 0 && activeIndex === 0) return

    this.startChanging()
    this.hideStoryLoader()

    this.stopStory()
    if (activeIndex <= 0) {
      this.toPrevStory()
    } else {
      this.gaApp.stores.home.stories.items[this.getActiveStoryIndex]
        .activeIndex--

      this.showStoryLoader()
      this.stopChanging()
      this.gaApp.services.home.stories.setStoriesViewedState('')
    }
  }

  setStoryItemImageLoaded(storyId, itemIndex) {
    this.getStoryItem(storyId, itemIndex).imageLoaded = true
  }

  setStoryItemImageLoadError(storyId, itemIndex) {
    this.getStoryItem(storyId, itemIndex).imageError = true
  }

  resetStoryItemImageLoadState(storyId, itemIndex) {
    const storyItem = this.getStoryItem(storyId, itemIndex)
    storyItem.imageLoaded = false
    storyItem.imageError = false
  }

  setStoryItemVideoLoaded(storyId, itemIndex) {
    this.getStoryItem(storyId, itemIndex).videoLoaded = true
  }

  setStoryItemVideoLoadError(storyId, itemIndex) {
    const { preferredFormat } = this.getStoryItem(storyId, itemIndex)
    if (preferredFormat === VIDEO_FORMAT.HLS) {
      const error = new Error('Ошибка проигрывания hls в stories')

      error.data = JSON.stringify({
        storyId,
        storyItemId: this.getStoryItem(storyId, itemIndex).id,
      })

      error.name = 'Stories hls error'

      this.gaApp.services.app.apm.captureError(error)

      this.setStoryItemVideoPreferredFormat(
        storyId,
        itemIndex,
        VIDEO_FORMAT.MP4,
      )
      return
    }

    this.getStoryItem(storyId, itemIndex).videoError = true
  }

  setStoryItemVideoPreferredFormat(storyId, itemIndex, format) {
    this.getStoryItem(storyId, itemIndex).preferredFormat = format
  }

  resetStoryItemVideoLoadState(storyId, itemIndex) {
    const storyItem = this.getStoryItem(storyId, itemIndex)
    storyItem.videoLoaded = false
    storyItem.videoError = false
  }

  setStoryItemDuration(storyId, itemIndex, duration) {
    this.getStoryItem(storyId, itemIndex).duration = duration
  }

  convertStory(story, index) {
    return {
      ...story,
      index,
      items: this.convertStoryItems(story.storyItems),
      view: VIEW.NEW,
      activeIndex: 0,
      lastIndex: story.storyItems.length - 1,
      paused: true,
      loading: false,
    }
  }

  convertStoryItems(storyItems) {
    return storyItems.map((item, index) => ({
      ...item,

      preloadImage: true,
      preloadVideo: false,

      imageLoaded: false,
      imageError: false,

      videoLoaded: false,
      videoError: false,

      preferredFormat: VIDEO_FORMAT.MP4,

      duration: item?.content?.duration ?? STORY_ITEM_DEFAULT_DURATION,
    }))
  }

  /** Наполняем стору состоянием */
  fillStore(block) {
    this.gaApp.stores.home.stories.items = (block.data?.items ?? []).map(
      (story, index) => {
        this.gaApp.stores.home.stories.storyViewHistory[story.id] = {
          viewed: [],
          skiped: [],
        }

        return this.convertStory(story, index)
      },
    )
    this.syncViewed()
  }

  increaseViewed() {
    this.gaApp.stores.home.stories.viewSkipInfo.pagesViewed++
  }

  clearViewed() {
    this.gaApp.stores.home.stories.viewSkipInfo.pagesViewed = 0
  }

  increaseSkiped() {
    this.gaApp.stores.home.stories.viewSkipInfo.skipPages++
  }

  clearSkiped() {
    this.gaApp.stores.home.stories.viewSkipInfo.skipPages = 0
  }

  disableFirstStories() {
    this.gaApp.stores.home.stories.viewSkipInfo.firstStories = false
  }

  clearFirstStories() {
    this.gaApp.stores.home.stories.viewSkipInfo.firstStories = true
  }

  viewedIncludes(storyId, storyItemId) {
    return this.gaApp.stores.home.stories.storyViewHistory[
      storyId
    ].viewed.includes(storyItemId)
  }

  skipedIncludes(storyId, storyItemId) {
    return this.gaApp.stores.home.stories.storyViewHistory[
      storyId
    ].skiped.includes(storyItemId)
  }

  viewedSkipedIncludes(storyId, storyItemId) {
    return {
      viewedIncludes: this.viewedIncludes(storyId, storyItemId),
      skipedIncludes: this.skipedIncludes(storyId, storyItemId),
    }
  }

  setStoriesViewedState(state) {
    this.gaApp.stores.home.stories.viewSkipInfo.state = state
  }

  setStoryItemViewed() {
    const storyId = this.getActiveStory.id
    const storyItemId = this.gaApp.stores.home.stories.activeStoryItem.id
    const { viewedIncludes, skipedIncludes } = this.viewedSkipedIncludes(
      storyId,
      storyItemId,
    )

    if (!viewedIncludes) {
      this.gaApp.stores.home.stories.storyViewHistory[storyId].viewed.push(
        storyItemId,
      )

      this.increaseViewed()
    }

    if (skipedIncludes) {
      const index =
        this.gaApp.stores.home.stories.storyViewHistory[storyId].skiped.indexOf(
          storyItemId,
        )

      this.gaApp.stores.home.stories.storyViewHistory[storyId].skiped.splice(
        index,
        1,
      )
    }
  }

  setStoryItemSkiped() {
    const storyId = this.getActiveStory.id
    const storyItemId = this.gaApp.stores.home.stories.activeStoryItem.id
    const { viewedIncludes, skipedIncludes } = this.viewedSkipedIncludes(
      storyId,
      storyItemId,
    )

    if (!skipedIncludes && !viewedIncludes) {
      this.gaApp.stores.home.stories.storyViewHistory[storyId].skiped.push(
        storyItemId,
      )

      this.increaseSkiped()
    }
  }

  init() {
    this.fillViewed()
    this.syncViewed()
    this.clearViewedFromUnused()
  }
}

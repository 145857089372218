import { TYPE } from '../../constants'
import { MindboxShareSetCartModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ShareSetCartBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.MINDBOX, MindboxShareSetCartModel]])

    super(models, data)
  }
}

import { GaIconPrimaryCart } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

import GaHeaderCartCounter from '../cart-counter'

const svg = {
  cart: GaIconPrimaryCart,
}

// @vue/component
export default {
  name: 'ga-header-cart-icon',

  components: {
    GaIcon,

    GaHeaderCartCounter,
  },

  inheritAttrs: false,

  data() {
    return {
      svg,
    }
  },

  computed: {
    qty() {
      return this.$gaApp.stores.cart.main.itemQty ?? 0
    },
  },
}

import { ApiService } from './api.service'
import { LaunchMeService } from './launch-me.service'
import { LrpEffaclarService } from './lrp-effaclar.service'
import { PageService } from './page.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  launchMe: new LaunchMeService(gaApp),
  lrpEffaclar: new LrpEffaclarService(gaApp),
})

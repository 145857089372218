import { ApiService } from './api.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { SearchService } from './search.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  page: new PageService(gaApp),
  search: new SearchService(gaApp),
  api: new ApiService(gaApp),
})

export const useClose = (items, emit) => {
  const destroy = (item) => {
    items.value = items.value.filter((value) => value.id !== item.id)
  }

  const destroyAll = () => {
    items.value = []
  }

  const close = (id) => {
    const item = items.value.find((value) => value.id === id)

    if (item) {
      destroy(item)

      emit('close', item)
      return item
    }
  }

  const onClickItem = (item) => {
    if (item.closeByClick) {
      destroy(item)
    }
  }

  const onSwipeItem = (item) => {
    if (item.closeBySwipe) {
      destroy(item)
    }
  }

  return {
    close,
    destroy,
    destroyAll,

    onClickItem,
    onSwipeItem,
  }
}

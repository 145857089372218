/*
 * возвращает ближайшее значение из массива
 * @param {number[]} array - массив значений
 * @param {number} value - значение
 * @returns {number} ближайшее значение
 */

export const findClosestValueInArray = (array, value) => {
  return array.reduce((prev, curr) => {
    return prev && Math.abs(curr - value) > Math.abs(prev - value) ? prev : curr
  }, null)
}

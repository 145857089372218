import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsShareCartClickModel extends GaAnalyticsBaseModel {
  constructor({ product }) {
    if (!product) {
      throw new Error('Product is required')
    }

    super('product')

    this.item = {
      itemId: product.itemId,
      itemListName: 'share_cart',
    }

    this.debug = {
      key: 'share-cart-click',
      data: product,
    }
  }
}

// @vue/component
export default {
  name: 'ga-image-adult',

  props: {
    isForAdults: {
      type: Boolean,
      default: false,
    },
    imageClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    adultAge() {
      return this.$gaApp.stores.app.common.adultAge
    },

    isAdult() {
      return this.$gaApp.stores.user.main.isAdult
    },

    showIcon() {
      return !this.hideIcon && this.isForAdults && !this.isAdult
    },

    iconLabel() {
      return `${this.adultAge}+`
    },
  },

  methods: {
    onIconWrapperClick(event) {
      event.stopPropagation()
      event.preventDefault()

      this.$gaApp.services.modal.main.openSuperModalAdult()
    },
  },
}

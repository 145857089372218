import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'

const STORES_CODE = {
  'ru-RU': 'default',
  'ru-BY': 'by',
  'ru-KZ': 'kz',
}
export class CacheService {
  constructor(gaApp, name, nameCookie) {
    this.gaApp = gaApp
    this.name = name
    this.nameCookie = nameCookie

    this.locale = this.gaApp.config.get('locale.active')
  }

  get storage() {
    return this.gaApp.services.app.browserStorage.local
  }

  init() {
    if (!this.storage.has(this.name)) {
      this.storage.set(this.name, {})
    }

    if (this.storage.has(this.name)) {
      this.gaApp.cookies.set('client-store-code', STORES_CODE[this.locale], {
        sameSite: 'lax',
      })
      this.gaApp.cookies.set(this.nameCookie, true, { sameSite: 'lax' })
    }
  }

  getCacheStorage() {
    const data = this.storage.get(this.name)

    if (isPlainObject(data)) {
      return data
    }

    return null
  }

  getItem(name) {
    const storage = this.getCacheStorage()
    const item = storage[name]

    if (storage && isPlainObject(item) && !isEmpty(item)) {
      return item
    }

    return null
  }

  setItem(name, data) {
    if (!isPlainObject(data)) {
      // throw new Error('параметр data должен быть обьектом')
      return
    }

    const storage = this.getCacheStorage()
    const oldData = this.getItem(name)
    let newData = {}

    if (oldData) {
      newData = { ...oldData, ...data }
    } else {
      newData = data
    }

    storage[name] = newData

    this.storage.set(this.name, storage)

    const newDataId = newData.dataId || newData.data_id

    if (newDataId) {
      this.gaApp.cookies.set(
        'section_data_ids',
        {
          ...(this.gaApp.cookies.get('section_data_ids') || {}),
          [name]: newDataId,
        },
        { sameSite: 'lax' },
      )
    }
  }
}

import cloneDeep from 'lodash/cloneDeep'
import isString from 'lodash/isString'

/**
 * @typedef TrimObjectStringsParams
 * @property {boolean} [edges] включить обрезание пробелов по краям (`true` по-умолчанию)
 * @property {boolean} [spaces] включить обрезание 2 и более пробелов до 1 пробела (`true` по-умолчанию)
 */

/**
 * Возвращает новый объект с обрезанными пробелами в строках
 *
 * @template T
 * @extends object
 *
 * @param {T} object объект у которого будут обрезаны строки
 * @param {TrimObjectStringsParams} params параметры обрезания
 * @returns {T}
 */
export const trimObjectStrings = (
  object,
  { edges = true, spaces = true } = {},
) => {
  const copy = cloneDeep(object)

  Object.keys(copy).forEach((key) => {
    if (!isString(copy[key])) {
      return undefined
    }

    if (spaces) {
      copy[key] = copy[key].replace(/\s+/g, ' ')
    }

    if (edges) {
      copy[key] = copy[key].replace(/\s+$/g, '').replace(/^\s+/g, '')
    }
  })

  return copy
}

<template>
  <svg viewBox="0 0 25 25">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M13 23.5c2.672 0 5.11-.998 6.963-2.641A8.096 8.096 0 0 0 13 16.9a8.096 8.096 0 0 0-6.963 3.96A10.46 10.46 0 0 0 13 23.5Zm0-8.1a9.591 9.591 0 0 1 8.04 4.353A10.457 10.457 0 0 0 23.5 13c0-5.799-4.701-10.5-10.5-10.5S2.5 7.201 2.5 13c0 2.572.925 4.928 2.46 6.753A9.592 9.592 0 0 1 13 15.4ZM25 13c0 3.15-1.214 6.017-3.2 8.158A11.968 11.968 0 0 1 13 25a11.963 11.963 0 0 1-5.968-1.587A11.995 11.995 0 0 1 1 13C1 6.373 6.373 1 13 1s12 5.373 12 12Zm-9.75-2.878a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm1.5 0a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-user',
}
</script>

export const GaModuleSearchModal = () =>
  import(
    /* webpackChunkName: 'modules/search/GaModuleSearchModal' */
    './modal'
  ).then(({ GaSearchModal }) => GaSearchModal)

export const GaModuleSearchResultHeader = () =>
  import(
    /* webpackChunkName: 'modules/search/GaModuleSearchResultHeader' */
    './search-result-header'
  ).then(({ GaSearchResultHeader }) => GaSearchResultHeader)

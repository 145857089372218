import { computed, toRefs, unref } from 'vue'

import { GaPrice } from '~/components/price'

import { propValidator } from '../../../utils'
import { price as priceSchema } from '../../../utils/schemas'

import { HIGHLIGHT_THEME, SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-dolyame',

  components: {
    GaPrice,
  },

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => propValidator(value, priceSchema),
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    highlightTheme: {
      type: String,
      default: HIGHLIGHT_THEME.DEFAULT,
      validator: (value) => Object.values(HIGHLIGHT_THEME).includes(value),
    },
  },

  setup(props) {
    const { size, highlight, highlightTheme } = toRefs(props)

    const mods = computed(() => ({
      size: unref(size),
      highlight: unref(highlight),
      highlight_theme: unref(highlightTheme),
    }))

    return {
      mods,
    }
  },
}

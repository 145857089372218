import { TYPE } from '../../constants'
import { GtmGa4OrderProductViewModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class OrderProductViewBuilder extends BaseBuilder {
  constructor(products) {
    const models = new Map([[TYPE.GTM_4, GtmGa4OrderProductViewModel]])

    super(models, products)
  }
}

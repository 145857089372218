import { EVENTS } from '../../../constants'

export class DigiAnalyticsAqSynonymClickModel {
  constructor(data) {
    this.data = {
      eventType: EVENTS.AQ_SYNONYM_CLICK,
    }

    for (const key in data) {
      this.data[key] = data[key]
    }
  }
}

import { imageFormat, screen, url, videoFormat } from './media-common'

export const video = {
  type: 'object',
  properties: {
    position: { type: 'number', required: true },
    videoThumbnail: {
      type: 'object',
      required: true,
      properties: {
        url: { ...url, required: true },
        screen: { ...screen, required: true },
        format: { ...imageFormat, required: true },
      },
    },
    videoUrl: {
      type: 'object',
      required: true,
      properties: {
        url: { ...url, required: true },
        screen: { ...screen, required: true },
        format: { ...videoFormat, required: true },
      },
    },
  },
  additionalProperties: false,
}

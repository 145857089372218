import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4CartItemModel } from '../gtm-ga4-cart-item.model/gtm-ga4-cart-item.model'

export class GtmGa4CartModel extends GtmBaseModel {
  constructor({ items, addToCart }) {
    const event = addToCart ? EVENT.ADD_TO_CART.GA4 : EVENT.REMOVE_FROM_CART.GA4
    super({ event })

    this.event_source = 'plaid_backend'
    this.ecommerce = {
      items: items.map((item) => new GtmGa4CartItemModel(item)),
    }
  }
}

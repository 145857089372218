import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useAppsStores = () => {
  const { $gaApp } = useContext()

  const android = computed(() => $gaApp.stores.app.common.apps.android)
  const huawei = computed(() => $gaApp.stores.app.common.apps.huawei)
  const rustore = computed(() => $gaApp.stores.app.common.apps.rustore)
  const ios = computed(() => $gaApp.stores.app.common.apps.ios)

  return {
    android,
    huawei,
    rustore,
    ios,
  }
}

import { URL_PAGE_KEY } from '../constants/keys'

export class ListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadListing(pageType) {
    // очищаем данные хранилища при смене типа страницы (акции/кд)
    if (this.gaApp.stores.promo.page.pageType) {
      this.gaApp.stores.promo.listing.$reset()
    }

    this.gaApp.services.promo.listing.setLoading(true)
    this.setPageType(pageType)

    await this.gaApp.services.promo.api.fetchCities()
    await this.gaApp.services.promo.api.fetchCityStores()
    await this.gaApp.services.promo.location.setBaseCity()
    await this.loadPromotions(this.gaApp.route.query[URL_PAGE_KEY] || 1)

    this.gaApp.services.promo.listing.setLoading(false)
  }

  async updateCitySelectAfterDeliveryChange() {
    await this.gaApp.services.promo.location.setBaseCity()
    await this.loadNewPage()
  }

  async loadPromotions(page) {
    await this.gaApp.services.promo.api.fetchPromotions(page)
  }

  async loadNewPage() {
    this.gaApp.services.promo.listing.setLoading(true)

    this.clearUrl()
    await this.gaApp.services.promo.listing.clearPages()
    await this.gaApp.services.promo.listing.loadPromotions(1)

    this.gaApp.services.promo.listing.setLoading(false)
  }

  setPageType(pageType) {
    this.gaApp.stores.promo.page.pageType = pageType
  }

  setPreviousPageType(pageType) {
    this.gaApp.stores.promo.page.previousPageType = pageType
  }

  setPromotionsCount(count) {
    this.gaApp.stores.promo.listing.promotionsCount = count
  }

  setPage(page) {
    this.gaApp.stores.promo.listing.pages.push(page)
    this.gaApp.stores.promo.listing.pages.sort((a, b) => a.number - b.number)
  }

  setMeta({ metaTitle, metaDescription, metaKeywords }) {
    this.gaApp.stores.promo.listing.metaTitle = metaTitle
    this.gaApp.stores.promo.listing.metaDescription = metaDescription
    this.gaApp.stores.promo.listing.metaKeywords = metaKeywords
  }

  setLoading(isLoading) {
    this.gaApp.stores.promo.listing.isLoading = isLoading
  }

  setShouldReloadPage(shouldReloadPage) {
    this.gaApp.stores.promo.listing.shouldReloadPage = shouldReloadPage
  }

  addViewedId(id) {
    this.gaApp.stores.promo.listing.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.promo.listing.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }

  clearPages() {
    this.gaApp.stores.promo.listing.pages = []
  }

  clearUrl() {
    const currentQuery = this.gaApp.route.query

    if (Object.keys(currentQuery).length) {
      this.gaApp.router.replace({ query: {} })
    }
  }

  async changeUrlPage(page) {
    const query = this.gaApp.route.query
    const queryPage = +query[URL_PAGE_KEY] || 1

    if (queryPage === page) {
      return
    }

    await this.gaApp.router.replace({
      query: {
        ...query,
        [URL_PAGE_KEY]: page > 1 ? page : undefined,
      },
    })
  }
}

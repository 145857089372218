import { EVENT_CATEGORIES, EVENTS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base/gtm-base.model'

export class GtmGa4SearchUserInteraction extends GtmBaseModel {
  constructor(data) {
    super({ event: EVENTS.USER_INTERACTION })

    for (const key in data) {
      this[key] = data[key]
    }

    this.event_category = EVENT_CATEGORIES.SEARCH
  }
}

export const state = () => ({
  items: [],
  hasBeenSent: false,
  selectModal: {
    isOpen: false,
    triggeredBy: null,
    hasBeenShown: false,
    isAnimated: false,
    isAnimating: false,
  },
  timer: {
    duration: null,
    durationLeft: null,
    pauseTime: null,
    resumeTime: null,
    startTime: null,
    timeoutId: null,
  },
  receive: {
    image: null,
    isExpired: null,
    isImageLoaded: false,
    recipientName: null,
  },
})

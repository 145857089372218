import { ApiService } from './api.service'
import { ApmService } from './apm.service'
import { BonusService } from './bonus.service'
import { BrowserStorageService } from './browserStorage.service'
import { BrowserWindowService } from './browserWindow.service'
import { ClipboardService } from './clipboard.service'
import { CookiePermissionService } from './cookie-permission.service'
import { CurrencyService } from './currency.service'
import { EventBusService } from './event-bus.service'
import { LangSwitcherService } from './lang-switcher.service'
import { MainService } from './main.service'
import { NumberService } from './number.service'
import { PageScrollService } from './page-scroll.service'
import { PhoneService } from './phone.service'
import { RouterService } from './router.service'
import { SchedulerService } from './scheduler.serivce'
import { SchemaService } from './schema'
import { ScrollNavigationService } from './scroll-navigation'
import { StringService } from './string.service'
import { VideoService } from './video.service'
import { WindowService } from './window.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  router: new RouterService(gaApp),
  api: new ApiService(gaApp),
  scrollNavigation: new ScrollNavigationService(gaApp),
  apm: new ApmService(gaApp),
  bonus: new BonusService(gaApp),
  browserStorage: new BrowserStorageService(gaApp),
  browserWindow: new BrowserWindowService(gaApp),
  scheduler: new SchedulerService(gaApp),
  currency: new CurrencyService(gaApp),
  eventBus: new EventBusService(gaApp),
  clipboard: new ClipboardService(gaApp),
  cookiePermission: new CookiePermissionService(gaApp),
  pageScroll: new PageScrollService(gaApp),
  string: new StringService(gaApp),
  number: new NumberService(gaApp),
  schema: new SchemaService(gaApp),
  langSwitcher: new LangSwitcherService(gaApp),
  phone: new PhoneService(gaApp),
  window: new WindowService(gaApp),
  video: new VideoService(gaApp),
})

<template>
  <svg viewBox="0 0 15 15">
    <path fill="none" d="M.5 7.5a7 7 0 1 1 14 0 7 7 0 0 1-14 0z" />
    <path
      stroke="none"
      d="M8.12 8.65V3.99H6.95v4.66h1.17zM7.55 9.9c-.39 0-.7.3-.7.67 0 .4.31.68.7.68.39 0 .7-.29.7-.68a.68.68 0 0 0-.7-.67z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-alert-sign',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

import { nextTick, shallowRef, unref } from 'vue'

import { getId } from '@ga/utils'

import { useQueueModals } from '~/modules/modal'

import { useZIndex } from '../z-index'

/**
 * Возвращает функции для хуков открытия/закрытия модалки,
 * которые обеспечивают взаимодействие с лейэаутом приложения
 * @param {Object} obj
 * @param {Number | String} [obj.uniqueId]
 * @param {Ref<Boolean>} [obj.layoutHide]
 * @param {Ref<Boolean>} [obj.opened]
 */
export const useModal = ({ uniqueId, layoutHide, opened }) => {
  const { zIndex, reserveZIndex, unreserveZIndex } = useZIndex(uniqueId)
  const { $gaApp } = useContext()

  const modalId = uniqueId || getId() // uniqueId передается не везде в useModal

  const layoutStateBeforeOpen = shallowRef()

  const { internalOpened } = useQueueModals({ modalId, opened })

  const onStartOpen = () => {
    reserveZIndex()

    // перед открытием модалки закрываем все нотификации
    $gaApp.services.notification.main.closeAll()
    $gaApp.services.modal.register.add(modalId)
  }

  const onFinishClose = () => {
    unreserveZIndex()

    $gaApp.services.modal.register.remove(modalId)
  }

  const onLeaveCancelled = () => {
    unreserveZIndex()
  }

  const onFinishOpen = () => {
    if (typeof layoutHide === 'undefined' || !unref(layoutHide)) return

    layoutStateBeforeOpen.value = {
      headerVisible: $gaApp.stores.app.main.headerVisible,
      mainVisible: $gaApp.stores.app.main.mainVisible,
      footerVisible: $gaApp.stores.app.main.footerVisible,
      windowScroll: window.pageYOffset,
    }

    $gaApp.stores.app.main.setLayoutVisible({
      header: false,
      main: false,
      footer: false,
    })
  }

  const onStartClose = () => {
    if (!unref(layoutStateBeforeOpen)) return

    // reset на всех устройствах для обработки случая ресайза из мобилки в десктоп
    // или для случая, когда layoutHide перешел в fasle уже после открытия
    $gaApp.stores.app.main.setLayoutVisible({
      header: unref(layoutStateBeforeOpen).headerVisible,
      main: unref(layoutStateBeforeOpen).mainVisible,
      footer: unref(layoutStateBeforeOpen).footerVisible,
    })

    nextTick(() => {
      window.scrollTo({ top: unref(layoutStateBeforeOpen).windowScroll })
      layoutStateBeforeOpen.value = null
    })
  }

  return {
    zIndex,
    onStartOpen,
    onFinishClose,
    onFinishOpen,
    onStartClose,
    onLeaveCancelled,
    internalOpened,
  }
}

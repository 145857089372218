<template>
  <svg viewBox="0 0 27 28">
    <circle cx="13.5" cy="13" r="12.38" fill="none" stroke-width="1.25" />
    <path
      stroke="none"
      d="M13.74 18c.58 0 1.05-.44 1.05-1 0-.57-.47-1.02-1.05-1.02-.58 0-1.04.45-1.04 1.02 0 .56.46 1 1.04 1zm-.86-9.39.23 6.5h1.28l.24-6.5h-1.75z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-alert',
}
</script>

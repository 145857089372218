import { ACTIONS } from '~/modules/subscription/analytics/constants'
import { GtmBaseModel } from '~/modules/subscription/analytics/models/gtm/gtm-base.model/gtm-base.model'

export class GtmGa4SubscribeSentModel extends GtmBaseModel {
  constructor({ eventCategory, actionType }) {
    super(ACTIONS.USER_INTERACTION.GA4)
    this.event_category = eventCategory
    this.action_type = actionType
  }
}

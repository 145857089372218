import flow from 'lodash/flow'
import has from 'lodash/has'

import { unref } from 'vue'

import { findReserveScreen, replaceMediaUrl } from '@ga/utils'

import { findSafeExtension, removeDot } from '../../utils'
import { useImageBreakpoints } from '../image-breakpoints'

export const useImageStrategyComplex = (config) => {
  const { breakpoints } = useImageBreakpoints()

  const getName = () => 'complex'

  /**
   * Метод позволяет определить стратегию на основе конфига изображения
   * Если конфиг содержит в такие ключи как url / format / screen, то это текущая стратегия
   */
  const isOwnStrategy = () => {
    return ['url', 'format', 'screen'].some((key) => has(config, key))
  }

  /**
   * Возвращает, массив имён экранов, в зависимости от количества
   * доступных экранов, для которых нужно собрать sources
   *
   * @returns {array} массив экранов, для которых нужно собрать sources
   */
  const getScreensForBuild = () => {
    const { screen } = unref(config)

    switch (screen?.length || 0) {
      // Если массив screen пустой, то собираем одну картинку, которая будет использоваться на всех экранах
      // без media-query и без параметра screen в url картинки
      case 0:
        return [null]

      // В любом другом случае, собираем sources всех возможных экранов
      default:
        return unref(breakpoints).keys
    }
  }

  /**
   * Возвращает массив sources для определенного экрана со всеми возможными форматами изображений
   *
   * @param {string} currentScreen имя экрана для которого нужно собрать sources
   * @param {string} image пропс image, который содержит конфиг для картинки
   * @return {array} sources для определенного экрана со всеми возможными форматами изображений
   */
  const buildSources = (currentScreen, image) => {
    const { screen, format, url } = unref(image)
    const { values: bpValues, keys: bpKeys } = unref(breakpoints)

    if (!format?.length) {
      return [
        {
          media: bpValues[currentScreen] ?? false,
          srcset: [
            replaceMediaUrl(url, {
              screen: findReserveScreen(currentScreen, bpKeys, screen),
            }),
          ],
        },
      ]
    }

    return format.map((ext) => ({
      ext: removeDot(ext),
      media: bpValues[currentScreen] ?? false,
      srcset: [
        replaceMediaUrl(url, {
          format: ext,
          screen: findReserveScreen(currentScreen, bpKeys, screen),
        }),
      ],
    }))
  }

  /**
   * Возвращает ссылку на изображение, которое будет использоваться в качестве fallback-изображения
   * Не имеет параметра screen и не имеет параметров media-query
   *
   * @param {string} image пропс image, который содержит конфиг для картинки
   * @return {string} ссылка на изображение
   */
  const buildSourceFallback = (image) => {
    const fallback = flow(buildSources, findSafeExtension)(null, image)

    return fallback && fallback.srcset[0]
  }

  return {
    getName,
    isOwnStrategy,
    getScreensForBuild,
    buildSourceFallback,
    buildSources,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

export const useCoupon = (data) => {
  const { $gaApp } = useContext()

  /**
   * Возвращает отформатированную строку путем преобразования объекта
   * цены в номинальное значение и добавления знака минус и валюты.
   *
   * @param {Object} цена — объект цены, содержащий сумму и валюту.
   * @return {строка} Отформатированная строка со знаком минус и валютой.
   */
  const getFormattedPrice = (price) => {
    const formatted = $gaApp.services.app.currency.getNominalFormatted({
      amount: Math.abs(price.amount),
      denominator: price.denominator,
      currency: price.currency,
      withSymbol: true,
      minimumFractionDigits: 0,
    })

    return `–${formatted}`
  }

  /**
   * Возвращает отформатированную строку скидки со знаком процента.
   *
   * @param {string} Discount — форматируемое значение скидки.
   * @return {string} Отформатированная строка скидки.
   */
  const getFormattedDiscount = (discount) => {
    const value = Math.abs(Number.parseInt(discount, 10))
    return `–${value}%`
  }

  const getDiscountValue = (data) => {
    const { discountPercent, discount, type } = unref(data)

    switch (type) {
      case 'percent':
        return getFormattedDiscount(discountPercent)

      case 'fixed':
        return getFormattedPrice(discount)

      default:
        break
    }
  }

  const discountText = computed(() => {
    return getDiscountValue(unref(data))
  })

  return {
    discountText,

    getDiscountValue,
  }
}

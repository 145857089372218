export const state = () => ({
  isQrModalOpened: false,
  foreground: {},
  background: {},
  designColor: '',
  denomination: {},
  orderNumber: '',
  cardNumber: '',
  qrCode: '',
  cvc: '',
})

import { deparam } from '@ga/utils'

import { PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect, context) {
    switch (redirect.pageType) {
      case PAGE_TYPE.CART:
        this.openCart(context)
        break

      case PAGE_TYPE.SHARE:
        this.openShare(redirect, context)
        break
    }
  }

  getParams() {
    return deparam(window.location.search.slice(1))
  }

  async openCart(context) {
    this.goToHomePage(context.redirect)

    if (this.gaApp.isServer) {
      return
    }

    const params = this.getParams()

    if (!params.products) {
      return
    }

    await this.gaApp.services.cart.product.addItemsFromUrlParams(params)
    this.gaApp.services.modal.main.openSuperModalCart()
  }

  openShare(redirect, context) {
    this.goToFirstCategory(context.redirect)

    if (this.gaApp.isServer) {
      return
    }

    this.gaApp.services.cart.share.open(redirect.id)
  }

  goToHomePage(redirect) {
    redirect('/')
  }

  goToFirstCategory(redirect) {
    redirect(this.gaApp.services.header.nav.getUrlFirstCategory())
  }
}

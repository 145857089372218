import { unref } from 'vue'

import { STATE } from '../../const'

/**
 * Возвращает методы открытия/закрытия модалки
 * @param {Ref<import("../../const").State>} state - текущее состояние модалки
 * @param {Ref<Boolean>} locked - залочена ли модалка
 * @param {Function} emit - функция emit из контекста
 */
export const useBehavior = (state, locked, emit) => {
  const close = ({ silent = false } = {}) => {
    const isLocked = unref(locked)
    const isAlreadyClosing = [STATE.CLOSING, STATE.CLOSED].includes(
      unref(state),
    )

    if (isAlreadyClosing || isLocked) {
      return
    }

    state.value = STATE.CLOSING

    if (!silent) {
      emit('toggleOpened', false)
    }
  }

  const open = ({ silent = false } = {}) => {
    const isAlreadyOpening = [STATE.OPENING, STATE.OPENED].includes(
      unref(state),
    )

    if (isAlreadyOpening) {
      return
    }

    state.value = STATE.OPENING

    if (!silent) {
      emit('toggleOpened', true)
    }
  }

  return {
    open,
    close,
  }
}

import { TYPE } from '../../constants'
import { GaAnalyticsItemClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ItemClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GAA, GaAnalyticsItemClickModel]])
    super(models, data)
  }
}

/*
 * Откладывает выполнение на два кадра анимации.
 * Для тех случаев, когда $nextTick, одиночный raf и setTimeout не работают.
 * Доступно только на клиенте.
 * Взято из https://github.com/twickstrom/vue-force-next-tick/blob/master/src/index.js
 * forceNextTick переименовано в doubleRAF.
 */

function doubleRAF(func) {
  window.requestAnimationFrame(() => window.requestAnimationFrame(func))
}

function $doubleRAF(func) {
  if (func && typeof func === 'function') {
    return doubleRAF(func)
  }

  return new Promise((resolve) => {
    doubleRAF(resolve)
  })
}

export default (_, inject) => {
  inject('doubleRAF', $doubleRAF)
}

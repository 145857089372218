<template>
  <svg viewBox="0 0 20 20">
    <path stroke="#000" stroke-width="1.5" d="m6 6 8 8m-8 0 8-8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-coupon-cancel',
}
</script>

import { computed, unref } from 'vue'

/**
 * Возвращает атрибуты для label
 * @param {Ref<Boolean>} isHovered - флаг ховера инпута
 * @param {Ref<Boolean>} isFocused - флаг фокуса инпута
 * @param {Ref<Boolean>} hasValue - флаг наличия значения в инпуте
 * @param {Ref<Boolean>} disabled - флаг disabled из props
 * @param {Ref<Boolean>} error - флаг ошибки из props
 * @param {Ref<Boolean>} [highlight] - параметр highlight из props (может не совпадать с фокусом, есть есть раскрывающийся список)
 * @param {Ref<Boolean>} hasScroll - флаг наличия скрола в инпуте
 * @param {Ref<Boolean>|undefined} isRequired - является ли поле обязательным, парамерт из props
 * @param {Ref<Boolean>|undefined} hasMask - инпут с маской из props
 * @param {Ref<Boolean>|undefined} noMaskSpacing - наследует letter-spacing от родителя, парамерт из props
 */

export const useStateAttributes = ({
  isHovered,
  isFocused,
  hasValue,
  disabled,
  error,
  highlight,
  hasScroll,
  isRequired,
  hasMask,
  noMaskSpacing,
}) => {
  const stateAttributes = computed(() => {
    return {
      'data-hover': isHovered.value,
      'data-focus': isFocused.value,
      'data-has-value': hasValue.value,
      'data-disabled': disabled.value,
      'data-error': error.value,
      'data-highlight': highlight?.value,
      'data-has-scroll': hasScroll?.value,
      'data-required': unref(isRequired),
      'data-has-mask': unref(hasMask),
      'data-no-mask-spacing': unref(noMaskSpacing),
    }
  })

  return {
    stateAttributes,
  }
}

import { POSITION } from '../const'

import { split } from './split'

export const getFullPosition = (shorthandPosition) => {
  const temporaryPosition = split(shorthandPosition)

  let x = null
  let y = null

  temporaryPosition.forEach((position) => {
    if (POSITION.y.includes(position)) {
      y = position
    }
    if (POSITION.x.includes(position)) {
      x = position
    }
  })

  return { x, y }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { STOCK_STATUS } from '../constants'

/**
 * @typedef {Object} ProductForStockStatusCheck
 * @property {String} itemId - id продукта (из api)
 * @property {Boolean} inStock - продукт в наличии (из api)
 */

/**
 * Определяет статуст продукта - в наличии, закончился или последний в текущей корзине
 * @param {Ref<ProductForStockStatusCheck>} product - продукт из пропс - ссылка на весь продукт для computed полей
 */
export const useStockStatus = (product) => {
  const { $gaApp } = useContext()

  const isWholeStockInCart = computed(() =>
    $gaApp.services.cart.main.checkProductWholeStock(unref(product)?.itemId),
  )

  const productStatus = computed(() => {
    // Если в корзину добавлены все запасы продукта
    if (isWholeStockInCart.value) {
      return STOCK_STATUS.WHOLE_STOCK_IN_CART
    }

    // Если продукта нет в наличии
    if (!unref(product)?.inStock) {
      return STOCK_STATUS.OOS
    }

    return STOCK_STATUS.IN_STOCK
  })

  const isOutOfStock = computed(() =>
    [STOCK_STATUS.OOS, STOCK_STATUS.WHOLE_STOCK_IN_CART].includes(
      productStatus.value,
    ),
  )

  return {
    productStatus,
    isOutOfStock,
  }
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const paymentStore = () => {
  const init = defineStore('giftCardsPayment', {
    state,
    getters,
  })

  return init(getActivePinia())
}

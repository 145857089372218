export class ClientDaysService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(
      this.gaApp.stores.home.clientDays.clientDaysData,
    ).includes(block.id)
  }

  updateBlockData(block) {
    const index =
      this.gaApp.stores.home.clientDays.clientDaysData[block.id]?.index
    this.gaApp.stores.home.clientDays.items[index].data = block.data
  }

  addBlockData(block) {
    this.gaApp.stores.home.clientDays.items.push({
      id: block.id,
      data: block.data,
    })
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }

  addViewedId(id) {
    this.gaApp.stores.home.clientDays.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.home.clientDays.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmClickPayModel extends GtmBaseModel {
  constructor({ customPriceValue, customCurrency }) {
    super({ event: 'pay_gift_card' })

    this.payload = {
      customPriceValue,
      customCurrency,
    }
  }
}

import { defineStore, getActivePinia } from 'pinia'

import state from './state'

export const warningStore = (gaApp) => {
  const init = defineStore('cartWarning', {
    state,
  })

  return init(getActivePinia())
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4FastFilterSortingChange extends GtmBaseModel {
  constructor(data) {
    super()

    const { formattedName, sortingValue, itemListName } = data

    this.action_type = 'apply_filters'
    this.type = 'select'
    this.item_list_name = itemListName

    this[formattedName] = sortingValue
  }
}

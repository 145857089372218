import { SETTINGS_PRIORITY } from '../../constants'

export const getters = (gaApp) => ({
  optionsList() {
    const data = this.options

    return Object.keys(data)
      .map((key) => ({
        name: key,
        checked: data[key],
        text: gaApp.i18n.t(`customer.settings.toggles.${key.toLowerCase()}`),
        priority: SETTINGS_PRIORITY[key],
      }))
      .sort((a, b) => a.priority - b.priority)
  },
})

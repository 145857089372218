import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { services } from './services'
import { stores } from './store'

export const eyezon = {
  meta: {
    name: 'eyezon',
    locales,
  },
  components,
  layers: {
    stores,
    services,
    analytics,
  },
}

import { TYPE } from '../../constants'
import { GtmPromptChipClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PromptChipClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmPromptChipClickModel]])
    super(models, data)
  }
}

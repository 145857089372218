import dayjs from 'dayjs'

/**
 * Проверяет, является ли входное значение для даты (строка или дата) корректным
 * @param {(Date|string)} inputValue
 * @return {boolean}
 */
export const dateValidate = (inputValue) => {
  return dayjs(inputValue).isValid()
}

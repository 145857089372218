/*
 * Oggetto
 *
 * Компонент, в который выносится DOM компонентов с position: fixed
 * (например, модальных окон) для облегчения работы с их версткой.
 *
 * Документация portal-vue: https://portal-vue.linusb.org/
 */

import { PortalTarget } from 'portal-vue'

// @vue/component
export default {
  name: 'ga-portal-target',

  components: {
    PortalTarget,
  },

  props: {
    name: {
      type: String,
      default: null,
    },
  },

  computed: {
    nameInternal() {
      return this.name || this.$options.name
    },
  },
}

import { TYPE } from '../../constants'
import { GtmFormSentModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class FormSentBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmFormSentModel]])
    super(models, data)
  }
}

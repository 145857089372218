export const state = () => ({
  stepHistory: [],
  paymentId: null,
  orderId: null,
  modal: {
    isOpen: false,
  },
  recipe: {
    phone: '',
  },
  payMethod: {
    selected: null,
    loading: null,
    error: '',
  },
  card: {
    saved: [],
    errors: {},
    saveable: null,
    selectedId: null,
    save: true,
    loading: false,
  },
  defaultPaymentMethod: '',
  sbp: {
    loading: false,
    paymentUrl: null,
    banks: [],
    searchBankQuery: '',
    selectedBankName: null,
  },
  status: {
    intervalHandler: null,
  },
})

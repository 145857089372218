import { EVENT } from '../../../constants'
import { MindboxShareProductModel } from '../mindbox-share-product.model/mindbox-share-product.model';

export class MindboxShareAddToCartEventModel {
  constructor(product) {
    this.operation = EVENT.SHARE_ADD_TO_CART.MINDBOX

    this.data = {
      addProductToList: new MindboxShareProductModel(product),
    }
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OrderClickModel extends GtmBaseModel {
  constructor({ place, type }) {
    super({})

    this.action_type = this.buildActionType(type)
    this.place = place
  }

  buildActionType(type) {
    return {
      digital: 'click_to_order_kepk',
      plastic: 'click_to_order_kppk',
    }[type]
  }
}

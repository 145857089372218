import { formatArrayAsString } from '@ga/utils'

export class DigiProductBaseModel {
  constructor(product) {
    this.productId = product.sku
    this.productName = formatArrayAsString(
      [product.type, product.brand, product.name],
      ' | ',
    )
    this.sku = product.sku
    this.price = `${product.price}`
    this.salePrice = `${product.salePrice}`
    this.quantity = product.quantity
    this.subtotal = `${product.subtotal}`
  }
}

import { scriptInjecting } from '@ga/shared-browser'

import { log } from './advcake.utils'

export class Advcake {
  constructor() {
    this.isInit = false
  }

  async create(id) {
    try {
      if (this.isInit) {
        log('Модуль уже проинициализирован', id)

        return false
      }

      // Загружаем на страницу скрипт
      const url = '//udqrh6.ru/'

      const options = {
        async: true,
        id: id || 'advcakeAsync',
      }

      const result = await scriptInjecting.inject(url, options)

      this.isInit = true

      log('Инициализация прошла успешно', id)

      return result
    } catch (error) {
      log('Ошибка инициализации', error)

      // Что бы ошибка не спамила в кибану
      // TODO: Попробовать найти способ разделять ошибки по типу
      // throw error
    }
  }

  push(payload) {
    log('push', payload)

    if (this.isInit) {
      window.advcake_data = window.advcake_data || []

      return window.advcake_data.push(payload)
    }
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmCloseModalModel extends GtmBaseModel {
  constructor({ eventStep }) {
    super({ event: 'exit_from_gift_card' })

    this.payload = {
      eventStep,
    }
  }
}

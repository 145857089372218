import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4BubbleClick extends GtmBaseModel {
  constructor(data) {
    super()

    const { itemListName, bubbleName, bubblePosition } = data

    this.type = 'bubble'
    this.action_type = 'apply_filters'
    this.filter_name = bubbleName
    this.position = bubblePosition
    this.item_list_name = itemListName
  }
}

import { PAGE_TYPE, PAGE_URL_KEY } from '../consts/pages'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.DOCUMENTS:
        case PAGE_TYPE.DELIVERY:
          return await this.gaApp.services.info.api.fetchCmsContent(
            PAGE_URL_KEY.DOCUMENTS,
          )
        case PAGE_TYPE.FAQ:
          return await this.gaApp.services.info.api.fetchCmsContent(
            PAGE_URL_KEY.FAQ,
          )
        case PAGE_TYPE.CONTACTS:
          return await this.gaApp.services.info.api.fetchContactsInfo()

        default:
          return undefined
      }
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error.message })
    }
  }
}

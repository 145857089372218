<template>
  <svg fill="none" viewBox="0 0 21 21">
    <path
      stroke="currentColor"
      d="m10.5 3.794 1.664 3.974.118.28.302.025 4.294.355-3.265 2.81-.23.199.07.295.989 4.193-3.682-2.236-.26-.158-.26.158-3.682 2.236.99-4.193.07-.295-.23-.198-3.266-2.811 4.294-.355.302-.025.118-.28L10.5 3.794Z"
    />
    <path
      fill="currentColor"
      d="m5.797 16.972 4.702-2.856V2.5L8.374 7.575l-5.483.453 4.17 3.59-1.264 5.354Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-star-fill-half',
}
</script>

import { unref } from 'vue'

import { useVideoAutoplay } from '~/modules/app'

export const usePlayer = (videoRef) => {
  const { handleVideoPlayError } = useVideoAutoplay()

  const play = () => {
    unref(videoRef).play()
  }

  const pause = () => {
    unref(videoRef).pause()
  }

  const stop = () => {
    unref(videoRef).stop()
  }

  const restartVideo = () => {
    unref(videoRef).restartVideo()
  }

  const togglePlay = () => {
    unref(videoRef).togglePlay()
  }

  const toggleMuted = () => {
    unref(videoRef).toggleMuted()
  }

  const mute = () => {
    unref(videoRef).mute()
  }

  const unmute = () => {
    unref(videoRef).unmute()
  }

  const changeCurrentTime = (currentTime) => {
    videoRef.value.currentTime = currentTime
  }

  const onErrorPlayer = (error) => handleVideoPlayError(error)

  return {
    play,
    pause,
    stop,
    restartVideo,
    togglePlay,

    toggleMuted,
    mute,
    unmute,

    changeCurrentTime,

    onErrorPlayer,
  }
}

import { TYPE } from '../../constants'
import { GaAnalyticsTitleClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class TitleClickBuilder extends BaseBuilder {
  constructor({ itemListName, itemListId, url }) {
    const models = new Map([[TYPE.GAA, GaAnalyticsTitleClickModel]])
    super(models, {
      itemListName,
      itemListId,
      url,
    })
  }
}

/*
 * Возвращает функцию замкнутую на отсортированном объекте config.
 *
 * Возвращенная функция возвращает ключ из объекта config
 * по условию config[key] <= value < config[nextKey].
 *
 * Значения объекта config должны быть числами.
 *
 * Использование:
 *
 * const config = {
 *     xxl: 0,
 *     xl: 10,
 *     l: 20,
 *     m: 30,
 *     s: 40,
 *     xs: 50,
 *     xxs: 60,
 * };
 *
 * const determineSize = sizeDeterminer(config);
 * const string = 'JavaScript - pomoika';
 *
 * const size = determineSize(string) // 'm'
 *
 * или
 *
 * const stringLength = 'JavaScript - pomoika'.length
 *
 * const size = determineSize(stringLength) // 'm'
 *
 */

export const sizeDeterminer = (config) => {
  const configEntries = Object.entries(config).sort((a, b) => a[1] - b[1])

  const configSorted = Object.fromEntries(configEntries)

  return (value) => {
    const length = typeof value === 'string' ? value.length : value

    const configSortedKeys = Object.keys(configSorted)
    const [firstKey] = configSortedKeys

    return configSortedKeys.reduce((acc, key) => {
      if (length >= configSorted[key]) {
        return key
      }

      return acc
    }, firstKey)
  }
}

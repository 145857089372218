export class GtmGa4CartItemModel {
  constructor(item) {
    const {
      id,
      name,
      price,
      brand,
      variant,
      itemListName,
      stock,
      categoryId,
      itemListId,
      creativeName,
      creativeSlot,
      promotionName,
    } = item

    this.item_id = id
    this.item_name = name
    this.item_list_name = itemListName
    this.item_list_id = itemListId
    this.item_brand = brand
    this.price = parseFloat(price)
    this.item_variant = variant
    this.stock = String(stock)

    if (categoryId) {
      this.category_id = categoryId
    }

    if (creativeName) {
      this.creative_name = creativeName
    }

    if (creativeSlot) {
      this.creative_slot = creativeSlot
    }

    if (promotionName) {
      this.promotion_name = promotionName
    }
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmOpenPageReceiveCardModel extends GtmBaseModel {
  constructor({ type, sourceLink }) {
    super({ event: 'recive_card' })

    this.payload = {
      type,
    }

    if (sourceLink) this.payload.source_link = sourceLink
  }
}

export const VIEW = {
  BASE: 'base',
  INVERSE: 'inverse',
}

export const SIZE = {
  XS: 'xs', // 10px
  S: 's', // 12px
  SM: 'sm', // 13px
  M: 'm', // 14px
  ML: 'ml', // 15px
  L: 'l', // 16px
  XL: 'xl', // 18px
}

export const INDENT = {
  NONE: 'none', // 0px
  S: 's', // 2px
  M: 'm', // 3px
  L: 'l', // 5px
}

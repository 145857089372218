export class CartV3Repository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Получение данных корзины
   */
  async getCart(data) {
    const { url } = this.endpoints.getCart()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Добавление товара
   */
  async addItem(data) {
    const { url } = this.endpoints.item()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Добавление товаров
   */
  async addItems(data) {
    const { url } = this.endpoints.items()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Удаление товаров
   */
  async deleteItems(data) {
    const { url } = this.endpoints.items()

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params: data,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Обновление количества товаров
   */
  async updateItemQty(data) {
    const { url } = this.endpoints.updateItemQty()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Применение купона
   */
  async applyCoupon(data) {
    const { url } = this.endpoints.coupon()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Удаление купона
   */
  async cancelCoupon(data) {
    const { url } = this.endpoints.coupon()

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params: data,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

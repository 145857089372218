export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getProductsV2(data = {}) {
    const { url } = this.endpoints.getProductsV2()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getFilters(data = {}) {
    const { url } = this.endpoints.getFilters()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getPlp(data = {}) {
    const { url } = this.endpoints.getPlp()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addProducts(data = []) {
    const { url } = this.endpoints.addProducts()

    const payload = {
      products: data,
    }

    try {
      const response = await this.gaApp.api.request().put(url, payload)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteProducts(data = []) {
    const { url } = this.endpoints.deleteProducts()

    const payload = {
      products: data,
    }

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params: payload,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addBrands(data = null) {
    const { url } = this.endpoints.addBrands()

    const payload = {
      id: data,
    }

    try {
      const response = await this.gaApp.api.request().put(url, payload)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addBrandsV2(data = []) {
    const { url } = this.endpoints.addBrandsV2()

    const payload = {
      brands: data,
    }

    try {
      const response = await this.gaApp.api.request().put(url, payload)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteBrands(data = null) {
    const { url } = this.endpoints.deleteBrands()

    const payload = {
      id: data,
    }

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params: payload,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async deleteBrandsV2(data = []) {
    const { url } = this.endpoints.deleteBrandsV2()

    const payload = {
      brands: data,
    }

    try {
      const response = await this.gaApp.api.request().delete(url, {
        params: payload,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getData(redirect) {
    switch (redirect.pageType) {
      case 'main':
        return this.gaApp.services.stockists.api.getPageMainData(redirect)

      default:
        return undefined
    }
  }
}

<template>
  <svg stroke="none" viewBox="0 0 15 16">
    <path
      fill-rule="evenodd"
      d="m5.884 1.624 6.384 6.384-6.384 6.384-1.768-1.768 4.616-4.616-4.616-4.616 1.768-1.768Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-home-simple-arrow-right',
}
</script>

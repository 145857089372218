import VClamp from 'vue-clamp'

import { GaIcon } from '../icon'
import { GaImageResponsive } from '../image-responsive'
import { GaNotification, SIZE } from '../notification'
import { GaProductCardAttributesColors } from '../product-card/attributes-colors'

import { TYPE } from './scripts/constants'
import { useAttributes } from './scripts/use/attributes'
import { useIcon } from './scripts/use/icon'
import { useImage } from './scripts/use/image'
import { useTextParts } from './scripts/use/text-parts'

// @vue/component
export default {
  name: 'ga-notification-product',

  components: {
    GaNotification,
    GaProductCardAttributesColors,
    GaImageResponsive,
    VClamp,
    GaIcon,
  },

  props: {
    product: {
      type: Object,
      default: null,
    },

    text: {
      type: String,
      default: null,
    },

    image: {
      type: Object,
      default: null,
    },

    imageLazy: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: TYPE.FAVORITES,
      validator: (value) => Object.values(TYPE).includes(value),
    },

    hideIcon: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },

    hasCloseButton: {
      type: Boolean,
      default: true,
    },

    testId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { hasImage } = useImage(props)

    const { iconComponent } = useIcon(props)

    const { parts } = useTextParts(props)

    const { hasColor, hasUnit, color, unit } = useAttributes(props)

    return {
      hasImage,

      iconComponent,

      parts,

      hasColor,
      hasUnit,
      color,
      unit,
    }
  },
}

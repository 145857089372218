import { render, staticRenderFns } from "./input-phone.vue?vue&type=template&id=73d3db93"
import script from "./input-phone.scripts.js?vue&type=script&lang=js&external"
export * from "./input-phone.scripts.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import state from './state'

export const categoriesTilesStore = (gaApp) => {
  const init = defineStore('brandzoneCategoriesTiles', {
    getters: getters(gaApp),
    state,
  })

  return init(getActivePinia())
}

export const getStateInitial = () => ({
  geolocationPending: false,
  countrySelectDisabled: false,
  updateDataOnSelect: false,

  citiesData: {
    cities: [],
    description: '',
    descriptionAdditional: '',
  },

  confirmCountry: {
    newCode: null,
    isOpen: false,
  },

  isOpened: false,
  isPending: false,
})

export const state = () => {
  return getStateInitial()
}

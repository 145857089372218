import { CacheService } from './cache.service'
import { MainService } from './main.service'
import { SetupService } from './setup.service'
import { VisualService } from './visual.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  visual: new VisualService(gaApp),
  setup: new SetupService(gaApp),
  cache: new CacheService(gaApp),
})

import * as components from './components'
import { locales } from './locales'
import { services } from './services'

export const advDisclaimer = {
  meta: {
    name: 'advDisclaimer',
    locales,
  },
  components,
  layers: {
    services,
  },
}

import { TYPE } from '../../constants'
import {
  GdeslonProductCartModel,
  GtmGa4ProductCartModel,
  MindboxProductCartModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ProductCartBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4ProductCartModel],
      [TYPE.MINDBOX, MindboxProductCartModel],
      [TYPE.GDESLON, GdeslonProductCartModel],
    ])
    super(models, data, country)
  }
}

export const buildSymbolsRegExp = (
  collection,
  {
    count = '+',
    wholeString = false,
    allowSpaces = false,
    allowPunctuation = false,
    flags = '',
  } = {},
) => {
  let pattern = `${wholeString ? '^' : ''}[${collection}`

  if (allowSpaces) {
    pattern += '\\s'
  }

  if (allowPunctuation) {
    pattern += `.,?!:;-—–"'()«»…`
  }

  pattern += `]${count}${wholeString ? '$' : ''}`

  return new RegExp(pattern, flags)
}

import { cmsPageStore } from './cms'
import { contactsPageStore } from './contacts'
import { launchMeStore } from './launch-me'
import { lrpEffaclarStore } from './lrp-effaclar'

export const stores = (gaApp) => {
  return {
    cms: cmsPageStore(gaApp),
    contacts: contactsPageStore(gaApp),
    launchMe: launchMeStore(gaApp),
    lrpEffaclar: lrpEffaclarStore(gaApp),
  }
}

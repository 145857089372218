function loopedClamp(value, min, max) {
  switch (true) {
    case value > max:
      return min
    case value < min:
      return max
    default:
      return value
  }
}

function regularClamp(value, min, max) {
  switch (true) {
    case value > max:
      return max
    case value < min:
      return min
    default:
      return value
  }
}

export const clamp = (
  value,
  { min, max, loop } = { min: 0, max: 1, loop: false },
) => {
  const clampFn = loop ? loopedClamp : regularClamp

  return clampFn(value, min, max)
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useAppWindowStore = () => {
  const { $gaApp } = useContext()

  const windowBreakpoints = computed(() => $gaApp.stores.app.window.breakpoints)
  const windowResizing = computed(() => $gaApp.stores.app.window.windowResizing)
  const windowSize = computed(() => $gaApp.stores.app.window.windowSize)

  return {
    windowBreakpoints,
    windowResizing,
    windowSize,
  }
}

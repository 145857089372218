export const SEARCH_PATH = '/catalogsearch/result'

export const TOOLTIP_SHOW_DELAY = 1000
export const TOOLTIP_PLACEMENT = 'bottom'
export const TOOLTIP_OFFSET_Y = 8

export const MODAL_MAX_PRODUCTS_THREE_ROWS = 9

export const SEARCH_DEBOUNCE_DELAY = 400

export const SEARCH_HEADER_BASE_HEIGHT = 170
export const SEARCH_HEADER_XL_HEIGHT = 200

export const SEARCH_HISTORY_KEY = 'searchHistory'

export const MAX_VISIBLE_HISTORY_REQUESTS = 5

export { SEARCH_BAR_CONFIG } from './search-bar'
export { MEDIA_TYPES } from './media-types'

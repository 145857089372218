import { ACTIONS, SCREEN_TYPES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OrderProductViewModel extends GtmBaseModel {
  constructor(products) {
    super({ event: ACTIONS.VIEW.GA_4 })

    this.item_list_name = SCREEN_TYPES.ACCOUNT_ORDER
    this.value = products.reduce(
      (acc, currentValue) => acc + currentValue.price,
      0,
    )
    this.ecommerce = {
      items: products,
    }
  }
}

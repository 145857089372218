import {
  buildNotificationEntityNameAsHtml,
  ENTITY,
} from '~/modules/notifications'

import { ACTION, LOCALE_PATH, TYPE } from '../constants'

export class NotificationsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  success(product, action = ACTION.ADD, type = TYPE.PRODUCTS) {
    const text = this.gaApp.i18n.t(LOCALE_PATH.SUCCESS[action])
    this.gaApp.services.notification.product.open({
      product,
      type: ENTITY.FAV,
      text,
      listeners: {
        click: () => {
          // проверяем, есть ли метод redirect'а у соответствующего типа
          // и вызываем его при клике по оповещению
          if (this.gaApp.services.favorites[type].redirectToFavorites) {
            this.gaApp.services.favorites[type].redirectToFavorites()
          }
        },
      },
    })
  }

  failure(product, action = ACTION.ADD) {
    const slotText = this.gaApp.i18n.t(LOCALE_PATH.ERROR[action], {
      product: buildNotificationEntityNameAsHtml(product),
    })

    this.gaApp.services.notification.main.open({
      slotText,
    })
  }
}

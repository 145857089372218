export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get state() {
    return this.gaApp.stores.header.main
  }

  setHeaderState({ value }) {
    this.state.headerState = value
  }

  setHeaderExtensionLockState({ value }) {
    this.state.headerExtensionLock = value
  }

  setHeaderExtendedState({ value }) {
    if (this.state.headerExtensionLock) {
      return
    }

    this.state.headerExtended = value
  }

  setHeaderMinimizedState({ value }) {
    this.state.headerMinimized = value
  }
}

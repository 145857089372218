import { TYPE } from '../../constants'
import { GaAnalyticsTitleClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class TitleClickBuilder extends BaseBuilder {
  constructor({ itemListId, itemListName, url }) {
    const models = new Map([[TYPE.GAA, GaAnalyticsTitleClickModel]])
    super(models, { itemListId, itemListName, url })
  }
}

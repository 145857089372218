export const STATE = {
  IN_PROCESS: 'in-process',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
}

export const IMAGE_URL = {
  // eslint-disable-next-line no-template-curly-in-string
  url: '/_static/img/subscription/banner_${screen}.${format}',
  format: ['jpg', 'webp'],
  screen: ['fullhd', 'tablet', 'mobile'],
}

export const CONTENT_DURATION = {
  leave: 0,
  enter: 300,
}

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4ArticleAllProductsButtonClickModel extends GtmBaseModel {
  constructor({ eventCategory, actionType, gtmListName }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = eventCategory
    this.action_type = actionType
    this.list_name = gtmListName
  }
}

/**
 * Рекурсивно ищет, есть ли вертикальные баннеры
 * @param {Object} item - nav item
 */
export const hasVerticalBanners = (item) => {
  // по дизайну если два баннера, значит они вертикальные
  if (item.banners?.length > 1) return true

  if (item.children?.length > 0) {
    return item.children.some((item) => hasVerticalBanners(item))
  }

  return false
}

import { TYPE } from '../../constants'
import { GtmFilterModel } from '../../models'
import { BaseBuilder } from '../base.builder'

export class FilterBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmFilterModel]])
    super(models, data)
  }
}

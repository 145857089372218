import isString from 'lodash/isString'

import { withMessage } from '../utils'

export default (pattern, message) => {
  return withMessage(
    (value) => {
      if (!value || !isString(value)) {
        return true
      }

      return value.match(pattern)
    },
    {
      type: 'regExp',
      message: 'app.validation.regExp',
    },
  )(message)
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const editStore = (gaApp) => {
  const init = defineStore('edit', {
    state,
    getters: getters(gaApp),
  })

  return init(getActivePinia())
}

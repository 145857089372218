export class CategoriesTilesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(
      this.gaApp.stores.brandzone.categoriesTiles.categoriesTilesData,
    ).includes(block.id)
  }

  updateBlockData(block) {
    const index =
      this.gaApp.stores.brandzone.categoriesTiles.categoriesTilesData[block.id]
        ?.index
    this.gaApp.stores.brandzone.categoriesTiles.items[index].data = block.data
  }

  addBlockData(block) {
    this.gaApp.stores.brandzone.categoriesTiles.items.push({
      id: block.id,
      data: block.data,
    })
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }

  reset() {
    this.gaApp.stores.brandzone.categoriesTiles.$reset()
  }
}

<template>
  <svg fill="none" viewBox="0 0 13 12">
    <path
      stroke="currentColor"
      stroke-width="2"
      d="m3.5 5.224 3.077 2.778 4.923-5"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-checked-mobile',
}
</script>

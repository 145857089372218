import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useUser = () => {
  const { $gaApp } = useContext()

  const userData = computed(() => $gaApp.stores.user.main.data)
  const userId = computed(() => $gaApp.stores.user.main.data.id)
  const userIsBot = computed(() => $gaApp.stores.user.main.isBot)
  const userIsAuth = computed(() => $gaApp.stores.user.main.isAuthorized)
  const userGroupId = computed(() => $gaApp.stores.user.main.data.groupId)
  const userIsAdult = computed(() => $gaApp.stores.user.main.isAdult)

  return {
    userData,
    userId,
    userIsBot,
    userIsAuth,
    userGroupId,
    userIsAdult,
  }
}

export class AdvcakeProductModel {
  constructor({ id, name, price, quantity, categoryId = null, category }) {
    this.id = id
    this.name = name
    this.price = price
    this.quantity = quantity
    this.categoryId = categoryId
    this.categoryName = category
  }
}

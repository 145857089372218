export const cartV3Endpoints = {
  getCart: () => ({
    url: '/cart/v3',
  }),
  items: () => ({
    url: '/cart/v3/items',
  }),
  item: () => ({
    url: '/cart/v3/item',
  }),
  itemByBarcode: () => ({
    url: '/cart/v3/item/barcode',
  }),
  updateItemQty: () => ({
    url: '/cart/v3/item/update-qty',
  }),
  coupon: () => ({
    url: '/cart/v3/coupon',
  }),
  recollectAvailableCoupons: () => ({
    url: '/cart/v3/recollect-available-coupons',
  }),
  reorder: () => ({
    url: `/cart/v3/reorder`,
  }),
}

import { computed, unref } from 'vue'

import { getFirstElementOrNull } from '@ga/utils'

/**
 * @typedef {Object} DataForCart
 * @property {String} itemId - id продукта (из api)
 * @property {String} name - имя продукта для отображения в нотификации
 * @property {String} brand - бренд продукта для отображения в нотификации
 * @property {Array<Object>} media - массив медиа (первое изображение будет отображено в нотификации)
 * @property {Object} attributes - объект атрибутов (первые варианты будут отражены в нотификации)
 * @property {Boolean} configurable - требует ли показа превью с вариантами
 * @property {Boolean} needOnlineConsultation - ??
 */

/**
 * Возвращает данные которые будут переданы кнопке из модуля cart
 * @param {Ref<DataForCart>} data
 */
export const useDataCart = (data) => {
  const dataCart = computed(() => {
    const {
      attributes,
      media,
      itemId,
      configurable,
      brand,
      name,
      needOnlineConsultation,
    } = unref(data) || {}

    // фильтруем, чтобы исключить видео
    const imageUrls = media
      .filter((source) => source.image)
      .map((source) => source.image)

    return {
      id: itemId,
      brand,
      name,
      attributes: attributes || undefined,
      image: getFirstElementOrNull(imageUrls),
      configurable,
      needOnlineConsultation,
    }
  })

  return {
    dataCart,
  }
}

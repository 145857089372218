export class DigiAnalyticsBrandViewModel {
  constructor({ brandName, breadcrumbs, pageProducts }) {
    this.data = {
      eventType: 'BRAND_PAGE_VIEW',
      brandName,
      breadcrumbs,
      pageProducts,
    }
  }
}

import * as components from './components'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export * from './locales'

export const captcha = {
  meta: {
    name: 'captcha',
  },
  components,
  layers: {
    stores,
    services,
    repositories,
  },
}

/**
 * @typedef {string} Type
 */

/**
 * @enum {Type}
 */

export const TYPE = {
  TEXT: 'text',
  TEL: 'tel',
  EMAIL: 'email',
  NUMBER: 'number',
  DATE: 'date',
  TIME: 'time',
}

import { sameAs } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (equalTo, message) => {
  return withMessage(sameAs(equalTo), {
    type: 'sameAs',
    eq: equalTo,
    message: 'app.validation.default',
  })(message)
}

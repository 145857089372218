import { getIntlFormatDate } from './intl-date'

/**
 * Возвращает системную таймзону в виде массива или объекта
 * https://learn.javascript.ru/intl#intl-datetimeformat
 * @returns {(string|string)[]}
 * @param {Object} options
 */
export const getIntlSystemTimezone = (options = {}) => {
  const locale = options.locale || 'ru'
  const asObject = Boolean(options.asObject)

  const cityTimezone = getIntlFormatDate(new Date(), locale, {
    timeZoneName: 'long',
  })
  const offsetTimezone = getIntlFormatDate(new Date(), locale, {
    timeZoneName: 'short',
  })

  const timeZoneName = cityTimezone.split(',')['1']?.trim()
  const offsetTimezoneName = offsetTimezone.split(',')['1']?.trim()
  const [offsetName, sign, hour] =
    offsetTimezoneName.match(/([A-Z])+|(\W)+|(\d)+/g)

  const timeOffset = hour?.length === 1 ? `0${hour}:00` : `${hour}:00`

  if (asObject) {
    return {
      timeZoneName,
      offsetName,
      longOffsetTime: `${sign}${timeOffset}`,
      shortOffsetTime: `${sign}${hour}`,
    }
  }

  return [timeZoneName, offsetName, `${sign}${timeOffset}`]
}

export const RATING = 'rating'
export const COMMENT = 'comment'
export const RECOMMENDATION = 'recommendation'

export const STEPS = [RATING, COMMENT, RECOMMENDATION]

export const FORM = {
  RATING: 0,
  COMMENT: 1,
  RECOMMENDATION: 2,
}

export const KEYS = {
  SESSION_ID: 'sessionId',
  ORDER_ID: 'orderId',
  ITEM_ID: 'itemId',
  STARS: 'stars',
  PROS: 'pros',
  CONS: 'cons',
  COMMENT: 'comment',
  IS_RECOMMENDED: 'isRecommended',
  MEDIA_IDS: 'mediaIds',
  CUSTOMER_ID: 'customerId',
}

export const initialFormData = () => ({
  [FORM.RATING]: {
    [KEYS.STARS]: 0,
  },
  [FORM.COMMENT]: {
    [KEYS.COMMENT]: '',
    [KEYS.CONS]: '',
    [KEYS.PROS]: '',
  },
  [FORM.RECOMMENDATION]: {
    [KEYS.IS_RECOMMENDED]: true,
  },
})

export const MAX_SYMBOLS_TEXTAREA = 700

export const VALIDATION_CONFIG = {
  [KEYS.STARS]: {
    MIN_VALUE: 1,
    MAX_VALUE: 5,
  },
  [KEYS.COMMENT]: {
    MAX_LENGTH: MAX_SYMBOLS_TEXTAREA,
  },
  [KEYS.CONS]: {
    MAX_LENGTH: MAX_SYMBOLS_TEXTAREA,
  },
  [KEYS.PROS]: {
    MAX_LENGTH: MAX_SYMBOLS_TEXTAREA,
  },
}

export const ROUTES = {
  SUCCESS: '/review/submit/success',
  FAILURE: '/review/submit/failure',
  FORM: '/submitreview',
}

export const REQUEST_UPLOAD_TIMEOUT = 120 * 1000

export const REASON_OPEN_AUTH_MODAL = 'login-by-review-form'

export const NEXT_STEP_DELAY = 600

export class CategoryService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  blockExists(block) {
    return Object.keys(this.gaApp.stores.home.category.categoryData).includes(
      block.id,
    )
  }

  updateBlockData(block) {
    const index = this.gaApp.stores.home.category.categoryData[block.id]?.index
    this.gaApp.stores.home.category.items[index].data = block.data
  }

  addBlockData(block) {
    this.gaApp.stores.home.category.items.push({
      id: block.id,
      data: block.data,
    })
  }

  fillStore(block) {
    if (this.blockExists(block)) {
      this.updateBlockData(block)
    } else {
      this.addBlockData(block)
    }
  }
}

import merge from 'lodash/merge'

import { SIZE } from '../constants'
import { getBaseParams } from '../helpers/base'

import { CommonService } from './common.service'

/**
 * Наследуется от `CommonService` и предоставляет
 * методы для открытия базовых уведомлений.
 */

export class MainService extends CommonService {
  constructor(gaApp) {
    super(gaApp, true)

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.gaApp.services.notification.main.closeAll()
    })
  }

  /**
   * Возвращает базовые параметры для уведомления на основе текущего
   * брейкпоинта и ширины экрана для мобильных устройств.
   */
  get paramsBaseNotification() {
    return getBaseParams({
      currentBreakpoint: this.currentBreakpoint,
      widthMobile: this.widthMobile,
      isWebview: this.gaApp.isWebview,
    })
  }

  // Возвращает функцию, которая генерирует объект с базовыми данными для уведомления
  get baseData() {
    const hasCloseButton = !this.isMobileBreakpoint
    const baseParams = this.paramsBaseNotification

    return (text = '') => ({
      data: {
        props: {
          hasCloseButton,
          size: SIZE.XS,
          text,
        },
      },

      params: {
        ...baseParams,
      },

      clean: this.needClean,
    })
  }

  // Открывает базовое уведомление с указанным текстом
  baseOpen(text) {
    const data = this.baseData(text)

    this.show(data)
  }

  // Открывает расширенное уведомление
  baseExtendedOpen(data) {
    const resultData = this.baseData()

    this.show(merge(resultData, data))
  }

  /**
   * Открывает уведомление на основе переданных данных.
   * Если `data` является объектом, вызывается `baseExtendedOpen`, иначе `baseOpen`
   */
  open(data) {
    this.gaApp.services.notification.product.needClean = true

    if (typeof data === 'object') {
      this.baseExtendedOpen(data)
    } else {
      this.baseOpen(data)
    }
  }
}

import {
  ContentElementClickBuilder,
  ContentElementLinkClickBuilder,
  ContentElementShowBuilder,
  HowToBuilder,
  MenuClickBuilder,
  PlacementTitleClickBuilder,
  ProductCardMediaBuilder,
  ShowMoreBuilder,
  ViewBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class BrandzoneAnalytics extends BaseController {
  onMounted(data) {
    this.send(ViewBuilder, data)
  }

  onShowMore(data) {
    this.send(ShowMoreBuilder, data)
  }

  onContentElementClick(data) {
    this.send(ContentElementClickBuilder, data)
  }

  onContentElementLinkClick(data) {
    this.send(ContentElementLinkClickBuilder, data)
  }

  onContentElementView(data) {
    this.send(ContentElementShowBuilder, data)
  }

  onMenuItemClick(data) {
    const normalized = this.getNormalizeMenuData(data)

    this.send(MenuClickBuilder, normalized)
  }

  // howto аналитика для длинных видео
  onActionHowTo(data) {
    this.send(HowToBuilder, data)
  }

  // Клик по тайтлу слайдера плейсмента
  onPlacementTitleClick(data) {
    this.send(PlacementTitleClickBuilder, data)
  }

  /**
   * Начало проигрывания видео на карточке продукта
   */
  onStartPlayingVideo({ productId }) {
    this.send(ProductCardMediaBuilder, {
      productId,
      action: 'start',
    })
  }

  /**
   * Окончание проигрывания видео на карточке продукта
   */
  onEndPlayingVideo({ productId }) {
    this.send(ProductCardMediaBuilder, {
      productId,
      action: 'viewed',
    })
  }
}

import { ACTIONS } from '../../../constants'

import { MindboxProductModel } from '../mindbox-product.model/mindbox-product.model'

export class MindboxCartModel {
  constructor({ id, amount, addToCart }, country) {
    this.operation = addToCart
      ? ACTIONS.ADD_TO_CART.MINDBOX[country]
      : ACTIONS.REMOVE_FROM_CART.MINDBOX[country]
    this.data = {
      addProductToList: new MindboxProductModel({ id, amount }),
    }
  }
}

export const getters = () => ({
  contentData() {
    const result = {}
    this.items.forEach((item, index) => {
      result[item.id] = {
        index,
        data: item.data,
      }
    })

    return result
  },
})

// @vue/component
export default {
  name: 'ga-product-card-name',

  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },

    brand: {
      type: String,
      default: null,
      required: true,
    },
  },
}

export const getters = (gaApp) => ({
  allReviewsCount() {
    return this.data?.allReviewsCount ?? 0
  },

  starStatistic() {
    return this.data?.starStatistic ?? []
  },

  isAvailableForReview() {
    return this.data?.isAvailableForReview ?? false
  },

  rating() {
    return this.data?.rating ?? 0
  },

  recommended() {
    return this.data?.recommended ?? 0
  },
  scorePointAttrs() {
    return {
      rating: this.rating,
      score: this.rating,
      count: this.allReviewsCount,
      size: 'l',
      view:
        gaApp.mq('tablet+') && gaApp.mq('desktop-small-') ? 'line' : 'block',
      text: gaApp.i18n.tc('review.listing.score.rating', this.allReviewsCount),
    }
  },
  onlyOneReview() {
    return this.allReviewsCount === 1
  },
})

<template>
  <svg viewBox="0 0 23 23">
    <path
      fill="none"
      stroke="#1D1D1D"
      stroke-width="1.25"
      d="M.625 11.5C.625 5.494 5.494.625 11.5.625S22.375 5.494 22.375 11.5v10.875H11.5C5.494 22.375.625 17.506.625 11.5Z"
    />
    <path fill="#1D1D1D" stroke="none" d="M9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    <path
      fill="#1D1D1D"
      stroke="none"
      d="M12.898 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
    <path
      fill="#1D1D1D"
      stroke="none"
      d="M15.797 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-chat-bubble-outline',
}
</script>

import { ACTIONS } from '~/modules/home/analytics/constants'
import { GtmBaseModel } from '~/modules/home/analytics/models/gtm/gtm-base.model/gtm-base.model'

export class GtmGa4StoriesClickModel extends GtmBaseModel {
  constructor({ eventCategory, name, storyPosition, actionType }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = eventCategory
    this.action_type = actionType
    this.name = name
    this.position = storyPosition
  }
}

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp
    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
    }
  }

  /**
   * Отправляем аналитические данные из билдеров в соответствующие метрики.
   */
  send(Builders, data) {
    new Builders(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsListingModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')
    this.category = {
      url: data.url,
      itemListName: 'search-modal',
      searchTerm: data.searchTerm || '',
    }

    this.debug = {
      key: 'search-modal-listing',
      data: {
        searchTerm: this.category.searchTerm,
      },
    }
  }
}

import { computed } from 'vue'

import { DISCLAIMER_TOOLTIP_PLACEMENT } from '../../../../../constants'

export const useTooltip = (tooltipPlacement, reversed) => {
  const { RIGHT, LEFT, AUTO } = DISCLAIMER_TOOLTIP_PLACEMENT

  const tooltipPlacementSide = computed(() => {
    if (tooltipPlacement === AUTO) {
      return reversed ? LEFT : RIGHT
    }
    return tooltipPlacement
  })

  return {
    tooltipPlacementSide,
  }
}

/**
 * Предоставляет метод `filledStore`, который заполняет store приложения, связанное с карточкой продукта (`review.card.data`), полученными данными с API.
 */

export class CardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  filledStore(fetchedData = null) {
    this.gaApp.stores.review.card.data = fetchedData
  }
}

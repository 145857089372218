import { EVENT, EVENT_CATEGORY } from '../../../constants'

export class GtmStartGenerateButtonClickModel {
  constructor({ isHintsUsed, isTextTyped }) {
    this.event = EVENT.USER_INTERACTION
    this.event_category = EVENT_CATEGORY
    this.action_type = 'click_generate_design_card'
    this.type = this.buildType(isHintsUsed, isTextTyped)
  }

  buildType(isHintsUsed, isTextTyped) {
    if (isHintsUsed && isTextTyped) {
      return 'bubble+text'
    }

    if (isHintsUsed) {
      return 'bubble'
    }

    if (isTextTyped) {
      return 'text'
    }
  }
}

import { computed, unref } from 'vue'

export const useMods = (mounted, overlaid, collapsed) => {
  const mods = computed(() => {
    return {
      mounted: unref(mounted),

      overlaid: unref(overlaid),
      collapsed: unref(collapsed),
    }
  })

  return {
    mods,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useApp = () => {
  const { $gaApp } = useContext()

  const appWithHover = computed(() => $gaApp.stores.app.main.withHover)

  const appIsSSR = computed(() => $gaApp.services.app.main.isSSR)
  const appBaseUrl = computed(() => $gaApp.services.app.main.baseUrl)

  return {
    appWithHover,

    appIsSSR,
    appBaseUrl,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { VERTICAL_CARD_SIZE } from '~/modules/product-card'

import { SIZE } from '~/components/widgets/product-slider'

export const useDefaultSliderLayout = () => {
  const { $gaApp } = useContext()

  const isDesktop = computed(() => $gaApp.mq('desktop-small+'))
  const isTablet = computed(
    () => $gaApp.mq('tablet-small+') && $gaApp.mq('desktop-small-'),
  )
  const sliderSize = computed(() => {
    if (unref(isDesktop)) {
      return SIZE.XL
    }
    if (unref(isTablet)) {
      return SIZE.M
    }
    return SIZE.S
  })

  const cardSize = computed(() => {
    if (unref(isDesktop)) {
      return VERTICAL_CARD_SIZE.L
    }
    if (unref(isTablet)) {
      return VERTICAL_CARD_SIZE.M
    }
    return VERTICAL_CARD_SIZE.S
  })

  return {
    sliderSize,
    cardSize,
  }
}

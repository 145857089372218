<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#F2F2F2" rx="2" />
    <g clip-path="url(#payment-cards-master-card-a)">
      <path fill="#FF5F00" d="M16.583 13.483h-4.057V6.01h4.057v7.473Z" />
      <path
        fill="#EB001B"
        d="M12.73 9.75c0-1.514.692-2.864 1.77-3.734a4.546 4.546 0 0 0-2.864-1.015C9.076 5.001 7 7.127 7 9.751c0 2.623 2.075 4.749 4.636 4.749a4.547 4.547 0 0 0 2.865-1.016A4.784 4.784 0 0 1 12.73 9.75Z"
      />
      <path
        fill="#F79E1B"
        d="M22.002 9.75c0 2.623-2.075 4.75-4.636 4.75a4.548 4.548 0 0 1-2.865-1.016 4.783 4.783 0 0 0 1.77-3.734 4.783 4.783 0 0 0-1.77-3.734 4.548 4.548 0 0 1 2.865-1.015c2.56 0 4.636 2.126 4.636 4.75"
      />
    </g>
    <defs>
      <clipPath id="payment-cards-master-card-a">
        <path fill="#fff" d="M0 0h15v9.474H0z" transform="translate(7 5)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-master-card',
}
</script>

import { EVENT_CATEGORIES, EVENT_TYPES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4SettingAddressModel extends GtmBaseModel {
  constructor({ actionType, screenType }) {
    super({ event: EVENT_TYPES.USER_INTERACTION })

    this.event_category = EVENT_CATEGORIES.SETTING_ADDRESS
    this.action_type = actionType
    this.screen_type = screenType
  }
}

export const AXIS = {
  X: 'x',
  Y: 'y',
}

export const DIRECTION = {
  FORWARD: 'forward',
  BACKWARD: 'backward',
}

export const FACTOR = {
  [DIRECTION.FORWARD]: 1,
  [DIRECTION.BACKWARD]: -1,
}

export const SWIPE_DIRECTION = {
  [AXIS.X]: {
    [DIRECTION.FORWARD]: 'right',
    [DIRECTION.BACKWARD]: 'left',
  },
  [AXIS.Y]: {
    [DIRECTION.FORWARD]: 'bottom',
    [DIRECTION.BACKWARD]: 'top',
  },
}

export const INDENT = {
  [AXIS.X]: '0',
  [AXIS.Y]: '0',
}

<template>
  <svg fill="none" viewBox="0 0 24 24">
    <path fill="#000" d="M.5 21.148 20.927.498l2.133 2.11-20.428 20.65z" />
    <path fill="#000" d="M21.03 23.15.5 2.622 2.62.5l20.53 20.53z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-launch-qa-cross',
}
</script>

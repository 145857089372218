/*
 * TODO:
 *  - рефакторинг пропсов currency и showCurrency
 */

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

// @vue/component
export default {
  name: 'ga-price',

  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    old: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: Boolean,
      default: false,
    },
    noStrikethrough: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'RUB',
    },
    denominator: {
      type: Number,
      default: 1,
    },
    showCurrency: {
      type: Boolean,
      default: true,
    },
    minimumFractionDigits: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()

    const valueInternal = computed(() =>
      $gaApp.services.app.currency.getNominalFormatted({
        amount: props.value,
        denominator: props.denominator,
        minimumFractionDigits: props.minimumFractionDigits,
      }),
    )

    const currencySymbol = computed(() => {
      return $gaApp.services.app.currency.getSymbol(props.currency)
    })

    const strikethrough = computed(() => {
      return props.old && !props.noStrikethrough
    })

    return {
      valueInternal,
      currencySymbol,

      strikethrough,
    }
  },
}

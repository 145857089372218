<template>
  <svg viewBox="0 0 20 21">
    <path
      d="M6.131 5.193a4.897 4.897 0 0 1 8.99 2.22l.041.467.467-.033a3.187 3.187 0 1 1 .231 6.365h-2.685v.952h2.686a4.14 4.14 0 0 0 .153-8.275A5.85 5.85 0 0 0 5.489 4.445a5.36 5.36 0 1 0-.128 10.719v-.952a4.408 4.408 0 1 1 .335-8.803l.282.021.153-.237Z"
    />
    <path
      d="m13.093 12.433-.673.674-2.153-2.153v7.152h-.952v-7.152l-2.152 2.153-.674-.674 3.302-3.302 3.302 3.302Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-upload',
}
</script>

import isString from 'lodash/isString'

import { withMessage } from '../utils'

export default withMessage(
  (value) => {
    if (!isString(value) || !value.length) {
      return true
    }

    const [lastName, firstName, middleName] = value
      .replace(/ +/g, ' ')
      .trim()
      .split(' ')

    return lastName && firstName && middleName
  },
  {
    type: 'fullName',
    message: 'app.validation.fullName',
  },
)

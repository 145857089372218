import { ApiService } from './api.service'
import { BannersService } from './banners.service'
import { CategoriesService } from './categories.service'
import { CategoryService } from './category.service'
import { ClientDaysService } from './client-days.service'
import { ContentService } from './content.service'
import { InfoService } from './info.service'
import { ListingService } from './listing.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { ProductsService } from './products.service'
import { PromoService } from './promo.service'
import { SlotsService } from './slots.service'
import { StoriesService } from './stories.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  stories: new StoriesService(gaApp),
  products: new ProductsService(gaApp),
  banners: new BannersService(gaApp),
  slots: new SlotsService(gaApp),
  category: new CategoryService(gaApp),
  info: new InfoService(gaApp),
  clientDays: new ClientDaysService(gaApp),
  listing: new ListingService(gaApp),
  content: new ContentService(gaApp),
  promo: new PromoService(gaApp),
  categoryList: new CategoriesService(gaApp),
})

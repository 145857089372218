import { FocusTrap } from 'focus-trap-vue'

// @vue/component
export default {
  name: 'ga-focus-trap',

  components: {
    FocusTrap,
  },

  model: {
    prop: 'active',
    event: 'update:active',
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    escapeDeactivates: {
      type: Boolean,
      default: false,
    },
    returnFocusOnDeactivate: {
      type: Boolean,
      default: true,
    },
    allowOutsideClick: {
      type: Boolean,
      default: true,
    },
    initialFocus: {
      type: Function,
      default: () => null,
    },
    fallbackFocus: {
      type: [Function, String],
      default: 'body',
    },
    disableFallback: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    props() {
      const { $props, initialFocus, fallbackFocus } = this

      return {
        ...$props,
        initialFocus: () => initialFocus() || this.getFallbackElement(),
        fallbackFocus,
      }
    },
  },

  methods: {
    getFallbackElement() {
      if (this.disableFallback) {
        return this.$el
      }

      return this.$el.querySelector('[data-focus-trap-target]') || this.$el
    },
  },
}

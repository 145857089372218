import { colorsValues } from './colors-values'

export const colors = {
  type: 'object',
  properties: {
    values: { ...colorsValues, required: true },
    count: { type: 'number', required: true },
  },
  additionalProperties: false,
}

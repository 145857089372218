import { ref, unref, watch } from 'vue'

import { useVideoAutoplay } from '~/modules/app'

export const useCommon = ({ props, videoRef, emit }) => {
  const isVideoRendered = ref(false)

  const { canVideoAutoplay } = useVideoAutoplay()

  const onVideoEnd = () => {
    emit('video-end')
  }

  const onVideoError = ({ error }) => {
    emit('video-error', error)
  }

  const onVideoStart = () => {
    emit('video-start')
  }

  const playVideo = ({ productId }) => {
    if (productId !== props.productId) {
      return
    }

    if (!unref(canVideoAutoplay)) {
      isVideoRendered.value = false
    } else if (!unref(isVideoRendered)) {
      isVideoRendered.value = true
    } else if (unref(videoRef)) {
      unref(videoRef).restartVideo()
    }
  }

  const stopVideo = ({ productId }) => {
    if (productId !== props.productId) {
      return
    }

    if (unref(videoRef)) {
      unref(videoRef).stop()
    }
  }

  /**
   * Если флаг canVideoAutoplay = false,
   * то не выводим компонент видео ни в одной карточке
   */
  watch(canVideoAutoplay, (value) => {
    if (value) {
      return false
    }

    return (isVideoRendered.value = false)
  })

  return {
    isVideoRendered,

    onVideoEnd,
    onVideoError,
    onVideoStart,
    playVideo,
    stopVideo,
  }
}

import { computed, unref } from 'vue'

import { prefixExists } from '../utils'

/**
 * Работает вместе useMask, иначе просто добавляет/убирает префикс при focus/blur
 * возвращает обработчики событий инпута c префиксом
 * @param {Computed<String>} value - ссылка на значение инпута
 * @param {Ref<String>} prefix - ссылка на значение prefix
 * @param {Ref<String[]>} prefixAliases - массив алиасов префикса(используется, чтобы удалить префикс при вставке)
 */

const usePrefix = (value, prefix, prefixAliases) => {
  const hasPrefix = computed(() => {
    return prefixExists(unref(prefix))
  })

  const onPrefixedInputFocus = (event) => {
    if (event.target.value === '' || event.target.value === null) {
      value.value = unref(prefix)
    }
  }

  const onPrefixedInputBlur = (event) => {
    if (
      unref(prefix).includes(event.target.value) ||
      unref(prefix).includes(unref(value))
    ) {
      value.value = ''
    }
  }

  const onPrefixedPaste = (event) => {
    event.preventDefault()
    const paste = (event.clipboardData || window.clipboardData)
      .getData('text')
      .trim()

    let cutted = paste

    /**
     * Условия для вставки телефонов из буфера обмена, чтобы маска смогла
     * автоматически убрать ненужные символы
     */
    if (unref(prefixAliases) && unref(prefixAliases).length > 0) {
      // убираем плюс
      if (
        cutted.startsWith('+') &&
        unref(prefixAliases).some((prefixAlias) => prefixAlias.startsWith('+'))
      ) {
        cutted = cutted.replace('+', '')
      }
      // заменяем восьмерку на префикс, если она не включена в тело номера
      if (
        cutted.startsWith('8') &&
        cutted.length > 10 &&
        unref(prefixAliases).includes('8')
      ) {
        cutted = cutted.replace('8', unref(prefix).replace(/\D/g, ''))
      }
    }

    value.value = cutted
  }

  return {
    hasPrefix,
    onPrefixedInputFocus,
    onPrefixedInputBlur,
    onPrefixedPaste,
  }
}

export { usePrefix }

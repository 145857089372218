import { useContext } from '@nuxtjs/composition-api'

import { computed, toRefs, unref } from 'vue'

import { STOCK_STATUS } from '../../../constants'

// @vue/component
export default {
  name: 'ga-product-card-status',

  props: {
    view: {
      type: String,
      required: true,
      validator: (value) => Object.values(STOCK_STATUS).includes(value),
    },
  },

  setup(props) {
    const { view } = toRefs(props)

    const { $gaApp } = useContext()

    const content = computed(() => {
      switch (unref(view)) {
        case STOCK_STATUS.OOS:
          return $gaApp.i18n.t('productCard.status.outOfStock')

        case STOCK_STATUS.WHOLE_STOCK_IN_CART:
          return $gaApp.i18n.t('productCard.status.wholeStockInCart')

        default:
          return null
      }
    })

    return {
      content,
    }
  },
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4CardItemBaseModel } from '../gtm-ga4-card-item-base.model/gtm-ga4-card-item-base.model'

export class GtmGa4CardClickModel extends GtmBaseModel {
  constructor({ name, position, promotionName }) {
    super('select_promotion')

    this.ecommerce = new GtmGa4CardItemBaseModel({
      name,
      position,
      promotionName,
    })
  }
}

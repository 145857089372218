<template>
  <svg fill="none" stroke="none" viewBox="0 0 54 21">
    <path
      fill="#FC3F1D"
      d="M12.114 20.223c5.451 0 9.87-4.42 9.87-9.87 0-5.452-4.419-9.872-9.87-9.872-5.452 0-9.871 4.42-9.871 9.871 0 5.452 4.42 9.871 9.87 9.871Z"
    />
    <path
      fill="#fff"
      d="M13.382 6.056h-.912c-1.672 0-2.552.847-2.552 2.096 0 1.411.609 2.073 1.857 2.92l1.032.695-2.965 4.43H7.628l2.66-3.963c-1.531-1.097-2.389-2.16-2.389-3.963 0-2.258 1.574-3.8 4.56-3.8h2.964v11.715h-2.041V6.056Z"
    />
    <path
      fill="#000"
      d="M28.171 4.447v12.177h2.141V12.28h1.044c2.795 0 4.582-1.28 4.582-3.977 0-2.453-1.663-3.855-4.565-3.855h-3.202Zm3.203 6.185h-1.062V6.094h1.132c1.504 0 2.318.683 2.318 2.208 0 1.594-.902 2.33-2.389 2.33Z"
    />
    <path
      fill="#000"
      d="M45.05 16.624 41.813 4.447h-2.707l-3.255 12.177h1.84l.69-2.663h3.768l.672 2.663h2.23Zm-6.245-4.31 1.468-5.642 1.451 5.642h-2.919Z"
    />
    <path
      fill="#000"
      d="M53.683 4.447h-2l-2.529 5.081-2.512-5.081h-2.3l3.609 6.973v5.204h2.14V11.42l3.592-6.973Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-yandex',
}
</script>

/**
 * Строит простой некриптографический хэш по строке
 * https://gist.github.com/eplawless/52813b1d8ad9af510d85
 */
export const djb2Hash = (str) => {
  let hash = 5381
  const len = str.length

  for (let i = 0; i < len; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i)
  }

  return hash >>> 0 // Ensure the result is a positive integer
}

export const djb2HashStr = (str) => djb2Hash(str).toString(16).substring(0, 8)

import flow from 'lodash/flow'

export const normalizeConfig = (config) => {
  const lowercaseKeys = (config) =>
    Object.fromEntries(
      Object.entries(config).map(([k, v]) => [k.toLowerCase(), v]),
    )

  return flow(lowercaseKeys)(config)
}

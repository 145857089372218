<template>
  <svg fill="none" stroke="none" viewBox="0 0 13 19">
    <path
      fill="#fff"
      d="M6.117 7.89.183 2.5 2.2.28 12.23 9.39 2.2 18.5.183 16.28l5.934-5.39v-3Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-arrow-cap',
}
</script>

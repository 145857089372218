import { getFirstSplitDotName } from '@ga/utils'

import { INIT_QUEUE_MODAL } from '../../constants/queue'

export const getters = (gaApp) => ({
  sortedModals() {
    return [...this.modals].sort((a, b) => {
      const indexA = INIT_QUEUE_MODAL.indexOf(getFirstSplitDotName(a))
      const indexB = INIT_QUEUE_MODAL.indexOf(getFirstSplitDotName(b))

      // если это модалка без заданного порядка, то пушим ее в конец очереди
      if (indexA === -1) {
        return 1
      }

      if (indexB === -1) {
        return -1
      }

      return indexA - indexB
    })
  },

  queueModals() {
    return this.sortedModals.filter(
      (modalId) => !this.openedModals.includes(modalId),
    )
  },

  hasModals() {
    return this.queueModals.length > 0
  },

  nextModalId() {
    return this.queueModals[0]
  },

  queueStarted() {
    return this.openedModals.length > 0
  },

  queueFinished() {
    return this.queueStarted && this.openedModals.length === this.modals.length
  },

  groupInitModals() {
    return this.sortedModals.map((modalId) => getFirstSplitDotName(modalId))
  },
})

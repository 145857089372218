import merge from 'lodash/merge'

import footer from '~/modules/footer/locales/ru-BY'
import footerOptions from '~/modules/footer/locales/ru-BY.options'
import stockists from '~/modules/stockists/locales/ru-BY'
import stockistsOptions from '~/modules/stockists/locales/ru-BY.options'

export default () => {
  return {
    ...merge(footer, footerOptions),
    ...merge(stockists, stockistsOptions),
  }
}

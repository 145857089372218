import { ALL_STORES_KEY } from '../../constants/keys'

export const getPromoStateInitial = () => ({
  cities: [],
  citiesSorted: false,
  stores: {},

  currentCity: null,
  previousCity: null,

  currentShop: ALL_STORES_KEY,
  previousShop: ALL_STORES_KEY,

  currentStores: [],
})

export default () => {
  return getPromoStateInitial()
}

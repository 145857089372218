<template>
  <svg fill="none" viewBox="0 0 11 11">
    <g clip-path="url(#gift-cards-check-a)">
      <path stroke="#fff" stroke-width="1.5" d="m1 5.5 3 3 6-6" />
    </g>
    <defs>
      <clipPath id="gift-cards-check-a">
        <path fill="#fff" d="M0 0h10v10H0z" transform="translate(.5 .5)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-check',
}
</script>

import { PAGINATION_BUTTON } from '../../constants'

export const getStreamsStateInitial = () => ({
  streams: [],
  statuses: {},
  headerImage: null,

  pages: [],

  pagination: {
    [PAGINATION_BUTTON.NEXT]: {
      pending: false,
      error: false,
      show: true,
    },
  },
})

export default () => {
  return getStreamsStateInitial()
}

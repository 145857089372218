import { ApiService } from './api.service'
import { MainService } from './main.service'
import { NavService } from './nav.service'
import { ThemeService } from './theme.service'

export const services = (gaApp) => {
  return {
    main: new MainService(gaApp),
    nav: new NavService(gaApp),
    theme: new ThemeService(gaApp),
    api: new ApiService(gaApp),
  }
}

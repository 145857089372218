<template>
  <svg fill="none" stroke="none" viewBox="0 0 49 20">
    <path
      fill="#000"
      d="M8.846 2.58c.55-.695.924-1.627.826-2.58-.807.04-1.79.536-2.36 1.231-.512.595-.965 1.567-.847 2.48.905.079 1.81-.456 2.38-1.131Z"
    />
    <path
      fill="#000"
      d="M9.66 3.888c-1.315-.079-2.433.752-3.06.752-.629 0-1.59-.712-2.63-.693-1.354.02-2.61.792-3.296 2.018-1.413 2.454-.373 6.094 1 8.092.668.989 1.472 2.077 2.531 2.038 1.001-.04 1.393-.653 2.61-.653 1.216 0 1.57.653 2.629.633 1.099-.02 1.785-.989 2.453-1.978.765-1.128 1.078-2.216 1.098-2.275-.02-.02-2.119-.832-2.138-3.265-.02-2.038 1.648-3.007 1.726-3.067-.942-1.404-2.413-1.562-2.923-1.602Z"
    />
    <path
      fill="#000"
      d="M23.184 1.128c2.858 0 4.847 1.985 4.847 4.875 0 2.9-2.03 4.896-4.918 4.896h-3.164v5.07h-2.286V1.13h5.521ZM19.95 8.965h2.622c1.99 0 3.123-1.08 3.123-2.951 0-1.872-1.132-2.942-3.112-2.942h-2.633v5.893Z"
    />
    <path
      fill="#000"
      d="M28.63 12.893c0-1.893 1.44-3.055 3.991-3.199l2.94-.174v-.833c0-1.204-.807-1.924-2.154-1.924-1.276 0-2.072.617-2.266 1.584H29.06c.122-1.954 1.775-3.394 4.429-3.394 2.602 0 4.265 1.389 4.265 3.559v7.456h-2.112v-1.78h-.051c-.623 1.204-1.98 1.965-3.388 1.965-2.103 0-3.572-1.316-3.572-3.26Zm6.93-.977v-.854l-2.643.165c-1.317.092-2.062.679-2.062 1.604 0 .946.776 1.564 1.96 1.564 1.54 0 2.745-1.07 2.745-2.479Z"
    />
    <path
      fill="#000"
      d="M39.748 19.948v-1.8c.162.041.53.041.714.041 1.02 0 1.571-.431 1.908-1.542 0-.02.194-.658.194-.669l-3.878-10.83h2.388l2.715 8.804h.04l2.715-8.804h2.327L44.85 16.534C43.932 19.156 42.87 20 40.645 20c-.183 0-.735-.021-.898-.052Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-apple-pay',
}
</script>

import {
  ACTION_TYPES,
  ACTIONS,
  EVENT_CATEGORIES,
  SCREEN_TYPES,
} from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4TabModel extends GtmBaseModel {
  constructor(tab) {
    if (!tab) {
      throw new Error('Tab is required')
    }

    super({ event: ACTIONS.TAB.GA_4 })

    this.event_category = EVENT_CATEGORIES[tab.type]

    if (ACTION_TYPES[tab.type]) {
      this.action_type = ACTION_TYPES[tab.type]
    }

    if (SCREEN_TYPES[tab.type]) {
      this.screen_type = SCREEN_TYPES[tab.type]
    }
  }
}

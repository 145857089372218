import { useContext } from '@nuxtjs/composition-api'

import { GaVisuallyHidden } from '@ga/ui-components/visually-hidden'

// @vue/component
export default {
  name: 'ga-mobile-app-schema',
  components: {
    GaVisuallyHidden,
  },
  setup() {
    const { $gaApp } = useContext()

    const apps = $gaApp.services.app.schema.mobileApp()

    return {
      apps,
    }
  },
}

const SUBMENU_DEFAULT_ITEM = {
  nuxt: true,
  level: 0,
  parent: false,
  main: true,
  additional: false,
  list: null,
  banners: null,
  href: null,
  modalName: null,
  mods: {
    parent: false,
    main: true,
    additional: false,
    parental: false,
    selected: false,
  },
}

const createSubmenuItem = (submenuItemProps) => {
  return {
    ...SUBMENU_DEFAULT_ITEM,
    ...submenuItemProps,
  }
}

export const buildSubmenuList = (propertiesList) => {
  return propertiesList.map((item) => {
    return createSubmenuItem(item)
  })
}

export const endpoints = {
  getStores: () => ({
    url: 'promo/shops',
  }),
  promotions: () => ({
    url: 'promo/sales',
  }),
  clientDays: () => ({
    url: 'promo/client-days',
  }),
  promoInfo: (id) => ({
    url: `promo/promotions/${id}`,
  }),
  redirect: (id) => ({
    url: `catalog/redirect`,
  }),
  offers: (body) => ({
    url: `promo/offers`,
  }),
}

import { commonStore } from './common'
import { cookieStore } from './cookie'
import { langSwitcherStore } from './lang-switcher'
import { mainStore } from './main'
import { pageScrollStore } from './page-scroll'
import { windowStore } from './window'

export const stores = (gaApp) => {
  return {
    main: mainStore(gaApp),
    common: commonStore(gaApp),
    cookie: cookieStore(gaApp),
    pageScroll: pageScrollStore(gaApp),
    langSwitcher: langSwitcherStore(gaApp),
    window: windowStore(gaApp),
  }
}

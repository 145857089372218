import { STATE } from '~/modules/subscription/components/organisms/subscription-modal/scripts/consts'

export const state = () => ({
  modalOpened: false,
  state: STATE.IN_PROCESS,
  isButtonLoading: false,
  form: {
    email: '',
    policy: false,
  },
})

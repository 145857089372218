import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsItemClickModel extends GaAnalyticsBaseModel {
  constructor(product) {
    super('product')

    this.item = {
      itemId: product?.id,
      itemListName: product?.itemListName,
    }

    this.debug = {
      key: 'cart-item-click',
      data: product,
    }
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const userInfo = () => {
  const { $gaApp } = useContext()

  const name = computed(() => $gaApp.services.user.main.getFullName())

  const phone = computed(() => $gaApp.services.user.main.getFormattedPhone())

  const avatar = computed(() => $gaApp.services.user.main.getAvatar())

  return {
    name,
    phone,
    avatar,
  }
}

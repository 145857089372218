import { STEP } from '../../constants/modal'

export const state = () => {
  return {
    phone: '', // Телефон для отправки в API
    phoneCode: '', // Код телефона для селекта
    phoneFormatted: '', // Телефон для вывода

    logged: false,

    // Код из смс.
    // На тесте приходит в теле ответа на запрос смс-кода
    verificationCode: '',

    step: STEP.START,
    stepError: null,

    localeChangingConfirmationCountry: null,

    pending: false,
    nextRequestDelay: 0,
  }
}

<template>
  <svg viewBox="0 0 20 20">
    <path
      stroke="none"
      d="M4.667 18.4h-.625v.625h.625V18.4zm10.666 0v.625h.625V18.4h-.625zm-8-16.4v-.625A.625.625 0 0 0 6.708 2h.625zm5.334 0h.625a.625.625 0 0 0-.625-.625V2zM4.042 4.6v13.8h1.25V4.6h-1.25zm.625 14.425h10.666v-1.25H4.667v1.25zm11.291-.625V4.6h-1.25v13.8h1.25zm-8-13.8V2h-1.25v2.6h1.25zm-.625-1.975h5.334v-1.25H7.333v1.25zM12.042 2v2.6h1.25V2h-1.25zM17.5 3.975h-15v1.25h15v-1.25z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-garbage-bin',
}
</script>

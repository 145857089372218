import objectHash from 'object-hash'

export const actions = () => ({
  resetState() {
    this.$reset()
  },

  setFilterModalDeferRendered() {
    if (!this.filterModalDeferRendered) {
      this.filterModalDeferRendered = true
    }
  },

  setFiltersModalOpened(value) {
    this.filtersModalOpened = value
  },

  setFiltersPending(value) {
    this.filtersPending = value
  },

  setFilters(value) {
    this.filters = value
  },

  setAnalytics(value) {
    this.filtersAnalytics = value
  },

  setFiltersSelected(value) {
    this.filtersSelected = value
  },

  setCountProductsFiltered(value) {
    this.countProductsFiltered = value
  },

  setCountSelectedFilters(value) {
    this.countSelectedFilters = value
  },

  setFiltersHash(value) {
    this.filtersHash = objectHash(value, { unorderedArrays: true })
  },

  setFiltersLocaly(value) {
    this.filtersLocaly = value
  },

  resetFiltersSelected() {
    this.filtersSelected = []
  },

  resetFilterSelectedById(id) {
    this.filtersSelected = this.filtersSelected.filter((item) => item.id !== id)
  },

  setFilterMobileValuesOfTrees(value) {
    this.filterMobileValuesOfTrees = value
  },

  setRequestFiltersFn(fn) {
    this.requestFiltersFn = fn
  },

  setAreHashesEqualFn(fn) {
    this.areHashesEqualFn = fn
  },

  setGetItemListNameFn(fn) {
    this.getItemListNameFn = fn
  },

  setGetAnalyticDataFn(fn) {
    this.getAnalyticDataFn = fn
  },
})

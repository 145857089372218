import { MEDIA_TYPES } from './media-types'

export const SEARCH_BAR_CONFIG = {
  [MEDIA_TYPES.DESKTOP_LARGE]: {
    initial: {
      fontSize: 60,
      letterSpacing: -0.2,
      height: 220,
      arrowWidth: 40,
    },

    squeezed: {
      fontSize: 35,
      letterSpacing: -0.4,
      height: 100,
      arrowWidth: 30,
    },

    fontGrades: [
      {
        fontSize: 60,
        letterSpacing: -0.2,
      },
      {
        fontSize: 50,
        letterSpacing: -0.2,
      },
      {
        fontSize: 45,
        letterSpacing: -0.2,
      },
      {
        fontSize: 40,
        letterSpacing: -0.2,
      },
      {
        fontSize: 35,
        letterSpacing: -0.4,
      },
    ],

    arrow: true,
    autocomplete: true,
    scrollTrigger: {
      start: 10,
      end: 700,
    },
  },
  [MEDIA_TYPES.DESKTOP]: {
    initial: {
      fontSize: 60,
      letterSpacing: -0.2,
      height: 200,
      arrowWidth: 40,
    },

    squeezed: {
      fontSize: 35,
      letterSpacing: -0.4,
      height: 80,
      arrowWidth: 30,
    },

    fontGrades: [
      {
        fontSize: 60,
        letterSpacing: -0.2,
      },
      {
        fontSize: 50,
        letterSpacing: -0.2,
      },
      {
        fontSize: 45,
        letterSpacing: -0.2,
      },
      {
        fontSize: 40,
        letterSpacing: -0.2,
      },
      {
        fontSize: 35,
        letterSpacing: -0.4,
      },
    ],

    arrow: true,
    autocomplete: true,
    scrollTrigger: {
      start: 10,
      end: 700,
    },
  },
  [MEDIA_TYPES.TABLET_LARGE]: {
    initial: {
      fontSize: 45,
      letterSpacing: -0.2,
      height: 170,
      arrowWidth: 30,
    },

    squeezed: {
      fontSize: 35,
      letterSpacing: -0.4,
      height: 80,
      arrowWidth: 30,
    },

    fontGrades: [
      {
        fontSize: 45,
        letterSpacing: -0.2,
      },
      {
        fontSize: 40,
        letterSpacing: -0.2,
      },
      {
        fontSize: 35,
        letterSpacing: -0.4,
      },
    ],

    arrow: true,
    autocomplete: true,
    scrollTrigger: {
      start: 10,
      end: 500,
    },
  },
  [MEDIA_TYPES.TABLET]: {
    initial: {
      fontSize: 45,
      letterSpacing: -0.2,
      height: 110,
      arrowWidth: 30,
    },

    squeezed: {
      fontSize: 35,
      letterSpacing: -0.4,
      height: 80,
      arrowWidth: 30,
    },

    fontGrades: [
      {
        fontSize: 45,
        letterSpacing: -0.2,
      },
    ],

    arrow: true,
    scrollTrigger: {
      start: 10,
      end: 500,
    },
    zoomFocus: true,
  },
  [MEDIA_TYPES.MOBILE]: {
    initial: {
      fontSize: 30,
      letterSpacing: -0.4,
      height: 60,
    },

    squeezed: {
      fontSize: 20,
      letterSpacing: 0,
      height: 50,
    },

    fontGrades: [
      {
        fontSize: 30,
        letterSpacing: -0.4,
      },
    ],

    clear: true,
    scrollTrigger: {
      start: 10,
      end: 300,
    },
    zoomFocus: true,
  },
}

<template>
  <svg fill="none" viewBox="0 0 14 11">
    <path stroke-width="1.25" d="M6 10 1.5 5.5 6 1M2 5.5h12" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-arrow-left',
}
</script>

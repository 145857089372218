import { SEO_ATTRS_KEY } from '~/modules/articles/constants'

export const getters = () => ({
  hero() {
    return {
      title: this.title,
      subtitle: this.subtitle,
      image: this.image,
      author: this.author,
      rubric: this.rubric,
      disclaimer: this.disclaimer,
      date: this.date,
      rawDate: this.rawDate,
      coverCaption: this.coverCaption,
      coverCaptionColor: this.coverCaptionColor,
    }
  },

  titleSeo() {
    return this.seo.find((item) => item.key === SEO_ATTRS_KEY.TITLE)?.value
  },

  descriptionSeo() {
    return this.seo.find((attr) => attr.key === SEO_ATTRS_KEY.DESCRIPTION)
      ?.value
  },

  additionalAttributesSeo() {
    return this.seo.filter(
      (attr) =>
        attr.key !== SEO_ATTRS_KEY.DESCRIPTION &&
        attr.key !== SEO_ATTRS_KEY.TITLE &&
        attr.value,
    )
  },
})

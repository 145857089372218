export const state = () => ({
  promptModal: {
    isOpen: false,
    isLoading: false,
  },
  generation: {
    timer: null,
    timeoutTimer: null,
    limit: null,
    limitMessage: null,
  },
  prompt: {
    text: '',
    hints: [],
    isHintsUsed: false,
    isTextTyped: false,
  },
})

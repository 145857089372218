export const getters = (gaApp) => ({
  card() {
    return this.isCachedReview
      ? gaApp.stores.review.listing.itemsHashTable[this.reviewId]
      : this.data
  },

  isCachedReview() {
    return this.reviewId in gaApp.stores.review.listing.itemsHashTable
  },

  isLoadedReview() {
    return this.reviewId === this.card?.reviewId
  },
})

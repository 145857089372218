import flow from 'lodash/flow'

import { computed, unref } from 'vue'

import { PIXEL_IMAGE } from '@ga/constants/pixel'

import { isValidImageProp, normalizeConfig, sortByExt } from '../../utils'
import { useImageStrategyFactory } from '../image-strategy-factory'

export const useImageResponsive = (_config, fallbackImage) => {
  if (!isValidImageProp(unref(_config))) {
    return {
      srcInternal: unref(fallbackImage) || PIXEL_IMAGE,
      sourcesInternal: [],
    }
  }

  const config = computed(() => normalizeConfig(unref(_config)))

  const { getScreensForBuild, buildSourceFallback, buildSources } =
    useImageStrategyFactory(unref(config))

  // Вычисляем fallback-картинку
  const srcInternal = computed(
    () =>
      buildSourceFallback(config) ||
      config.value?.src ||
      unref(fallbackImage) ||
      PIXEL_IMAGE,
  )

  // Вычисляем набор изображений для доступных экранов
  const sourcesInternal = computed(() =>
    getScreensForBuild().flatMap((screen) =>
      flow(buildSources, sortByExt)(screen, config),
    ),
  )

  return {
    srcInternal,
    sourcesInternal,
  }
}

export const GaModuleLocationCityInput = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationCityInput' */
    './account/city-input/city-input.vue'
  )
export const GaModuleLocationConfirmCountry = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationConfirmCountry' */
    './main/confirm-country/confirm-country.vue'
  )
export const GaModuleLocationConfirmAddress = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationConfirmAddress' */
    './main/confirm-address/confirm-address.vue'
  )
export const GaModuleLocationModal = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationModal' */
    './main/modal/modal.vue'
  )
export const GaModuleLocationSpecifyEditModal = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationSpecifyEditModal' */
    './main/address-block/specify-edit-modal/specify-edit-modal.vue'
  )
export const GaModuleLocationSpecifySelectModal = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationSpecifySelectModal' */
    './main/address-block/specify-select-modal/specify-select-modal.vue'
  )
export const GaModuleLocationDeliveryAddress = () =>
  import(
    /* webpackChunkName: 'module/location/GaModuleLocationDeliveryAddress' */
    './main/delivery-address/delivery-address.vue'
  )

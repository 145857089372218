/**
 * Метод проверяет переданное value на истинность.
 *
 * В фильтрах может использоваться идентификатор равный 0.
 * 0 - это валидный идентификатор, поэтому вариант фильтровать
 * данные с помощью Boolean нам не подходит. Нужно учитывать 0
 *
 * @param {any} value любое переданное значение
 * @returns {boolean} истино ли значение.
 */
export const isTruthyIncludingZero = (value) => {
  return Boolean(value) || value === 0
}

export const getters = (gaApp) => ({
  hasMedia() {
    return this.items?.length > 0
  },

  items() {
    return Object.values(this.media || {}).flat()
  },

  thumbs() {
    if (!this.hasMedia) {
      return []
    }
    return this.items.map(({ thumbnail }) => ({
      ...thumbnail,
      ...gaApp.services.review.seo.getImageAttributesReview(),
    }))
  },

  activeMediaItem() {
    return this.mediaForModal[this.activeIndex]
  },

  isMediaFromReviews() {
    return this.activeReviewId in gaApp.stores.review.listing.itemsHashTable
  },

  mediaForModal() {
    if (this.isMediaFromReviews) {
      const source =
        gaApp.stores.review.listing.itemsHashTable[this.activeReviewId]

      return source.imageUrls.map((image) => ({
        original: image.original,
        thumbnail: image.thumbnail,
        stars: source.stars,
        username: source.username,
        reviewId: this.activeReviewId,
      }))
    }

    return this.items
  },
})

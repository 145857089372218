import isString from 'lodash/isString'

import { withMessage } from '../utils'

export default (metadata, message) => {
  return withMessage(
    (value) => {
      if (!metadata?.lengths || !isString(value) || !value.trim()) {
        return true
      }

      const { lengths } = metadata
      const sanitizedValue = value ? value.replace(/[^\d]/g, '') : ''

      return lengths.includes(sanitizedValue.length)
    },
    {
      type: 'phone',
      metadata,
      message: 'app.validation.phone',
    },
  )(message)
}

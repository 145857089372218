import { LISTING_MAX_PAGE_INFINITE_LOADER } from '../../constants'

export const getters = () => ({
  canLoadOnIntersect() {
    return (
      this.pagination.page !== LISTING_MAX_PAGE_INFINITE_LOADER &&
      !this.pagination.end
    )
  },
})

export const NOT_SET = 'not_set'

export const VENDOR = {
  GTM: 'gtm',
  MINDBOX: 'mindbox',
  GDESLON: 'gdeslon',
  GAA: 'gaAnalytics',
  ADVCAKE: 'advcake',
  DIGI: 'digiAnalytics',
  YANDEX_METRIKA: 'yandexMetrika',
}

import { BannerService } from './banner.service'
import { MainService } from './main.service'
import { ProductService } from './product.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  product: new ProductService(gaApp),
  banner: new BannerService(gaApp),
  /*  open: (options) => {
    gaApp.services.notification.main.open(options)
  }, */
})

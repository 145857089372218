import { useContext } from '@nuxtjs/composition-api'

import { computed, nextTick, onMounted, ref, unref, watch } from 'vue'

import { updatePrependAppendDisplay } from '../utils'

/**
 * Функция обеспечивает подгонку контента по макетам
 * https://www.figma.com/file/1kenH1Y5NEV3RQvvqfDToV/GA_holiday-templates?type=design&node-id=1-4424&t=jpv3ccZ6lTQzlQA4-0
 * @param {Ref<Object>} item
 * @param {Ref<Node>} innerRef
 */
export const useContentFit = (
  item,
  innerRef,
  textRef,
  prependRef,
  appendRef,
) => {
  const { $gaApp } = useContext()

  const contentVisible = ref(false)

  const showContent = () => {
    contentVisible.value = true
  }

  const headerMinimized = computed(() => {
    return $gaApp.stores.header.main.headerMinimized
  })

  const hasIcons = computed(() => {
    return unref(item).iconLeft || unref(item).iconRight
  })

  const nodeInner = computed(() => {
    return unref(innerRef) || null
  })

  const nodeText = computed(() => {
    return unref(textRef) || null
  })

  const nodePrepend = computed(() => {
    return unref(prependRef)?.$el || null
  })

  const nodeAppend = computed(() => {
    return unref(appendRef)?.$el || null
  })

  const windowWidth = computed(() => {
    return $gaApp.stores.app.window.windowSizeLazy.innerWidth
  })

  let inited = false
  let stopWindowWidthWatcher = null

  const initContentFit = () => {
    if (inited || !unref(nodeInner) || !unref(nodeText)) return
    inited = true

    updatePrependAppendDisplay(
      unref(nodeInner),
      unref(nodeText),
      unref(nodePrepend),
      unref(nodeAppend),
      showContent,
    )

    stopWindowWidthWatcher = watch(windowWidth, () => {
      updatePrependAppendDisplay(
        unref(nodeInner),
        unref(nodeText),
        unref(nodePrepend),
        unref(nodeAppend),
      )
    })
  }

  const destroyContentFit = () => {
    if (!inited) return
    inited = false

    stopWindowWidthWatcher()
    stopWindowWidthWatcher = null
  }

  onMounted(() => {
    if (!unref(hasIcons)) {
      showContent()
      return
    }

    initContentFit()
  })

  watch([headerMinimized, hasIcons], ([headerMinimized, hasIcons]) => {
    if (headerMinimized && hasIcons) {
      destroyContentFit()
      nextTick(initContentFit)
    } else {
      destroyContentFit()
    }
  })

  return {
    contentVisible,
  }
}

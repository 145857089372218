export class LrpEffaclarService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  toggleOpenModalQuiz(value) {
    this.gaApp.stores.info.lrpEffaclar.quizModalIsOpened = value
  }

  setTestLink(value) {
    this.gaApp.stores.info.lrpEffaclar.testLink = value
  }

  openModalQuiz(link) {
    this.gaApp.services.info.lrpEffaclar.setTestLink(link)
    this.gaApp.services.info.lrpEffaclar.toggleOpenModalQuiz(true)
  }
}

import { computed, toRefs, unref } from 'vue'

import { propValidator } from '../../../utils'
import { unitsValues as schemaUnitsValues } from '../../../utils/schemas'

import { useMods } from './scripts/composables'
import { OPTION_SHIFT_COMPACT, SIZE, VISIBLE_MAX_COUNT } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-units',

  props: {
    options: {
      type: Array,
      default: () => [],
      validator: (value) => propValidator(value, schemaUnitsValues),
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    forceCompact: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { size, options, forceCompact } = toRefs(props)

    const visibleCount = computed(() =>
      Math.min(unref(options).length, VISIBLE_MAX_COUNT),
    )

    const optionsVisible = computed(() =>
      unref(options).slice(0, unref(visibleCount)),
    )

    const { mods } = useMods(size, options, forceCompact)

    const rootStyles = computed(() => ({
      paddingRight: unref(mods).compact
        ? `${
            OPTION_SHIFT_COMPACT[unref(size)] *
            (unref(optionsVisible).length - 1)
          }px`
        : null,
    }))

    const optionStyles = (index) => {
      return {
        right:
          unref(mods).compact && index > 0
            ? `${
                (unref(optionsVisible).length - index - 1) *
                OPTION_SHIFT_COMPACT[unref(size)]
              }px`
            : null,
      }
    }

    return {
      mods,
      optionsVisible,
      rootStyles,
      optionStyles,
    }
  },
}

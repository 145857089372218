import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsProductClickModel extends GaAnalyticsBaseModel {
  constructor({ product }) {
    super('product')

    const { itemId, itemListName, itemListId } = product

    this.item = {
      itemId,
      itemListName,
      itemListId,
    }

    this.debug = {
      key: 'magazine-single-product-click',
      data: product,
    }
  }
}

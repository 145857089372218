import { VIEW } from '../../constants'
import { getMapSections, getSectionItems } from '../../utils/store'
export const getters = (gaApp) => ({
  items() {
    return this.sections.flatMap(getSectionItems)
  },

  analyticItems() {
    return this.items.map((item) => item.analyticData ?? {})
  },

  mapSections() {
    return getMapSections(this.items)
  },

  itemsHashTable() {
    return this.items.reduce((acc, product) => {
      acc[product.sku] = product

      return acc
    }, {})
  },

  ids() {
    const ids = {
      defaultSection: [],
      outOfStockSection: [],
      partOfStock: [],
      wholeStock: [],
      oos: [],
      magento: [],
      product: [],
    }

    for (const {
      sku,
      errors,
      canAddMoreFromPDP,
      id,
      view,
      stockExceeded,
    } of this.items) {
      if (view === VIEW.GIFT) {
        continue
      }

      if (view === VIEW.DEFAULT) {
        ids.defaultSection.push(sku)
      }

      if (view === VIEW.OUTOFSTOCK) {
        ids.outOfStockSection.push(sku)
      }

      if (view === VIEW.DEFAULT && stockExceeded) {
        ids.partOfStock.push(sku)
      }

      if (errors?.length > 0) {
        ids.oos.push(sku)
      }

      if (canAddMoreFromPDP === false) {
        ids.wholeStock.push(sku)
      }

      ids.magento.push(id)
      ids.product.push(sku)
    }

    return ids
  },

  itemsMagentoId() {
    return this.ids.magento
  },

  itemsId() {
    return this.ids.product
  },

  hasDefaultSection() {
    return this.ids.defaultSection.length > 0
  },

  hasOutOfStockSection() {
    return this.ids.outOfStockSection.length > 0
  },

  hasPartOfStock() {
    return this.ids.partOfStock.length > 0
  },

  hasErrorOos() {
    return this.ids.oos.length > 0
  },

  onlyOutOfStock() {
    return this.ids.outOfStockSection.length === this.ids.product.length
  },

  hasError() {
    return this.errors?.length > 0 || this.onlyOutOfStock
  },

  validForOrder() {
    return !this.hasError && !this.hasOutOfStockSection
  },
})

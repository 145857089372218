<template>
  <svg fill="none" viewBox="0 0 26 26">
    <path stroke-width="1.838" d="m4.667 10.917 8.334 8.333 8.333-8.333" />
    <path stroke-width="1.838" d="M13.001 19.25V.917" />
    <path stroke-width="1.838" d="M3.834 23.836h18.333" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-export',
}
</script>

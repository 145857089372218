export const VISIBLE_MAX_COUNT = 3 // equals $visible-max-count

export const OPTION_SHIFT_COMPACT = {
  s: 4,
  m: 4,
  l: 5,
}

export const SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
}

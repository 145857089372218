import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductViewModel extends GtmBaseModel {
  constructor({ chunk, searchTermAnalytics, analyticData }) {
    if (!chunk.length) {
      throw new Error('data chunk is required')
    }

    super({ event: 'view_item_list' })

    const { listId, searchTerm, list, currency } = chunk[0]

    if (analyticData?.creativeName)
      this.creative_name = analyticData.creativeName
    if (analyticData?.creativeSlot)
      this.creative_slot = analyticData.creativeSlot
    if (analyticData?.promotionName)
      this.promotion_name = analyticData.promotionName

    this.item_list_name = list
    this.item_list_id = listId
    this.search_term = searchTermAnalytics ?? searchTerm
    this.ecommerce = {
      currency,
      items: chunk.map((product) => new GtmGa4ProductBaseModel(product)),
    }
  }
}

import { DEVICE } from '@ga/constants/device'

export const getBaseParams = ({
  currentBreakpoint,
  widthMobile,
  isWebview,
}) => {
  return (
    {
      [DEVICE.DESKTOP]: {
        indent: {
          x: '20px',
          y: '20px',
        },
        closeByClick: true,
      },
      [DEVICE.TABLET]: {
        indent: {
          x: '20px',
          y: isWebview ? '108px' : '20px',
        },
      },
      [DEVICE.MOBILE]: {
        indent: {
          x: '20px',
          y: isWebview ? '98px' : '10px',
        },
        position: 'center top',
        axis: 'y',
        direction: 'backward',
        width: widthMobile,
      },
    }[currentBreakpoint] ?? {}
  )
}

import { useContext } from '@nuxtjs/composition-api'
import { onClickOutside } from '@vueuse/core'

import { computed, onUnmounted, ref } from 'vue'

import { GaStoreSwitcherStoreList } from '../children/store-list'

// @vue/component
export default {
  name: 'ga-app-store-switcher-dropdown',

  components: { GaStoreSwitcherStoreList },

  setup() {
    const { $gaApp } = useContext()

    const rootRef = ref(null)

    const opened = computed(
      () => $gaApp.stores.app.langSwitcher.dropdown.opened,
    )

    onClickOutside(rootRef, () => {
      $gaApp.services.app.langSwitcher.hideDropdown()
    })

    onUnmounted(() => {
      $gaApp.services.app.langSwitcher.hideDropdown()
    })

    return {
      opened,
      rootRef,

      onClickOutside,
    }
  },
}

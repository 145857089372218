/**
 * Метод проверяет, является ли value переданного filter дефолтным
 *
 * В фильтрах поле значений может быть представлено в виде массива, либо примитива,
 * либо вообще не представлено. Поэтому проверяем, если значения в фильтре представлены в виде
 * массива, то итерируемся и возвращаем результат, является ли значение дефолтным или нет. Если
 * значения переданного фильтра хранятся в виде, отличном от массива, то возвращаем false, т.к. в таком
 * случае фильтр всегда не дефолтный
 *
 * @param {object} filter текущий выбранный фильтр
 * @param {object[]} filters массив фильтров из стора
 * @returns {boolean} истинно ли значение
 */

export const isDefaultValue = (filter, filters) => {
  const filterFromStore = filters.find((f) => f.id === filter.id)

  if (filterFromStore?.values) {
    return !!filterFromStore.values.find((v) => v.id === filter.value)
      ?.isDefault
  }

  return false
}

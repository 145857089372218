import { mainStore as main } from './main'
import { modalStore as modal } from './modal'
import { shareStore as share } from './share'
import { warningStore as warning } from './warning'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    modal: modal(gaApp),
    share: share(gaApp),
    warning: warning(gaApp),
  }
}

/**
 * @typedef {Object} Time
 * @property {Number} hour - час (0 - 23)
 * @property {Number} minute - минута (0 - 59)
 */

/**
 * Проверяет, является ли объект времени валидным
 * @param {Time} value - объект времени
 * @returns {Boolean}
 */
const validateTimeObject = (value) => {
  return (
    value.hour >= 0 &&
    value.hour <= 23 &&
    value.minute >= 0 &&
    value.minute <= 59
  )
}

export { validateTimeObject }

import { EVENT } from '../../constants'

export class DigiAnalyticsViewModel {
  constructor(data) {
    
    this.data = {
     
      eventType: EVENT.DIGI.view,
    }
  }
}

import { computed, ref, toRefs, unref } from 'vue'

import { propValidator } from '../../../utils'
import { coupon as schemaCoupon } from '../../../utils/schemas'

import { useCoupon } from './scripts/composables'
import { HIGHLIGHT_THEME, SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-coupon',

  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true,
      validator: (value) => propValidator(value, schemaCoupon),
    },

    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },

    highlight: {
      type: Boolean,
      default: false,
    },

    highlightTheme: {
      type: String,
      default: HIGHLIGHT_THEME.DEFAULT,
      validator: (value) => Object.values(HIGHLIGHT_THEME).includes(value),
    },
  },

  setup(props) {
    const { data, size, highlight, highlightTheme } = toRefs(props)

    const rootRef = ref(null)

    const mods = computed(() => ({
      size: unref(size),
      highlight: unref(highlight),
      highlight_theme: unref(highlightTheme),
    }))

    const { discountText } = useCoupon(data)

    return {
      rootRef,

      mods,

      discountText,
    }
  },
}

import { image } from './image'

export const labelsAdapterItem = {
  type: 'object',
  properties: {
    id: { type: 'string', required: true },
    group: { type: 'string', required: true },
    backgroundColor: { type: 'string' },
    text: { type: 'string' },
    textColor: { type: 'string' },
    textGravity: { type: 'array', items: { type: 'string' } },
    resource: {
      type: 'object',
      properties: {
        resourceType: { type: 'string' },
        resourceUrl: {
          oneOf: [
            image,
            {
              type: 'string',
            },
          ],
        },
      },
    },
  },
  additionalProperties: false,
}

export const labelsAdapter = {
  type: 'array',
  items: labelsAdapterItem,
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmSelectPriceModel extends GtmBaseModel {
  constructor({ actionType = 'default', customPriceValue, customCurrency }) {
    super({ event: 'select_card_price' })

    this.payload = {
      actionType,
      customPriceValue,
      customCurrency,
    }
  }
}

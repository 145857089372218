import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

import { NOT_SET } from '~/analytics/constants'

export class GtmSelectWhomModel extends GtmBaseModel {
  constructor({ recipient }) {
    super({ event: 'select_card_whom' })

    this.payload = {
      recipient: recipient || NOT_SET,
    }
  }
}

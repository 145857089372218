export const getters = () => ({
  categoryData() {
    const result = {}
    this.items.forEach((item, index) => {
      const name = item?.data?.name ?? ''
      const content = item?.data?.content ?? {}

      result[item.id] = {
        index,
        data: {
          name,
          ...content,
        },
      }
    })

    return result
  },
})

import get from 'lodash/get'
import merge from 'lodash/merge'

import { getDefaultConfig } from './default'
import { getConfigDevelopment } from './development'

const defaultConfig = getDefaultConfig()
const envConfig = getConfigDevelopment()

const getKeyConfig = (path) => {
  return get(defaultConfig, path)
}

const getKeyConfigDevelop = (path) => {
  const mergeConfig = merge(defaultConfig, envConfig)

  return get(mergeConfig, path)
}

export const config = {
  get:
    defaultConfig.configEnv === 'development'
      ? getKeyConfigDevelop
      : getKeyConfig,
}

import { TYPE } from '../../constants'
import { DigiAnalyticsSearchEventModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class SearchEventBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map()

    models.set(TYPE.DIGI, DigiAnalyticsSearchEventModel)

    super(models, data)
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaAction } from '@ga/ui-components/action'
import { GaImage } from '@ga/ui-components/image'

import { GaStoreSwitcherCountry } from '../children/country'

// @vue/component
export default {
  name: 'ga-app-store-switcher-trigger',

  components: {
    GaAction,
    GaImage,
    GaStoreSwitcherCountry,
  },

  setup() {
    const { $gaApp } = useContext()

    const currentStore = computed(
      () => $gaApp.services.app.langSwitcher.currentStore,
    )

    const currentLanguage = computed(
      () => $gaApp.services.app.langSwitcher.currentLanguage,
    )

    const onClick = () => {
      $gaApp.services.app.langSwitcher.showDropdown()
    }

    return {
      currentStore,
      currentLanguage,

      onClick,
    }
  },
}

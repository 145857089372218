<template>
  <svg viewBox="0 0 22 22">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M18.781 3.201A10.903 10.903 0 0 0 15.215.825 10.982 10.982 0 0 0 11 0C4.94 0 0 4.895 0 10.901c0 1.925.445 3.542 1.4 5.192L.38 22l5.875-1.317c1.61.87 2.901 1.13 4.744 1.13 6.06 0 11-4.895 11-10.901 0-2.915-1.143-5.654-3.219-7.71Zm-3.13 9.809c-.509-.246-1.326-.64-1.51-.715-.256-.088-.434-.132-.622.132-.189.275-.71.89-.866 1.067-.155.187-.322.209-.6.066a4.824 4.824 0 0 0-.157-.07c-.38-.161-1.16-.493-2.051-1.283-.822-.726-1.366-1.617-1.532-1.892-.155-.275-.022-.418.122-.561.083-.082.182-.2.278-.316.045-.054.09-.108.133-.157.122-.14.178-.254.255-.407l.022-.044c.089-.187.045-.341-.022-.473a90.072 90.072 0 0 1-.844-2.024c-.196-.467-.4-.47-.56-.471-.022 0-.042 0-.061-.002h-.533c-.189 0-.477.066-.733.34-.018.022-.04.046-.064.072-.285.31-.89.968-.89 2.206 0 1.335.977 2.626 1.119 2.813l.002.003.027.04c.267.386 2.036 2.949 4.668 4.074.655.286 1.166.45 1.565.572.655.209 1.255.176 1.732.11.533-.077 1.631-.66 1.854-1.298.233-.638.233-1.177.155-1.298-.062-.097-.182-.152-.37-.238a84.553 84.553 0 0 1-.517-.246Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-support-wa',
}
</script>

<template>
  <svg fill="none" stroke="none" viewBox="0 0 30 20">
    <g
      clip-path="url(#common-30-arrow-back-a)"
      stroke="currentColor"
      stroke-width="1.5"
    >
      <path d="M10 1 1 9.978 10 19" />
      <path d="M1 10h29" />
    </g>
    <defs>
      <clipPath id="common-30-arrow-back-a">
        <path fill="transparent" d="M0 0h30v20H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-30-arrow-back',
}
</script>

import { ACTIONS } from '../../../constants'
import { GtmBaseModel, GtmGa4ProductAddToBaseModel } from '../../index'

export class GtmGa4ProductQuickViewModel extends GtmBaseModel {
  constructor({ product }) {
    super(ACTIONS.VIEW_ITEM.GA4)

    this.pdp_type = 'quick_view'

    this.ecommerce = new GtmGa4ProductAddToBaseModel({ product })
  }
}

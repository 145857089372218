import { unref } from 'vue'

export const useAnimationHooks = (overflowVisible) => {
  const resetStyles = (element) => {
    element.style.height = ''
    element.style.overflow = ''
  }

  const toPixels = (pixels) => `${pixels}px`

  const changeHeight = (element, from, to) => {
    window.requestAnimationFrame(() => {
      element.style.height = toPixels(from)
      element.style.overflow = unref(overflowVisible) ? '' : 'hidden'

      window.requestAnimationFrame(() => {
        element.style.height = toPixels(to)
      })
    })
  }

  const onEnter = (element) => {
    changeHeight(element, 0, element.offsetHeight)
  }

  const onAfterEnter = (element) => {
    resetStyles(element)
  }

  const onLeave = (element) => {
    changeHeight(element, element.offsetHeight, 0)
  }

  const onAfterLeave = (element) => {
    resetStyles(element)
  }

  return {
    onEnter,
    onAfterEnter,
    onLeave,
    onAfterLeave,
  }
}

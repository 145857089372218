export const actions = (gaApp) => ({
  setResendCodeToken(value) {
    this.resendCodeToken = value
  },

  setIsTokenPending(value) {
    this.isTokenPending = value
  },

  setIsInit() {
    this.isInit = true
  },

  setTokenOptions({ grantType }) {
    this.grantType = grantType
  },
})

<template>
  <svg stroke="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M5.6 21.6h-.75v.75h.75v-.75Zm12.8 0v.75h.75v-.75h-.75ZM8.8 2.4v-.75a.75.75 0 0 0-.75.75h.75Zm6.4 0h.75a.75.75 0 0 0-.75-.75v.75ZM4.85 5.6v16h1.5v-16h-1.5Zm.75 16.75h12.8v-1.5H5.6v1.5Zm13.55-.75v-16h-1.5v16h1.5Zm-9.6-16V2.4h-1.5v3.2h1.5ZM8.8 3.15h6.4v-1.5H8.8v1.5Zm5.65-.75v3.2h1.5V2.4h-1.5Zm6.35 2.45H3.2v1.5h17.6v-1.5Z"
    />
    <path stroke="currentColor" stroke-width="1.5" d="M12 9.598v7.8" />
    <path stroke="currentColor" stroke-width="1.5" d="M15 9.598v7.8" />
    <path stroke="currentColor" stroke-width="1.5" d="M9 9.598v7.8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-trash',
}
</script>

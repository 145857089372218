import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4OutOfStockDialogBeforeCheckoutDeleteModel extends GtmBaseModel {
  constructor({ items }) {
    super({ event: EVENT.REMOVE_FROM_CART2.GA4 })

    this.ecommerce = {
      type: 'all',
      items,
    }
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmPayErrorModel extends GtmBaseModel {
  constructor({ errorMessage }) {
    super({ event: 'pay_gift_card_error' })

    this.buildErrorMessage(errorMessage)
  }

  buildErrorMessage(errorMessage) {
    if (errorMessage) {
      this.error_message = errorMessage
    }
  }
}

import { GaIconAdditionalBackArrow } from '@ga/icons'

import { GaAction } from '../../action'
import { GaIcon } from '../../icon'

// @vue/component
export default {
  name: 'ga-modal-back-button',

  components: {
    GaAction,
    GaIcon,
    GaIconAdditionalBackArrow,
  },
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const stepsStore = () => {
  const init = defineStore('giftCardsB2bSteps', {
    getters,
    state,
  })

  return init(getActivePinia())
}

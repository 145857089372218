import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

import { NOT_SET } from '~/analytics/constants'

export class GtmSelectTimeModel extends GtmBaseModel {
  constructor({ selectedDeliveryTime, selectedTimeZone }) {
    super({ event: 'select_card_time' })

    this.payload = {
      selectedDeliveryTime: selectedDeliveryTime || NOT_SET,
      selectedTimeZone: selectedTimeZone || NOT_SET,
    }
  }
}

import { TooltipViewBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class LabelsAnalytics extends BaseController {
  onTooltipView(event, params) {
    const data = this.getLabelsData(event, params)

    this.send(TooltipViewBuilder, data)
  }
}

export class MindboxProductModel {
  constructor({ id, price }) {
    this.product = {
      ids: {
        website: id,
      },
    }

    this.pricePerItem = price

  }
}

import { MindboxShareSetCartEventModel } from '../mindbox-share-set-cart-event.model/mindbox-share-set-cart-event.model'

export class MindboxShareSetCartModel {
  constructor({ products }) {
    if (!products) {
      throw new Error('Products are required')
    }

    this.events = [
      new MindboxShareSetCartEventModel(products)
    ]
  }
}

export const TYPE = {
  GTM_4: 'gtm_4',
  GAA: 'ga_analytics',
}

export const ACTIONS = {
  USER_INTERACTION: {
    GA4: 'user_interaction',
  },
}

export const ACTION_TYPES = {
  CLICK_BRAND: 'click_brand',
  CLICK_SEARCH: 'click_search',
  CLICK_ALPHABET: 'click_alphabet',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  ENTERING_SEARCH_PHRASE: 'entering_search_phrase',
}

export const EVENT_CATEGORIES = {
  BRANDS_LIST: 'brands_list',
}

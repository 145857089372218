import memoizee from 'memoizee'

import { MEMO_MAX_AGE_MS } from '../constants'

export class MainService {
  constructor(gaApp, recaptcha) {
    this.gaApp = gaApp
    this.recaptcha = recaptcha

    // Мемоизируем функцию запроса конфига капчи
    // https://www.npmjs.com/package/memoizee
    this.getConfig = memoizee(this.getConfig, {
      maxAge: MEMO_MAX_AGE_MS,
      promise: true,
    })
  }

  get storeMain() {
    return this.gaApp.stores.captcha.main
  }

  getConfig() {
    return this.gaApp.services.captcha.api.getConfig()
  }

  // Метод запрашивает настройки капчи, затем генерирует токен
  async generateToken() {
    // Запрос настроек и загрузка капчи
    await this.refresh()

    // Если капча включена, то сгенерировать токен
    if (this.storeMain.enabled) {
      return this.recaptcha.generateToken()
    }

    return null
  }

  // Метод загружает капчу в указанный html-элемент
  loadCaptcha(container, size = 'invisible') {
    this.recaptcha
      .setKey(this.storeMain.siteKey)
      .setRenderParameters({ container, size })

    return this.recaptcha.load()
  }

  createCaptchaContainer() {
    // Создаем div для капчи
    const div = document.createElement('div')
    div.setAttribute('id', 'recaptcha')
    div.style.visibility = 'hidden'

    // Добавляем div в тело документа
    document.body.appendChild(div)

    return div
  }

  // Метод запрашивает настройки капчи,
  // и загружает капчу, если она не была загружена
  async refresh() {
    await this.getConfig()

    // Если капча включена, и не загружена, то загружаем капчу
    if (this.storeMain.enabled && !this.storeMain.loaded) {
      try {
        await this.loadCaptcha(this.createCaptchaContainer())

        this.gaApp.stores.captcha.main.loaded = true
      } catch (error) {
        this.gaApp.stores.captcha.main.loaded = false
      }
    }
  }
}

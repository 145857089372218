<template>
  <svg fill="inherit" viewBox="0 0 34 31">
    <g
      filter="url(#video-player-rewind-animated-a)"
      fill="inherit"
      stroke="none"
    >
      <path
        fill-rule="evenodd"
        d="M26.079 7.92c-4.27-4.27-11.18-4.278-15.435-.024-2.756 2.757-3.724 6.63-2.9 10.177l1.976-1.976 1.29-1.29 1.062 1.06-1.291 1.29-3.097 3.098-.53.53-.53-.53-2.84-2.84-1.42-1.419 1.061-1.06 1.42 1.419 1.284 1.284c-.634-3.813.515-7.864 3.455-10.804 4.842-4.842 12.702-4.829 17.555.024s4.867 12.713.024 17.556a12.353 12.353 0 0 1-9.464 3.604 12.43 12.43 0 0 1-4.663-1.197l.648-1.353a10.93 10.93 0 0 0 4.1 1.052c2.994.17 6.037-.886 8.319-3.167 4.253-4.254 4.246-11.164-.024-15.434Z"
        clip-rule="evenodd"
      />
      <path
        d="M18.432 20.878c-2.17 0-3.178-1.19-3.332-2.828h1.162c.196 1.092.644 1.848 2.156 1.848 1.358 0 2.198-.91 2.198-2.422 0-1.414-.812-2.338-2.1-2.338-1.176 0-1.82.364-2.366.966h-.448v-5.362h5.628v1.05h-4.62v3.052c.406-.378 1.092-.686 2.044-.686 1.792 0 3.108 1.274 3.108 3.318 0 2.128-1.526 3.402-3.43 3.402Z"
      />
    </g>
    <defs>
      <filter
        id="video-player-rewind-animated-a"
        width="33.788"
        height="32.652"
        x=".038"
        y="-.826"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.163" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3678_4611"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3678_4611"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-rewind-animated',
}
</script>

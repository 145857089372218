import { computed, unref } from 'vue'

/**
 * Возвращает параметры корневого элемента модалки
 * @param {Ref<(String|Array)>} className
 * @param {Ref<import("../const").Position>} position
 * @param {Ref<Boolean>} noScroll - флаг скролла из props
 * @param {Ref<Object>} transitionMods
 * @param {Ref<Object>} transitionStyles
 * @param {Ref<Number>} zIndex - zIndex
 * @param {Ref<Boolean>} headerShown - видимость хедера
 * @param {Object} slots - слоты компоненты из контекста
 * @param {Function} b - бем функция контекста
 * @returns
 */
export const useRoot = (
  className,
  position,
  noScroll,
  transitionMods,
  transitionStyles,
  zIndex,
  hasHeader,
  headerShown,
  b,
) => {
  const rootClass = computed(() => {
    return [
      b({
        position: unref(position),
        scrollable: !unref(noScroll),
        'header-shown': unref(hasHeader) && unref(headerShown),
        ...transitionMods,
      }),
      unref(className),
    ]
  })

  const rootStyles = computed(() => {
    return { zIndex: unref(zIndex), ...unref(transitionStyles) }
  })

  return {
    rootClass,
    rootStyles,
  }
}

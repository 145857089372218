/*
 * Добавляет в компонент поле `id` с уникальным идентификатором
 */

let uuidCounter = 1

export default () => {
  return {
    data() {
      const uuid = uuidCounter

      uuidCounter += 1

      return {
        id: null,
        uuid: `ga-uuid-${uuid}`,
      }
    },

    mounted() {
      this.id = this.$attrs.id || this.uuid
    },
  }
}

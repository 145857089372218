import { COLOR_SCHEME, STATE } from '../constants'

export class ThemeService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get state() {
    return this.gaApp.stores.header.main
  }

  setHeaderRegularColorScheme() {
    this.state.headerColorScheme = COLOR_SCHEME.REGULAR
  }

  setHeaderTransparentColorScheme() {
    this.state.headerColorScheme = COLOR_SCHEME.TRANSPARENT
  }

  setHeaderTransparentInvertedColorScheme() {
    this.state.headerColorScheme = COLOR_SCHEME.TRANSPARENT_INVERTED
  }

  setHeaderInitialColorSet({ background, foreground, accent }) {
    this.state.headerColorScheme[STATE.INITIAL] = {
      background,
      foreground,
      accent,
    }
  }

  setHeaderScrolledColorSet({ background, foreground, accent }) {
    this.state.headerColorScheme[STATE.SCROLLED] = {
      background,
      foreground,
      accent,
    }
  }

  setHeaderActiveColorSet({ background, foreground, accent }) {
    this.state.headerColorScheme[STATE.ACTIVE] = {
      background,
      foreground,
      accent,
    }
  }
}

<template>
  <svg viewBox="0 0 15 15">
    <circle cx="7.5" cy="7.5" r="7" fill="none" />
    <path fill="none" d="m4.67 7.31 2.18 1.9 3.48-3.4" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pdp-check-sign',
}
</script>

const emptyField = () => ({
  value: '',
  error: false,
  props: {},
})

export const getStateInitial = () => ({
  list: [],
  activeId: '',
  form: {
    id: emptyField(),
    address: emptyField(),
    apartmentNumber: emptyField(),
    entranceNumber: emptyField(),
    floorNumber: emptyField(),
    intercomNumber: emptyField(),
  },
  showDeleteModal: false,
  showEditModal: false,
  showInfoModal: false,
})

export const state = () => {
  return getStateInitial()
}

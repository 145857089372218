<template>
  <svg fill="none" viewBox="0 0 18 18">
    <circle cx="9" cy="9" r="7.875" stroke="currentColor" stroke-width="1.25" />
    <path
      stroke="currentColor"
      stroke-width="1.25"
      d="M8.66 4.5v4.617L12.5 12"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-clock',
}
</script>

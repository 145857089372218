import { del as vueDel, set as vueSet } from 'vue'

import { getId } from '@ga/utils'

import { MODAL_COMPONENTS, TYPE } from '../constants'
export class ActionService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openPushModal({ modal, id }) {
    this.gaApp.stores.modal.main.stateModals[id].modals.push(modal)
    this.gaApp.eventBus.publish('module/modal/open/push', modal)
  }

  // открываем новую модалку
  openNewModal({ name, data, modal, type, id: modalId }) {
    const id = modalId || getId()

    const initData = {
      id,
      isOpening: false,
      isVisible: false,
      isFullyVisible: false,
      name,
      type,
      swipe: TYPE.SWIPE === type,
      scrollTop: 0,
      modals: [modal],
    }

    this.gaApp.stores.modal.main.queueModals.push(id)

    vueSet(this.gaApp.stores.modal.main.stateModals, id, initData)

    this.gaApp.eventBus.publish('module/modal/open/new', initData)
  }

  open({ name, data, push, type = TYPE.BASE, id }) {
    const modal = { name, data, component: MODAL_COMPONENTS[name] }
    const lastOpenedModalId = this.gaApp.stores.modal.main.lastOpenedModalId

    if (push && lastOpenedModalId) {
      this.openPushModal({ modal, id: lastOpenedModalId })
    } else {
      this.openNewModal({ name, data, modal, type, id })
    }

    this.gaApp.eventBus.publish('module/modal/open', { name, data, push, type })
    this.gaApp.eventBus.publish(`module/modal/open/${modal.name}`, {
      name,
      data,
      push,
      type,
    })
  }

  close(id) {
    const modalId = id || this.gaApp.stores.modal.main.lastOpenedModalId
    const modal = this.gaApp.stores.modal.main.stateModals[modalId]

    const hasModals = modal?.modals?.length > 0

    if (!hasModals) {
      return
    }

    this.gaApp.stores.modal.main.stateModals[modalId].modals = []

    this.gaApp.eventBus.publish('module/modal/close', modal)
    this.gaApp.eventBus.publish(`module/modal/close/${modal.name}`, modal)
  }

  closeAll() {
    this.gaApp.stores.modal.main.queueModals.forEach((id) => {
      this.close(id)
    })
  }

  // удаляем стейт модалки из стора
  delete(id) {
    this.gaApp.stores.modal.main.queueModals =
      this.gaApp.stores.modal.main.queueModals.filter(
        (modalId) => modalId !== id,
      )

    vueDel(this.gaApp.stores.modal.main.stateModals, id)

    this.gaApp.eventBus.publish('module/modal/delete', id)
  }

  // закрываем модалки без анимации
  hardCloseAll() {
    this.gaApp.stores.modal.main.queueModals.forEach((id) => {
      this.delete(id)
    })
  }

  goBack(id) {
    const modalId = id || this.gaApp.stores.modal.main.lastOpenedModalId

    if (!modalId) {
      return
    }

    this.gaApp.stores.modal.main.stateModals[modalId].modals =
      this.gaApp.stores.modal.main.stateModals[modalId].modals.slice(0, -1)

    this.gaApp.eventBus.publish('module/modal/back', modalId)
  }
}

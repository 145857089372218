<template>
  <svg viewBox="0 0 16 16">
    <path
      stroke-width="1.25"
      d="M7.548 3.627 8 4.1l.452-.473c.371-.389 1.225-1.08 2.24-1.369.964-.275 2.078-.198 3.156.93 1.083 1.133 1.168 2.326.897 3.365-.28 1.077-.947 1.975-1.317 2.363l-3.255 3.406-1.824 1.909-.35.364-.348-.365-1.824-1.908-3.254-3.406c-.37-.388-1.037-1.286-1.318-2.363-.27-1.04-.186-2.232.897-3.365 1.077-1.128 2.191-1.205 3.156-.93 1.015.29 1.868.98 2.24 1.37Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-heart-thicc',
}
</script>

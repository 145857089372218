<template>
  <svg fill="none" viewBox="0 0 15 15">
    <g
      clip-path="url(#common-15-arrow-mini-bold-a)"
      stroke="#000"
      stroke-width="1.5"
    >
      <path d="m7 13.496-6-6 6-6" />
      <path d="M1 7.496h14" />
    </g>
    <defs>
      <clipPath id="common-15-arrow-mini-bold-a">
        <path fill="#fff" d="M0 0h15v15H0z" transform="translate(0 -.004)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-arrow-mini-bold',
}
</script>

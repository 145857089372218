<template>
  <svg viewBox="0 0 12 11">
    <path d="m1.55 5.5 4.24-4.24 4.25 4.24-4.25 4.24z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-nav-icon-contacts',
}
</script>

// наблюдатель за добавленными узлами в body
export const mutationObserver = ({
  container = document.body,
  settings,
  cb,
}) => {
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (const node of mutation.addedNodes) {
        cb && cb(node)
      }
    }
  })

  const config = settings || {
    attributes: false,
    childList: true,
    characterData: true,
  }
  observer.observe(container, config)

  return {
    observer,
    stop: () => observer.disconnect && observer.disconnect(),
  }
}

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
      [TYPE.ADVCAKE]: this.core.all?.advcake,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric?.send) {
        metric.send(data)
      }
    })
  }

  getThankYouData() {
    const {
      id,
      cartId,
      orderInfo,
      orderTotal,
      products,
      shipments,
      isPickup,
      tax,
      coupon,
    } = this.gaApp.stores.checkout.thankYou

    return {
      id,
      cartId,
      orderInfo,
      orderTotal,
      products,
      shipments,
      isPickup,
      tax,
      coupon,
    }
  }
}

import { AnalyticsService } from './analytics.service'
import { ApiService } from './api.service'
import { BannerService } from './banner.service'
import { CategoriesTilesService } from './categories-tiles.service'
import { CategoryService } from './category.service'
import { LandingService } from './landing.service'
import { ListingService } from './listing.service'
import { LogoService } from './logo.service'
import { MainService } from './main.service'
import { MediaService } from './media.service'
import { PageService } from './page.service'
import { ProductsService } from './products.service'
import { PromoService } from './promo.service'
import { SliderService } from './slider.service'
import { SlotsService } from './slots.service'
import { VideoSliderService } from './video-slider.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  banner: new BannerService(gaApp),
  slots: new SlotsService(gaApp),
  page: new PageService(gaApp),
  logo: new LogoService(gaApp),
  slider: new SliderService(gaApp),
  videoSlider: new VideoSliderService(gaApp),
  products: new ProductsService(gaApp),
  listing: new ListingService(gaApp),
  category: new CategoryService(gaApp),
  categoriesTiles: new CategoriesTilesService(gaApp),
  landing: new LandingService(gaApp),
  media: new MediaService(gaApp),
  promo: new PromoService(gaApp),
  analytics: new AnalyticsService(gaApp),
})

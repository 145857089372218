export const url = {
  type: 'string',
  pattern: /^.+\$\{screen\}\.\$\{format\}$/,
}

export const screen = {
  type: 'array',
  items: { type: 'string', pattern: /^fullhd|tablet|mobile$/ },
}

export const imageFormat = {
  type: 'array',
  items: {
    type: 'string',
    pattern: /^webp|jpeg|png|jpg$/,
  },
}

export const videoFormat = {
  type: 'array',
  items: {
    type: 'string',
    pattern: /^mp4$/,
  },
}

import { computed, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { useItemEventsEmitters } from '~/modules/header/helpers/nav'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-additional',

  components: {
    GaAction,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { item } = toRefs(props)

    const style = computed(() => {
      return (
        unref(item).viewOptions?.textColor &&
        `--header-nav-item-color: ${unref(item).viewOptions.textColor}`
      )
    })

    const { onClick, onKeyDown } = useItemEventsEmitters(item, emit)

    return {
      style,

      onClick,
      onKeyDown,
    }
  },
}

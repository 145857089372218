<template>
  <svg fill="none" viewBox="0 0 6 78">
    <path
      fill="#fff"
      d="M6 35.423c0-.684-.212-1.246-.545-1.628a1.69 1.69 0 0 0-1.311-.595c-.594 0-1.002.18-1.27.538-.26.358-.407.887-.497 1.595-.065.448-.146.717-.268.871-.106.163-.27.212-.473.212-.39 0-.66-.285-.66-.871 0-.562.253-.896.774-.969v-1.213c-1.164.106-1.742.871-1.742 2.182 0 .643.187 1.172.489 1.539.317.366.749.578 1.245.578.562 0 .96-.147 1.23-.497.284-.334.44-.855.537-1.604.065-.423.146-.7.252-.87.122-.164.277-.229.505-.229.456 0 .773.375.773.96 0 .432-.114.701-.285.872-.162.17-.407.236-.667.269v1.261c.586-.032 1.058-.236 1.392-.626.334-.391.521-.97.521-1.775Z"
    />
    <path
      fill="#fff"
      d="M6 28.48c0-.716-.187-1.335-.554-1.79-.358-.457-.911-.75-1.628-.823v1.294c.75.106 1.116.61 1.116 1.335 0 .97-.692 1.515-1.906 1.515h-.065c-1.213 0-1.92-.587-1.92-1.506 0-.741.365-1.124 1.074-1.222v-1.351c-.717.065-1.246.366-1.596.814-.35.456-.521 1.075-.521 1.767 0 .822.301 1.546.822 2.06.521.52 1.27.83 2.15.83h.065c.903 0 1.636-.253 2.149-.75.52-.488.814-1.22.814-2.173Z"
    />
    <path
      fill="#fff"
      d="M.09 23.83h5.82v-1.31H3.663v-.57l2.247-1.392v-1.4l-2.474 1.538a1.78 1.78 0 0 0-.521-.854c-.253-.22-.579-.342-1.026-.342h-.033c-.594 0-1.042.22-1.335.61-.293.391-.431.953-.431 1.637v2.084Zm2.678-2.05v.74H1.099v-.74c0-.644.204-.994.798-.994h.032c.554 0 .839.334.839.993Z"
    />
    <path
      fill="#fff"
      d="M4.95 14.549c0 .48-.187.887-.521 1.172-.326.293-.806.464-1.4.464h-.066c-.586 0-1.066-.147-1.4-.431-.326-.285-.513-.676-.513-1.19 0-.512.196-.91.53-1.196.333-.268.813-.423 1.375-.423h.065c.587 0 1.075.163 1.4.431.334.285.53.684.53 1.173Zm1.05.024c0-.912-.317-1.66-.847-2.182-.529-.52-1.278-.822-2.149-.822H2.94c-.88 0-1.604.317-2.125.838-.513.53-.806 1.279-.806 2.158 0 .871.31 1.612.83 2.141.522.545 1.262.855 2.134.855h.065c.846 0 1.595-.293 2.116-.814.53-.513.847-1.262.847-2.174Z"
    />
    <path fill="#fff" d="M.09 9.432h5.82V5.744H4.877v2.37H.09v1.318Z" />
    <path fill="#fff" d="M.09 3.688h5.82V0H4.877v2.369H.09v1.319Z" />
    <path fill="#fff" d="M0 43h6v35H0z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-brandzone-scroll',
}
</script>

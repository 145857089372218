import { useContext } from '@nuxtjs/composition-api'
import { useElementHover, useFocus } from '@vueuse/core'

import { computed, unref } from 'vue'

export const useHoverFocus = (linkRef, rootRef, cartRef) => {
  const { $gaApp } = useContext()

  const withHover = computed(() => $gaApp.stores.app.main.withHover)
  const withFocusKeyboard = computed(
    () => $gaApp.stores.app.main.withFocusKeyboard,
  )

  const hovered = useElementHover(rootRef)
  const cartHovered = useElementHover(cartRef)

  const { focused } = useFocus(linkRef)

  const isHovering = computed(() => unref(withHover) && unref(hovered))

  const isHoveringExceptCart = computed(
    () => unref(isHovering) && !unref(cartHovered),
  )

  const isFocusingWithKeyboard = computed(
    () => unref(withFocusKeyboard) && unref(focused),
  )

  // определяет подстветку карточки - при фокусе или ховере
  const highlight = computed(
    () => unref(isHoveringExceptCart) || unref(isFocusingWithKeyboard),
  )

  return {
    withHover,
    isHovering,
    highlight,
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ExitClickModel extends GtmBaseModel {
  constructor({ step, type }) {
    super({})

    this.action_type = 'click_exit'
    this.type = type
    this.step = step
  }
}

import { getId } from '@ga/utils'

import { VENDOR } from '../constants'

export class DigiAnalyticsFacade {
  constructor(digiAnalytics, gaApp) {
    this.module = digiAnalytics
    this.gaApp = gaApp
  }

  get entity() {
    return VENDOR.DIGI
  }

  init() {

    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    this.setSessionId()

    const endpoints = this.gaApp.api.endpoints({
      base: () => ({
        url: 'search/analytics/digi',
      }),
    })

    return this.module.create(this.gaApp.api, endpoints)
  }

  setSessionId() {
    if (!this.gaApp.cookies.get('digi-analytics-sessionId')) {

      this.gaApp.cookies.set('digi-analytics-sessionId', getId(), {
        maxAge: 1800,
        path: '/',
        sameSite: 'lax'
      })
    }
  }

  getBaseParams() {
    this.setSessionId()

    const { to, from } = this.gaApp.services.app.main.getRouter()
    const { origin, pathname } = window.location

    const location = to ? origin + to.path : origin+ pathname
    const referer = from ? origin + from.path  : window.document.referrer


    const params = {
      sessionId: this.gaApp.cookies.get('digi-analytics-sessionId'),
      viewGUID: this.gaApp.stores.user.main.viewGUID,
      referer,
      location: location,
      channel: this.gaApp.mq('desktop-small-') ? 'WEB MOBILE' : 'WEB',
      regionId: this.gaApp.services.location.main.getDeliveryAddress().id
    }

    if (this.gaApp.stores.user.main?.data?.id) {
      params.userId = this.gaApp.stores.user.main.data.id
    }

    return params
  }

  send({ data }) {

    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const baseParams = this.getBaseParams()
    const location = data?.location ? data.location : baseParams.location
    const userGUID = this.gaApp.services.app.main.deviceId
    
    const requestData = {
      ...data,
      ...baseParams,
      location,
      userGUID
    }

    const push = () => this.module.sendAnalytics(requestData)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }
}

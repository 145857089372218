import { BLOCK_VIEWS } from './block-views'

export const CHUNK_TYPE = {
  EMPTY: 'empty',
  PLAIN: 'plain',
  DOUBLE: 'double',
}

/**
 * Таблица переходов для типов чанка.
 */
export const CHUNK_TRANSITIONS = {
  [CHUNK_TYPE.EMPTY]: {
    [BLOCK_VIEWS.NARROW]: CHUNK_TYPE.DOUBLE,
    [BLOCK_VIEWS.WIDE]: CHUNK_TYPE.PLAIN,
    [BLOCK_VIEWS.ADAPTIVE]: CHUNK_TYPE.DOUBLE,
  },
  [CHUNK_TYPE.PLAIN]: {
    [BLOCK_VIEWS.WIDE]: CHUNK_TYPE.PLAIN,
    [BLOCK_VIEWS.NARROW]: CHUNK_TYPE.DOUBLE,
    [BLOCK_VIEWS.ADAPTIVE]: CHUNK_TYPE.PLAIN,
  },
  [CHUNK_TYPE.DOUBLE]: {
    [BLOCK_VIEWS.WIDE]: CHUNK_TYPE.PLAIN,
    [BLOCK_VIEWS.NARROW]: CHUNK_TYPE.DOUBLE,
    [BLOCK_VIEWS.ADAPTIVE]: CHUNK_TYPE.DOUBLE,
  },
}

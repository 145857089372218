export const getters = (gaApp) => ({
  productSliderData() {
    const result = {}
    this.items.forEach((item, index) => {
      const content = item?.data?.content
      const additionalSection = content?.additionalSection
      const products = content?.products ?? []
      const link = content?.link ?? ''

      let linkCardData = null
      let sliderData = products

      if (products.length) {
        // если есть buttonName, то добавляем к данным по слайдам products данные слайда со ссылкой
        const buttonName = additionalSection?.buttonName ?? ''
        if (buttonName && link) {
          const title = additionalSection?.title ?? ''

          sliderData = products
          linkCardData = {
            href: link,
            buttonText: buttonName,
            title,
          }
        }
      }

      // добавляем index в изначальном массиве items стора
      const name = item?.data?.name ?? ''
      result[item.id] = {
        index,
        data: {
          name,
          link,
          products: sliderData,
          linkCardData,
        },
      }
    })

    return result
  },
})

import { GaAnalyticsBaseModel } from '../ga-analytics-base.model/ga-analytics-base.model'

export class GaAnalyticsBubbleClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { url, bubbleName, itemListName } = data

    if (data.bubbleName) {
      delete data.bubbleName
    }

    if (data.bubblePosition) {
      delete data.bubblePosition
    }

    this.category = {
      ...data,
      url,
      itemListName: `${itemListName}/${bubbleName}`,
    }

    this.debug = {
      key: 'filters-bubble-click',
      data,
    }
  }
}

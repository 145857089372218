import { computed } from 'vue'

// @vue/component
export default {
  name: 'ga-button-base',

  setup(_, { attrs }) {
    const type = computed(() => attrs.type || 'button')

    return { type }
  },
}

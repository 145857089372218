import { computed, unref } from 'vue'

/**
 * Возвращет свойства для overlay
 * @param {Ref<Object>} transitionStyles
 */
export const useOverlay = (transitionStyles) => {
  const overlayStyles = computed(() => {
    return { ...unref(transitionStyles) }
  })

  return {
    overlayStyles,
  }
}

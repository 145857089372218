import { nextTick } from 'vue'

// @vue/component
export default {
  name: 'ga-hover-gallery',

  props: {
    items: {
      type: Array,
      required: true,
    },
    keepIndex: {
      type: Boolean,
      default: false,
    },
    forceVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      renderedItemsIndexes: [0],
      loadedItemsIndexes: [],

      hoveredIndex: 0,
      visibleIndex: 0,
      hovered: false,
    }
  },

  computed: {
    itemsInternal() {
      return this.items.map((item, index) => {
        if (item.priority) {
          this.loadedItemsIndexes.push(index)
        }
        const isLoaded = this.loadedItemsIndexes.includes(index)
        const rendered = this.renderedItemsIndexes.includes(index)

        const visible =
          this.forceVisible || (this.visibleIndex === index && isLoaded)

        return { ...item, rendered, visible }
      })
    },
  },

  watch: {
    items() {
      // необходимо полностью очистить массив renderedItemsIndexes и затем запушить в него индекс === 0,
      // т.к. в случае если новый item с индексом === 0 будет идентичен предыдущему,
      // получивший тот же самый src < img > внутри слота не отправит событие 'load'
      this.renderedItemsIndexes = []
      this.loadedItemsIndexes = []

      nextTick(() => {
        this.renderedItemsIndexes.push(0)
      })
    },

    keepIndex(value) {
      if (!value) {
        this.clearIndexes()
      }
    },
  },

  methods: {
    onMouseLeave() {
      if (!this.keepIndex) {
        this.clearIndexes()
      }

      this.hovered = false
    },

    onItemMouseEnter(index) {
      const isLoaded = this.loadedItemsIndexes.includes(index)

      if (isLoaded) {
        this.visibleIndex = index
      }

      const isNotRendered = !this.renderedItemsIndexes.includes(index)

      if (isNotRendered) {
        this.renderedItemsIndexes.push(index)
      }

      this.hovered = true
      this.hoveredIndex = index
    },

    isHovered(index) {
      return this.hovered && index === this.hoveredIndex
    },

    onItemLoad(index) {
      const isHovered = index === this.hoveredIndex

      if (isHovered) {
        this.visibleIndex = index
      }

      const isNotLoaded = !this.loadedItemsIndexes.includes(index)

      if (isNotLoaded) {
        this.loadedItemsIndexes.push(index)
      }
    },

    clearIndexes() {
      this.hoveredIndex = 0
      this.visibleIndex = 0
    },
  },
}

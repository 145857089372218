class Index {
  constructor(vm) {
    this.vm = vm
  }

  async bind(el, binding) {
    await this.vm.$nextTick()

    const observerOptions = { ...binding.value?.observerOptions }

    this.interSectionObserver = new IntersectionObserver(
      this.onIntersectChange.bind(this),
      observerOptions,
    )

    this.interSectionObserver.observe(el)

    this.el = el
    this.options = {
      inView: binding.value?.inView,
      outView: binding.value?.outView,
      disposeWhen: binding.value?.disposeWhen,
    }
    this.handlerChange = binding.value?.onChange
  }

  unbind(el) {
    if (this.interSectionObserver) {
      this.interSectionObserver.unobserve(el)
    }
  }

  handlerStyle(entry) {
    const { inView, outView } = this.options

    if (entry.isIntersecting) {
      if (inView) {
        this.addStyleOptions(inView)
      }

      if (outView) {
        this.removeStyleOptions(outView)
      }
    } else {
      if (inView) {
        this.removeStyleOptions(inView)
      }
      if (outView) {
        this.addStyleOptions(outView)
      }
    }
  }

  onIntersectChange(entries) {
    const [entry] = entries

    if (!entry) {
      return
    }

    this.handlerStyle(entry)

    if (this.handlerChange) {
      this.handlerChange(entry.isIntersecting, this.el, this.options)
    }

    if (this.options.disposeWhen) {
      const shouldDispose = entry.isIntersecting === this.options.disposeWhen

      if (shouldDispose) {
        this.unbind(this.el)
      }
    }
  }

  addStyleOptions(options) {
    if (Array.isArray(options)) {
      this.el.classList.add(...options)
    } else {
      for (const prop of Object.keys(options)) {
        this.el.style[prop] = options[prop]
      }
    }
  }

  removeStyleOptions(options) {
    if (Array.isArray(options)) {
      this.el.classList.remove(...options)
    } else {
      for (const prop of Object.keys(options)) {
        this.el.style.removeProperty(prop)
      }
    }
  }
}

const intersectMap = new Map()

const bind = (el, binding, vnode) => {
  const intersect = new Index(vnode.context)

  intersectMap.set(el, intersect)
  intersect.bind(el, binding)
}

const unbind = (el, binding) => {
  const intersect = intersectMap.get(el)

  if (intersect) {
    intersect.unbind(el, binding)
  }
}

const intersect = {
  bind,
  unbind,
}

export { intersect }

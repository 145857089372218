<template>
  <svg fill="none" viewBox="0 0 52 14">
    <path d="M51 7H0" />
    <path d="m45 1 6 6-6 6" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-arrow-long-right',
}
</script>

export class RetailStoresService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchCities() {
    const { latitude, longitude } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const parameters = {
      ...(this.gaApp.features.get('setNearestCityWithStores') && {
        latitude,
        longitude,
      }),
    }

    try {
      const { data } =
        await this.gaApp.repositories.location.retailStores.getCities(
          parameters,
        )

      return data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('promo.errors.fetchCities'),
      )
    }
  }

  getBaseCity({ cities, citiesSorted }) {
    const { value: mainCityId } = this.gaApp.services.app.main.getMainCity()
    const { id: locationCityId } =
      this.gaApp.services.location.main.getDeliveryAddress()

    /*
     * если города были отсортированы (переданы координаты)
     *   выбираем первый магазин в списке (ближайший)
     * иначе
     *   ищем валидный id магазина, который есть в списке офлайн магазинов
     *   либо берем id столицы
     *   либо первый в списке магазинов
     * */
    const firstCity = cities[0]
    const firstCityId = firstCity?.value

    if (citiesSorted && firstCityId) {
      return firstCity
    }

    return (
      this.findCity(cities, locationCityId) ||
      this.findCity(cities, mainCityId) ||
      firstCity
    )
  }

  findCity(cities, id) {
    return cities.find(({ value }) => value === id)
  }
}

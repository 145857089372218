export const getStateInitial = () => ({
  isOpen: false,
  loadingHash: false,
  loadingProducts: true,
  loadingAddToCart: false,
  products: [],
})

export default () => {
  return getStateInitial()
}

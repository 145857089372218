import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const lrpEffaclarStore = () => {
  const init = defineStore('lrpEffaclarStore', {
    state,
  })

  return init(getActivePinia())
}

export const state = () => ({
  phone: null,
  email: null,
  contacts: {
    telegram: null,
    whatsapp: null,
  },
  groups: [],
  apps: [],
  socials: [],
  payments: [],
  text: null,
})

/*
 * Данная утилита преобразует ошибки vuelidate в в объект { [путь_поля]: текст_ошибки }
 * и обрабатывает шаблонные строки.
 */

import { template } from '.'

const i18nKeyRegExp = /^([A-Za-z0-9]+\.)*[A-Za-z0-9]+$/

const resetErrors = (errors) => {
  return errors.map(({ $propertyPath }) => [$propertyPath, null])
}

const buildErrors = (errors, t) => {
  return errors.map(({ $propertyPath, $message, $params }) => {
    const localizedMessage = i18nKeyRegExp.test($message)
      ? t($message)
      : $message

    return [$propertyPath, template(localizedMessage, $params)]
  })
}

export const processErrors = (v$, t) => {
  const errors = v$.$anyDirty
    ? buildErrors(v$.$errors, t)
    : resetErrors(v$.$errors)

  return Object.fromEntries(errors)
}

<template>
  <svg viewBox="0 0 20 18">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M19.5 14.623h-3.94a3.124 3.124 0 0 1-6.12 0H.5v-1.245h8.94a3.124 3.124 0 0 1 6.12 0h3.94v1.245ZM10.622 14a1.878 1.878 0 1 0 3.755 0 1.878 1.878 0 0 0-3.755 0Z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M.5 4.623V3.378h3.94a3.124 3.124 0 0 1 6.12 0h8.94v1.245h-8.94a3.124 3.124 0 0 1-6.12 0H.5ZM5.622 4a1.878 1.878 0 1 0 3.755 0 1.878 1.878 0 0 0-3.755 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-plp-filter',
}
</script>

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getConfig() {
    try {
      const { data } = await this.gaApp.repositories.captcha.main.getConfig()
      const { enabled, siteKey = null } = data

      this.gaApp.stores.captcha.main.enabled = enabled
      this.gaApp.stores.captcha.main.siteKey = siteKey
    } catch (error) {
      console.log(error)

      this.gaApp.stores.captcha.main.enabled = false
      this.gaApp.stores.captcha.main.siteKey = null
    }
  }
}

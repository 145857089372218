import { TYPE } from '../../constants'
import { GtmGa4FavoriteModel, MindboxFavoriteModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class FavoriteBuilder extends BaseBuilder {
  constructor(product, country) {
    const models = new Map([
      [TYPE.MINDBOX, MindboxFavoriteModel],
      [TYPE.GTM_4, GtmGa4FavoriteModel],
    ])
    super(models, product, country)
  }
}

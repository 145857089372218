<template>
  <svg viewBox="-1 -1 27 27">
    <path
      d="M20.036 12.5A7.535 7.535 0 0 0 12.5 4.964c-.995 0-1.943.192-2.81.541a3.004 3.004 0 1 0-4.184 4.186 7.515 7.515 0 0 0-.542 2.809c0 4.15 3.386 7.536 7.536 7.536 4.15 0 7.536-3.36 7.536-7.536ZM5.506 9.69ZM0 12.5C0 5.6 5.6 0 12.5 0S25 5.6 25 12.5 19.4 25 12.5 25 0 19.4 0 12.5Z"
    />
    <path
      d="M9.69 5.506a7.559 7.559 0 0 0-4.184 4.186A3.004 3.004 0 0 0 9.69 5.506Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-eyezone',
}
</script>

import { EVENT_CATEGORY_BTS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmBtsButtonDownloadClickModel extends GtmBaseModel {
  constructor() {
    super({ eventCategory: EVENT_CATEGORY_BTS })

    this.action_type = 'click_get_check-list'
  }
}

import isEmpty from 'lodash/isEmpty'

import { TYPE } from '../../constants'
import {
  buildBreadcrumbs,
  buildVariant,
  getProductPosition,
} from '../../helpers'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_UA]: this.core.all?.gtm,
      [TYPE.GTM_UA_SYSTEM]: this.core.all?.gtm,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
      [TYPE.GDESLON]: this.core.all?.gdeslon,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.ADVCAKE]: this.core.all?.advcake,
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
    }

    this.referer = null
    this.searchTerm = null

    this.getCurrencyService = () => this.gaApp.services.app.currency

    this.getDataForAnalytics = () => {
      const user = this.gaApp.stores.user.main.data
      const pages = this.gaApp.stores.plp.main.pages
      const category = this.gaApp.stores.plp.main.getCategoryData

      return {
        category,
        pages,
        user,
      }
    }
  }

  getItemListName(product) {
    const category = this.gaApp.stores.plp.main.getCategoryData

    // Страница с результатами поиска
    if (this.gaApp.services.plp.main.isPageTypeSearch()) {
      return this.referer ?? 'search_result'
    }

    const itemListName = this.gaApp.stores.plp.main.analyticsData?.itemListName

    // Если из сервиса аналитики пришел itemListName
    if (itemListName) {
      return itemListName
    }

    // Если URL включает brands
    if (this.gaApp.services.plp.main.isPageTypeBrandzone()) {
      // Если getItemListName() используется в контексте продукта
      if (product) {
        if (this.referer) {
          return `${this.referer}/${product.brand}`
        }

        return `брендзона/${product.brand}`
      }

      // Если getItemListName() используется в контексте категории, например в аналитике фильтров
      if (category?.name) {
        if (this.referer) {
          return `${this.referer}/${category.name}`
        }

        return `брендзона/${category.name}`
      }
    }

    // Если есть хлебные крошки
    if (!isEmpty(category.breadcrumbs)) {
      if (this.referer) {
        return `${this.referer}/${buildBreadcrumbs(category.breadcrumbs)}`
      }

      return buildBreadcrumbs(category.breadcrumbs)
    }

    return category.name
  }

  getCategoryId() {
    const category = this.gaApp.stores.plp.main.getCategoryData

    return category?.id?.toString()
  }

  getAnalyticData() {
    return this.gaApp.stores.plp.main.analyticsData
  }

  getItemListId(category, product) {
    // Если есть данные о том с какой страницы был переход
    if (this.gaApp.stores.plp.main.analyticsData?.itemListId) {
      return this.gaApp.stores.plp.main.analyticsData?.itemListId
    }

    // Если это брендзона
    if (this.gaApp.services.plp.main.isPageTypeBrandzone()) {
      const itemListName =
        this.gaApp.stores.plp.main.analyticsData?.itemListName

      // Если из сервиса аналитики пришел itemListName
      if (itemListName) {
        return itemListName
      }

      // Значение по-умолчанию
      return `брендзона/${product.brand}`
    }

    // Если нет данных о месте перехода
    return category?.id
  }

  getPrice(price) {
    return {
      amount: this.getCurrencyService().getNominal(price.actual),
      currency: price.actual.currency,
    }
  }

  getBreadcrumbsData(breadcrumbs) {
    if (Array.isArray(breadcrumbs)) {
      return {
        path: buildBreadcrumbs(breadcrumbs),
        lastHref: breadcrumbs[breadcrumbs.length - 1]?.href,
      }
    }

    return {
      path: null,
      lastHref: null,
    }
  }

  getSearchTerm() {
    return (
      this.gaApp.stores.plp.main.searchQueryCorrected ||
      this.gaApp.stores.plp.main.pageData?.params?.query ||
      ''
    )
  }

  getProductData(product, isAdded) {
    const { user, category, pages } = this.getDataForAnalytics()

    const userId = user?.id ?? null
    const email = user?.email ?? null

    const {
      itemId,
      currentView,
      name,
      brand,
      inStock,
      attributes,
      price,
      addToCart,
      index,
      placementPath,
      reviews,
    } = product

    const { amount, currency } = this.getPrice(price)

    return {
      name,
      brand,
      inStock,
      breadcrumbs: category.breadcrumbs,
      addToCart,
      userId,
      email,
      reviews,
      id: itemId,
      price: amount,
      currency,
      plpType: currentView,
      categoryId: this.getCategoryId(),
      listId: this.getItemListId(category, product),
      searchTerm: this.getSearchTerm(),
      categoryName: category.name,
      category: category.breadcrumbs && buildBreadcrumbs(category.breadcrumbs),
      variant: buildVariant(attributes),
      isAdded,
      // placementPath имеют продукты, которые выводятся в слайдерах рекомендаций
      list: placementPath || this.getItemListName(product),
      analyticData: this.getAnalyticData(),
      position: placementPath ? index + 1 : getProductPosition(itemId, pages),
    }
  }

  // Отправить данные в сервисы аналитики
  send(Builder, data) {
    const { country } = this.gaApp.i18n.locale
    new Builder(data, country).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }

  // Запросить данные из сервисов аналитики
  get(Builder, data) {
    const requests = [...new Builder(data).build()].flatMap(([key, data]) => {
      const metric = this.metrics[key]

      return metric.get
        ? [metric.get(data).then(({ data }) => ({ key, data: data.data }))]
        : []
    })

    return Promise.allSettled(requests)
  }
}

/**
 * @typedef {string} State
 */

/**
 * @enum {State}
 */

export const STATE = {
  CLOSING: 'closing',
  CLOSED: 'closed',
  OPENING: 'opening',
  OPENED: 'opened',
}

/**
 * @typedef {string} Transition
 */

/**
 * @enum {Transition}
 */

export const TRANSITION = {
  FANCY: 'fancy',
  SLIDE: 'slide',
  FADE: 'fade',
  CUT: 'cut',
}

export const TRANSITIONS_WITH_DIRECTION = [TRANSITION.FANCY, TRANSITION.SLIDE]

/**
 * @typedef {string} Position
 */

/**
 * @enum {Position}
 */

export const POSITION = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center',
}

export const BACKGROUND_COLOR_DEFAULT = '#ffffff'

/**
 * @typedef {string} CloseButtonType
 */

/**
 * @enum {CloseButtonType}
 */

export const CLOSE_BUTTON_TYPE = {
  CROSS: 'cross',
  ARROW: 'arrow',
}

import { render, staticRenderFns } from "./nav-minimized-screens-item.vue?vue&type=template&id=6355f3de"
import script from "./nav-minimized-screens-item.script.js?vue&type=script&lang=js&external"
export * from "./nav-minimized-screens-item.script.js?vue&type=script&lang=js&external"
import style0 from "./nav-minimized-screens-item.style.scss?vue&type=style&index=0&prod&module=true&lang=scss&external"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
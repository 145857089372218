import { analytics } from './analytics'
import * as components from './components'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'

export const brandzone = {
  meta: {
    name: 'brandzone',
    routes,
  },
  components,
  layers: {
    analytics,
    stores,
    services,
    repositories,
  },
}

export * from './constants'

import {
  GtmGa4UserAuthModel,
  YandexMetrikaStoreModel,
  YandexMetrikaUserAuthModel,
} from '../../models'

/**
 * Особый builder, который является исключением в флоу отправки событий аналитики
 *
 * Данные в этом builder'е отправляются до инициализации модулей аналитик,
 * поэтому мы не можем использовать стандартный способ отправки событий
 */
export class OnAppInitBuilder {
  /**
   * @param {Object} gaApp - Экземпляр gaApp
   * @param {Object} data - Данные необходимые для отправки при ините приложения
   * @property {Object} data.user - Данные авторизации пользователя
   * @property {Object} data.store - Данные страны
   */
  constructor(gaApp, data) {
    this.gaApp = gaApp
    this.data = data
  }

  /**
   * Отправляет необходимые данные при ините приложения
   */
  send() {
    this.setGtm4()
    this.setYandexMetrika()
  }

  /**
   * Отправляет данные в GTM
   */
  setGtm4() {
    // Отправляет данные авторизованного пользователя
    if (this.data.user.userId && this.data.user.isAuthorized) {
      const params = new GtmGa4UserAuthModel(this.data.user)
      window.dataLayer.push(params)
    }
  }

  /**
   * Отправляет данные в YandexMetrika
   */
  setYandexMetrika() {
    // Отправляет данные страны
    const store = new YandexMetrikaStoreModel(this.data.store)
    this.gaApp.analytics.libs.yandexMetrika.send(store)

    // Отправляет данные авторизованного пользователя
    if (this.data.user.userId && this.data.user.isAuthorized) {
      const user = new YandexMetrikaUserAuthModel(this.data.user)
      this.gaApp.analytics.libs.yandexMetrika.send(user)
    }
  }
}

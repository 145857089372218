import { useContext } from '@nuxtjs/composition-api'
import { useIntersectionObserver } from '@vueuse/core'

import { onMounted, onUnmounted, watch } from 'vue'

import { useCommon } from '../common'

/**
 * Стратегия для управления воспроизведением видео вручную по event-bus.
 *
 * @param {Object} props — объект свойств.
 * @param {Object} videoRef — объект ссылки на видео-компонент.
 * @param {Function} emit — функция для генерации событий.
 * @return {Object} Объект, содержащий определенные функции, связанные с видео.
 */
export const useManualStrategy = ({ props, videoRef, emit }) => {
  const { $gaApp } = useContext()

  const {
    isVideoRendered,

    playVideo,
    stopVideo,
    onVideoEnd,
    onVideoError,
    onVideoStart,
  } = useCommon({
    props,
    videoRef,
    emit,
  })

  useIntersectionObserver(
    videoRef,
    ([{ intersectionRatio, isIntersecting }]) =>
      emit('video-intersect', { intersectionRatio, isIntersecting }),
    {
      threshold: [0, 0.7, 0.75, 0.8, 0.85, 0.9, 0.93, 0.95, 0.98, 0.97, 1],
    },
  )

  onMounted(() => {
    $gaApp.services.app.eventBus.on('module/product-card/video/play', playVideo)
    $gaApp.services.app.eventBus.on('module/product-card/video/stop', stopVideo)
  })

  onUnmounted(() => {
    $gaApp.services.app.eventBus.off(
      'module/product-card/video/play',
      playVideo,
    )
    $gaApp.services.app.eventBus.off(
      'module/product-card/video/stop',
      stopVideo,
    )
  })

  watch(
    () => props.isHovered,
    (val) => {
      if (props.source.video) {
        emit('video-hover', { hovered: val })
      }
    },
  )

  // скрытие/отображение слайда при пролистывании
  watch(
    () => props.isVisible,
    (val) => {
      if (props.source.video) {
        emit('video-swipe', { visible: val })
      }
    },
  )

  return {
    isVideoRendered,
    onVideoEnd,
    onVideoError,
    onVideoStart,
  }
}

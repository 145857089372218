import { EVENT } from '../../../constants'
import { formatArrayAsString } from '@ga/utils'

export class DigiAnalyticsProductViewModel {
  constructor(product) {
    
    const { origin } = window.location
    
    this.data = {

      eventType: EVENT.DIGI.view,
      availability: product.inStock,
      productId: product.id,
      location: origin + product.url,
      productName: formatArrayAsString([product.productType,product.brand,product.name], ' | '),
    }
  }
}

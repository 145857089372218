import { computed, ref } from 'vue'

import { AXIS, FACTOR } from '../const'

export const useSwipeAnimation = (
  refSwiper,
  axis,
  direction,
  resetDuration,
  resetEasing,
  threshold,
) => {
  const isAxisX = computed(() => axis.value === AXIS.X)

  const swiperSize = computed(() => {
    const $el = refSwiper.value
    return isAxisX.value ? $el.offsetWidth : $el.offsetHeight
  })

  const swipeThreshold = computed(() => threshold.value * swiperSize.value)

  const swiperOffset = ref(null)

  const factor = computed(() => FACTOR[direction.value])

  const getSwiperOffset = (diff) => {
    if (diff * factor.value < 0) {
      return Math.sqrt(Math.abs(diff) / 10) * Math.sign(diff)
    }

    return diff
  }

  const setSwiperOffset = (swipeLengthX, swipeLengthY) => {
    const temporaryDiff = isAxisX.value ? swipeLengthX : swipeLengthY
    const diff = temporaryDiff.value * -1

    swiperOffset.value = getSwiperOffset(diff)
  }

  const resetSwiperOffset = () => {
    swiperOffset.value = 0
  }

  const style = computed(() => {
    if (swiperOffset.value === 0) {
      const transition = `transform ${resetDuration.value}ms ${resetEasing.value}`

      return { transition }
    }

    const transform = `translate${axis.value}(${swiperOffset.value}px)`

    return { transform }
  })

  return {
    style,

    swipeThreshold,
    swiperOffset,

    setSwiperOffset,
    resetSwiperOffset,
  }
}

import { KEYS } from '../constants/filters'

/**
 * Предоставляет методы для управления фильтрами отзывов:

`changeValue({ key, value })` - изменяет значение фильтра в store filters в зависимости от переданного ключа (key).

`change({ key, value, checked })` - вызывает changeValue с переданными key и value, а затем применяет фильтры в списке отзывов (listing) и отправляет аналитические данные о применении фильтра.

`filledStore(fetchedData)` - заполняет store filters значениями из fetchedData.
 */

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // переключение фильтров происходит по типу радиобатонов
  changeValue({ key, value }) {
    const { filters } = this.gaApp.stores.review
    const { MEDIA, FILTERS, ALL } = KEYS

    switch (key) {
      case MEDIA:
        filters.value[MEDIA] = true
        filters.value[FILTERS] = []
        break

      case FILTERS:
        filters.value[FILTERS] = [value]
        filters.value[MEDIA] = false
        break

      case ALL:
        filters.value = {
          [MEDIA]: false,
          [FILTERS]: [],
        }
        break

      default:
        return null
    }
  }

  change({ key, value, checked }) {
    if (checked) {
      return
    }
    this.changeValue({ key, value })
    this.gaApp.services.review.listing.applyFilters()

    this.gaApp.analytics.modules.review.onApplyFilter()
  }

  filledStore(fetchedData) {
    if (!fetchedData) {
      return
    }

    this.gaApp.stores.review.filters.stars = fetchedData.filters
    this.gaApp.stores.review.filters.media = fetchedData.hasMedia
  }
}

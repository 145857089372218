import { requiredIf } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (locator, message) => {
  return withMessage(requiredIf(locator), {
    type: 'requiredIf',
    message: 'app.validation.required',
  })(message)
}

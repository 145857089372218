import { getCountryCode } from '.'

/**
 * Возвращает коды номеров телефона для определенной локали по плану нумерации:
 *
 * - [ru-RU](https://ru.wikipedia.org/wiki/%D0%A2%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9_%D0%BF%D0%BB%D0%B0%D0%BD_%D0%BD%D1%83%D0%BC%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8)
 * - [ru-BY](https://ru.wikipedia.org/wiki/%D0%A2%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9_%D0%BF%D0%BB%D0%B0%D0%BD_%D0%BD%D1%83%D0%BC%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%91%D0%B5%D0%BB%D0%BE%D1%80%D1%83%D1%81%D1%81%D0%B8%D0%B8)
 * - [ru-KZ](https://ru.wikipedia.org/wiki/%D0%A2%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D0%BD%D1%8B%D0%B9_%D0%BF%D0%BB%D0%B0%D0%BD_%D0%BD%D1%83%D0%BC%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%9A%D0%B0%D0%B7%D0%B0%D1%85%D1%81%D1%82%D0%B0%D0%BD%D0%B0)
 *
 * @param {string} localeNameIso iso-код локали страны
 * @returns
 */
export const getPhoneRegionCodes = (localeNameIso) => {
  const countryCode = getCountryCode(localeNameIso)

  if (!countryCode) {
    return null
  }

  const codes = {
    RU: [3, 4, 8, 9],
    BY: [1, 2, 3, 4],
    KZ: [3, 6, 7, 8],

    // todo: Добавить номера для других стран
    // 'QA': [],
  }[countryCode]

  return codes || null
}

import { TYPE } from '../../constants'
import { GtmRouteModel } from '../../models'
import { BaseBuilder } from '../base.builder'

export class RouteBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmRouteModel]])
    super(models, data)
  }
}

import { TARGET } from '../../../../constants/common'
import { SOURCE, VIEW } from '../../../constants'

export class GtmBaseModel {
  constructor({ event, source, view, isEyezonEnabled, reviewStatistics }) {
    this.event = event
    this.event_source = source || SOURCE
    this.pdp_type = view === TARGET.PREVIEW ? VIEW.PREVIEW : VIEW.BASE

    if (isEyezonEnabled !== undefined && this.pdp_type === VIEW.BASE) {
      this.live_shopping = isEyezonEnabled
    }
    // статистика по отзывам только на главной карточке продукта
    if (this.pdp_type === VIEW.BASE && reviewStatistics) {
      const { rating, recommended, allReviewsCount } = reviewStatistics

      this.rating = rating || 0
      this.recommend = (recommended || 0) / 100
      this.reviews = allReviewsCount || 0
    }
  }
}

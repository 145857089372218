import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/en'
import layoutsEnQa from '~/locales/languages/layouts/en-QA'

export default () => {
  return {
    ...merge(layouts, layoutsEnQa),
  }
}

import { GtmBaseModel } from '../base.model'
export class GtmOpenFormModel extends GtmBaseModel {
  constructor({ sku, source } = {}) {
    super({ sku, action: 'to_write_a_review' })

    if (source) {
      this.source = source
    }
  }
}

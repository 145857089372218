<template>
  <svg stroke="none" preserveAspectRatio="none" viewBox="0 0 1245 1245">
    <path
      fill="inherit"
      d="M1052.42 547.009c41.65 237.488-117.117 463.771-354.604 505.411-237.487 41.65-463.768-117.116-505.412-354.603-41.645-237.488 117.117-463.769 354.605-505.413 237.487-41.644 463.771 117.118 505.411 354.605Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-green-blob',
}
</script>

import { useTransitionProps } from '@ga/use/transition-props'

import { GaAction } from '@ga/ui-components/action'

import { GaImageAdult } from '~/components/image-adult'
import { GaImageResponsive } from '~/components/image-responsive'

import { DURATION } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-nav-banners-tablet',

  components: {
    GaAction,
    GaImageAdult,
    GaImageResponsive,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    return {
      transitionProps: useTransitionProps(),
    }
  },

  data() {
    return {
      duration: DURATION,
    }
  },

  computed: {
    key() {
      return this.items.map((item) => item.id).join('-')
    },
    isSmallCard() {
      return this.items.length > 1
    },
  },
}

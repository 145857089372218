export class BannersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fillStore(block) {
    // videoUrl необходим для корректной работы баннеров
    this.gaApp.stores.home.banners.items = (block.data?.banners ?? []).map(
      (item) => {
        return {
          ...item,
          media: {
            ...item.media,
            videoUrl: item.media.videoUrl ?? {},
          },
        }
      },
    )
  }
}

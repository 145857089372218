import { useContext } from '@nuxtjs/composition-api'

import { computed, toRefs } from 'vue'

import { scrollLockFillGap } from '@ga/shared-directives'

import { GaPortalTarget } from '@ga/ui-components/portal-target'

import { useItems } from '~/modules/header/composable/use'

import GaHeaderNavFullExtension from './children/nav-full-extension'
import { GaHeaderNavFullItem } from './children/nav-full-item'
import { useEvents } from './scripts/events'

// @vue/component
export default {
  name: 'ga-header-nav-full',

  directives: {
    scrollLockFillGap,
  },

  components: {
    GaHeaderNavFullItem,
    GaHeaderNavFullExtension,
    GaPortalTarget,
  },

  props: {
    collapsed: {
      type: Boolean,
      defaut: false,
    },
    path: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { path } = toRefs(props)
    const { $gaApp } = useContext()
    const navFull = computed(() => $gaApp.stores.header.nav.navFull)
    const { items } = useItems(path, navFull, false)

    const isItemExpanded = (item) => {
      return $gaApp.services.header.nav.getIsItemExpanded(item)
    }

    const isItemRendered = (item) => {
      return $gaApp.services.header.nav.getIsItemRendered(item)
    }

    return {
      items,
      isItemExpanded,
      isItemRendered,
      ...useEvents(),
    }
  },
}

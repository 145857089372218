import { TYPE } from '../../constants'
import {
  GaAnalyticsOrderProductClickModel,
  GtmGa4OrderProductClickModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class OrderProductClickBuilder extends BaseBuilder {
  constructor(item) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4OrderProductClickModel],
      [TYPE.GAA, GaAnalyticsOrderProductClickModel],
    ])
    super(models, item)
  }
}

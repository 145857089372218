import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

import { getId } from '@ga/utils'

import { KEYS, ROUTES, STEPS } from '../constants/form'

/**
 * Предоставляет методы для управления формой отзыва:

`steps` - возвращает массив объектов со значениями шагов формы.

`startNewForm()` - инициализирует новую форму, устанавливая sessionId и createdAt в store формы.

`changeStep(step)` - изменяет текущий шаг формы, если переданный step находится в допустимом диапазоне.

`nextStep()` - переходит к следующему шагу формы, если пользователь является валидным.

`prevStep()` - переходит к предыдущему шагу формы.

`preparedFormData()` - подготавливает данные формы для отправки, объединяя данные из различных store (form, products, media) в один объект.

`changeValue({ name, value, form })` - изменяет значение поля формы с указанным именем и формой в store формы.

`getValue({ name, step })` - получает значение поля формы с указанным именем и шагом из store формы.

`updateAt()` - обновляет время последнего изменения формы.

`resetState()` - сбрасывает состояние store формы.

`goToForm({ orderId, productId })` - переходит на страницу формы отзыва, передавая orderId и productId в качестве параметров запроса.
 */

export class FormService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get steps() {
    return STEPS.map((name, index) => ({
      value: name,
      label: `${index + 1}. ${this.gaApp.i18n.t(
        `review.form.${name}.stepName`,
      )}`,
    }))
  }

  startNewForm() {
    this.gaApp.stores.review.form.$reset()
    this.gaApp.stores.review.form.sessionId = getId()
    this.gaApp.stores.review.form.createdAt = Date.now()
  }

  changeStep(step) {
    if (step >= this.steps.length || step < 0) {
      return
    }

    this.gaApp.stores.review.form.currentStep = step
    this.updateAt()
  }

  nextStep() {
    const isNotValidUser = !this.gaApp.services.review.user.isValidUser()

    if (isNotValidUser) {
      return
    }

    const nextStep = this.gaApp.stores.review.form.currentStep + 1
    this.changeStep(nextStep)
  }

  prevStep() {
    const nextStep = this.gaApp.stores.review.form.currentStep - 1
    this.changeStep(nextStep)
  }

  preparedFormData() {
    const clonedData = cloneDeep(this.gaApp.stores.review.form.data)

    const plainData = Object.values(clonedData).reduce((acc, obj) => {
      return {
        ...acc,
        ...obj,
      }
    }, {})

    plainData[KEYS.ITEM_ID] = this.gaApp.stores.review.products[KEYS.ITEM_ID]
    plainData[KEYS.ORDER_ID] = this.gaApp.stores.review.products[KEYS.ORDER_ID]
    plainData[KEYS.CUSTOMER_ID] =
      this.gaApp.stores.review.products[KEYS.CUSTOMER_ID]
    plainData[KEYS.MEDIA_IDS] = this.gaApp.stores.review.media.preview.map(
      ({ mediaId }) => mediaId,
    )
    plainData[KEYS.SESSION_ID] = this.gaApp.stores.review.form.sessionId
    return plainData
  }

  changeValue({ name, value, form }) {
    set(this.gaApp.stores.review.form.data, [form, name], value)
    this.updateAt()
  }

  getValue({ name, step }) {
    return get(this.gaApp.stores.review.form.data, [step, name])
  }

  updateAt() {
    this.gaApp.stores.review.form.updateAt = Date.now()
  }

  resetState() {
    this.gaApp.stores.review.form.$reset()
  }

  goToForm({ orderId, productId } = {}) {
    this.gaApp.router.push({
      path: ROUTES.FORM,
      query: {
        order_id: orderId,
        product_id: productId,
      },
    })
  }
}

import { VENDOR } from '../constants'

import { config } from '~/config'

export class MindboxFacade {
  constructor(mindbox, gaApp) {
    this.module = mindbox
    this.gaApp = gaApp
  }

  get entity() {
    return VENDOR.MINDBOX
  }

  init(id) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const identificator = id || config.get(VENDOR.MINDBOX)?.id

    return this.module.create(identificator)
  }

  send(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const push = () => this.module.async(...args)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }

  sendSync(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    return this.module.sync(...args)
  }
}

import { ENTITIES, REGEXPS } from '@ga/constants/text'

/**
 * Используется для трансформирования текста путем добавления неразрывных пробелов, символов объединения слов
 */
export class TextTransformService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  isPlusSign = (word) => REGEXPS.PLUS_SIGN.test(word)
  isSlashSign = (word) => REGEXPS.SLASH_SIGN.test(word)
  hasSlashSign = (word) => REGEXPS.HAS_SLASH_SIGN.test(word)

  hasProperLength = (word, maxWordLength) => word.length < maxWordLength

  // Возвращает слово с добавленным неразрывным пробелом
  addNonBreakingSpace = (word) => {
    return word + ENTITIES.NON_BREAKING_SPACE
  }

  // Возвращает слово с добавленным обычным пробелом
  addSpace = (word) => {
    return word + ENTITIES.SPACE
  }

  // Возвращает слово с добавленным символом объединения слов
  addWordJoiner = (word) => {
    return word + ENTITIES.WORD_JOINER
  }

  // Возвращает слово с добавленным при необходимости символом объединения слов после всех символов косой черты
  insertWordJoinerIfNeeded = (word) => {
    if (this.hasSlashSign(word)) {
      const letters = word.split('')

      return letters.map((letter) =>
        this.isSlashSign(letter) ? this.addWordJoiner(letter) : letter,
      )
    }

    return word
  }

  // Возвращает слово с добавленным при необходимости соответствующим пробелом
  addProperSpace = (word, nextWord, maxWordLength) => {
    if (!nextWord) {
      return word
    }

    if (this.hasProperLength(word, maxWordLength)) {
      return this.addNonBreakingSpace(word)
    }

    if (this.isPlusSign(nextWord)) {
      return this.addNonBreakingSpace(word)
    }

    if (this.isSlashSign(nextWord)) {
      return this.addNonBreakingSpace(word)
    }

    return this.addSpace(word)
  }

  // Трансформирует и возвращает текст с добавленными при необходимости неразрывными пробелами
  addNonBreakingSpaces = (text, maxWordLength) => {
    const words = text.split(' ')

    const transformedText = words
      .map((word, index, words) =>
        this.addProperSpace(word, words[index + 1], maxWordLength),
      )
      .join('')

    return transformedText
  }
}

import isEmpty from 'lodash/isEmpty'

import { RECEIVE_SOURCE_LINK_OPTIONS } from '../constants/receive'

export class DigitalReceiveCardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает данные для страницы получения ЭПК
   * @param {object} receivedCard данные получаемой ЭПК
   */
  setReceivedCard(receivedCard = {}) {
    if (isEmpty(receivedCard)) {
      return undefined
    }

    Object.assign(this.gaApp.stores.giftCards.digitalReceive, receivedCard)
  }

  /**
   * Вычисляет источник перехода по ссылке получения ЭПК
   */
  getSourceLink() {
    const source = this.gaApp.route.query?.from
    const hasSourceLink = Object.values(RECEIVE_SOURCE_LINK_OPTIONS).includes(
      source,
    )
    const sourceLink = hasSourceLink ? source : RECEIVE_SOURCE_LINK_OPTIONS.SMS

    return sourceLink
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 16">
    <path stroke="inherit" stroke-width="2.25" d="m2 5.25 6 6 6-6" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-arrow-polygon',
}
</script>

import isEqual from 'lodash/isEqual'

import {
  ATTRIBUTE,
  MAX_ATTRIBUTE_VALUE_LENGTH,
  MAX_OPTIONS_LENGTH,
  SKU_HASH_DELIMITER,
  SKU_HASH_NAME,
} from '../../../constants/common'

export class AttributesServices {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает значение указанного атрибута для данного представления и атрибутов.
   *
   * @param {string} attributeName - Название атрибута.
   * @param {string} view - Название представления.
   * @param {Object} attributesSelected - Выбранные атрибуты.
   * @param {Array} variants - Массив вариантов.
   * @return {Function} - Возвращает функцию, которая устанавливает значение атрибута.
   */
  setAttributeWithType({ attributeName, view, attributesSelected, variants }) {
    return (value) => {
      const tempAttributes = {
        ...attributesSelected,
        [attributeName]: value,
      }

      let foundProduct = variants.find((variant) =>
        isEqual(variant.attributesValue, tempAttributes),
      )
      // если нет продукта с такими аттрибута, то ищем первый подходящий
      if (!foundProduct) {
        foundProduct = variants.find(
          (variant) => variant.attributesValue[attributeName] === value,
        )
      }

      this.setAttributesValue({ view, value: foundProduct?.attributesValue })
    }
  }

  /**
   * Устанавливает выбранные атрибуты для указанного представления в хранилище приложения.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string} param.view - Название представления, по умолчанию 'main'.
   * @param {Object} param.value - Объект с выбранными значениями атрибутов, по умолчанию {}.
   * @return {undefined} - Не возвращает значения.
   */
  setAttributesValue({ view = 'main', value = {} } = {}) {
    this.gaApp.stores.pdp[view].attributesSelected = {
      ...value,
    }
  }

  /**
   * Возвращает массив объектов, представляющих атрибуты компонента.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {Object} param.attributes - Объект атрибутов.
   * @param {Object[]} param.variants - Массив вариантов.
   * @param {Object} param.attributesSelected - Объект с выбранными значениями атрибутов.
   * @param {string} param.view - Название представления.
   * @return {Object[]} - Массив объектов, представляющих атрибуты компонента.
   */
  get attributesComponents() {
    return {
      get: ({ attributes, variants, attributesSelected, view }) =>
        Object.keys(attributes).reduce(
          (result, attributeName) =>
            this.getComponentAttributes({
              result,
              attributeName,
              attributesSelectedValues: attributesSelected,
              attributes,
              variants,
              view,
            }),
          [],
        ),
    }
  }

  /**
   * Возвращает атрибуты компонента на основе предоставленных параметров.
   *
   * @param {object} params - Параметры для получения атрибутов компонента.
   * @param {any} params.result - Объект результата для сохранения атрибутов компонента.
   * @param {string} params.attributeName - Название атрибута.
   * @param {object} params.attributesSelectedValues - Выбранные значения атрибутов.
   * @param {object} params.attributes - Объект атрибутов.
   * @param {object} params.variants - Объект вариантов.
   * @param {object} params.view - Объект представления.
   * @return {array} Обновленный объект результата, содержащий атрибуты компонента.
   */
  getComponentAttributes({
    result,
    attributeName,
    attributesSelectedValues,
    attributes,
    variants,
    view,
  }) {
    const options = attributes[attributeName]?.options ?? []

    const isManyView =
      options.length > MAX_OPTIONS_LENGTH ||
      !!options.find(
        (option) =>
          option.image ||
          option.value?.replace(/[.,:]/g, '').length >
            MAX_ATTRIBUTE_VALUE_LENGTH,
      )
    const value = attributesSelectedValues[attributeName]
    const normalizeAttributes = this.buildAttributes({
      attributeName,
      attributes,
      attributesSelectedValues,
      variants,
      isManyView,
    })

    result.push({
      name: attributeName,
      component: 'ga-pdp-attribute-universal',
      props: {
        isManyView,
        value,
        asLink: this.gaApp.stores.user.main.isBot,
        ...normalizeAttributes,
      },
      listeners: {
        change: this.setAttributeWithType({
          attributeName,
          view,
          attributesSelected: attributesSelectedValues,
          variants,
        }),
      },
    })

    return result
  }

  /**
   * Строит атрибуты для заданных параметров.
   *
   * @param {object} params - Параметры для построения атрибутов.
   * @param {string} params.attributeName - Название атрибута.
   * @param {object} params.attributes - Объект атрибутов.
   * @param {object} params.attributesSelectedValues - Выбранные значения атрибутов.
   * @param {object} params.variants - Объект вариантов.
   * @param {boolean} params.isManyView - Флаг, указывающий, является ли представление множественным.
   * @return {object} Построенный объект атрибутов.
   */
  buildAttributes({
    attributeName,
    attributes,
    attributesSelectedValues,
    variants,
    isManyView,
  }) {
    const currentAttributeData = attributes[attributeName]

    const options = this.buildOptions({
      attributeName,
      attributes,
      attributesSelectedValues,
      variants,
      isManyView,
    })

    return {
      ...currentAttributeData,
      name: attributeName,
      options,
    }
  }

  /**
   * Строит опции для заданного атрибута.
   *
   * @param {object} params - Параметры для построения опций.
   * @param {string} params.attributeName - Название атрибута.
   * @param {object} params.attributes - Объект атрибутов.
   * @param {object} params.attributesSelectedValues - Выбранные значения атрибутов.
   * @param {object[]} params.variants - Массив вариантов.
   * @param {boolean} params.isManyView - Указывает, является ли представление множественным.
   * @return {object[]} Массив опций.
   */
  buildOptions({
    attributeName,
    attributes,
    attributesSelectedValues,
    variants,
    isManyView,
  }) {
    const sourceName =
      attributeName === ATTRIBUTE.PHYSICAL
        ? ATTRIBUTE.VISUAL
        : ATTRIBUTE.PHYSICAL

    // пока считаем, что это главный аттрибут по которому фильтруются остальные аттрибуты
    const isPhysical = attributeName === ATTRIBUTE.PHYSICAL

    const options = attributes[attributeName].options.reduce(
      (acc, targetOption) => {
        let hasStock = false
        let isVisualAvailable = false
        let matchedProduct = null

        for (const variant of variants) {
          // Проверяем, соответствует ли вариант источнику (sourceName)
          const hasSource =
            variant.attributesValue[sourceName] ===
            attributesSelectedValues[sourceName]
          const isMatchedSource = isPhysical || hasSource
          // Проверяем, соответствует ли вариант целевому атрибуту (attributeName)
          const isMatchedTarget =
            variant.attributesValue[attributeName] === targetOption.value
          // Если вариант соответствует и источнику, и целевому атрибуту
          if (isMatchedSource && isMatchedTarget) {
            // Присваиваем вариант в matchedProduct
            matchedProduct = variant
            // Устанавливаем hasStock из варианта
            hasStock = variant.inStock
            // Устанавливаем isVisualAvailable в true, если вариант доступен (inStock) и соответствует источнику (sourceName)
            isVisualAvailable = hasStock && hasSource
            // Выходим из цикла, если нашли вариант и он соответствует источнику
            if (isVisualAvailable) break
          }
        }

        // Если найден подходящий вариант (matchedProduct) добавляем в результирующий объект
        if (matchedProduct) {
          acc.push({
            ...targetOption,
            oos: !hasStock || !isVisualAvailable,
            href: hasStock ? matchedProduct.url : null,
          })
        }

        return acc
      },
      [],
    )

    return !isManyView ? options : options.sort((a, b) => a.oos - b.oos)
  }

  /**
   * Фоллбэк для установки атрибута на основе хэша варианта в роуте.
   *
   * @param {object} params - Параметры для установки атрибутов.
   * @param {object[]} params.variants - Массив вариантов.
   * @return {undefined} Не возвращает значения.
   */
  setAttributesByHashFallback({ variants }) {
    const [hashName, hashValue] = location.hash
      .replace('#', '')
      .split(SKU_HASH_DELIMITER)

    if (hashName !== SKU_HASH_NAME) {
      return
    }

    const foundProduct = variants.find(
      (product) => product.itemId === hashValue,
    )
    if (!foundProduct) {
      return
    }
    const { attributesValue: value } = foundProduct

    this.setAttributesValue({ view: 'main', value })
  }
}

import {
  CHECK_PAYMENT_STATUS_INTERVAL,
  PAYMENT_STATUS,
} from '../constants/payment'

/**
 * @typedef CheckerOptions
 * @property {Function} onSuccess коллбек, отрабатывающий при статусе "success"
 * @property {Function} onProcess коллбек, отрабатывающий при статусе "processing"
 * @property {Function} onFailed коллбек, отрабатывающий в случае ошибки и статусе "failed"
 */

export class PaymentStatusService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  generateFailedStatusError(message) {
    const error = new Error(message)
    error.failedStatus = true

    return error
  }

  /**
   * Проверяет статус оплаты.
   * @param {string} paymentId идентификатор оплаты
   * @param {CheckerOptions} options настройки проверки статуса оплаты заказа
   */
  async checkStatus(paymentId, { onSuccess, onProcess, onFailed } = {}) {
    try {
      const { data } =
        await this.gaApp.services.giftCards.api.checkOrderStatus(paymentId)

      switch (data.status) {
        case PAYMENT_STATUS.FAILED:
          throw this.generateFailedStatusError()

        case PAYMENT_STATUS.SUCCESS:
          this.stopChecker()
          return onSuccess?.()

        case PAYMENT_STATUS.PROCESS:
          return onProcess?.()
      }
    } catch (error) {
      console.error(error)
      this.stopChecker()
      onFailed?.(error)
    }
  }

  /**
   * Запускает проверку статуса оплаты.
   * @param {string} paymentId идентификатор оплаты
   * @param {CheckerOptions} options настройки проверки статуса оплаты заказа
   */
  startChecker(paymentId, options) {
    this.gaApp.stores.giftCards.payment.status.intervalHandler = setInterval(
      () => this.checkStatus(paymentId, options),
      CHECK_PAYMENT_STATUS_INTERVAL,
    )
  }

  /**
   * Останавливает проверку статуса оплаты
   */
  stopChecker() {
    clearInterval(this.gaApp.stores.giftCards.payment.status.intervalHandler)
    this.gaApp.stores.giftCards.payment.status.intervalHandler = null
  }
}

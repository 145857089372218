import { GaAnalyticsBaseModel } from '../ga-analytics-base.model/ga-analytics-base.model'

export class GaAnalyticsBreadcrumbClickModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('listing')

    const { lastHref, path } = data

    this.category = {
      url: lastHref,
      itemListName: path,
    }

    this.debug = {
      key: 'pdp-breadcrumbs-item-click',
      data,
    }
  }
}

const round = (value) => Math.round((value + Number.EPSILON) * 100) / 100

const getConfig = (system) => {
  const { base, power, sizes } = {
    iec: {
      base: 2,
      power: 10,
      sizes: ['b', 'kib', 'mib', 'gib'],
    },
    si: {
      base: 10,
      power: 3,
      sizes: ['b', 'kb', 'mb', 'gb'],
    },
  }[system]

  return {
    power,
    sizes,
    mathLog: `log${base}`,
    unitSeparator: base ** power,
    maxSizeIndex: sizes.length - 1,
  }
}

/**
 * Функция определяет максимальный размер единицы измерения информации и
 * сокращает `size` до него.
 *
 * @param {number | null | undefined} size размер информации в байтах
 * @param {{
 *  system: 'si' | 'iec',
 *  roundAfter: number | null,
 *  maxUnit: number | null
 * }} config конфигурация вычислений:
 *  - system – в какой системе указан размер [МЭК или СИ](https://clck.ru/35S3Dy) (по-умолчанию: `'si'`)
 *  - roundAfter – порог, после которого происходит округление в сторону большего разряда (отключено по-умолчанию).
 *  - maxUnit – индекс максимально допустимой единицы измерения информации (по-умолчанию ограничение до `'gb'` или `'gib'`, в зависимости от системы)
 * @returns {{
 *  value: number,
 *  type: config['system'] extends 'si' ? 'b' | 'kb' | 'mb' | 'gb' : 'b' | 'kib' | 'mib' | 'gib'
 * }}
 * @example
 * console.log(getInfoUnit(1000))
 * // вывод: { system: 'kb', value: 1 }
 *
 * console.log(getInfoUnit(1024, { system: 'iec' }))
 * // вывод: { system: 'kib', value: 1 }
 *
 * console.log(getInfoUnit(500, { roundAfter: 0.5 }))
 * // вывод: { system: 'kb', value: 0.5 }
 *
 * console.log(getInfoUnit(1e6, { maxUnit: 1 }))
 * // вывод: { system: 'kb', value: 1000 }
 */

export const getInfoUnit = (
  size,
  { system = 'si', roundAfter = null, maxUnit = null } = {},
) => {
  if (!size) {
    return {
      type: 'b',
      value: 0,
    }
  }

  const { power, sizes, unitSeparator, mathLog, maxSizeIndex } =
    getConfig(system)

  // определяем единицу измерения объема информации
  const unit = Math.floor(Math[mathLog](size) / power)

  // если единица измерения за границами массива, то используем последний
  const maxUnitIndex = Math.min(maxUnit ?? maxSizeIndex, maxSizeIndex, unit)

  // если размер единицы измерения больше roundAfter, то переходим на следующий разряд
  const hasToSwitchToNext =
    roundAfter &&
    size / unitSeparator ** maxUnitIndex >= roundAfter * unitSeparator
  const unitIndex = maxUnitIndex + hasToSwitchToNext

  return {
    type: sizes[unitIndex] ?? sizes.at(-1),
    value: round(size / unitSeparator ** unitIndex),
  }
}

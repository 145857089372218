export class WarningService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // показываем модалку с предупреждением перед добавлением в корзину
  open({ productData }) {
    if (!productData) {
      return
    }

    this.gaApp.stores.cart.warning.isOpen = true
    this.gaApp.stores.cart.warning.productData = productData

    this.sendAnalytic(productData)
  }

  // закрываем модалку с предупреждением
  close() {
    this.gaApp.stores.cart.warning.isOpen = false
  }

  clearProductData() {
    this.gaApp.stores.cart.warning.productData = null
  }

  async addProductToCart({ addToCart }) {
    try {
      await addToCart()
    } catch (error) {
      this.gaApp.services.app.apm.captureError(error)
    } finally {
      this.close()
    }
  }

  sendAnalytic(product) {
    this.gaApp.analytics.modules.cart.onOpenWarning(product)
  }
}

import { computed, unref } from 'vue'

/**
 * Возвращает счетчик инпута
 * @param {Ref<String>} value - value инпута
 * @param {Ref<Number>} maxLength - максимальное количество символов
 */
export const useCounter = (value, maxLength) => {
  const counter = computed(() => {
    return `${unref(value).length}/${unref(maxLength)}`
  })

  return {
    counter,
  }
}

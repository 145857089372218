export class MindboxProductBaseModel {
  constructor(product) {
    const { id, price } = product

    this.product = {
      ids: {
        website: id.toString(),
      },
    }

    if (price) {
      this.pricePerItem = price
    }
  }
}

import { computed, toRefs } from 'vue'

import { usePostRender } from '../../../composables'
import { propValidator } from '../../../utils'
import { labelsAdapter as schemaLabelsAdapter } from '../../../utils/schemas'
import { GaProductCardLabels } from '../../molecules/labels'

import { useDataLabels } from './scripts/composables'

// @vue/component
export default {
  name: 'ga-product-card-labels-adapter',

  components: {
    GaProductCardLabels,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
      validator: (value) => propValidator(value, schemaLabelsAdapter),
      required: true,
    },
  },

  setup(props, { attrs }) {
    const { data } = toRefs(props)

    const { dataLabels } = useDataLabels(data)

    const propsToPass = computed(() => {
      const { data, ...restProps } = attrs
      return restProps
    })

    const { postRendered } = usePostRender()

    return { dataLabels, propsToPass, postRendered }
  },
}

// @vue/component
import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaIconPrimaryArrowRight } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'

export default {
  name: 'ga-product-slider-link-card',

  components: {
    GaAction,
    GaIcon,
    GaIconPrimaryArrowRight,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { $gaApp } = useContext()

    const isWebview = computed(() => $gaApp.isWebview)
    return {
      isWebview,
    }
  },
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение данных конфигурации оформления КЭПК
   */
  async getDigitalConfig() {
    const { data } =
      await this.gaApp.repositories.giftCardsB2b.digital.getConfig()

    const { covers, felicitation, sets, counterparty } = data

    this.gaApp.stores.giftCardsB2b.digital.sets.covers = covers
    this.gaApp.stores.giftCardsB2b.digital.sets.rules = sets
    this.gaApp.stores.giftCardsB2b.digital.felicitation.rules = felicitation
    this.gaApp.stores.giftCardsB2b.digital.counterparty.rules = counterparty
  }

  /**
   * Получение информации о контрагенте
   * @param {string} inn ИНН контрагента
   * @param {Function} setError коллбек в случае ошибки
   */
  async findByInn(inn, setError) {
    try {
      this.gaApp.services.giftCardsB2b.digitalCounterparty.clearInfo()
      this.gaApp.stores.giftCardsB2b.digital.counterparty.isLoading = true

      const { data } =
        await this.gaApp.repositories.giftCardsB2b.digital.findByInn({ inn })

      if (data.blocked) {
        const error = new Error('Counterparty is blocked')
        error.localizedMessage = this.gaApp.i18n.t(
          'giftCardsB2b.digital.error.innBlocked',
        )

        throw error
      }

      this.gaApp.services.giftCardsB2b.digitalCounterparty.fillInfo(data)
      this.gaApp.analytics.modules.giftCardsB2b.onFindCounterpartyClick({
        success: 'yes',
      })
    } catch (error) {
      const errorData = error.data?.data
      const message =
        error.localizedMessage ||
        errorData?.invalidParameters?.inn?.localizedMessage ||
        errorData?.localizedMessage

      this.gaApp.analytics.modules.giftCardsB2b.onFindCounterpartyClick({
        success: 'no',
        error: message,
      })

      if (!message) {
        return this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('giftCardsB2b.digital.error.findByInn'),
        )
      }

      setError(message)
    } finally {
      this.gaApp.stores.giftCardsB2b.digital.counterparty.isLoading = false
    }
  }

  /**
   * Оформление заказа КЭПК
   */
  async digitalOrder() {
    try {
      const { order } = this.gaApp.services.giftCardsB2b.digital
      this.gaApp.stores.giftCardsB2b.main.isLoading = true

      const { data } =
        await this.gaApp.repositories.giftCardsB2b.digital.order(order)

      this.gaApp.services.giftCardsB2b.successModal.open()
      this.gaApp.analytics.modules.giftCardsB2b.onSuccessOrder(
        data.orderNumber,
        'kepk',
      )
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('giftCardsB2b.digital.error.order'),
      )
    } finally {
      this.gaApp.stores.giftCardsB2b.main.isLoading = false
    }
  }

  /**
   * Подтверждение электронной почты пользователя после оформления заказа
   * @param {string} emailVerificationCode код подтверждения электронной почты
   */
  async emailConfirm(emailVerificationCode) {
    try {
      const { data } =
        await this.gaApp.repositories.giftCardsB2b.digital.emailConfirm({
          emailVerificationCode,
        })

      if (!data.emailVerified) {
        throw new Error('Confirmation failed')
      }

      this.gaApp.analytics.modules.giftCardsB2b.onMailConfirmed()
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 400, message: error })
    }
  }

  /**
   * Получение конфигурации для КППК
   */
  async getPlasticConfig() {
    const { data } =
      await this.gaApp.repositories.giftCardsB2b.plastic.getConfig()

    const { cities } = data

    this.gaApp.stores.giftCardsB2b.plastic.cities = cities
  }

  /**
   * Создание заказа на КППК
   */
  async plasticOrder() {
    try {
      this.gaApp.stores.giftCardsB2b.main.isLoading = true

      const { order } = this.gaApp.services.giftCardsB2b.plastic

      await this.gaApp.repositories.giftCardsB2b.plastic.order(order)

      this.gaApp.services.giftCardsB2b.successModal.open()
      this.gaApp.analytics.modules.giftCardsB2b.onSuccessOrder(
        undefined,
        'kppk',
      )
    } catch (error) {
      const messageKey =
        error.statusCode === 413
          ? 'giftCardsB2b.plastic.error.tooLarge'
          : 'giftCardsB2b.plastic.error.order'
      const localizedMessage = this.gaApp.i18n.t(messageKey)

      this.gaApp.services.notification.main.open(localizedMessage)
    } finally {
      this.gaApp.stores.giftCardsB2b.main.isLoading = false
    }
  }

  /**
   * Получение конфигурации для КППК - новый флоу
   * @param {string} cityId идентификатор города
   *
   * TODO:
   * – правила для полей формы
   * – данные магазинов для самовывоза
   */
  async getPlasticNewConfig(cityId) {
    this.setPlasticNewConfigLoading(true)

    const { data } =
      await this.gaApp.repositories.giftCardsB2b.plastic.getNewConfig(cityId)

    const { cities, cards, rules } = data

    /** Установка городов, доступных для заказа КППК */
    this.gaApp.services.giftCardsB2b.location.setCities(cities)
    /** Установка города по умолчанию */
    this.gaApp.services.giftCardsB2b.location.setDefaultCity()
    /** Установка возможных дизайнов КППК */
    this.gaApp.stores.giftCardsB2b.plasticNew.config.cards = cards
    /** Установка правил валидации полей заявки */
    this.gaApp.stores.giftCardsB2b.plasticNew.config.rules = rules

    this.setPlasticNewConfigLoading(false)
  }

  setPlasticNewConfigLoading(value) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.isLoading = value
  }

  /** TODO:
   * – Оформление заявки на КППК – новый флоу
   */
  submitPlasticNewOrder() {}
}

import { TYPE } from '../../constants'
import { GtmGa4UserAccountModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class UserAccountBuilder extends BaseBuilder {
  constructor({ actionType, screenType }) {
    const models = new Map([[TYPE.GTM_4, GtmGa4UserAccountModel]])

    super(models, { actionType, screenType })
  }
}

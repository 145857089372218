import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmSuccessClearanceModel extends GtmBaseModel {
  constructor({ orderId, paymentType } = {}) {
    super({ event: 'pay_gift_card_success' })

    this.buildGiftCardId(orderId)
    this.buildPaymentType(paymentType)
  }

  buildGiftCardId(id) {
    if (id) {
      this.giftcard_id = id
    }
  }

  buildPaymentType(paymentType) {
    if (paymentType) {
      this.payment_type = paymentType
    }
  }
}

import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

/*
 * Сравнивает два объекта по массиву ключей
 * */
export const areFieldsEqual = (firstObject, secondObject, fields = []) => {
  // избавляемся от полей со значением null, чтобы считать равными null и undefined
  const cleanFirstObject = omitBy(firstObject, isNil)
  const cleanSecondObject = omitBy(secondObject, isNil)

  return fields.every((field) =>
    isEqual(cleanFirstObject[field], cleanSecondObject[field]),
  )
}

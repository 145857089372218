import has from 'lodash/has'

import { unref } from 'vue'

import { PIXEL_IMAGE } from '@ga/constants/pixel'
import { findReserveScreen } from '@ga/utils'

import {
  extractExtension,
  filterInvalidScreens,
  sortByBreakpoint,
} from '../../utils'
import { useImageBreakpoints } from '../image-breakpoints'

export const useImageStrategyPlain = (config) => {
  const { breakpoints } = useImageBreakpoints()

  const getName = () => 'plain'

  /**
   * Метод позволяет определить стратегию на основе конфига изображения
   * Если конфиг содержит в качестве ключей имена брейкпоинтов, то это текущая стратегия
   */
  const isOwnStrategy = () => {
    return unref(breakpoints).keys.some((key) => has(config, key))
  }

  const getScreensForBuild = () => {
    // Очищаем невалидные экраны
    const cleared = filterInvalidScreens(unref(config))

    // Сортируем пришедшие экраны в порядке убывания
    const sorted = sortByBreakpoint(Object.keys(cleared))

    // Находим индекс первого экрана, в массиве всех экранов
    const first = unref(breakpoints).keys.indexOf(sorted[0])

    // Делаем слайс диапазона экранов от наивысшего пришедшего до наименьшего
    return unref(breakpoints).keys.slice(first)
  }

  const buildSources = (currentScreen, image) => {
    const { values: bpValues, keys: bpKeys } = unref(breakpoints)

    const configScreens = Object.keys(filterInvalidScreens(unref(config)))

    const currentAvaScreen = unref(image)[currentScreen]
      ? currentScreen
      : findReserveScreen(currentScreen, bpKeys, configScreens, true)

    const url = unref(image)[currentAvaScreen]

    return [
      {
        ext: extractExtension(url),
        media: bpValues[currentScreen],
        srcset: [url],
      },
    ]
  }

  const buildSourceFallback = () => {
    return PIXEL_IMAGE
  }

  return {
    getName,
    isOwnStrategy,
    getScreensForBuild,
    buildSourceFallback,
    buildSources,
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 54 12">
    <rect
      width="54"
      height="12"
      fill="#fff"
      fill-opacity=".1"
      data-id="background"
      rx="6"
    />
    <path
      fill="#fff"
      d="m8 2.75 1.034 2.216L11.25 6 9.034 7.034 8 9.25 6.966 7.034 4.75 6l2.216-1.034L8 2.75Zm7.737 3.826h-.652v2.4H14V3.024h1.737c.88 0 1.313.32 1.313 1.272v.976c0 .904-.457 1.304-1.313 1.304Zm-.652-2.704v1.816h.383c.375 0 .506-.136.506-.632v-.56c0-.48-.13-.624-.506-.624h-.383Zm3.7-.848h2.667v.928h-1.606v1.472h1.337v.952h-1.337v1.648H21.5v.952h-2.716V3.024Zm6.655 5.952-1.16-2.848v2.848h-1.076V3.024h1.076v2.648l1.093-2.648h1.06L25.276 5.8l1.272 3.176H25.44Zm4.628 0V3.944h-.807c0 1.904-.008 3.24-.082 3.896-.09.8-.359 1.136-1.06 1.136h-.35V8h.065c.203 0 .318-.128.367-.552.048-.48.057-.744.057-4.424h2.895v5.952h-1.085Zm5.012 0L34.9 7.88h-1.11l-.17 1.096h-.955l1.02-5.952h1.353l1.085 5.952h-1.044Zm-.93-3.32-.213 1.336h.816l-.22-1.336a17.067 17.067 0 0 1-.188-1.424 28.03 28.03 0 0 1-.196 1.424Zm6.539.432-.587 2.888h-.571l-.571-2.832a74.443 74.443 0 0 1-.35-1.872c.016.664.024 1.216.024 2.064v2.64h-.922V3.024h1.452l.416 2.12c.138.696.195 1.096.269 1.544.073-.448.122-.848.26-1.544l.417-2.12h1.427v5.952h-.946v-2.64c0-.848.016-1.4.032-2.064-.09.496-.187 1.016-.35 1.816Zm5.267 2.888-.18-1.096h-1.108l-.172 1.096h-.954l1.02-5.952h1.353L47 8.976h-1.044Zm-.93-3.32-.212 1.336h.816l-.22-1.336a17.067 17.067 0 0 1-.188-1.424 28.03 28.03 0 0 1-.196 1.424Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-ad-xxs',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

export const useAnalytics = () => {
  const { $gaApp } = useContext()

  // При клике на таб в хедере
  const onTabClick = (item) => {
    $gaApp.analytics.modules.header.onTabClick(item)
  }

  // При клике на таб в хедере
  const onMenuItemClick = (items, lastItem) => {
    const url = lastItem.link

    const path = $gaApp.services.header.nav.getExpandedItemsPath(
      items,
      lastItem,
    )

    $gaApp.analytics.modules.header.onMenuItemClick({ url, path })
  }

  return {
    onTabClick,
    onMenuItemClick,
  }
}

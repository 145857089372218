import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsContentCategoryClickModel extends GaAnalyticsBaseModel {
  constructor({
    blockName,
    blockPosition,
    slideName,
    slidePosition,
    link,
    itemListName,
  }) {
    super('listing')

    this.category = {
      creativeName: slideName,
      creativeSlot: `slide_${slidePosition}`,
      promotionName: blockName,
      itemListId: `главная/block_${blockPosition}`,
      itemListName,
      url: link,
    }
  }
}

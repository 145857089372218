import { computed, unref } from 'vue'

import {
  findReserveScreen,
  getVideoTypeByFormat,
  replaceMediaUrl,
  VIDEO_EXTENSION_PRIORITY,
} from '@ga/utils'

import { SCREEN } from '../const'

/**
 * Пользовательский хук для управления отзывчивыми видеоисточниками.
 *
 * @param {$gaApp} $gaApp - Экземпляр контекста приложения.
 * @param {Object} video - Объект видео с полями url (URL видео), format (массив форматов), screen (массив экранов).
 * @return {Object} Объект, содержащий свойства hasVideo и sourceInternal.
 */
export const useSourceResponsive = ($gaApp, video) => {
  const hasVideo = computed(() => !!unref(video)?.url)

  const getCurrentScreen = () => {
    if ($gaApp.mq('desktop-small+')) {
      return SCREEN[1]
    }

    if ($gaApp.mq('tablet+')) {
      return SCREEN[2]
    }

    return SCREEN[3]
  }

  const currentScreen = getCurrentScreen()

  const sourceInternal = computed(() => {
    if (!hasVideo.value) {
      return null
    }

    const { url, format = [], screen = [] } = unref(video)

    const foundScreen = findReserveScreen(currentScreen, SCREEN, screen)
    if (!format?.length) {
      return {
        src: replaceMediaUrl(url, {
          screen: foundScreen,
        }),
      }
    }

    return [...format]
      .sort((a, b) => VIDEO_EXTENSION_PRIORITY[a] - VIDEO_EXTENSION_PRIORITY[b])
      .map((ext) => ({
        src: replaceMediaUrl(url, {
          format: ext,
          screen: foundScreen,
        }),
        type: getVideoTypeByFormat(ext),
      }))
  })

  return {
    hasVideo,
    sourceInternal,
  }
}

const REQUEST_TYPE = {
  json: 'application/json',
  form: 'application/x-www-form-urlencoded',
}

export const buildContentTypeHeader = (type) => {
  const headers = {}

  if (type) {
    headers['Content-Type'] = REQUEST_TYPE[type]
  }

  return {
    headers,
  }
}

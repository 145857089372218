export const getGameStateInitial = () => ({
  name: '',
  hasMobileToken: false,
  gameToken: null,
  processingStatus: null,
  user: {},
})

export default () => {
  return getGameStateInitial()
}

import { computed, ref, unref } from 'vue'

import { useCurrencyVisible } from '~/modules/product-card'

import { SIZE } from './const'

export const useItemsActiveIndexes = ({
  itemsInternal,
  offset,
  offsetStep,
}) => {
  const activeIndexes = computed(() => {
    const start = offset.value
    const end = offset.value + offsetStep.value

    return itemsInternal.value.map((_, index) => index).slice(start, end)
  })

  return { activeIndexes }
}

export const useItems = ({
  size,
  itemsInternal,
  activeIndexes,
  fake = false,
  showStartIndent,
  skeleton,
}) => {
  const isMirrored = computed(() => size.value === SIZE.XL)

  const showCurrency = useCurrencyVisible()

  const lastReversedIndex = computed(() => {
    return activeIndexes.value.length - 3
  })

  const items = computed(() => {
    return itemsInternal.value.map((item, index) => {
      const activeIndex = activeIndexes.value.indexOf(index)

      let reversed = false

      if (isMirrored.value) {
        reversed = !(activeIndex > lastReversedIndex.value)
      }

      const mods = { type: fake ? 'fake' : 'real' }

      const infoIndent = showStartIndent.value && index === 0 ? 'start' : null

      const showPriceCurrency = unref(showCurrency)

      const productCardProps = {
        product: item,
        reversed,
        infoIndent,
        skeleton,
        showPriceCurrency,

        // Для фейковых карточек элементы делаем видимыми, что бы избежать мигания при перелистывании
        contentVisibilityAuto: !fake,
      }

      const linkCardProps = item

      const itemComponentProps = item?.buttonText
        ? linkCardProps
        : productCardProps

      if (activeIndex === -1) {
        return {
          index,
          mods,
          ...itemComponentProps,
        }
      }

      const active = true
      const first = activeIndex === 0
      return {
        index,
        active,
        skeleton,
        mods: { ...mods, active, first },
        ...itemComponentProps,
      }
    })
  })

  return { items }
}

export const useItemsDirection = () => {
  return { itemsDirection: ref(null) }
}
export const useItemsAnimating = () => {
  return { itemsAnimating: ref(false) }
}

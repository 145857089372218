export const callbackResponseDefault = (eventBus) => ({
  success(data) {
    eventBus.publish('end', {
      data,
    })

    return data
  },

  error(error) {
    console.error(error)
    eventBus.publish('error', {
      error,
    })

    return Promise.reject(error)
  },
})

import { EYEZON_ROOT_ID, LISTENER } from '../constants'
import { mutationObserver } from '../utils/mutation-observer'

import { config } from '~/config'

/**
 * сервис управляет жизненным циклом экземпляра eyeZon,
 * включая его создание, настройку, добавление обработчиков событий
 * и аналитики, а также сброс состояния при необходимости.
 */

export class SetupService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // инстанс из модуля
  get _eyeZonInstance() {
    return this.gaApp.ctx.$eyezon
  }

  // инстанс айзонв в глобальной области видимости
  get eyeZon() {
    return this._eyeZonInstance.eyeZon
  }

  // Возвращает список имен слушателей
  get listenersName() {
    return [
      LISTENER.CART_WIDGET,
      LISTENER.CART_STREAMER,
      LISTENER.CART_RECORD,
      LISTENER.CLOSE,
    ]
  }

  // слушатели эвентов айзона
  get listenersEvent() {
    return {
      [LISTENER.CART_WIDGET]: async (data) => {
        await this.handleCartRequest(data)
      },
      [LISTENER.CART_STREAMER]: async (data) => {
        await this.handleCartRequest(data)
      },
      [LISTENER.CART_RECORD]: async (data) => {
        await this.handleCartRequest(data)
      },
      [LISTENER.CLOSE]: () => this.gaApp.services.eyezon.visual.addStyles(),
      [LISTENER.DIALOG_CREATED]: () =>
        this.gaApp.analytics.modules.eyezon.createDialog(),
    }
  }

  async handleCartRequest(data) {
    const isBarcodeRequest = data.barcode
    const id = isBarcodeRequest ? data.barcode : data.sku || data.target
    const method = isBarcodeRequest ? 'addItemByBarcode' : 'addItem'
    await this.gaApp.services.cart.api[method]({ id }, false)
  }

  /**
   * Метод проверяет, включен ли eyezon в конфигурации,
   * и затем планирует выполнение нескольких задач,
   * таких как установка данных пользователя, создание
   * экземпляра eyeZon, добавление обработчиков событий,
   * настройка аналитики и добавление стилей.
   */
  setup(initConfig) {
    if (!config.get('eyezon').enabled) {
      return
    }

    this.gaApp.services.app.scheduler.postTasks([
      { task: () => this.setUserData() },
      { task: () => this.create(initConfig) },
      { task: () => this.addListeners() },
      { task: () => this.setAnalytic() },
      { task: () => this.gaApp.services.eyezon.visual.addStyles() },
    ])
  }

  // инициализируем айзон из модуля и после маунта в body добавляем доп стили
  create(initConfig) {
    this.mount()

    this._eyeZonInstance.init(initConfig)

    this.gaApp.stores.eyezon.main.isInit = true
  }

  // следим за добавление в body web components eyezon
  mount() {
    const { stop } = mutationObserver({
      cb: (node) => {
        if (node?.id === EYEZON_ROOT_ID) {
          this.gaApp.services.eyezon.visual.addStyles(node)
          stop()
        }
      },
    })

    // принудительно стопаем обсервер, чтоб не тормозил приложение
    const timer = setTimeout(() => {
      stop()
      clearTimeout(timer)
    }, 10000)
  }

  // аналитика для айзона
  setAnalytic() {
    const clientId = this.gaApp.cookies.get('_ga')

    if (!clientId) {
      return
    }

    const arrClientId = this.gaApp.cookies.get('_ga').split('.')

    const eyezonNewVersion = this.gaApp.features.get('eyezonV2')

    // Во второй версии eyezon изменился параметр для передачи client id.
    this.eyeZon(
      'setAnalyticsParam',
      eyezonNewVersion ? 'analyticsClientId' : 'clientId',
      `${arrClientId[2]}.${arrClientId[3]}`,
    )
  }

  // прокидываем данные пользователя, если он авторизован
  setUserData() {
    // Во второй версии скрипта eyezon не устанавливаются дефолтные поля
    const eyezonNewVersion = this.gaApp.features.get('eyezonV2')
    if (eyezonNewVersion) {
      return
    }

    const userData = this.gaApp.stores?.user?.main?.data

    this.eyeZon('setDefaultClientField', 'name', userData?.firstName ?? '')
    this.eyeZon('setDefaultClientField', 'phone', userData?.phone ?? '')
    this.eyeZon('setDefaultClientField', 'email', userData?.email ?? '')
  }

  // добавляем слушатели для эвентов айзона
  addListeners() {
    this.listenersName.forEach((name) => {
      this.eyeZon('on', name, this.listenersEvent[name])
    })
  }

  // удаляем слушателей для евентов айзона
  removeListeners() {
    this.listenersName.forEach((name) => {
      this.eyeZon('off', name)
    })
  }

  reset() {
    this.eyeZon('reset')
    this.removeListeners()
  }
}

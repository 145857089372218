/**
 * Выполняет функцию, используя два вызова requestAnimationFrame.
 *
 * Откладывает выполнение на два кадра анимации.
 * Для тех случаев, когда $nextTick, одиночный raf и setTimeout не работают.
 *
 * Взято из https://github.com/twickstrom/vue-force-next-tick/blob/master/src/index.js
 *
 * @param {function} func — функция, которую нужно выполнить.
 * @return {undefined}
 */
function _doubleRAF(func) {
  window.requestAnimationFrame(() => window.requestAnimationFrame(func))
}

function doubleRAF(func) {
  if (func && typeof func === 'function') {
    return _doubleRAF(func)
  }

  return new Promise((resolve) => {
    _doubleRAF(resolve)
  })
}

export { doubleRAF }

import { DIALOG } from '../../constants'

export const getters = (gaApp) => ({
  isEmpty() {
    return gaApp.stores.cart.main.itemQty === 0
  },

  coupon() {
    const value = gaApp.stores.cart.main.totals.coupon
    const discount = gaApp.stores.cart.main.totals.couponDiscount
    const currency = gaApp.stores.cart.main.totals.currency
    const applied = Boolean(value)
    const loading = this.couponState.isLoading
    const error = this.couponState.errorMessage

    return { value, discount, currency, applied, loading, error }
  },

  availableCoupons() {
    return gaApp.stores.cart.main.totals.availableCoupons
  },

  hasLoadingItems() {
    return this.itemsLoading?.length > 0
  },

  isAuthConfirm() {
    return this.typeDialog === DIALOG.AUTH
  },

  isItemTypeDialog() {
    return this.typeDialog === DIALOG.ITEM
  },

  isSwipeDialog() {
    return gaApp.mq('tablet-') && !this.isAuthConfirm
  },
})

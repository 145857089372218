import { ACTIONS } from '~/modules/home/analytics/constants'
import { GtmBaseModel } from '~/modules/home/analytics/models/gtm/gtm-base.model/gtm-base.model'

export class GtmGa4InfoBlockBaseModel extends GtmBaseModel {
  constructor({ eventCategory, actionLabel, blockPosition, actionType }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const position = `block_${blockPosition}`

    this.event_category = eventCategory
    this.action_type = actionType
    this.action_label = actionLabel
    this.position = position
  }
}

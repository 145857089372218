<template>
  <svg viewBox="0 0 33 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M10.056 19.5C4.602 19.5.21 15.231.21 10 .21 4.77 4.602.5 10.055.5c2.069 0 3.986.616 5.569 1.665-2.222 1.92-3.626 4.717-3.626 7.836 0 3.118 1.404 5.915 3.626 7.835a10.048 10.048 0 0 1-5.569 1.665ZM19.89 10c0 2.886-1.334 5.475-3.447 7.22-2.113-1.745-3.446-4.334-3.446-7.22 0-2.885 1.333-5.474 3.446-7.22C18.556 4.526 19.89 7.115 19.89 10Zm-2.627 7.836c2.223-1.92 3.627-4.717 3.627-7.835 0-3.119-1.404-5.916-3.627-7.836A10.051 10.051 0 0 1 22.833.5c5.454 0 9.845 4.27 9.845 9.5 0 5.231-4.391 9.5-9.845 9.5a10.05 10.05 0 0 1-5.569-1.664Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-mastercard',
}
</script>

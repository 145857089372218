import { computed, unref } from 'vue'

import { VISIBLE_MAX_COUNT } from '../consts'

export const useMods = (size, options, forceCompact) => {
  const optionsWithLongText = computed(() =>
    unref(options).some((item) => item.length > 5),
  )

  const mods = computed(() => ({
    size: unref(size),
    compact:
      unref(options).length > VISIBLE_MAX_COUNT ||
      unref(forceCompact) ||
      unref(optionsWithLongText),
  }))

  return {
    mods,
  }
}

export const routes = [
  {
    moduleType: 'cart',
    pageType: 'cart',
    path: '/cart',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () => import('../components/pages/cart.vue'),
  },
  {
    moduleType: 'cart',
    pageType: 'share',
    path: '/sharing/basket/:id',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () => import('../components/pages/share.vue'),
  },
]

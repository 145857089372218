import { computed, unref } from 'vue'

/**
 * Конструирует имя варианта
 * @param {Ref<Boolean>} active - флаг активности
 * @param {Ref<Boolean>} withShift - модификатор смещения
 */
export const useTransitionName = (active, withShift) => {
  const transitionName = computed(() => {
    let finalName = ''

    if (unref(active)) {
      finalName += 'active'
    }

    if (unref(withShift)) {
      finalName += '-with-shift'
    }

    return finalName
  })

  return {
    transitionName,
  }
}

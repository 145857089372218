import { computed, unref } from 'vue'

import { useTransitionProps } from '@ga/use/transition-props'

export const useTransition = (transitionProps) => {
  const transitionPropsBuilder = useTransitionProps()

  const transitionPropsInternal = computed(() => {
    if (!unref(transitionProps)) {
      return transitionPropsBuilder('expand')
    }

    return unref(transitionProps)
  })

  return {
    transitionPropsInternal,
  }
}

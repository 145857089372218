import isString from 'lodash/isString'

import { withMessage } from '@ga/validation'

const part = '[A-z0-9_-]+'
const emailRegExp = new RegExp(`^(${part}\\.)*${part}@(${part}\\.)+[A-z]{2,}$`)

export default withMessage(
  (value) => {
    if (!value || !isString(value)) {
      return true
    }

    return emailRegExp.test(value)
  },
  {
    type: 'email',
    message: 'app.validation.email',
  },
)

<template>
  <svg fill="none" stroke="none" viewBox="0 0 47 30">
    <g clip-path="url(#pay-methods-yandex-pay-a)">
      <path
        fill="#000"
        fill-rule="evenodd"
        d="M31.875 1.406H15C7.492 1.406 1.406 7.492 1.406 15c0 7.508 6.086 13.594 13.594 13.594h16.875c7.508 0 13.594-6.086 13.594-13.594 0-7.508-6.086-13.594-13.594-13.594ZM15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15h16.875c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15H15Z"
        clip-rule="evenodd"
      />
      <path
        fill="#000"
        fill-rule="evenodd"
        d="M36.334 15.119c0-2.749-.961-5.324-2.46-7.483l.897-.622c1.603 2.31 2.654 5.098 2.654 8.105 0 2.902-.963 5.59-2.466 7.858l-.91-.603c1.404-2.118 2.285-4.599 2.285-7.255Zm-3.925.056c0-2.182-.727-4.19-1.944-5.804l.871-.657a10.697 10.697 0 0 1 2.163 6.46c0 2.28-.706 4.387-1.929 6.125l-.892-.627a9.498 9.498 0 0 0 1.73-5.497Zm-5.063-4.227a6.788 6.788 0 0 1 .26 8.173l.89.629a7.88 7.88 0 0 0-.298-9.483l-.852.681Z"
        clip-rule="evenodd"
      />
      <rect
        width="18.75"
        height="18.75"
        x="5.625"
        y="5.625"
        fill="#FC3F1D"
        rx="9.375"
      />
      <path
        fill="#fff"
        d="M16.321 20.634h1.959V9.384h-2.85c-2.864 0-4.37 1.473-4.37 3.642 0 1.732.826 2.752 2.299 3.804L10.8 20.634h2.12l2.85-4.257-.988-.664c-1.197-.809-1.78-1.44-1.78-2.8 0-1.198.842-2.007 2.444-2.007h.874v9.728Z"
      />
    </g>
    <defs>
      <clipPath id="pay-methods-yandex-pay-a">
        <path fill="#fff" d="M0 0h46.875v30H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-yandex-pay',
}
</script>

import { PAGE_NOT_FOUND } from '~/modules/review/constants/listing'

/**
 *
Сервис предоставляет методы для получения данных страницы, обработки навигации и перенаправления на страницы ошибок:

`getData(redirect, context)` - асинхронный метод, который получает данные страницы в зависимости от значения `redirect.pageType`:

- Если `redirect.pageType` равен 'submit', вызывается метод `gaApp.services.review.products.getProductsForm(context)` для получения данных формы отправки отзыва.
- Если `redirect.pageType` равен 'listing', вызывается метод `gaApp.services.review.listing.getPageData({ redirect, context })` для получения данных страницы списка отзывов.

`onBack()` - метод, который обрабатывает навигацию назад. Если есть сохраненный путь `fromRoutePath` в store `products` и он отличается от текущего пути, выполняется переход назад. В противном случае выполняется переход на главную страницу с помощью метода `toMainPage()`.

`toMainPage()` - метод, который перенаправляет пользователя на главную страницу.

`toErrorPage({ statusCode = 500 } = {})` - метод, который перенаправляет пользователя на страницу ошибки с указанным кодом состояния.
 */

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect, context) {
    try {
      // если отзывы отключены то ридеректим на 404
      if (!this.gaApp.features.get('isReviewAvailable')) {
        throw new Error(PAGE_NOT_FOUND)
      }

      switch (redirect.pageType) {
        case 'submit': {
          return await this.gaApp.services.review.products.getProductsForm(
            context,
          )
        }
        case 'listing': {
          return await this.gaApp.services.review.listing.getPageData({
            redirect,
            context,
          })
        }

        default:
          return undefined
      }
    } catch (error) {
      const isErrorNotFound =
        error?.statusCode === 404 || error?.message === PAGE_NOT_FOUND
      const statusCode = isErrorNotFound ? 404 : 500

      this.toErrorPage({ statusCode })
    }
  }

  onBack() {
    const { fromRoutePath } = this.gaApp.stores.review.products
    if (fromRoutePath && fromRoutePath !== this.gaApp.route.fullPath) {
      // this.gaApp.router.replace(fromRoutePath)
      this.gaApp.router.back()
    } else {
      this.toMainPage()
    }
  }

  toMainPage() {
    window?.location?.assign('/')
  }

  toErrorPage({ statusCode = 500 } = {}) {
    this.gaApp.redirectError({
      statusCode,
    })
  }
}

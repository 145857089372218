/*
 * Данная утилита позволяет задать сообщение об ошибке для валидатора при его объявлении.
 */

import { helpers } from '@vuelidate/validators'

export const withMessage = (validator, params = {}) => {
  return (message = params.message) =>
    helpers.withParams(
      params,
      message ? helpers.withMessage(message, validator) : validator,
    )
}

<template>
  <svg viewBox="0 0 40 25">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M19.518 10.622c-.021 1.568 1.482 2.443 2.615 2.963 1.164.533 1.555.876 1.55 1.353-.009.73-.928 1.053-1.789 1.065-1.501.022-2.374-.382-3.068-.687l-.54 2.385c.696.302 1.985.566 3.322.578 3.138 0 5.191-1.46 5.202-3.724.013-2.873-4.216-3.032-4.187-4.316.01-.39.404-.805 1.268-.91.428-.054 1.608-.095 2.946.486l.525-2.308a8.441 8.441 0 0 0-2.796-.483c-2.954 0-5.031 1.48-5.048 3.598Zm12.891-3.4c-.573 0-1.056.316-1.271.8l-4.483 10.087h3.136l.624-1.625h3.832l.362 1.625h2.764L34.961 7.223H32.41Zm.439 2.941.905 4.088h-2.479l1.574-4.088Zm-17.132-2.94-2.472 10.886h2.988l2.47-10.886h-2.986Zm-4.421 0-3.11 7.41-1.259-6.301c-.147-.703-.73-1.11-1.378-1.11H.464l-.071.317c1.043.213 2.23.557 2.948.926.44.225.565.422.71.956l2.382 8.688h3.159l4.841-10.886h-3.138Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-visa',
}
</script>

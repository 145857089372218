import isEmpty from 'lodash/isEmpty'

import { computed } from 'vue'

const normalizeColor = (color) => {
  if (!color) {
    return null
  }

  return color ? [color] : []
}

const normalizeUnit = (unit) => {
  if (!unit) {
    return null
  }

  return `${unit.value || ''} ${unit.name || ''}`.toUpperCase()
}

export const useAttributes = (props) => {
  const { attributes } = props.product

  const color = computed(() => normalizeColor(attributes?.color))
  const unit = computed(() => normalizeUnit(attributes?.unit))

  const hasColor = !isEmpty(color.value)
  const hasUnit = !isEmpty(unit.value)

  return {
    color,
    unit,

    hasColor,
    hasUnit,
  }
}

export const state = () => {
  return {
    data: {},

    addresses: [],

    isBot: false,

    isAuthorized: false,

    isAdult: false,
    isAdultWasAsked: false,

    // id просмотра пейджа. К профилю пользователя параметр не привязан
    viewGUID: null,
  }
}

export { GaListingProductRating } from './molecules/listing-product-rating'

export const GaReviewListingProductRating = () =>
  import(
    /* webpackChunkName: 'module/review/GaReviewListingProductRating' */
    './molecules/listing-product-rating'
  ).then(({ GaListingProductRating }) => GaListingProductRating)
export const GaModuleReviewPreview = () =>
  import(
    /* webpackChunkName: 'module/review/GaModuleReviewPreview' */
    './organisms/preview'
  ).then(({ GaReviewPreview }) => GaReviewPreview)

export const GaModuleReviewRatingPreview = () =>
  import(
    /* webpackChunkName: 'modules/review/GaModuleReviewRatingPreview' */
    './organisms/rating-preview'
  ).then(({ GaReviewRatingPreview }) => GaReviewRatingPreview)

export const GaModuleReviewProductsModal = () =>
  import(
    /* webpackChunkName: 'modules/review/GaModuleReviewProductsModal' */
    './organisms/products-modal'
  ).then(({ GaReviewProductsModal }) => GaReviewProductsModal)

export const getHomeStateInitial = () => ({
  pageHeading: '',

  immediateShowBlocks: false, // для принудительного рендра всех блоков
  immediateScrollAnchor: null, // храним id блока, к которому надо проскролить
  scrollAnchor: null, // храним id блока, который показан на экране

  analyticData: {}, // храним данные аналитики для использования после перехода на другие страницы
})

export default () => {
  return getHomeStateInitial()
}

export const endpoints = {
  slots: () => ({
    url: `home/slots`,
  }),
  block: () => ({
    url: `home/block`,
  }),
  storyItem: (id) => ({
    url: `home/story/${id}`,
  }),
  redirect: (id) => ({
    url: `catalog/redirect`,
  }),
}

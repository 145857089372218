export const getters = (gaApp) => ({
  isEmpty() {
    return Boolean(
      this.queryOriginal &&
        !this.resultFirst?.length &&
        !this.resultSecond?.length,
    )
  },

  isEmptyV2() {
    return Boolean(!this.searchResultsV2?.length && this.queryOriginal)
  },

  isSearching() {
    return Boolean(this.queryOriginal)
  },
})

export const getters = (gaApp) => ({
  itemsDefault() {
    return gaApp.features.get('addressTopCitiesList')
      ? gaApp.stores.location.modal.citiesData.cities
      : gaApp.stores.app.common.cities
  },

  itemsState(state) {
    return state.isDefaultState ? this.itemsDefault : state.items
  },
})

<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path
      d="M10.53 6.324 8.175 3.97M2 12.5v-2.353L9.647 2.5 12 4.853 4.353 12.5H2Z"
    />
    <path stroke-width="1.067" d="M8 12.5h4.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-edit',
}
</script>

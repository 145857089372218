import { TYPE } from '../../constants'
import { GaAnalyticsProductGetModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class GaAnalyticsProductGetBuilder extends BaseBuilder {
  constructor(product) {
    const models = new Map([[TYPE.GAA, GaAnalyticsProductGetModel]])
    super(models, product)
  }
}

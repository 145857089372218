<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M18.519 5.703c.124-.405 0-.703-.594-.703h-1.961c-.499 0-.729.257-.854.54 0 0-.997 2.371-2.41 3.91-.457.446-.665.588-.915.588-.124 0-.305-.142-.305-.547V5.703c0-.486-.145-.703-.56-.703H7.837c-.311 0-.499.226-.499.44 0 .46.707.567.78 1.863v2.816c0 .618-.115.73-.364.73-.665 0-2.283-2.381-3.242-5.106C4.324 5.213 4.135 5 3.634 5H1.673C1.113 5 1 5.257 1 5.54c0 .507.665 3.02 3.096 6.342 1.621 2.269 3.905 3.498 5.983 3.498 1.247 0 1.401-.273 1.401-.743v-1.715c0-.546.118-.655.513-.655.291 0 .79.141 1.954 1.235 1.33 1.297 1.549 1.878 2.297 1.878h1.962c.56 0 .84-.273.678-.812-.176-.537-.811-1.317-1.654-2.24-.457-.527-1.143-1.095-1.35-1.378-.292-.365-.209-.527 0-.851 0 0 2.39-3.282 2.639-4.396Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-vk',
}
</script>

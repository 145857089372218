/*
 * Oggetto
 *
 * TODO:
 *  - доработать построение `href` c учётом трансграничной доставки
 */

import { GaLinkBase } from '../link-base'

// @vue/component
export default {
  name: 'ga-link-phone',

  components: {
    GaLinkBase,
  },

  inheritAttrs: false,

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  computed: {
    href() {
      const sanitizedPhone = this.phone.replace(/[\s+,-]/g, '')

      return `tel:${sanitizedPhone}`
    },
  },
}

<template>
  <svg stroke="none" viewBox="0 0 15 15">
    <path
      fill-rule="evenodd"
      d="M1.636 7.499C3.559 9.883 5.626 11 7.5 11c2.209 0 4.286-1.527 5.866-3.493C11.465 5.112 9.776 4 7.396 4 5.25 4 3.228 5.513 1.637 7.499ZM.603 7.197C2.28 4.994 4.659 3 7.397 3c2.93 0 4.917 1.489 7 4.203l.233.303-.232.304C12.73 10.001 10.296 12 7.501 12 5.118 12 2.696 10.531.603 7.804L.37 7.501l.232-.304ZM7.5 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM5 7.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-eye-opened',
}
</script>

import { EVENT, EVENT_CATEGORY } from '../../../constants'

export class GtmGenerateMoreDesignButtonClickModel {
  constructor({ succeedGeneration }) {
    this.event = EVENT.USER_INTERACTION
    this.event_category = EVENT_CATEGORY
    this.action_type = 'click_more_generate_design'
    this.success = Number(succeedGeneration)
  }
}

export class DigiAnalyticsSearchEventModel {
  constructor({
    searchTerm,
    originalSearchTerm,
    pageNumber,
    pageProducts,
  
  }) {
    this.data = {
      eventType: 'SEARCH_EVENT',
      searchTerm,
      originalSearchTerm,
      pageNumber,
      pageProducts,
    }
  }
}

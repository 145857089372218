import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { VERTICAL_CARD_SIZE } from '~/modules/product-card'

import { SIZE as WIDGET_SLIDER_SIZE } from './const'

export const useWithSidebarSliderLayout = () => {
  const { $gaApp } = useContext()

  const tillTablet = computed(() => $gaApp.mq('tablet-'))
  const tillTabletLarge = computed(() => $gaApp.mq('tablet-large-'))

  const sliderSize = computed(() => {
    switch (true) {
      case unref(tillTablet):
        return WIDGET_SLIDER_SIZE.S
      case unref(tillTabletLarge):
        return WIDGET_SLIDER_SIZE.M
      default:
        return WIDGET_SLIDER_SIZE.L
    }
  })

  const sliderCardSize = computed(() => {
    if (unref(sliderSize) === WIDGET_SLIDER_SIZE.S) {
      return VERTICAL_CARD_SIZE.M
    }

    return VERTICAL_CARD_SIZE.S
  })

  return {
    sliderCardSize,
    sliderSize,
  }
}

export const PHONE_FORMAT = {
  NATIONAL: 'national',
  INTERNATIONAL: 'international',
}

export const PHONE_INITIAL = ''
export const PHONE_MASK_CHAR = '0'
export const PHONE_PLACEHOLDER_CHAR = '_'

export const MIN_PHONE_CODES_LENGTH = 1

import { GaAnalyticsBaseModel } from '../ga-analytics-base.model/ga-analytics-base.model'

export class GaAnalyticsPreviewDetailsClickModel extends GaAnalyticsBaseModel {
  constructor({ productData, analyticsData }) {
    super('product')

    this.item = {
      ...analyticsData,
      itemId: productData.id,
      itemListName: productData.itemListName,
    }

    this.debug = {
      key: 'pdp-preview-details-click',
      data: productData,
    }
  }
}

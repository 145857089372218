import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_FULL_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from '~/modules/header'

import { MEDIA_TYPE } from '../../const'

export const useHeaderProps = (mediaType) => {
  const { $gaApp } = useContext()

  const headerExtended = computed(() => {
    return $gaApp.stores.header.main.headerExtended
  })

  const headerHeight = computed(() => {
    return {
      [MEDIA_TYPE.DESKTOP]: HEADER_HEIGHT_FULL_DESKTOP,
      [MEDIA_TYPE.TABLET_LANDSCAPE]: HEADER_HEIGHT_DESKTOP,
      [MEDIA_TYPE.TABLET]: HEADER_HEIGHT_MOBILE,
      [MEDIA_TYPE.MOBILE]: HEADER_HEIGHT_MOBILE,
    }[unref(mediaType)]
  })

  const headerHeightCollapsed = computed(() => {
    return {
      [MEDIA_TYPE.DESKTOP]: HEADER_HEIGHT_DESKTOP,
      [MEDIA_TYPE.TABLET_LANDSCAPE]: HEADER_HEIGHT_DESKTOP,
      [MEDIA_TYPE.TABLET]: HEADER_HEIGHT_MOBILE,
      [MEDIA_TYPE.MOBILE]: HEADER_HEIGHT_MOBILE,
    }[unref(mediaType)]
  })

  return {
    headerHeight,
    headerExtended,
    headerHeightCollapsed,
  }
}

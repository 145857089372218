import { TYPE } from '../../constants'
import { DigiAnalyticsAutocompleteClickModel } from '../../models'
import { BaseBuilder } from '../base/base.builder'

export class DigiAutocompleteBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map()

    models.set(TYPE.DIGI, DigiAnalyticsAutocompleteClickModel)

    super(models, data)
  }
}

<template>
  <svg stroke="none" viewBox="0 0 24 24">
    <path
      fill="inherit"
      fill-rule="evenodd"
      d="M17.5 11a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Zm-1.398 6.162a8 8 0 1 1 1.06-1.06l4.368 4.368-1.06 1.06-4.368-4.368Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-24-search',
}
</script>

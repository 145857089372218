import { CURRENCY_CODE_BYN, CURRENCY_CODE_BYR } from '@ga/constants/currency'

export const TYPE = {
  GTM_4: 'gtm_4',
  DIGI: 'digi_analytics',
  ADVCAKE: 'advcake',
}

export const EVENT = {
  THANK_YOU_VIEW: {
    GA4: 'purchase',
    DIGI: 'ORDER_SUCCESS',
  },
}

export const CURRENCY_CODE = {
  [CURRENCY_CODE_BYR]: CURRENCY_CODE_BYN,
}

import { toRefs } from 'vue'
import { IMaskComponent as ImaskInput } from 'vue-imask'

import { useInput } from '@ga/use/input'

import {
  GaIconAdditionalCrossTiny,
  GaIconAdditionalSelectArrow,
} from '@ga/icons'

import { GaButtonBase } from '../button-base'
import { GaIcon } from '../icon'

import {
  INPUTMODE,
  MASK_CONFIG,
  SIZE,
  THEME,
  TYPE,
} from './scripts/const/index'

// @vue/component
export default {
  name: 'ga-new-input',

  components: {
    ImaskInput,
    GaButtonBase,
    GaIcon,
    GaIconAdditionalCrossTiny,
    GaIconAdditionalSelectArrow,
  },

  inheritAttrs: false,

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    type: {
      type: String,
      default: TYPE.TEXT,
      validator: (value) => Object.values(TYPE).includes(value),
    },
    theme: {
      type: String,
      default: THEME.DEFAULT,
      validator: (value) => Object.values(THEME).includes(value),
    },
    inputmode: {
      type: String,
      default: INPUTMODE.TEXT,
      validator: (value) => Object.values(INPUTMODE).includes(value),
    },
    mask: {
      type: [String, Function, RegExp],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    placeholderChar: {
      type: String,
      default: MASK_CONFIG.PLACEHOLDER_CHAR,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: null,
    },
    prefixAliases: {
      type: Array,
      default: null,
    },
    unmask: {
      type: Boolean,
      default: true,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    noMaskSpacing: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, listeners }) {
    const {
      value,
      size,
      theme,
      error,
      disabled,
      clear,
      highlight,
      mask,
      prefix,
      prefixAliases,
      maxLength,
      placeholderChar,
      isRequired,
      noMaskSpacing,
    } = toRefs(props)

    return useInput(
      value,
      size,
      theme,
      error,
      disabled,
      clear,
      highlight,
      mask,
      prefix,
      prefixAliases,
      emit,
      listeners,
      maxLength,
      placeholderChar,
      isRequired,
      noMaskSpacing,
    )
  },
}

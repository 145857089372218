import { EYEZON_ROOT_ID } from '../constants'

/**
 * Сервис отвечает за визуальное представление
 * и стили компонента eyeZon в приложении.
 * Проверяет наличие активных диалогов и добавляет
 * соответствующие стили к корневому элементу eyeZon,
 * чтобы правильно отображать или скрывать виджет.
 */

export class VisualService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get isVisibleWidget() {
    return this.gaApp.stores.eyezon.main.hasActiveDialog
  }

  // проверяем видимость виджета
  checkVisibleWidget() {
    this.gaApp.stores.eyezon.main.hasActiveDialog =
      this.gaApp.services.cache.main.storage.get('Eyezon-Button.1.dialogs')
        ?.active?.length > 0
  }

  // добавляем доп стили в айзон
  addStyles(node) {
    this.checkVisibleWidget()

    const elementById = node || document.getElementById(EYEZON_ROOT_ID)

    if (elementById) {
      elementById.setAttribute('style', 'z-index: 1000')
      elementById.shadowRoot.appendChild(this._getStyles())
    }
  }

  // правим стили веб компонента виджета по тз
  _getStyles() {
    const styleElement = document.createElement('style')

    const baseText =
      '#eyezon #eye-button.BOTTOM_RIGHT {right: 20px; bottom: 75px;'
    const additionalText = this.isVisibleWidget
      ? 'display: block; }'
      : 'display: none; }'

    styleElement.type = 'text/css'
    styleElement.textContent = `${baseText} ${additionalText}`

    return styleElement
  }
}

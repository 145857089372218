import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4PreviewClickModel extends GtmBaseModel {
  constructor() {
    super({})

    this.action_type = 'click_preview'
    this.type = 'kepk'
  }
}

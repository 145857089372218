<template>
  <svg viewBox="0 0 15 15">
    <path
      d="m7.501.5 2.057 4.168 4.6.669-3.328 3.245.786 4.581L7.5 11l-4.114 2.163.785-4.581L.844 5.337l4.6-.669L7.5.5Z"
      fill="currentColor"
      stroke="transparent"
    />
    <path
      d="M7.501.5V11l-4.114 2.163.785-4.581L.844 5.337l4.6-.669L7.5.5Z"
      fill="currentColor"
      stroke="transparent"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-star-with-half',
}
</script>

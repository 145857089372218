<template>
  <svg fill="none" stroke="currentcolor" viewBox="0 0 28 20">
    <path stroke="inherit" stroke-width="1.5" d="M5 10h22" />
    <path stroke="inherit" stroke-width="1.5" d="m13 2-8 8 8 8" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-20-arrow-back',
}
</script>

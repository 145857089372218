import { unref } from 'vue'

import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
    }
  }

  getNormalizeMenuData(data) {
    const brandName = this.gaApp.services.brandzone.main.getBrandName()

    const { item, screen, screens } = data

    // Собираем путь до item
    const screensPath = unref(screens)
      .flatMap((scr) => (scr.name ? scr.name : []))
      .join('/')

    const path = (() => {
      // Если кликнули на "все товары категории" корневой категории
      if (!screen.link) {
        return ''
      }

      // Если кликнули на "все товары категории" вложенной категории
      if (screen.link === item.link) {
        return '/' + screensPath
      }

      // Если кликнули на вложенную категорию
      if (screensPath.length) {
        return '/' + screensPath + '/' + item.name
      }

      return '/' + item.name
    })()

    const itemListName = `брендзона/${brandName}${path}`

    return {
      url: item.link,
      name: item.name,
      path,
      itemListName,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]
      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}

import { EVENT_CATEGORY_BTS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmBtsProductClickModel extends GtmBaseModel {
  constructor({ listName, itemSku }) {
    super({ eventCategory: EVENT_CATEGORY_BTS })

    this.action_type = 'click_product'
    this.list_name = listName
    this.item_sku = itemSku
  }
}

import { GaAnalyticsBaseModel } from '../ga-analytics-base.model/ga-analytics-base.model'

export class GaAnalyticsProductGetModel extends GaAnalyticsBaseModel {
  constructor(data) {
    super('product')

    const { itemId } = data

    this.itemId = itemId
  }
}

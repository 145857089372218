export const getters = () => ({
  productSliderData() {
    const result = {}
    this.items.forEach((item, index) => {
      const content = item?.data?.content
      const additionalSection = content?.additionalSection
      const products = content?.products ?? []

      let sliderData = products

      // если есть buttonName, то добавляем к данным по слайдам products данные слайда со ссылкой
      const buttonName = additionalSection?.buttonName ?? ''
      const link = content?.link ?? ''
      if (buttonName && link) {
        const title = additionalSection?.title ?? ''
        const linkCardData = {
          link,
          buttonName,
          title,
        }

        sliderData = [...products, linkCardData]
      }

      // добавляем index в изначальном массиве items стора
      const name = item?.data?.name ?? ''
      result[item.id] = {
        index,
        data: {
          name,
          link,
          products: sliderData,
        },
      }
    })

    return result
  },
})

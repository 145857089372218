<template>
  <svg fill="none" stroke="none" viewBox="0 0 21 20">
    <path
      fill="#000"
      d="M21 0h-2.71v16.604H21V0ZM2.71 3.396H0V20h2.71V3.396Zm6.096-1.132h-2.71v16.604h2.71V2.264Zm6.097-1.132h-2.71v16.604h2.71V1.132Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-dolyame',
}
</script>

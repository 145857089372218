import { doubleRAF } from '@ga/shared-browser'

export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp

    gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      doubleRAF(() => {
        this.close()
      })
    })
  }

  open() {
    this.gaApp.stores.support.modal.opened = true
  }

  close() {
    this.gaApp.stores.support.modal.opened = false
  }
}

<template>
  <svg fill="none" viewBox="0 0 16 15">
    <path
      stroke="currentColor"
      stroke-width="2"
      d="m4.484 6.705 3.462 3 5.538-5.4"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-checked',
}
</script>

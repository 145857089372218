import { attributes, image } from '../../../../../utils/schemas'

export const favorite = {
  type: 'object',
  properties: {
    productId: { type: 'string', required: true },
    favourite: { type: 'boolean', required: true },
    name: { type: 'string', required: true },
    brand: { type: 'string', required: true },
    attributes,
    imageUrls: { type: 'array', items: image, required: true },
  },
}

<template>
  <svg stroke="none" viewBox="0 0 15 15">
    <path
      fill-rule="evenodd"
      d="M1.395 5.195C3.385 7.788 5.546 9 7.498 9c2.32 0 4.496-1.687 6.102-3.796l.796.606a14.05 14.05 0 0 1-1.862 2.019l1.232 1.231-.707.708L11.75 8.46a8.636 8.636 0 0 1-1.924 1.08l.618 1.236-.894.447-.69-1.38A6.046 6.046 0 0 1 7.497 10c-.453 0-.908-.053-1.362-.158l-.69 1.381-.895-.447.619-1.237a8.593 8.593 0 0 1-1.923-1.08l-1.31 1.309-.707-.708 1.235-1.234A14.404 14.404 0 0 1 .602 5.804l.793-.609Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-eye-closed',
}
</script>

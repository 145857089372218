import {
  BreadcrumbClickBuilder,
  GetListingAnalyticsBuilder,
  PageLeaveProductViewBuilder,
  PageViewBuilder,
  ProductCardMediaBuilder,
  ProductCartBuilder,
  ProductClickBuilder,
  ProductFavoriteBuilder,
  ProductViewBuilder,
  SearchEventBuilder,
  SearchResultBuilder,
} from '../../builders'
import { CHUNK_SIZE_ANALYTICS } from '../../constants'
import { ViewedProducts } from '../../helpers'
import { BaseController } from '../base.controller/base.controller'

export class PlpAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.viewedProduct = new ViewedProducts()
  }

  /**
   * При входе на страницу, делаем запрос за данными аналитики
   */
  async onPageInit(data) {
    const results = await this.get(GetListingAnalyticsBuilder, data)

    return results.reduce((accumulator, current) => {
      const { key, data } = current.value

      accumulator[key] = data
      return accumulator
    }, {})
  }

  /**
   * Клик по товару
   */
  onProductClick(product, analyticData) {
    const data = { ...this.getProductData(product), analyticData }

    this.send(ProductClickBuilder, data)
  }

  /**
   * Добавление продукта в корзину.
   */
  onAddToCart(product, isAdded, analyticData) {
    const data = { ...this.getProductData(product, isAdded), analyticData }

    this.send(ProductCartBuilder, { ...data, addToCart: true })
  }

  /**
   * Добавление продукта в избранное.
   */
  onAddToFavorite(product, analyticData) {
    const data = { ...this.getProductData(product), analyticData }

    this.send(ProductFavoriteBuilder, data)
  }

  /**
   * Просмотр товаров
   */
  onProductView(data, analyticData) {
    const product = this.getProductData(data)
    const chunk = this.viewedProduct
      .setViewed(data)
      .getLastChunk()
      .map((product) => this.getProductData(product))

    const products = this.viewedProduct
      .getAllProducts()
      .map((product) => this.getProductData(product))

    this.send(ProductViewBuilder, {
      product,
      chunk,
      products,
      analyticData,
      searchTermAnalytics: this.searchTerm,
    })
  }

  /**
   * При уходе со страницы
   */
  onPageLeaveProductView() {
    this.referer = null
    this.searchTerm = null

    /**
     * Если при уходе со страницы, юзер увидел 1-4 товара,
     * то нужно отправить об этом событие в аналитику
     */
    const products = this.viewedProduct.getAllProducts()

    if (products.length && products.length < CHUNK_SIZE_ANALYTICS) {
      const chunk = products.map((product) => this.getProductData(product))

      this.send(PageLeaveProductViewBuilder, { chunk })
    }

    // Очищаем список просмотренных продуктов
    this.viewedProduct.clearProducts()
  }

  /**
   * При входе на страницу PLP
   */
  onMounted(data) {
    this.referer = this.gaApp.route.query?.referer
    this.searchTerm = this.gaApp.route.query?.search_term
    this.send(PageViewBuilder, data)
  }

  /**
   * При клике хлебную крошку в PLP
   */
  onBreadcrumbClick(breadcrumbs) {
    const data = this.getBreadcrumbsData(breadcrumbs)

    this.send(BreadcrumbClickBuilder, data)
  }

  /**
   * При получении результатов поиска один раз для query
   */
  onSearchResult() {
    this.referer = this.gaApp.route.query?.referer

    const itemsCount = this.gaApp.stores.plp.main.getProductsCount
    const searchTerm = this.gaApp.stores.plp.main.pageData?.params?.query

    this.send(SearchResultBuilder, {
      itemsCount,
      searchTerm,
    })
  }

  /**
   * При получении результатов поиска постранично
   */
  onSearchProducts(products, pageNumber) {
    if (!products || !pageNumber) return

    const state = this.gaApp.stores.plp.main

    const originalSearchTerm = state.pageData?.params?.query
    const searchTerm = state.searchQueryCorrected || originalSearchTerm

    const pageProducts = products?.map(({ itemId }) => itemId)

    this.send(SearchEventBuilder, {
      pageNumber: String(pageNumber),
      pageProducts,
      originalSearchTerm,
      searchTerm,
    })
  }

  /**
   * Начало проигрывания видео на карточке продукта
   */
  onStartPlayingVideo({ productId }) {
    this.send(ProductCardMediaBuilder, {
      productId,
      action: 'start',
    })
  }

  /**
   * Окончание проигрывания видео на карточке продукта
   */
  onEndPlayingVideo({ productId }) {
    this.send(ProductCardMediaBuilder, {
      productId,
      action: 'viewed',
    })
  }
}

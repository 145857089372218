export const endpoints = {
  getDelivery: () => ({
    url: 'delivery/calculate/item',
  }),
  subscribe: () => ({
    url: 'catalog/subscribe',
  }),
  getProductDescription: () => ({
    url: 'catalog/product/description',
  }),
}

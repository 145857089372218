export class GtmGaUaProductBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const {
      id,
      name,
      brand,
      inStock,
      list,
      price,
      variant,
      plpType,
      category,
      position,
    } = product

    if (variant) {
      this.variant = variant
    }

    this.id = id
    this.name = `${brand} ${name}`
    this.list = list
    this.brand = brand
    this.price = price
    this.category = category
    this.position = position
    this.dimension4 = inStock
    this.plp_type = plpType
  }
}

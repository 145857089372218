export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchBrands(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.brands()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchBrandsV2(parameters = {}, requestParams = {}) {
    const { url } = this.endpoints.brandsV2()

    try {
      const response = await this.gaApp.api.request(requestParams).get(url, {
        params: parameters,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchBrandsFavorites(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.brandsFavorites()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchBrandsFavoritesV2() {
    const { url } = this.endpoints.brandsFavoritesV2()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { STATE } from '../../constants'

export const getters = (gaApp) => ({
  headerCurrentColorSet(state) {
    return state.headerColorScheme[state.headerState]
  },

  headerInitialColorSet(state) {
    return state.headerColorScheme[STATE.INITIAL]
  },

  headerScrolledColorSet(state) {
    return state.headerColorScheme[STATE.SCROLLED]
  },

  headerActiveColorSet(state) {
    return state.headerColorScheme[STATE.ACTIVE]
  },
})

<template>
  <svg viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="10" stroke="none" />
    <path
      fill="#fff"
      fill-rule="evenodd"
      stroke="none"
      d="m10.85 10 3.048-3.048-.85-.848-3.046 3.047-3.048-3.047-.848.848L9.153 10l-3.048 3.048.849.848 3.048-3.047 3.047 3.048.849-.849L10.85 10Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-coupon-cancel-v1',
}
</script>

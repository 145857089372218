<template>
  <svg fill="none" viewBox="0 0 29 29">
    <g filter="url(#video-player-subtitle-disabled-a)">
      <path
        stroke="inherit"
        stroke-width="1.5"
        d="M2.75 2.75h23.5v23.5H2.75V2.75Z"
      />
      <path
        fill="#fff"
        stroke="none"
        d="M18.69 18.5c-2.368 0-3.69-1.554-3.69-3.946v-.087c0-2.337 1.554-3.967 3.67-3.967 1.733 0 3.076.891 3.245 2.717h-1.427c-.159-1.043-.709-1.565-1.808-1.565-1.322 0-2.2 1.065-2.2 2.805v.086c0 1.75.804 2.783 2.21 2.783 1.058 0 1.777-.543 1.935-1.641H22c-.222 1.88-1.502 2.815-3.31 2.815Z"
      />
      <path
        fill="#fff"
        stroke="none"
        d="M10.69 18.5C8.322 18.5 7 16.946 7 14.554v-.087C7 12.13 8.554 10.5 10.67 10.5c1.733 0 3.076.891 3.245 2.717h-1.427c-.159-1.043-.708-1.565-1.808-1.565-1.322 0-2.2 1.065-2.2 2.805v.086c0 1.75.804 2.783 2.21 2.783 1.058 0 1.777-.543 1.935-1.641H14c-.222 1.88-1.502 2.815-3.31 2.815Z"
      />
    </g>
    <defs>
      <filter
        id="video-player-subtitle-disabled-a"
        width="29"
        height="29"
        x="0"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3186_4356"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3186_4356"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-subtitle-disabled',
}
</script>

import { render, staticRenderFns } from "./product-slider.vue?vue&type=template&id=a2e16f76"
import script from "./product-slider.script.js?vue&type=script&lang=js&external"
export * from "./product-slider.script.js?vue&type=script&lang=js&external"
import style0 from "./product-slider.style.scss?vue&type=style&index=0&prod&module=true&lang=scss&external"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
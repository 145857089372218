import { AddressesRepository } from './addresses.repository'
import {
  addressesEndpoints,
  mainEndpoints,
  retailStoresEndpoints,
} from './endpoints'
import { MainRepository } from './main.repository'
import { RetailStoresRepository } from './retail-stores.repository'

export const repositories = (gaApp) => ({
  main: new MainRepository(gaApp, mainEndpoints),
  retailStores: new RetailStoresRepository(gaApp, retailStoresEndpoints),
  addresses: new AddressesRepository(gaApp, addressesEndpoints),
})

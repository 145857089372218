export const getAttributeUnit = (units) => {
  const name = units?.name
  const value = Array.isArray(units?.values) && units.values[0]

  return value
    ? {
        name,
        value,
      }
    : false
}

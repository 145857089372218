import chunk from 'lodash/chunk'

export class ViewedProducts {
  constructor() {
    this.products = []
    this.addedProducts = {}
  }

  setViewed(product) {
    if (!this.addedProducts[product.itemId]) {
      this.products.push(product)
      this.addedProducts[product.itemId] = true
    }

    return this
  }

  getLastChunk(CHUNK_SIZE) {
    const chunks = chunk(this.products, CHUNK_SIZE)

    const lastChunk = chunks[chunks.length - 1]

    return lastChunk.length === CHUNK_SIZE ? lastChunk : []
  }

  getAllProducts() {
    return this.products
  }

  clearProducts() {
    this.products = []
    this.addedProducts = {}
  }
}

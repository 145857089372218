import { computed, ref, unref } from 'vue'

/**
 * Use для управлением рендерингом слайдов и слотов.
 *
 * Use предоставляет вычисляемые свойства для определения рендеринга элементов и того, следует ли рендерить все элементы.
 * Она также предоставляет функцию переключения флага для рендеринга остальных элементов.
 *
 * @param {Array} itemsInternal — внутренний массив элементов для рендеринга.
 */
export const useRenderedItems = (itemsInternal) => {
  const renderedRest = ref(false)

  /**
   * Изначально рендрим только два контейнера для слотов
   * После свайпа рендрим все контейнеры
   */
  const renderedItems = computed(() => {
    return unref(renderedRest) || !process.client
      ? unref(itemsInternal)
      : unref(itemsInternal).slice(0, 2)
  })

  /**
   * Изначально рендрим только активный слайд (см. шаблон)
   * После свайпа рендрим все слоты
   */
  const renderedItemsSlot = computed(() => {
    return unref(renderedRest) || !process.client
  })

  const renderRestItems = () => {
    renderedRest.value = true
  }

  return {
    renderedItems,
    renderedItemsSlot,

    renderRestItems,
  }
}

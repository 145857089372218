/**
 * @typedef {string} MediaTypes
 */

/**
 * @enum {MediaTypes}
 */
export const MEDIA_TYPES = {
  DESKTOP_LARGE: 'desktop-large',
  DESKTOP: 'desktop',
  TABLET_LARGE: 'tablet-large',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

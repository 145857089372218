import { SLOT_STATUSES } from '../constants'

export class SlotsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  updateSlotsData() {
    return this.gaApp.stores.home.slots.items.forEach((item) => {
      // перезапрашиваем данные для всех слотов в статусе pending
      // (то есть для слотов, данные по блокам которых не пришли в первом запросе fetchSlots)
      if (
        this.gaApp.stores.home.slots.statuses[item.id] === SLOT_STATUSES.PENDING
      ) {
        this.gaApp.services.home.api.updateBlockData(item.id)
      }
    })
  }

  /** обновляем статус в сторе:
   * success - если пришли корректные данные
   * pending - если пришёл null и в сторе ещё нет данных по этому id
   * failed - если пришёл null и в сторе уже null по этому id */
  updateStatus(id, data) {
    if (data === null) {
      this.gaApp.stores.home.slots.statuses[id] =
        this.gaApp.stores.home.slots.asObject[id]?.data === null
          ? SLOT_STATUSES.FAILED
          : SLOT_STATUSES.PENDING
    } else {
      this.gaApp.stores.home.slots.statuses[id] = SLOT_STATUSES.SUCCEED
    }
  }

  /** заполняем все сторы блоков */
  fillStores() {
    this.gaApp.stores.home.slots.items.forEach((block) => {
      this.fillStoresBlockData(block)
    })
  }

  /** проверяем есть ли нужный стор и добавляем/обновляем данные блока в зависимости от типа */
  fillStoresBlockData(block) {
    // проверяем есть ли нужный стор
    if (this.gaApp.stores.home[block.type]) {
      // вызываем метод fillStore соответствующего сервиса для заполнения его стора
      this.gaApp.services.home[block.type].fillStore(block)
    }
  }

  async fetchSlotsData() {
    // готовим данные по слотам для главной страницы
    await this.gaApp.services.home.api.fetchSlots()
    this.updateSlotsData()
  }
}

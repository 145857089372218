import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4FastFilterExpressChange extends GtmBaseModel {
  constructor(data) {
    super()

    const { formattedName, expressDeliveryToggleValue, itemListName } = data

    this.action_type = 'apply_filters'
    this.type = 'toggle'
    this.item_list_name = itemListName
    this[formattedName] = expressDeliveryToggleValue
  }
}

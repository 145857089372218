import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import state from './state'

export const offersStore = (gaApp) => {
  const init = defineStore('offersListing', {
    state,
    getters: getters(gaApp),
  })

  return init(getActivePinia())
}

import { computed, nextTick, ref, unref, watch } from 'vue'

import { STATE } from '../const'

/**
 * Возвращает необходимые параметры видимости
 * @param {Ref<Boolean>} opened - параметр открытия из props
 * @param {Ref<import("../const").State} state - текущее состояние модалки
 * @param {Ref<Boolean>} immediateRender - загрузка содержимого модалки в момент инициализации
 */
export const useShowParams = (opened, state, immediateRender) => {
  const displayed = computed(() => {
    if (!unref(opened)) {
      return false
    }

    return [STATE.OPENING, STATE.OPENED].includes(unref(state))
  })

  const visible = computed(() => {
    return [STATE.CLOSING, STATE.OPENING, STATE.OPENED].includes(unref(state))
  })

  const rendered = ref(unref(immediateRender))

  if (!unref(immediateRender)) {
    const unwatchOpened = watch(
      opened,
      (value) => {
        if (value) {
          rendered.value = true
          nextTick(() => {
            unwatchOpened()
          })
        }
      },
      { immediate: true },
    )
  }

  return {
    visible,
    displayed,
    rendered,
  }
}

import { KEYS } from '../../constants/form'
import { TYPE_PRODUCTS } from '../../constants/products'

export const getters = (gaApp) => ({
  hasItems() {
    return !!this.data?.products
  },

  items() {
    if (!this.hasItems) {
      return []
    }

    return this.data.products.map((item) => ({
      ...item,
      url: gaApp.services.review.products.getItemUrl({
        itemId: item.itemId,
        orderId: item.orderId || this[KEYS.ORDER_ID],
        customerId: this[KEYS.CUSTOMER_ID],
      }),
    }))
  },

  card() {
    return this.items.find(({ itemId }) => itemId === this.itemId)
  },

  secondaryItems() {
    if (!this.hasManyItems) {
      return []
    }
    return this.items.filter(
      ({ itemId }) => !this.submittedItemIds.includes(itemId),
    )
  },

  hasManyItems() {
    return this.items?.length > 1
  },

  hasOneItem() {
    return this.items?.length === 1
  },

  hasError() {
    return !!this.error
  },

  isVariantsTypeProducts() {
    return this.typeProducts === TYPE_PRODUCTS.VARIANTS
  },

  isAvailableTypeProducts() {
    return this.typeProducts === TYPE_PRODUCTS.AVAILABLE
  },

  isFormTypeProducts() {
    return !!this.typeProducts
  },
})

<template>
  <svg fill="none" stroke="none" viewBox="0 0 25 15">
    <g clip-path="url(#common-15-arrow-back-a)">
      <path stroke="currentColor" d="M6 2 .5 7.5 6 13" />
      <path fill="currentColor" d="M1 7h20v1H1z" />
    </g>
    <defs>
      <clipPath id="common-15-arrow-back-a">
        <path fill="transparent" d="M0 0h25v15H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-arrow-back',
}
</script>

import { PAGINATION_BUTTON } from '../../constants'

export const state = () => {
  return {
    productsCount: 0,
    hasProducts: false,
    productsFromRecommendations: [],

    brands: [],

    totalPages: 0,

    pages: [],

    pagination: {
      [PAGINATION_BUTTON.NEXT]: {
        pending: false,
        error: false,
      },
      [PAGINATION_BUTTON.PREV]: {
        pending: false,
        error: false,
      },
    },

    favoritesHash: '',

    lastViewedPage: 1,
    lastViewedProduct: null,
  }
}

import { Notifier } from '@ga/ui-components/notifications'

// применение внутри компонента
// this.$notifier.open(<message>)
// <message> опции из https://github.com/euvl/vue-notification
export default (_, inject) => {
  const notifier = new Notifier()

  inject('notifier', notifier.notifications)
}

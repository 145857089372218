import { TYPE } from '../../constants'
import {
  AdvcakePageViewModel,
  DigiAnalyticsProductViewModel,
  GdeSlonModel,
  GtmGa4Model,
  GtmGaUaModel,
  MindboxModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ViewBuilder extends BaseBuilder {
  constructor(product, country) {
    const models = new Map([
      [TYPE.GTM_UA, GtmGaUaModel],
      [TYPE.GTM_4, GtmGa4Model],
      [TYPE.MINDBOX, MindboxModel],
      [TYPE.GDESLON, GdeSlonModel],
      [TYPE.ADVCAKE, AdvcakePageViewModel],
      [TYPE.DIGI, DigiAnalyticsProductViewModel],
    ])
    super(models, product, country)
  }
}

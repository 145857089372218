import { computed } from 'vue'

import { SWIPE_DIRECTION } from '../../children/notifications-swiper/scripts/const'

export const useNotificationsMods = (axis, direction, stacked) => {
  const swipe = computed(() => {
    const axisName = SWIPE_DIRECTION[axis.value]
    return axisName[direction.value]
  })

  const queued = computed(() => {
    return !stacked.value
  })

  return {
    swipe,
    queued,
  }
}

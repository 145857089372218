import { services } from './services'

export { ENTITY } from './constants'
export { buildNotificationEntityNameAsHtml } from './helpers'

export const notifications = {
  meta: {
    name: 'notification',
  },
  layers: {
    services,
  },
}

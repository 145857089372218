export const state = () => {
  return {
    products: [],
    productDeferred: null,

    brands: [],
    brandsEx: [],
    brandDeferred: null,
    // [id]: true/false
    brandsMap: {},
  }
}

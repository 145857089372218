export const useAnalytic = () => {
  const getCartAnalyticDetails = ({ productIndex, blockName }) => {
    return {
      analyticsDetails: {
        indexTotal: productIndex + 1,
        itemListId: `page_error/block_1`,
        itemListName: `page_error/${blockName}`,
      },
    }
  }

  const getAnalyticData = ({ title, placement }) => {
    return {
      path: `page_error/${title}`,
      itemListId: `page_error/block_1`,
      digiAnalytics: true,
      plpType: 's',
      chunkSize: 1,
      placement,
    }
  }

  return {
    getCartAnalyticDetails,
    getAnalyticData,
  }
}

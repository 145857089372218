export const setTimer = (callback, delay) => {
  let timerId
  let start
  let timeLeft = delay

  const pause = () => {
    clearTimeout(timerId)
    timerId = null
    timeLeft -= Date.now() - start
  }

  const resume = () => {
    if (timerId) {
      return
    }

    start = Date.now()
    timerId = setTimeout(() => {
      clearTimeout(timerId)
      callback()
    }, timeLeft)
  }

  resume()

  return {
    pause,
    resume,
  }
}

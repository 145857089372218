<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#F2F2F2" rx="2" />
    <path
      fill="#286CB4"
      fill-rule="evenodd"
      d="M7.346 10.477 6.72 8.96l-.625 1.518h1.25Zm9.996-1.453h2.412v-.69l1.681 1.9-1.68 1.819v-.601h-2.413v-.747h2.372V9.698h-2.372v-.674ZM13.558 8h1.819l-.008 4.467h-1.186V9.284l-1.413 3.183h-.966l-1.421-3.192v3.192H8.174l-.398-.966h-2.12l-.397.966H4L5.933 8h1.616l1.673 3.898V8h1.802l1.316 2.867L13.558 8ZM25 8l-2.055 2.242 2.039 2.233h-1.519l-1.259-1.413-1.307 1.413h-4.71V8h4.775l1.259 1.397L23.522 8H25Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-amex',
}
</script>

export const getStateInitial = () => ({
  query: '',
  items: [],
  isDefaultState: true,
  isPending: false,
  listActive: false,

  streets: {
    items: [],
    query: '',
    pending: false,
  },

  cities: {
    items: [],
    query: '',
    pending: false,
  },
})

export const state = () => {
  return getStateInitial()
}

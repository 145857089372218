import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaIconPrimaryLogo } from '@ga/icons'

import { GaButton } from '@ga/ui-components/button'
import { GaIcon } from '@ga/ui-components/icon'
import { GaLinkBase } from '@ga/ui-components/link-base'

import GaAuthModalHeader from '../modal-header'

export default {
  name: 'ga-auth-modal-welcome-form',

  components: {
    GaIconPrimaryLogo,

    GaIcon,
    GaButton,
    GaLinkBase,

    GaAuthModalHeader,
  },

  setup() {
    const { $gaApp } = useContext()

    const footerHref = computed(() =>
      $gaApp.services.app.router.getPathByRoute({
        moduleType: 'customer',
        pageType: 'edit',
      }),
    )

    const bonusesProgramHref = computed(() => {
      return $gaApp.i18n.t('auth.modalWelcomeForm.subtitle.href')
    })

    return { footerHref, bonusesProgramHref }
  },
}

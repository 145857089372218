import { SCREEN_TYPES } from '../analytics/constants'

export class AddressesApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchAddressList() {
    const geoAddressesEnabled = this.gaApp.features.get(
      'addressSelectAddressToPlaid',
    )

    return geoAddressesEnabled
      ? await this.fetchAddressListV2()
      : await this.fetchAddressListV1()
  }

  /**
   * Получение списка адресов для ЛК
   * мага
   */
  async fetchAddressListV1() {
    const response =
      await this.gaApp.repositories.location.addresses.fetchAddressList()

    this.updateAddressesMagento(response)

    return response
  }

  /**
   * Получение списка адресов для ЛК
   * плед
   */
  async fetchAddressListV2() {
    const response =
      await this.gaApp.repositories.location.addresses.fetchAddressListV2()

    this.updateAddressesPlaid(response)

    return response
  }

  async setDefaultAddress(addressId) {
    const geoAddressesEnabled = this.gaApp.features.get(
      'addressSelectAddressToPlaid',
    )

    try {
      geoAddressesEnabled
        ? await this.setDefaultAddressV2(addressId)
        : await this.setDefaultAddressV1(addressId)

      this.gaApp.stores.location.addresses.activeId =
        this.gaApp.stores.location.addresses.defaultId

      this.gaApp.services.user.main.dropTemporaryAddresses()

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.addressChanged', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.setDefaultId'),
      )
    }
  }

  /**
   * Установка дефолтного адреса в ЛК
   * мага
   */
  async setDefaultAddressV1(addressId) {
    const response =
      await this.gaApp.repositories.location.addresses.setAddressDefault({
        addressId,
      })

    this.updateAddressesMagento(response)
  }

  /**
   * Установка дефолтного адреса в ЛК
   * плед
   */
  async setDefaultAddressV2(addressId) {
    const response =
      await this.gaApp.repositories.location.addresses.setAddressDefaultV2({
        id: addressId,
      })

    this.updateAddressesPlaid(response)
  }

  async setAddress(addressId) {
    this.gaApp.analytics.modules.location.onClickChangeAddress(
      SCREEN_TYPES.ACCOUNT_MY_ADDRESSES,
    )

    await this.setDefaultAddress(addressId)
  }

  async deleteAddress(addressId) {
    const geoAddressesEnabled = this.gaApp.features.get(
      'addressSelectAddressToPlaid',
    )

    try {
      geoAddressesEnabled
        ? await this.deleteAddressV2(addressId)
        : await this.deleteAddressV1(addressId)

      this.gaApp.services.user.main.dropAddress(addressId)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.delete.success', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.delete'),
      )
    }
  }

  /**
   * Удаление адреса в ЛК
   * мага
   */
  async deleteAddressV1(addressId) {
    const response =
      await this.gaApp.repositories.location.addresses.deleteAddress(addressId)

    this.updateAddressesMagento(response)
  }

  /**
   * Удаление адреса в ЛК
   * плед
   */
  async deleteAddressV2(addressId) {
    const response =
      await this.gaApp.repositories.location.addresses.deleteAddressV2({
        id: addressId,
      })

    this.updateAddressesPlaid(response)
  }

  async updateAddress(addressData) {
    const geoAddressesEnabled = this.gaApp.features.get(
      'addressSelectAddressToPlaid',
    )

    try {
      geoAddressesEnabled
        ? await this.updateAddressV2(addressData)
        : await this.updateAddressV1(addressData)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('customer.addresses.edit.success', {
          value:
            this.gaApp.services.location.addresses.formatAddressStreetHouse(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.addresses.error.update'),
      )
    }
  }

  /**
   * Обновление адреса в ЛК
   * мага
   */
  async updateAddressV1(addressData) {
    const response =
      await this.gaApp.repositories.location.addresses.updateAddress(
        addressData,
      )

    this.updateAddressesMagento(response)
  }

  /**
   * Обновление адреса в ЛК
   * плед
   */
  async updateAddressV2(addressData) {
    const response =
      await this.gaApp.repositories.location.addresses.updateAddressV2(
        addressData,
      )

    this.updateAddressesPlaid(response)
  }

  updateAddressesMagento(response) {
    const { addresses, geolocation } = response?.data || {}

    this.gaApp.stores.location.addresses.list = addresses

    if (geolocation) {
      this.gaApp.services.location.main.setLocation(geolocation)
      this.gaApp.services.location.cache.saveMigrateSelectedAddress(geolocation)
    }
  }

  updateAddressesPlaid(response) {
    const { addresses, selectedAddress } = response?.data || {}

    this.gaApp.stores.location.addresses.list = addresses

    if (selectedAddress) {
      this.gaApp.services.location.main.setLocation(selectedAddress)
    }
  }
}

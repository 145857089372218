import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'

export class HttpWrapper {
  constructor(client, eventBus) {
    this.client = client || HttpWrapper.client

    this.eventBus = eventBus
    this.callbacks = {
      request: [],
      response: [],
    }
  }

  create(config) {
    this.client = this.client.create(config)

    this.applyInterceptors()

    return this.client
  }

  normalizeСallbacks(item) {
    const {
      success = () => Promise.resolve(),
      error = (errorData) => Promise.reject(errorData),
    } = item

    return [success, error]
  }

  registerСallbacks(target, callbacks) {
    callbacks.forEach((item) => {
      if (isPlainObject(item)) {
        target.use(...this.normalizeСallbacks(item))
      }
    })
  }

  applyInterceptors() {
    this.registerСallbacks(
      this.client.interceptors.request,
      this.callbacks.request,
    )
    this.registerСallbacks(
      this.client.interceptors.response,
      this.callbacks.response,
    )
  }

  addCallback(callback, type) {
    if (isArray(callback)) {
      callback.forEach((item) => this.callbacks[type].push(item))
    } else {
      this.callbacks[type].push(callback)
    }
  }

  addRequestСallbacks(callback) {
    this.addCallback(callback, 'request')
  }

  addResponseСallbacks(callback) {
    this.addCallback(callback, 'response')
  }
}

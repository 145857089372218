/**
 * Часть useMediaControls из @vueuse/core'
 * Оставлены currentTime, duration, ended и playing.
 * При использовании контрола из библиотеки вызываем проблему автозапуска видео
 * Issue: https://github.com/vueuse/vueuse/issues/3263
 */

import { useEventListener } from '@vueuse/core'
import { toValue, watchIgnorable } from '@vueuse/shared'

import { ref, watch } from 'vue'

export const useMediaControls = (target, emit) => {
  const currentTime = ref(0)
  const duration = ref(0)
  const ended = ref(false)
  const playing = ref(false)
  const waiting = ref(false)
  const canPlay = ref(false)
  const muted = ref(true)
  const buffered = ref([])

  const timeRangeToArray = (timeRanges) => {
    let ranges = []
    for (let i = 0; i < timeRanges.length; ++i) {
      ranges = [...ranges, [timeRanges.start(i), timeRanges.end(i)]]
    }

    return ranges
  }

  const toggleMuted = () => {
    muted.value = !muted.value
  }

  const mute = () => {
    muted.value = true
  }

  const unmute = () => {
    muted.value = false
  }

  /**
   * Следим за состоянием muted.
   */
  watch([target, muted], () => {
    const el = toValue(target)
    if (!el) {
      return
    }
    el.muted = muted.value

    emit('mutedchange', el.muted)
  })

  const restartVideo = () => {
    currentTime.value = 0
    ended.value = false
    playing.value = true
  }

  const togglePlay = () => {
    playing.value = !playing.value
  }

  const play = () => {
    playing.value = true
  }

  const pause = () => {
    playing.value = false
  }

  const stop = () => {
    ended.value = true
    playing.value = false
  }

  const handleError = (error) => {
    console.error('error: ', error)
    emit('error', error)
  }

  /**
   * Это позволит нам обновлять текущее время события "timeupdate",
   * не устанавливая текущую позицию медиа. Однако, если пользователь
   * изменяет текущее время через ссылку (ref), медиа выполнит поиск.

   * Если бы мы не использовали watchIgnorable,
   * то обновление текущего времени из события "timeupdate"
   * вызвало бы прерывания воспроизведения медиа.
   */
  const { ignoreUpdates: ignoreCurrentTimeUpdates } = watchIgnorable(
    currentTime,
    (time) => {
      const el = toValue(target)
      if (!el) {
        return
      }
      el.currentTime = time
    },
  )
  /**
   * Используем watchIgnorable, чтобы мы могли управлять
   * состоянием воспроизведения с использованием ссылки (ref), а не функции.
   */
  const { ignoreUpdates: ignorePlayingUpdates } = watchIgnorable(
    playing,
    (isPlaying) => {
      const el = toValue(target)
      if (!el) {
        return
      }

      // запуск видео может упасть с ошибки
      isPlaying ? Promise.resolve(el.play()).catch(handleError) : el.pause()
    },
  )

  useEventListener(target, 'progress', () => {
    buffered.value = timeRangeToArray(toValue(target).buffered)

    emit('bufferedupdate', buffered.value)
  })

  /**
   * Обновляет и делает emit события для currentTime
   */
  useEventListener(target, 'timeupdate', () =>
    ignoreCurrentTimeUpdates(() => {
      currentTime.value = toValue(target).currentTime
      emit('timeupdate', currentTime.value)
    }),
  )
  useEventListener(target, ['waiting', 'loadstart'], () => {
    waiting.value = true
    ignorePlayingUpdates(() => (playing.value = false))
    emit('waiting')
  })
  /**
   * Делает emit события окончания загрузки видео
   */
  useEventListener(target, 'loadeddata', () => {
    waiting.value = false
    playing.value = true
    emit('loadeddata', {
      hasAudio:
        target.value?.mozHasAudio ||
        Boolean(target.value?.webkitAudioDecodedByteCount) ||
        Boolean(target.value?.audioTracks && target.value?.audioTracks.length),
    })
  })
  /**
   * Запускает принудительно воспроизведение видео и emit события playing
   */
  useEventListener(target, 'playing', () => {
    waiting.value = false
    ended.value = false
    ignorePlayingUpdates(() => {
      playing.value = true
      emit('playing')
    })
  })
  /**
   * emit события ended по окончанию видео
   */
  useEventListener(target, 'ended', () => {
    ended.value = true
    emit('ended', ended.value)
  })
  /**
   * Останавливает видео и emit события pause
   */
  useEventListener(target, 'pause', () =>
    ignorePlayingUpdates(() => {
      playing.value = false
      emit('pause')
    }),
  )
  /**
   * Обновляет и делает emit события для duration
   */
  useEventListener(target, 'durationchange', () => {
    duration.value = toValue(target).duration
    emit('durationchange', duration.value)
  })
  /**
   * Запускает воспроизведение видео и emit события play
   */
  useEventListener(target, 'play', () =>
    ignorePlayingUpdates(() => {
      playing.value = true
      emit('play')
    }),
  )

  /**
   * emit события canplay, если загружено достаточно данных
   * для воспроизведения видео до конца без необходимости остановки
   * для дальнейшей буферизации контента
   */
  useEventListener(target, 'canplay', () => {
    canPlay.value = true
    emit('canplay', canPlay.value)
  })
  /**
   * emit события error
   */
  useEventListener(target, 'error', (error) =>
    ignorePlayingUpdates(() => handleError(error)),
  )

  return {
    currentTime,
    duration,
    ended,
    playing,
    waiting,
    canPlay,
    muted,
    buffered,

    restartVideo,
    toggleMuted,
    togglePlay,
    play,
    pause,
    stop,
    mute,
    unmute,
  }
}

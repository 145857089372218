import { computed, unref } from 'vue'

import { CONFIG_BY_SIZE } from './const'

export const useSizeDependentProps = ({
  size,
  showStartIndent,
  controls,
  itemsCount,
}) => {
  const controlsInternal = computed(() => {
    if (CONFIG_BY_SIZE[unref(size)].controls === undefined) {
      return unref(controls)
    }

    return CONFIG_BY_SIZE[unref(size)].controls
  })

  const showStartIndentInternal = computed(() => {
    if (CONFIG_BY_SIZE[unref(size)].showStartIndent === undefined) {
      return unref(showStartIndent)
    }

    return CONFIG_BY_SIZE[unref(size)].showStartIndent
  })

  const itemsCountInternal = computed(() => {
    if (CONFIG_BY_SIZE[unref(size)].itemsCount === undefined) {
      return unref(itemsCount)
    }

    return CONFIG_BY_SIZE[unref(size)].itemsCount
  })

  return {
    controlsInternal,
    showStartIndentInternal,
    itemsCountInternal,
  }
}

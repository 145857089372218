export const endpoints = {
  headerImage: () => ({
    url: 'streamerce/header-image',
  }),
  streams: () => ({
    url: 'streamerce/streams',
  }),
  streamsStatuses: () => ({
    url: `streamerce/streams/status`,
  }),
}

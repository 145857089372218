import debounce from 'lodash/debounce'

import { SEARCH_DEBOUNCE_DELAY } from '../constants/cities'

export class CitiesService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.getSearchDataDebounced = debounce(
      this.getSearchDataDebounced,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  updateQuery(value) {
    this.gaApp.stores.location.search.cities.query = value

    if (value.trim()) {
      this.pendingStart()
      this.getSearchDataDebounced(value)
    } else {
      this.resetState()
    }
  }

  pendingStart() {
    this.gaApp.stores.location.search.cities.pending = true
  }

  pendingStop() {
    this.gaApp.stores.location.search.cities.pending = false
  }

  async getSearchDataDebounced(value) {
    await this.gaApp.services.location.api.getSearchCitiesData(value)

    this.pendingStop()
  }

  resetState() {
    this.gaApp.stores.location.search.cities.items = []
    this.gaApp.stores.location.search.cities.query = ''
    this.gaApp.stores.location.search.cities.pending = false
  }

  /*
   * Возвращает подсказку с полными данными
   *
   * с тогглом addressSeparateSuggestGeocodeRequests в option может быть 2 формата данных:
   * 1 - с локацией, тогда getSuggestData возвращает option как есть
   * 2 - без локации, тогда getSuggestData запрашивает локацию,
   * и подмешивает в option чтобы сохранить логику, которая была до разделения запросов
   * */
  async getSuggestData(option) {
    const suggestSeparated = this.gaApp.features.get(
      'addressSeparateSuggestGeocodeRequests',
    )

    const additionalData = suggestSeparated
      ? await this.gaApp.services.location.cities.fetchSuggestAdditionalData(
          option.value,
          option.fullAddress,
        )
      : {}

    return {
      ...option,
      ...additionalData,
    }
  }

  /*
   * Запрос локации для адресных подсказок с раздельными запросами
   * */
  async fetchSuggestAdditionalData(suggestId, fullAddress) {
    this.pendingStart()
    const data = await this.gaApp.services.location.api.geocodeCities(
      suggestId,
      fullAddress,
    )
    this.pendingStop()

    return data
  }
}

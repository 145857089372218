<template>
  <svg viewBox="0 0 25 25">
    <path fill="none" stroke-width="1.5" d="m18 18 5 5" />
    <circle cx="11.5" cy="11.5" r="8.5" fill="none" stroke-width="1.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-search',
}
</script>

// @vue/component
import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { GaHomeStoriesCirclesSliderItem } from '../../../base-stories-circles-slider/children/item'
import { SKELETON_ITEMS_LENGTH } from '../../../stories-circles-slider/scripts/consts'

export default {
  name: 'ga-home-stories-circles-slider-skeleton',
  components: {
    GaHomeStoriesCirclesSliderItem,
  },
  props: {},
  setup(props) {
    const { $gaApp } = useContext()

    const isDesktop = computed(() => $gaApp.mq('desktop-small+'))
    const isTablet = computed(
      () => $gaApp.mq('tablet-small+') && $gaApp.mq('desktop-small-'),
    )

    const skeletonItemsLength = computed(() => {
      return unref(isDesktop)
        ? SKELETON_ITEMS_LENGTH.DESKTOP
        : unref(isTablet)
          ? SKELETON_ITEMS_LENGTH.TABLET
          : SKELETON_ITEMS_LENGTH.MOBILE
    })

    return {
      skeletonItemsLength,
    }
  },
}

import { KEYS } from '../../constants/form'
import { initialData } from '../../constants/main'

export const state = () =>
  initialData(null, {
    [KEYS.ITEM_ID]: null,
    [KEYS.ORDER_ID]: null,
    [KEYS.CUSTOMER_ID]: null,
    submittedItemIds: [],
    fromRoutePath: null,
    typeProducts: '',
  })

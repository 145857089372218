import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGaUaMenuModel extends GtmBaseModel {
  constructor(data) {
    super({ event: ACTIONS.MENU.GA_UA })

    const { path } = data

    this.eventCategory = 'menu_click'
    this.path = path
  }
}

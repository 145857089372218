<template>
  <svg fill="inherit" stroke="none" viewBox="0 0 15 15">
    <path
      fill="inherit"
      d="m6.071 7.5 1.43-6S12.5 4.795 12.5 8.201c0 2.824-2.13 5.303-5 5.299-2.761-.004-5-2.204-5-4.92 0-2.06 2.143-3.747 2.143-3.747L6.07 7.5Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-fire',
}
</script>

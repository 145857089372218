<template>
  <svg fill="none" stroke="none" viewBox="0 0 25 23">
    <path
      fill="#00ADEE"
      d="M14.894 15.213c-2.159 2.5-4.934 4.005-7.819 4.005-4.88 0-6.549-4.022-4.227-6.657.508-.582 1.36-1.13 2.703-1.438 2.087-.479 5.388.291 8.49 1.267.563-.959 1.016-2.037 1.38-3.166H5.84v-.907h4.935V6.674H4.808v-.907h5.968V3.44s0-.394.417-.394h2.413v2.721h5.914v.907h-5.896v1.643h4.808A17.644 17.644 0 0 1 16.4 13.16c1.47.496 2.776.958 3.755 1.284 3.52 1.112 4.318 1.163 4.318 1.163V3.68c0-2.036-1.76-3.679-3.919-3.679H3.92C1.759 0 0 1.66 0 3.68v15.64C0 21.358 1.76 23 3.919 23h16.635c2.16 0 3.919-1.66 3.919-3.68v-.153s-6.368-2.499-9.579-3.954Z"
    />
    <path
      fill="#00ADEE"
      d="M5.95 12.492c-.617.052-1.76.309-2.395.84-1.887 1.54-.762 4.363 3.048 4.363 2.213 0 4.426-1.335 6.168-3.457-2.45-1.146-4.554-1.95-6.821-1.745Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-ali-pay',
}
</script>

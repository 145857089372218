import { queryHighlight } from '@ga/utils'

export class PopularRequestsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Проверяет необходимость отображения тултипа
   * @param {HTMLElement} textRef
   * @returns {boolean}
   */
  checkIsTooltipNeeded(textRef) {
    return textRef.offsetWidth > textRef.parentElement.offsetWidth
  }

  /**
   * Подчеркивает текст и добаляет тултипы популярным запросам
   * @param {object} rows - html элементы запросов
   */
  setFormattedPopularRequests({ rows, isHoverable } = {}) {
    const formattedPopularRequests =
      this.gaApp.stores.search.main.searchResult.popularRequests.map(
        (popularRequest, i) => {
          const textEl = rows?.[i]?.querySelector('[data-search-query]')

          return {
            ...popularRequest,
            queryHtml: queryHighlight(
              popularRequest.query,
              this.gaApp.stores.search.main.searchInputValue,
              {},
            ),
            hasTooltip:
              !!textEl && isHoverable && this.checkIsTooltipNeeded(textEl),
          }
        },
      )

    this.gaApp.stores.search.main.searchResult.formattedPopularRequests =
      formattedPopularRequests
  }
}

export const state = () => {
  return {
    permission: {
      isAccepted: false,
      isAlreadyAnswered: false,
      shouldRenderCookiePanel: true,
      shouldRenderCookieModal: true,
      modalOpened: false,
      modalSettingsOpened: false,
      modalSettingsLoading: false,
      modalSettingsText: '',
    },
    notification: {
      isAnswered: false,
    },
  }
}

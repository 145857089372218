export class GtmGa4ContentSlideBaseModel {
  constructor({ blockName, blockPosition, slideName, slidePosition }) {
    const creativeSlot = `slide_${slidePosition}`
    const itemListId = `главная/block_${blockPosition}`

    this.creative_name = slideName
    this.creative_slot = creativeSlot
    this.promotion_name = blockName
    this.items = [
      {
        creative_name: slideName,
        creative_slot: creativeSlot,
        promotion_name: blockName,
        item_list_id: itemListId,
      },
    ]
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 22 22">
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M18.228 3.771H3.771V0h14.457v3.771Zm0 18.23H3.771V18.23h14.457V22ZM0 18.228h3.771V3.773H0v14.457Zm22 0h-3.771V3.773H22v14.457Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-oplati',
}
</script>

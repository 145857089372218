<template>
  <svg stroke="none" viewBox="0 0 213 263">
    <path
      fill="inherit"
      d="M212.319 131.63c-5.891 72.45-57.854 131.182-116.061 131.182-58.208 0-100.618-58.732-94.726-131.182C7.424 59.18 59.386.448 117.594.448c58.207 0 100.617 58.732 94.725 131.182Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-yellow-blob-mobile',
}
</script>

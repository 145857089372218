import { TYPE } from '../../constants'
import {
  GaAnalyticsBreadcrumbClickModel,
  GtmGa4userInteractionModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class BreadcrumbClickBuilder extends BaseBuilder {
  constructor({ product, type }) {
    const model =
      TYPE[type] === TYPE.GAA
        ? GaAnalyticsBreadcrumbClickModel
        : GtmGa4userInteractionModel
    const models = new Map([[TYPE[type], model]])
    super(models, product)
  }
}

import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export { mainStore } from './stores/main'

export { useBusyBrands } from './composable/use/busy-brands'

export const favorites = {
  meta: {
    name: 'favorites',
    locales,
  },
  layers: {
    stores,
    services,
    repositories,
  },
  components,
}

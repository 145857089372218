export class ProductRatingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get textSizesMap() {
    return {
      mobile: 'm',
      tablet: 'm',
      desktop: 'l',
    }
  }

  get starsSizesMap() {
    return {
      mobile: 's',
      tablet: 's',
      desktop: 'm',
    }
  }

  getBreakpointName() {
    if (this.gaApp.mq('tablet-')) {
      return 'mobile'
    }

    if (this.gaApp.mq('desktop-small+')) {
      return 'desktop'
    }

    return 'tablet'
  }

  getTextSize() {
    return this.textSizesMap[this.getBreakpointName()]
  }

  getStarsSize(productCardViews = {}) {
    return this.starsSizesMap[this.getBreakpointName()]
  }
}

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../../models/gtm/gtm-base.model/gtm-base.model'
import { GtmGa4ProductModel } from '../../../models/gtm/gtm-ga4-product.model/gtm-ga4-product.model'

export class GtmGa4CartModel extends GtmBaseModel {
  constructor({
    id,
    addToCart,
    currency,
    view,
    brand,
    name,
    amount,
    breadcrumbs,
    attributesSelected,
    itemListName,
    isEyezonEnabled,
    itemListId,
    searchTerm,
    inStock,
    index,
    analyticData,
    hasCoupon,
    categoryId,
  }) {
    const event = addToCart
      ? ACTIONS.ADD_TO_CART.GA4
      : ACTIONS.REMOVE_FROM_CART.GA4

    super({ event, view, isEyezonEnabled })

    this.value = amount
    this.currency = currency
    this.item_list_name = itemListName
    this.item_list_id = itemListId

    if (addToCart) {
      this.search_term = searchTerm
    }

    if (analyticData?.creativeName)
      this.creative_name = analyticData.creativeName
    if (analyticData?.creativeSlot)
      this.creative_slot = analyticData.creativeSlot
    if (analyticData?.promotionName)
      this.promotion_name = analyticData.promotionName
    if (hasCoupon) this.has_coupon = 1

    const item = {
      brand,
      name,
      amount,
      id,
      breadcrumbs,
      attributesSelected,
      itemListName,
      itemListId,
      inStock,
      index,
      currency,
      hasCoupon,
      categoryId,
    }

    this.ecommerce = {
      items: [new GtmGa4ProductModel(item)],
    }
  }
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getBrands() {
    const { data } = this.gaApp.features.get('newBrands')
      ? await this.gaApp.repositories.brands.main.fetchBrandsV2()
      : await this.gaApp.repositories.brands.main.fetchBrands()

    this.gaApp.stores.brands.main.metaData = data?.meta

    this.gaApp.stores.brands.main.items = data.brands
  }

  async getFavorites() {
    try {
      this.gaApp.stores.brands.main.isFavoritesFetching = true

      const { data } = this.gaApp.features.get('newBrands')
        ? await this.gaApp.repositories.brands.main.fetchBrandsFavoritesV2()
        : await this.gaApp.repositories.brands.main.fetchBrandsFavorites()

      if (!Array.isArray(data.brands)) {
        throw new TypeError('Favorites is not an array')
      }

      const favoritesIds = data.brands.map(({ id }) => id)

      this.gaApp.services.favorites.brands.setItems(favoritesIds)
      this.gaApp.services.favorites.brands.setItemsEx(favoritesIds)
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('brands.notifications.favoritesError'),
      )
    } finally {
      this.gaApp.stores.brands.main.isFavoritesFetching = false
    }
  }

  async searchBrands(parameters, requestParams) {
    try {
      const { data } = await this.gaApp.repositories.brands.main.fetchBrandsV2(
        parameters,
        requestParams,
      )

      this.gaApp.services.brands.search.setSearchResultsV2(data, parameters)
    } catch (error) {
      const statusCode = error?.statusCode

      if (statusCode) {
        const message =
          statusCode >= 500 ? 'brands.error.server' : 'brands.error.default'
        this.gaApp.services.notification.main.open(this.gaApp.i18n.t(message))
      }
    }
  }
}

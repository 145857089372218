export class GtmGa4HowToModel {
  constructor({ id, actionType, name, progress, position }) {
    this.event = 'user_interaction'
    this.event_category = 'pdp_info'
    this.type = 'pdp'
    this.action_type = actionType
    this.item_id = id
    this.name = name

    if (progress) {
      this.progress = progress
    }

    if (position) {
      this.slot = position
    }
  }
}

import { defineStore, getActivePinia } from 'pinia'

import { actions } from './actions'
import { getters } from './getters'
import { state } from './state'

export const mainStore = (gaApp) => {
  const init = defineStore('footerMain', {
    actions: actions(gaApp),
    getters: getters(gaApp),
    state,
  })

  return init(getActivePinia())
}

import { EventBus } from '@ga/utils'

import { callbackRequestDefault } from './callbacks/request'
import { callbackResponseDefault } from './callbacks/response'
import { CancelRequest } from './utils/cancel-request'
import { getDefaultConfigAxiosNuxt } from './configs'
import { ErrorWrapper, HttpWrapper, ResponseWrapper } from './wrappers'

export class Api {
  constructor({ client, urlPrefix }) {
    this.prefixUrl = urlPrefix
    this.httpClient = client

    this.requestAbort = new CancelRequest(this.httpClient)
    this.eventBus = new EventBus()
    this.callbacks = {
      request: [callbackRequestDefault(this.eventBus)],
      response: [callbackResponseDefault(this.eventBus)],
    }
  }

  request(configuration = {}) {
    const configNuxt = getDefaultConfigAxiosNuxt()
    const config = {
      ...configNuxt,
      ...configuration,
    }

    if (config.abortKey) {
      // todo переделать на AbortController при апдейте версии axios v.0.22.0
      // https://axios-http.com/ru/docs/cancellation
      // this.requestAbort.addRequest(config.abortKey)

      config.cancelToken = this.requestAbort.addRequest(config.abortKey)

      delete config.abortKey
    }

    const http = new HttpWrapper(this.httpClient, this.eventBus)
    http.addRequestСallbacks(this.callbacks.request)
    http.addResponseСallbacks(this.callbacks.response)

    return http.create(config)
  }

  response(response = {}) {
    return new ResponseWrapper(response)
  }

  error(...parameters) {
    return new ErrorWrapper(...parameters)
  }

  endpoints(obj) {
    return this.setProxyEndpoints(obj)
  }

  setProxyEndpoints(obj) {
    return new Proxy(obj, {
      get: (target, prop) => {
        return (options) => {
          const { url, ...all } = target[prop](options)

          return {
            url: `${options?.urlPrefix ?? this.prefixUrl}/${url}`,
            ...all,
          }
        }
      },
    })
  }

  subscribe(eventType, listener) {
    this.eventBus.subscribe(eventType, listener)
  }

  publish(eventType, arg) {
    this.eventBus.publish(eventType, arg)
  }

  addRequestCallback(callback) {
    this.callbacks.request.push(callback)
  }

  addResponseCallback(callback) {
    this.callbacks.response.push(callback)
  }
}

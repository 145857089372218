import { getPhoneWithoutMask } from '@ga/utils'

import { DESIGN_TYPES, KEYS, STEPS } from '../../../constants/digital'
export class DtoOrderService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Содержимое формы
   */
  get formData() {
    return this.gaApp.stores.giftCards.digital.formData
  }

  /**
   * Флаг, что пользователь является получателем (пользователь дарит карту себе)
   */
  get isUserRecipient() {
    return this.gaApp.stores.giftCards.digital.isUserRecipient
  }

  /**
   * Формирует объект для отправки заказа
   * @returns {object} данные заказа
   */
  getOrderData() {
    const orderData = {}

    // Шаг 1
    this.setDesignFields(orderData)

    // Шаг 2
    orderData[KEYS.AMOUNT] = this.formData[STEPS.PRICE][KEYS.AMOUNT]

    // Шаг 3
    orderData[KEYS.PHONE] = this.formData[STEPS.RECIPIENT][KEYS.PHONE]
    orderData[KEYS.PHONE_SENDER] =
      this.formData[STEPS.RECIPIENT][KEYS.PHONE_SENDER]

    // Шаг 4
    if (!this.gaApp.stores.giftCards.digital.isDeliveryNow) {
      // добавляем поле, если нужна отложенная доставка + форматируем дату с учетом часового пояса
      orderData[KEYS.DATE] = this.getOffsetUtcDate()
    }

    // если оформляем карту "себе", то номер получателя = номер отправителя
    orderData[KEYS.PHONE] = this.getPhoneRecipient(orderData)
    // добавляем необязательные поля из варианта "другу", если в них не пусто
    if (!this.isUserRecipient) {
      Object.entries(this.formData[STEPS.RECIPIENT]).forEach(([key, value]) => {
        if (value) {
          orderData[key] = value
        }
      })
    }
    // устанавливаем номер телефона отправителя
    orderData[KEYS.PHONE_SENDER] = this.getPhoneSender(orderData)
    // доп поля
    orderData[KEYS.SELF] = Boolean(this.isUserRecipient)
    orderData[KEYS.BASKET] = this.gaApp.stores.giftCards.digital.basketId
    //  стоимость карты шлем умноженную на деноминатор
    orderData[KEYS.AMOUNT] = this.getPriceWithDenominator(orderData)

    return orderData
  }

  /**
   * Определение номера телефона отправителя
   */
  getPhoneSender(data) {
    // если пользователь неавторизован или оформляет не себе,
    // то берем номер телефона отправителя из поля для чека
    const phoneSender =
      this.gaApp.stores.user.main.isAuthorized || this.isUserRecipient
        ? data[KEYS.PHONE_SENDER]
        : this.formData[STEPS.PAYMENT][KEYS.PHONE_RECEIPT]

    return getPhoneWithoutMask(phoneSender)
  }

  /**
   * Определение данных, если пользователь выбрал карту для себя
   */
  getPhoneRecipient(data) {
    const phoneRecipient = this.isUserRecipient
      ? data[KEYS.PHONE_SENDER]
      : data[KEYS.PHONE]

    return getPhoneWithoutMask(phoneRecipient)
  }

  /**
   * Определение номинала с учетом деноминатора
   */
  getPriceWithDenominator(data) {
    return this.gaApp.services.app.currency.getNominal({
      amount: Number(data[KEYS.AMOUNT]),
      denominator: this.gaApp.stores.giftCards.digital.priceInfo.denominator,
      isNominator: true,
    })
  }

  /**
   * Определение данных часового пояса
   */
  getOffsetUtcDate() {
    const timezone = this.formData[STEPS.DELIVERY][KEYS.TIMEZONE]
    const date = this.formData[STEPS.DELIVERY][KEYS.DATE]
    const offsetTz = Number(timezone.replace(/[^(\W\d)]/g, ''))

    return this.gaApp.libs.dateWrapper.utc(date).utcOffset(offsetTz).format()
  }

  /**
   * Определяет поля дизайна при выбранном типе дизайна
   */
  setDesignFields(data) {
    const {
      selectedDesignType,
      selectedImage,
      defaultForegroundId,
      defaultBackgroundId,
    } = this.gaApp.stores.giftCards.digital

    switch (selectedDesignType) {
      case DESIGN_TYPES.GA:
        data[KEYS.IMG] = this.formData[STEPS.DESIGN][KEYS.IMG]
        data[KEYS.COLOR] = this.formData[STEPS.DESIGN][KEYS.COLOR]
        break

      case DESIGN_TYPES.AI:
        data[KEYS.IMG] = defaultForegroundId
        data[KEYS.COLOR] = defaultBackgroundId
        data[KEYS.YANDEX_IMAGE_ID] = selectedImage.data.yandexImageId
        break
    }
  }
}

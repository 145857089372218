import { brandsStore } from './brands'
import { mainStore } from './main'
import { plpStore } from './plp'

export const stores = (gaApp) => {
  return {
    main: mainStore(gaApp),
    plp: plpStore(gaApp),
    brands: brandsStore(gaApp),
  }
}

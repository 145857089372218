import { useImageStrategyComplex } from '../image-strategy-complex'
import { useImageStrategyPlain } from '../image-strategy-plain'

export const useImageStrategyFactory = (config = {}) => {
  // Возможные стратегии работы с конфигом изображения
  const STRATEGYS = [useImageStrategyComplex, useImageStrategyPlain]

  // Пытаемся определить стратегию на основе конфига изображения
  const stragegy = STRATEGYS.find((strategy) =>
    strategy(config).isOwnStrategy(),
  )

  // Если стратегия не найдена, то используем стратегию по умолчанию
  return stragegy ? stragegy(config) : useImageStrategyComplex(config)
}

import { maxLength } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (max, message) => {
  return withMessage(maxLength(max), {
    type: 'maxLength',
    max,
    message: 'app.validation.maxLength',
  })(message)
}

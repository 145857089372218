import { mediaQueryInstance } from '../helpers/mediaQuery'

export class WindowService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setWindowResizingState(value) {
    this.gaApp.stores.app.window.windowResizing = value
  }

  setWindowSize({ innerWidth, innerHeight }) {
    this.gaApp.stores.app.window.windowSize = { innerWidth, innerHeight }
  }

  setWindowSizeLazy() {
    this.gaApp.stores.app.window.windowSizeLazy = {
      ...this.gaApp.stores.app.window.windowSize,
    }
  }

  setCurrentBreakpoint() {
    const width = this.gaApp.stores.app.window.windowSize.innerWidth

    this.gaApp.stores.app.window.currentBreakpoint =
      this.gaApp.services.app.window.getCurrentBreakpointName(width)
  }

  setViewportExcludedHeaderRef(value) {
    this.gaApp.stores.app.window.viewportExcludedHeaderRef = value
  }

  getCurrentBreakpointName(width) {
    return mediaQueryInstance.findBreakpointName(width)
  }
}

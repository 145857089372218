import { VENDOR } from '../constants'

import { config } from '~/config'

export class AdvcakeFacade {
  constructor(advcake, gaApp) {
    this.module = advcake
    this.gaApp = gaApp
  }

  get entity() {
    return VENDOR.ADVCAKE
  }

  init(id) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const identificator = id || config.get(VENDOR.ADVCAKE)?.id

    return this.module.create(identificator)
  }

  send(...args) {
    if (!this.module) {
      throw new Error(`Модуль ${this.entity} отсутствует`)
    }

    const push = () => this.module.push(...args)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }
}

export class GdeSlonProductModel {
  constructor({ id, amount, quantity }) {
    this.product = {
      ids: {
        website: id,
      },
    }

    if (amount) {
      this.price = amount
    }

    if (quantity) {
      this.quantity = quantity
    }
  }
}

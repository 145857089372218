/**
 * Кеш сервис eyezon.
 */

export class CacheService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // Очищаем local storage при выходе с профиля
  clearStorage() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('Eyezon')) {
        this.gaApp.services.app.browserStorage.local.remove(key)
      }
    })
  }
}

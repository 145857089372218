import { onMounted, onUnmounted } from 'vue'

/**
 * Привязыает и отвязывает слушатель pageYOffset
 * @param {Function} listener
 */
export const useWindowScrollTopListener = (listener) => {
  const eventListener = (event) => {
    listener(window.pageYOffset, event)
  }

  onMounted(() => {
    window.addEventListener('scroll', eventListener, true)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', eventListener, true)
  })
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useModalStore = () => {
  const { $gaApp } = useContext()

  const register = computed(() => $gaApp.stores.modal.register)

  const main = computed(() => $gaApp.stores.modal.main)

  return {
    register,
    main,
  }
}

<template>
  <svg fill="none" viewBox="0 0 156 50">
    <path
      stroke="inherit"
      stroke-width="inherit"
      d="m84.423 36.125-.306.262v12.548h38.43v9.94h21.56v-9.94h10.85v-15.19h-10.85V4.765h-23.038l-.246.21-36.4 31.15Zm-4.848-3.185v-2.1c0-4.918-1.148-11.654-6.495-17.156-5.35-5.505-14.752-9.619-30.92-9.619h-3.15c-16.203 0-25.622 4.113-30.98 9.618-5.356 5.502-6.505 12.238-6.505 17.157v2.1c0 4.85 1.15 11.618 6.501 17.171 5.358 5.558 14.777 9.744 30.984 9.744h3.15c16.173 0 25.574-4.186 30.923-9.744 5.343-5.553 6.492-12.321 6.492-17.171Zm-22.4-1.68v.91c0 2.553-.8 5.467-3.201 7.748-2.403 2.282-6.532 4.047-13.424 4.047-6.887 0-11.035-1.797-13.457-4.107-2.424-2.31-3.238-5.245-3.238-7.758v-.84c0-2.525.75-5.343 3.123-7.54 2.38-2.203 6.526-3.905 13.572-3.905 6.9 0 11.033 1.7 13.434 3.908 2.395 2.202 3.191 5.024 3.191 7.537Zm50.419 2.485 14.953-12.767v12.767h-14.953Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-number-4',
}
</script>

import { TYPE } from '../../constants'
import { GtmRecommendedModel } from '../../models/gtm/recommended.model'
import { BaseBuilder } from '../base.builder'

export class RecommendedBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmRecommendedModel]])
    super(models, data)
  }
}

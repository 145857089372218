import { EXTENSION_PRIORITY } from '../../const'

/**
 * Возвращает список отсортированных объектов изображений
 *
 * @param {array} sources список уже собранных изображений
 * @returns {array} список отсортированных объектов изображений. Новые -> более старые
 */
export const sortByExt = (sources) => {
  return sources.sort(
    (a, b) => EXTENSION_PRIORITY[a.ext] - EXTENSION_PRIORITY[b.ext],
  )
}

import { TYPE } from '../../constants'
import { GtmGa4userInteractionModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class userInteractionBuilder extends BaseBuilder {
  constructor(product) {
    const models = new Map([[TYPE.GTM_4, GtmGa4userInteractionModel]])

    super(models, product)
  }
}

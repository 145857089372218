import { TYPE } from '../../constants'
import {
  GaAnalyticsBrandItemClickModel,
  GtmGa4BrandClickModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class BrandItemClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GAA, GaAnalyticsBrandItemClickModel],
      [TYPE.GTM_4, GtmGa4BrandClickModel],
    ])
    super(models, data)
  }
}

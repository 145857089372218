import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductClickModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    super({ event: 'select_item' })

    const { listId, searchTerm, list, plpType, currency, analyticData } =
      product

    if (analyticData?.creativeName)
      this.creative_name = analyticData.creativeName
    if (analyticData?.creativeSlot)
      this.creative_slot = analyticData.creativeSlot
    if (analyticData?.promotionName)
      this.promotion_name = analyticData.promotionName

    this.item_list_name = list
    this.item_list_id = listId
    this.plp_type = plpType
    this.search_term = searchTerm
    this.ecommerce = {
      currency,
      items: [new GtmGa4ProductBaseModel(product)],
    }
  }
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4WarningModel extends GtmBaseModel {
  constructor(product) {
    super({ event: EVENT.WARNING.GA4 })

    this.event_category = product.category ?? 'plp'
    this.click = 'get_consultation'
    this.item_id = product.id
  }
}

import { TYPE } from '../../constants'
import { GtmGa4TooltipViewModel } from '../../models/gtm/gtm-ga4-tooltip-view/gtm-ga4-tooltip-view.model'
import { BaseBuilder } from '../base.builder/base.builder'

export class TooltipViewBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4TooltipViewModel]])
    super(models, data)
  }
}

<template>
  <svg fill="none" viewBox="0 0 15 22">
    <path stroke-width="1.5" d="m.59 13.39 6.667 6.667 6.666-6.666" />
    <path stroke-width="1.5" d="M7.26 20.063V.062" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-arrow-down',
}
</script>

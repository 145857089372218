<template>
  <svg viewBox="0 0 20 20">
    <path
      fill-rule="evenodd"
      stroke="none"
      d="M8 5.25a2 2 0 1 1 4 0v.5H8v-.5Zm-1 1.5v1.5h1v-1.5h4v1.5h1v-1.5h2v10H5v-10h2Zm0-1v-.5a3 3 0 0 1 6 0v.5h3v12H4v-12h3Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-cart-mini',
}
</script>

/**
 * Это автоматически добавит источники в медиа-элемент.
 * Источники будут добавлены как дочерние элементы к медиа-элементу
 * как элементы `<source>`.
 *
 * @param {ref} target ссылка на медиа-элемент.
 */
import { toRefs, unref, watch } from 'vue'

import { DEFAULT_TRACK_SETTINGS, TRACKS_MODE } from '../const'

export const useTracks = (target, props, emit) => {
  const { trackUrl, hideTracks } = toRefs(props)

  const checkExistTextTrack = () => {
    return Boolean(target.value?.textTracks?.[0])
  }
  const hideTrack = () => {
    if (!checkExistTextTrack()) {
      return
    }
    target.value.textTracks[0].mode = TRACKS_MODE.HIDDEN
    emit('track:hide')
  }

  const showTrack = () => {
    if (!checkExistTextTrack()) {
      return
    }
    target.value.textTracks[0].mode = TRACKS_MODE.SHOWING
    emit('track:show')
  }

  const changeCueEventHandler = (event) => {
    const text = event.target.track?.activeCues?.[0]?.text ?? ''
    emit('track:change', {
      text,
    })
  }

  const handleError = () => {
    emit('track:error')
  }
  /**
   * Загрузка субтитров
   */
  watch([target, trackUrl], () => {
    if (!document || !trackUrl.value) {
      return
    }

    const textTracks = trackUrl.value
    const el = unref(target)
    if (!textTracks || !textTracks.length || !el) {
      return
    }

    /**
     * MediaAPI предоставляет API для добавления текстовых дорожек, но в настоящее время они не поддерживаются.
     * есть API для удаления текстовых дорожек, поэтому вместо этого мы будем просто создавать и удалять
     * треки вручную с использованием HTML API.
     */
    el.querySelectorAll('track').forEach((e) => {
      e.removeEventListener('cuechange', changeCueEventHandler)
      e.removeEventListener('error', handleError)
      e.remove()
    })

    const track = document.createElement('track')

    track.kind = hideTracks.value
      ? DEFAULT_TRACK_SETTINGS.METADATA
      : DEFAULT_TRACK_SETTINGS.KIND
    track.src = textTracks
    track.default = true

    el.appendChild(track)

    if (hideTracks.value) {
      hideTrack()
    }

    track.addEventListener('cuechange', changeCueEventHandler)
    track.addEventListener('error', handleError)
  })

  // Следим за изменением hideTracks, что бы скрыть/показать субтитры
  watch(hideTracks, (val) => {
    if (val) {
      hideTrack()
    } else {
      showTrack()
    }
  })
}

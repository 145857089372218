import { RAW_STATE } from '../../constants'

export const getters = (gaApp) => ({
  liveStreams() {
    return gaApp.services.streamerce.main.getStreamsByStatus(RAW_STATE.NOW)
  },

  futureStreams() {
    return gaApp.services.streamerce.main.getStreamsByStatus(RAW_STATE.FUTURE)
  },

  pastStreams() {
    return gaApp.services.streamerce.main.getStreamsByStatus(RAW_STATE.PAST)
  },

  streamsItems() {
    return [...this.liveStreams, ...this.futureStreams, ...this.pastStreams]
  },
})

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGaUaProductModel } from '../gtm-gaua-product.model/gtm-gaua-product.model'

export class GtmGaUaCartModel extends GtmBaseModel {
  constructor({
    id,
    addToCart,
    currency,
    view,
    brand,
    name,
    amount,
    breadcrumbs,
    attributesSelected,
    itemListName,
  }) {
    const event = addToCart
      ? ACTIONS.ADD_TO_CART.GA_UA
      : ACTIONS.REMOVE_FROM_CART.GA_UA

    super({ event, view })

    this.item_list_name = itemListName

    const method = addToCart ? 'add' : 'remove'
    this.ecommerce = {
      currencyCode: currency,
      [method]: {
        products: [
          new GtmGaUaProductModel({
            brand,
            name,
            amount,
            id,
            breadcrumbs,
            attributesSelected,
            itemListName,
          }),
        ],
      },
    }
  }
}

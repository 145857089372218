export const POSITION = {
  BOTTOM: 'bottom',
  TOP: 'top',
}

export const SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
}

export const BREAKPOINT = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
}

export const DEFAULT_TIMER = 5000

export const SYMAPHORE_FLAGS = {
  FULL_SHOWED_CURRENT: 'full-showed-text-current-ribbon',
  AFTER_ENTER_CURRENT: 'after-enter-transition-current-ribbon',
  UNMOUNTED_PREVIOUS: 'unmounted-previous-ribbon',
}

<template>
  <svg viewBox="0 0 23 23">
    <path
      fill="#1D1D1D"
      fill-rule="evenodd"
      stroke="none"
      d="M11.5 0C5.149 0 0 5.149 0 11.5S5.149 23 11.5 23h11.216a.284.284 0 0 0 .284-.284V11.5C23 5.149 17.851 0 11.5 0ZM9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3.898 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3.899 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-chat-bubble',
}
</script>

export const VIDEO_EXTENSION = {
  WEBM: 'webm',
  MP4: 'mp4',
  AVI: 'avi',
  WMV: 'wmv',
  HLS: 'hls',
}

export const VIDEO_EXTENSION_PRIORITY = {
  [VIDEO_EXTENSION.WEBM]: 0,
  [VIDEO_EXTENSION.MP4]: 1,
  [VIDEO_EXTENSION.AVI]: 2,
  [VIDEO_EXTENSION.WMV]: 3,
  [VIDEO_EXTENSION.HLS]: 4,
}
export const getVideoTypeByFormat = (format) => {
  switch (format) {
    case VIDEO_EXTENSION.HLS:
      return 'application/x-mpegURL'
    default:
      return `video/${format}`
  }
}

import { computed, ref, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { useItemEventsEmitters } from '../../../../../helpers/nav'
import { GaHeaderNavMinimizedArrow } from '../nav-minimized-arrow'

import { GaHeaderNavMinimizedItemIcon } from './children/nav-minimized-item-icon'
import { useContentFit } from './scripts/use'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-item',

  components: {
    GaAction,
    GaHeaderNavMinimizedItemIcon,
    GaHeaderNavMinimizedArrow,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { item } = toRefs(props)
    const innerRef = ref()
    const textRef = ref()
    const prependRef = ref()
    const appendRef = ref()

    const mods = computed(() => {
      const { parent } = item.value.mods

      return {
        parent,
      }
    })

    const { contentVisible } = useContentFit(
      item,
      innerRef,
      textRef,
      prependRef,
      appendRef,
    )

    const style = computed(() => ({
      '--header-nav-item-color': unref(item).viewOptions?.textColor,
      opacity: unref(contentVisible) ? 1 : 0,
    }))

    const { onClick, onKeyDown } = useItemEventsEmitters(item, emit)

    return {
      mods,

      innerRef,
      textRef,
      prependRef,
      appendRef,

      style,

      onClick,
      onKeyDown,
    }
  },
}

<template>
  <svg viewBox="0 0 21 20">
    <path
      stroke="none"
      d="M10.083 20.004h.834c4.533 0 6.791 0 8.191-1.4 1.392-1.4 1.392-3.667 1.392-8.183v-.85c0-4.5 0-6.767-1.392-8.167-1.4-1.4-3.666-1.4-8.191-1.4h-.834c-4.533 0-6.791 0-8.191 1.4C.5 2.804.5 5.07.5 9.587v.85c0 4.5 0 6.767 1.4 8.167 1.4 1.4 3.667 1.4 8.183 1.4Z"
    />
    <path
      fill="#000"
      stroke="none"
      d="M11.142 14.415c-4.559 0-7.159-3.125-7.267-8.325h2.283c.075 3.817 1.759 5.433 3.092 5.767V6.09h2.15v3.292c1.317-.142 2.7-1.642 3.167-3.292h2.15a6.35 6.35 0 0 1-2.925 4.15 6.583 6.583 0 0 1 3.425 4.175H14.85a4.117 4.117 0 0 0-3.45-2.975v2.975h-.258Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-social-vk-square',
}
</script>

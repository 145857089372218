import { GaModalBackButton } from '@ga/ui-components/modal/back-button'

// @vue/component
export default {
  name: 'ga-auth-modal-header',

  components: {
    GaModalBackButton,
  },

  props: {
    hasBackAction: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const onBackAction = () => {
      emit('back')
    }

    return { onBackAction }
  },
}

import { between } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (min, max, message) => {
  return withMessage(between(min, max), {
    type: 'between',
    min,
    max,
    message: 'app.validation.between',
  })(message)
}

/**
 * Поскольку у нас используется несколько скриптов яндекс-метрики
 * этот класс нужен для управления конкретным скриптом яндекс-метрики
 */
export class ModuleItem {
  constructor(gaApp, id) {
    this.gaApp = gaApp

    // Идентификатор яндекс-метрики
    this.id = id

    // Псевдо-модуль, содержащий метод отправки события
    this.module = null

    // Флаг инициалзации
    this.isInit = false

    /**
     * Очередь событий, которая наполняется до момента инициализации яндекс-метрики
     * После инициализации яндекс-метрики, проходим по этому массиву и вызываем события
     */
    this.queue = []
  }

  /**
   * Возвращает имя события, которое нужно слушать для отслеживания события инита яндекс-метрики
   *
   * @return {string}
   */
  get listenerName() {
    return `yacounter${this.id}inited`
  }

  /**
   * Инициализация модуля.
   *
   * Слушает событие `yacounter{this.id}inited`,
   * которое посылается яндекс-метрикой когда она готова к работе.
   *
   * @returns {void}
   */
  init() {
    document.addEventListener(this.listenerName, this.create.bind(this))
  }

  /**
   * Создает псевдо-модуль, если он не создан
   *
   * @returns {boolean}
   */
  create() {
    if (this.isInit) {
      return false
    }

    // Удаляем слушатель события инита
    document.removeEventListener(this.listenerName, this.create)

    if (window.ym) {
      this.module = {
        /**
         * Метод отправки-события
         * Частично-примененная ф-ия с id-метрики
         */
        push: (args) => window.ym(this.id, ...args),
      }

      // После инита яндекс-метрики проходимся по очереди и вызываем события
      this.queue.forEach((elem) => this.send(elem))

      // Очищаем очередь
      this.queue = []

      // Устанавливаем флаг инита
      this.isInit = true
    }
  }

  send(args) {
    if (!this.module) {
      return this.queue.push(args)
    }

    const push = () => this.module.push(args)

    return this.gaApp.services.app.scheduler.postTask(push, 'background')
  }
}

import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const landingStore = () => {
  const init = defineStore('giftCardsB2bLanding', {
    state,
  })

  return init(getActivePinia())
}

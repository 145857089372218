export const PAGE_SIZE = 24

export const PAGE_TYPE = {
  SEARCH: 'search',
  LISTING: 'listing',
  BRANDZONE: 'brandzone',
}

export const PAGINATION_DIRECTION = {
  NEXT: 'next',
  PREV: 'prev',
}

export const CLEAR_PAGES = {
  BEFORE_REQUEST: 'beforeRequest',
  AFTER_REQUEST: 'afterRequest',
  NONE: null,
}

export const WINDOW_INNER_HEIGHT_COEFFICIENT = 0.65

export const CARD_SIZE = {
  L: 'L',
  M: 'M',
  S: 'S',
}

export const CARD_SIZE_NUMBER = {
  [CARD_SIZE.L]: 1,
  [CARD_SIZE.M]: 2,
  [CARD_SIZE.S]: 3,
}

export const AUTOPLAY_DELAY = 500

export const WIDGET_TYPE = 'product'
export const STRATEGY_NAME = 'vectors_extended,zero_queries'

/**
 * Устанавливает прозрачность иконок
 * промежуточный этап для красивого перерасчета при ресайзе
 * @param {Node} nodePrepend - может быть null
 * @param {Node} nodeAppend - может быть null
 * @param {Number} nodePrependOpacity
 * @param {Number} nodeAppendOpactiy
 */
export const setPrependAppendOpacity = (
  nodePrepend,
  nodeAppend,
  nodePrependOpacity,
  nodeAppendOpactiy,
) => {
  if (nodePrepend) nodePrepend.style.opacity = nodePrependOpacity
  if (nodeAppend) nodeAppend.style.opacity = nodeAppendOpactiy
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 37 24">
    <mask id="pay-methods-split-a" fill="#fff">
      <path
        fill-rule="evenodd"
        d="M11.716 22c5.366 0 9.716-4.477 9.716-10s-4.35-10-9.716-10S2 6.477 2 12s4.35 10 9.716 10Zm.449-14.402h.983v10.41h1.967v-12h-2.95c-2.95 0-4.495 1.59-4.495 3.903 0 1.88.843 2.892 2.388 4.049l-2.67 4.048h2.248l2.81-4.482-.983-.723c-1.265-.868-1.827-1.59-1.827-3.036 0-1.301.843-2.169 2.529-2.169Z"
        clip-rule="evenodd"
      />
    </mask>
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M11.716 22c5.366 0 9.716-4.477 9.716-10s-4.35-10-9.716-10S2 6.477 2 12s4.35 10 9.716 10Zm.449-14.402h.983v10.41h1.967v-12h-2.95c-2.95 0-4.495 1.59-4.495 3.903 0 1.88.843 2.892 2.388 4.049l-2.67 4.048h2.248l2.81-4.482-.983-.723c-1.265-.868-1.827-1.59-1.827-3.036 0-1.301.843-2.169 2.529-2.169Z"
      clip-rule="evenodd"
    />
    <path
      fill="#000"
      d="M13.148 7.598h1v-1h-1v1Zm0 10.41h-1v1h1v-1Zm1.967 0v1h1v-1h-1Zm0-12h1v-1h-1v1Zm-5.057 7.952.835.55.518-.786-.754-.565-.6.8Zm-2.67 4.048-.834-.55-1.022 1.55h1.857v-1Zm2.248 0v1h.554l.294-.47-.848-.53Zm2.81-4.482.847.531.493-.787-.748-.55-.592.806Zm-.983-.723.592-.806-.013-.01-.014-.009-.565.825Zm8.97-.803c0 4.998-3.93 9-8.717 9v2c5.945 0 10.716-4.952 10.716-11h-2Zm-8.717-9c4.787 0 8.716 4.002 8.716 9h2c0-6.048-4.77-11-10.716-11v2ZM3 12c0-4.998 3.93-9 8.716-9V1C5.771 1 1 5.952 1 12h2Zm8.716 9C6.93 21 3 16.998 3 12H1c0 6.048 4.77 11 10.716 11v-2Zm1.432-14.402h-.983v2h.983v-2Zm1 11.41V7.598h-2v10.41h2Zm.967-1h-1.967v2h1.967v-2Zm-1-11v12h2v-12h-2Zm-1.95 1h2.95v-2h-2.95v2ZM8.67 9.91c0-.926.302-1.614.814-2.08.522-.474 1.38-.823 2.681-.823v-2c-1.649 0-3.039.446-4.027 1.344-.998.908-1.468 2.172-1.468 3.56h2Zm1.987 3.248c-.742-.556-1.224-1.011-1.53-1.49-.289-.453-.457-.984-.457-1.758h-2c0 1.106.253 2.021.772 2.834.501.786 1.214 1.414 2.017 2.015l1.198-1.6Zm-2.433 5.4 2.669-4.049-1.67-1.1-2.669 4.047 1.67 1.101Zm1.412-1.551H7.39v2h2.247v-2Zm1.963-4.013-2.81 4.482 1.695 1.062 2.81-4.482-1.695-1.062Zm-.729.614.984.723 1.184-1.612-.983-.723-1.185 1.612ZM8.636 9.767c0 .863.171 1.604.595 2.271.408.641.998 1.131 1.666 1.59l1.131-1.65c-.596-.409-.919-.714-1.109-1.012-.173-.273-.283-.616-.283-1.2h-2Zm3.529-3.169c-1.005 0-1.906.26-2.566.853-.673.607-.963 1.442-.963 2.316h2c0-.428.132-.676.302-.83.183-.164.546-.339 1.227-.339v-2Z"
      mask="url(#pay-methods-split-a)"
    />
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M23.629 2.765a9.018 9.018 0 0 0-2.513 1.602c-1.443 1.306-2.362 2.773-2.814 4.29a8.141 8.141 0 0 0-.053.183l-.007.022a10.162 10.162 0 0 0-.096.337c-.868 3.209-.16 6.804 2.179 9.409 3.583 3.991 9.72 4.242 13.6.554a.423.423 0 0 1 .016-.016l.017-.016-.01-.011a1.259 1.259 0 0 0 .294-.288c1.081-1.466-.592-5.442-3.914-9.308 2.696-2.288 4.207-3.806 4.19-4.237-.005-.067-.077-.148-.077-.148l-.002.007v.007a.138.138 0 0 0-.006-.01l.005-.004v-.002l-.01-.013-.011-.012-.001-.001h-.002v-.002l-.001-.001-.002-.004a9.977 9.977 0 0 0-.3-.312 9.474 9.474 0 0 0-7.026-2.786 9.511 9.511 0 0 0-1.664.198 9.41 9.41 0 0 0-1.792.562Zm6.631 15.374c-1.675-.907-3.54-2.42-5.283-4.292.725-.563 3.861-3.009 5.13-4.045 1.538 1.8 2.747 3.66 3.445 5.305.378.888.578 1.66.609 2.275.03.618-.11 1.05-.382 1.3-.271.251-.713.355-1.322.27-.607-.086-1.353-.356-2.197-.813Z"
      clip-rule="evenodd"
    />
    <path
      stroke="#fff"
      stroke-width="2"
      d="M11.606 22.773c5.884 0 10.605-4.902 10.605-10.887S17.49 1 11.606 1C5.72 1 1 5.901 1 11.886c0 5.985 4.721 10.887 10.606 10.887Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-split',
}
</script>

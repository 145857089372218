import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsTitleClickModel extends GaAnalyticsBaseModel {
  constructor({ itemListId, itemListName, url }) {
    super('listing')

    this.category = {
      itemListName: `${itemListName}/listing`,
      itemListId,
      url,
    }

    this.debug = {
      key: 'placements-title-click',
      data: {
        itemListName: `${itemListName}/listing`,
        itemListId,
        url,
      },
    }
  }
}

/**
 * @typedef {string} Theme
 */

/**
 * @enum {Theme}
 */

export const THEME = {
  DEFAULT: 'default',
  GRAY: 'gray',
  INVERSE: 'inverse',
}

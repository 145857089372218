import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref, watch } from 'vue'

/**
 * Отправляет запрос получения данных для рекомендательных блоков
 * Возвращает объект с реактивными значением состояния загрузки данных и массивом данных для рек блоков.
 *
 * @param {Array} [productsIds = []] - id продуктов
 * @param {String} [categoryId = null] - id категории
 * @param {String} type - тип страницы. Возможные значения см. в константах модуля.
 * @return {Object} Объект, содержащий свойства pending - состояние загрузки данных, placements - массив объектов с данными для рек блоков
 */
export const usePlacements = ({
  productsIds = [],
  categoryId = null,
  type,
}) => {
  const { $gaApp } = useContext()

  const pending = ref(true)
  const placements = ref([])
  const productIdsLength = computed(() => unref(productsIds).length)

  const fetchPlacements = () => {
    pending.value = true

    $gaApp.services.placements.api
      .getProducts({
        productsIds: unref(productsIds),
        categoryId: unref(categoryId),
        type: unref(type),
      })
      .then((data) => {
        const filteredData =
          data && data.length
            ? data.filter((placement) => placement.products?.length)
            : []

        placements.value = filteredData
      })
      .finally(() => {
        pending.value = false
      })
  }

  fetchPlacements()

  watch(productIdsLength, () => {
    fetchPlacements()
  })

  return {
    pending,
    placements,
  }
}

import isEmpty from 'lodash/isEmpty'

import { computed } from 'vue'

export const useImage = (props) => {
  const hasImage = computed(() => !isEmpty(props.image))

  return {
    hasImage,
  }
}

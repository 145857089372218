export class GdeSlonProductBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { id, price, quantity } = product

    this.id = id
    this.price = price

    if (quantity) {
      this.quantity = quantity
    }
  }
}

import { TYPE } from '../../constants'
import { GtmGa4HowToModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class HowToBuilder extends BaseBuilder {
  constructor(product) {
    const models = new Map([[TYPE.GTM_4, GtmGa4HowToModel]])
    super(models, product)
  }
}

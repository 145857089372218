import { computed, toRefs, unref } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { useItemEventsEmitters } from '../../../../../../../helpers/nav'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-screens-item',

  components: {
    GaAction,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { item } = toRefs(props)

    const mods = computed(() => {
      const { parent } = item.value.mods

      return {
        parent,
      }
    })

    const style = computed(() => ({
      '--header-nav-item-color': unref(item).viewOptions?.textColor,
    }))

    const { onClick, onKeyDown } = useItemEventsEmitters(item, emit)

    return {
      mods,

      style,

      onClick,
      onKeyDown,
    }
  },
}

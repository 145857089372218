import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useVideoAutoplay = () => {
  const { $gaApp } = useContext()

  const canVideoAutoplay = computed(
    () => $gaApp.stores.app.main.canVideoAutoplay,
  )

  const handleVideoPlayError = (error) =>
    $gaApp.services.app.video.handleVideoPlayError(error)

  return {
    canVideoAutoplay,

    handleVideoPlayError,
  }
}

import { ref, unref, watch } from 'vue'

/**
 * Возвращает флаги видимости хедера
 * @param {Ref<Boolean>} visible - видимость модалки
 * @param {Ref<Number>} scrollPosition - позиция скролла inner блока
 * @param {Ref<Number>} headerShowFrom - пороговое значение из props
 * @param {Object} slots - слоты контекста
 * @param {Function} emit - emit функция контекста
 */
export const useHeader = (
  visible,
  scrollPosition,
  headerShowFrom,
  slots,
  emit,
) => {
  const hasHeader = !!slots.header
  const headerShown = ref(false)

  if (hasHeader) {
    watch(headerShown, (value) => {
      emit('header-shown', value)
    })

    watch(visible, (value) => {
      if (!value) headerShown.value = false
    })

    watch(scrollPosition, (newValue, oldValue) => {
      headerShown.value =
        oldValue > newValue && newValue > unref(headerShowFrom)
    })
  }

  return {
    hasHeader,
    headerShown,
  }
}

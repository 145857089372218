export const moduleBrandsSsrRoutes = [
  {
    moduleType: 'brands',
    pageType: 'main',
    path: '/brands',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/brands/page/main' */
        '~/modules/brands/components/pages/main.vue'
      ),
  },
]

export const routes = [...moduleBrandsSsrRoutes]

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref } from 'vue'

export const useCartAdd = (product) => {
  const { $gaApp } = useContext()
  const isLoading = ref(false)

  const productId = computed(() => {
    return unref(product).id || unref(product).itemId
  })

  // флаг добавленного товара в корзину
  const isAdded = computed(
    () => !!$gaApp.services.cart.main.getProductItem(unref(productId)),
  )
  // флаг последнего товара на складе
  const isWholeStock = computed(() =>
    $gaApp.services.cart.main.checkProductWholeStock(unref(productId)),
  )
  // нужна ли онлайн-консультация по товару
  const needWarning = computed(() => unref(product)?.needOnlineConsultation)

  // добавление товара в корзину
  const addToCart = async (_product = unref(product)) => {
    if (isLoading.value) {
      return
    }

    if (needWarning.value) {
      //  показываем диалоговое окно предупреждение, если нужна онлайн консультация
      return $gaApp.services.cart.warning.open({
        productData: _product,
      })
    }

    isLoading.value = true

    await $gaApp.services.cart.api.addItem(_product)

    isLoading.value = false
  }

  return {
    isLoading,
    isAdded,
    isWholeStock,

    addToCart,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { ref, unref, watch } from 'vue'

/**
 *
 * @param {Ref<Boolean>} visible
 * @param {Ref<Boolean} noScroll
 * @param {Reactive<Object>} refs
 * @param {Function} emit
 */
export const useScroll = (visible, noScroll, refs, emit) => {
  const scrollPosition = ref(0)

  const onScroll = (event) => {
    scrollPosition.value = event.target.scrollTop

    emit('scroll', event)
  }

  watch(visible, (value) => {
    if (!value && !unref(noScroll) && refs.inner) {
      // TODO: придумать нормальный способ ресета скролла при display: none
      refs.inner.replaceWith(refs.inner)
    }
  })

  const { $scrollTo } = useContext()

  // Публичные методы
  const scrollTo = (element, options = {}) => {
    const container = refs.inner
    const elementInternal = element || container

    $scrollTo(elementInternal, { container, ...options })
  }

  const getScrollTop = () => {
    return refs.inner.scrollTop
  }

  const getScrollHeight = () => {
    return refs.inner.scrollHeight
  }

  return {
    scrollPosition,
    onScroll,

    scrollTo,
    getScrollTop,
    getScrollHeight,
  }
}

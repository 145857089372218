<template>
  <svg fill="none" stroke="none" viewBox="0 0 55 20">
    <path
      fill="#00D800"
      d="M28.891 11.516h1.947c3.698 0 5.682-1.478 5.682-4.418 0-2.85-1.695-4.277-5.682-4.277h-4.202V15.45h2.255v-3.934Zm0-6.62h2.147c2.199 0 3.138.596 3.138 2.148 0 1.713-1.083 2.398-3.176 2.398h-2.118l.01-4.545Z"
    />
    <path
      fill="#00D800"
      d="M39.91 15.6c1.445 0 2.544-.722 2.977-1.767v1.624h1.984V9.504c0-2.354-1.3-3.249-3.933-3.249-1.551 0-2.634.363-3.227.812v2.634c.612-.955 1.567-1.605 3.227-1.605 1.21 0 1.822.398 1.822 1.678v.396H40.72c-2.217 0-3.714.577-3.714 2.634 0 1.768 1.08 2.796 2.902 2.796Zm1.066-4.058h1.784v.73c-.09.612-.758 1.412-2.182 1.412-.942 0-1.443-.433-1.443-1.118 0-.79.595-1.024 1.84-1.024Z"
    />
    <path
      fill="#00D800"
      d="M48.237 17.148a1.592 1.592 0 0 1-1.083-.452v1.968c.27.236.883.396 1.443.396 1.191 0 2-.488 2.797-2.65L55 6.432h-2.236l-2.02 6.186-2.472-6.186h-2.354l3.807 9.056c-.346 1.226-.834 1.66-1.488 1.66Z"
    />
    <path
      fill="#00D800"
      d="M5.987 7.087v2.77L9.66 12.16l8.792-6.469a9.65 9.65 0 0 0-1.158-1.918L9.66 9.389 5.987 7.087Z"
    />
    <path
      fill="#00D800"
      d="M9.659 19.302a9.649 9.649 0 0 0 9.65-9.651c0-.58-.051-1.16-.155-1.73l-2.078 1.53v.2a7.427 7.427 0 1 1-3.237-6.12l1.883-1.382a9.65 9.65 0 1 0-6.058 17.153h-.005Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-payment-sber',
}
</script>

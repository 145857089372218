import get from 'lodash/get'
import set from 'lodash/set'

import { del as $del, set as $set } from 'vue'

import { byteToMb, compressImageSize, convertBase64 } from '@ga/image-utils'
import { getId } from '@ga/utils'

import { KEYS, VALIDATION_RULES } from '../constants/media'

/**
 *
Предоставляет методы для работы с медиафайлами (изображения, видео) в форме отзыва:

`upload(files)` - загружает переданные файлы.

`getPrepareFile(file)` - возвращает объект с подготовленными данными для указанного файла, включая itemId, sessionId и расширение файла.

`prepareFiles(files)` - подготавливает переданные файлы к загрузке, добавляя их в store media с уникальными идентификаторами.

`delete(id)` - удаляет файл с указанным идентификатором из store media и прерывает процессы сжатия и загрузки этого файла.

`abortProcessUpload(id)` - прерывает процессы сжатия и загрузки файла с указанным идентификатором.

`toBase64(id)` - конвертирует файл с указанным идентификатором в base64-представление.

`setFileData(id, key, value)` - устанавливает значение указанного ключа для файла с указанным идентификатором в store media.

`getFileData(id, key)` - получает значение указанного ключа для файла с указанным идентификатором из store media.

`toCompressed(id)` - сжимает файл с указанным идентификатором, если он соответствует ограничениям по размеру и разрешению.

`fillSourceImage()` - заполняет превью изображений в store media, конвертируя их в base64-представление.

`setError(errorText)` - добавляет сообщение об ошибке в store media.

`removeError()` - очищает сообщения об ошибках в store media.

`getValidatedFiles(files)` - валидирует переданные файлы по длине, размеру и расширению, возвращая только валидные файлы.

`validations` - объект с методами валидации файлов по размеру и расширению.

`validationLength(files)` - валидирует количество файлов относительно максимально допустимого количества.

`toFormData(file)` - преобразует данные файла в объект FormData для отправки на сервер.

`prepareUploadFile(id)` - подготавливает данные файла с указанным идентификатором для отправки на сервер в формате FormData.
 */

export class MediaService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async upload(files) {
    if (!files) {
      return
    }

    const validatedFiles = this.getValidatedFiles(files)

    this.prepareFiles(validatedFiles)

    // фичтогл предварительной загрузки превью изрбражений
    if (this.gaApp.features.get('reviewFormPreloadImage')) {
      this.fillSourceImage()
    }

    for (const id of this.gaApp.stores.review.media.needLoadFiles) {
      await this.toCompressed(id)
      await this.gaApp.services.review.api.uploadMedia(id)
    }
  }

  getPrepareFile(file) {
    return {
      [KEYS.ITEM_ID]: this.gaApp.stores.review.form.itemId,
      [KEYS.FILE]: file,
      [KEYS.FILE_EXTENSION]: file.name.split('.').pop(),
      [KEYS.SESSION_ID]: this.gaApp.stores.review.form.sessionId,
    }
  }

  prepareFiles(files) {
    for (const file of files) {
      const id = getId()
      this.gaApp.stores.review.media.fileIds.push(id)

      const prepareFile = {
        src: '',
        id,
        mediaId: null,
        file,
        abortControllerCompression: new AbortController(),
        isAdded: false,
        isCompressing: false,
        isUploading: false,
      }

      $set(this.gaApp.stores.review.media.files, id, prepareFile)
    }
  }

  delete(id) {
    const index = this.gaApp.stores.review.media.fileIds.indexOf(id)
    if (index === -1) {
      return
    }
    // сброс процесса компрессии и загрузки
    this.abortProcessUpload(id)

    const [deleteId] = this.gaApp.stores.review.media.fileIds.splice(index, 1)

    $del(this.gaApp.stores.review.media.files, deleteId)
  }

  abortProcessUpload(id) {
    const deletedFile = this.gaApp.stores.review.media.files[id]

    if (deletedFile?.isCompressing) {
      deletedFile.abortControllerCompression.abort(
        new Error('Deleted compressing image'),
      )
    }

    if (deletedFile?.isUploading) {
      this.gaApp.api.requestAbort.abortRequest(id)
    }
  }

  async toBase64(id) {
    const file = this.getFileData(id, 'file')
    if (!file) {
      return null
    }

    return await convertBase64(file)
  }

  setFileData(id, key, value) {
    if (id in this.gaApp.stores.review.media.files) {
      set(this.gaApp.stores.review.media.files, [id, key], value)
    }
  }

  getFileData(id, key) {
    return get(this.gaApp.stores.review.media.files, [id, key], null)
  }

  async toCompressed(id) {
    this.setFileData(id, 'isCompressing', true)
    const file = this.getFileData(id, 'file')
    if (!file) {
      return null
    }

    const compressedFile = await compressImageSize(file, {
      maxSizeMB: VALIDATION_RULES.SIZE_MB,
      maxWidthOrHeight: VALIDATION_RULES.WIDTH,
      signal: this.getFileData(id, 'abortControllerCompression').signal,
    })

    if (!this.validations.size(compressedFile)) {
      return this.delete(id)
    } else {
      this.setFileData(id, 'file', compressedFile)
      this.setFileData(id, 'isCompressing', false)
    }

    return compressedFile
  }

  // Заполняем src изображений для превью загрузки
  async fillSourceImage() {
    const conversionPromises = this.gaApp.stores.review.media.needLoadFiles.map(
      async (id) => {
        const src = await this.gaApp.services.review.media.toBase64(id)
        this.gaApp.services.review.media.setFileData(id, 'src', src)
      },
    )

    return await Promise.allSettled(conversionPromises)
  }

  setError(errorText) {
    if (this.gaApp.stores.review.media.errors.includes(errorText)) {
      return
    }

    this.gaApp.stores.review.media.errors.push(errorText)
  }

  removeError() {
    this.gaApp.stores.review.media.errors = []
  }

  getValidatedFiles(files) {
    const validatedFiles = this.validationLength(files)
    return validatedFiles.filter((file) => this.validations.extension(file))
  }

  get validations() {
    return {
      size: (value) => {
        if (byteToMb(value?.size) < VALIDATION_RULES.SIZE_MB) {
          return true
        }
        this.setError(
          this.gaApp.i18n.t('review.form.media.error.size', {
            size: VALIDATION_RULES.SIZE_MB,
          }),
        )
        return false
      },
      extension: (value) => {
        if (VALIDATION_RULES.EXTENSIONS.includes(value.type)) {
          return true
        }
        this.setError(
          this.gaApp.i18n.t('review.form.media.error.type', {
            type: VALIDATION_RULES.EXTENSIONS.map((type) =>
              type.split('/').pop(),
            ).join('/'),
          }),
        )
        return false
      },
    }
  }

  validationLength(files) {
    let validFiles = [...files]
    const storeFilesLength = this.gaApp.stores.review.media.preview.length
    const allowedAmount =
      VALIDATION_RULES.MAX_LENGTH - (storeFilesLength + validFiles.length)

    if (allowedAmount < 0) {
      validFiles = validFiles.slice(
        0,
        VALIDATION_RULES.MAX_LENGTH - storeFilesLength,
      )
      this.setError(
        this.gaApp.i18n.t('review.form.media.error.maxLength', {
          maxLength: VALIDATION_RULES.MAX_LENGTH,
        }),
      )
    }

    return validFiles
  }

  toFormData(file) {
    const formData = new FormData()

    Object.entries(file).forEach(([key, value]) => {
      formData.append(key, value)
    })
    return formData
  }

  prepareUploadFile(id) {
    const file = this.getFileData(id, 'file')

    if (!file) {
      return null
    }

    const data = this.getPrepareFile(file)
    return this.toFormData(data)
  }
}

import { computed, ref } from 'vue'

import { GaSelect } from '../../../select'

import { SIZE } from './scripts/constants'

// @vue/component
export default {
  name: 'ga-input-phone-select-code',
  components: {
    GaSelect,
  },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    callingCode: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    options: {
      type: Array,
      default: () => [],
    },
    withModal: {
      type: Boolean,
      default: false,
    },
    modalZIndex: {
      type: Number,
      default: null,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const selectRef = ref()
    const dropdownPlacement = computed(() =>
      props.rtl ? 'bottom-end' : 'bottom-start',
    )

    const onModalFinishClose = () => {
      selectRef.value?.close()
    }

    return {
      selectRef,
      dropdownPlacement,
      onModalFinishClose,
    }
  },
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import state from './state'

export const listingStore = (gaApp) => {
  const init = defineStore('homeListing', {
    getters: getters(gaApp),
    state,
  })

  return init(getActivePinia())
}

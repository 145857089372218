const ANIMATION = {
  CHANGING: 'changing',
  APPEARING: 'appearing',
  DISAPPEARING: 'disappearing',
}

// @vue/component
export default {
  name: 'ga-counter-base',

  props: {
    value: {
      type: Number,
      required: true,
    },
    noAppearAnimation: {
      type: Boolean,
      default: false,
    },
    noChangeAnimation: {
      type: Boolean,
      default: false,
    },
    noDisappearAnimation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      animation: null,
    }
  },

  computed: {
    shown() {
      return this.value > 0 || Boolean(this.animation)
    },
  },

  watch: {
    value(newValue, oldValue) {
      this.animation = this.getAnimationName(newValue, oldValue)
    },
  },

  methods: {
    getAnimationName(newValue, oldValue) {
      switch (true) {
        case newValue === 0 && this.noDisappearAnimation === false:
          return ANIMATION.DISAPPEARING
        case oldValue === 0 && this.noAppearAnimation === false:
          return ANIMATION.APPEARING
        case this.noChangeAnimation === false:
          return ANIMATION.CHANGING
        default:
          return null
      }
    },

    onAnimationEnd() {
      this.animation = null
    },
  },
}

import { GaPortalTarget } from '@ga/ui-components/portal-target'

// @vue/component
export default {
  name: 'ga-portal-layout-prepend',

  components: {
    GaPortalTarget,
  },
}

import { minValue } from '@vuelidate/validators'

import { withMessage } from '../utils'

export default (min, message) => {
  return withMessage(minValue(min), {
    type: 'minValue',
    min,
    message: 'app.validation.minValue',
  })(message)
}

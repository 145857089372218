/**
 * @typedef {string} Inputmode
 */

/**
 * @enum {Inputmode}
 */

export const INPUTMODE = {
  // Отключает вывод клавиатуры.
  NONE: 'none',

  // Стандартная клавиатура для пользователя, учитывая его локализацию.
  TEXT: 'text',

  // Дробные значения чисел, содержащие разряд и соответствующий символ-разделитель,
  // который учитывает локализацию пользователя (чаще всего "." или ",").
  // Устройства могут показывать или нет символ минуса (отрицательное значение).
  DECIMAL: 'decimal',

  // Числовая клавиатура – числа от 0 до 9.
  // Устройства могут показывать или нет символ минуса (отрицательное значение).
  NUMERIC: 'numeric',

  // Клавиатура ввода номера телефона, включая числа от 0 до 9, звёздочку ("*"), и решетку ("#").
  // Поля ввода, имеющие обязательное поле номера телефона должны использовать <input type="tel">.
  TEL: 'tel',

  // Клавиатура, оптимизированная для работы с поиском.
  // Например, кнопка ввода может быть заменена на "Поиск", и др.
  SEARCH: 'search',

  // Клавиатура, оптимизированная для ввода электронной почты, с использованием символа "@" и др.
  // Поля ввода, имеющие обязательное поле почты должны использовать <input type="email">.
  EMAIL: 'email',

  // Виртуальная клавиатура, оптимизированная для ввода ссылок. Может иметь кнопку "/",
  // расположенную в более удобном месте. Поле может содержать функцию истории ввода и др.
  // Поля ввода, имеющие обязательное поле ссылки URL должны использовать <input type="url">.
  URL: 'url',
}

import { EXCLUDE_REMOVE_HASH, MODAL_HASH } from '../constants'

export class HashService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  detectModalName(hash) {
    return Object.values(MODAL_HASH).find(
      (modalHash) => hash.indexOf(modalHash) === 0,
    )
  }

  openModal(hash, params) {
    const payload = { ...params, value: true }

    return {
      [MODAL_HASH.AUTH]: () =>
        this.gaApp.services.modal.main.openSuperModalAuth(payload),
      [MODAL_HASH.CART]: () =>
        this.gaApp.services.modal.main.openSuperModalCart(payload),
      [MODAL_HASH.LOCATION]: () => this.gaApp.services.location.modal.open(),
      [MODAL_HASH.GIFT_CARDS]: () =>
        this.gaApp.services.giftCards.digital.openModalWithStep(payload),
    }[hash]()
  }

  parseParams(params = '') {
    const entries = params.split('&').map((param) => param.split('='))

    return Object.fromEntries(entries)
  }

  remove() {
    const [path] = this.gaApp.route.fullPath.split('#')

    this.gaApp.router.replace({ path })
  }

  check(hash) {
    if (!hash) {
      return
    }

    const [hashName, hashParams = ''] = hash.slice(1).split('?')

    const name = hashName
    let params = hashParams

    if (!name || !this.detectModalName(name)) {
      return
    }

    if (params) {
      params = this.parseParams(hashParams)
    }

    this.openModal(name, params)

    if (!EXCLUDE_REMOVE_HASH.includes(hashName)) {
      this.remove()
    }
  }
}

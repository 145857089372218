import { price } from './price'

export const couponPercent = {
  type: 'object',
  properties: {
    linkCoupon: { type: 'string', required: false },
    code: { type: 'string', required: true },
    type: { type: 'string', required: true },
    discount: price,
  },
  additionalProperties: false,
}

// Переводит классы по бэм в классы css модулей

export const getCssModulesClassNames = function (style, names) {
  // Получаем массив классов
  const nameArr = names.split(' ')

  // Если в строке один класс сразу получаем новое имя
  if (nameArr.length === 1) {
    return style[names]
  }

  // Получаем модульные классы
  return nameArr
    .reduce((result, name) => {
      // Проверяем есть ли нужный класс в css модулях
      if (name in style) {
        result.push(style[name])
      }

      return result
    }, [])
    .join(' ')
}

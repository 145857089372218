import { URL_PAGE_KEY } from '../constants/keys'

export class OffersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadListing() {
    this.gaApp.services.promo.offers.setLoading(true)
    await this.loadOffers(this.gaApp.route.query[URL_PAGE_KEY] || 1)

    this.gaApp.services.promo.offers.setLoading(false)
  }

  async loadOffers(page) {
    await this.gaApp.services.promo.api.fetchOffers({ page })
  }

  async loadNewPage() {
    this.gaApp.services.promo.offers.setLoading(true)

    this.clearUrl()
    await this.gaApp.services.promo.offers.clearPages()
    await this.gaApp.services.promo.offers.loadPromotions(1)

    this.gaApp.services.promo.offers.setLoading(false)
  }

  setOffersCount(count) {
    this.gaApp.stores.promo.offers.offersCount = count
  }

  setPage(page) {
    this.gaApp.stores.promo.offers.pages.push(page)
    this.gaApp.stores.promo.offers.pages.sort((a, b) => a.number - b.number)
  }

  setLoading(isLoading) {
    this.gaApp.stores.promo.offers.isLoading = isLoading
  }

  setShouldReloadPage(shouldReloadPage) {
    this.gaApp.stores.promo.offers.shouldReloadPage = shouldReloadPage
  }

  addViewedId(id) {
    this.gaApp.stores.promo.offers.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.promo.offers.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }

  clearPages() {
    this.gaApp.stores.promo.offers.pages = []
  }

  clearUrl() {
    const currentQuery = this.gaApp.route.query

    if (Object.keys(currentQuery).length) {
      this.gaApp.router.replace({ query: {} })
    }
  }

  async changeUrlPage(page) {
    const query = this.gaApp.route.query
    const queryPage = +query[URL_PAGE_KEY] || 1

    if (queryPage === page) {
      return
    }

    await this.gaApp.router.replace({
      query: {
        ...query,
        [URL_PAGE_KEY]: page > 1 ? page : undefined,
      },
    })
  }
}

/*
 * возвращает массив целых чисел по возрастанию
 * @param {number} start - первый элемент
 * @param {number} end - последний элемент
 */

const rangeArray = (start, end) => {
  const array = []
  for (let i = start; i <= end; i += 1) {
    array.push(i)
  }
  return array
}

/**
 * Функция генерации последовательности
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 */
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export { rangeArray, range }

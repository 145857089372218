import { GaIconAdditionalCross } from '@ga/icons'

import { GaAction } from '../../action'
import { GaIcon } from '../../icon'

// @vue/component
export default {
  name: 'ga-modal-sticky-header',

  components: {
    GaAction,
    GaIcon,
    GaIconAdditionalCross,
  },

  props: {
    shown: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    onCloseClick() {
      this.$emit('close:click')
    },
  },
}

import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export * from './use'

export const location = {
  meta: {
    name: 'location',
    locales,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

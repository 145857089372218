import { FORM, KEYS } from '../../../constants/form'
import {
  CommentBuilder,
  ExitBuilder,
  FilterBuilder,
  OpenFormBuilder,
  OpenGalleryBuilder,
  RatingBuilder,
  ReadBuilder,
  RecommendedBuilder,
  RouteBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class ReviewAnalytics extends BaseController {
  /**
   * инпут рейтинг.
   */
  onChangeRating() {
    const rating = this.getFormData(FORM.RATING, KEYS.STARS)

    this.send(RatingBuilder, {
      sku: this.reviewProductId,
      rating,
    })
  }

  /**
   * клик на кнопку далее на 2-м шаге формы отзыва.
   */
  onClickComment() {
    const mediaCount = this.getStoreData(['media', 'preview'])?.length ?? 0
    this.send(CommentBuilder, {
      sku: this.reviewProductId,
      mediaCount,
      hasComment: true,
    })
  }
  /**
   * клик на радиобатон рекомендации.
   */

  onClickRecommended() {
    const isRecommended = this.getFormData(
      FORM.RECOMMENDATION,
      KEYS.IS_RECOMMENDED,
    )

    this.send(RecommendedBuilder, {
      sku: this.reviewProductId,
      isRecommended,
    })
  }

  /**
   * закрытие страницы оценки отзыва.
   */
  onExitForm() {
    const step = this.getStoreData(['form', 'humanCurrentStep'])
    this.send(ExitBuilder, {
      sku: this.reviewProductId,
      step,
    })
  }

  /**
   * открытие формы отзыва.
   */
  onOpenForm() {
    this.send(OpenFormBuilder, {
      sku: this.reviewProductId,
    })
  }

  /**
   * открытие модалки отзыва.
   */
  onOpenDetail() {
    this.send(ReadBuilder, {
      sku: this.listingProductId,
    })
  }

  /**
   * открытие листинга отзывов из пдп
   */
  onClickOpenListing({ source }) {
    this.send(RouteBuilder, {
      sku: this.previewProductId,
      source,
    })
  }

  /**
   * применение фильтров на листинге отзывов
   */
  onApplyFilter() {
    this.send(FilterBuilder, {
      sku: this.listingProductId,
      filters: this.filtersValue,
    })
  }

  /**
   * просмотр галереи медиа отзывов
   */
  onOpenGallery() {
    this.send(OpenGalleryBuilder, {
      sku: this.listingProductId,
    })
  }
}

import cloneDeep from 'lodash/cloneDeep'

export class DigitalCounterpartyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Очистка информации о контрагенте
   */
  clearInfo() {
    this.gaApp.stores.giftCardsB2b.digital.counterparty.infoIsVisible = false
    this.gaApp.stores.giftCardsB2b.digital.counterparty.info = {}
  }

  /**
   * Заполнение информации о контрагенте исключая поле blocked
   * @param {object} info информация о контр объекте
   */
  fillInfo(info) {
    const copy = cloneDeep(info)

    delete copy.blocked

    this.gaApp.stores.giftCardsB2b.digital.counterparty.info = copy
    this.gaApp.stores.giftCardsB2b.digital.counterparty.infoIsVisible = true
  }
}

import { TYPE } from '../../constants'
import { GtmReadModel } from '../../models'
import { BaseBuilder } from '../base.builder'

export class ReadBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmReadModel]])
    super(models, data)
  }
}

export class ProductAdvDisclaimerService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get labelSizesMap() {
    return {
      mobile: [
        { views: ['llp'], size: 'xs' }, // L карточки
        { views: ['sln', 'slp', 'mlp'], size: 'xxs' }, // S и M карточки
      ],

      tablet: [
        { views: ['lln'], size: 'xs' }, // L  карточки
        { views: ['sln', 'mln'], size: 'xxs' }, // S и M карточки
      ],

      desktop: [
        { views: ['mln', 'mrn', 'lln', 'lrn'], size: 'xs' }, // L и M карточки
        { views: ['sln', 'srn'], size: 'xxs' }, // S-карточки
      ],
    }
  }

  get tooltipPlacementMap() {
    return [
      { views: ['sln', 'mln', 'lln'], placement: 'right' },
      { views: ['srn', 'mrn', 'lrn'], placement: 'left' },
    ]
  }

  getBreakpointName() {
    if (this.gaApp.mq('tablet-')) {
      return 'mobile'
    }

    if (this.gaApp.mq('desktop-small+')) {
      return 'desktop'
    }

    return 'tablet'
  }

  /**
   * Задача: динамически передавать пропс размера лейбла рекламы
   *
   * Проблема: на одной и той же ширине экрана, карточка, в зависимости от позиции в листинге
   * может быть любого размера: большая, средняя или маленькая. Соотвественно размер лейбла
   * должен быть динамическими соотвествовать размеру карточки согласно дизайну.
   * Проблема в том, что в CSS размер лейбла прописать нельзя, т.к. нужно передать его пропсом
   */
  getLabelSize(productCardViews = {}) {
    const breakpointCurrent = this.getBreakpointName()
    const productViewCurrent = productCardViews[breakpointCurrent]
    const mapSizesByBreakpoint = this.labelSizesMap[breakpointCurrent]

    return mapSizesByBreakpoint.find(({ views }) =>
      views.includes(productViewCurrent),
    )?.size
  }

  /**
   * Задача: динамически задавать сторону, в которую открывается тултип
   *
   * Для лейблов, которые располагаются справа, тултип открываем влево
   * Для лейблов, которые располагаются слева, тултип открываем вправо
   */
  getTooltipPlacement(productCardViews = {}) {
    const breakpointCurrent = this.getBreakpointName()
    const productViewCurrent = productCardViews[breakpointCurrent]

    return this.tooltipPlacementMap.find(({ views }) =>
      views.includes(productViewCurrent),
    )?.placement
  }
}

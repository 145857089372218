import replace from 'lodash/replace'

import { doubleRAF } from '@ga/shared-browser'

import { SCROLL_HASH_PREFIX, SCROLL_HASH_PREFIX_REGEXP } from '../constant'

export class ScrollNavigationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setAnchor(anchor, href = window.location.href) {
    const hash = SCROLL_HASH_PREFIX + anchor
    const url = href + hash

    if (window.location.hash === hash) {
      return false
    }

    return this.gaApp.services.app.router.pushState(
      window.history.state,
      '',
      url,
    )
  }

  getAnchor() {
    const { hash, fullPath } = this.gaApp.route

    if (hash) {
      const [path] = fullPath.split('#')

      this.gaApp.router.replace({ path })
    }

    return hash
  }

  findElement(anchor, dataAttrName = 'data-scroll-id') {
    if (!anchor) {
      return false
    }

    const id = replace(anchor, SCROLL_HASH_PREFIX_REGEXP, '')
    const element = document.querySelector(`[${dataAttrName}="${id}"]`)

    return element
  }

  scrollToAnchor(
    anchor,
    dataAttrName = 'data-scroll-id',
    blockPosition = 'center',
  ) {
    doubleRAF(() => {
      const element = this.findElement(anchor, dataAttrName)

      element && element.scrollIntoView({ block: blockPosition })
    })
  }
}

export {
  SIZE as VERTICAL_CARD_SIZE,
  RATIO as VERTICAL_CARD_RATIO,
  INFO_INDENT as VERTICAL_CARD_INFO_INDENT,
} from '../components/organisms/product-card-vertical/scripts/consts'

export { INFO_INDENT as HORIZONTAL_CARD_INFO_INDENT } from '../components/organisms/product-card-horizontal/scripts/consts'

export const STOCK_STATUS = {
  IN_STOCK: 'in-stock',
  OOS: 'oos',
  WHOLE_STOCK_IN_CART: 'whole-stock-in-cart',
}

export const DISCLAIMER_TOOLTIP_PLACEMENT = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  AUTO: 'auto',
}

export const ROOT_TEST_ID = 'ga-product-card'

export const ELEMENT_TEST_IDS = {
  labels: 'labels',
  type: 'type',
  name: 'name',
  attributes: 'attributes',
  rating: 'rating',
  favoritesButton: 'add-to-favorite',
  cartButton: 'add-to-cart',
  prices: {
    old: 'old-price',
    actual: 'actual-price',
    prefix: 'price-prefix',
  },
}

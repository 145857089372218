<template>
  <svg viewBox="0 0 25 25">
    <path stroke="none" d="M3 4h19v1.5H3zm0 8h19v1.5H3zm0 8h19v1.5H3z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-burger',
}
</script>

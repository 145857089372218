import { STORAGE_GUEST_CART_ID_KEY } from '../constants'

export class GuestService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение айди гостевой корзины из локального хранилища
   */
  getId() {
    return (
      this.gaApp.services.app.browserStorage.local.get(
        STORAGE_GUEST_CART_ID_KEY,
      ) || undefined
    )
  }

  /**
   * Схранение айди гостевой корзины в локальное хранилище
   * @param {string} cartId – айди корзины
   */
  setId(cartId) {
    this.gaApp.services.app.browserStorage.local.set(
      STORAGE_GUEST_CART_ID_KEY,
      cartId,
    )
  }

  /**
   * Удаление айди гостевой корзины из локального хранилища
   */
  removeId() {
    this.gaApp.services.app.browserStorage.local.remove(
      STORAGE_GUEST_CART_ID_KEY,
    )
  }
}

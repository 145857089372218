import { analytics } from './analytics'
import * as components from './components'
import { locales } from './locales'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

// TODO: вынести подписку pdp в модуль subscription
export const subscription = {
  meta: {
    name: 'subscription',
    locales,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

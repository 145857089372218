/**
 * Утилита для сихронизации событий.
 * Используется для обеспечения того, чтобы ряд событий происходил в правильном порядке.
 *
 * @returns {Object} Объект с методом create для создания симафора.
 */
export const symaphoreOrdered = () => {
  /**
   * Создает симафор, который обрабатывает последовательность флагов.
   *
   * @param {Function} options.onSuccess — функция, вызываемая после применения всех флагов.
   * @param {Function} options.onFinish — функция, вызываемая после завершения симафора.
   * @param {Array} options.flags — массив применяемых флагов.
   * @returns {Function} Функция-обработчик симафора.
   */
  const create = ({ onSuccess, onFinish, flags }) => {
    const appliedFlags = new Set()

    let currentIndex = 0
    let isCompleted = false

    /**
     * Функция для обработки флага.
     *
     * @param {string} flag — применяемый флаг.
     */
    return function handleFlag(flag) {
      if (isCompleted) return false

      /**
       * Если текущий флаг соответствует применяемому флагу, добавляем его к примененным флагам
       * Иначе завершаем выполнение
       **/
      if (flags[currentIndex] === flag) {
        appliedFlags.add(flag)
        currentIndex++
      } else {
        return onFinish()
      }

      // Если все флаги были применены в правильном порядке, вызывается callback onSuccess
      if (appliedFlags.size === flags.length) {
        isCompleted = true
        onSuccess()
        return onFinish()
      }
    }
  }

  return {
    create,
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue'

import { isIOS } from '@ga/shared-browser'

import { GaAction } from '@ga/ui-components/action'
import { GaButton } from '@ga/ui-components/button'
import { GaDetails } from '@ga/ui-components/details'
import { GaField } from '@ga/ui-components/field'
import { GaLinkPhone } from '@ga/ui-components/link-phone'
import { GaNewInput } from '@ga/ui-components/new-input'

import GaAuthCountdown from '../countdown'
import GaAuthModalHeader from '../modal-header'

import { useValidate } from './scripts/composables'

// @vue/component
export default {
  name: 'ga-auth-modal-confirm-form',

  components: {
    GaAction,
    GaButton,
    GaDetails,
    GaField,
    GaNewInput,
    GaLinkPhone,

    GaAuthCountdown,
    GaAuthModalHeader,
  },

  props: {
    meta: {
      type: Object,
      default: () => {},
    },
    phone: {
      type: String,
      required: true,
    },
    phoneCode: {
      type: String,
      required: true,
    },
    phoneFormatted: {
      type: String,
      required: true,
    },
    error: {
      type: Object,
      default: null,
    },
    nextRequestDelay: {
      type: Number,
      default: 30000,
    },
    verificationCode: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const { $gaApp } = useContext()

    const codeInternal = ref('')
    const inputRef = ref(null)

    const { error } = toRefs(props)
    const { validate, getError, hasErrors, resetErrors } = useValidate(
      $gaApp,
      codeInternal,
      error,
    )

    const {
      isPhoneToken,
      helpPhone,
      codeMessage,
      countdownType,
      actionMessage,
    } = toRefs($gaApp.stores.auth.modal)

    const pending = computed(() => $gaApp.stores.auth.modal.pending)
    const disabledResend = ref(false)

    watch(codeInternal, () => {
      resetErrors()
      emit('error-clear')
    })

    onMounted(() => {
      resetErrors()

      setVerificationCode()

      focusCode()
    })

    onBeforeUnmount(() => {
      emitNextRequestDelay(0)
    })

    const onFormSubmit = async () => {
      const isValid = await validate()
      if (isValid) {
        const payload = await $gaApp.services.auth.modalConfirm.confirmCode(
          codeInternal.value,
          props.phone,
          props.meta,
        )

        if (payload) {
          emit('success', payload)
        }
      }
    }

    const onResendCodeClick = async () => {
      await $gaApp.services.auth.modalConfirm.resendCode(
        props.phone,
        props.phoneCode,
      )
      clear()
    }

    const onCountdownStart = () => {
      disabledResend.value = true
    }

    const onCountdownEnd = () => {
      emitNextRequestDelay(0)
      disabledResend.value = false
    }

    const emitNextRequestDelay = (ms = 0) => {
      emit('set-next-request-delay', ms)
    }

    const clear = () => {
      codeInternal.value = ''
      focusCode()
    }

    const setVerificationCode = () => {
      codeInternal.value = props.verificationCode
    }

    const focusCode = () => {
      if (isIOS()) {
        return
      }

      inputRef.value?.focus()
    }

    return {
      inputRef,
      codeInternal,

      getError,
      hasErrors,

      pending,
      disabledResend,

      isPhoneToken,
      helpPhone,
      codeMessage,
      countdownType,
      actionMessage,

      onFormSubmit,
      onResendCodeClick,
      onCountdownStart,
      onCountdownEnd,
    }
  },
}

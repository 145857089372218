import {
  ACTION_TYPES,
  ACTIONS,
  EVENT_CATEGORIES,
  SCREEN_TYPES,
} from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MenuModel extends GtmBaseModel {
  constructor(item) {
    if (!item) {
      throw new Error('Menu item is required')
    }

    super({ event: ACTIONS.MENU.GA_4 })

    this.event_category = EVENT_CATEGORIES.ACCOUNT
    this.action_type = ACTION_TYPES.MENU
    this.screen_type = SCREEN_TYPES.ACCOUNT

    this.path = `account/${item.name}`
  }
}

export const getters = (gaApp) => ({
  navExtended() {
    return this.expandedNavItemsIds.length > 0
  },

  navMobile() {
    return this.nav.filter((item) => !item.excludedInMobile)
  },

  navFull() {
    return this.nav.filter((item) => !item.mods.additional)
  },

  firstCategoryInCatalog() {
    return this.nav[0]?.children[0] || null
  },
})

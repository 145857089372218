import { SCREEN_TYPES } from '../analytics/constants'

export class PageScreenService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.subscribe()
  }

  subscribe() {
    this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      ({ moduleType, pageType }) => {
        this.gaApp.stores.location.main.pageScreenType = this.getPageScreenType(
          moduleType,
          pageType,
        )
      },
    )
  }

  getPageScreenType(moduleType, pageType) {
    switch (
      `${moduleType}/${pageType}` // moduleType и pageType сами по себе не уникальны
    ) {
      case 'plp/listing':
      case 'plp/search':
      case 'pdp/product':
        return SCREEN_TYPES.PRODUCT

      case 'home/main':
      default:
        return SCREEN_TYPES.HOME
    }
  }
}

import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const digitalAiDesignStore = () => {
  const init = defineStore('giftCardsDigitalAiDesign', {
    getters,
    state,
  })

  return init(getActivePinia())
}

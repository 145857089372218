import dayjs from 'dayjs'

/**
 * @typedef {Object} Time
 * @property {Number} hour - час (0 - 23)
 * @property {Number} minute - минута (0 - 59)
 */

/**
 * Возвращает строку
 * @param {Time} time - время
 * @param {String} [format] - формат вывода (https://day.js.org/docs/en/display/format)
 * @returns {String}
 */
const timeObjectToString = (time, format = 'HH:mm') => {
  const { hour, minute } = time

  return dayjs({ hour, minute }).format(format)
}

export { timeObjectToString }

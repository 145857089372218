const convertNavItems = (data) => {
  data.children = data.children.map(convertNavItems)

  return data
}

export const formatResponse = (response) => {
  response.data.data = response.data.data.map(convertNavItems)

  return response
}

import {
  ACTION_CATEGORIES,
  ACTION_TYPES,
} from '~/modules/subscription/analytics/constants'

import { SubscribeSentBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class SubscriptionAnalytics extends BaseController {
  /**
   *   Подписка на рассылку: Успешно подписался на рассылку
   *   Успешно подписался на рассылку
   */
  onSubscribeSent() {
    this.send(SubscribeSentBuilder, {
      eventCategory: ACTION_CATEGORIES.MAIN,
      actionType: ACTION_TYPES.FORM_SENT_SUBSCRIBE,
    })
  }
}

/**
 * Этот сервис (PageService) предназначен для получения
 * начальных данных страницы на основе типа страницы.
 */

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * основной метод для получения данных страницы. Он получает pageType из объекта
   * redirect и вызывает соответствующий метод для получения данных.
   */
  getData(redirect) {
    switch (redirect.pageType) {
      case 'product':
        return this.getProductInitialData(redirect)

      default:
        return undefined
    }
  }

  // Запрос данных из внутреннего сервиса аналитики
  async getProductInitialData(redirect) {
    // запрос отзывов для пдп
    this.gaApp.services.review.preview.getReviews({
      productId: redirect.entityId,
    })

    const [product] = await Promise.allSettled([
      this.gaApp.services.pdp.main.getProductBase(redirect), // Запрос продукта
      this.gaApp.services.pdp.main.getInitialAnalytics(redirect), // Запрос аналитики PDP
    ])

    // запрос бенефитов
    this.gaApp.services.pdp.main.getBenefits(redirect)
    // запрос геозависимых данных
    this.gaApp.services.pdp.main.getProductAdditional(redirect)
    // Запрос слотов для пдп
    this.gaApp.services.pdp.main.getProductSlots(product.value)

    return product
  }
}

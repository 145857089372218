import { load } from 'recaptcha-v3'

function TokenGenerationError(message) {
  const error = new Error('Ошибка')

  this.name = 'TokenGenerationError'
  this.message = message
  this.stack = error.stack
}

export class RecaptchaService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  _instance = null

  _siteKey = null

  _renderParameters = {}

  setKey(key) {
    this._siteKey = key

    return this
  }

  getKey() {
    return this._siteKey
  }

  setRenderParameters(params) {
    this._renderParameters = params

    return this
  }

  getRenderParameters() {
    return this._renderParameters
  }

  load() {
    return this._load()
  }

  /*
   * При вызове метода `_load`, если инстанс капчи уже существует, то он будет реиспользован.
   * Данная логика реализована внутри метода `load` библиотеки recaptcha-v3.
   */
  execute() {
    return this._load().then(() => this._execute())
  }

  reset() {
    return this._reset()
  }

  generateToken() {
    return this.execute()
      .then(this._handleGenerateTokenSuccess)
      .catch(this._handleGenerateTokenError)
  }

  isTokenGenerationError(error) {
    return error instanceof TokenGenerationError
  }

  _execute() {
    return this._instance.recaptcha.execute(this._instance.recaptchaID)
  }

  _reset() {
    return this._instance.recaptcha.reset(this._instance.recaptchaID)
  }

  _load() {
    return load(this.getKey(), {
      explicitRenderParameters: this.getRenderParameters(),
    }).then((instance) => {
      this._instance = instance

      return instance
    })
  }

  _handleGenerateTokenSuccess(token) {
    return token
  }

  _handleGenerateTokenError() {
    const message =
      'Извините, произошла ошибка. Пожалуйста, попробуйте перезагрузить страницу.'
    const error = new TokenGenerationError(message)

    return Promise.reject(error)
  }
}

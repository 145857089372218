import { DIALOG } from '../../constants'

export const getters = (gaApp) => ({
  isOrderCancelConfirmationDialog() {
    return this.typeDialog === DIALOG.CONFIRMATION
  },

  isOrderCancelledDialog() {
    return this.typeDialog === DIALOG.CANCELLED
  },

  isOrderCompletedDialog() {
    return this.typeDialog === DIALOG.COMPLETED
  },

  isOrderDeliveringDialog() {
    return this.typeDialog === DIALOG.DELIVERING
  },

  isOrderCancelDefaultDialog() {
    return this.typeDialog === DIALOG.DEFAULT
  },

  isSwipeDialog() {
    return gaApp.mq('tablet-')
  },
})

import { TYPE } from '../../constants'
import {
  GaAnalyticsShareCartClickModel,
  GtmGa4ShareCartProductClickModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class ShareCartProductClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GAA, GaAnalyticsShareCartClickModel],
      [TYPE.GTM_4, GtmGa4ShareCartProductClickModel],
    ])

    super(models, data)
  }
}

export const LINK = {
  TEXT: 'text',
  HREF: 'href',
}

export const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
}

import { render, staticRenderFns } from "./cashback-label-checked.vue?vue&type=template&id=4c909522"
import script from "./cashback-label-checked.vue?vue&type=script&lang=js"
export * from "./cashback-label-checked.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <svg viewBox="0 0 16 16">
    <path fill="none" stroke-width="1.25" d="M.625.625h14.75v14.75H.625z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-plp-layout-regular',
}
</script>

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useImageBreakpoints = () => {
  const { $gaApp } = useContext()

  // Получаем брейкпоинты из стора
  const allBreakpoints = computed(() => $gaApp.stores.app.window.breakpoints)

  // Формируем диапазоны ширин для каждого экрана
  const breakpoints = computed(() => {
    const { fullhd, desktop, tablet } = allBreakpoints.value

    const ranges = {
      fullhd: {
        from: fullhd.width,
        till: null,
      },
      desktop: {
        from: desktop.width,
        till: fullhd.width,
      },
      tablet: {
        from: tablet.width,
        till: desktop.width,
      },
      mobile: {
        from: null,
        till: tablet.width,
      },
    }

    return {
      values: ranges,
      keys: Object.keys(ranges),
    }
  })

  return {
    breakpoints,
  }
}

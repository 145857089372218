import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

/**
 * @typedef {Object} ProductForApplyContext
 * @property {String} itemId - id продукта (из api)
 * @property {Boolean} url - url продукта (из api)
 */

/**
 * @param {Ref<ProductForApplyContext>} product - продукт из пропс - ссылка на весь продукт для computed полей
 * @param {Ref<Boolean>} showAddToCart - из props
 * @param {Ref<Boolean>} showAddToFavorites - из props
 */
export const useApplyContext = (product, showAddToCart, showAddToFavorites) => {
  const { $gaApp } = useContext()

  const isWebview = computed(() => $gaApp.isWebview)

  const contextShowAddToCart = computed(
    () => unref(showAddToCart) && !unref(isWebview),
  )

  const contextShowAddToFavorites = computed(
    () => unref(showAddToFavorites) && !unref(isWebview),
  )

  const contextNuxtProductLink = computed(() => !unref(isWebview))

  return {
    contextShowAddToCart,
    contextShowAddToFavorites,
    contextNuxtProductLink,
  }
}

<template>
  <svg viewBox="0 0 21 20">
    <rect width="20" height="20" x=".5" stroke="none" rx="5.233" />
    <path
      fill="#000"
      stroke="none"
      d="M13.636 16.034a.8.8 0 0 1-.484-.157l-3.375-2.462c-.154-.113-.301-.235-.46-.342-.257-.171-.32-.488-.244-.76.083-.291.257-.515.458-.723.919-.96 1.909-1.843 2.812-2.824.128-.138.248-.283.374-.42a.45.45 0 0 0 .101-.267c.01-.084-.03-.126-.108-.123a.582.582 0 0 0-.243.084 4.701 4.701 0 0 0-.568.373c-1.082.803-2.145 1.637-3.25 2.404-.329.227-.662.443-.998.659a1.685 1.685 0 0 1-.892.275c-.34.01-.665-.075-.99-.17a7.725 7.725 0 0 1-.68-.227c-.333-.132-.667-.262-.983-.437a.967.967 0 0 1-.307-.254.238.238 0 0 1-.024-.239c.06-.152.193-.213.315-.282a29.43 29.43 0 0 1 1.89-.981 147.09 147.09 0 0 1 3.272-1.516 194.403 194.403 0 0 1 5.291-2.29c.218-.092.445-.143.678-.155.19-.01.381.008.53.155a.534.534 0 0 1 .16.439c-.01.18-.025.36-.037.54-.03.39-.08.775-.132 1.162-.097.72-.193 1.441-.298 2.16-.14.948-.285 1.892-.433 2.84-.136.879-.28 1.759-.416 2.639-.079.523-.473.897-.959.899Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-social-tg-square',
}
</script>

/**
 * Нормализует объект времени (24ч 60мин)
 * @param {Time}
 * @return {Time}
 */
export const normalizeTimeObject = (time) => {
  const { hour = 0, minute = 0 } = time

  return {
    hour: Number(hour) < 24 ? hour : 23,
    minute: Number(minute) < 60 ? minute : 59,
  }
}

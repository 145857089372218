<template>
  <svg viewBox="0 0 28 28">
    <path
      stroke="none"
      d="M21.705 5.713c-3.465-.238-11.184-.237-14.644 0-3.747.257-4.189 2.53-4.217 8.51.028 5.97.466 8.253 4.217 8.51 3.461.237 11.179.238 14.644 0 3.747-.256 4.189-2.529 4.216-8.51-.027-5.97-.465-8.252-4.216-8.51Zm-10.207 12.46v-7.899l7.212 3.942-7.212 3.956Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-social-yt',
}
</script>

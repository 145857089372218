export const geStoriesStateInitial = () => ({
  modalOpened: false,
  muted: true,
  products: {
    opened: false,
    items: [],
  },
  disclaimer: {
    opened: false,
  },
  viewed: {}, // объект с просмотренными стори
  items: [], // массив сторисов
  activeSlideIndex: 0,
  changing: false,
  dragging: false,
  longpressed: false,
  waiting: false,

  storyViewHistory: {},

  viewSkipInfo: {
    state: '',
    pagesViewed: 0,
    skipPages: 0,
    firstStories: true,
  },
})

export default () => {
  return geStoriesStateInitial()
}

export const TYPE = {
  DIGI: 'digi_analytics',
  GAA: 'ga_analytics',
  GTM_4: 'gtm_4',
  GTM_UA: 'gtm_ua',
  GTM_UA_SYSTEM: 'gtm_ua_system',
  MINDBOX: 'mindbox',
}

export const EVENTS = {
  // GTM
  USER_INTERACTION: 'user_interaction',
  // DIGI
  AUTOCOMPLETE_CLICK: 'AUTOCOMPLETE_CLICK',
  AQ_SYNONYM_CLICK: 'AQ_SYNONYM_CLICK',
}

export const EVENT_CATEGORIES = {
  SEARCH: 'search',
}

export const HINT_TYPES = {
  BRANDS: 'brands',
  CATEGORY: 'category',
  HISTORY: 'history',
  POPULAR: 'popular',
}

export const ACTION_TYPES = {
  AUTOCOMPLETE: 'autocomplete',
  CLICK_SEARCH_HINTS: 'click_search_hints',
  CLICK_SEARCH_QUERY: 'click_search_query',
  OPEN_SEARCH: 'open_search',
}

export const REFERER_TYPES = {
  SEARCH_RESULT_HISTORY: 'search_result_history',
  SEARCH_RESULT_HINTS: 'search_result_hints',
  SEARCH_BRAND: 'search_brand',
  SEARCH_CATEGORY: 'search_category',
  SEARCH_HINTS: 'search_hints',
}

export const AUTOCOMPLETE_ITEM_TYPES = {
  BRANDS: 'brands',
  HISTORY: 'history',
  PRODUCTS: 'products',
  QUERIES: 'queries',
  CATEGORIES: 'categories',
}

export const SOURCE = 'plaid_backend'

export const ACTIONS = {
  ADD_TO_CART: {
    GA_UA: 'addToCart',
    GA_4: 'add_to_cart',
    MINDBOX: {
      ru: 'Website.AddProductToCartItemList.JS',
      by: 'Website.AddProductToCartItemList.JS',
      kz: 'Website.AddProductToCartItemList.JS',
      qa: 'WebsiteQA.AddProductToCartItemList.JS',
    },
  },
  REMOVE_FROM_CART: {
    GA_UA: 'Remove from Cart',
    GA_4: 'remove_from_cart',
    MINDBOX: {
      ru: 'Website.RemoveProductFromCartItemList.JS',
      by: 'Website.RemoveProductFromCartItemList.JS',
      kz: 'Website.RemoveProductFromCartItemList.JS',
      qa: 'WebsiteQA.RemoveProductFromCartItemList.JS',
    },
  },
  FAVORITE: {
    MINDBOX: {
      ru: 'Website.AddProductToFavoritesItemList.JS',
      by: 'Website.AddProductToFavoritesItemList.JS',
      kz: 'Website.AddProductToFavoritesItemList.JS',
      qa: 'WebsiteQA.AddProductToFavoritesItemList.JS',
    },
  },
}

export const CHUNK_SIZE_ANALYTICS = 2
export const PLP_TYPE = {
  S: 's',
}

import { buildBanners, findExpandedItem } from '../utils/nav'

export class NavService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get state() {
    return this.gaApp.stores.header.nav
  }

  // mutations
  expandNavItem(item) {
    if (!this.state.renderedNavItemsIds.includes(item.id))
      this.state.renderedNavItemsIds.push(item.id)

    if (this.state.expandedNavItemsIds.length === 0) {
      this.state.expandedNavItemsIds.push(item.id)
      return
    }

    this.state.expandedNavItemsIds = [
      ...this.state.expandedNavItemsIds.slice(0, item.level),
      item.id,
    ]
  }

  closeNavItems() {
    this.state.expandedNavItemsIds = []
  }

  // getters
  getIsItemRendered(item) {
    return (
      this.state.renderedNavItemsIds.includes(item.id) ||
      this.gaApp.stores.user.main.isBot
    )
  }

  getIsItemExpanded(item) {
    return this.state.expandedNavItemsIds.includes(item.id)
  }

  getIsItemParental(item) {
    return (
      this.state.expandedNavItemsIds.indexOf(item.id) <
      this.state.expandedNavItemsIds.length - 1
    )
  }

  getExpandedNavItems(items) {
    const expandedItems = []
    const getIsItemExpanded = this.getIsItemExpanded.bind(this)
    let expandedItem = findExpandedItem(items, getIsItemExpanded)
    while (expandedItem) {
      expandedItems.push(expandedItem)
      expandedItem = findExpandedItem(expandedItem.children, getIsItemExpanded)
    }
    return expandedItems
  }

  /**
   * Нужно для того, что бы отправить путь до пункта меню,
   * по которому был совершен клик в сервисы аналитики
   *
   * @param {array} items пункты меню
   * @param {object} lastItem последний элемент, по которому был совершен клик
   * @returns {string} строка-путь до пункта меню, по которому был совершен клик
   */
  getExpandedItemsPath(items, lastItem) {
    // Если кликнули по вложенному пункту меню
    if (lastItem.level > 0) {
      const result = this.getExpandedNavItems(items)

      if (!lastItem.parent) {
        result.push(lastItem)
      }

      return result.map((item) => item.name).join('/')
    }

    // Если кликнули по корневому пункту меню
    return lastItem.name
  }

  getBannersNavFull() {
    const path = this.getExpandedNavItems(this.state.navFull)

    const itemWithBanners = path
      .slice(path.length > 1 ? 1 : 0) // чтобы случайно не взять баннеры корневой категории
      .reverse()
      .find((item) => item.banners?.length > 0)

    return buildBanners(itemWithBanners)
  }

  getBannersTablet() {
    const [mainItem] = this.getExpandedNavItems(this.state.navMobile)

    return buildBanners(mainItem)
  }

  getUrlFirstCategory() {
    return this.state.firstCategoryInCatalog?.link || ''
  }
}

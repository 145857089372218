import { TYPE } from '../../constants'
import {
  AdvcakeCartModel,
  GdeSlonCartModel,
  GtmGa4CartModel,
  GtmGaUaCartModel,
  MindboxCartModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class CartBuilder extends BaseBuilder {
  constructor(product, country) {
    const models = new Map([
      [TYPE.GTM_UA, GtmGaUaCartModel],
      [TYPE.GTM_4, GtmGa4CartModel],
      [TYPE.MINDBOX, MindboxCartModel],
      [TYPE.ADVCAKE, AdvcakeCartModel],
    ])

    if (product.addToCart) {
      models.set(TYPE.GDESLON, GdeSlonCartModel)
    }

    super(models, product, country)
  }
}

import { log } from './ga-analytics.utils.js'

export class DigiAnalytics {
  constructor() {
    this.api = null

    this.isInit = false
  }

  create(api, endpoints) {
    try {
      if (this.isInit) {
        log('Модуль уже проинициализирован', api)

        return false
      }

      this.api = api
      this.endpoints = endpoints

      this.isInit = true

      log('Инициализация прошла успешно', api)
    } catch (error) {
      log('Ошибка инициализации', error)

      throw error
    }
  }

  sendAnalytics(data) {
    if (this.isInit) {
      const { url } = this.endpoints.base()
      return this.api.request().post(url, data)
    }
  }
}

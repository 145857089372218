/*
 * Oggetto
 *
 * Выносит DOM компонентов в ga-app-portal-target.
 * При переносе, компоненты теряют наследование html атрибутов (props работают как обычно).
 *
 * Документация portal-vue: https://portal-vue.linusb.org/
 */

import { Portal } from 'portal-vue'

import { GaPortalTarget } from '../portal-target'

// @vue/component
export default {
  name: 'ga-portal',

  components: {
    Portal,
  },

  props: {
    to: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    toInternal() {
      return this.to || GaPortalTarget.name
    },

    disabledInternal() {
      return this.disabled
    },
  },
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4MenuItemClickModel extends GtmBaseModel {
  constructor({ path }) {
    super({})

    this.action_type = 'click_menu_item'
    this.path = path
  }
}

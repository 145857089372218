import { MODAL_NAME } from '../../constants'

export const getters = (gaApp) => ({
  hasModals() {
    return this.queueModals.length > 0
  },

  lastOpenedModalId() {
    return this.hasModals ? this.queueModals.at(-1) : null
  },

  lastOpenedModal() {
    return this.stateModals?.[this.lastOpenedModalId]
  },

  hasManyOpenedLastModal() {
    return this.lastOpenedModal?.modals?.length > 1
  },

  modals() {
    return this.queueModals.map((id) => {
      const parentModal = this.stateModals[id]
      const modals = parentModal.modals
      const opened = modals?.length > 0
      const hasManyComponents = modals?.length > 1

      const { data, name, component } = opened ? modals.at(-1) : {}

      const componentData = {
        data,
        name,
        component,
      }

      return {
        ...parentModal,
        componentData,
        opened,
        hasManyComponents,
      }
    })
  },

  modalNames() {
    return this.modals.flatMap(({ modals }) => modals.map(({ name }) => name))
  },

  hasOpenedModals() {
    return this.modalNames.length > 0
  },

  hasOpenedAdultModal() {
    return this.modalNames.includes(MODAL_NAME.ADULT)
  },

  hasOpenedCartModal() {
    return this.modalNames.includes(MODAL_NAME.CART)
  },

  hasOpenedAuthModal() {
    return this.modalNames.includes(MODAL_NAME.AUTH)
  },
})

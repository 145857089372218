import { MODULE_TYPE } from '@ga/constants/redirect'

import { GaAnalyticsBaseModel } from '../ga-analytics-base-model/ga-analytics-base-model'

export class GaAnalyticsContentElementLinkClickModel extends GaAnalyticsBaseModel {
  constructor({ data, redirect }) {
    const { moduleType, entityId } = redirect

    const type = {
      [MODULE_TYPE.plp]: 'listing',
      [MODULE_TYPE.pdp]: 'product',
    }[moduleType]

    const key = {
      [MODULE_TYPE.plp]: 'category',
      [MODULE_TYPE.pdp]: 'item',
    }[moduleType]

    super(type)

    const {
      itemListId,
      itemListName,
      promotionName,
      creativeSlot,
      creativeName,
    } = data

    this[key] = {
      ...(data.url ? { url: data.url } : {}),
      ...(entityId ? { itemId: entityId } : {}),

      ...(itemListId ? { itemListId } : {}),
      ...(itemListName ? { itemListName } : {}),
      ...(promotionName ? { promotionName } : {}),
      ...(creativeSlot ? { creativeSlot } : {}),
      ...(creativeName ? { creativeName } : {}),
    }

    this.debug = {
      key: 'brandzone-content-element-link-click',
      data,
    }
  }
}

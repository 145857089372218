import { isIOS, isSafari } from '@ga/shared-browser'

export class ShareService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getHash() {
    return this.gaApp.services.cart.api.getSharingShare(
      this.gaApp.stores.cart.share.cartProductsCompact,
    )
  }

  getSharingList(hash) {
    const { id: cityId } =
      this.gaApp.services.location.main.getDeliveryAddress()

    return this.gaApp.services.cart.api.getSharingList({
      cityId,
      hash,
    })
  }

  getProductsForCart() {
    return this.gaApp.stores.cart.share.validProducts.map((product) => {
      const { itemId, quantity } = product

      return {
        quantity,
        sku: itemId,
        analyticsDetails: {
          itemListName: 'share_cart',
        },
      }
    })
  }

  buildSharingUrl(hash) {
    return `${window.location.origin}/sharing/basket/${hash}`
  }

  async getTextForBuffer() {
    const hash = await this.getHash()

    if (!hash) {
      return ''
    }

    const link = this.buildSharingUrl(hash)

    return this.gaApp.i18n.t('cart.share.copyText', { link })
  }

  async copyLinkBase() {
    const text = await this.getTextForBuffer()

    if (!text) {
      return
    }

    this.gaApp.services.cart.notification.showShortNotification(
      this.gaApp.i18n.t('cart.share.copyLink'),
    )

    return this.gaApp.services.app.clipboard.writeText(text)
  }

  copyLinkMacOs() {
    const clipboardItem = new ClipboardItem({
      'text/plain': this.getTextForBuffer().then((text) => {
        if (text) {
          this.gaApp.services.cart.notification.showShortNotification(
            this.gaApp.i18n.t('cart.share.copyLink'),
          )
        }

        return new Promise((resolve) => {
          resolve(new Blob([text], { type: 'text/plain' }))
        })
      }),
    })

    return this.gaApp.services.app.clipboard.write([clipboardItem])
  }

  async copyLink() {
    try {
      if (isIOS() || isSafari()) {
        await this.copyLinkMacOs()
      } else {
        await this.copyLinkBase()
      }
    } catch (error) {
      this.gaApp.services.cart.notification.showShortNotification(
        this.gaApp.i18n.t('cart.error.somethingWrong'),
      )
    }
  }

  share() {
    this.gaApp.analytics.modules.cart.onShareCartClick({
      count: this.gaApp.stores.cart.share.cartProductsCompact.length,
      totals: this.gaApp.stores.cart.main.totals,
    })

    if (!this.gaApp.services.cart.user.isAuthorized) {
      this.gaApp.services.cart.dialog.openAuthToCart()

      return
    }

    this.copyLink()
  }

  async loadSharingList(hash) {
    this.gaApp.stores.cart.share.products = await this.getSharingList(hash)
  }

  async addToCart() {
    this.gaApp.stores.cart.share.loadingAddToCart = true

    this.gaApp.analytics.modules.cart.onShareAddToCart({
      products: this.gaApp.stores.cart.share.validProducts,
    })

    try {
      await this.gaApp.services.cart.api.addItems(this.getProductsForCart())

      this.close()
      this.gaApp.services.modal.main.openSuperModalCart()

      this.showAddToCartNotification()
    } catch (error) {}

    this.gaApp.stores.cart.share.loadingAddToCart = false
  }

  addToCartWithValidation() {
    if (!this.gaApp.stores.cart.share.needAdultConfirm) {
      this.addToCart()
      return
    }

    this.gaApp.services.modal.main.openSuperModalAdult({
      callbacks: {
        confirm: () => {
          this.addToCart()
        },
        cancel: () => {
          this.addToCart()
        },
      },
    })
  }

  getAddToCartNotification() {
    const hasOutOfStockProducts =
      this.gaApp.stores.cart.share.hasOutOfStockProducts
    const needAdultConfirm = this.gaApp.stores.cart.share.needAdultConfirm

    if (hasOutOfStockProducts && needAdultConfirm) {
      return this.gaApp.i18n.t(
        'cart.share.addToCartNotification.outOfStockAndAdult',
      )
    }

    if (hasOutOfStockProducts) {
      return this.gaApp.i18n.t('cart.share.addToCartNotification.outOfStock')
    }

    if (needAdultConfirm) {
      return this.gaApp.i18n.t('cart.share.addToCartNotification.adult')
    }

    return ''
  }

  showAddToCartNotification() {
    const message = this.getAddToCartNotification()

    if (message) {
      this.gaApp.services.cart.notification.showShortNotification(message)
    }
  }

  async open(hash) {
    this.gaApp.stores.cart.share.isOpen = true

    await this.loadSharingList(hash)

    this.gaApp.analytics.modules.cart.onShareCartOpen({
      products: this.gaApp.stores.cart.share.products,
    })
  }

  close() {
    this.gaApp.stores.cart.share.isOpen = false
  }
}

export const getMappedAttributes = (attributes) => {
  return {
    colors: {
      values: attributes.colors?.values || [],
      count: attributes.colors?.count || 0,
    },
    units: {
      name: attributes.units?.name || '',
      values: attributes.units?.values || [],
      count: attributes.units?.count || 0,
      currentUnitValue: attributes.units?.currentUnitValue || '',
    },
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductCartWishModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const { list, id, plpType, searchTerm, price, itemListId } = product

    super({ event: 'add_to_wishlist' })

    this.item_list_name = list
    this.item_list_id = itemListId || id
    this.plp_type = plpType
    this.search_term = searchTerm
    this.value = price
    this.ecommerce = {
      items: [new GtmGa4ProductBaseModel(product)],
    }
  }
}

// TODO: Порефачить аналитику модуля (заказы, фильтры)
// TODO: Проверить, все ли события отправляются,
// TODO: а также переписать аналитику фильтров на аналогичную модулям избранного и купленных продуктов
import { useContext } from '@nuxtjs/composition-api'

import { CHUNK_SIZE_ANALYTICS } from '../../../analytics/constants'
import { CATEGORY } from '../../../constants'

export const useAnalytics = () => {
  const { $gaApp } = useContext()

  // При входе на страницу
  const onMounted = () => {
    $gaApp.analytics.modules.customer.onMounted({ id: CATEGORY })
  }

  // При клике на пункт меню
  const onClickMenuItem = (item) => {
    $gaApp.analytics.modules.customer.onMenuItemClick(item)
  }

  // При клике на инфо о пользователе
  const onClickUserInfo = () => {
    $gaApp.analytics.modules.customer.onUserInfoClick()
  }

  // Пользователь тапает на избранное в Таббаре внизу экрана, или переходит из ЛК
  const onOpenWishlist = (from) => {
    $gaApp.analytics.modules.customer.onOpenWishlist(from)
  }

  // Клик на кнопку "Повторить заказ" в детальной информации по заказу
  const onClickRepeatOrder = () => {
    $gaApp.analytics.modules.customer.onClickRepeatOrder()
  }

  // Клик на кнопку "Оценить покупку" в детальной информации по заказу
  const onClickRateOrder = () => {
    $gaApp.analytics.modules.customer.onClickRateOrder()
  }

  // Клик на кнопку "Отменить заказ" в детальной информации по заказу
  const onClickCancelOrder = () => {
    $gaApp.analytics.modules.customer.onClickCancelOrder()
  }

  // Клик на кнопку "Подробнее о пункте выдачи"
  const onClickAboutPickUpPoint = () => {
    $gaApp.analytics.modules.customer.onClickAboutPickUpPoint()
  }

  // Клик на кнопку "Перейти к покупкам" (если нет заказов)
  const onClickBackToShopping = () => {
    $gaApp.analytics.modules.customer.onClickBackToShopping()
  }

  // Клик на кнопку "Оформить возврат" в детальной информации по заказу
  const onClickReturnOrder = () => {
    $gaApp.analytics.modules.customer.onClickReturnOrder()
  }

  // Клик на кнопку "Привезти сейчас" в листинге
  const onClickOnDemandOrder = () => {
    $gaApp.analytics.modules.customer.onClickOnDemandOrder()
  }

  // Клик на кнопку "Привезти сейчас" в детализированном закаке
  const onClickOnDemandOrderDetails = () => {
    $gaApp.analytics.modules.customer.onClickOnDemandOrderDetails()
  }

  // При просмотре товара в детальной информации по заказу
  const onOrderProductView = (product) => {
    $gaApp.analytics.modules.customer.onOrderProductView(product)
  }

  // Клик по товару в заказе
  const onOrderProductClick = (item) => {
    $gaApp.analytics.modules.customer.onOrderProductClick(item)
  }

  // При уходе со страницы детализированного заказа
  const onOrderPageLeave = () => {
    $gaApp.analytics.modules.customer.onOrderPageLeaveProductView()
  }

  const productsAnalytic = ({ type }) => {
    const getAnalyticData = ({ name = '', placement }) => {
      return {
        path: `${type}/block: ${name}`,
        chunkSize: CHUNK_SIZE_ANALYTICS,
        plpType: 's',
        // TODO: уточнить, какие itemListId отправлять по всем спискам продуктов в ЛК
        itemListId: `${type}/block_1`,
        digiAnalytics: true,
        placement,
      }
    }
    const getCartAnalyticDetails = ({ productIndex, name }) => {
      const { path, itemListId } = getAnalyticData({ name })

      return {
        analyticsDetails: {
          indexTotal: productIndex + 1,
          itemListName: path,
          itemListId,
        },
      }
    }

    return { getAnalyticData, getCartAnalyticDetails }
  }

  // Клик на область сгорающих бонусов на карте лояльности
  const onExpireBonusesClick = () => {
    $gaApp.analytics.modules.customer.onExpireBonusesClick()
  }

  // Клик по иконке инфо на карте лояльности
  const onLoyaltyInfoClick = () => {
    $gaApp.analytics.modules.customer.onLoyaltyInfoClick()
  }

  // При входе в раздел с картой лояльности
  const onExpireBonusesView = (hasBonuses) => {
    $gaApp.analytics.modules.customer.onExpireBonusesView(hasBonuses)
  }

  // При переключении тогглов настроек уведомлений
  const onSettingsToggleClick = ({ text, checked }) => {
    const status = Number(checked)

    $gaApp.analytics.modules.customer.onSettingsToggleClick({ text, status })
  }

  return {
    onClickMenuItem,
    onClickUserInfo,
    onMounted,
    onOpenWishlist,
    onClickRepeatOrder,
    onClickRateOrder,
    onClickCancelOrder,
    onClickAboutPickUpPoint,
    onClickBackToShopping,
    onClickReturnOrder,
    onOrderProductClick,
    onOrderProductView,
    onOrderPageLeave,
    onClickOnDemandOrder,
    onClickOnDemandOrderDetails,
    productsAnalytic,
    onExpireBonusesClick,
    onLoyaltyInfoClick,
    onExpireBonusesView,
    onSettingsToggleClick,
  }
}

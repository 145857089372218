<template>
  <svg fill="currentColor" stroke="none" viewBox="0 0 28 28">
    <path
      fill="currentColor"
      d="m15 0 3.968 9.54 10.298.825-7.846 6.72 2.397 10.05L15 21.75l-8.817 5.385 2.397-10.05-7.846-6.72 10.298-.826L15 0Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-review-star',
}
</script>

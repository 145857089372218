import { TYPE } from '../../constants'
import {
  AdvcakeCartModel,
  GtmGa4CartModel,
  GtmGaUaCartModel,
  MindboxProductItemsModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class CartBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([
      [TYPE.GTM_4, GtmGa4CartModel],
      [TYPE.GTM_UA, GtmGaUaCartModel],
      [TYPE.MINDBOX, MindboxProductItemsModel],
      [TYPE.ADVCAKE, AdvcakeCartModel],
    ])

    super(models, data, country)
  }
}

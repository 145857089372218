import { defineStore, getActivePinia } from 'pinia'

import state from './state'

export const contactsPageStore = (gaApp) => {
  const init = defineStore('infoContactsPage', {
    state,
  })

  return init(getActivePinia())
}

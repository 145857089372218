import { computed, ref, unref, watch } from 'vue'
import { IMask } from 'vue-imask' // import той версии, которую использует vue-imask

/**
 * Возвращает текст плейсхолдера маски
 * @param {Computed<String>} value - ссылка на внутреннее computed значение инпута
 * @param {Ref<(String|Object)>} mask - ссылка на значение mask
 * @param {String} placeholderChar - символ для плейсхолдера маски
 */

const usePhantom = (value, mask, placeholderChar = '_') => {
  const imaskPhantom = ref(null)

  watch(
    mask,
    (newMask) => {
      if (newMask) {
        if (!unref(imaskPhantom)) {
          imaskPhantom.value = IMask.createMask({
            mask: unref(mask),
            placeholderChar,
            lazy: false,
          })
        } else {
          unref(imaskPhantom).updateOptions({
            mask: unref(mask),
          })
        }
      } else if (unref(imaskPhantom)) {
        imaskPhantom.value = null
      }
    },
    { immediate: true },
  )

  const maskPhantom = computed(() => {
    return unref(imaskPhantom)?.resolve(unref(value))
  })

  return {
    maskPhantom,
  }
}

export { usePhantom }

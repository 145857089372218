import { nextTick } from 'vue'

import { STATE } from '../../const'

/**
 * Возвращает обработчики transition хуков
 * @param {Ref<import("../../const").State} state - текущее состояние модалки
 * @param {Function} emit - emit функция контекста
 */
export const useTransitionHooks = (state, emit) => {
  const startOpen = () => {
    // ожидаем rendered внутреннего содержимого
    nextTick(() => {
      emit('start-open')
    })
  }

  const startClose = () => {
    emit('start-close')
  }

  const finishOpen = () => {
    state.value = STATE.OPENED

    emit('finish-open')
  }

  const finishClose = () => {
    state.value = STATE.CLOSED

    emit('finish-close')
  }

  const cancelLeave = () => {
    emit('leave-cancelled')
  }

  const transitionHooks = {
    enter: startOpen,
    leave: startClose,
    afterEnter: finishOpen,
    afterLeave: finishClose,
    leaveCancelled: cancelLeave,
  }

  return {
    transitionHooks,
  }
}

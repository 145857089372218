import objectHash from 'object-hash'

import { getCategoryDataStateInitial } from './state'

export const actions = () => ({
  setCategoryData(value) {
    this.categoryData = value
  },

  setPage(value) {
    this.pages.push(value)
    this.pages.sort((a, b) => a.number - b.number)
  },

  setPageData(value) {
    this.pageData = value
  },

  clearPages() {
    this.pages = []
  },

  clearCategory() {
    this.categoryData = getCategoryDataStateInitial()
  },

  setListingTempData(value) {
    this.listingTempData = value
  },

  clearListingTempData() {
    this.listingTempData = null
  },

  setProductsCount(value) {
    this.productsCount = value
  },

  setProductsHash(value) {
    this.productsHash = objectHash(value, { unorderedArrays: true })
  },

  setIsSimpleLayout(value) {
    this.isSimpleLayout = value
  },

  setPagination({ direction, value }) {
    this.pagination[direction] = {
      ...this.pagination[direction],
      ...value,
    }
  },

  setScrollAnchor(value) {
    this.scrollAnchor = value
  },

  setLayoutAnimating(value) {
    this.isLayoutAnimating = value
  },

  setAnalyticsData(value) {
    this.analyticsData = value
  },

  setSearchQueryError(value) {
    this.searchQueryError = value
  },

  setSearchPlacements(value) {
    this.searchPlacements = value
  },

  setSearchQueryCorrected(value) {
    this.searchQueryCorrected = value
  },

  setSearchResultId(value) {
    this.searchResultId = value.id
  },

  setBrandzoneSlots(value) {
    this.brandzoneSlots = value
  },
})

import { config } from '../mocks'

export class PlasticRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getConfig() {
    const { url } = this.endpoints.getPlasticConfig()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async order(data) {
    const { url } = this.endpoints.plasticOrder()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получение конфигурации для КППК - новый флоу
   * @param {string} cityId идентификатор города
   *
   * TODO: заменить моки
   */
  async getNewConfig(cityId) {
    try {
      const response = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(config)
        }, 1000)
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}

import { analytics } from './analytics'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'

export { buildCorporativeLink } from './helpers'

export const giftCardsB2b = {
  meta: {
    name: 'giftCardsB2b',
    locales,
    routes,
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

<template>
  <svg fill="none" viewBox="0 0 52 14">
    <path d="M1 7h51" />
    <path d="M7 13 1 7l6-6" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-arrow-long-left',
}
</script>

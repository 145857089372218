<template>
  <svg viewBox="0 0 29 15">
    <path fill="none" d="M10 2 4.5 7.5 10 13" />
    <path stroke="none" d="M5 7h20v1H5z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-back-arrow-long',
}
</script>

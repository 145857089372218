/**
 * @typedef {string} State
 */

/**
 * @enum {State}
 */
export const STATE = {
  NOW: 'now',
  PAST: 'past',
  FUTURE: 'future',
}

export const RAW_STATE = {
  FUTURE: 0,
  NOW: 1,
  PAST: 2,
}

export const STREAM_LIVE_BASE_URL = 'https://live.goldapple.ru/stream'
export const STREAM_POSTER_BASE_URL =
  'https://images.streamerce.live/images/resized/stream-posters/-x600'

export const STREAM_STATUS_UPDATE_TIME = 30000

export const STREAMS_LIMIT = 6

export const PAGINATION_BUTTON = {
  NEXT: 'next',
  PREV: 'prev',
}

export const FLACON_STREAM_URL = '/flacon/live'

export const PAGE_TYPE = {
  main: 'main',
  mainOld: 'main-old',
}

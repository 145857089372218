import { analytics } from './analytics'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export const featuresToggle = {
  meta: {
    name: 'featuresToggle',
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}

import { Storage } from './ga-analytics.storage.class.js'
import { log, promisify } from './ga-analytics.utils.js'

// docs: https://lodash.com/docs/#template
const storageOptions = '{"maxSize":30,"freePercent":0.5,"storageName":"ga-app-analytics"}'

export class GaAnalytics {
  constructor() {
    this.api = null

    this.isInit = false

    this.storage = null
  }

  create(api, endpoints, storage) {
    try {
      if (this.isInit) {
        log('Модуль уже проинициализирован', { api, endpoints })

        return false
      }

      this.api = api
      this.endpoints = endpoints

      if (storage) {
        this.storage = new Storage(storage, JSON.parse(storageOptions))
      }

      this.isInit = true

      log('Инициализация прошла успешно', { api, endpoints })
    } catch (error) {
      log('Ошибка инициализации', error)

      throw error
    }
  }

  getFromStorage(key) {
    if (!this.storage) {
      return {
        data: null,
      }
    }

    return this.storage.get(key) || { data: null }
  }

  setToStorage(key, value) {
    if (!this.storage) {
      return false
    }

    return this.storage.set(key, value)
  }

  get product() {
    return {
      get: (params) => {
        const { url } = this.endpoints.getProduct()

        const { data } = this.getFromStorage(params.itemId)

        return data ? promisify(data) : this.api.request().post(url, params)
      },

      set: (data) => {
        const { url } = this.endpoints.setProduct()

        this.setToStorage(data.item.itemId, data.item)

        return this.api.request().post(url, data)
      },
    }
  }

  get listing() {
    return {
      get: (params) => {
        const { url } = this.endpoints.getListing()

        const { data } = this.getFromStorage(params.url)

        return data ? promisify(data) : this.api.request().post(url, params)
      },

      set: (data) => {
        const { url } = this.endpoints.setListing()

        this.setToStorage(data.category.url, data.category)

        return this.api.request().post(url, data)
      },
    }
  }
}

import { PAGINATION_BUTTON, STATE } from '../constants'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadStreams() {
    await this.gaApp.services.streamerce.api.fetchHeaderImage()

    await this.loadPage(1)
    await this.gaApp.services.streamerce.api.getStreamsStatuses()
  }

  getStatus(status) {
    switch (status) {
      case 0:
        return STATE.FUTURE
      case 1:
        return STATE.NOW
      case 2:
        return STATE.PAST
    }
  }

  getStreamsByStatus(statusCode) {
    const streamIds = Object.entries(this.gaApp.stores.streamerce.main.statuses)
      .filter(([id, status]) => status === statusCode)
      .map((status) => +status[0])

    return this.gaApp.stores.streamerce.main.streams.filter((stream) =>
      streamIds.includes(stream.id),
    )
  }

  isPageLoaded(page) {
    return this.gaApp.stores.streamerce.main.pages
      .map((page) => page.number)
      .includes(page)
  }

  async loadPage(page, lastItemId) {
    if (this.isPageLoaded(page)) {
      return
    }

    const { streams, next } =
      await this.gaApp.services.streamerce.api.fetchStreams(page, lastItemId)

    this.setPage({ streams, number: page })

    this.setPagination({
      direction: PAGINATION_BUTTON.NEXT,
      value: {
        show: next,
      },
    })
  }

  setPagination({ direction, value }) {
    this.gaApp.stores.streamerce.main.pagination[direction] = {
      ...this.gaApp.stores.streamerce.main.pagination[direction],
      ...value,
    }
  }

  setPage(page) {
    this.gaApp.stores.streamerce.main.pages.push(page)
  }

  onProductClick(product) {
    if (product.sku) {
      this.gaApp.services.modal.main.openQuickPreviewProductModal(product.sku)
    }
  }
}

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
export class GtmGa4userInteractionModel extends GtmBaseModel {
  constructor(data = {}) {
    super({
      event: 'user_interaction',
    })

    const mapping = {
      id: 'item_id',
      eventCategory: 'event_category',
      actionType: 'action_type',
      screenType: 'screen_type',
      photos: 'photos',
      position: 'position',
      productType: 'product_type',
      event: 'event',
      click: 'click',
      place: 'place',
    }

    Object.keys(data).forEach((key) => {
      if (mapping[key]) {
        this[mapping[key]] = data[key]
      }
    })
  }
}

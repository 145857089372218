import {EVENT_TYPES, WIDGET_TYPES} from '../../../constants'
import {DigiAnalyticsWidgetBaseModel} from '../digi-analytics-widget-base/digi-analytics-widget-base.model'

export class DigiAnalyticsProductClickModel extends DigiAnalyticsWidgetBaseModel {
  constructor({placement, product, index}) {
    super({placement})
    this.data.eventType = EVENT_TYPES.WIDGET_CLICK
    this.data.widgetType = placement.widgetType || WIDGET_TYPES.SLIDER
    this.data.pageNumber = null
    this.data.productId = product.itemId
    this.data.position = index + 1
  }
}


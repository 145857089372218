<template>
  <svg viewBox="0 0 25 25">
    <path
      fill="none"
      stroke-width="1.5"
      d="m11.963 5.067.537.55.536-.55c.585-.599 1.924-1.66 3.529-2.109 1.55-.432 3.346-.302 5.058 1.45 1.716 1.756 1.85 3.611 1.422 5.215-.44 1.652-1.482 3.026-2.066 3.624l-4.991 5.11-2.797 2.862-.691.708-.691-.708-2.797-2.863-4.99-5.109c-.585-.598-1.626-1.972-2.067-3.624-.427-1.604-.294-3.46 1.421-5.215 1.712-1.752 3.509-1.882 5.058-1.45 1.605.448 2.944 1.51 3.529 2.11Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-heart-big',
}
</script>

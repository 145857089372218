export const ADULT_MODAL_REASON_TO_OPEN = {
  PRODUCT: 'product',
  CATEGORY: 'category',
  ARTICLE: 'article',
}

export const AUTH_MODAL_REASON_TO_OPEN = {
  REGULAR_LOGIN: 'regular-login',
  RELOGIN: 'relogin',
  ADD_PRODUCT_TO_FAVORITES: 'add-product-to-favorites',
  ADD_BRAND_TO_FAVORITES: 'add-brand-to-favorites',
  LOGIN_TO_CHECKOUT_BY_CART: 'login-to-checkout-by-cart',
}

export const AUTH_MODAL_REDIRECT = {
  CART: 'cart',
  PAGE: 'page',
}

export const CART_MODAL_REASON_TO_OPEN = {
  REGULAR: 'regular',
}

export const MODAL_NAME = {
  ADULT: 'adult',
  AUTH: 'auth',
  CART: 'cart',
  PRODUCT_SUBSCRIPTION: 'product-subscription',
  QUICK_PREVIEW: 'quick-preview',
  PARTS_PAYMENT: 'parts-payment',
  DELIVERY: 'delivery',
}

export const MODAL_COMPONENTS = {
  [MODAL_NAME.QUICK_PREVIEW]: 'ga-super-modal-quick-preview',
  [MODAL_NAME.DELIVERY]: 'ga-super-modal-delivery',
  [MODAL_NAME.AUTH]: 'ga-super-modal-auth',
  [MODAL_NAME.PARTS_PAYMENT]: 'ga-super-modal-parts-payment',
  [MODAL_NAME.PRODUCT_SUBSCRIPTION]: 'ga-super-modal-product-subscription',
  [MODAL_NAME.CART]: 'ga-super-modal-cart',
  [MODAL_NAME.ADULT]: 'ga-super-modal-adult',
}

export const TYPE = {
  SWIPE: 'swipe',
  BASE: 'base',
}

export const MODAL_HASH = {
  AUTH: 'auth',
  CART: 'cart',
  LOCATION: 'location',
  GIFT_CARDS: 'gift-cards',
}

export const EXCLUDE_REMOVE_HASH = [MODAL_HASH.GIFT_CARDS]

export const WEBVIEW_MODALS = [MODAL_NAME.ADULT]

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useSuperModal = () => {
  const { $gaApp } = useContext()

  const hasOpenedModal = computed(
    () => $gaApp.stores.modal.main.hasOpenedModals,
  )
  const hasOpenedCartModal = computed(
    () => $gaApp.stores.modal.main.hasOpenedCartModal,
  )
  const hasOpenedAdultModal = computed(
    () => $gaApp.stores.modal.main.hasOpenedAdultModal,
  )
  const hasOpenedAuthModal = computed(
    () => $gaApp.stores.modal.main.hasOpenedAuthModal,
  )
  return {
    hasOpenedModal,
    hasOpenedCartModal,
    hasOpenedAdultModal,
    hasOpenedAuthModal,
  }
}

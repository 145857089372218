import { defineStore, getActivePinia } from 'pinia'

import { getters } from './getters'
import { state } from './state'

export const mainStore = (gaApp) => {
  const init = defineStore('purchasedMain', {
    getters: getters(gaApp),
    state,
  })

  return init(getActivePinia())
}

import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/ar'
import layoutsArAe from '~/locales/languages/layouts/ar-AE'

export default () => {
  return {
    ...merge(layouts, layoutsArAe),
  }
}

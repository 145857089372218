import isString from 'lodash/isString'

const SYMBOL_HALF_WEIGHT = 0.5
const DEFAULT_WEIGHT = 1

const SYMBOL_WEIGHT = {
  ' ': SYMBOL_HALF_WEIGHT,
  ',': SYMBOL_HALF_WEIGHT,
  '.': SYMBOL_HALF_WEIGHT,
}

export class StringService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getWeight(value) {
    const reducer = (accumulator, currentSymbol) => {
      return accumulator + (SYMBOL_WEIGHT[currentSymbol] ?? DEFAULT_WEIGHT)
    }

    return isString(value) ? [...value].reduce(reducer, 0) : 0
  }
}

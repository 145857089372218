import { computed, toRefs, unref } from 'vue'

import { propValidator } from '../../../utils'
import { reviews as schemaReviews } from '../../../utils/schemas'

import { HIGHLIGHT_THEME, SIZE } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-product-card-rating',

  props: {
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    data: {
      type: Object,
      default: () => ({}),
      required: true,
      validator: (value) => propValidator(value, schemaReviews),
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    highlightTheme: {
      type: String,
      default: HIGHLIGHT_THEME.DEFAULT,
      validator: (value) => Object.values(HIGHLIGHT_THEME).includes(value),
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { highlight, reversed, size, highlightTheme } = toRefs(props)

    const mods = computed(() => ({
      highlight: unref(highlight),
      reversed: unref(reversed),
      highlight_theme: unref(highlightTheme),
    }))

    const sizes = computed(() => ({
      text: unref(size),
      stars: {
        [SIZE.S]: 'xs',
        [SIZE.M]: 's',
        [SIZE.L]: 'm',
      }[unref(size)],
    }))

    return {
      mods,
      sizes,
    }
  },
}

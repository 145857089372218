import { unref } from 'vue'

import { KEY_CODES } from '@ga/constants/key-codes'

export const useItemEventsEmitters = (item, emit) => {
  const onKeyDown = (event) => {
    const { space, enter } = KEY_CODES
    const { keyCode } = event

    const isParent = unref(item).parent
    const isSpaceOrEnterPressed = [space, enter].includes(keyCode)

    if (isParent && isSpaceOrEnterPressed) {
      event.preventDefault()
      event.stopPropagation()

      emit('item-click-keyboard', unref(item))
    }
  }

  const onMouseEnter = () => {
    emit('item-mouseenter', unref(item))
  }

  const onMouseLeave = () => {
    emit('item-mouseleave', unref(item))
  }

  const onClick = (event) => {
    const isParent = unref(item).parent

    if (event && isParent) {
      event.preventDefault()
      event.stopPropagation()
    }

    emit('item-click', unref(item))
  }

  return {
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onClick,
  }
}

import { PAGE_SIZE } from '../../constant'

export const getters = (gaApp) => ({
  getCategoryData() {
    return this.categoryData
  },

  getProductsCount() {
    return this.productsCount
  },

  getPagination() {
    return this.pagination
  },

  getLoadedPagesCount() {
    return this.pages?.length
  },

  getPages() {
    return this.pages
  },

  getListingTempData() {
    return this.listingTempData
  },

  getPagesCount() {
    return this.productsCount / PAGE_SIZE
  },

  getMarketingProductsCount() {
    return this.marketingProductsCount
  },

  getPageNumberFirst() {
    const pages = this.pages

    return pages.length ? pages[0].number : 0
  },

  getPageNumberLast() {
    const pages = this.pages

    return pages.length ? pages[pages.length - 1].number : 0
  },

  getIsSimpleLayout() {
    return this.isSimpleLayout
  },

  getLayoutAnimating() {
    return this.isLayoutAnimating
  },
})

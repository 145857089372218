<template>
  <svg viewBox="0 0 20 20">
    <path
      stroke="none"
      d="M15.654 4.128c-2.693-.171-8.69-.17-11.378 0C1.365 4.314 1.022 5.955 1 10.275c.022 4.313.362 5.961 3.276 6.148 2.69.17 8.685.171 11.378 0 2.911-.186 3.254-1.827 3.276-6.147-.022-4.313-.362-5.961-3.276-6.148Zm-7.93 9V7.423l5.603 2.848-5.603 2.857Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-social-yt',
}
</script>

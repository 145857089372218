import { computed } from 'vue'

import { GaImageResponsive } from '../../image-responsive'

import { SIZE, VISIBLE_MAX_COUNT } from './scripts/const'

// @vue/component
export default {
  name: 'ga-product-card-attributes-colors',

  components: {
    GaImageResponsive,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    count: {
      type: Number,
      default: 0,
    },
    maxCount: {
      type: Number,
      default: VISIBLE_MAX_COUNT,
    },
  },

  setup(props) {
    const limit = computed(() => {
      return props.maxCount < VISIBLE_MAX_COUNT
        ? props.maxCount
        : VISIBLE_MAX_COUNT
    })

    const extraCount = computed(() => {
      return props.count - limit.value
    })

    const optionsVisible = computed(() => {
      return props.options.slice(0, limit.value)
    })

    const mods = computed(() => {
      const { size, count } = props
      const compact = count > VISIBLE_MAX_COUNT

      return { size, compact }
    })

    return {
      mods,

      optionsVisible,
      extraCount,
    }
  },
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 50 50">
    <path
      fill="#fff"
      d="M25.616 33.6C17.075 33.6 12.203 27.744 12 18h4.279c.14 7.152 3.294 10.181 5.793 10.806V18H26.1v6.168c2.467-.265 5.059-3.076 5.933-6.168h4.029c-.672 3.81-3.482 6.62-5.481 7.776 1.999.937 5.2 3.389 6.418 7.824h-4.435c-.952-2.967-3.326-5.263-6.464-5.575V33.6h-.485Z"
    />
    <defs>
      <clipPath><path fill="#fff" d="M12 18h25v15.6H12z" /></clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-vk',
}
</script>

import { useResizeObserver } from '@vueuse/core'

import { nextTick, onMounted, reactive, ref, unref, watch } from 'vue'

/**
 * Возвращает cтили поля с автоматически увеличивающейся высотой
 * @param {Ref<Ref>} inputRef - input ref
 * @param {Ref<String>} value - input value
 * @param {Ref<Boolean>} autoHeight - разрешено ли увеличение поля
 * @param {Ref<Number>} minHeight - минимально возможная высота px
 * @param {Ref<Number>} maxHeight - максимально возможная высота px
 * @param {Ref<Number>} baseHeight - базовая высота инпута
 */
export const useAutoHeightStyles = (
  inputRef,
  value,
  autoHeight,
  minHeight,
  maxHeight,
  baseHeight,
) => {
  const styleAutoHeight = reactive({
    height: null,
    overflow: null,
  })

  const hasScroll = ref(false)

  const resetStyles = () => {
    hasScroll.value = false
    styleAutoHeight.height = `${unref(baseHeight)}px`
    styleAutoHeight.overflow = 'hidden'
  }

  const setStyles = () => {
    let contentHeight = unref(inputRef).scrollHeight

    contentHeight =
      unref(minHeight) && contentHeight < unref(minHeight)
        ? unref(minHeight)
        : contentHeight

    hasScroll.value = unref(maxHeight) && contentHeight > unref(maxHeight)

    styleAutoHeight.height = hasScroll.value
      ? `${unref(maxHeight)}px`
      : `${contentHeight}px`
    styleAutoHeight.overflow = hasScroll.value ? 'auto' : 'hidden'
  }

  const updateStyles = () => {
    if (unref(autoHeight)) {
      resetStyles()

      nextTick(() => {
        setStyles()
      })
    } else {
      styleAutoHeight.height = null
      styleAutoHeight.overflow = null
    }
  }

  onMounted(() => {
    updateStyles()

    useResizeObserver(unref(inputRef), () => {
      updateStyles()
    })
  })

  watch([value, autoHeight], () => {
    updateStyles()
  })
  return {
    styleAutoHeight,
    hasScroll,
  }
}

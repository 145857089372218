/**
 * Возвращает локализованный формат даты
 * https://learn.javascript.ru/intl#intl-datetimeformat
 * @returns {String}
 * @param {Date} date
 * @param {String} locale
 * @param {Object} options
 */
export const getIntlFormatDate = (
  date = new Date(),
  locale = 'ru',
  options = {},
) => {
  const formatter = new Intl.DateTimeFormat(locale, options)

  return formatter.format(date)
}

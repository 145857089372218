import { defineStore } from 'pinia'

import { getters } from './getters'
import state from './state'

export const modalStore = (gaApp) => {
  const init = defineStore('customerModal', {
    getters: getters(gaApp),
    state,
  })

  return init.bind(gaApp.appVue)()
}

import { CHUNK_SIZE_ANALYTICS } from '../analytics/constants'

export const useAnalytics = () => {
  const productsAnalytic = ({ type }) => {
    const getAnalyticData = ({ name, placement }) => {
      return {
        path: `${type}/block: ${name}`,
        itemListId: `${type}/block_1`,
        chunkSize: CHUNK_SIZE_ANALYTICS,
        plpType: 's',
        digiAnalytics: true,
        placement: placement.value,
      }
    }

    const getCartAnalyticDetails = ({ productIndex, blockName }) => {
      return {
        analyticsDetails: {
          indexTotal: productIndex + 1,
          itemListId: `${type}/block_1`,
          itemListName: `${type}/block: ${blockName}`,
        },
      }
    }

    return { getAnalyticData, getCartAnalyticDetails }
  }

  return { productsAnalytic }
}

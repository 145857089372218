import { advModalStore } from './adv-modal'
import { mainStore } from './main'
import { queueStore } from './queue'
import { registerStore } from './register'

export const stores = (gaApp) => {
  return {
    main: mainStore(gaApp),
    register: registerStore(gaApp),
    advModal: advModalStore(gaApp),
    queue: queueStore(gaApp),
  }
}

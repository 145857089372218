import { useElementHover, useFocus } from '@vueuse/core'

import { computed, unref } from 'vue'

import { valueExists } from '../utils'

/**
 * Возвращает ссылки на флаги состояния инпута
 * @param {Ref<Object>} inputRef - input ref
 * @param {Ref<String>} value - input value ref
 */

export const useInputState = (inputRef, value) => {
  const { focused: isFocused } = useFocus(inputRef)

  const focus = () => {
    isFocused.value = true
    // android не может программно установить фокус
    // даже через .focus()
    const inputEl = unref(inputRef).$el || unref(inputRef)
    inputEl.click()
  }

  const isHovered = useElementHover(inputRef)

  const hasValue = computed(() => {
    return valueExists(unref(value))
  })

  return {
    isHovered,
    isFocused,
    hasValue,

    focus,
  }
}

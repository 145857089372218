import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGaUaProductModel } from '../gtm-gaua-product.model/gtm-gaua-product.model'

export class GtmGaUaModel extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }

    const {
      view,
      brand,
      name,
      amount,
      id,
      breadcrumbs,
      attributesSelected,
      itemListName,
      inStock,
    } = product

    super({ event: 'productDetail', view })

    this.ecommerce = {
      detail: {
        products: [
          new GtmGaUaProductModel({
            brand,
            name,
            amount,
            id,
            breadcrumbs,
            attributesSelected,
            itemListName,
            inStock,
          }),
        ],
      },
    }
  }
}

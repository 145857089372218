import { computed } from 'vue'

import { useBem } from '@ga/bem'

import { GaIconReviewStarWithHalf } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

import { useClassNameStarsWrapper } from './scripts/class-name-stars-wrapper'
import { INDENT, SIZE, VIEW } from './scripts/const'

export default {
  name: 'ga-review-stars',
  components: {
    GaIcon,
    GaIconReviewStarWithHalf,
  },

  props: {
    view: {
      type: String,
      default: VIEW.BASE,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    size: {
      type: String,
      default: SIZE.ML,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    indent: {
      type: String,
      default: INDENT.M,
      validator: (value) => Object.values(INDENT).includes(value),
    },
    rating: {
      type: [Number, String],
      default: 0,
    },
    count: {
      type: Number,
      default: 5,
    },
    classNameStars: {
      type: [String, Function],
      default: null,
    },
    hasSeoAttrs: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const b = useBem()
    const classNameStarsWrapper = useClassNameStarsWrapper(props.classNameStars)

    const getClasses = (index) => {
      const mods = getMods(index)

      return [b('icon', mods), classNameStarsWrapper(mods)]
    }

    const seoAttrs = computed(() => {
      return props.hasSeoAttrs
        ? {
            itemprop: 'reviewRating',
            itemtype: 'https://schema.org/Rating',
            itemscope: true,
          }
        : {}
    })

    const getMods = (index) => {
      const ceilRating = Math.ceil(props.rating)
      const delta = ceilRating * 10 - props.rating * 10

      const hasHalf = delta >= 3 && delta <= 7
      const filledCount = delta > 7 ? ceilRating - 1 : ceilRating

      return {
        view: props.view,
        size: props.size,
        fill: index <= filledCount,
        indent: props.indent,
        half: hasHalf && index === ceilRating,
      }
    }

    return {
      getClasses,
      seoAttrs,
    }
  },
}

import { EVENT } from '../../../constants'
import { MindboxShareProductModel } from '../mindbox-share-product.model/mindbox-share-product.model';

export class MindboxShareSetCartEventModel {
  constructor(products) {
    this.operation = EVENT.SHARE_SET_CART.MINDBOX

    this.data = {
      productList: products.map(product => new MindboxShareProductModel(product, true)),
    }
  }
}

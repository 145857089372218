import { EXTENSION_SAFE } from '../../const'

/**
 * Возвращает объект одной картинки, с "безопасным" форматом изображения png или jpg
 *
 * @param {array} sources список уже собранных изображений
 * @returns {object} Объект одной картинки, с "безопасным" форматом изображения png или jpg
 */
export const findSafeExtension = (sources) => {
  return sources.find((el) => (el.ext ? EXTENSION_SAFE[el.ext] : el))
}

export class LandingService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.gaApp.services.giftCardsB2b.landing.closeMenu()
    })
  }

  /**
   * Открывает мобильное меню лендинга
   */
  openMenu() {
    this.gaApp.stores.giftCardsB2b.landing.menuIsOpen = true
  }

  /**
   * Закрывает мобильное меню лендинга
   */
  closeMenu() {
    this.gaApp.stores.giftCardsB2b.landing.menuIsOpen = false
  }

  /**
   * Открывает модальное окно информации об определенном виде КПК
   * @param {string} contentKey ключ типа КПК
   */
  openCardsModal(contentKey) {
    if (!contentKey) {
      return undefined
    }

    this.gaApp.stores.giftCardsB2b.landing.cardsModalContentKey = contentKey
  }

  /**
   * Закрывает модальное окно информации об определенном виде КПК
   */
  closeCardsModal() {
    this.gaApp.stores.giftCardsB2b.landing.cardsModalContentKey = null
  }
}

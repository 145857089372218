<template>
  <svg viewBox="0 0 18 19">
    <path fill="currentColor" d="M9 18.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
    <path
      fill="#fff"
      fill-rule="evenodd"
      stroke="none"
      d="M4.218 9a402.009 402.009 0 0 1 4.833-2.01c2.302-.924 2.78-1.084 3.092-1.09a.56.56 0 0 1 .32.093.334.334 0 0 1 .119.217c.01.062.024.204.014.315-.125 1.265-.665 4.335-.94 5.752-.116.6-.344.8-.566.82-.48.043-.846-.307-1.312-.602-.73-.462-1.142-.749-1.85-1.2-.818-.52-.288-.806.179-1.274.122-.122 2.242-1.984 2.283-2.153.005-.02.01-.1-.039-.141-.048-.042-.12-.027-.171-.016-.073.016-1.238.76-3.496 2.23-.33.22-.63.326-.898.32-.296-.005-.866-.16-1.289-.294-.519-.162-.931-.248-.895-.525.018-.144.224-.291.616-.442Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-support-tg-inverted',
}
</script>

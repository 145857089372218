export const getters = (gaApp) => ({
  asObject() {
    const result = {}
    this.items.forEach((item, index) => {
      result[item.id] = { index, ...item }
    })

    return result
  },
})

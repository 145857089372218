export class ConfirmService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  acceptConfirm() {
    this.gaApp.services.customer.dialog.closeDialog()

    const { id } = this.gaApp.stores.customer.order.data
    this.gaApp.services.customer.api.canCancelOrder(id)
  }

  cancelConfirm() {
    this.gaApp.services.customer.dialog.closeDialog()
  }
}

import ar from './ar.json'
import en from './en.json'
import ru from './ru.json'
import ruBY from './ru-BY.json'
import ruKZ from './ru-KZ.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ru-RU': [ru, ruRUOptions],
  'ru-BY': [ru, ruKZ],
  'ru-KZ': [ru, ruBY],
  'ar-AE': ar,
  'ar-QA': ar,
  'en-AE': en,
  'en-QA': en,
  'ar-RU': ar,
  'en-RU': en,
}

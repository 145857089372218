import { EVENT } from '../../../constants'

export class GtmBaseModel {
  constructor({
    sku,
    action,
    event = EVENT.INTERACTION.GA4,
    eventCategory = 'reviews',
  } = {}) {
    this.event = event
    this.action = action
    this.event_category = eventCategory
    this.item_id = sku
  }
}

import { computed, nextTick, ref, watch } from 'vue'

import { getPhoneWithoutMask } from '@ga/utils'

import {
  PHONE_FORMAT,
  PHONE_INITIAL,
  PHONE_MASK_CHAR,
  PHONE_PLACEHOLDER_CHAR,
} from '../../constants'

import { useMasks, usePhoneCode, usePhoneFormatted } from './use'

/**
 * Возвращает необходимые поля для инпута телефона
 * @param {Object} obj
 * @param {Ref<String>} obj.phone // Значение phone из props
 * @param {Ref<String>} obj.phoneCode // Значение phone из props
 * @param {Ref<Array>} obj.phoneCodes // Значение phoneCodes из props
 * @param {Ref<String>} obj.phoneFormatted // Значение phoneFormatted из props
 *
 * @param {Function} emit - emit функция контекста
 */

export function usePhone(
  { phone, phoneCode, phoneCodes, phoneFormatted },
  emit,
) {
  // Код телефона
  const { phoneCodeInternal, phoneCodeActive, getPhoneCode } = usePhoneCode(
    { phone, phoneCode, phoneCodes },
    emit,
  )

  // Маски
  const { maskNational, maskInternational, initMaskNational } = useMasks({
    phoneCodeActive,
  })

  // Отформатированный телефон
  usePhoneFormatted(
    { phone, phoneFormatted, phoneCode, maskInternational },
    emit,
  )

  // Телефон
  const isAutocompleted = ref(false)
  const phoneInternal = computed({
    get() {
      return maskNational.value.value
    },
    set(newValue) {
      const oldValue = phoneInternal.value
      let sanitizedPhone = getPhoneWithoutMask(newValue)

      if (!oldValue) {
        sanitizedPhone = formatPhone(sanitizedPhone)
      }

      maskNational.value.resolve(sanitizedPhone)

      return maskNational.value.value
    },
  })

  phoneInternal.value = phone.value

  watch(phoneInternal, (value) => {
    const fullPhone = phoneCodeActive.value.countryCode + value

    maskInternational.value.resolve(fullPhone)
    updatePhone(maskInternational.value.unmaskedValue)
  })

  watch(
    phoneCodeInternal,
    () => {
      if (!isAutocompleted.value) {
        resetPhone()
        initMaskNational()
      }
    },
    {
      immediate: true,
    },
  )

  function formatPhone(sanitizedPhone) {
    const { value, nationalPrefix, countryCode, lengths, masks } =
      getPhoneCode(sanitizedPhone)
    const isMaxLength = lengths.includes(sanitizedPhone.length)

    let formattedPhone = sanitizedPhone

    if (isMaxLength) {
      // Обновляем маску
      maskNational.value.updateOptions({
        mask: masks[PHONE_FORMAT.NATIONAL],
      })

      // Удаляем префикс
      if (formattedPhone.startsWith(nationalPrefix)) {
        formattedPhone = formattedPhone.replace(nationalPrefix, '')
      } else if (formattedPhone.startsWith(countryCode)) {
        formattedPhone = formattedPhone.replace(countryCode, '')
      }

      // Говорим что произошло автозаполнение
      // нужно для того чтобы не происходил ресет при изменении кода страны
      isAutocompleted.value = true

      // Обновляем код страны
      phoneCodeInternal.value = value

      nextTick(() => {
        isAutocompleted.value = false
      })
    }

    return formattedPhone
  }

  function resetPhone() {
    phoneInternal.value = PHONE_INITIAL
    updatePhone(phoneInternal.value)
  }

  function updatePhone(value) {
    emit('update:phone', value)
  }

  // Плейсхолдер
  const placeholder = computed(() => {
    return phoneCodeActive.value.masks[PHONE_FORMAT.NATIONAL].replaceAll(
      PHONE_MASK_CHAR,
      PHONE_PLACEHOLDER_CHAR,
    )
  })

  return {
    phoneInternal,
    phoneCodeInternal,
    placeholder,
    resetPhone,
  }
}

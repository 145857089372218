import { onMounted, ref, unref, watch } from 'vue'

export const useRender = (active) => {
  // рендер содержимого по первому открытию
  const render = ref(false)
  onMounted(() => {
    if (unref(active)) {
      render.value = true
      return
    }

    const unwatchActive = watch(active, (value) => {
      if (value) {
        unwatchActive()
        render.value = true
      }
    })
  })

  return {
    render,
  }
}

import { AdvcakeBaseModel } from '../advcake-base.model/advcake-base.model'
import { AdvcakeProductModel } from '../advcake-product.model/advcake-product.model'
import { ACTIONS } from '../../../constants'

export class AdvcakePageViewModel extends AdvcakeBaseModel {
  constructor(data) {
    super(ACTIONS.VIEW.ADVCAKE)

    this.user = {
      email: ''
    }

    this.currentProduct = new AdvcakeProductModel(data)
  }
}

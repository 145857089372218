export const OBJECT_FIT = {
  CONTAIN: 'contain',
  COVER: 'cover',
}

export const TRACKS_MODE = {
  SHOWING: 'showing',
  DISABLED: 'disabled',
  HIDDEN: 'hidden',
}

export const DEFAULT_TRACK_SETTINGS = {
  KIND: 'subtitles',
  DEFAULT: 'true',
  METADATA: 'metadata',
}

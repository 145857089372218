<template>
  <svg viewBox="0 0 18 18">
    <path fill="none" d="M17.5 9a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z" />
    <path
      stroke="none"
      d="m10.4 12.5-.96-.35c-.13.41-.3.64-.43 1.05h1.4v-.7z"
    />
    <path stroke="none" d="M8.54 13.2V7.28H9.6v5.92H8.54z" />
    <path
      stroke="none"
      d="m7.8 7.54.74-.26c.13.4.52.55.65.96H7.8v-.7zm0 4.96.96-.35c.14.41.3.64.43 1.05H7.8v-.7zm1.27-6.15c.45 0 .8-.33.8-.77a.78.78 0 0 0-.8-.78c-.44 0-.8.33-.8.78 0 .44.36.77.8.77z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-info',
}
</script>

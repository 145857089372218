import { computed, getCurrentInstance } from 'vue'

import { GaButtonBase } from '../button-base'
import { GaLinkBase } from '../link-base'

import { VIEW } from './scripts/const'

// @vue/component
export default {
  name: 'ga-action',

  inheritAttrs: false,

  props: {
    view: {
      type: String,
      default: VIEW.REGULAR,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { attrs }) {
    const instance = getCurrentInstance()
    const transactionName = instance.proxy.$parent.$options.name
    const component = computed(() => {
      return attrs.href ? GaLinkBase : GaButtonBase
    })

    return { component, transactionName }
  },
}

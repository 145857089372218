export const TYPE = {
  LOCALY: 'localyType',
}
export const KEYS = {
  ALL: 'all',
  MEDIA: 'hasMedia',
  FILTERS: 'filters',
}

export const VALUES = {
  ALL: 'all',
  WITH_MEDIA: 'withMedia',
}

export const initFiltersData = () => ({
  [KEYS.MEDIA]: false,
  [KEYS.FILTERS]: [],
})

export const FILTER_PAGE_NUMBER = {
  id: 'pageNumber',
  key: 'p',
  type: TYPE.LOCALY,
}

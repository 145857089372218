/**
 * Возвращает из массива первый элемент, если он есть, иначе null
 *
 * @param {array} array массив, из которого нужно забрать первый элемент
 * @returns {any|null} первый элемент массива или null
 */
export const getFirstElementOrNull = (array = []) => {
  if (Array.isArray(array) && array.length > 0) {
    return array[0]
  }

  return null
}

import { cartV3Endpoints } from './endpoints/cart-v3'
import { CartV3Repository } from './cart-v3.repository'
import { endpoints } from './endpoints'
import { MainRepository } from './main.repository'
import { ShareRepository } from './share.repository'

export const repositories = (gaApp) => ({
  main: new MainRepository(gaApp, endpoints),
  cartV3: new CartV3Repository(gaApp, cartV3Endpoints),
  share: new ShareRepository(gaApp, endpoints),
})

<template>
  <svg fill="currentColor" viewBox="0 0 33 30">
    <g filter="url(#video-player-rewind-a)">
      <path
        fill="inherit"
        fill-rule="evenodd"
        d="M11.04 7.549c4.13-4.13 10.84-4.123 14.986.023 4.147 4.147 4.154 10.857.023 14.987a10.54 10.54 0 0 1-8.077 3.076 10.613 10.613 0 0 1-3.982-1.022l-.629 1.313a12.07 12.07 0 0 0 4.528 1.163 11.995 11.995 0 0 0 9.19-3.5c4.703-4.702 4.69-12.334-.023-17.047C22.344 1.83 14.712 1.817 10.01 6.52c-2.853 2.854-3.97 6.786-3.355 10.489l-1.246-1.246-1.378-1.379L3 15.413l1.378 1.379 2.757 2.757.515.515.515-.515 3.008-3.008 1.253-1.253-1.03-1.03-1.253 1.253-1.92 1.92c-.8-3.445.14-7.205 2.816-9.882Zm4.556 9.303c.096 1.476 1.044 2.58 3.06 2.58 1.848 0 3.156-1.128 3.156-2.952 0-1.788-1.092-2.88-2.64-2.88-.912 0-1.512.336-1.848.72l.012-2.328h3.984v-1.26h-5.208l-.072 4.776h.816c.384-.444.912-.744 1.788-.744.96 0 1.548.648 1.548 1.692 0 1.056-.552 1.74-1.548 1.74-1.104 0-1.428-.54-1.56-1.344h-1.488Z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="video-player-rewind-a"
        width="34.652"
        height="34.652"
        x="-.326"
        y="-2.326"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.163" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_3186_4390"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3186_4390"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-rewind',
}
</script>

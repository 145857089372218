import { computed, unref } from 'vue'

/**
 * @typedef {Object} DataForFavorite
 * @property {String} itemId - id продукта (из api)
 * @property {String} name - имя продукта для отображения в нотификации
 * @property {String} brand - бренд продукта для отображения в нотификации
 * @property {Array<Object>} media - массив медиа (первое изображение будет отображено в нотификации)
 * @property {Object} attributes - объект атрибутов (первые варианты будут отражены в нотификации)
 * @property {Boolean} favourite - favorite из api
 */

/**
 * Возвращает данные для кнопки favorite
 * @param {Ref<DataForFavorite>} data
 */

export const useDataFavorite = (data) => {
  const dataFavorite = computed(() => {
    const { attributes, itemId, brand, name, media, favourite } =
      unref(data) || {}

    return {
      favourite,
      brand,
      name,
      productId: itemId,
      attributes: attributes || undefined,
      // фильтруем, чтобы исключить видео
      imageUrls: media
        .filter((source) => source.image)
        .map((source) => source.image),
    }
  })

  return {
    dataFavorite,
  }
}

export const getListingStateInitial = () => ({
  page: 1,
  layout: '',
  name: '',
  pages: [],
  pagination: {
    page: 0,
    show: false,
    pending: false,
    end: false,
  },
})

export default () => {
  return getListingStateInitial()
}

<template>
  <svg fill="none" viewBox="0 0 14 11">
    <path stroke-width="1.25" d="m8 1 4.5 4.5L8 10m4-4.5H0" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-primary-arrow-right',
}
</script>

import { useEventListener } from '@vueuse/core'

import { BLOCK_TYPES, PAGE_NOT_FOUND, ROUTE } from '../constants/listing'

/**
 *
Предоставляет основные методы для работы со списком отзывов:

`isBot` - возвращает флаг, указывающий, является ли текущий пользователь ботом.

`getAvailableProductsForReview()` - получает список доступных для отзыва продуктов.

`getUrlToListing({ productId })` - возвращает URL-адрес страницы со списком отзывов для указанного productId.

`scrollToAnchor()` - прокручивает страницу к указанному якорю.

`routeBack()` - возвращается на предыдущую страницу, используя историю маршрутов, URL продукта или переходя на главную страницу.

`saveFromRoutePath()` - сохраняет путь, с которого пришел пользователь, в store.

`closePageKeydownEsc()` - закрывает страницу при нажатии клавиши Esc, если нет открытых модальных окон.

`getReviewStatistics()` - получает статистику отзывов для использования на странице продукта.

`filledAllStores(fetchedData, productId)` - заполняет все необходимые store (статистика, галерея, фильтры и т.д.) данными из fetchedData. Если нет отзывов и пользователь не является ботом, выбрасывает ошибку PAGE_NOT_FOUND.
 */

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get isBot() {
    return this.gaApp.stores.user.main.isBot
  }

  getAvailableProductsForReview() {
    const skipRequest =
      !this.gaApp.stores.user.main.isAuthorized ||
      !this.gaApp.features.get('reviewProductsAvailable')

    if (skipRequest) {
      return
    }

    this.gaApp.services.review.api.getProductsAvailable()
  }

  getUrlToListing({ productId } = {}) {
    return `${ROUTE}/${productId}`
  }

  scrollToAnchor() {
    const anchor = this.gaApp.services.app.scrollNavigation.getAnchor()

    this.gaApp.services.app.scrollNavigation.scrollToAnchor(anchor)
  }

  routeBack() {
    const fromRoutePath = this.gaApp.stores.review.main.fromRoutePath
    const url = this.gaApp.stores.review.card.productCard?.url

    if (fromRoutePath && fromRoutePath !== this.gaApp.route.fullPath) {
      this.gaApp.router.back()
      this.gaApp.stores.review.main.fromRoutePath = null
    } else if (url) {
      // если есть урл продукта то переходимируем на него
      this.gaApp.router.push(url)
    } else {
      this.gaApp.services.review.page.toMainPage()
    }
  }

  saveFromRoutePath() {
    const { route, from } = this.gaApp.ctx

    if (route?.fullPath !== from?.fullPath) {
      this.gaApp.stores.review.main.fromRoutePath = from?.fullPath
    }
  }

  closePageKeydownEsc() {
    // на mount компонента слушатель вешается, на unmount удаляется
    useEventListener(document, 'keydown', (e) => {
      // если есть открытые модалки то ничего не делаем
      const hasOpenModals =
        Object.values(this.gaApp.stores.app.main.zIndexes)?.length > 0

      if (e.key === 'Escape' && !hasOpenModals) {
        this.gaApp.services.review.main.routeBack()
      }
    })
  }

  // данные для аналитики пдп
  getReviewStatistics() {
    return this.gaApp.services.review.statistic.getPreviewStatistic()
  }

  filledAllStores(fetchedData = {}, productId) {
    // если нет отзывов, то показываем 404, для пользователя
    if (
      !fetchedData?.[BLOCK_TYPES.STATISTIC]?.allReviewsCount &&
      !this.gaApp.services.review.main.isBot
    ) {
      throw new Error(PAGE_NOT_FOUND)
    }

    for (const [key, data] of Object.entries(fetchedData)) {
      if (!data || !this.gaApp.services.review[key]) {
        continue
      }

      this.gaApp.services.review[key].filledStore(data, productId)
    }
  }
}

<template>
  <svg viewBox="0 0 20 20">
    <path stroke-width="1.5" d="m2 2 16 16" />
    <path stroke-width="1.5" d="M2 18 18 2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-cross',
}
</script>

import { computed } from 'vue'

import { AXIS } from '../../children/notifications-swiper'
import { getFullPosition, parseWidthValue } from '../utils'

export const useNotificationsStyles = (position, speed, width, indent) => {
  const style = computed(() => {
    const { x, y } = getFullPosition(position.value)

    const parsedWidth = parseWidthValue(width.value)

    const widthNumber = parsedWidth.number
    const widthUnit = parsedWidth.unit

    const styles = {
      width: widthNumber + widthUnit,
      [y]: indent.value[AXIS.Y],
    }

    if (x === 'center') {
      styles.left = `calc(50% - ${widthNumber / 2}${widthUnit})`
    } else {
      styles[x] = indent.value[AXIS.X]
    }

    return styles
  })

  const notificationWrapperStyle = computed(() => {
    const { y } = getFullPosition(position.value)

    return {
      [y]: '0px',
      transitionDuration: `${speed.value}ms`,
    }
  })

  return {
    style,
    notificationWrapperStyle,
  }
}

<template>
  <svg viewBox="0 0 14 11">
    <path fill="none" d="m8 1 4.5 4.5L8 10m4-4.5H0" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-button-arrow',
}
</script>

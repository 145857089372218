import { EVENTS } from '../../../constants'

export class DigiAnalyticsAutocompleteClickModel {
  constructor(data) {
    this.data = {
      eventType: EVENTS.AUTOCOMPLETE_CLICK,
    }

    for (const key in data) {
      this.data[key] = data[key]
    }
  }
}

import { computed, ref, watch } from 'vue'

import { ITEMS_DIRECTION } from './const'

export const useSlider = ({
  items,
  itemsCount,
  itemsInternal,
  itemsAnimating,
  itemsDirection,
}) => {
  const offset = ref(0)
  const offsetStep = computed(() => {
    return itemsCount.value
  })
  const offsetLast = computed(() => {
    return itemsInternal.value.length - offsetStep.value
  })

  const instantAnimation = ref(false)

  const onAnimationEnd = () => {
    if (itemsAnimating.value) {
      itemsDirection.value = null
      itemsAnimating.value = false
    }
  }

  watch(offset, (newValue, oldValue) => {
    if (instantAnimation.value) {
      itemsDirection.value = null

      instantAnimation.value = false

      return
    }

    itemsAnimating.value = true
    itemsDirection.value =
      newValue - oldValue > 0 ? ITEMS_DIRECTION.RIGHT : ITEMS_DIRECTION.LEFT
  })

  watch(items, (newValue, oldValue) => {
    if (oldValue.length === 0) {
      return
    }

    instantAnimation.value = true

    offset.value = 0
  })

  return { offset, offsetStep, offsetLast, onAnimationEnd }
}

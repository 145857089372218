export const routes = [
  {
    moduleType: 'app',
    pageType: 'error404',
    path: '/404',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/app/page/404' */
        '~/modules/app/components/page-404/page-404.vue'
      ),
  },
  {
    moduleType: 'app',
    pageType: 'error500',
    path: '/500',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/app/page/500' */
        '~/modules/app/components/page-500/page-500.vue'
      ),
  },
]

export const getPages = (otherRoutes = []) => {
  return [...routes, ...otherRoutes].reduce((components, route) => {
    components[route.moduleType + '-' + route.pageType.replace('/', '-')] =
      route.component
    return components
  }, {})
}

import { EVENT_CATEGORY_TRIX } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmTrixTopProductClickModel extends GtmBaseModel {
  constructor({ itemSku, blockName }) {
    super({ eventCategory: EVENT_CATEGORY_TRIX })

    this.action_type = 'click_product'
    this.block_name = blockName
    this.item_sku = itemSku
  }
}

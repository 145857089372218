import { TABS } from '../../constants'

export const TYPE = {
  GTM_UA: 'gtm_ua',
  GTM_4: 'gtm_4',
  GAA: 'ga_analytics',
}
export const SOURCE = 'plaid_backend'

export const ACTIONS = {
  TAB: {
    GA_UA: 'user_interaction',
    GA_4: 'user_interaction',
  },
  MENU: {
    GA_UA: 'user_interaction',
    GA_4: 'user_interaction',
  },
}

export const EVENT_CATEGORIES = {
  [TABS.AUTH]: 'user_account',
  [TABS.FAVORITES]: 'open_wishlist',
}

export const ACTION_TYPES = {
  [TABS.AUTH]: 'open_account',
}

export const SCREEN_TYPES = {
  [TABS.AUTH]: 'account',
}

import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const getMappedPrice = (price) => {
  const { $gaApp } = useContext()

  const prefix = computed(
    () => !$gaApp.stores.user.main.isAuthorized && price.hasLoyalty,
  )

  return {
    old: price.old || undefined,
    actual: price.actual,
    oldStrikethrough: price.viewOptions.crossPrice,
    prefix: prefix.value,
  }
}

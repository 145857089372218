import { formatArrayAsString } from '@ga/utils'

import {
  DigiAqSynonymClickBuilder,
  DigiAutocompleteBuilder,
  SearchBuilder,
} from '../../builders'
import { ACTION_TYPES, AUTOCOMPLETE_ITEM_TYPES } from '../../constants'
import { BaseController } from '../base/base.controller'

export class SearchAnalytics extends BaseController {
  // GTM
  /**
   * Ввели запрос и нажали enter или кнопку перехода на поисковую выдачу
   */
  onClickSearchQuery(inputTerm) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.CLICK_SEARCH_QUERY,
      search_term: inputTerm,
      input_term: inputTerm,
    })
  }

  /**
   * Воспользовались "автокомплитом" в строке запроса
   */
  onAutocomplete(inputTerm, autocompleteTerm) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.AUTOCOMPLETE,
      input_term: inputTerm,
      autocomplete_term: autocompleteTerm,
    })
  }

  /**
   * Открытие модалки поиска
   */
  onOpenSearch() {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.OPEN_SEARCH,
    })
  }

  /**
   * Клик на подсказке (history / popular / brands / category)
   */
  onClickSearchHints(inputTerm, type, searchTerm, searchPosition) {
    this.send(SearchBuilder, {
      action_type: ACTION_TYPES.CLICK_SEARCH_HINTS,
      type,
      input_term: inputTerm,
      search_term: searchTerm,
      search_position: searchPosition + 1,
    })
  }

  /**
   * Клик по истории
   */
  onClickHistory(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.HISTORY,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по категории
   */
  onClickCategory(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.CATEGORIES,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по бренду
   */
  onClickBrand(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.BRANDS,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по запросу
   */
  onClickQueries(searchTerm, autocompleteItem) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem,
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.QUERIES,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по товару
   */
  onClickProduct(searchTerm, product) {
    this.send(DigiAutocompleteBuilder, {
      anyQueryWorked: false,
      autocompleteItem: formatArrayAsString(
        [product.productType, product.brand, product.name],
        ' | ',
      ),
      autocompleteItemType: AUTOCOMPLETE_ITEM_TYPES.PRODUCTS,
      productId: product.itemId,
      originalSearchTerm: searchTerm,
      searchTerm,
    })
  }

  /**
   * Клик по баблу подсказок
   */
  onClickSynonym(searchTerm, anyQuerySynonymClicked, anyQuerySynonyms) {
    this.send(DigiAqSynonymClickBuilder, {
      searchTerm,
      anyQuerySynonymClicked,
      anyQuerySynonyms,
    })
  }
}

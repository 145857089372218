<template>
  <svg fill="none" stroke="none" viewBox="0 0 29 20">
    <rect width="29" height="20" fill="#F2F2F2" rx="2" />
    <path
      fill="#fff"
      d="M22.46 13.58A2.422 2.422 0 0 1 20.041 16H6.88V6.419A2.422 2.422 0 0 1 9.299 4H22.46v9.58Z"
    />
    <path
      fill="url(#payment-cards-jcb-a)"
      d="M18.174 11.124h1c.029 0 .096-.01.124-.01a.446.446 0 0 0 .353-.447c0-.229-.162-.4-.353-.447-.028-.01-.086-.01-.124-.01h-1v.914Z"
    />
    <path
      fill="url(#payment-cards-jcb-b)"
      d="M19.06 4.81c-.952 0-1.733.771-1.733 1.733v1.8h2.447a.98.98 0 0 1 .172.01c.552.028.962.314.962.809 0 .39-.276.724-.79.79v.02c.561.037.99.352.99.838 0 .523-.476.866-1.105.866h-2.686V15.2h2.543c.953 0 1.733-.772 1.733-1.733V4.81H19.06Z"
    />
    <path
      fill="url(#payment-cards-jcb-c)"
      d="M19.527 9.275a.405.405 0 0 0-.353-.409c-.019 0-.066-.01-.095-.01h-.905v.839h.905c.029 0 .086 0 .095-.01a.405.405 0 0 0 .353-.41Z"
    />
    <path
      fill="url(#payment-cards-jcb-d)"
      d="M9.48 4.81c-.953 0-1.734.771-1.734 1.733v4.276c.486.238.99.39 1.496.39.6 0 .923-.361.923-.857V8.333h1.486v2.01c0 .78-.486 1.419-2.133 1.419-1 0-1.781-.22-1.781-.22v3.648h2.543c.952 0 1.733-.771 1.733-1.733V4.81H9.48Z"
    />
    <path
      fill="url(#payment-cards-jcb-e)"
      d="M14.27 4.81c-.952 0-1.733.771-1.733 1.733v2.266c.438-.37 1.2-.609 2.428-.552.657.029 1.362.21 1.362.21V9.2a3.296 3.296 0 0 0-1.314-.381c-.934-.067-1.495.39-1.495 1.19 0 .81.561 1.267 1.495 1.19.543-.037.962-.209 1.314-.38v.733s-.695.181-1.362.21c-1.228.057-1.99-.181-2.428-.553v4h2.542c.953 0 1.734-.771 1.734-1.733V4.81H14.27Z"
    />
    <defs>
      <linearGradient
        id="payment-cards-jcb-a"
        x1="17.325"
        x2="21.605"
        y1="10.668"
        y2="10.668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset=".229" stop-color="#00873F" />
        <stop offset=".743" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="payment-cards-jcb-b"
        x1="17.325"
        x2="21.605"
        y1="10.001"
        y2="10.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset=".229" stop-color="#00873F" />
        <stop offset=".743" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="payment-cards-jcb-c"
        x1="17.325"
        x2="21.605"
        y1="9.274"
        y2="9.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset=".229" stop-color="#00873F" />
        <stop offset=".743" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="payment-cards-jcb-d"
        x1="7.744"
        x2="12.09"
        y1="10.001"
        y2="10.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1F286F" />
        <stop offset=".475" stop-color="#004E94" />
        <stop offset=".826" stop-color="#0066B1" />
        <stop offset="1" stop-color="#006FBC" />
      </linearGradient>
      <linearGradient
        id="payment-cards-jcb-e"
        x1="12.512"
        x2="16.733"
        y1="10.001"
        y2="10.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C2C2F" />
        <stop offset=".173" stop-color="#882730" />
        <stop offset=".573" stop-color="#BE1833" />
        <stop offset=".859" stop-color="#DC0436" />
        <stop offset="1" stop-color="#E60039" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-payment-cards-jcb',
}
</script>

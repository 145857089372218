import { computed, unref } from 'vue'

export const useMods = (
  size,
  reversed,
  reversedContent,
  highlight,
  isOutOfStock,
  withHover,
  infoIndent,
  contentVisibilityAuto,
) => {
  const mods = computed(() => ({
    size: unref(size),
    dir: unref(reversed) ? 'reversed' : 'normal',
    dir_content: unref(reversedContent) ? 'reversed' : 'normal',
    highlight: unref(highlight),
    out_of_stock: unref(isOutOfStock),
    touch: unref(withHover) ? 'off' : 'on',
    info_indent: unref(infoIndent),
    content_visibility_auto: unref(contentVisibilityAuto),
  }))

  return {
    mods,
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 51 21">
    <path
      fill="#000"
      d="M24.121 10.118v6.076h-1.883V1.195h4.995A4.422 4.422 0 0 1 30.46 2.5c1.708 1.627 1.813 4.366.21 6.124l-.21.215c-.877.85-1.953 1.28-3.228 1.28H24.12Zm0-7.08v5.238h3.158a2.435 2.435 0 0 0 1.86-.777c.983-1.041.96-2.715-.058-3.72a2.538 2.538 0 0 0-1.802-.742h-3.158Z"
    />
    <path
      fill="#000"
      d="M36.158 5.598c1.391 0 2.49.383 3.298 1.136.807.754 1.205 1.806 1.205 3.134v6.327h-1.802V14.77h-.081c-.784 1.173-1.813 1.759-3.112 1.759-1.1 0-2.035-.335-2.772-1.005a3.203 3.203 0 0 1-1.11-2.512c0-1.064.397-1.901 1.18-2.535.784-.634 1.837-.945 3.147-.945 1.123 0 2.035.215 2.76.622v-.443c0-.658-.28-1.28-.772-1.71a2.703 2.703 0 0 0-1.813-.706c-1.053 0-1.883.455-2.491 1.364l-1.661-1.065c.889-1.327 2.234-1.997 4.024-1.997Zm-2.433 7.451c0 .503.234.969.62 1.256.42.335.935.514 1.462.502.795 0 1.555-.323 2.117-.897.62-.598.935-1.303.935-2.117-.584-.478-1.403-.717-2.456-.705-.76 0-1.403.191-1.918.562-.503.37-.76.837-.76 1.4Z"
    />
    <path
      fill="#000"
      fill-rule="evenodd"
      d="m51 5.931-6.293 14.771h-1.942l2.34-5.166-4.13-9.605h2.048l2.982 7.368h.035l2.913-7.368H51Z"
      clip-rule="evenodd"
    />
    <path
      fill="#4285F4"
      fill-rule="evenodd"
      d="M16.506 8.804c0-.586-.047-1.172-.14-1.746H8.424v3.313h4.55a3.995 3.995 0 0 1-1.684 2.62v2.152h2.713c1.59-1.495 2.503-3.708 2.503-6.339Z"
      clip-rule="evenodd"
    />
    <path
      fill="#34A853"
      fill-rule="evenodd"
      d="M8.424 17.223c2.27 0 4.188-.765 5.58-2.081l-2.714-2.153c-.76.526-1.731.825-2.866.825-2.199 0-4.059-1.518-4.725-3.552H.903v2.225c1.427 2.906 4.34 4.736 7.521 4.736Z"
      clip-rule="evenodd"
    />
    <path
      fill="#FBBC04"
      fill-rule="evenodd"
      d="M3.699 10.263a5.307 5.307 0 0 1 0-3.301V4.749H.904a8.71 8.71 0 0 0 0 7.726l2.795-2.212Z"
      clip-rule="evenodd"
    />
    <path
      fill="#EA4335"
      fill-rule="evenodd"
      d="M8.556 3.41a4.478 4.478 0 0 1 3.228 1.29l2.41-2.463A8.03 8.03 0 0 0 8.555.001C5.374 0 2.462 1.842 1.035 4.749L3.83 6.973C4.497 4.928 6.357 3.41 8.556 3.41Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-google-pay',
}
</script>

import { computed, ref, watch } from 'vue'

/**
 * Возвращает необходимые поля для кода телефона
 * @param {Object} obj
 * @param {Ref<String>} obj.phone // Значение phone из props
 * @param {Ref<String>} obj.phoneCode // Значение phone из props
 * @param {Ref<Array>} obj.phoneCodes // Значение phoneCodes из props
 *
 * @param {Function} emit - emit функция контекста
 */

export function usePhoneCode({ phone, phoneCode, phoneCodes }, emit) {
  // Код телефона
  const phoneCodeInternal = ref('')
  const phoneCodeDefault = computed(() =>
    phoneCodes.value.find(({ isDefault }) => isDefault),
  )
  const phoneCodeActive = computed(() =>
    phoneCodes.value.find(({ value }) => value === phoneCodeInternal.value),
  )

  watch(
    phoneCode,
    (value) => {
      if (value) {
        phoneCodeInternal.value = value
      } else {
        const phoneCode = getPhoneCode(phone.value)

        phoneCodeInternal.value = phoneCode?.value
      }
    },
    { immediate: true },
  )

  watch(
    phoneCodeInternal,
    (value) => {
      updatePhoneCode(value)
    },
    { immediate: true },
  )

  function getPhoneCode(phone) {
    const codeByPhone =
      phone &&
      phoneCodes.value.find(
        ({ countryCode, nationalPrefix }) =>
          phone.startsWith(countryCode) || phone.startsWith(nationalPrefix),
      )

    return codeByPhone || phoneCodeDefault.value
  }

  function updatePhoneCode(value) {
    emit('update:phone-code', value)
  }

  return { phoneCodeInternal, phoneCodeActive, getPhoneCode }
}

import { EVENT } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ShareCartProductModel } from '../gtm-ga4-share-cart-product.model/gtm-ga4-share-cart-product.model'

export class GtmGa4ShareCartProductClickModel extends GtmBaseModel {
  constructor({ product }) {
    if (!product) {
      throw new Error('Product is required')
    }

    super({ event: EVENT.SHARE_PRODUCT_CLICK.GA4 })

    this.event_source = 'plaid_backend'
    this.item_list_name = 'share_cart'

    this.ecommerce = {
      items: [new GtmGa4ShareCartProductModel(product)],
    }
  }
}

export const GaModulePdpDeliveryModal = () =>
  import(
    /* webpackChunkName: 'modules/pdp/GaModulePdpDeliveryModal' */
    '../components/common/organisms/delivery-modal/delivery-modal.vue'
  )

export const GaModulePdpPartsPayment = () =>
  import(
    /* webpackChunkName: 'modules/pdp/GaModulePdpPartsPayment' */
    '../components/common/organisms/parts-payment/parts-payment.vue'
  )

export const GaModulePdpSubscription = () =>
  import(
    /* webpackChunkName: 'modules/pdp/GaModulePdpSubscription' */
    '../components/common/organisms/subscription/subscription.vue'
  )

export const GaModulePdpPreview = () =>
  import(
    /* webpackChunkName: 'modules/pdp/GaModulePdpPreview' */
    '../components/preview/templates/preview/preview.vue'
  )

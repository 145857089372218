import { useContext } from '@nuxtjs/composition-api'

import { onMounted, ref, toRefs, watch } from 'vue'

import { GaCountdown } from '@ga/ui-components/countdown'

// @vue/component
export default {
  name: 'ga-auth-countdown',

  components: { GaCountdown },

  props: {
    time: {
      type: Number,
      default: 0,
    },

    tPath: {
      type: String,
      default: '',
    },

    tokenType: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { $gaApp } = useContext()
    const show = ref(false)
    const { time } = toRefs(props)

    watch(time, (value) => {
      if (!isNaN(value) && value > 0) {
        show.value = true
      } else {
        show.value = false
      }
    })

    onMounted(() => {
      if (!isNaN(props.time) && props.time >= 0) {
        show.value = true
      }
    })

    const onCountdownEnd = () => {
      show.value = false
    }

    const getSecondsLeftText = (count) => {
      return $gaApp.i18n.tc('auth.modalStartForm.getSecondsLeftText', count)
    }

    return { show, onCountdownEnd, getSecondsLeftText }
  },
}

import { computed } from 'vue'

import { GaIconCommon15ArrowBack, GaIconCommon30ArrowBack } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'

import { DIRECTION, ICON_COMPONENT, SIZE } from './scripts/const'

// @vue/component
export default {
  name: 'ga-product-slider-control',

  components: {
    GaAction,
    GaIcon,
    GaIconCommon15ArrowBack,
    GaIconCommon30ArrowBack,
  },

  props: {
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    direction: {
      type: String,
      default: DIRECTION.RIGHT,
      validator: (value) => Object.values(DIRECTION).includes(value),
    },
  },

  setup(props) {
    const iconComponent = computed(() => ICON_COMPONENT[props.size])

    return {
      iconComponent,
    }
  },
}

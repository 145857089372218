import { computed, unref } from 'vue'

/**
 * Возвращет свойства overlay
 * @param {Ref<Boolean>} fullwidth - флаг полной ширины из props
 * @param {Ref<Boolean>} fullheight - флаг полной высоты из props
 * @param {Ref<Object>} transitionStyles
 * @param {Ref<String>} backgroundColor
 */
export const useOuter = (
  fullwidth,
  fullheight,
  transitionStyles,
  backgroundColor,
) => {
  const outerStyles = computed(() => {
    const width = unref(fullwidth) ? '100%' : null
    const height = unref(fullheight) ? '100%' : null

    return {
      width,
      height,
      backgroundColor: unref(backgroundColor),
      ...unref(transitionStyles),
    }
  })

  return {
    outerStyles,
  }
}

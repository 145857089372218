import { digitalStore } from './digital'
import { landingStore } from './landing'
import { mainStore } from './main'
import { plasticStore } from './plastic'
import { plasticNewStore } from './plastic-new'
import { stepsStore } from './steps'

export const stores = (gaApp) => {
  return {
    steps: stepsStore(gaApp),
    digital: digitalStore(gaApp),
    plastic: plasticStore(gaApp),
    plasticNew: plasticNewStore(gaApp),
    main: mainStore(gaApp),
    landing: landingStore(gaApp),
  }
}

import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../../index'

export class GtmGa4ListingArticleShowModel extends GtmBaseModel {
  constructor({ eventCategory, actionType, articleId, tags }) {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = eventCategory
    this.action_type = actionType
    this.article_id = articleId

    if (tags) {
      this.tags = tags
    }
  }
}

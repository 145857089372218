export const modulePlpSsrRoutes = [
  {
    moduleType: 'plp',
    pageType: 'listing',
    path: '/category/:entityId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/plp/page/listing' */
        '~/modules/plp/components/pages/main.vue'
      ),
  },
  {
    moduleType: 'plp',
    pageType: 'search',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/plp/page/listing' */
        '~/modules/plp/components/pages/search.vue'
      ),
  },
]

export const routes = [...modulePlpSsrRoutes]

import {
  DESIGN_OPTIONS,
  DESIGN_TYPES,
  EXPIRED,
  LOADER,
  MAX_TEXT_LIMIT,
  PAYMENT_METHODS,
  TABS_VALUE_DELIVERY,
  TABS_VALUE_RECIPIENT,
} from '~/modules/gift-cards/constants/digital'

import { initFormData, initStepItems } from '../../helpers'

export const state = () => ({
  visibleModal: false,
  visibleInnerModal: false,

  selectedDesignType: DESIGN_TYPES.GA,
  design: {
    [DESIGN_TYPES.GA]: {
      [DESIGN_OPTIONS.BACKGROUND]: null,
      [DESIGN_OPTIONS.IMAGE]: null,
      imagesOptions: [],
      backgroundsOptions: [],
    },
    [DESIGN_TYPES.AI]: {
      [DESIGN_OPTIONS.IMAGE]: null,
      imagesOptions: [],
    },
  },

  // Цвет фона стартового экрана и мобильного меню
  backgroundColor: '#A485FF',

  // Фоновые картинки для мобильного меню
  backgroundMenu: {
    left: {
      src: '/_static/img/gift-cards/new-year/bg-gift-cards-menu-l.png',
      srcRetina: '/_static/img/gift-cards/new-year/bg-gift-cards-menu-l@2x.png',
    },
    right: {
      src: '/_static/img/gift-cards/new-year/bg-gift-cards-menu-r.png',
      srcRetina: '/_static/img/gift-cards/new-year/bg-gift-cards-menu-r@2x.png',
    },
  },

  currentStep: 0,

  // дата последнего редактирования в мс
  lastModify: 0,

  // дата последнего редактирования данных в форме
  lastModifyFormData: 0,

  // индикатор восстановления стора из localStorage
  isRestored: false,

  // время устаревания данных в мс
  expired: EXPIRED,

  formData: initFormData(),

  isHideRecipientFields: true,

  activeTabDelivery: TABS_VALUE_DELIVERY.NOW,
  activeTabRecipient: TABS_VALUE_RECIPIENT.FRIEND,
  activeOptionPayment: PAYMENT_METHODS.CARD,

  currentDate: '',
  currentPhoneCode: '',
  priceInfo: {
    currency: 'RUB',
    denominator: 1,
  },

  validationRules: {
    deliveryUntilDate: '',
    felicitationCharacterLimit: MAX_TEXT_LIMIT,
    recipientNameCharactersLimit: MAX_TEXT_LIMIT,
    senderNameCharactersLimit: MAX_TEXT_LIMIT,
  },

  pricesOptions: [],
  timezoneOptions: [],
  thematics: [],
  allThematics: {},
  activeThematicVal: '',

  basketId: '',

  showReceiptForm: false,

  stepItems: initStepItems(),
  visitedStepsByDesign: {
    [DESIGN_TYPES.GA]: [],
    [DESIGN_TYPES.AI]: [],
  },

  loader: {
    [LOADER.CONFIG]: false,
    [LOADER.SUBMIT]: false,
  },
})

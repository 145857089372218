import { MODAL_MAX_PRODUCTS_THREE_ROWS } from '../constants'

export class ProductsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Возвращает ограниченное количество продуктов
   * в зависимости от сетки
   * @param {boolean} isThreeRowsGrid
   * @returns {array}
   */
  getProductsList(isThreeRowsGrid) {
    if (isThreeRowsGrid) {
      return this.gaApp.stores.search.main.searchResult.products.slice(
        0,
        MODAL_MAX_PRODUCTS_THREE_ROWS,
      )
    }
    return this.gaApp.stores.search.main.searchResult.products
  }
}

export class ApmService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get apm() {
    return this.gaApp.libs.apm
  }

  getCurrentTransaction() {
    if (this.gaApp.isServer) return

    return this.apm.getCurrentTransaction()
  }

  startTransaction(...params) {
    return this.apm.startTransaction(...params)
  }

  captureError(error) {
    if (this.gaApp.isServer) return

    return this.apm.captureError(error)
  }

  setCustomContext(...params) {
    if (this.gaApp.isServer) return

    return this.apm.setCustomContext(...params)
  }

  setUserContext(...params) {
    if (this.gaApp.isServer) return
    return this.apm.setUserContext(...params)
  }
}

<template>
  <svg fill="none" stroke="none" viewBox="0 0 58 50">
    <path
      fill="#FC3F1D"
      d="M.046 24.5c0 6.351 5.16 11.5 11.512 11.5S23.07 30.851 23.07 24.5 17.91 13 11.558 13C5.207 13 .046 18.149.046 24.5Z"
    />
    <path
      fill="#fff"
      d="M13.032 19.516h-1.064c-1.95 0-2.976.986-2.976 2.441 0 1.645.71 2.416 2.166 3.403l1.203.81-3.457 5.16H6.32l3.103-4.617c-1.786-1.277-2.786-2.517-2.786-4.617 0-2.63 1.836-4.427 5.318-4.427h3.457v13.649h-2.38V19.516Zm15.97-2.028v13.72h2.39v-4.895h1.167c3.122 0 5.118-1.441 5.118-4.482 0-2.763-1.857-4.343-5.099-4.343h-3.577Zm3.576 6.97h-1.185v-5.114h1.265c1.68 0 2.588.77 2.588 2.487 0 1.797-1.007 2.626-2.668 2.626Zm15.278 6.751-3.616-13.72h-3.024l-3.636 13.72h2.055l.77-3h4.21l.751 3h2.49Zm-6.976-4.857 1.64-6.357 1.62 6.357h-3.26Zm16.62-8.864h-2.233l-2.826 5.725-2.807-5.725h-2.569l4.032 7.858v5.863h2.391v-5.863l4.012-7.858Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-yandex-pay',
}
</script>

import { defineStore, getActivePinia } from 'pinia'

import { state } from './state'

export const plasticStore = () => {
  const init = defineStore('giftCardsB2bPlastic', {
    state,
  })

  return init(getActivePinia())
}

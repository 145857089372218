/*
 * Добавляет в компонент поле `hovered`
 */

import mixinNamespaceGenerator from './utils/mixin-namespace-generator'

const namespace = mixinNamespaceGenerator('hoverable', [
  'el',

  'onMouseEnter',
  'onMouseLeave',
])

const defaultOptions = {
  // ссылка на DOM-узел, на который буду повешены события,
  ref: null,

  // свойство в `data` для внутреннего хранения состояния
  hoverData: 'hovered',
}

export default (userOptions) => {
  let options = { ...defaultOptions }

  // предполагаем, что передана только ссылка на DOM-узел
  if (typeof userOptions === 'string') {
    options.ref = userOptions
  } else {
    options = { ...options, ...userOptions }
  }

  return {
    data() {
      return {
        [options.hoverData]: false,
      }
    },

    computed: {
      [namespace.el]() {
        if (options.ref === null) {
          return null
        }

        const $ref = this.$refs[options.ref]

        return $ref ? $ref.$el || $ref : null
      },
    },

    mounted() {
      const $el = this[namespace.el]

      if ($el) {
        $el.addEventListener('mouseenter', this[namespace.onMouseEnter])
        $el.addEventListener('mouseleave', this[namespace.onMouseLeave])
      }
    },

    destroyed() {
      const $el = this[namespace.el]

      if ($el) {
        $el.removeEventListener('mouseenter', this[namespace.onMouseEnter])
        $el.removeEventListener('mouseleave', this[namespace.onMouseLeave])
      }
    },

    methods: {
      [namespace.onMouseEnter]() {
        this[options.hoverData] = true
      },

      [namespace.onMouseLeave]() {
        this[options.hoverData] = false
      },
    },
  }
}

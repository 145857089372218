export const HASH = 'gift-cards'

/**
 * Параметры состояния формы ЭПК для хеша
 * @enum {string}
 * @readonly
 */
export const HASH_PARAM = {
  STEP: 'step',
  PROMPT: 'prompt',
}

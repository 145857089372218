import { VIEW } from '../../../constants'
import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp
    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GTM_UA]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
      [TYPE.ADVCAKE]: this.core.all?.advcake,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
    }
  }

  get _currency() {
    return this.gaApp.stores.cart.main.totals.currency
  }

  getItemsAnalyticData({ ids, isOneItem = false, withStock = false }) {
    if (!ids.length) {
      return []
    }

    return ids.map((id) => {
      const item = this.gaApp.stores.cart.main.itemsHashTable[id]
      const analyticData = item?.analyticData ?? {}
      const result = {
        ...analyticData,
        quantity: isOneItem ? 1 : analyticData.quantity,
        _quantity: analyticData.quantity,
      }

      if (withStock) {
        result.stock = item.view === VIEW.DEFAULT
      }

      return result
    })
  }

  getShareProductAnalyticData(product, index = 1) {
    const { name, itemId, price, brand, inStock, quantity } = product
    const actualPrice = price?.actual?.amount ?? 0

    return {
      index,
      name,
      itemId,
      brand,
      inStock,
      quantity,
      price: actualPrice,
    }
  }

  getShareProductsAnalyticData(products) {
    return products.map((product, index) =>
      this.getShareProductAnalyticData(product, index + 1),
    )
  }

  /**
   * Отправляем аналитические данные из билдеров в соответствующие метрики.
   */
  send(Builders, data) {
    const { country } = this.gaApp.i18n.locale
    for (const [key, value] of new Builders(data, country).build()) {
      const metric = this.metrics[key]
      if (!metric) {
        continue
      }

      switch (key) {
        case TYPE.MINDBOX: {
          value.events.forEach((event) => {
            metric.send(event)
          })
          break
        }
        case TYPE.ADVCAKE: {
          const { pageType, products } = value
          products.forEach((product) => {
            metric.send({ currentProduct: product, pageType })
          })
          break
        }
        default:
          metric.send(value)
      }
    }
  }
}

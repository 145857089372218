import isEmpty from 'lodash/isEmpty'

export class AddressesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  listIsEmpty() {
    return isEmpty(this.gaApp.stores.location.addresses.list)
  }

  formatAddressFull(addressId) {
    const address =
      this.gaApp.stores.location.addresses.addressListAsObject[addressId]

    return this.gaApp.services.location.formatter.formatAddressFull(address)
  }

  formatAddressStreetHouse(addressId) {
    const address =
      this.gaApp.stores.location.addresses.addressListAsObject[addressId]

    return this.gaApp.services.location.formatter.formatAddressStreetHouse(
      address,
    )
  }

  formatAddressStreetHouseDivided(addressId) {
    const address =
      this.gaApp.stores.location.addresses.addressListAsObject[addressId]

    return this.gaApp.services.location.formatter.formatAddressStreetHouseDivided(
      address,
    )
  }

  formatAddressCityState(addressId) {
    const address =
      this.gaApp.stores.location.addresses.addressListAsObject[addressId]

    return this.gaApp.services.location.formatter.formatAddressCityState(
      address,
    )
  }

  formatAddressCityStateCascade(addressId) {
    const address =
      this.gaApp.stores.location.addresses.addressListAsObject[addressId]

    return this.gaApp.services.location.formatter.formatAddressCityStateCascade(
      address,
    )
  }
}

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('features')
      const { data } =
        cacheData ||
        (await this.gaApp.repositories.featuresToggle.main.getData())

      return data
    } catch (error) {
      const newError = new Error('Ошибка запроса features')
      newError.errorData = JSON.stringify(error)

      this.gaApp.services.app.apm.captureError(error)

      return []
    }
  }
}

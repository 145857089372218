/*
 * TODO:
 *  - переписать на copmposition api
 */

import { useResizeObserver } from '@vueuse/core'

import { scrollLockScrollable } from '@ga/shared-directives'

const SMOOTH_SCROLL_MIN_UPDATE_DELTA = 100

const THUMB_MIN_HEIGHT = 15

// @vue/component
export default {
  name: 'ga-scroll',

  directives: {
    scrollLockScrollable,
  },

  props: {
    padded: {
      type: Boolean,
      default: false,
    },
    faded: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: Number.POSITIVE_INFINITY,
    },
  },

  data() {
    return {
      rootHeight: 0,
      outerHeight: 0,
      innerHeight: 0,
      shaftHeight: 0,
      thumbHeight: 0,

      scrollProgress: 0,
      scrollMax: 0,

      positionUpdatedAt: 0,
    }
  },

  computed: {
    overflown() {
      return this.innerHeight > this.rootHeight
    },

    outerStyle() {
      const maxHeight = `${this.maxHeight}px`
      const height = this.overflown ? `${this.rootHeight}px` : 'auto'

      return { height, maxHeight }
    },

    thumbStyle() {
      const position = Math.ceil(this.shaftHeight * this.scrollProgress)

      const transform = `translateY(${position}px)`
      const height = `${this.thumbHeight}px`

      return { transform, height }
    },
  },

  watch: {
    maxHeight() {
      this.rootHeight = Number.POSITIVE_INFINITY
    },
  },

  mounted() {
    window.requestAnimationFrame(() => {
      this.update()
    })

    const { inner, outer } = this.$refs

    useResizeObserver(inner, this.update)
    useResizeObserver(outer, this.update)
  },

  methods: {
    onScroll() {
      this.updateScrollProgress()
    },

    updateScroll(value, { instant } = {}) {
      if (instant) {
        this.setScrollTo(value)

        return
      }

      const positionUpdateAt = Date.now()
      const positionUpdateDelta = positionUpdateAt - this.positionUpdatedAt

      const isUpdateTooFrequent =
        positionUpdateDelta < SMOOTH_SCROLL_MIN_UPDATE_DELTA

      if (isUpdateTooFrequent) {
        this.setScrollTo(value)
      } else {
        this.moveScrollTo(value)
      }

      this.positionUpdatedAt = positionUpdateAt
    },

    moveScrollTo(position) {
      const { outer } = this.$refs

      if (outer.scrollTo) {
        outer.scrollTo({ top: position, behavior: 'smooth' })
      } else {
        this.setScrollTo(position)
      }
    },

    setScrollTo(value) {
      const { outer } = this.$refs

      outer.scrollTop = value
    },

    update() {
      this.updateHeights()
      this.updateScrollMax()
      this.updateScrollProgress()
    },

    updateHeights() {
      const { outer, inner, shaft } = this.$refs

      if (!outer || !inner || !shaft) return

      const outerHeight = outer.offsetHeight
      const innerHeight = inner.offsetHeight
      const shaftHeight = shaft.clientHeight
      const thumbHeight = Math.max(
        (outerHeight / innerHeight) * shaftHeight,
        THUMB_MIN_HEIGHT,
      )

      this.rootHeight = this.$el.offsetHeight
      this.outerHeight = outerHeight
      this.innerHeight = innerHeight
      this.shaftHeight = shaftHeight - thumbHeight
      this.thumbHeight = thumbHeight
    },

    updateScrollMax() {
      const { outer } = this.$refs

      if (!outer) return

      this.scrollMax = outer.scrollHeight - this.outerHeight
    },

    updateScrollProgress() {
      const { outer } = this.$refs

      if (!outer) return

      this.scrollProgress = outer.scrollTop / this.scrollMax
    },
  },
}

import { imageFormat, screen, url } from './media-common'

export const imageComplex = {
  type: 'object',
  properties: {
    screen: { ...screen, required: true },
    format: { ...imageFormat, required: true },
    url: {
      type: url.type,
      required: true,
    },
  },
  if: {
    properties: {
      format: {
        not: { const: [] },
      },
      screen: {
        not: { const: [] },
      },
    },
  },
  then: {
    properties: {
      url: {
        pattern: url.pattern,
      },
    },
  },
  additionalProperties: false,
}

export const imagePlain = {
  type: 'object',
  properties: {
    fullhd: {
      type: 'string',
      required: false,
    },
    desktop: {
      type: 'string',
      required: false,
    },
    tablet: {
      type: 'string',
      required: false,
    },
    mobile: {
      type: 'string',
      required: false,
    },
  },
  additionalProperties: false,
}

export const image = {
  oneOf: [imagePlain, imageComplex],
}

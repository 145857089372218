import { STATE } from '~/modules/subscription/components/organisms/subscription-modal/scripts/consts'
import { SUBSCRIBE_RESPONSE_STATE } from '~/modules/subscription/constant'

export class EmailService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  resetState() {
    this.gaApp.stores.subscription.email.$reset()
  }

  openModal() {
    this.gaApp.stores.subscription.email.modalOpened = true
  }

  closeModal() {
    this.resetState()
  }

  openContentModal() {
    this.gaApp.stores.subscription.email.form.email = ''
    this.gaApp.stores.subscription.email.form.policy = false
    this.gaApp.stores.subscription.email.state = STATE.IN_PROCESS
  }

  getCurrentEmail() {
    if (!this.gaApp.stores.user.main.data?.email) return
    this.gaApp.stores.subscription.email.form.email =
      this.gaApp.stores.user.main.data?.email
  }

  async subscribe() {
    this.gaApp.stores.subscription.email.isButtonLoading = true
    const response = await this.gaApp.services.subscription.api.subscribe()
    this.gaApp.stores.subscription.email.isButtonLoading = false
    if (!response) return
    if (response === SUBSCRIBE_RESPONSE_STATE.SUCCESS)
      this.gaApp.analytics.modules.subscription.onSubscribeSent()
    this.gaApp.stores.subscription.email.state =
      response === SUBSCRIBE_RESPONSE_STATE.SUCCESS
        ? STATE.SUCCESSFUL
        : STATE.FAILED
  }
}

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, toRefs, unref } from 'vue'

import { GaVideoNative } from '@ga/ui-components/video-native'

import { useListeners, usePlayer, useSourceResponsive } from './scripts/use'

// @vue/component
export default {
  name: 'ga-video-responsive',
  components: {
    GaVideoBase: GaVideoNative,
  },
  props: {
    video: {
      type: Object,
      default: () => ({}),
    },
    videoKey: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'canplay',
    'loadeddata',
    'ended',
    'pause',
    'play',
    'playing',
    'error',
    'timeupdate',
    'durationchange',
    'mutedchange',
    'bufferedupdate',
    'showedtracks',
  ],
  setup(props, { emit, listeners: $listeners }) {
    const { $gaApp } = useContext()
    const { video, videoKey, options } = toRefs(props)

    /**
     * autoplay: true необходим для корректной работы на ios,
     * иначе видео не запускается программно, без пользовательской активности
     */
    const playerConfig = {
      playsinline: true,
      preload: 'auto',
      autoplay: true,
      muted: true,
      loop: false,
      controls: false,
    }

    const mergedConfig = computed(() => ({
      ...playerConfig,
      ...unref(options),
    }))

    const videoRef = ref()

    const {
      onErrorPlayer,
      restartVideo,
      togglePlay,
      play,
      pause,
      stop,
      toggleMuted,
      changeCurrentTime,
      mute,
      unmute,
    } = usePlayer(videoRef)

    const listeners = useListeners({
      videoKey,
      onErrorPlayer,
      emit,
      $listeners,
    })

    const { hasVideo, sourceInternal } = useSourceResponsive($gaApp, video)

    return {
      mergedConfig,
      videoRef,

      // публичные методы
      restartVideo,
      togglePlay,

      play,
      pause,
      stop,
      changeCurrentTime,
      toggleMuted,
      mute,
      unmute,

      hasVideo,
      listeners,
      sourceInternal,
    }
  },
}

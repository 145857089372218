import { DigiAnalytics } from './digi-analytics.class'

const autoInit = false;

function getDigiAnalytics() {
    const digiAnalytics = new DigiAnalytics()

    return {
        create(api, endpoints) {
            return digiAnalytics.create(api, endpoints)
        },

        sendAnalytics(data) {
          return digiAnalytics.sendAnalytics(data)
        }
    }
}

export default function (context, inject) {
    const $digiAnalytics = getDigiAnalytics()

    if (autoInit) {
      $digiAnalytics.create(context.$api)
    }

    inject('digiAnalytics', $digiAnalytics)
}

import { TYPE } from '../../constants'
import { GtmTrixBloggerProductsAddToCartModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class TrixBloggerProductsAddToCartBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmTrixBloggerProductsAddToCartModel]])
    super(models, data)
  }
}

<template>
  <svg fill="none" stroke="currentcolor" viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="9.25" stroke="currentcolor" stroke-width="1.5" />
    <path stroke="currentcolor" stroke-width="1.5" d="M10 7.951v7.3" />
    <circle cx="10" cy="5.75" r="1" fill="currentcolor" stroke="none" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-checkout-info',
}
</script>

<template>
  <svg viewBox="0 0 20 20">
    <circle
      cx="10"
      cy="10"
      r="8.5"
      fill="none"
      stroke="#fff"
      stroke-width="3"
    />
    <circle cx="10" cy="10" r="9.25" fill="none" stroke-width="1.5" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-additional-check-sign',
}
</script>

import {
  ProductViewBuilder,
  ProductViewLeaveBuilder,
  TitleClickBuilder,
  WidgetRenderBuilder,
  WidgetViewBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class ProductListingAnalytics extends BaseController {
  /**
   * Просмотр товаров
   */
  onProductView({ product, analyticData, chunk }) {
    const data = this.getProductData(product, analyticData)
    const chunkData = chunk.map((product) =>
      this.getProductData(product, analyticData),
    )
    this.send(ProductViewBuilder, { product: data, chunk: chunkData })
  }

  /**
   * При уходе со страницы
   */
  onDestroyProductView({ products, analyticData, lengthRestDiff }) {
    /**
     * Если при уходе со страницы, юзер увидел 1-4 товара,
     * то нужно отправить об этом событие в аналитику
     */

    const chunk = products
      .slice(lengthRestDiff)
      .map((product) => this.getProductData(product, analyticData))

    this.send(ProductViewBuilder, {
      product: chunk[0],
      chunk,
      products,
    })
  }

  onPageLeaveView() {
    this.send(ProductViewLeaveBuilder)
  }

  /**
   * При клике тайтла в слайдере
   */
  onTitleClick({ itemListId, path, url }) {
    this.send(TitleClickBuilder, { itemListId, itemListName: path, url })
  }

  /**
   * Отрисовка блока рекомендаций на странице
   */
  onWidgetRender(data) {
    this.send(WidgetRenderBuilder, data)
  }

  /**
   * Просмотр блока рекомендаций
   */
  onWidgetView(data) {
    this.send(WidgetViewBuilder, data)
  }
}

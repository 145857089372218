export class DigitalFelicitationPreviewService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Открывает модальное окно предпросмотра поздравления
   */
  open() {
    this.gaApp.stores.giftCardsB2b.digital.felicitation.previewIsOpen = true
  }

  /**
   * Закрывает модальное окно предпросмотра поздравления
   */
  close() {
    this.gaApp.stores.giftCardsB2b.digital.felicitation.previewIsOpen = false
  }
}

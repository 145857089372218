export const SUPPORT_MESSENGERS_ICONS = {
  telegram: {
    default: 'GaIconPrimarySupportTg',
    inverted: 'GaIconPrimarySupportTgInverted',
    rtl: null,
  },
  whatsapp: {
    default: 'GaIconPrimarySupportWa',
    inverted: null,
    rtl: null,
  },
}

export const state = () => {
  return {
    parameters: {
      pageType: null,
      moduleType: null,
      entityId: null,
      tickerPosition: null,
    },

    ticker: null,
    tickerGap: null,
    tickerPosition: null,

    ribbon: {
      currentId: null,
      previousId: null,
    },
  }
}

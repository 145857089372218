<template>
  <svg fill="none" stroke="none" viewBox="0 0 49 28">
    <path
      fill="#000"
      d="M48.657 10.856V17.7h-2.41V12.9h-2.322v4.803h-2.41v-6.847h7.142Z"
    />
    <path
      fill="#000"
      fill-rule="evenodd"
      d="M36.563 17.946c2.159 0 3.761-1.341 3.761-3.375 0-1.97-1.182-3.247-3.158-3.247-.912 0-1.665.325-2.232.887.136-1.161 1.104-2.008 2.17-2.008l2.098-.004 1.047-2.033s-2.325.054-3.406.054c-2.47.044-4.138 2.32-4.138 5.085 0 3.22 1.627 4.641 3.858 4.641Zm.014-4.822c.8 0 1.356.534 1.356 1.446 0 .822-.493 1.499-1.356 1.5-.826 0-1.382-.627-1.382-1.486 0-.913.556-1.46 1.382-1.46Z"
      clip-rule="evenodd"
    />
    <path
      fill="#000"
      d="M30.743 15.41s-.57.333-1.42.396c-.977.03-1.848-.597-1.848-1.71 0-1.084.769-1.706 1.824-1.706.647 0 1.503.455 1.503.455s.627-1.166.951-1.749c-.594-.456-1.386-.707-2.306-.707-2.323 0-4.122 1.536-4.122 3.694 0 2.185 1.69 3.686 4.122 3.64.68-.025 1.617-.267 2.188-.64l-.892-1.673Z"
    />
    <path fill="#5B57A2" d="m0 6.094 3.344 6.061v3.697l-3.34 6.05L0 6.093Z" />
    <path
      fill="#D90751"
      d="m12.84 9.95 3.134-1.947 6.413-.006-9.547 5.93V9.95Z"
    />
    <path
      fill="#FAB718"
      d="m12.823 6.059.017 8.024-3.352-2.088V0l3.335 6.059Z"
    />
    <path
      fill="#ED6F26"
      d="m22.387 7.997-6.413.006-3.151-1.944L9.488 0l12.899 7.997Z"
    />
    <path
      fill="#63B22F"
      d="M12.84 21.935v-3.893l-3.352-2.049L9.49 28l3.35-6.065Z"
    />
    <path
      fill="#1487C9"
      d="m15.966 20.005-12.622-7.85L0 6.095l22.373 13.902-6.407.008Z"
    />
    <path
      fill="#017F36"
      d="m9.49 28 3.35-6.065 3.126-1.93 6.407-.007L9.49 28Z"
    />
    <path
      fill="#984995"
      d="m.004 21.901 9.512-5.908-3.198-1.99-2.974 1.85L.004 21.9Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-pay-methods-sbp',
}
</script>

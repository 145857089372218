import { PAGE_TYPE } from '../constants'

export const moduleStreamerceSsrRoutes = [
  {
    moduleType: 'streamerce',
    pageType: PAGE_TYPE.main,
    path: '/streamerce',
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/streamerce/page/main' */
        '~/modules/streamerce/components/pages/main.vue'
      ),
  },
]

export const routes = [...moduleStreamerceSsrRoutes]

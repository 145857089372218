export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getSearchResult(query) {
    try {
      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()
      const customerGroupId = this.gaApp.stores.user.main.data.groupId

      const requestBody = {
        query,
        cityId,
        cityDistrict,
        geoPolygons,
        customerGroupId,
      }

      const { data } =
        await this.gaApp.repositories.search.main.getSearchResult(requestBody)
      this.gaApp.stores.search.main.searchResult = data
    } catch (error) {
      throw new Error(error)
    }
  }
}

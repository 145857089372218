export const QUEUE_MODAL_NAME = {
  COOKIE: 'cookie',
  LOCATION: 'location',
  AUTH: 'auth',
  REVIEW: 'review',
  ADV: 'adv',
}

// очередь открытия модалок при старте приложения
export const INIT_QUEUE_MODAL = [
  QUEUE_MODAL_NAME.ADV,
  QUEUE_MODAL_NAME.COOKIE,
  QUEUE_MODAL_NAME.LOCATION,
  QUEUE_MODAL_NAME.AUTH,
  QUEUE_MODAL_NAME.REVIEW,
]

export const OPEN_MODAL_TIMEOUT = 2000

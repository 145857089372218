export const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
}

export const INFO_INDENT = {
  START: 'start',
  END: 'end',
  BOTH: 'both',
  NONE: 'none',
}

export const RATIO = {
  SQUARE: '1_1',
  RECT: '11_13',
}

export const GaModuleCartModal = () =>
  import(
    /* webpackChunkName: 'module/cart/modal' */
    './template/modal/index'
  ).then(({ GaCartModal }) => GaCartModal)

export const GaModuleCartWarningModal = () =>
  import(
    /* webpackChunkName: 'module/cart/warning-modal' */
    './template/warning-modal/index'
  ).then(({ GaCartWarningModal }) => GaCartWarningModal)

export const GaModuleCartShareModal = () =>
  import('./template/share-modal/index').then(
    ({ GaCartShareModal }) => GaCartShareModal,
  )

export const GaModuleCartButtonAdd = () =>
  import(
    /* webpackChunkName: 'module/cart/add-button' */
    './molecules/button-add/index.js'
  )

import { computed } from 'vue'

import { useDeferRender } from '@ga/use/defer-render'

import { GaIconAdditionalPlay } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

import { GaImageResponsive } from '~/components/image-responsive'

import { VIEW } from '../../scripts/consts'

// @vue/component
export default {
  name: 'ga-home-stories-circles-slider-item',
  components: {
    GaImageResponsive,
    GaIcon,
    GaIconAdditionalPlay,
  },
  props: {
    view: {
      type: String,
      default: VIEW.BASE,
      validator: (value) => Object.values(VIEW).includes(value),
    },
    name: {
      type: String,
      default: null,
    },
    image: {
      type: Object,
      default: null,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const showPlayButton = computed(() => {
      return [VIEW.LIVE].includes(props.view)
    })

    const { defer } = useDeferRender(100)

    return {
      showPlayButton,

      defer,
    }
  },
}
